import { Observable, forkJoin } from 'rxjs';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService, MessageService } from '../../core';
import { CondominiosConselhoHttpService } from './services';
import { ConselhosCondominio, Bloco, Usuario, tiposConselhosCondominio } from '../../models';
import { CondominiosConselhoHistorioModalComponent } from './historico-modal';
import { BlocosHttpService } from '../blocos';
import { UsuariosHttpService, UsuariosCadastroModalComponent } from '../usuarios';
import { catchError, map } from 'rxjs/operators';
import { Permission } from './../../componentes-base/permission';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { UsuariosPesquisaModalComponent } from '../usuarios/pesquisa-modal';

declare var jQuery: any;

@Component({
	selector: 'condominios-conselho',
	templateUrl: './condominios-conselho.component.html',
	providers: [
		PermissionService
	]
})
export class CondominiosConselhoComponent implements OnInit {

	@ViewChild('condominiosConselhoHistoricoModal') condominiosConselhoHistoricoModal: CondominiosConselhoHistorioModalComponent;
	@ViewChild('usuariosCadastroModal') usuariosCadastroModalComponent: UsuariosCadastroModalComponent;
	@ViewChild('usuariosPesquisaModal') usuariosPesquisaModal: UsuariosPesquisaModalComponent;

	private idCliente: number;
	private idCondominio: number;
	private idUsuario: number;

	entities: ConselhosCondominio[];
	usuarios: Usuario[];
	blocos: Bloco[];
	public permissions: Permission;
	public resource = '/condominios-conselho';

	constructor(private messageService: MessageService,
		private authService: AuthService,
		private condominiosConselhoHttpService: CondominiosConselhoHttpService,
		private blocosHttpService: BlocosHttpService,
		private usuariosHttpService: UsuariosHttpService,
		private permissionService: PermissionService) {
		this.idUsuario = this.authService.getIdCurrentUser();
	}

	ngOnInit() {
		this.idCliente = this.authService.getIdCurrentCliente();
		this.idCondominio = this.authService.getIdCurrentCondominio();
		if (this.idCondominio) {
			this.buscaTodos(this.idCondominio);
			this.getAllBlocos(this.idCondominio);
		} else {
			this.messageService.error('O condomínio não foi encontrado. É selecionar o condomínio para editar o seu conselho', 'Erro');
		}

		this.getAllUsuarios();
		this.getPermissions();
	}

	// private getVigentes(idCondominio: number) {
	// 	this.condominiosConselhoHttpService.getHistoricoDoisAnos(idCondominio).subscribe(
	// 		entities => {
	// 			// Se o condomínio não possuir nenhum membro do conselho, criamos um novo conselho
	// 			if (entities.length === 0) {
	// 				this.createNovoConselho();
	// 			} else {
	// 				this.entities = entities;
	// 			}
	// 		},
	// 		error => this.messageService.error('Erro ao carregar os conselhos vigentes do condomínio. Tente novamente', 'Erro', error)
	// 	);
	// }

	private buscaTodos(idCondominio: number) {
		this.condominiosConselhoHttpService.obterTodosCondominio(idCondominio).subscribe(
			entities => {
				// Se o condomínio não possuir nenhum membro do conselho, criamos um novo conselho
				if (entities.length === 0) {
					this.createNovoConselho();
				} else {
					this.entities = entities;
				}
			},
			error => this.messageService.error('Erro ao carregar os conselhos vigentes do condomínio. Tente novamente', 'Erro', error)
		);
	}

	// Carrega os blocos do condomínio
	private getAllBlocos(idCondominio: number) {
		this.blocosHttpService.getAllByCondominio(idCondominio).subscribe(
			entities => {
				this.blocos = entities;
			},
			error => this.messageService.error('Erro ao carregar os blocos do condomínio. Tente novamente', 'Erro', error)
		);
	}

	// Carrega todos os usuário cadastrados
	private getAllUsuarios() {
		let filterParams = {
			id_cliente: this.idCliente, 
			tipo: 3, 
			id_condominio: this.idCondominio, 
			id_usuario: this.idUsuario, 
			bloco: true, 
			unidade: true
		};
		this.usuariosHttpService.getAllByClienteCondominioAndTipo(filterParams).subscribe(
			condominos => this.usuarios = condominos,
			error => this.messageService.error('Erro ao carregar os usuários', 'Erro', error)
		);
	}

	// Cria um novo conselho com os membros padrões
	private createNovoConselho() {
		this.entities = [];
		this.createSindico();
		this.createOutroMembro();
	}

	private createSindico() {
		const sindico = new ConselhosCondominio(1, this.idCondominio);
		this.entities.unshift(sindico);
	}

	public createOutroMembro() {
		const outroMembro = new ConselhosCondominio(null, this.idCondominio);
		this.entities.push(outroMembro);
	}

	public onDeleteMembro(membro: ConselhosCondominio): void {
		if (membro.id) {
			this.condominiosConselhoHttpService.delete(membro).subscribe(
				() => {
					this.removeFromList(membro);
					this.messageService.success('Membro excluído com sucesso', 'Sucesso');
				},
				error => this.messageService.error('Não foi possível excluir o membro. Tente novamente mais tarde', 'Erro', error)
			);
		} else {
			this.removeFromList(membro);
		}
	}

	private removeFromList(membro: ConselhosCondominio): void {
		const index = this.entities.indexOf(membro);
		if (index > -1) {
			this.entities.splice(index, 1);
		}
	}

	// Valida se está faltando alguma informação para algum membro do conselho
	private validaSaveMembros() {
		let countSindicosGestao = 0;
		this.entities
			.filter(entity => entity.tipo === 1)
			.map(entity => { if (!entity.data_saida) countSindicosGestao++; });

		const usuariosConselho = this.entities.filter(it => !it.data_saida)
			.map(it => it.id_usuario_conselho);

		const usuarios: string[] = Object.keys(this.groupBy(usuariosConselho));

		if (usuarios.length !== usuariosConselho.length) {
			this.messageService.info(`Está pessoa já esta associada a outro tipo`);
			return false;
		}

		for (let i = 0; i < this.entities.length; i++) {
			const entity = this.entities[i];
			if (entity.editado) {
				const tipoMembro = entity.tipo ? tiposConselhosCondominio.find(tipo => tipo.id === entity.tipo) : null;
				const nomeMembro = entity.usuarioConselho ? entity.usuarioConselho.nome : null;
				if (countSindicosGestao > 1) {
					this.messageService.info(`Não é possivel cadastrar mais de um síndico por gestão`);
					return false;
				}
				if (!entity.tipo) {
					this.messageService.info(`É necessário informar o tipo para o membro ${nomeMembro ? nomeMembro : ''}`, 'Informação');
					return false;
				}
				if (!entity.id_usuario_conselho) {
					this.messageService.info(`É necessário informar o nome do ${tipoMembro.nome}`, 'Informação');
					return false;
				}
				if (!entity.data_entrada) {
					this.messageService.info(`É necessário informar a data de entrada do ${tipoMembro.nome}`, 'Informação');
					return false;
				}
				if (entity.renovando && !entity.data_saida) {
					this.messageService.info(`É necessário informar a data de saída do ${tipoMembro.nome} ou cancelar sua renovação`, 'Informação');
					return false;
				}
			}
		}
		return true;
	}

	private groupBy(array: any[]): any[] {
		return array.reduce(function (groups, item) {
			const val = item;
			groups[val] = groups[val] || [];
			groups[val].push(item);
			return groups;
		}, {});
	}

	public save() {
		if (this.validaSaveMembros()) {
			const saveMembrosList = this.createSaveMembrosList();
			if (saveMembrosList.length > 0) {
				const btnSave = jQuery('#btnSave');
				btnSave.button('loading');
				forkJoin(saveMembrosList).subscribe(
					() => {
						this.buscaTodos(this.idCondominio);
						btnSave.button('reset');
						this.messageService.success('', 'Registros salvos com sucesso');
					},
					error => {
						btnSave.button('reset');
						this.buscaTodos(this.idCondominio);
					}
				);
			} else {
				this.messageService.info('É necessário alterar algum membro do conselho para salvar', 'Informação');
			}
		}
	}

	private createSaveMembrosList(): Observable<ConselhosCondominio>[] {
		const list: Observable<ConselhosCondominio>[] = [];
		this.entities.forEach(entity => {
			if (entity.editado) {
				const observable: Observable<ConselhosCondominio> = entity.id ? this.condominiosConselhoHttpService.put(entity) : this.condominiosConselhoHttpService.post(entity);
				observable.pipe(
					map(response => response),
					catchError(error => {
						this.messageService.error(`Erro ao salvar o membro ${entity.usuarioConselho ? entity.usuarioConselho.nome : ''}. Tente novamente`, 'Erro', error);
						return Observable.throw(error);
					})
				);
				list.push(observable);
			}
		});
		return list;
	}

	public visualizarHistorico() {
		this.condominiosConselhoHistoricoModal.open(this.idCondominio);
	}

	public cancel() {
		// Nada faz
	}

	public getPermissions() {
		this.permissionService.getPermissions(this.resource).subscribe(
			permissions => this.permissions = permissions
		);
	}

}