import { map, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { Crud, CrudService, ConfigService, MessageService, CustomHttp } from '../../../core';
import { Endereco, Cidade, Condominio } from '../../../models';
import { CidadeHttpService } from '../../cidade';
import { CondominiosHttpService } from '../../../home/condominios/services/condominios-http.service';

@Injectable()
export class EnderecoHttpService implements Crud<Endereco> {
    resource = 'endereco';
    resourceCep = 'cep';
    baseUrl: string;
    baseCepUrl: string;


    constructor(private crudService: CrudService,
                private customHttp: CustomHttp,
                private configService: ConfigService,
                private messageService: MessageService,
                private cidadeHttpService: CidadeHttpService,
                private condominiosHttpService: CondominiosHttpService) {
            this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
            this.baseCepUrl = `${configService.getBaseUrl()}/${this.resourceCep}`;

    }

    /**
     * Carrega o endereço informado e a cidade cadastrada nele
     */
    get(id: number): Observable<Endereco> {
        return this.crudService.get<Endereco>(this.resource, id)
                   .pipe(map(response => {
                            // Carregando a cidade do endereço
                            this.cidadeHttpService.get(response.id_cidade)
                                .subscribe(
                                    entity => response.cidade = entity,
                                    error => this.messageService.error('Erro ao carregar a cidade do endereço. Tente novamente', 'Erro', error)
                                );
                            return response;
                       })
                   );
    }

    getAll(): Observable<Endereco[]> {
        return this.crudService.getAll<Endereco>(this.resource);
    }

    post(entity: Endereco): Observable<Endereco> {
        return this.crudService.post<Endereco>(this.resource, entity);
    }

    put(entity: Endereco): Observable<Endereco> {
        return this.crudService.put<Endereco>(this.resource, entity);
    }

    delete(entity: Endereco): Observable<string> {
        return this.crudService.delete<Endereco>(this.resource, entity);
    }


    /** Carrega o endereço do condomínio informado */
    getEnderecoCondominio(idCondominio: number): Promise<Endereco> {
        return new Promise<Endereco>((resolve, reject) => {
            // 1 - Carrega o condomínio para pegar o ID do endereço
            this.condominiosHttpService.get(idCondominio)
                .subscribe(
                    condominio => {
                        if (condominio) {
                            // 2 - Carrega o endereço do condomínio
                            this.get(condominio.id_endereco)
                                .subscribe(
                                    entity => resolve(entity),
                                    error => reject(error)
                                );
                        } else {
                            resolve(null);
                        }
                    },
                    error => reject(error)
                );
        });
    }

    getAddressByCep(cep): Observable<Endereco> {
        return this.customHttp.get(`${this.baseCepUrl}/${cep}`)
                    .pipe(
                        map(response => response.json()),
                        catchError(error => Observable.throw(error.json()))
                    );
    }

    getAddressByCondominio(id_condominio: number, id_usuario: number, id_unidade: number): Observable<Endereco> {
        return this.customHttp.get(`${this.baseUrl}/consultar/condominio`, {params: {id_condominio, id_usuario, id_unidade}})
            .pipe(
                map(response => response.json()),
                catchError(error => _throw(error))
            );
    }

}
