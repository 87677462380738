export class AnaliticoRelFiltro {
	id_cliente: number;
	id_condominio: number;
	id_usuario: number;
	id_conta_bancaria: any;
	data_inicial: Date;
	data_final: Date;
	showAnexo: boolean;

	/** Limpa o filtro */
	limpar() {
		this.id_conta_bancaria = null;
		this.data_inicial = null;
		this.data_final = null;
		this.showAnexo = false;
	}
}