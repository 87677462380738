import { Routes } from '@angular/router';
import { RetrievePasswordComponent } from './login';
import { AuthGuard } from './core';
import { LoginComponent } from './login';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './home/dashboard';
import { ClientesComponent } from './home/clientes';
import { FornecedoresComponent, FornecedoresCadastroComponent } from './home/fornecedores';
import { CondominiosCadastroComponent, CondominiosComponent } from './home/condominios';
import { CondominiosConfiguracaoComponent } from './home/condominios-configuracao';
import { CondominiosConselhoComponent } from './home/condominios-conselho';
import { BlocosComponent, BlocosCadastroComponent, UnidadesTrocaUsuarioComponent } from './home/blocos';
import { UsuariosComponent, UsuariosCadastroComponent, TelefonesComponent, TelefonesEditarComponent } from './home/usuarios';
import { LancamentosCondominioComponent, LancamentosCondominioCadastroComponent, TemplateLancamentoCondominioComponent } from './home/lancamentos-condominio';
import { ContasBancariasComponent, ContasBancariasCadastroComponent } from './home/contas-bancarias';
import { OcorrenciasComponent } from './home/ocorrencias';
import { PlanoDeContasComponent, PlanoDeContasCadastroComponent, CategoriaContaGrupoComponent } from './home/plano-de-contas';
import { PagamentosComponent } from './home/pagamentos';
import { TiposConsumoComponent, TiposConsumoCadastroComponent } from './home/tipos-consumo';
import { ConsumosUnidadesCadastroComponent } from './home/consumos-unidades';
import { LancamentosUnidadesComponent, LancamentosUnidadesCadastroComponent } from './home/lancamentos-unidades';
import { LancamentosEspecificosComponent, LancamentosEspecificosCadastroComponent, TemplateLancamentoEspecificoComponent } from './home/lancamentos-especificos';
import { RateiosComponent, RateiosCadastroComponent, RateiosUnidadesComponent } from './home/rateios';
import { GruposDeUsuariosComponent, GruposDeUsuariosCadastroComponent } from './home/grupos-de-usuarios';
import { ArquivosRetornoCadastroComponent } from './home/arquivos-retorno';
import { BoletosComponent, TituloCondominoComponent } from './home/boletos';
import { ClientesCadastroComponent } from './home/clientes/cadastro';
import { RenegociacoesRelComponent } from './home/relatorios/renegociacoes';
import { FormulariosCadastroComponent, FormulariosComponent } from './home/formularios';
import { InventariosComponent, InventariosCadastroComponent } from './home/inventarios';
import { SegurosCadastroComponent, SegurosComponent } from './home/seguros';
import { ComunicacoesComponent, ComunicacoesCadastroComponent } from './home/comunicacoes';
import { OrcamentosComponent, OrcamentosCadastroComponent } from './home/orcamentos';
import { LogoClienteCadastroComponent } from './home/logo-cliente';
import { DocumentosCadastroComponent, DocumentosComponent } from './home/documentos';
import { LocalCadastroComponent, LocalComponent } from './home/local';
import { ReservaAreaCadastroComponent } from './home/reserva-area';
import { PrevisaoOrcamentariaComponent, PrevisaoOrcamentariaCadastroComponent } from './home/previsao-orcamentaria';
import { ConciliacaoBancariaComponent, ConciliacaoContaComponent, ConsultaConciliacaoComponent } from './home/contas-bancarias/conciliacao-bancaria';
import { ConciliacaoBancariaRelComponent, AnaliticoRelComponent, InadimplenciaRelComponent, BalanceteRelComponent, ComparativoFinanceiroRelComponent, RazaoRelComponent, LancamentosCategoriaContaRelComponent, HistoricoMovimentacaoMensalRelComponent, RelatorioReservasComponent, CertidaoNegativaDebitoComponent, CertidaoNegativaDebitoCadastroComponent, ConsumosRelComponent, ContasAPagarRelComponent, NotificacaoComponent, CondominosRelComponent, ConfiguracaoNotificacaoAutomaticaComponent, ProtocoloRelComponent, ConsumosUnidadesRelComponent, CreditosLancamentosRelComponent, DemonstrativoRelComponent, PrevisaoPorFracaoRelComponent, EspelhoBoletoRelComponent, TitulosRelComponent, AntecipacaoTaxaCondominioRelComponent, RelatorioCobrancaComponent, ChequeRelatorioComponent, EnvioEmailRelatorioComponent, ComparativoPrevisaoReceitaDespesaRelComponent, FundosMovimentacaoRelComponent } from 'app/home/relatorios';
import { LivroComponent, LivroConfiguracaoComponent, LivroConfiguracaoCadastroComponent } from './home/livro';
import { RelatorioCadastroComponent, RelatorioComponent } from './home/relatorio';
import { FiltroCadastroComponent, FiltroComponent } from './home/relatorio/filtro';
import { BancoComponent } from './home/banco';
import { ExtratosCadastroComponent, ExtratosComponent, TransferenciasCadastroComponent, VisualizacaoExtratoComponent } from './home/contas-bancarias/extratos';
import { ConfiguracaoEmailClienteComponent } from './home/configuracao-email-cliente';
import { FabricanteVeiculoComponent, ModeloVeiculoComponent, TipoVeiculoComponent } from './home/veiculo';
import { TipoContatoComponent } from './home/tipo-contato';
import { ReservaBloqueioComponent } from './home/reserva-bloqueio';
import { CobrancaAvulsaCadastroComponent, CobrancaAvulsaComponent } from './home/cobranca-avulsa';
import { ExtratoAnexoComponent } from './home/contas-bancarias/extrato-anexo';
import { ReciboComponent } from './home/recibo';
import { CertificadoDigitalComponent } from './home/certificado-digital';

export const ROUTES: Routes = [
	{ path: '', redirectTo: 'login', pathMatch: 'full' },
	{ path: 'login', component: LoginComponent },
	{ path: 'retrieve', component: RetrievePasswordComponent },
	{
		path: '', component: HomeComponent, canActivateChild: [AuthGuard],
		children: [
			{ path: '', redirectTo: 'dashboard', pathMatch: 'full' },
			{ path: 'dashboard', component: DashboardComponent },
			{
				path: 'clientes',
				children: [
					{ path: '', component: ClientesComponent },
					{ path: 'new', component: ClientesCadastroComponent },
					{ path: ':id', component: ClientesCadastroComponent }
				]
			}, {
				path: 'relatorio',
				children: [
					{ path: '', component: RelatorioComponent },
					{ path: 'new', component: RelatorioCadastroComponent },
					{ path: ':id', component: RelatorioCadastroComponent },
				]
			}, {
				path: 'banco',
				children: [
					{ path: '', component: BancoComponent },
				]
			}, {
				path: 'certificado-digital',
				children: [
					{ path: '', component: CertificadoDigitalComponent },
				]
			}, {
				path: 'fabricante-veiculo',
				children: [
					{ path: '', component: FabricanteVeiculoComponent },
				]
			}, {
				path: 'modelo-veiculo',
				children: [
					{ path: '', component: ModeloVeiculoComponent },
				]
			}, {
				path: 'tipo-veiculo',
				children: [
					{ path: '', component: TipoVeiculoComponent },
				]
			}, {
				path: 'tipo-contato',
				children: [
					{ path: '', component: TipoContatoComponent },
				]
			}, {
				path: 'filtro',
				children: [
					{ path: '', component: FiltroComponent },
					{ path: 'new', component: FiltroCadastroComponent },
					{ path: ':id', component: FiltroCadastroComponent },
				]
			}, {
				path: 'fornecedores',
				canActivate: [AuthGuard],
				data: { resource: '/fornecedores' },
				children: [
					{ path: '', component: FornecedoresComponent },
					{ path: 'new', component: FornecedoresCadastroComponent },
					{ path: ':id', component: FornecedoresCadastroComponent }
				]
			}, {
				path: 'condominios',
				canActivate: [AuthGuard],
				data: { resource: '/condominios' },
				children: [
					{ path: '', component: CondominiosComponent },
					{ path: 'new', component: CondominiosCadastroComponent },
					{ path: ':id', component: CondominiosCadastroComponent }
				]
			}, {
				path: 'blocos',
				canActivate: [AuthGuard],
				data: { resource: '/blocos' },
				children: [
					{ path: '', component: BlocosComponent },
					{ path: 'new', component: BlocosCadastroComponent },
					{ path: ':id', component: BlocosCadastroComponent },
					{
						path: ':id/unidade', children: [
							{ path: ':idunidade/troca/:tipo', component: UnidadesTrocaUsuarioComponent }
						]
					}
				]
			}, {
				path: 'condominios-configuracao',
				canActivate: [AuthGuard],
				data: { resource: '/condominios-configuracao' },
				component: CondominiosConfiguracaoComponent
			}, {
				path: 'condominios-conselho',
				canActivate: [AuthGuard],
				data: { resource: '/condominios-conselho' },
				component: CondominiosConselhoComponent
			}, {
				path: 'condominos',
				canActivate: [AuthGuard],
				data: { resource: '/condominos' },
				children: [
					{ path: '', component: UsuariosComponent },
					{ path: 'new', component: UsuariosCadastroComponent },
					{ path: ':id', component: UsuariosCadastroComponent },
					{ path: ':id/telefones', component: TelefonesComponent },
					{ path: ':id/telefones/:id', component: TelefonesEditarComponent },
					{ path: ':id/telefones/new', component: TelefonesEditarComponent }
				]
			}, {
				path: 'colaboradores',
				canActivate: [AuthGuard],
				data: { resource: '/colaboradores' },
				children: [
					{ path: '', component: UsuariosComponent },
					{ path: 'new', component: UsuariosCadastroComponent },
					{ path: ':id', component: UsuariosCadastroComponent },
					{ path: ':id/telefones', component: TelefonesComponent },
					{ path: ':id/telefones/:id', component: TelefonesEditarComponent },
					{ path: ':id/telefones/new', component: TelefonesEditarComponent }
				]
			}, {
				path: 'contas-bancarias',
				canActivate: [AuthGuard],
				data: { resource: '/contas-bancarias' },
				children: [
					{ path: '', component: ContasBancariasComponent },
					{ path: 'new', component: ContasBancariasCadastroComponent },
					{ path: ':id', component: ContasBancariasCadastroComponent },
				]
			}, {
				path: 'movimentacao', component: VisualizacaoExtratoComponent, data: { resource: '/financeiro/controle-financeiro' }
			}, {
				path: 'financeiro',
				children: [
					{
						path: 'controle-financeiro/anexo',
						canActivate: [AuthGuard],
						data: { resource: '/financeiro/controle-financeiro' },
						children: [
							{ path: '', component: ExtratoAnexoComponent }
						]
					}, {
						path: 'controle-financeiro',
						canActivate: [AuthGuard],
						data: { resource: '/financeiro/controle-financeiro' },
						children: [
							{ path: '', component: ExtratosComponent },
							{ path: 'new', component: ExtratosCadastroComponent },
							{ path: ':id', component: ExtratosCadastroComponent },
						]
					}, {
						path: 'contas-pagar',
						component: PagamentosComponent,
						canActivate: [AuthGuard],
						data: { resource: '/financeiro/contas-pagar' }
					}, {
						path: 'transferencia',
						canActivate: [AuthGuard],
						data: { resource: '/financeiro/controle-financeiro' },
						children: [
							{ path: '', component: TransferenciasCadastroComponent },
							{ path: 'new', component: TransferenciasCadastroComponent },
							{ path: ':id', component: TransferenciasCadastroComponent }
						]
					}, {
						path: 'previsao-orcamentaria',
						canActivate: [AuthGuard],
						data: { resource: '/financeiro/previsao-orcamentaria' },
						children: [
							{ path: '', component: PrevisaoOrcamentariaComponent },
							{ path: 'new', component: PrevisaoOrcamentariaCadastroComponent },
							{ path: ':id', component: PrevisaoOrcamentariaCadastroComponent }
						]
					}, {
						path: 'conciliacao-bancaria',
						canActivate: [AuthGuard],
						data: { resource: '/financeiro/conciliacao-bancaria' },
						children: [
							{ path: '', component: ConciliacaoBancariaComponent },
							{ path: 'conciliar/:id', component: ConciliacaoContaComponent },
							{ path: 'consulta/:id', component: ConsultaConciliacaoComponent },
						]
					}, {
						path: 'cobranca-avulsa',
						canActivate: [AuthGuard],
						data: { resource: '/financeiro/cobranca-avulsa' },
						children: [
							{ path: '', component: CobrancaAvulsaComponent },
							{ path: 'new', component: CobrancaAvulsaCadastroComponent },
							{ path: ':id', component: CobrancaAvulsaCadastroComponent }
						]
					}
				]
			}, {
				path: 'documentos',
				canActivate: [AuthGuard],
				data: { resource: '/documentos' },
				children: [
					{ path: '', component: DocumentosComponent },
					{ path: 'new', component: DocumentosCadastroComponent },
					{ path: ':id', component: DocumentosCadastroComponent }
				]
			}, {
				path: 'local',
				canActivate: [AuthGuard],
				data: { resource: '/local' },
				children: [
					{ path: '', component: LocalComponent },
					{ path: 'new', component: LocalCadastroComponent },
					{ path: ':id', component: LocalCadastroComponent }
				]
			}, {
				path: 'reserva-area',
				canActivate: [AuthGuard],
				data: { resource: '/reserva-area' },
				children: [
					{ path: '', component: ReservaAreaCadastroComponent },
					{ path: 'new', component: ReservaAreaCadastroComponent },
					{ path: ':id', component: ReservaAreaCadastroComponent }
				]
			}, {
				path: 'reserva-bloqueio',
				canActivate: [AuthGuard],
				data: { resource: '/reserva-bloqueio' },
				children: [
					{ path: '', component: ReservaBloqueioComponent }
				]
			}, {
				path: 'despesas-condominio',
				canActivate: [AuthGuard],
				data: { resource: '/despesas-condominio' },
				children: [
					{ path: '', component: LancamentosCondominioComponent },
					{ path: 'template', component: TemplateLancamentoCondominioComponent },
					{ path: 'new', component: LancamentosCondominioCadastroComponent },
					{ path: ':id', component: LancamentosCondominioCadastroComponent },
				]
			}, {
				path: 'receitas-condominio',
				canActivate: [AuthGuard],
				data: { resource: '/receitas-condominio' },
				children: [
					{ path: '', component: LancamentosEspecificosComponent },
					{ path: 'template', component: TemplateLancamentoEspecificoComponent },
					{ path: 'new', component: LancamentosEspecificosCadastroComponent },
					{ path: ':id', component: LancamentosEspecificosCadastroComponent }
				]
			}, {
				path: 'ocorrencias',
				canActivate: [AuthGuard],
				data: { resource: '/ocorrencias' },
				component: OcorrenciasComponent
			}, {
				path: 'plano-de-contas',
				canActivate: [AuthGuard],
				data: { resource: '/plano-de-contas' },
				children: [
					{ path: '', component: PlanoDeContasComponent },
					{ path: 'new', component: PlanoDeContasCadastroComponent },
					{ path: ':id', component: PlanoDeContasCadastroComponent }
				]
			}, {
				path: 'categoria-conta-grupo',
				canActivate: [AuthGuard],
				data: { resource: '/categoria-conta-grupo' },
				children: [
					{ path: '', component: CategoriaContaGrupoComponent }
				]
			}, {
				path: 'tipos-consumo',
				canActivate: [AuthGuard],
				data: { resource: '/tipos-consumo' },
				children: [
					{ path: '', component: TiposConsumoComponent },
					{ path: 'new', component: TiposConsumoCadastroComponent },
					{ path: ':id', component: TiposConsumoCadastroComponent }
				]
			}, {
				path: 'consumos-unidades',
				canActivate: [AuthGuard],
				data: { resource: '/consumos-unidades' },
				children: [
					{
						path: '',
						component: ConsumosUnidadesCadastroComponent,
						canActivate: [AuthGuard],
						data: { resource: '/consumos-unidades' }
					}
				]
			}, {
				path: 'receitas-unidades',
				canActivate: [AuthGuard],
				data: { resource: '/receitas-unidades' },
				children: [
					{ path: '', component: LancamentosUnidadesComponent },
					{ path: 'new', component: LancamentosUnidadesCadastroComponent },
					{ path: ':id', component: LancamentosUnidadesCadastroComponent }
				]
			}, {
				path: 'inventarios',
				canActivate: [AuthGuard],
				data: { resource: '/inventarios' },
				children: [
					{ path: '', component: InventariosComponent },
					{ path: 'new', component: InventariosCadastroComponent },
					{ path: ':id', component: InventariosCadastroComponent }
				]
			}, {
				path: 'seguros',
				canActivate: [AuthGuard],
				data: { resource: '/seguros' },
				children: [
					{ path: '', component: SegurosComponent },
					{ path: 'new', component: SegurosCadastroComponent },
					{ path: ':id', component: SegurosCadastroComponent }
				]
			}, {
				path: 'comunicacoes',
				canActivate: [AuthGuard],
				data: { resource: '/comunicacoes' },
				children: [
					{ path: '', component: ComunicacoesComponent },
					{ path: 'new', component: ComunicacoesCadastroComponent },
					{ path: ':id', component: ComunicacoesCadastroComponent }
				]
			}, {
				path: 'orcamentos',
				canActivate: [AuthGuard],
				data: { resource: '/orcamentos' },
				children: [
					{ path: '', component: OrcamentosComponent },
					{ path: 'new', component: OrcamentosCadastroComponent },
					{ path: ':id', component: OrcamentosCadastroComponent }
				]
			}, {
				path: 'rateios',
				canActivate: [AuthGuard],
				data: { resource: '/rateios' },
				children: [
					{ path: '', component: RateiosComponent },
					{ path: 'new', component: RateiosCadastroComponent },
					{ path: ':id', component: RateiosCadastroComponent },
					{ path: ':id/unidades', component: RateiosUnidadesComponent },
					{ path: ':id/unidades/editar', component: RateiosUnidadesComponent }
				]
			}, {
				path: 'relatorios',
				children: [
					{
						path: 'analitico',
						component: AnaliticoRelComponent,
						canActivate: [AuthGuard],
						data: { resource: '/relatorios/analitico' }
					}, {
						path: 'balancete',
						component: BalanceteRelComponent,
						canActivate: [AuthGuard],
						data: { resource: '/relatorios/balancete' }
					}, {
						path: 'condominos',
						component: CondominosRelComponent,
						canActivate: [AuthGuard],
						data: { resource: '/relatorios/condominos' }
					}, {
						path: 'consumos-unidades',
						component: ConsumosUnidadesRelComponent,
						canActivate: [AuthGuard],
						data: { resource: '/relatorios/consumos-unidades' }
					}, {
						path: 'creditos-lancamentos',
						component: CreditosLancamentosRelComponent,
						canActivate: [AuthGuard],
						data: { resource: '/relatorios/creditos-lancamentos' }
					}, {
						path: 'comparativo-financeiro',
						component: ComparativoFinanceiroRelComponent,
						canActivate: [AuthGuard],
						data: { resource: '/relatorios/comparativo-financeiro' }
					}, {
						path: 'comparativo-previsao-receita-despesa',
						component: ComparativoPrevisaoReceitaDespesaRelComponent,
						canActivate: [AuthGuard],
						data: { resource: '/relatorios/comparativo-previsao-receita-despesa' }
					}, {
						path: 'demonstrativo',
						component: DemonstrativoRelComponent,
						canActivate: [AuthGuard],
						data: { resource: '/relatorios/demonstrativo' }
					}, {
						path: 'historico-movimentacao-mensal',
						component: HistoricoMovimentacaoMensalRelComponent,
						canActivate: [AuthGuard],
						data: { resource: '/relatorios/historico-movimentacao-mensal' }
					}, {
						path: 'livro',
						component: LivroComponent,
						canActivate: [AuthGuard],
						data: { resource: '/relatorios/livro' }
					}, {
						path: 'lancamentos-categoria-conta',
						component: LancamentosCategoriaContaRelComponent,
						canActivate: [AuthGuard],
						data: { resource: '/relatorios/lancamentos-categoria-conta' }
					}, {
						path: 'renegociacoes',
						component: RenegociacoesRelComponent,
						canActivate: [AuthGuard],
						data: { resource: '/relatorios/renegociacoes' }
					}, {
						path: 'previsao-por-fracao',
						component: PrevisaoPorFracaoRelComponent,
						canActivate: [AuthGuard],
						data: { resource: '/relatorios/previsao-por-fracao' }
					}, {
						path: 'espelho-boleto',
						component: EspelhoBoletoRelComponent,
						canActivate: [AuthGuard],
						data: { resource: '/relatorios/espelho-boleto' }
					}, {
						path: 'inadimplencia',
						component: InadimplenciaRelComponent,
						canActivate: [AuthGuard],
						data: { resource: '/relatorios/inadimplencia' }
					}, {
						path: 'titulos',
						component: TitulosRelComponent,
						canActivate: [AuthGuard],
						data: { resource: '/relatorios/titulos' }
					}, {
						path: 'conciliacao-bancaria',
						component: ConciliacaoBancariaRelComponent,
						canActivate: [AuthGuard],
						data: { resource: '/relatorios/conciliacao-bancaria' }
					}, {
						path: 'razao',
						component: RazaoRelComponent,
						canActivate: [AuthGuard],
						data: { resource: '/relatorios/razao' }
					}, {
						path: 'antecipacao-taxa-condominio',
						component: AntecipacaoTaxaCondominioRelComponent,
						canActivate: [AuthGuard],
						data: { resource: '/relatorios/antecipacao-taxa-condominio' }
					}, {
						path: 'fundos-movimentacao',
						component: FundosMovimentacaoRelComponent,
						canActivate: [AuthGuard],
						data: { resource: '/relatorios/fundos-movimentacao' }
					}
				]
			}, {
				path: 'relatorios-administrativos',
				children: [
					{
						path: 'certidao-negativa-debito',
						canActivate: [AuthGuard],
						data: { resource: '/relatorios-administrativos/certidao-negativa-debito' },
						children: [
							{ path: '', component: CertidaoNegativaDebitoComponent },
							{ path: 'new', component: CertidaoNegativaDebitoCadastroComponent },
						]
					}, {
						path: 'relatorio-cheque',
						component: ChequeRelatorioComponent,
						canActivate: [AuthGuard],
						data: { resource: '/relatorios-administrativos/relatorio-cheque' }
					}, {
						path: 'relatorio-consumos',
						canActivate: [AuthGuard],
						data: { resource: '/relatorios-administrativos/relatorio-consumos' },
						children: [
							{ path: '', component: ConsumosRelComponent },
						]
					}, {
						path: 'relatorio-contas-a-pagar',
						canActivate: [AuthGuard],
						data: { resource: '/relatorios-administrativos/relatorio-contas-a-pagar' },
						children: [
							{ path: '', component: ContasAPagarRelComponent },
						]
					}, {
						path: 'notificacao',
						canActivate: [AuthGuard],
						data: { resource: '/relatorios-administrativos/notificacao' },
						children: [
							{ path: '', component: NotificacaoComponent },
							{
								path: 'configuracao',
								canActivate: [AuthGuard],
								children: [
									{ path: '', component: ConfiguracaoNotificacaoAutomaticaComponent }
								]
							}
						]
					}, {
						path: 'relatorio-reservas',
						canActivate: [AuthGuard],
						data: { resource: '/relatorios-administrativos/relatorio-reservas' },
						children: [
							{ path: '', component: RelatorioReservasComponent },
						]
					}, {
						path: 'relatorio-protocolo',
						canActivate: [AuthGuard],
						data: { resource: '/relatorios-administrativos/relatorio-protocolo' },
						children: [
							{ path: '', component: ProtocoloRelComponent },
						]
					}, {
						path: 'cobranca',
						component: RelatorioCobrancaComponent,
						canActivate: [AuthGuard],
						data: { resource: '/relatorios-administrativos/cobranca' }
					}, {
						path: 'envio-email',
						component: EnvioEmailRelatorioComponent,
						canActivate: [AuthGuard],
						data: { resource: '/relatorios-administrativos/envio-email' }
					}
				]
			}, {
				path: 'cobrancas',
				children: [
					{
						path: 'arquivo-retorno',
						canActivate: [AuthGuard],
						data: { resource: '/cobrancas/arquivo-retorno' },
						children: [
							{ path: '', component: ArquivosRetornoCadastroComponent }
						]
					}, {
						path: 'titulos',
						canActivate: [AuthGuard],
						data: { resource: '/cobrancas/titulos' },
						children: [
							{ path: '', component: BoletosComponent },
						]
					}, {
						path: 'titulo-condomino',
						canActivate: [AuthGuard],
						data: { resource: '/cobrancas/titulo-condomino' },
						children: [
							{ path: '', component: TituloCondominoComponent }
						]
					}
				]
			}, {
				path: 'configuracao',
				children: [
					{
						path: 'grupos-de-acessos',
						canActivate: [AuthGuard],
						data: { resource: '/configuracao/grupos-de-acessos' },
						children: [
							{ path: '', component: GruposDeUsuariosComponent },
							{ path: 'new', component: GruposDeUsuariosCadastroComponent },
							{ path: ':id', component: GruposDeUsuariosCadastroComponent }
						]
					}, {
						path: 'email-cliente',
						canActivate: [AuthGuard],
						data: { resource: '/configuracao/email-cliente' },
						component: ConfiguracaoEmailClienteComponent
					}, {
						path: 'formularios',
						canActivate: [AuthGuard],
						data: { resource: '/configuracao/formularios' },
						children: [
							{ path: '', component: FormulariosComponent },
							{ path: 'new', component: FormulariosCadastroComponent },
							{ path: ':id', component: FormulariosCadastroComponent }
						]
					}, {
						path: 'logo-sistema',
						canActivate: [AuthGuard],
						data: { resource: '/configuracao/logo-sistema' },
						component: LogoClienteCadastroComponent
					}, {
						path: 'livro',
						canActivate: [AuthGuard],
						data: { resource: '/configuracao/livro' },
						children: [
							{ path: '', component: LivroConfiguracaoComponent },
							{ path: 'new', component: LivroConfiguracaoCadastroComponent },
							{ path: ':id', component: LivroConfiguracaoCadastroComponent }
						]
					}
				]
			}, {
				path: 'recibo',
				canActivate: [AuthGuard],
				data: { resource: '/recibo' },
				children: [
					{ path: '', component: ReciboComponent },
				]
			}
		]
	}
];