import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';

import { Crud, CrudService, ConfigService, CustomHttp } from '../../../../core';
import { PagamentoUnidade } from '../../../../models';
import { LancamentosUnidadesPagamentosDataCompetenciaService } from './lancamentos-unidades-pagamentos-data-competencia.service';

@Injectable()
export class LancamentosUnidadesPagamentosHttpService implements Crud<PagamentoUnidade> {
	resource = 'pagamentounidade';
	private baseUrl: string;

	constructor(private customHttp: CustomHttp,
		private crudService: CrudService,
		private configService: ConfigService,
		private lancamentosUnidadesPagamentosDataCompetenciaService: LancamentosUnidadesPagamentosDataCompetenciaService) {
		this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
	}

	get(id: number): Observable<PagamentoUnidade> {
		return this.crudService.get<PagamentoUnidade>(this.resource, id);
	}

	getAll(): Observable<PagamentoUnidade[]> {
		return this.crudService.getAll<PagamentoUnidade>(this.resource);
	}

	post(entity: PagamentoUnidade): Observable<PagamentoUnidade> {
		return this.crudService.post<PagamentoUnidade>(this.resource, entity);
	}

	put(entity: PagamentoUnidade): Observable<PagamentoUnidade> {
		return this.crudService.put<PagamentoUnidade>(this.resource, entity);
	}

	delete(entity: PagamentoUnidade): Observable<string> {
		return this.crudService.delete<PagamentoUnidade>(this.resource, entity);
	}

	/**
	 * Carrega todos os pagamentos do lançamento informado
	 */
	public getAllByLancamentoUnidade(idLancamentoUnidade: number): Observable<PagamentoUnidade[]> {
		return this.customHttp.get(`${this.baseUrl}/consultar/lancamento/${idLancamentoUnidade}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Busca todos os parcelas de lancamentos de um condomínio para seleção
	 * @param id_condominio 
	 * @author Marcos Frare
	 * @since 28/07/2021
	 */
	public getAllSelectCondominio(id_condominio: number, filtro: any): Observable<PagamentoUnidade[]> {
		return this.customHttp.post(`${this.baseUrl}/selecao/condominio/${id_condominio}`, filtro).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}
	
}