import { BaseEntity } from '../base-entity';

export class ConfiguracaoLayoutRecibo extends BaseEntity {
	id_condominio: number;
	recibos_por_pagina: number;

	idsTitulo: number[];

	constructor() {
		super();
		this.recibos_por_pagina = 1;
		this.idsTitulo = [];
	}
}