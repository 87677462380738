import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NavigationComponent } from './navigation.component';
import { SharedModule } from '../../../shared';
import { TrocaCondominioModalComponent } from './troca-condominio-modal';
import { AlterarSenhaModalComponent } from './alterar-senha-modal/alterar-senha-modal.component';

@NgModule({
	declarations: [NavigationComponent, TrocaCondominioModalComponent, AlterarSenhaModalComponent],
	imports: [BrowserModule, RouterModule, SharedModule],
	exports: [NavigationComponent],
})

export class NavigationModule { }