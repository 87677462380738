import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseCadastroComponent } from '../../../componentes-base';
import { AuthService, MessageService } from '../../../core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { CategoriaContas, LancamentoEspecifico, PagamentoEspecifico, tiposDivisaoRateio, tiposUnidade, TYPES_PAGAMENTO, Unidade } from '../../../models';
import { LancamentosEspecificosHttpService } from '../services';
import { PermissionService } from '../../../core/services/permission/permission.service';
import { FloatFormatPipe, DecimalFormatPipe } from '../../../shared/pipes';
import { CondominiosConfiguracaoHttpService } from '../../condominios-configuracao';
import { LancamentoPagamentosComponent } from 'app/componentes-utils/lancamento-pagamentos';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import { CategoriaContasHttpService, CategoriaContasPesquisaModalComponent, PlanoDeContasCadastroModalComponent } from 'app/home/plano-de-contas';
import { UnidadesHttpService } from 'app/home/blocos';
import { NgxSpinnerService } from 'ngx-spinner';

declare var jQuery: any;

@Component({
	selector: 'lancamentos-especificos-cadastro',
	templateUrl: './lancamentos-especificos-cadastro.component.html',
	providers: [
		PermissionService,
		FloatFormatPipe,
		DecimalFormatPipe
	]
})
export class LancamentosEspecificosCadastroComponent extends BaseCadastroComponent<LancamentoEspecifico> implements OnInit {

	@ViewChild('categoriaContasPesquisaModal') categoriaContasPesquisaModal: CategoriaContasPesquisaModalComponent;
	@ViewChild('planoDeContasCadastroModal') planoDeContasCadastroModal: PlanoDeContasCadastroModalComponent;
	@ViewChild('lancamentoPagamentos') lancamentoPagamentos: LancamentoPagamentosComponent;

	public categoriasContas: CategoriaContas[] = [];
	public pagamentos: PagamentoEspecifico[];
	public valor_total_inicial: number;
	public idCliente: number;
	public idCondominio: number;
	public tiposUnidade = tiposUnidade;
	public tiposDivisaoRateio = tiposDivisaoRateio;
	public typesPagamento = TYPES_PAGAMENTO;
	public tipoDivisaoRateioDefault: number;
	public unidades: Unidade[] = [];

	public multiselectSettings: IMultiSelectSettings = {
		enableSearch: true,
		containerClasses: 'container-multiselect',
		showUncheckAll: true,
		showCheckAll: true,
		dynamicTitleMaxItems: 3,
		checkedStyle: 'checkboxes',
		buttonClasses: 'btn btn-default',
		itemClasses: 'dropdown-menu'
	};
	public multiselectOptionsUnidades: IMultiSelectOption[];
	public multiselectTextsUnidades: IMultiSelectTexts = {
		checkAll: 'Selecionar todas',
		uncheckAll: 'Limpar',
		checked: 'selecionada',
		checkedPlural: 'selecionadas',
		searchPlaceholder: 'Pesquisar...',
		defaultTitle: 'Selecionar',
		allSelected: 'Todas selecionadas',
		searchEmptyResult: 'Nenhum registro encontrado',
		searchNoRenderText: 'Digite para pesquisar',
	};

	constructor(activatedRoute: ActivatedRoute,
		router: Router,
		messageService: MessageService,
		permissionService: PermissionService,
		lancamentosEspecificosHttpService: LancamentosEspecificosHttpService,
		private authService: AuthService,
		private condominiosconfiguracaoHttpService: CondominiosConfiguracaoHttpService,
		private categoriaContasHttpService: CategoriaContasHttpService,
		private floatFormatPipe: FloatFormatPipe,
		private decimalFormatPipe: DecimalFormatPipe,
		private unidadesHttpService: UnidadesHttpService,
		public spinner: NgxSpinnerService) {
		super(LancamentoEspecifico, lancamentosEspecificosHttpService, activatedRoute, router, messageService, permissionService);
	}

	ngOnInit() {
		this.idCondominio = this.authService.getIdCurrentCondominio();
		this.idCliente = this.authService.getIdCurrentCliente();
		this.getAllUnidades();
		this.getAllCategoriasByCondominio();
		this.setTipoUnidade();
		super.ngOnInit();
	}

	/**
	 * Carregar todas as unidades do condomínio
	 * @author Marcos Frare
	 * @since 23/08/2021
	 */
	 private getAllUnidades():void {
		this.unidades = [];
		this.unidadesHttpService.getAllByCondominioBloco(this.idCondominio, null).subscribe(
			response => {
				this.unidades = response;
			},
			error => {
				this.messageService.error('Erro ao carregar as unidades do condomínio! Tente novamente.', 'Erro', error);
			}
		);
	}

	public onChangeTipoUnidades(): void {
		this.getUnidadesByTipo();
	}

	/**
	 * Carrega as unidades pelo tipo
	 * @author Marcos Frare
	 * @since 24/08/2021
	 */
	private getUnidadesByTipo(): void {
		let unidades = [];
		if (this.entity.cobrar_tipo_unidade != 100) {
			unidades = this.unidades.filter(un => un.tipo == this.entity.cobrar_tipo_unidade);
		} else {
			unidades = this.unidades;
		}

		this.multiselectOptionsUnidades = unidades.map(uni => {
			return {
				id: uni.id,
				name: `${uni.nome_bloco} - ${uni.nome_unidade}`
			};
		});
	}

	public allowEdit(): boolean {
		return this.entity.permite_edicao;
	}

	private getTipoDivisaoRateio(): void {
		this.condominiosconfiguracaoHttpService.getTipoDivisaoRateioByCondominio(this.idCondominio).subscribe(
			response => this.tipoDivisaoRateioDefault = this.entity.tipo_divisao_rateio = response
		);
	}

	private setTipoUnidade(): void {
		this.unidadesHttpService.getAllTipoByCondominio().subscribe(
			response => {
				this.tiposUnidade = this.filterAvailableTypes(response);
				if (Array.isArray(this.tiposUnidade) && this.tiposUnidade.length > 0) {
					this.entity.cobrar_tipo_unidade = this.tiposUnidade[0].id;
				}
				this.getUnidadesByTipo();
			}
		);
	}

	private filterAvailableTypes(available): any[] {
		return this.tiposUnidade.filter(tipo => available.includes(tipo.id));
	}

	private getAllCategoriasByCondominio(): void {
		this.categoriasContas = [];
		// Contas de Receita
		if (this.idCondominio) {
			this.spinner.show();
			this.categoriaContasHttpService.getAllByCondominio(this.idCondominio, 1).then(
				entities => {
					this.spinner.hide();
					this.categoriasContas = entities
				});
		}
	}

	private setCategoriaContas(): void {
		if (!this.entity.id_categoria_conta) {
			return;
		}
		this.categoriaContasHttpService.get(this.entity.id_categoria_conta).subscribe(
			entity => this.entity.categoriaContas = entity
		);
	}

	private parsePagamentos(pagamentos: any[]): void {
		this.entity.pagamentos = pagamentos.map(pagamento => {
			pagamento.valor = this.floatFormatPipe.transform(pagamento.valor);
			pagamento.permite_exclusao = pagamento.data_pagamento || pagamento.id_demonstrativo_rateio ? false : true;
			return pagamento;
		});
	}

	public updatePagamentos(): void {
		this.lancamentoPagamentos.updatePagamentos();
	}

	public dividirEntreParcelas(): void {
		this.lancamentoPagamentos.dividirEntreParcelas();
	}

	public canEditPagamento(pagamento: any): boolean {
		return this.lancamentoPagamentos.canEditPagamento(pagamento);
	}

	public afterEdit(entity: LancamentoEspecifico): void {
		this.entity.valor_total = this.floatFormatPipe.transform(this.entity.valor_total);
		this.valor_total_inicial = this.entity.valor_total;
		this.parsePagamentos(entity.pagamentos);
		this.setCategoriaContas();
		super.afterEdit(entity);
		this.getUnidadesByTipo();
	}

	public afterInsert(): void {
		this.entity.id_condominio = this.idCondominio;
		this.getTipoDivisaoRateio()
		super.afterInsert();
	}

	public onAfterSaveContaCategoriaConta(categoriaContas: CategoriaContas): void {
		this.onSelectCategoriaContasPesquisaModal(categoriaContas);
	}

	public onSelectCategoriaContas(tm: TypeaheadMatch): void {
		const categoriaContas: CategoriaContas = tm.item as CategoriaContas;
		if (categoriaContas) {
			this.entity.id_categoria_conta = categoriaContas.id;
			this.entity.categoriaContas = categoriaContas;
			this.entity.id_fornecedor = categoriaContas.id_fornecedor;
		} else {
			this.entity.id_categoria_conta = null;
			this.entity.categoriaContas = null;
		}
	}

	public onCleanCategoriaContas(input: any): void {
		this.entity.id_categoria_conta = null;
		this.entity.categoriaContas = null;

		input.value = '';
		input.focus();
	}

	public openPlanoDeContasCadastroModal(): void {
		this.planoDeContasCadastroModal.open();
	}

	public openCategoriaContasPequisaModal(): void {
		this.categoriaContasPesquisaModal.open();
	}

	public onSelectCategoriaContasPesquisaModal(categoriaContas: CategoriaContas): void {
		if (categoriaContas) {
			this.entity.id_categoria_conta = categoriaContas.id;
			this.entity.categoriaContas = categoriaContas;
			this.entity.id_fornecedor = categoriaContas.id_fornecedor;
		}
		jQuery('#categoriaContas').focus();
	}

	public beforeSave(): boolean {
		const totalPagamentos = Number((this.entity.pagamentos.map(x => x.valor).reduce((x, y) => x + y)).toFixed(2));
		if (this.entity.valor_total !== totalPagamentos) {
			this.messageService.warning('O valor total do lançamento está diferente da soma de suas parcelas, favor corrigir os valores e tentar novamente', 'Atenção');
			return false;
		}
		if (this.editMode) {
			if (this.entity.valor_total !== this.valor_total_inicial) {
				if (!confirm(`O valor total do lançamento foi alterado de ${this.decimalFormatPipe.transform(
					this.valor_total_inicial, 1, 2, 2)} para ${this.decimalFormatPipe.transform(
						this.entity.valor_total, 1, 2, 2)}, confirma essa alteração?`)) {
					return false;
				}
			}
		}
		if (!this.validaSavePagamento()) { return false; }
		return true;
	}

	public save(): Promise<any> {
		this.spinner.show();
		return new Promise<any>((resolve, reject) => {
			this.makeAllFieldsDirty();

			if (this.form.valid) {
				if (this.beforeSave()) {
					this.btnSave = jQuery('#btnSave');
					this.btnSave.button('loading');

					const envio: any = { ...this.entity };
					const pagamentos: any[] = [];
					this.entity.pagamentos.forEach(pagamento => {
						const obj: any = { ...pagamento };
						pagamentos.push(obj);
					});
					envio.pagamentos = pagamentos.filter(pagamento => this.canEditPagamento(pagamento)).map(pagamento => {
						return pagamento;
					});
					if (this.editMode) { // PUT
						this.crudService.put(envio).subscribe(
							entity => {
								this.spinner.hide();
								this.messageService.success('', 'Registro salvo com sucesso');
								this.btnSave.button('reset');
								this.afterSave(this.entity);
								resolve(entity);
							},
							error => {
								this.spinner.hide();
								this.messageService.error('Erro ao atualizar o registro', 'Erro', error);
								this.btnSave.button('reset');
								reject(null);
							}
						);
					} else { // POST
						this.crudService.post(envio).subscribe(
							entity => {
								this.spinner.hide();
								this.entity = entity;
								this.messageService.success('', 'Registro salvo com sucesso');
								this.btnSave.button('reset');
								this.afterSave(this.entity);
								resolve(entity);
							},
							error => {
								this.spinner.hide();
								this.messageService.error('Erro ao salvar o registro', 'Erro', error);
								this.btnSave.button('reset');
								reject(null);
							}
						);
					}
				} else {
					this.spinner.hide();
					reject(null);
				}
			} else {
				this.spinner.hide();
				this.showMessageFormInvalid();
				reject(null);
			}
		});
	}

	public saveAndAdd(): Promise<any> {
		let fieldConta = jQuery('#categoriaContas');
		return new Promise<any>((resolve, reject) => {
			this.makeAllFieldsDirty();

			if (this.form.valid) {
				if (this.beforeSave()) {
					this.btnSave = jQuery('#btnSaveAndAdd');
					this.btnSave.button('loading');

					const envio: any = { ...this.entity };
					const pagamentos: any[] = [];
					this.entity.pagamentos.forEach(pagamento => {
						const obj: any = { ...pagamento };
						pagamentos.push(obj);
					});
					envio.pagamentos = pagamentos.filter(pagamento => this.canEditPagamento(pagamento)).map(pagamento => {
						return pagamento;
					});
					if (this.editMode) { // PUT
						this.crudService.put(envio)
							.subscribe(
								entity => {
									this.messageService.success('', 'Registro salvo com sucesso');
									this.btnSave.button('reset');
									this.insert();
									fieldConta.focus();
									resolve(entity);
								},
								error => {
									this.messageService.error('Erro ao atualizar o registro', 'Erro', error);
									this.btnSave.button('reset');
									fieldConta.focus();
									reject(null);
								}
							);
					} else { // POST
						this.crudService.post(envio)
							.subscribe(
								entity => {
									this.entity = entity;
									this.messageService.success('', 'Registro salvo com sucesso');
									this.btnSave.button('reset');
									this.clean();
									fieldConta.focus();
									resolve(entity);
								},
								error => {
									this.messageService.error('Erro ao salvar o registro', 'Erro', error);
									this.btnSave.button('reset');
									fieldConta.focus();
									reject(null);
								}
							);
					}
				} else {
					reject(null);
				}
			} else {
				this.showMessageFormInvalid();
				reject(null);
			}
		});
	}

	public cancel(): void {
		this.router.navigate(['../'], { relativeTo: this.activatedRoute });
	}

	public insert(): void {
		this.router.navigate(['../new'], { relativeTo: this.activatedRoute });
	}

	private clean(): void {
		this.entity = new LancamentoEspecifico();
		this.afterInsert();
		this.valor_total_inicial = null;
	}

	public afterSave(entity: LancamentoEspecifico): void {
		this.entity.valor_total = this.floatFormatPipe.transform(this.entity.valor_total);
		super.afterSave(entity);
	}

	private validaSavePagamento(): boolean {
		for (const pagamento of this.entity.pagamentos) {
			if (!pagamento.valor || isNaN(pagamento.valor)) {
				this.messageService.info(`É necessário informar o valor da ${pagamento.numero_parcela}ª parcela`, 'Informação');
				return false;
			}
			if (!pagamento.data_competencia) {
				this.messageService.info(`É necessário informar a competência da ${pagamento.numero_parcela}ª parcela`, 'Informação');
				return false;
			}
		}
		return true;
	}

}