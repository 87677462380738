import { ActivatedRoute } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { Permission } from 'app/componentes-base';
import { Bloco, ConsumoUnidade, TipoConsumo } from 'app/models';
import { datepickerMesAnoOptions } from 'app/shared';
import { AuthService, MessageService } from 'app/core';
import { ConsumosUnidadesDataCompetenciaService, ConsumosUnidadesHttpService } from '../services';
import { ConsumosUnidadesEstruturaCadastroService, ConsumosUnidadesEstruturaService } from '../estrutura';

declare var jQuery: any;

@Component({
	selector: 'consumos-unidades-cadastro',
	templateUrl: './consumos-unidades-cadastro.component.html',
	styleUrls: ['./consumos-unidades-cadastro.component.css'],
	providers: [DatePipe, PermissionService]
})
export class ConsumosUnidadesCadastroComponent implements OnInit {

	@Input() entities: any[];
	public idCondominio: number;
	public blocos: any[];
	public dataCompetencia: Date;
	public dataCompetenciaAtual: Date;
	public datepickerDataCompetenciaOptions = datepickerMesAnoOptions;
	public tiposConsumo: TipoConsumo[];
	public selectedBloco: number;
	public resource: string;
	public permissions: Permission;
	public isChangedSelecaoLancamento: boolean = false;
	public bloco: Bloco;

	constructor(activatedRoute: ActivatedRoute,
		private messageService: MessageService,
		private authService: AuthService,
		private consumosUnidadesDataCompetenciaService: ConsumosUnidadesDataCompetenciaService,
		private consumosUnidadesEstruturaService: ConsumosUnidadesEstruturaService,
		private consumosUnidadesEstruturaCadastroService: ConsumosUnidadesEstruturaCadastroService,
		private datePipe: DatePipe,
		private permissionService: PermissionService,
		private consumosUnidadesHttpService: ConsumosUnidadesHttpService) {
		this.resource = activatedRoute.data['value']['resource'];
	}

	ngOnInit() {
		this.idCondominio = this.authService.getIdCurrentCondominio();
		if (this.idCondominio) {
			this.getDataCompetenciaAtualByCondominio(this.idCondominio)
				.then(() => this.aplicar())
				.catch(() => { });
		}
		this.getPermissions();
	}

	private getDataCompetenciaAtualByCondominio(idCondominio: number, isAtualizarDataCompetencia: boolean = true): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.consumosUnidadesDataCompetenciaService.getByCondominio(idCondominio)
				.then(dataCompetencia => {
					this.dataCompetenciaAtual = dataCompetencia;
					if (isAtualizarDataCompetencia) {
						this.dataCompetencia = dataCompetencia;
					}
					resolve;
				})
				.catch(error => {
					this.messageService.error('Erro ao carregar a data de competência para o bloco. Tente novamente', 'Erro', error);
					this.dataCompetenciaAtual = null;
					this.dataCompetencia = null;
					reject(error);
				});
		});
	}

	private validarAplicar(): boolean {
		if (!this.consumosUnidadesDataCompetenciaService.validar(this.dataCompetencia, this.dataCompetenciaAtual)) {
			return false;
		}
		return true;
	}

	public aplicar(): void {
		if (this.validarAplicar()) {
			const btnAplicar = jQuery('#btnAplicar');
			btnAplicar.button('loading');
			this.isChangedSelecaoLancamento = false;
			this.consumosUnidadesEstruturaService.createEstutura(this.authService.getIdCurrentCondominio(), this.formataData(this.dataCompetencia))
				.then(response => {
					this.blocos = response.blocos;
					if (!this.selectedBloco) {
						this.selectedBloco = this.blocos[0].id;
					}
					this.bloco = this.blocos.find(it => it.id === Number(this.selectedBloco));
					this.tiposConsumo = response.tiposConsumo;
					this.blocos.forEach(bloco => {
						bloco.estrutura.forEach(estrutura => {
							estrutura.items.forEach(item => {
								item.consumoUnidade.valor = item.consumoUnidade.valor || item.consumoUnidade.valor === 0 ? item.consumoUnidade.valor : null;
								item.consumoUnidade.consumo_anterior = item.consumoUnidade.consumo_anterior;
								item.consumoUnidade.consumo_atual = item.consumoUnidade.consumo_atual || item.consumoUnidade.consumo_atual === 0 ? item.consumoUnidade.consumo_atual : null;
							});
						});
					});
					btnAplicar.button('reset');
				})
				.catch(() => btnAplicar.button('reset'));
		}
	}

	public saveEstrutura(consumoUnidade: ConsumoUnidade): void {
		if (consumoUnidade.consumo_anterior && consumoUnidade.consumo_anterior) {
			consumoUnidade.loading = true;
			this.consumosUnidadesEstruturaCadastroService.save(consumoUnidade).then(
				entity => {
					consumoUnidade.loading = false;
					consumoUnidade.id = entity[0].id;
				},
				error => {
					consumoUnidade.loading = false;
				}
			);
		}
	}

	public deleteEstrutura(consumoUnidade: ConsumoUnidade): void {
		if (consumoUnidade.id) {
			this.consumosUnidadesHttpService.limpar(consumoUnidade)
				.subscribe(() => this.cleanEstrutura(consumoUnidade));
		} else {
			this.cleanEstrutura(consumoUnidade)
		}
	}

	public cleanEstrutura(consumoUnidade: ConsumoUnidade): void {
		consumoUnidade.valor = null;
		consumoUnidade.consumo_atual = null;
	}

	public onChangeDataCompetencia(): void {
		if (this.dataCompetencia) {
			this.dataCompetencia = new Date(this.dataCompetencia);
		}
		this.isChangedSelecaoLancamento = true;
	}

	private formataData(data = null): string {
		data = data ? new Date(data) : new Date();
		return this.datePipe.transform(data.setHours(1), 'dd-MM-yyyy');
	}

	public getPermissions(): void {
		this.permissionService.getPermissions(this.resource)
			.subscribe(permissions => this.permissions = permissions);
	}

	public onChangeSelectedBloco(event: any): void {
		this.bloco = this.blocos.find(it => it.id === Number(event.target.value));
	}

}