export class PrevisaoOrcamentariaFiltro {
	id_cliente: number;
	id_condominio: number;
	id_usuario: number;

	situacao: number;

	/** Limpa o filtro */
	limpar() {
		// const today = new Date((new Date).getFullYear(), (new Date).getMonth(), 1);
		// this.dataInicial = today;
		this.situacao = null;
	}
}