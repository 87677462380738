import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MessageService, AuthService } from 'app/core';
import { ContasBancariasHttpService } from '../../services';
import { ConciliacaoBancariaHttpService } from '../service';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContaBancaria, Conciliacao } from 'app/models';
import { Permission } from 'app/componentes-base';
import { datepickerMesAnoOptions } from 'app/shared';
import { error } from 'protractor';

@Component({
  selector: 'consulta-conciliacao',
  templateUrl: './consulta-conciliacao.component.html',
  styleUrls: ['./consulta-conciliacao.component.css'],
  providers: [
    PermissionService,
    DatePipe
  ]
})
export class ConsultaConciliacaoComponent implements OnInit {

  public contaBancaria: ContaBancaria;
  public competencia: Date;
  private resource: string;
  public permissions: Permission;
  public datepickerDataCompetenciaOptions = datepickerMesAnoOptions;

  public conciliacoes: Conciliacao[];

  constructor(private router: Router,
      private activatedRoute: ActivatedRoute,
      private messageService: MessageService,
      private authService: AuthService,
      private contaBancariaHttpService: ContasBancariasHttpService,
      private conciliacaoBancariaHttpService: ConciliacaoBancariaHttpService,
      private permissionService: PermissionService,
      private datePipe: DatePipe,
      private spinner: NgxSpinnerService) {
    this.contaBancaria = new ContaBancaria();
    this.competencia = new Date();
    this.conciliacoes = [];
    this.resource = this.activatedRoute.data['value']['resource'];
  }

  ngOnInit() {
    this.getPermissions();
    
    // buscar a conta bancária
    this.activatedRoute.params.subscribe((params: Params) => {
      let id = +params['id']; // Convertendo o id para um number
      this.loadContaBancaria(id);
      this.contaBancaria.id = id;
      this.loadConciliacoes();
    });
  }

  /**
   * Busca os dados da conta bancária que será feito a conciliação
   * @param id - ID da conta bancária
   * @author Marcos Frare
   * @since 03/07/2020
   */
  private loadContaBancaria(id: number) {
    this.spinner.show();
    this.contaBancariaHttpService.get(id).subscribe(
      response => {
        this.spinner.hide();
        this.contaBancaria = response;
      },
      error => {
        this.spinner.hide();
        this.messageService.error('Não foi possível buscar informações da conta bancária. Tente novamente', 'Erro.', error);
      }
    );
  }

  /**
   * Busca conciliações feitras para a conta/período
   * @author Marcos Frare
   * @since 03/07/2020
   */
  private loadConciliacoes(): void {
    this.spinner.show();
    this.conciliacoes = [];
    let competencia = this.competencia ? this.datePipe.transform(this.competencia, 'yyyy-MM-dd') : this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.conciliacaoBancariaHttpService.consultarConciliaces(this.contaBancaria, competencia).subscribe(
      response => {
        this.spinner.hide();
        this.conciliacoes = response;
      },
      error => {
        this.spinner.hide();
        this.messageService.error('Erro ao buscar conciliações. Tente novamente!', 'Erro', error);
      }
    );
  }

  /**
   * Busca permissões do usuário
   * @author Marcos Frare
   * @since 03/07/2020
   */
  private getPermissions(): void {
    this.permissionService.getPermissions(this.resource).subscribe(permissions => {
      this.permissions = permissions;
    });
  }

  /**
   * Alteração da competência
   * @author Marcos Frare
   * @since 03/07/2020
   */
  public onChangeCompetencia(): void {
    this.loadConciliacoes();
  }

  /**
   * Exclusão de uma conciliação
   * @param conciliacao 
   * @author Marcos Frare
   * @since 06/07/2020
   */
  public deleteManual(conciliacao: Conciliacao): void {
    this.spinner.show();
    if (confirm('Realmente deseja excluir a conciliação?')) {
      this.conciliacaoBancariaHttpService.excluirConciliacao(conciliacao.id).subscribe(
        ()=> {
          this.spinner.hide();
          this.messageService.info('Conciliação desfeita com sucesso!!', 'Info');
          this.loadConciliacoes();
        },
        error => {
          this.spinner.hide();
          this.messageService.error('Erro ao desfazer conciliação. Tente novamente', 'Erro!', error);
        }
      );
    }
  }

  /**
   * Cancela o processo
   * @author Marcos Frare
   * @since 03/07/2020
   */
  public cancel() {
    this.router.navigate(['../../'], {relativeTo: this.activatedRoute});
  }
}
