import { Bloco, Unidade } from "app/models";

export class RelatorioCobrancaFiltro {
	id_cliente: number;
	id_condominio: number;
	id_bloco: number;
	id_unidade: number;
	id_usuario: number;
	data_inicial: Date;
	data_final: Date;
	acrescimo: number;
	bloco: Bloco;
	unidade: Unidade;
	tipo_acrescimo: string;

	/** Limpa o filtro */
	limpar() {
		const today = new Date();
		this.data_inicial = new Date(today.getFullYear(), today.getMonth(), 1); // primeiro dia do mês passado
		this.data_final = today; // Dia de hoje
		this.acrescimo = 0;
		this.id_bloco = null;
		this.id_unidade = null;
		this.bloco = null;
		this.unidade = null;
		this.tipo_acrescimo = '';
	}
}