import { map, catchError } from 'rxjs/operators';

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { _throw } from 'rxjs/observable/throw';

import { Crud, CrudService, ConfigService, CustomHttp } from '../../../core';
import { Documento, DocumentoPasta } from 'app/models';

@Injectable()
export class DocumentosHttpService implements Crud<Documento> {
	resource = 'documento';
	private baseUrl: string;

	constructor(private customHttp: CustomHttp,
		private crudService: CrudService,
		private configService: ConfigService) {

		this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
	}

	get(id: number): Observable<Documento> {
		return this.crudService.get<Documento>(this.resource, id);
	}

	getAll(): Observable<Documento[]> {
		return this.crudService.getAll<Documento>(this.resource);
	}

	post(entity: Documento): Observable<Documento> {
		return this.crudService.post<Documento>(this.resource, entity);
	}

	put(entity: Documento): Observable<Documento> {
		return this.crudService.put<Documento>(this.resource, entity);
	}

	delete(entity: Documento): Observable<string> {
		return this.crudService.delete<Documento>(this.resource, entity);
	}

	getAllByCondominio(id_condominio: number, privado: boolean = false): Observable<DocumentoPasta[]> {
		return this.customHttp.get(`${this.baseUrl}/consultar`, {params:{id_condominio, privado}}).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public getAllByLivro(id_condominio: number, data_competencia: string): Observable<Documento[]> {
		return this.customHttp.get(`${this.baseUrl}/livro`, { params: { id_condominio, data_competencia } }).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

}
