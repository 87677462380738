import { BaseEntity } from "../base-entity";
import { TipoConsumo } from "./tipo-consumo";
import { TarifasConsumo } from "./tarifas-consumo";

export class CategoriaTarifaConsumo extends BaseEntity {
	id_tipo_consumo: number;
	tipo_consumo: TipoConsumo;
	nome: string;
	tarifas: TarifasConsumo[];
	data_historico: Date;

	constructor() {
		super();
		this.tarifas = [];
		this.data_historico = new Date();
	}
}