import { map, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';

import { Crud, CrudService, AuthService, ConfigService, CustomHttp } from '../../../core';
import { Condominio } from '../../../models';
import { AtivaDesativaCondominio } from '../ativa-desativa-condominio';

@Injectable()
export class CondominiosHttpService implements Crud<Condominio> {
	resource = 'condominio';
	acessos = 'acessoscondominios';
	private baseUrl: string;
	private baseAcessoUrl: string;

	constructor(private customHttp: CustomHttp,
		private crudService: CrudService,
		private configService: ConfigService,
		private authService: AuthService) {
		this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
		this.baseAcessoUrl = `${configService.getBaseUrl()}/${this.acessos}`;
	}

	get(id: number): Observable<Condominio> {
		return this.crudService.get<Condominio>(this.resource, id);
	}

	getAll(): Observable<Condominio[]> {
		return this.crudService.getAll<Condominio>(this.resource);
	}

	getAllByCliente(idCliente: number): Observable<Condominio[]> {
		return this.customHttp.get(`${this.baseUrl}/consultar/cliente/${idCliente}`)
			.pipe(
				map(response => response.json()),
				catchError(error => _throw(error))
			);
	}

	getAllByUsuario(idUsuario: number): Observable<Condominio[]> {
		return this.customHttp.get(`${this.baseAcessoUrl}/consultar/usuario/${idUsuario}`)
			.pipe(
				map(response => response.json()),
				catchError(error => _throw(error))
			);
	}

	post(entity: Condominio): Observable<Condominio> {
		return this.crudService.post<Condominio>(this.resource, entity);
	}

	put(entity: Condominio): Observable<Condominio> {
		return this.crudService.put<Condominio>(this.resource, entity);
	}

	delete(entity: Condominio): Observable<string> {
		return this.crudService.delete<Condominio>(this.resource, entity);
	}

	ativaDesativa(id: number, ativo: boolean): Observable<Response> {
		let entity = new AtivaDesativaCondominio(this.authService.getCurrentUser().id, ativo);
		return this.customHttp.put(`${this.baseUrl}/ativardesativar/${id}`, entity)
			.pipe(
				map(response => response.json()),
				catchError(error => _throw(error))
			);
	}

	getTotalUnidades(idCondominio: number): Observable<number> {
		return this.customHttp.get(`${this.baseUrl}/${idCondominio}/total-unidades`)
			.pipe(
				map(response => response.json()),
				catchError(error => _throw(error))
			);
	}

}
