import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';
import { Crud, CrudService, ConfigService, CustomHttp } from '../../../core';
import { GrupoDeUsuario } from '../../../models';

@Injectable()
export class GruposDeUsuariosHttpService implements Crud<GrupoDeUsuario> {
	resource = 'gruposdeusuario';
	baseUrl: string;

	constructor(private crudService: CrudService,
		public configService: ConfigService,
		private customHttp: CustomHttp) {
		this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
	}

	public get(id_grupo_de_usuario: number): Observable<GrupoDeUsuario> {
		let observable: Observable<any> = id_grupo_de_usuario ?
			this.customHttp.get(`${this.baseUrl}/consultar`, { params: { id_grupo_de_usuario } }) :
			this.customHttp.get(`${this.baseUrl}/consultar`);

		return observable.pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public getAll(): Observable<GrupoDeUsuario[]> {
		return this.crudService.getAll<GrupoDeUsuario>(this.resource);
	}

	public getAllByCliente(idCliente: number): Observable<GrupoDeUsuario[]> {
		return this.customHttp.get(`${this.baseUrl}/consultar/cliente/${idCliente}`)
			.pipe(
				map(response => response.json()),
				catchError(error => _throw(error))
			);
	}

	public post(entity: GrupoDeUsuario): Observable<GrupoDeUsuario> {
		return this.crudService.post<GrupoDeUsuario>(this.resource, entity);
	}

	public put(entity: GrupoDeUsuario): Observable<GrupoDeUsuario> {
		return this.crudService.put<GrupoDeUsuario>(this.resource, entity);
	}

	public delete(entity: GrupoDeUsuario): Observable<string> {
		return this.crudService.delete<GrupoDeUsuario>(this.resource, entity);
	}

	/**
	 * Buscar acessos mobile de um grupo de usuário
	 * @param id_grupo_de_usuario 
	 * @returns 
	 * @author Marcos Frare
	 * @since 10/05/2021
	 */
	public getAcessoMobile(id_grupo_de_usuario: number): Observable<GrupoDeUsuario[]> {
		return this.customHttp.get(`${this.baseUrl}/mobile/${id_grupo_de_usuario}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}
}