/**
 * Classe utilizada nos pagamentos a serem rateados
 */
export class PagamentoRateio {
	id: number;
	checado: boolean;
	nome_lancamento_condominio: string;
	nome_categoria_conta: string;
	numero_parcela: number;
	data_vencimento: string;
	valor_pago: number;
	tipo: number;
	pago: boolean;
}
