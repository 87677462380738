import { CategoriaContas } from './categoria-contas';
import { BaseEntity } from "../base-entity";
import { Extrato } from './extrato';

export class Transferencia extends BaseEntity {
	id: number;
	valor: number;
	data: Date;
	descricao: string;
	id_conta_bancaria_origem: number;
	id_conta_bancaria_destino: number;
	id_categoria_conta: number;
	categoriaContas: CategoriaContas;
	id_extrato_origem: number;
	id_extrato_destino: number;
}