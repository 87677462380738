import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared';
import { UsuariosModule } from '../usuarios';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { CondominiosConselhoComponent } from './condominios-conselho.component';
import { CondominiosConselhoMembrosComponent } from './membros';
import { CondominiosConselhoHistorioModalComponent } from './historico-modal';
import { SindicioFilterPipe, OutrosMembrosFilterPipe } from './pipes';
import { CondominiosConselhoHttpService } from './services';

@NgModule({
    imports: [
        SharedModule,
        UsuariosModule,
        TypeaheadModule
    ],
    declarations: [
        // Components
        CondominiosConselhoComponent,
        CondominiosConselhoMembrosComponent,
        CondominiosConselhoHistorioModalComponent,

        // Pipes
        SindicioFilterPipe,
        OutrosMembrosFilterPipe
    ],
    providers: [
        CondominiosConselhoHttpService
    ]
})
export class CondominiosConselhoModule { }
