import { BaseEntity } from '../base-entity';

export class AcessoPainel extends BaseEntity {
	id: number;
	id_grupo_de_usuario: number;
	codigo: number;
	tipo: number;
	sequencia: number;
	acesso: boolean;
	titulo: string;

	constructor(codigo?: number) {
		super();
		this.codigo = codigo;
	}
}

export enum tipoAcesso { GRAFICO, CARD };