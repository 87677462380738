import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseVisualizacaoComponent } from 'app/componentes-base';
import { AuthService, MessageService } from 'app/core';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { ReservaCalendarioBloqueio } from 'app/models';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReservaBloqueioCadastroModalComponent } from './reserva-bloqueio-cadastro-modal';
import { ReservaBloqueioHttpService } from './service';

@Component({
  selector: 'reserva-bloqueio',
  templateUrl: './reserva-bloqueio.component.html',
  styleUrls: ['./reserva-bloqueio.component.css']
})
export class ReservaBloqueioComponent extends BaseVisualizacaoComponent<ReservaCalendarioBloqueio> implements OnInit {

  @ViewChild('reservaBloqueioCadastroModal') reservaBloqueioCadastroModal: ReservaBloqueioCadastroModalComponent;

  public filtro: any = {};

  constructor(public activatedRoute: ActivatedRoute,
      private authService: AuthService,
      public router: Router,
      public messageService: MessageService,
      public reservaBloqueioHttpService: ReservaBloqueioHttpService,
      public permissionService: PermissionService,
      public spinner: NgxSpinnerService) {
    super('#tableReservaCalendarioBloqueio', reservaBloqueioHttpService, activatedRoute, router, messageService, permissionService, spinner);
  }

  ngOnInit() {
    super.ngOnInit();
    this.initFilter();
  }

  /**
   * Iniciando o filtro
   * @author Marcos Frare
   * @since 28/05/2021
   */
  private initFilter(): void {
    const today = new Date();
    this.filtro.data_inicial = new Date(today.getFullYear(), today.getMonth(), 1);
    this.filtro.data_final = new Date(today.getFullYear(), today.getMonth()+1, 0);
  }

  /**
   * Carregando filtro padrão
   * @author Marcos Frare
   * @since 28/05/2021
   */
  public clear(): void {
    this.initFilter();
  }

  /**
   * Consultar dados
   * @author Marcos Frare
   * @since 28/05/2021
   */
  public search(): void {
    this.filtro.id_condominio = this.authService.getIdCurrentCondominio();
    this.spinner.show();
    this.entities = [];
    this.destroyTable();
    this.reservaBloqueioHttpService.getByFiltro(this.filtro).subscribe(
      response => {
        this.spinner.hide();
        this.entities = response;
        this.showTable();
      },
      error => {
        this.showTable();
        this.spinner.hide();
        this.messageService.error('Erro ao buscar registros!', 'Erro!', error);
      }
    );
  }

  /**
   * Adicionar bloqueio de reserva
   * @author Marcos Frare
   * @since 28/05/2021
   */
  public addBloqueio(): void {
    this.reservaBloqueioCadastroModal.id = null;
    this.reservaBloqueioCadastroModal.open();
  }

  /**
   * Adicionar bloqueio de reserva
   * @author Marcos Frare
   * @since 28/05/2021
   */
  public edit(entity: ReservaCalendarioBloqueio): void {
    this.reservaBloqueioCadastroModal.id = entity.id;
    this.reservaBloqueioCadastroModal.open();
  }

  /**
   * Exclusão de um registro
   * @param entity 
   * @author Marcos Frare
   * @since 28/05/2021
   */
  public delete(entity: ReservaCalendarioBloqueio): void {
    if (confirm(`Deseja mesmo excluir bloqueio de reserva?`)) {
      this.reservaBloqueioHttpService.delete(entity).subscribe(
        () => {
          this.messageService.success('Bloqueio excluído com sucesso!', 'Sucesso');
          this.search();
        },
        error => {
          this.messageService.error('Erro ao excluir registro! Tente novamente.', 'Erro', error)
        }
      );
    }
  }

}