import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared';

import { ContasAPagarRelComponent } from './contas-a-pagar-rel.component';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { FornecedoresModule } from 'app/home/fornecedores';

@NgModule({
  imports: [
    SharedModule,
    MultiselectDropdownModule,
    FornecedoresModule
  ],
  declarations: [
    ContasAPagarRelComponent
  ]
})
export class ContasAPagarRelModule { }