import { Injectable } from '@angular/core';
import { AuthService, ConfigService, Crud, CrudService, CustomHttp } from 'app/core';
import { Antecipacao, CobrancaAvulsa, PagamentoUnidade } from 'app/models';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs-compat/observable/throw';
import { catchError, map } from 'rxjs/operators';
import { GeracaoCobrancaAvulsa } from '../cobranca-avulsa-cadastro/cobranca-avulsa-fatura-modal/geracao-cobranca-avulsa';

@Injectable({
  providedIn: 'root'
})
export class CobrancaAvulsaHttpService implements Crud<CobrancaAvulsa> {

  resource = 'cobranca-avulsa';
	private baseUrl: string;

  constructor(private customHttp: CustomHttp,
      private crudService: CrudService,
      private authService: AuthService,
      private configService: ConfigService) {
    this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
  }

  get(id: number): Observable<CobrancaAvulsa> {
    return this.crudService.get<CobrancaAvulsa>(this.resource, id);
  }
  getAll(): Observable<CobrancaAvulsa[]> {
    return this.crudService.getAll<CobrancaAvulsa>(this.resource, {params: {id_condominio: this.authService.getIdCurrentCondominio()}});
  }
  post(entity: CobrancaAvulsa): Observable<CobrancaAvulsa> {
    return this.crudService.post<CobrancaAvulsa>(this.resource, entity);
  }
  put(entity: CobrancaAvulsa): Observable<CobrancaAvulsa> {
    return this.crudService.put<CobrancaAvulsa>(this.resource, entity);
  }
  delete(entity: CobrancaAvulsa): Observable<any> {
    return this.customHttp.delete(`${this.baseUrl}/excluir/${entity.id}`, {params: {forceDelete: entity.forceDelete}}).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
  }

  /**
   * Busca cobrancas conforme filtro
   * @param params 
   * @author 
   * @returns 
   */
  public search(params: any): Observable<CobrancaAvulsa[]> {
    return this.customHttp.get(`${this.baseUrl}/consultar`, {params}).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
  }

  /**
   * Chamada ao Back-end para gerar as cobranças avulsas por unidade
   * @param cobrancas 
   * @param geracaoCobranca 
   * @author Marcos Frare
   * @since 26/07/2021
   */
  public loadCobrancas(cobrancas: CobrancaAvulsa[], geracaoCobranca: GeracaoCobrancaAvulsa): Observable<CobrancaAvulsa[]> {
    return this.customHttp.post(`${this.baseUrl}/gerar-cobrancas`, {cobrancas, geracaoCobranca}).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
  }

  /**
   * Chamada ao Back-end para gerar as cobranças avulsas por unidade
   * @param cobrancas 
   * @param geracaoCobranca 
   * @author Marcos Frare
   * @since 26/07/2021
   */
  public loadCobrancasLancamentos(cobrancas: CobrancaAvulsa[], pagamentosUnidades: PagamentoUnidade[]): Observable<CobrancaAvulsa[]> {
    return this.customHttp.post(`${this.baseUrl}/gerar-cobrancas-lancamento`, {cobrancas, pagamentosUnidades}).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
  }

  /**
   * Salvar cobranças
   * @param cobrancas 
   * @author Marcos Frare
   * @since 27/07/2021
   */
  public saveCobrancas(cobrancas: CobrancaAvulsa[]): Observable<any> {
    let id_condominio = this.authService.getIdCurrentCondominio();
    return this.customHttp.post(`${this.baseUrl}/gravar-cobrancas`, {cobrancas, id_condominio}).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
  }

  /**
   * Fazer geração de titulos para as cobranças
   * @param cobrancas 
   * @author Marcos Frare
   * @since 28/07/2021
   */
  public gerarTitulo(cobrancas: CobrancaAvulsa[]): Observable<any> {
    return this.customHttp.post(`${this.baseUrl}/gerar-titulos`, {cobrancas}).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
  }

  /**
   * Gerar cobranças de antecipações
   * @param antecipacao 
   * @author Marcos Frare
   * @since 28/07/2021
   */
  public gerarAntecipacao(antecipacao: Antecipacao): Observable<any> {
    let id_condominio = this.authService.getIdCurrentCondominio();
    return this.customHttp.post(`${this.baseUrl}/antecipacao`, {antecipacao, id_condominio}).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
  }

  /**
   * habilitar unidade e gerar cobrança de antecipação
   * @param antecipacao 
   * @author Marcos Frare
   * @since 18/08/2021
   */
  public habilitarGerarAntecipacao(antecipacao: Antecipacao): Observable<any> {
    let id_condominio = this.authService.getIdCurrentCondominio();
    return this.customHttp.post(`${this.baseUrl}/antecipacao/habilitar-unidade`, {antecipacao, id_condominio}).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
  }

}