import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared';

import { DashboardComponent } from './dashboard.component';
import {RouterModule} from '@angular/router';

@NgModule({
    imports: [
        SharedModule,
        RouterModule
    ],
    declarations: [
        DashboardComponent
    ]
})
export class DashboardModule { }
