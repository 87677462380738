import { BaseEntity } from '../base-entity';
import { TipoDocumentoFiscal } from './tipo-documento-fiscal';

export class DocumentoFiscal extends BaseEntity {
	id_lancamento_condominio: number;
	id_tipo_documento_fiscal: number;
	numero_documento: string;
	data_documento: Date;

	tipoDocumentoFiscal: TipoDocumentoFiscal;

	constructor() {
		super();
		this.numero_documento = '';
		this.id_lancamento_condominio = null;
		this.data_documento = null;
		this.tipoDocumentoFiscal = new TipoDocumentoFiscal();
	}
}