import { Component, Input, OnInit } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { MessageService } from 'app/core';
import { Filtro, Relatorio } from 'app/models';
import { RelatorioFiltroHttpService } from './service/relatorio-filtro-http.service';

declare var jQuery: any;

@Component({
  selector: 'relatorio-filtro-modal',
  templateUrl: './relatorio-filtro-modal.component.html',
  styleUrls: ['./relatorio-filtro-modal.component.css']
})
export class RelatorioFiltroModalComponent implements OnInit, BaseModal {
  
  @Input() idModal: string;
  @Input() relatorio: Relatorio;

  public filtros: Filtro[];

  constructor(private messageService: MessageService,
      private relatorioFiltroHttpService: RelatorioFiltroHttpService) { }
  
  ngOnInit() {
    this.relatorio = new Relatorio();
    if (!this.idModal) {
      this.idModal = 'relatorioFiltroModal';
    }
  }

  /**
   * Carregar os dados para configuração
   * @autor Marcos Frare
   * @since 08/12/2020
   */
  private load(): void {
    this.filtros = [];
    this.relatorioFiltroHttpService.load(this.relatorio.id).subscribe(
      response => {
        this.filtros = response;
      },
      error => {
        this.messageService.error('Erro ao buscar dados de configuração!', 'Erro', error);
      }
    );
  }

  /**
   * Salvando os dados 
   * @author Marcos Frare
   * @since 08/12/2020
   */
  public save(): void {
    let btnSave = jQuery('#btnSave');
    btnSave.button('loading');
    this.relatorioFiltroHttpService.salvar(this.relatorio.id, this.filtros).subscribe(
      () => {
        btnSave.button('reset');
        this.messageService.info('Dados gravados com sucesso!', 'Info');
        this.close();
      },
      error => {
        btnSave.button('reset');
        this.messageService.error('Erro ao gravar dados!', 'Erro', error);
      }
    );
  }

  open() {
    this.load();
    jQuery(`#${this.idModal}`).modal('show');
  }
  
  close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

}