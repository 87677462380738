import { BaseEntity } from '../base-entity';

export class TarifasConsumo extends BaseEntity {

	constructor(public consumo_inicial?: any,
		public consumo_final?: any,
		public valor?: any,
		public id_tipo_consumo?: number,
		public id_categoria_tarifa_consumo?: number,
		public consumo_minimo?: any) {
		super();
	}
}