import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe para filtar os outros membros do conselho de um condomínio que não sejam o síndico
 */
@Pipe({
    name: 'outrosMembrosFilter',
    pure: false
})
export class OutrosMembrosFilterPipe implements PipeTransform {

    transform(values: any[]): any[] {
         if (!values) {
             return [];
         }
         return values.filter(value => {
            return value.tipo !== 1; // 1 - Síndico
         });
    }
}
