import { BaseEntity } from "../base-entity";
import { Local } from "./local";

export class ReservaCalendarioBloqueio extends BaseEntity {
	id_condominio: number;
	data_bloqueio: Date;
	observacao: string

	locais: Local[];
	msg: string;

	constructor() {
		super();
		this.locais = [];
	}
}