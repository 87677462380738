import { RateioUnidadeLancamentoUnidade } from './rateio-unidade-lancamento-unidade';

export class RateioUnidadeLancamento {
	id: number;
	id_demonstrativo_rateio: number;
	valor_condominio: number;
	valor_total: number;
	data_vencimento: string;
	unidade: RateioUnidadeLancamentoUnidade;
	open: boolean = false;
	resumo: any;
}
