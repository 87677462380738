import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { EspelhoBoletoRelComponent } from './espelho-boleto-rel.component';
import { BlocosHttpService } from '../../blocos';

@NgModule({
	imports: [
		SharedModule,
		TypeaheadModule,
		MultiselectDropdownModule
	],
	declarations: [
		EspelhoBoletoRelComponent
	],
	exports: [],
	providers: [
		BlocosHttpService
	]
})
export class EspelhoBoletoRelModule { }