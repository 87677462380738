import { BaseEntity } from '../base-entity';
import { Cliente } from './cliente';
import { Condominio } from './condominio';
import { Bloco } from './bloco';
import { Unidade } from './unidade';
import { Anexo } from './anexo';

export class Comunicacao extends BaseEntity {
	id: number;
	id_cliente: number;
	id_condominio: number;
	id_bloco: number;
	id_unidade: number;
	id_usuario_envio: number;
	forma_comunicacao: string;
	situacao_financeira: string;
	cliente: Cliente;
	condominio: Condominio;
	bloco: Bloco;
	unidade: Unidade;
	data_comunicado: Date;
	data_acontecimento: Date;
	tipo: number;
	descricao: string;
	email: boolean;
	email_enviado: boolean;
	remetente: string;
	tipo_remetente: number;
	destinatarios: number[];
	sindico: boolean;
	tipo_usuario: number;

	vinculos: number[];
	nao_enviado: boolean;

	anexos: Anexo[];

	constructor() {
		super();
		this.forma_comunicacao = 'GERAL';
		this.situacao_financeira = 'TODOS';
		this.vinculos = [];
		this.anexos = [new Anexo()];
	}
}

export enum TIPO_REMETENTE {
	ADMINISTRADORA = 1,
	CONDOMINIO = 2,
	CONDOMINO = 3,
	SINDICO = 4
};

export enum DESTINATARIO {
	ADMINISTRADORA = 1,
	CONDOMINO = 2,
	SINDICO = 3
};

export const tiposComunicacao = [
	{ id: 1, nome: 'Administração' },
	{ id: 2, nome: 'Comunicação' },
	{ id: 3, nome: 'Notificação' },
	{ id: 4, nome: 'Ocorrência' },
	// {id: 5, nome: 'Inadimplência'},
	{ id: 6, nome: 'Convocação' },
	{ id: 7, nome: 'Aviso' },
];

export const tiposRemetentes = [
	{ id: 1, nome: 'Administradora' },
	{ id: 2, nome: 'Condomínio' },
	{ id: 3, nome: 'Condômino' },
	{ id: 4, nome: 'Síndico' }
];

export const destinatarios = [
	{ id: 1, nome: 'Administradora', checked: false, disabled: false },
	{ id: 2, nome: 'Condôminos', checked: false, disabled: false },
	{ id: 3, nome: 'Síndico', checked: false, disabled: false }
];