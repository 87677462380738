import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { AuthService, MessageService } from 'app/core';
import { Livro, ConfiguracaoLivro, Documento } from 'app/models';
import { datepickerMesAnoOptions } from 'app/shared';
import { LivroConfiguracaoHttpService } from '../configuracao';
import { LivroHttpService } from '../service';
import { NgForm } from '@angular/forms';
import { DocumentosPesquisaModalComponent } from 'app/home/documentos';

declare var jQuery: any;

@Component({
  selector: 'livro-cadastro-modal',
  templateUrl: './livro-cadastro-modal.component.html',
  styleUrls: ['./livro-cadastro-modal.component.css']
})
export class LivroCadastroModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @ViewChild('documentosPesquisaModal') documentosPesquisaModal: DocumentosPesquisaModalComponent;
  @ViewChild('form') form: NgForm;

  public datepickerCompetenciaOptions = datepickerMesAnoOptions;
  public livro: Livro;
  public configuracoesLivro: ConfiguracaoLivro[];

  constructor(private authService: AuthService,
    private messageService: MessageService,
    private livroConfiguracaoHttpService: LivroConfiguracaoHttpService,
    private livroHttpService: LivroHttpService) { }

  ngOnInit() {
    this.livro = new Livro();
    // Mapeia evento de seleção dos documentos
    this.documentosPesquisaModal.selectDoc$.subscribe(docs => this.livro.documentos = docs);
  }

  /**
   * Exclusão de um documento do livro
   * @param documento 
   * @author Marcos Frare
   * @since 03/08/2020
   */
  public excluirDocumento(documento: Documento): void {
    this.livro.documentos = this.livro.documentos.filter(doc => doc.id != documento.id);
  }

  /**
   * Busca as configurações do livro
   * @author Marcos Frare
   * @since 22/07/2020
   */
  private loadConfiguracoes(): void {
    this.configuracoesLivro = [];
    this.livroConfiguracaoHttpService.getAllGeracao().subscribe(
      response => this.configuracoesLivro = response,
      error => this.messageService.error('Erro ao buscar configurações de livro', 'Erro', error)
    );
  }

  open() {
    this.livro = new Livro();
    this.loadConfiguracoes();
    jQuery(`#${this.idModal}`).modal('show');
  }

  close() {
    this.livro = new Livro();
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Marcar campos "sujos" para mostrar mensagem de erro
   * @author Marcos Frare
   * @since 27/07/2020
   */
  private makeAllFieldsDirty() {
    for (const key in this.form.controls) {
      if (this.form.controls.hasOwnProperty(key)) {
        const control = this.form.controls[key];
        control.markAsDirty();
      }
    }
  }

  /**
   * Processar emissão do livro
   * @param btnSave 
   * @author Marcos Frare
   * @since 31/07/2020
   */
  private processarLivro(btnSave): void {
    this.livroHttpService.processaLivro(this.livro).subscribe(
      () => {
        btnSave.button('reset');
        this.messageService.success('Livro gerado com sucesso!');
        jQuery('#btnUpdateTableLivro').click();
        this.close();
      },
      error => {
        btnSave.button('reset');
        this.messageService.error('Erro ao gerar livro. Tente novamente.', 'Erro', error);
      }
    );
  }

  /**
   * Persistir dados e processar relatorio
   * @author Marcos Frare
   * @since 22/07/2020
   */
  public save(): void {
    this.makeAllFieldsDirty();
    if (this.form.valid) {
      let btnSave = jQuery('#btnSalvarModal');
      btnSave.button('loading');
      this.livro.id_cliente = this.authService.getIdCurrentCliente();
      this.livro.id_condominio = this.authService.getIdCurrentCondominio();
      this.livro.id_usuario = this.authService.getIdCurrentUser();

      // Validar emissão do livro
      this.livroHttpService.validar(this.livro).subscribe(
        response => {
          if (!response.valido) {
            if (confirm(response.msg + ' Deseja gerar o livro mesmo assim?')) {
              this.processarLivro(btnSave);
            } else {
              btnSave.button('reset');
            }
          } else {
            this.processarLivro(btnSave);
          }
        },
        error => {
          btnSave.button('reset');
          this.messageService.error('Erro ao validar livro. Tente novamente.', 'Erro', error);
        }
      );
    } else {
      this.messageService.warning('Informe todos os campos obrigatórios!', 'Atenção!');
    }
  }

  /**
   * Abrir tela para seleçao de outros arquivos para anexar ao livro
   * @author Marcos Frare
   * @since 31/07/2020
   */
  public outrosAnexos(): void {
    this.documentosPesquisaModal.data_competencia = this.livro.competencia;
    this.documentosPesquisaModal.open();
  }

  public changeCompetencia(): void {
    this.livro.documentos = [];
  }

}