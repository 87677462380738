import { BaseEntity } from '../base-entity';

export class Telefone extends BaseEntity {
	tipo: number;
	numero: number;
	observacao: string;
	id_usuario_telefone: number;

	constructor() {
		super();
		this.tipo = 1;
	}
}

export const tiposTelefones = [
	{ id: 1, nome: 'Residencial' },
	{ id: 2, nome: 'Comercial' },
	{ id: 3, nome: 'Celular' },
	{ id: 4, nome: 'Recado' },
	{ id: 5, nome: 'Outro' },
];