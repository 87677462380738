import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe baseado no pipe decimal nativo do Angular para formatar valores decimais no formato brasileiro
 */
@Pipe({
    name: 'decimalFormat'
})
export class DecimalFormatPipe implements PipeTransform {

    transform(value: number, minIntegerDigits: number = 1, minFractionDigits: number = 0, maxFractionDigits: number = 2) {
        if (value == null) {
            return '';
        }
        if (typeof value !== 'number') {
            value = Number(value);
        }
        return value.toLocaleString('pt-br', {style: 'decimal', minimumIntegerDigits: minIntegerDigits, minimumFractionDigits: minFractionDigits, maximumFractionDigits: maxFractionDigits});
    }
}