import { Pipe, PipeTransform } from '@angular/core';

import { TipoConsumo } from '../../../models';

/**
 * Pipe para ordernar os tipos de consumos pelo seu id
 */
@Pipe({
    name: 'tiposConsumoOrderBy'
})
export class TiposConsumoOrderByPipe implements PipeTransform {

    transform(entities: TipoConsumo[]): TipoConsumo[] {
        entities.sort((entityA: TipoConsumo, entityB: TipoConsumo) => {
            if (entityA.id < entityB.id) {
                return -1;
            } else if (entityA.id > entityB.id) {
                return 1;
            }
            return 0;
        });
        return entities;
    }
}
