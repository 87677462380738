import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared';
import { BancoCadastroModalComponent } from './banco-cadastro-modal';
import { BancoComponent } from './banco.component';
import { UiSwitchModule } from 'ngx-ui-switch';

@NgModule({
  imports: [
    SharedModule,
    UiSwitchModule
  ],
  declarations: [
    BancoComponent,
    BancoCadastroModalComponent
  ]
})
export class BancoModule { }