import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { AuthService, MessageService } from '../../../core';
import { RenegociacoesRelFiltro, situacaoFiltroAcordo } from './renegociacoes-rel-filtro';
import { Unidade, Bloco } from 'app/models';
import { BlocosPesquisaModalComponent, UnidadesPesquisaModalComponent, BlocosHttpService, UnidadesHttpService } from 'app/home/blocos';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { datepickerMesAnoOptions } from '../../../shared';
import { RelatorioModalComponent } from '../relatorio-modal';

declare var jQuery: any;

@Component({
	selector: 'renegociacoes-rel',
	templateUrl: './renegociacoes-rel.component.html',
	styleUrls: ['../shared/visualizacao.relatorio.css']
})
export class RenegociacoesRelComponent implements OnInit {

	public filtro = new RenegociacoesRelFiltro();
	public bloco: Bloco;
	public blocos: Bloco[] = [];
	public unidade: Unidade;
	public unidades: Unidade[] = [];
	public idBlocoAtual: number;
	public idUnidadeAtual: number;
	public situacaoFiltro = situacaoFiltroAcordo;
	public datepickerDataCompetenciaOptions = datepickerMesAnoOptions;

	@ViewChild('inputBloco') inputBloco: ElementRef;
	@ViewChild('inputUnidade') inputUnidade: ElementRef;
	@ViewChild('blocosPesquisaModal') blocosPesquisaModal: BlocosPesquisaModalComponent;
	@ViewChild('unidadesPesquisaModal') unidadesPesquisaModal: UnidadesPesquisaModalComponent;
	@ViewChild('relatorioModal') relatorioModal: RelatorioModalComponent;

	constructor(private authService: AuthService,
		private messageService: MessageService,
		private blocosHttpService: BlocosHttpService,
		private unidadesHttpService: UnidadesHttpService) { }

	ngOnInit() {
		this.filtro.id_cliente = this.authService.getIdCurrentCliente();
		this.filtro.id_condominio = this.authService.getIdCurrentCondominio();
		this.filtro.id_usuario = this.authService.getIdCurrentUser();
		this.limpar();
		this.getBlocosByCondominio(this.filtro.id_condominio);
	}

	private validateView(): boolean {
		if (!this.filtro.data_inicial) {
			this.messageService.info('É necessário informar a data inicial para imprimir', 'Informação');
			return false;
		}
		if (!this.filtro.data_final) {
			this.messageService.info('É necessário informar a data final para imprimir', 'Informação');
			return false;
		}
		return true;
	}

	public view(): void {
		if (this.validateView()) {
			this.relatorioModal.url = '/relatorio/renegociacoes';
			this.relatorioModal.params = this.filtro;
			this.relatorioModal.open();
		}
	}

	public limpar(): void {
		this.filtro.limpar();
		this.onCleanBloco(this.inputBloco.nativeElement);
	}

	// Custom
	public getBlocosByCondominio(idCondominio: number): void {
		this.blocos = [];
		this.blocosHttpService.getAllByCondominio(idCondominio).subscribe(
			entities => {
				this.blocos = entities;
				if (this.idBlocoAtual && !isNaN(this.idBlocoAtual)) {
					const index = this.blocos.findIndex(entity => entity.id === this.idBlocoAtual);
					if (index > -1) this.setBloco(this.blocos[index]);
				}
			},
			error => this.messageService.error('Erro ao carregar os blocos do condomínio. Tente novamente', 'Erro', error)
		);
	}

	private setBloco(blocoSelecionado: Bloco) {
		this.bloco = blocoSelecionado;
		this.filtro.id_bloco = this.bloco.id;
		this.getAllUnidadesByBloco(this.bloco.id);
		this.onCleanUnidade(this.inputUnidade.nativeElement);
	}

	public onSelectBloco(tm: TypeaheadMatch): void {
		this.setBloco(tm.item as Bloco);
	}

	public openBlocosPequisaModal(): void {
		this.blocosPesquisaModal.open();
	}

	public onSelectBlocosPesquisaModal(bloco: Bloco): void {
		if (bloco) this.setBloco(bloco);
		jQuery('#bloco').focus();
	}

	public onCleanBloco(input: any): void {
		this.onCleanUnidade(this.inputUnidade.nativeElement);
		this.filtro.id_bloco = null;
		this.bloco = null;
		input.value = '';
		input.focus();
	}

	public getAllUnidadesByBloco(idBloco: number): void {
		this.unidades = [];
		this.unidadesHttpService.getAllByBloco(idBloco).subscribe(
			entities => {
				for (const unidade of entities) {
					if (unidade.nome) this.unidades.push(unidade);
				}
				if (this.idUnidadeAtual && !isNaN(this.idUnidadeAtual)) {
					const index = this.unidades.findIndex(entity => entity.id === this.idUnidadeAtual);
					if (index > -1) this.setUnidade(this.unidades[index]);
				}
			},
			error => this.messageService.error('Erro ao carregar as unidades do bloco. Tente novamente', 'Erro', error)
		);
	}

	private setUnidade(unidadeSelecionada: Unidade) {
		this.unidade = unidadeSelecionada;
		this.filtro.id_unidade = this.unidade.id;
	}

	public onSelectUnidade(tm: TypeaheadMatch): void {
		this.setUnidade(tm.item as Unidade);
	}

	public onCleanUnidade(input: any): void {
		this.unidade = null;
		this.filtro.id_unidade = null;
		input.value = '';
		input.focus();
	}

	public openUnidadesPequisaModal(): void {
		this.unidadesPesquisaModal.open();
	}

	public onSelectUnidadesPesquisaModal(unidade: Unidade): void {
		if (unidade) this.setUnidade(unidade);
		jQuery('#unidade').focus();
	}
}