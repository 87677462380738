import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ConfigService, Crud, CrudService, CustomHttp } from 'app/core';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';

import { Extrato, Transferencia } from 'app/models';

@Injectable()
export class ExtratosHttpService implements Crud<Extrato> {
	resource = 'extrato';
	private baseUrl: string;

	constructor(private customHttp: CustomHttp,
		private crudService: CrudService,
		private configService: ConfigService) {
		this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
	}

	get(id: number): Observable<Extrato> {
		return this.crudService.get<Extrato>(this.resource, id);
	}

	getAll(): Observable<Extrato[]> {
		return this.crudService.getAll<Extrato>(this.resource);
	}

	post(entity: Extrato): Observable<Extrato> {
		return this.crudService.post<Extrato>(this.resource, entity);
	}

	put(entity: Extrato): Observable<Extrato> {
		return this.crudService.put<Extrato>(this.resource, entity);
	}

	delete(entity: Extrato): Observable<string> {
		return this.crudService.delete<Extrato>(this.resource, entity);
	}

	getAllByContaBancaria(idContabancaria: number): Observable<Extrato[]> {
		return this.customHttp.get(`${this.baseUrl}/consultar/contabancaria/${idContabancaria}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	getAllByContaBancariaEFiltro(filtro: any): Observable<any> {
		return this.customHttp.post(`${this.baseUrl}/consultar/contabancaria`, filtro).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	transferir(transferencia: any): Observable<Transferencia> {
		return this.customHttp.post(`${this.baseUrl}/transferir`, transferencia).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

}