import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService, MessageService } from 'app/core';
import { BlocosHttpService, BlocosPesquisaModalComponent, UnidadesHttpService, UnidadesPesquisaModalComponent } from 'app/home/blocos';
import { CondominiosHttpService } from 'app/home/condominios';
import { RelatorioModalComponent } from 'app/home/relatorios/relatorio-modal';
import { Bloco, Condominio, Unidade } from 'app/models';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { RelatorioCobrancaFiltro } from './relatorio-cobranca-filtro';

declare var jQuery: any;

@Component({
  selector: 'relatorio-cobranca',
  templateUrl: './relatorio-cobranca.component.html',
  styleUrls: ['./relatorio-cobranca.component.css']
})
export class RelatorioCobrancaComponent implements OnInit {

  @ViewChild('relatorioModal') relatorioModal: RelatorioModalComponent;
  @ViewChild('inputBloco') inputBloco: ElementRef;
  @ViewChild('inputUnidade') inputUnidade: ElementRef;
  @ViewChild('blocosPesquisaModal') blocosPesquisaModal: BlocosPesquisaModalComponent;
  @ViewChild('unidadesPesquisaModal') unidadesPesquisaModal: UnidadesPesquisaModalComponent;

  public filtro: RelatorioCobrancaFiltro = new RelatorioCobrancaFiltro();
  public condominios: Condominio[] = [];
  public blocos: Bloco[] = [];
  public unidades: Unidade[] = [];
  public mostraCondominio: boolean = false;

  constructor(private messageService: MessageService,
    private authService: AuthService,
    private condominioHttpService: CondominiosHttpService,
    private blocosHttpService: BlocosHttpService,
    private unidadesHttpService: UnidadesHttpService) { }

  ngOnInit() {
    this.limpar();
    this.filtro.id_cliente = this.authService.getIdCurrentCliente();
    this.filtro.id_condominio = this.authService.getIdCurrentCondominio();
    this.filtro.id_usuario = this.authService.getIdCurrentUser();
    this.mostraCondominio = this.filtro.id_condominio ? false : true;
    if (this.mostraCondominio) {
      this.getAllCondominios();
    } else {
      this.getBlocosByCondominio();
    }
  }

  /**
   * Busca os condomínios do cliente
   * @author Marcos Frare
   * @since 30/11/2021
   */
   private getAllCondominios(): void {
    this.condominioHttpService.getAllByCliente(this.filtro.id_cliente).subscribe(
      response => {
        this.condominios = response;
      },
      error => {
        this.messageService.error('Erro ao buscar condomínios do cliente! Tente novamente', error)
      }
    )
  }

  /**
   * Limpar filtros
   */
  public limpar(): void {
    this.filtro.limpar();
    this.filtro.id_condominio = this.authService.getIdCurrentCondominio();
  }

  /**
   * Visualização do relatório
   * @author Marcos Frare
   * @since 30/11/2021
   */
  public view(): void {
    this.relatorioModal.url = '/relatorio/cobranca';
    this.relatorioModal.params = this.filtro;
    this.relatorioModal.open();
  }

  public getBlocosByCondominio(): void {
    this.blocos = [];
    this.blocosHttpService.getAllByCondominio(this.filtro.id_condominio).subscribe(
      entities => this.blocos = entities,
      error => this.messageService.error('Erro ao carregar os blocos do condomínio. Tente novamente', 'Erro', error)
    );
  }

  private setBloco(blocoSelecionado: Bloco): void {
    this.filtro.bloco = blocoSelecionado;
    this.filtro.id_bloco = this.filtro.bloco.id;
    this.getAllUnidadesByBloco(this.filtro.bloco.id);
    this.onCleanUnidade(this.inputUnidade.nativeElement);
  }

  public onSelectBloco(tm: TypeaheadMatch): void {
    this.setBloco(tm.item as Bloco);
  }

  public openBlocosPequisaModal(): void {
    this.blocosPesquisaModal.open();
  }

  public onSelectBlocosPesquisaModal(bloco: Bloco): void {
    if (bloco) this.setBloco(bloco);
    jQuery('#bloco').focus();
  }

  public onCleanBloco(input: any): void {
    this.onCleanUnidade(this.inputUnidade.nativeElement);
    this.filtro.bloco = null;
    this.filtro.id_bloco = null;
    input.value = '';
    input.focus();
  }

  public getAllUnidadesByBloco(idBloco: number): void {
    this.unidades = [];
    this.unidadesHttpService.getAllByBloco(idBloco).subscribe(
      entities => {
        for (const unidade of entities) {
          if (unidade.nome) {
            this.unidades.push(unidade);
          }
        }
      },
      error => this.messageService.error('Erro ao carregar as unidades do bloco. Tente novamente', 'Erro', error)
    );
  }

  private setUnidade(unidadeSelecionada: Unidade): void {
    this.filtro.unidade = unidadeSelecionada;
    this.filtro.id_unidade = this.filtro.unidade.id;
  }

  public onSelectUnidade(tm: TypeaheadMatch): void {
    this.setUnidade(tm.item as Unidade);
  }

  public onCleanUnidade(input: any): void {
    this.filtro.unidade = null;
    this.filtro.id_unidade = null;
    input.value = '';
    input.focus();
  }

  public openUnidadesPequisaModal(): void {
    this.unidadesPesquisaModal.open();
  }

  public onSelectUnidadesPesquisaModal(unidade: Unidade): void {
    if (unidade) this.setUnidade(unidade);
    jQuery('#unidade').focus();
  }

}