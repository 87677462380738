import { Component, OnInit } from '@angular/core';
import { ConfiguracaoLivro } from 'app/models';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'app/core';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LivroConfiguracaoHttpService } from './service';
import { BaseVisualizacaoComponent } from 'app/componentes-base';

@Component({
  selector: 'livro-configuracao',
  templateUrl: './livro-configuracao.component.html',
  styleUrls: ['./livro-configuracao.component.css'],
  providers: [PermissionService]
})
export class LivroConfiguracaoComponent extends BaseVisualizacaoComponent<ConfiguracaoLivro> implements OnInit {

  constructor(public activatedRoute: ActivatedRoute,
      public router: Router,
      public messageService: MessageService,
      private configuracaoLivroHttpService: LivroConfiguracaoHttpService,
      public permissionService: PermissionService,
      public spinner: NgxSpinnerService) {
    super('#tableConfiguracaoLivro', configuracaoLivroHttpService, activatedRoute, router, messageService, permissionService, spinner);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  /**
   * Excluir dados
   * @param entity 
   * @author Marcos Frare
   * @since 21/07/2020
   */
  public delete(entity: ConfiguracaoLivro): void {
    if (confirm(`Tem certeza que deseja excluir a configuração?`)) {
      this.configuracaoLivroHttpService.delete(entity).subscribe(
        () => {
          this.messageService.success('', `Configuração excluída com sucesso!`);
          this.destroyTable();
          this.getAll();
        },
        error => {
          switch (error.status) {
            case 404:
              this.messageService.warning(`Configuração não encontrado, atualize a consulta e tente novamente.`, 'Atenção', error);
              break;
            case 500:
              this.messageService.error(`Erro ao excluir a configuração. Tente novamente`, 'Erro', error);
              break;
            default:
              break;
          }
        });
    }
  }

}