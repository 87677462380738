import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared';
import { OcorrenciasComponent } from './ocorrencias.component';
import { OcorrenciasHttpService } from './services';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        OcorrenciasComponent,
    ],
    exports: [
        OcorrenciasComponent
    ],
    providers: [
        OcorrenciasHttpService
    ]
})
export class OcorrenciasModule { }

