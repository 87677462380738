import { BaseEntity } from '../base-entity';
import { Documento } from './documento';

export class Livro extends BaseEntity {
	id_cliente: number;
	id_condominio: number;
	id_usuario: number;
	descricao: string;
	competencia: Date;
	data_emissao: Date;
	imprimir_frente_verso: boolean;
	arquivo: any;

	documentos: Documento[];

	constructor() {
		super();
		this.imprimir_frente_verso = false;
		this.documentos = [];
	}
}