import { NgModule } from '@angular/core';

import { SharedModule } from '../../../shared';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { BlocosHttpService } from '../../blocos';

import { NotificacaoComponent } from './notificacao.component';
import { NotificacoesHttpService } from './services';
import { ConfiguracaoNotificacaoAutomaticaCadastroModalComponent, ConfiguracaoNotificacaoAutomaticaComponent } from './configuracao-notificacao-automatica';
import { CadastroNotificacaoModalComponent } from './cadastro-notificacao-modal';
import { StatusEnvioEmailComponent } from './status-envio-email';
import { NgxEditorModule } from 'ngx-editor';

@NgModule({
  imports: [
    SharedModule,
    TypeaheadModule,
    MultiselectDropdownModule,
    NgxEditorModule
  ],
  declarations: [
    NotificacaoComponent,
    CadastroNotificacaoModalComponent,
    StatusEnvioEmailComponent,
    ConfiguracaoNotificacaoAutomaticaComponent,
    ConfiguracaoNotificacaoAutomaticaCadastroModalComponent
  ],
  providers: [
    BlocosHttpService,
    NotificacoesHttpService
  ]
})
export class NotificacaoModule { }