import { Pipe, PipeTransform } from '@angular/core';

import { HistoricoUnidade } from '../../../../../models';

/**
 * Pipe para filtar o histórico do proprietário dentro de uma unidade
 */
@Pipe({
    name: 'unidadesHistoricoProprietarioFilter',
    pure: false
})
export class UnidadesHistoricoProprietarioFilterPipe implements PipeTransform {

    transform(values: HistoricoUnidade[]): HistoricoUnidade[] {
         if (!values) {
             return [];
         }
         return values.filter(value => {
            return value.tipo === 2; // 2 - Proprietario
         });
    }
}
