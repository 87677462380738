
import { throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Http, XHRBackend, RequestOptions, Request, RequestOptionsArgs } from '@angular/http';

import { AuthService } from '../auth/auth.service';
import { DefaultRequestOptionsService } from './default-request-options.service';
import { catchError } from 'rxjs/operators';


export function customHttpFactory(backend: XHRBackend, options: RequestOptions,
                                  defaultRequestOptionsService: DefaultRequestOptionsService, authService: AuthService) {
    return new CustomHttp(backend, options, defaultRequestOptionsService, authService);
}

/**
 * Service criado para facilitar o envio das requisições com o JWT de autenticação. Com ele não precisamos
 * em todas as requisições que fizermos ficar adicionando o token no header da requisição.
 * Esse service extende o Http nativo do Angular e possui os mesmos métodos e a mesma lógica de trabalhar.
 * Na mairia dos casos será ele utilizado para fazer os requests.
 *
 * Foi criado com o nome "CustomHttp" para deixar ainda disponível o service padrão do Angular caso for necessário utiliza-lo.
 */
@Injectable()
export class CustomHttp extends Http {

    constructor(backend: XHRBackend,
                defaultOptions: RequestOptions,
                private defaultRequestOptionsService: DefaultRequestOptionsService,
                private authService: AuthService) {

        super(backend, defaultOptions);
    }

    request(url: string | Request, options?: RequestOptionsArgs): Observable<any> {
        return super.request(url, options);
    }

    get(url: string, options?: RequestOptionsArgs): Observable<any> {
        return super.get(url, this.defaultRequestOptionsService.merge(options))
                    .pipe(catchError(this.catchAuthError(this)));
    }

    post(url: string, body: any, options?: RequestOptionsArgs): Observable<any> {
        return super.post(url, body, this.defaultRequestOptionsService.merge(options))
                    .pipe(catchError(this.catchAuthError(this)));
    }

    put(url: string, body: any, options?: RequestOptionsArgs): Observable<any> {
        return super.put(url, body, this.defaultRequestOptionsService.merge(options))
                    .pipe(catchError(this.catchAuthError(this)));
    }

    delete(url: string, options?: RequestOptionsArgs): Observable<any> {
        return super.delete(url, this.defaultRequestOptionsService.merge(options))
                    .pipe(catchError(this.catchAuthError(this)));
    }

    patch(url: string, body: any, options?: RequestOptionsArgs): Observable<any> {
        return super.patch(url, body, this.defaultRequestOptionsService.merge(options))
                    .pipe(catchError(this.catchAuthError(this)));
    }

    head(url: string, options?: RequestOptionsArgs): Observable<any> {
        return super.head(url, this.defaultRequestOptionsService.merge(options))
                    .pipe(catchError(this.catchAuthError(this)));
    }

    options(url: string, options?: RequestOptionsArgs): Observable<any> {
        return super.options(url, this.defaultRequestOptionsService.merge(options))
                    .pipe(catchError(this.catchAuthError(this)));
    }

    private catchAuthError (self: CustomHttp) {
        return (error: any) => {
            // Se for um erro de autenticação, voltamos a tela de login
            if (error.status === 401) {
                // if (error.status === 401 || error.status === 403) {
                this.authService.logout();
            }
            return observableThrowError(error);
        };
    }

}
