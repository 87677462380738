
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';
import { map, catchError } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { ConfigService, CustomHttp, Crud, CrudService } from '../../../../../core';
import { AcessoCondominio } from '../../../../../models';
@Injectable()
export class AcessosCondominioHttpService implements Crud<AcessoCondominio> {
    resource = 'acessoscondominios';
    baseUrl: string;

    constructor(private crudService: CrudService,
                private customHttp: CustomHttp,
                private configService: ConfigService) {
        this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
    }

    get(id: number): Observable<AcessoCondominio> {
        return this.crudService.get<AcessoCondominio>(this.resource, id);
    }

    post(entity: AcessoCondominio): Observable<AcessoCondominio> {
        return this.crudService.post<AcessoCondominio>(this.resource, entity);
    }

    put(entity: AcessoCondominio): Observable<AcessoCondominio> {
        return this.crudService.put<AcessoCondominio>(this.resource, entity);
    }

    delete(entity: AcessoCondominio): Observable<string> {
        return this.crudService.delete<AcessoCondominio>(this.resource, entity);
    }

    getAll(): Observable<AcessoCondominio[]> {
        return this.crudService.getAll<AcessoCondominio>(this.resource);
    }

    getAllByUsuarioAndCliente(idUsuario: number, idCliente: number): Observable<AcessoCondominio[]> {
        return this.customHttp.get(`${this.baseUrl}/consultar/cliente/${idCliente}/usuario/${idUsuario}`)
                    .pipe(
                        map(response => response.json()),
                        catchError(error => _throw(error))
                    );
    }

    getAllByUsuario(idUsuario: number): Observable<AcessoCondominio[]> {
        return this.customHttp.get(`${this.baseUrl}/consultar/usuario/${idUsuario}`)
                    .pipe(
                        map(response => response.json()),
                        catchError(error => _throw(error))
                    );
    }

    getAllWithoutAccessByUsuarioAndCliente(idUsuario: number, idCliente: number): Observable<AcessoCondominio[]> {
        return this.customHttp.get(`${this.baseUrl}/consultar/naotemacesso/cliente/${idCliente}/usuario/${idUsuario}`)
                    .pipe(
                        map(response => response.json()),
                        catchError(error => _throw(error))
                    );
    }

    getAllWithoutAccessByUsuario(idUsuario: number): Observable<AcessoCondominio[]> {
        return this.customHttp.get(`${this.baseUrl}/consultar/naotemacesso/usuario/${idUsuario}`)
                    .pipe(
                        map(response => response.json()),
                        catchError(error => _throw(error))
                    );
    }

    getAllByNomeUsuario(nome: string): Observable<AcessoCondominio[]> {
        return this.customHttp.get(`${this.baseUrl}/consultar/usuario/nome/${nome}`)
                    .pipe(
                        map(response => response.json()),
                        catchError(error => _throw(error))
                    );
    }
    

}

