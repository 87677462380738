import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { PermissionService } from 'app/core/services/permission/permission.service';
import { BaseCadastroComponent } from 'app/componentes-base';
import { CertidaoNegativaDebito, Bloco, Unidade } from 'app/models';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService, AuthService } from 'app/core';
import { CertidaoNegativaDebitoHttpService } from '../services';
import { RelatorioModalComponent } from 'app/home/relatorios/relatorio-modal';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { isNull } from 'util';
import { NgxSpinnerService } from 'ngx-spinner';
import { BlocosHttpService, BlocosPesquisaModalComponent, UnidadesHttpService, UnidadesPesquisaModalComponent } from 'app/home/blocos';

declare var jQuery: any;

@Component({
  selector: 'certidao-negativa-debito-cadastro',
  templateUrl: './certidao-negativa-debito-cadastro.component.html',
  styleUrls: ['./certidao-negativa-debito-cadastro.component.css'],
  providers: [PermissionService]
})
export class CertidaoNegativaDebitoCadastroComponent extends BaseCadastroComponent<CertidaoNegativaDebito> implements OnInit {

  public id_cliente: number;
  public id_condominio: number;
  public blocos: Bloco[] = [];
  public unidades: Unidade[] = [];

  @ViewChild('inputBloco') inputBloco: ElementRef;
  @ViewChild('blocosPesquisaModal') blocosPesquisaModal: BlocosPesquisaModalComponent;
  @ViewChild('inputUnidade') inputUnidade: ElementRef;
  @ViewChild('unidadesPesquisaModal') unidadesPesquisaModal: UnidadesPesquisaModalComponent;
  @ViewChild('relatorioModal') relatorioModal: RelatorioModalComponent;

  constructor(public activatedRoute: ActivatedRoute,
      public router: Router,
      public messageService: MessageService,
      public permissionService: PermissionService,
      private authService: AuthService,
      private blocosHttpService: BlocosHttpService,
      private unidadesHttpService: UnidadesHttpService,
      private certidaoNegativaDebitoHttpService: CertidaoNegativaDebitoHttpService,
      private spinner: NgxSpinnerService) {
    super(CertidaoNegativaDebito, certidaoNegativaDebitoHttpService, activatedRoute, router, messageService, permissionService);
  }

  ngOnInit() {
    this.id_condominio = this.authService.getIdCurrentCondominio();
    this.id_cliente = this.authService.getIdCurrentCliente();
    this.getBlocosByCondominio();
    super.ngOnInit();
  }

  /**
   * Carrega os blocos do Condomínio
   */
  private getBlocosByCondominio(): void {
    this.blocos = [];
    this.blocosHttpService.getAllByCondominio(this.id_condominio).subscribe(
      entities => {
        this.blocos = entities;
        if (entities.length === 1) this.setBloco(entities[0])
      },
      error => this.messageService.error('Erro ao carregar os blocos do condomínio. Tente novamente', 'Erro', error)
    );
  }

  /**
   * Atribui valor do bloco
   * @param blocoSelecionado Bloco selecionado
   */
  private setBloco(blocoSelecionado: Bloco): void {
    this.entity.bloco = blocoSelecionado;
    this.getAllUnidadesByBloco(this.entity.bloco.id);
    this.onCleanUnidade(this.inputUnidade.nativeElement);
  }

  /**
   * Busca todas as unidade de um bloco
   * @param idBloco 
   */
  public getAllUnidadesByBloco(idBloco: number): void {
    this.unidades = [];
    this.unidadesHttpService.getAllByBloco(idBloco).subscribe(
      entities => {
        for (const unidade of entities) {
          if (unidade.nome) {
            this.unidades.push(unidade);
          }
        }
      },
      error => this.messageService.error('Erro ao carregar as unidades do bloco. Tente novamente', 'Erro', error)
    );
  }

  public onSelectBloco(tm: TypeaheadMatch): void {
    this.setBloco(tm.item as Bloco);
  }

  /**
   * Limpa campo de Unidades
   * @param input Objeto do elemento
   */
  public onCleanUnidade(input: any): void {
    this.entity.id_unidade = null;
    this.entity.unidade = null;
    input.value = '';
    input.focus();
  }

  private setUnidade(unidadeSelecionada: Unidade): void {
    this.entity.id_unidade = unidadeSelecionada.id;
    this.entity.unidade = unidadeSelecionada;
  }

  public onSelectUnidade(tm: TypeaheadMatch): void {
    this.setUnidade(tm.item as Unidade);
  }

  public openBlocosPequisaModal() {
    this.blocosPesquisaModal.open();
  }

  public onSelectBlocosPesquisaModal(bloco: Bloco) {
    if (bloco) {
      this.setBloco(bloco)
    }
    jQuery('#bloco').focus();
  }

  public openUnidadesPequisaModal(): void {
    this.unidadesPesquisaModal.open();
  }

  public onSelectUnidadesPesquisaModal(unidade: Unidade): void {
    if (unidade) { this.setUnidade(unidade); }
    jQuery('#unidade').focus();
  }

  public onCleanBloco(input: any): void {
    this.onCleanUnidade(this.inputUnidade.nativeElement);
    this.entity.bloco = null;
    input.value = '';
    input.focus();
  }

  /**
   * Faz emissão da CNDC
   * @author Marcos Frare
   * @since 30/012020
   */
  public emitir() {
    const bloco_id = this.entity.bloco ? this.entity.bloco.id || null : null;
    const unidade_id = this.entity.unidade ? this.entity.unidade.id || null : null;

    if (isNull(bloco_id)) {
      this.messageService.warning('Campos bloco e unidade são obrigatórios!', 'Atenção!');
      return;
    }
    if (isNull(unidade_id)) {
      this.messageService.warning('Campo unidade é obrigatório!', 'Atenção!');
      return;
    }
    
    this.spinner.show();
    // Valida unidade na base de dados
    this.entity.id_usuario = this.authService.getIdCurrentUser();
    this.certidaoNegativaDebitoHttpService.validar(this.entity).subscribe(
      response => {
        this.spinner.hide();
        const params = {
          id_condominio: this.id_condominio,
          id_usuario: this.entity.id_usuario,
          id_unidade: this.entity.unidade.id,
          id: response.id
        }
        this.relatorioModal.url = '/certidaonegativadebito/emitir';
        this.relatorioModal.params = params;
        this.relatorioModal.open();
      },
      error => {
        this.spinner.hide();
        this.messageService.error('Erro ao emitir CNDC!', 'Erro', error);
      }
    );
  }

}