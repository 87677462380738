import { Injectable } from '@angular/core';
import { AuthService, ConfigService, Crud, CrudService, CustomHttp } from 'app/core';
import { Recibo } from 'app/models';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs-compat/observable/throw';
import { catchError, map } from 'rxjs/operators';
import { ReciboGeracaoFiltro } from '../recibo-geracao-modal/recibo-geracao-filtro';

@Injectable({
  providedIn: 'root'
})
export class ReciboHttpService implements Crud<Recibo> {

  public resource = 'recibo';
  private baseUrl: string;

  constructor(private customHttp: CustomHttp,
      private crudService: CrudService,
      private configService: ConfigService,
      private authService: AuthService) {
    this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
  }

  public get(id: number): Observable<Recibo> {
    return this.crudService.get<Recibo>(this.resource, id);
  }

  public getAll(): Observable<Recibo[]> {
    return this.crudService.getAll<Recibo>(this.resource, {params:{id_condominio: this.authService.getIdCurrentCondominio(), id_cliente: this.authService.getIdCurrentCliente()}});
  }

  public post(entity: Recibo): Observable<Recibo> {
    return this.crudService.post<Recibo>(this.resource, entity);
  }

  public put(entity: Recibo): Observable<Recibo> {
    return this.crudService.put<Recibo>(this.resource, entity);
  }

  public delete(entity: Recibo): Observable<string> {
    return this.crudService.delete<Recibo>(this.resource, entity);
  }

  public getAllByParams(params: any): Observable<Recibo[]> {
		return this.customHttp.get(`${this.baseUrl}`, {params}).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
	}

  public getPagamentosGerarReciboByParams(params: ReciboGeracaoFiltro): Observable<any> {
    return this.customHttp.get(`${this.baseUrl}/pagamentos-gerar-recibo`, {params}).pipe(
        map(response => response.json()),
        catchError(error => _throw(error))
      );
  }

  public gerarRecibos(ids: number[]): Observable<any> {
    return this.customHttp.post(`${this.baseUrl}/gerar-recibos`, {ids}).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
  }
}
