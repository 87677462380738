import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';

import { Crud, CrudService, ConfigService, CustomHttp } from '../../../core';
import { Orcamento } from '../../../models';

@Injectable()
export class OrcamentosHttpService implements Crud<Orcamento> {
	resource = 'orcamento';
	private baseUrl: string;

	constructor(private customHttp: CustomHttp,
		private crudService: CrudService,
		private configService: ConfigService) {
		this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
	}

	get(id: number): Observable<Orcamento> {
		return this.crudService.get<Orcamento>(this.resource, id);
	}

	getAll(): Observable<Orcamento[]> {
		return this.crudService.getAll<Orcamento>(this.resource);
	}

	post(entity: Orcamento): Observable<Orcamento> {
		return this.crudService.post<Orcamento>(this.resource, entity);
	}

	put(entity: Orcamento): Observable<Orcamento> {
		return this.crudService.put<Orcamento>(this.resource, entity);
	}

	delete(entity: Orcamento): Observable<string> {
		return this.crudService.delete<Orcamento>(this.resource, entity);
	}

	getAllByCondominio(idCondominio: number): Observable<Orcamento[]> {
		return this.customHttp.get(`${this.baseUrl}/consultar/condominio/${idCondominio}`)
			.pipe(
				map(response => response.json()),
				catchError(error => _throw(error))
			);
	}

	search(params: any): Observable<Orcamento[]> {
		return this.customHttp.get(`${this.baseUrl}/consultar`, { params })
			.pipe(
				map(response => response.json()),
				catchError(error => _throw(error))
			);
	}

}
