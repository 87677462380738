import { NgModule } from '@angular/core';
import { HomeComponent } from './home.component';

import { ArquivosRetornoModule } from './arquivos-retorno';
import { ArquivosRemessaModule } from './arquivos-remessa';
import { ComunicacoesModule } from './comunicacoes';
import { BalanceteRelModule } from './relatorios/balancete';
import { BlocosModule } from './blocos';
import { BoletosModule } from './boletos';
import { FormulariosModule } from './formularios';
import { ClientesModule } from './clientes';
import { CondominiosConfiguracaoModule } from './condominios-configuracao';
import { CondominiosConselhoModule } from './condominios-conselho';
import { CondominiosModule } from './condominios';
import { ConsumosUnidadesModule } from './consumos-unidades';
import { ContasBancariasModule } from './contas-bancarias';
import { DashboardModule } from './dashboard';
import { DemonstrativoRelModule } from './relatorios/demonstrativo';
import { EspelhoBoletoRelModule } from './relatorios/espelho-boleto';
import { FooterModule } from './common/footer/footer.module';
import { FornecedoresModule } from './fornecedores';
import { GruposDeUsuariosModule } from './grupos-de-usuarios';
import { InadimplenciaRelModule } from './relatorios/inadimplencia';
import { InventariosModule } from './inventarios';
import { LancamentosCondominioModule } from './lancamentos-condominio';
import { LancamentosEspecificosModule } from './lancamentos-especificos';
import { LancamentosUnidadesModule } from './lancamentos-unidades';
import { MyAccountModule } from './my-account/my-account.module';
import { NavigationModule } from './common/navigation/navigation.module';
import { OcorrenciasModule } from './ocorrencias';
import { OrcamentosModule } from './orcamentos';
import { PagamentosModule } from './pagamentos';
import { PlanoDeContasModule } from './plano-de-contas';
import { RateiosModule } from './rateios';
import { RenegociacoesRelModule } from './relatorios/renegociacoes';
import { RelatoriosSharedModule } from './relatorios/shared';
import { RouterModule } from '@angular/router';
import { SegurosModule } from './seguros';
import { TiposConsumoModule } from './tipos-consumo';
import { TopnavbarModule } from './common/topnavbar/topnavbar.module';
import { UsuariosModule } from './usuarios';
import { LogoClienteModule } from './logo-cliente';
import { DocumentosModule } from './documentos';
import { LocalModule } from './local';
import { TitulosRelModule } from './relatorios/titulos';
import { ReservaAreaModule } from './reserva-area';
import { ConciliacaoBancariaRelModule, ConsumosUnidadesRelModule, PrevisaoPorFracaoRelModule, AnaliticoRelModule, ComparativoFinanceiroRelModule, CreditosLancamentosRelModule, RazaoRelModule, LancamentosCategoriaContaRelModule, HistoricoMovimentacaoMensalRelModule, RelatorioReservasModule, NotificacaoModule, CertidaoNegativaDebitoModule, ConsumosRelModule, ContasAPagarRelModule, CondominosRelModule, ProtocoloRelModule, AntecipacaoTaxaCondominioRelModule, RelatorioCobrancaModule, ChequeRelatorioModule, EnvioEmailRelatorioModule, ComparativoPrevisaoReceitaDespesaRelModule } from 'app/home/relatorios';
import { PrevisaoOrcamentariaModule } from './previsao-orcamentaria';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LivroModule } from './livro';
import { RelatorioModule } from './relatorio';
import { BancoModule } from './banco';
import { ConfiguracaoEmailClienteModule } from './configuracao-email-cliente';
import { VeiculoModule } from './veiculo';
import { TipoContatoModule } from './tipo-contato';
import { ReservaBloqueioModule } from './reserva-bloqueio';
import { CobrancaAvulsaModule } from './cobranca-avulsa';
import { ReciboModule } from './recibo';
import { FundosMovimentacaoRelModule } from './relatorios/fundos-movimentacao';
import { CertificadoDigitalModule } from './certificado-digital';

@NgModule({
	declarations: [
		HomeComponent
	],
	imports: [
		AnaliticoRelModule,
		ArquivosRetornoModule,
		ArquivosRemessaModule,
		BancoModule,
		ComunicacoesModule,
		BalanceteRelModule,
		BlocosModule,
		BoletosModule,
		FormulariosModule,
		DocumentosModule,
		ClientesModule,
		ComparativoFinanceiroRelModule,
		ConciliacaoBancariaRelModule,
		CondominiosConfiguracaoModule,
		LogoClienteModule,
		CondominiosConselhoModule,
		CondominiosModule,
		ConsumosUnidadesModule,
		ConsumosUnidadesRelModule,
		ContasBancariasModule,
		CreditosLancamentosRelModule,
		DashboardModule,
		DemonstrativoRelModule,
		EspelhoBoletoRelModule,
		FooterModule,
		FornecedoresModule,
		GruposDeUsuariosModule,
		InadimplenciaRelModule,
		TitulosRelModule,
		InventariosModule,
		LancamentosCondominioModule,
		LancamentosEspecificosModule,
		LancamentosUnidadesModule,
		MyAccountModule,
		NavigationModule,
		OcorrenciasModule,
		OrcamentosModule,
		PagamentosModule,
		PlanoDeContasModule,
		PrevisaoPorFracaoRelModule,
		RateiosModule,
		RenegociacoesRelModule,
		RelatoriosSharedModule,
		RouterModule,
		SegurosModule,
		TiposConsumoModule,
		TopnavbarModule,
		UsuariosModule,
		LocalModule,
		LivroModule,
		ReservaAreaModule,
		NotificacaoModule,
		CertidaoNegativaDebitoModule,
		RelatorioReservasModule,
		ConsumosRelModule,
		ContasAPagarRelModule,
		PrevisaoOrcamentariaModule,
		RazaoRelModule,
		LancamentosCategoriaContaRelModule,
		HistoricoMovimentacaoMensalRelModule,
		CondominosRelModule,
		RelatorioModule,
		ConfiguracaoEmailClienteModule,
		VeiculoModule,
		TipoContatoModule,
		NgxSpinnerModule,
		ProtocoloRelModule,
		ReservaBloqueioModule,
		CobrancaAvulsaModule,
		AntecipacaoTaxaCondominioRelModule,
		RelatorioCobrancaModule,
		ChequeRelatorioModule,
		ReciboModule,
		EnvioEmailRelatorioModule,
		ComparativoPrevisaoReceitaDespesaRelModule,
		FundosMovimentacaoRelModule,
		CertificadoDigitalModule,
	]
})
export class HomeModule { }