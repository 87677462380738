export class ChequeRelatorioFiltro {
	id_cliente: number;
	id_condominio: number;
	id_usuario: number;
	status: string;
	data_emissao_inicial: Date;
	data_emissao_final: Date;
	data_compensacao_inicial: Date;
	data_compensacao_final: Date;

	constructor() {
		this.limpar();
	}

	/** Limpa o filtro */
	limpar() {
		const today = new Date();
		this.data_emissao_inicial = new Date(today.getFullYear(), today.getMonth(), 1); // primeiro dia do mês passado
		this.data_emissao_final = new Date(today.getFullYear(), today.getMonth() + 1, 0); // primeiro dia do mês passado
		this.data_compensacao_inicial = null;
		this.data_compensacao_final = null;
		this.status = '';
	}

}