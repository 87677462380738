import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared';
import { EnderecoComponent } from './endereco.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { EnderecoHttpService } from './services';
import { CidadeHttpService } from '../cidade';
import { CondominiosHttpService } from '../../home/condominios/services/condominios-http.service';

@NgModule({
	imports: [
		SharedModule,
		TypeaheadModule
	],
	declarations: [
		EnderecoComponent
	],
	exports: [
		EnderecoComponent
	],
	providers: [
		EnderecoHttpService,
		CidadeHttpService,
		CondominiosHttpService
	]
})
export class EnderecoModule { }