import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit, ChangeDetectorRef } from '@angular/core';

import { AuthService, MessageService } from 'app/core';
import { CategoriaContasHttpService } from './services/categoria-contas-http.service';
import { UtilsCategoriaContasService } from './services/utils-categoria-contas.service';

import { CategoriaContas, tiposDivisaoRateio, CategoriaContaGrupo } from 'app/models';
import { FloatFormatPipe } from 'app/shared/pipes/number-format';
import { Permission } from 'app/componentes-base';

declare var jQuery: any;

@Component({
	selector: 'categoria-contas',
	templateUrl: './categoria-contas.component.html',
	styleUrls: ['./categoria-contas.component.css'],
	providers: [FloatFormatPipe]
})
export class CategoriaContasComponent implements OnInit, AfterViewInit {

	@Input() entity: CategoriaContas;
	@Input() gruposConta: CategoriaContaGrupo[];
	@Input() permiteSalvarCategoria: boolean;
	@Input() permiteCriarCategoriaIrma: boolean;
	@Input() permiteCadastrarSaldo: number;
	@Input() permiteAtivarDesativarCategoria: boolean;
	@Input() condominio: number;
	@Input() codigoCategoriaPai: string;
	@Input() proximaSequencia: number;
	@Input() carregarCategoriasFilhas: boolean;
	@Input() permissions: Permission;

	@Output() createCategoriaIrma = new EventEmitter();
	@Output() onDelete = new EventEmitter<CategoriaContas>();
	@Output() onSave: EventEmitter<CategoriaContas> = new EventEmitter();

	tiposDivisaoRateio = tiposDivisaoRateio;
	btnSave: any;
	public idCondominio: number;

	constructor(private utils: UtilsCategoriaContasService,
		private authService: AuthService,
		private categoriaContasHttpService: CategoriaContasHttpService,
		private floatFormatPipe: FloatFormatPipe,
		private cdRef: ChangeDetectorRef,
		private messageService: MessageService) {
	}

	ngOnInit() {
		this.idCondominio = this.authService.getIdCurrentCondominio();
		this.condominio = this.idCondominio;
		this.transformSaldo(this.entity);
	}

	ngAfterViewInit() {

	}

	// Carrega as categorias filhas da categoria
	private getFilhos() {
		this.categoriaContasHttpService.getFilhos(this.entity.id).subscribe(
			entities => {
				this.entity.categoria_contas_filhas = entities;
				this.cdRef.detectChanges(); // Chama a detecção de mudanças para verificar as categorias filhas recebidas
			},
			error => this.messageService.error('Erro ao carregar as categorias filhas. Tente novamente.', 'Erro', error)
		);
	}

	save() {
		this.btnSave = jQuery('#btnSave');
		this.btnSave.button('loading');
		this.entity.editado = false;
		const envio: any = { ...this.entity };
		if (this.entity.id) {
			this.categoriaContasHttpService.put(envio).subscribe(
				entity => {
					this.onSave.emit(entity);
					this.btnSave.button('reset');
					this.messageService.success('Salvo com sucesso');
					this.closeSaldoModal();
				},
				error => {
					this.messageService.error('Erro ao atualizar a categoria de contas. Tente novamente', 'Erro', error);
					this.btnSave.button('reset');
				}
			);
		} else {
			this.categoriaContasHttpService.post(envio).subscribe(
				entity => {
					this.entity.id = entity.id;
					this.entity.categoria_contas_filhas = [];
					this.onSave.emit(entity);
					this.btnSave.button('reset');
					this.messageService.success('Salvo com sucesso');
					this.closeSaldoModal();
				},
				error => this.messageService.error('Erro ao salvar a categoria de contas. Tente novamente', 'Erro', error)
			);
		}
	}

	createCategoriaFilha() {
		const categoriaFilha = new CategoriaContas();
		categoriaFilha.codigo = this.utils.gerarCodigo(this.codigoCategoriaPai, this.proximaSequencia);
		categoriaFilha.id_plano_de_conta = this.entity.id_plano_de_conta;
		categoriaFilha.id_categoria_conta_pai = this.entity.id;
		categoriaFilha.tipo_conta = this.entity.tipo_conta;
		this.entity.categoria_contas_filhas.push(categoriaFilha);
	}

	createCategoriaIrmaEmit() {
		this.createCategoriaIrma.emit();
	}

	// Cria uma categoria "irmã" da CategoriaConta que emitiu o evento, para a CategoriaConta
	// que está recebendo o evento seria para criar uma categoria "filha".
	onCreateCategoriaIrma() {
		this.createCategoriaFilha();
	}

	public delete(): void {
		if (this.entity.id) {
			if (confirm(`Realmente deseja exlcuir a categoria de conta ${this.entity.nome} ?`)) {
				this.onDelete.emit(this.entity);
			}
		} else {
			this.onDelete.emit(this.entity);
		}
	}

	onDeleteCategoria(categoriaConta: CategoriaContas) {
		const index: number = this.entity.categoria_contas_filhas.indexOf(categoriaConta);
		const isNew: boolean = !categoriaConta.id;
		if (index > -1) {
			if (isNew) {
				this.entity.categoria_contas_filhas.splice(index, 1);
			} else {
				this.categoriaContasHttpService.delete(categoriaConta).subscribe(
					() => {
						this.entity.categoria_contas_filhas.splice(index, 1);
						this.messageService.success('Categoria de conta excluída com sucesso', 'Sucesso');
					},
					error => {
						if (error.status === 403) {
							this.messageService.error(`Não foi possível excluir a categoria de conta ${categoriaConta.nome}, pois a mesma está sendo utilizada por algum processo do sistema.`, 'Erro', error);
						} else {
							this.messageService.error('Erro ao excluir categoria de conta, contate o administrador do sistema.', 'Erro', error);
						}
					}
				);
			}
		}
	}

	getProximaSequenciaCategoria(categoriaConta: CategoriaContas) {
		if (!categoriaConta.categoria_contas_filhas) {
			return 1;
		}
		const lastIndex: number = categoriaConta.categoria_contas_filhas.length - 1;
		if (lastIndex < 0) {
			return 1;
		}
		const lastCategoria: CategoriaContas = categoriaConta.categoria_contas_filhas[lastIndex];
		const codigos: string[] = lastCategoria.codigo.split('.');
		const sequencia: number = Number(codigos[codigos.length - 1]) + 1;
		return sequencia;
	}

	onChangeNome() {
		this.entity.editado = true;
	}

	// Ativar/Desativa uma categoria de contas
	onChangeAtivo(ativo: boolean) {
		if (this.entity.id) {
			this.categoriaContasHttpService.ativaDesativa(this.entity.id, ativo).subscribe(
				response => { },
				error => this.messageService.error('Erro ao alterar a situação Ativo/Inativo da categoria de contas. Tente novamente', 'Erro', error)
			);
		}
	}

	onSaveCategoriaFilha(categoriaConta: CategoriaContas): void {
		this.onSave.emit(categoriaConta);
	}

	public openSaldoModal(): void {
		jQuery('#saldoModal' + this.entity.id).modal('show');
	}

	public closeSaldoModal(): void {
		jQuery('#saldoModal' + this.entity.id).modal('hide');
	}

	public transformSaldo(entity: CategoriaContas): void {
		entity.saldo = this.floatFormatPipe.transform(entity.saldo);
	}
}