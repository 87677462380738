import { BaseEntity } from '../base-entity';
import { ContaBancaria } from './conta-bancaria';
import { Extrato } from './extrato';
import { ExtratoBanco } from './extrato-banco';

export class Conciliacao extends BaseEntity {
	id_conta_bancaria: number;
	id_usuario: number;
	sistema: boolean;
	hash: string;

	contaBancaria: ContaBancaria;
	extratos: Extrato[];
	extratosBanco: ExtratoBanco[];
}

export class Resumo {
	qtdConciliado: number;
	qtdCreditoBanco: number;
	qtdCreditoSistema: number;
	qtdDebitoBanco: number;
	qtdDebitoSistema: number;
	valorConciliado: number;
	valorCreditoBanco: number;
	valorCreditoSistema: number;
	valorDebitoBanco: number;
	valorDebitoSistema: number;
}