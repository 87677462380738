import { BaseEntity } from '../base-entity';
import { CategoriaContas } from './categoria-contas';

export class LancamentoEspecifico extends BaseEntity {
	nome: string;
	observacao: string;
	valor_total: any;
	parcelas: number;
	id_condominio: number;
	id_fornecedor: number;
	categoriaContas: CategoriaContas;
	id_categoria_conta: number;
	tipo_divisao_rateio: number;
	cobrar_tipo_unidade: number;
	pagamentos: any[];
	ids_unidades: number[];
	permite_edicao: boolean;
	para_proprietario: boolean;

	nome_categoria_conta: string;

	constructor() {
		super();
		this.pagamentos = [];
		this.ids_unidades = [];
		this.permite_edicao = true;
	}
}