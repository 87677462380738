import { BaseEntity } from '../base-entity';
import { Pagamento } from './pagamento';
import { Anexo } from './anexo';

export class PagamentoAnexo extends BaseEntity {
	id_pagamento: number;
	id_anexo: number;

	pagamento: Pagamento;
	anexo: Anexo;
}