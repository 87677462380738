import { BaseEntity } from '../base-entity';
import { Pagamento } from './pagamento';
import { Anexo } from './anexo';
import { DocumentoFiscal } from './documento-fiscal';
import { LancamentoCondominioItem } from './lancamento-condominio-item';

export class LancamentosCondominio extends BaseEntity {
	nome: string;
	complemento: string;
	nome_nao_rateado: string;
	id_condominio: number;
	id_fornecedor: number;
	tipo_despesa: string;
	anexos: Anexo[];
	valor_total: any;
	parcelas: number;
	observacao: string;
	fixo: boolean;
	pagamentos: Pagamento[];
	pago: boolean;
	selected: boolean;
	
	documentoFiscal: DocumentoFiscal;
	lancamentoCondominioItems: LancamentoCondominioItem[];

	open: boolean;

	constructor() {
		super();
		this.fixo = false;
		this.pagamentos = [];
		this.parcelas = 1;
		this.valor_total = 0;
		this.anexos = [new Anexo()];
		this.pago = false;
		this.documentoFiscal = new DocumentoFiscal();
		this.tipo_despesa = 'ORDINARIA';
		this.lancamentoCondominioItems = [new LancamentoCondominioItem()];
		this.open = false;
	}
}