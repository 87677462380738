import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared';

import { NotasLancamentosCondominioModalComponent } from './lancamentos-condominio';
import { NotasPagamentosModalComponent } from './pagamentos';

import { NotasHttpService } from './services';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        NotasLancamentosCondominioModalComponent,
        NotasPagamentosModalComponent
    ],
    exports: [
        NotasLancamentosCondominioModalComponent,
        NotasPagamentosModalComponent
    ],
    providers: [
        NotasHttpService
    ]
})
export class NotasModalModule { }
