import { BaseEntity } from '../base-entity';
import { Cidade } from './cidade';

export class Endereco extends BaseEntity {
	rua: string;
	numero: string;
	complemento: string;
	bairro: string;
	cep: string;
	id_unidade: number;
	id_cidade: number;

	cidade: Cidade;
}
