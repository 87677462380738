import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { MessageService, AuthService, notCloseMessageOptions } from 'app/core';
import { BoletosHttpService } from '../services';
import { DatePipe, Time } from '@angular/common';
import { Titulo, SITUACAO_BOLETO, Ocorrencia } from 'app/models';
import { NgForm } from '@angular/forms';
import { join } from 'path';

declare var jQuery: any;

@Component({
  selector: 'ocorrencia-titulo-modal',
  templateUrl: './ocorrencia-titulo-modal.component.html',
  styleUrls: ['./ocorrencia-titulo-modal.component.css']
})
export class OcorrenciaTituloModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @Input() id_titulo: number;
  @Input() id_unidade: number;

  @ViewChild('form') form: NgForm;

  public titulos: Titulo[] = [];
  public ocorrencias: Ocorrencia[] = [];
  public situacaoBoleto = SITUACAO_BOLETO;
  public allSelected = true;
  public entity: Ocorrencia;

  constructor(private authService: AuthService,
      private boletosHttpService: BoletosHttpService,
      private messageService: MessageService,
      private datePipe: DatePipe) {}

  ngOnInit() {
    this.entity = new Ocorrencia();
    if (!this.idModal) {
      this.idModal = 'renegociacaoModal';
    }
  }

  open() {
    this.entity = new Ocorrencia();
    this.buscaTitulosUnidade();
    jQuery(`#${this.idModal}`).modal('show');
  }
  close() {
    this.entity = new Ocorrencia();
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Marcar/desmarcar todos os títulos
   * @author Marcos Frare
   * @since 08/09/2020
   */
  public toggleAllSelected(): void {
    this.titulos.map(titulo => titulo.selected = this.allSelected);
  }

  /**
   * Verifica se todos os títulos estão setados
   * @author Marcos Frare
   * @since 08/09/2020
   */
  public checkAllSelected(): boolean {
    for (let titulo of this.titulos) {
      if (!titulo.selected) {
        return false;
      }
    }
    return true;
  }

  /**
   * Busca títulos aberto da unidade para seleção
   * @author Marcos Frare
   * @since 07/09/2020
   */
  private buscaTitulosUnidade(): void {
    this.titulos = [];
    this.ocorrencias = [];
    this.boletosHttpService.buscaTitulosAbertoUnidade(this.id_unidade).subscribe(
      response => {
        this.titulos = response.titulos;
        this.ocorrencias = response.ocorrencias;
        this.titulos.map(titulo => titulo.selected = true);
        this.ocorrencias.map(ocorrencia => {
          ocorrencia.numeros = (ocorrencia.titulos.map(item => item.numero_documento)).join(', ');
        });
      },
      error => this.messageService.error('Erro ao buscar os títulos da unidade. Tente novamente!', 'Erro', error)
    );
  }

  /**
   * Seleção com click na linha
   * @param titulo 
   * @author Marcos Frare
   * @since 08/09/2020
   */
  public lineCheckTitulo(titulo: Titulo): void {
    titulo.selected = !titulo.selected;
  }

  /**
   * Torna todos os campos do formulário "sujos" para exibir as mensagens de campos inválidos
   */
  private makeAllFieldsDirty() {
    for (const key in this.form.controls) {
      if (this.form.controls.hasOwnProperty(key)) {
        const control = this.form.controls[key];
        control.markAsDirty();
      }
    }
  }

  /**
   * Persistir os dados da ocorrência
   * @author Marcos Frare
   * @since 09/09/2020
   */
  public gravar(): void {
    this.entity.titulos = this.titulos.filter(titulo => titulo.selected);
    if (this.entity.titulos.length < 1) {
      this.messageService.warning('Necessário selecionar ao menos um título para ocorrência', 'Atenção!');
    } else {
      if (!this.form) {
        this.messageService.warning('É necessário criar uma variável de template com nome #form', 'Variável de template (#form) não encontrada', notCloseMessageOptions);
      }
      this.makeAllFieldsDirty();
      if (this.form.valid) {
        const btnSave = jQuery('#btnSave');
        btnSave.button('loading');
        this.boletosHttpService.gravarOcorrenciaTitulo(this.entity).subscribe(
          () => {
            btnSave.button('reset');
            this.buscaTitulosUnidade();
            this.entity = new Ocorrencia();
            this.messageService.info('Ocorrência gravada com sucesso!!', 'Info');
          },
          error => {
            btnSave.button('reset');
            this.messageService.error('Erro ao gravar ocorrência! Tente novamente.', 'Erro', error);
          }
        );
  
      } else {
        this.messageService.info('Para salvar é preciso informar corretamente todos os campos destacados em vermelho', 'Informação');
      }
    }
  }

  /**
   * Exclusão de uma ocorrência
   * @param ocorrencia 
   * @author Marcos Frare
   * @since 10/09/2020
   */
  public exclirOcorrencia(ocorrencia: Ocorrencia): void {
    if (confirm(`Deseja excluir a ocoência para os títulos ${ocorrencia.numeros}??`)) {
      this.boletosHttpService.excluirOcorrencia(ocorrencia).subscribe(
        () => {
          this.buscaTitulosUnidade();
          this.messageService.info('ocorrência excluída com sucesso!', 'Info');
        },
        error => this.messageService.error('Erro ao exlcuir ocorrência! Tente novamente.', 'Erro', error)
      );
    }
  }

}