import { Bloco } from './bloco';
import { BaseEntity } from '../base-entity';
import { Endereco } from './endereco';

export class Condominio extends BaseEntity {
	razao_social: string;
	nome_fantasia: string;
	cnpj: string;
	id_endereco: number;
	ativo: boolean;
	id_unidades: number;
	endereco: Endereco;

	blocos: Bloco[];
	quantidade_andar: number;
	quantidade_por_andar: number;
	tipo_unidade: number;
	id_grupo_de_usuario: number;
	id_cliente: number;

	constructor() {
		super();
		this.ativo = true;
		this.endereco = new Endereco();
		this.razao_social = '';
	}
}
