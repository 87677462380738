import { NgModule } from '@angular/core';
import { LancamentosCategoriaContaRelComponent } from './lancamentos-categoria-conta-rel.component';
import { SharedModule } from 'app/shared';
import { TypeaheadModule } from 'ngx-bootstrap';
import { PlanoDeContasModule } from 'app/home/plano-de-contas';
import { EasyUIModule } from 'ng-easyui';

@NgModule({
  imports: [
    SharedModule,
    TypeaheadModule,
    PlanoDeContasModule,
    EasyUIModule
  ],
  declarations: [
    LancamentosCategoriaContaRelComponent
  ]
})
export class LancamentosCategoriaContaRelModule { }