export class DemonstrativoRelFiltro {
	id_cliente: number;
	id_condominio: number;
	id_usuario: number;
	data_inicial: Date;
	data_final: Date;
	tipoFiltro: number;
	dataCompetencia: Date;
	showChamadaCapital: Boolean;
	showFundoReserva: Boolean;
	showClassificacao: Boolean;

	/** Limpa o filtro */
	limpar() {
		const today = new Date();
		this.data_inicial = new Date(today.getFullYear(), today.getMonth(), 1); // primeiro dia do mês
		this.data_final = new Date(today.getFullYear(), today.getMonth() + 1, 0); // último dia do mês
		this.dataCompetencia = new Date(today.getFullYear(), today.getMonth() - 1, 1); // Primeiro dia do mês anterior
		this.tipoFiltro = 1;
		this.showChamadaCapital = false;
		this.showFundoReserva = false;
		this.showClassificacao = false;
	}
}