import { Injectable } from '@angular/core';

import { MessageService } from '../../../../core';
import { TiposConsumoHistoricoHttpService } from './tipos-consumo-historico-http.service';

@Injectable()
export class TiposConsumoHistoricoCadastroService {

    constructor(private tiposConsumoHistoricoHttpService: TiposConsumoHistoricoHttpService,
                private messageService: MessageService) { }

}
