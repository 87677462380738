import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseCadastroComponent } from '../../../componentes-base';
import { AuthService, MessageService } from '../../../core';
import { DocumentosHttpService } from './../services';
import { PermissionService } from '../../../core/services/permission/permission.service';
import { Documento, DocumentoPasta } from 'app/models';
import { datepickerMesAnoOptions } from 'app/shared';
import { DocumentoPastaHttpService } from '../documento-pasta-modal';
import { Subscription } from 'rxjs';

@Component({
	selector: 'documentos',
	templateUrl: 'documentos-cadastro.component.html',
	providers: [
		PermissionService
	]
})
export class DocumentosCadastroComponent extends BaseCadastroComponent<Documento> implements OnInit, OnDestroy {

	public isCondominoUser: boolean;
	public subs: Subscription[] = [];

	public pastas: DocumentoPasta[] = [];

	public datepickerDataCompetenciaOptions = datepickerMesAnoOptions;

	constructor(public activatedRoute: ActivatedRoute,
			public router: Router,
			public messageService: MessageService,
			public documentosHttpService: DocumentosHttpService,
			private documentoPastaHttpService: DocumentoPastaHttpService,
			public permissionService: PermissionService,
			private authService: AuthService) {
		super(Documento, documentosHttpService, activatedRoute, router, messageService, permissionService);
		this.isCondominoUser = this.authService.getTipoCurrentUser() === 3;
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.getAllPastas();
	}

	ngOnDestroy(): void {
		try {
			this.subs.map(s => s.unsubscribe());
		} catch(err) {
			console.error(err);
		}
	}

	public afterInsert(): void {
		this.entity.id_condominio = this.authService.getIdCurrentCondominio();
	}

	public getAllPastas(): void {
		this.pastas = [];
		this.subs.push(this.documentoPastaHttpService.getAllByCondominio(this.authService.getIdCurrentCondominio()).subscribe(
			res => this.pastas = res,
			error => this.messageService.error('Erro ao buscar as pastas do condomínio! Tente novamente', 'Erro', error)
		));
	}

}
