import { BaseEntity } from '../base-entity';
import { Anexo } from './anexo';
import { Cheque } from './cheque';

export const tipoPagamento = [
	{ id: 1, nome: 'TED' },
	{ id: 2, nome: 'Cheque' },
	{ id: 3, nome: 'Em Espécie' },
	{ id: 4, nome: 'Débito em conta' },
	{ id: 5, nome: 'Depósito em Conta' },
	{ id: 6, nome: 'Cartão de Crédito' },
	{ id: 7, nome: 'Cartão de Débito' },
	{ id: 8, nome: 'Pix' },
];

export const tipoRateio = [
	{ id: 4, nome: 'Categoria de conta não rateada' },
	{ id: 2, nome: 'Não processado' },
	{ id: 1, nome: 'Processado' },
	{ id: 3, nome: 'Todos' },
	{ id: 5, nome: 'Previsão Orçamentária' }
];

export const sindicoAprovaPagamento = [
	{ id: 1, nome: 'Aprovou' },
	{ id: 2, nome: 'Pendente' },
	{ id: 3, nome: 'Não aprovou' }
];

export enum TYPES_PAGAMENTO { CONDOMINIO = 1, ESPECIFICO = 2, UNIDADE = 3 };

export enum SITUACAO_RATEIO { PROCESSADO = 1, NAO_PROCESSADO = 2, TODOS = 3, CATEGORIA_NAO_RATEADA = 4 };

export interface PagamentoItem {
	id: number;
	categoria_conta_codigo: string;
	categoria_conta_nome: string;
	complemento: string;
	valor: number;
}

export class Pagamento extends BaseEntity {
	valor: number;
	valor_pago: number;
	numero_parcela: number;
	data_vencimento: Date;
	data_pagamento: Date;
	id_lancamento_condominio: number;
	id_demonstrativo_rateio: number;
	tipo: number;
	sindico_aprova: number;
	sindico_aprova_nao_rateio: boolean;
	isSindicoAprova: boolean; // true = 1 - Aprovou
	// false = 2 - Pendente
	data_aprovacao_sindico: Date;
	ratear: boolean;
	multa: number;
	juros: number;
	id_conta_bancaria: number;
	tipo_divisao_rateio: number;
	cobrar_tipo_unidade: number;
	permite_exclusao: boolean;
	cobrar_fundo_reserva: boolean;
	id_cancelamento: number;
	lancamento_anexos: number;

	cheque: Cheque;

	anexos: Anexo[];

	updated: boolean;

	itens: PagamentoItem[];

	constructor() {
		super();
		this.tipo = 4; // Débito em Conta
		this.isSindicoAprova = false;
		this.ratear = true;
		this.updated = false;
		this.itens = [];
	}
}