import { CategoriaContas } from "app/models";

export class LancamentosCategoriaContaRelFiltro {
	id_cliente: number;
	id_condominio: number;
	id_usuario: number;
	tipoFiltro: number;
	competencia_inicial: Date;
	competencia_final: Date;
	data_inicial: Date;
	data_final: Date;
	id_categoria_conta: number;
	showComplemento: boolean;
	showObservacao: boolean;
	showFornecedor: boolean;

	categoriaConta: CategoriaContas;

	/** Limpa o filtro */
	limpar() {
		this.tipoFiltro = 1;
		const today = new Date();
		this.data_inicial = new Date(today.getFullYear(), today.getMonth(), 1); // primeiro dia do mês passado
		this.data_final = new Date(today.getFullYear(), today.getMonth() + 1, -1); // último dia do mês passado
		this.competencia_inicial = new Date(today.getFullYear(), today.getMonth(), 1);
		this.competencia_final = null;
		this.categoriaConta = null;
		this.showComplemento = false;
		this.showObservacao = false;
		this.showFornecedor = false;
	}
}