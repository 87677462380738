import { BaseEntity } from '../base-entity';
import { CategoriaContas } from './categoria-contas';
import { Telefone } from './telefone';

export class Fornecedor extends BaseEntity {
	nome: string;
	cpf: string;
	cnpj: string;
	email: string;
	telefones: Telefone[];

	id_categoria_conta: number;
	id_categoria_conta_nao_rateado: number;
	id_cliente: number;
	categoriaContas: CategoriaContas;
	categoriaContasNaoRateado: CategoriaContas;
	nome_categoria_conta: string;
	nome_contato: string;
	cpf_contato: string;
	data_nascimento_contato: Date;

	constructor() {
		super();
	}
}
