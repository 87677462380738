import { BaseEntity } from '../base-entity';
import { ContaBancaria } from './conta-bancaria';
import { Fornecedor } from './fornecedor';
import { CategoriaContas } from './categoria-contas';

export class ConfiguracaoTarifaBancaria extends BaseEntity {
	ativo: boolean;
	descricao: string;
	id_conta_bancaria: number;
	id_fornecedor: number;
	id_categoria_conta: number;
	tipo_divisao_rateio: number;
	id_categoria_conta_nao_rateado: number;
	cobrar_fundo_reserva: boolean;

	conta_bancaria: ContaBancaria;
	fornecedor: Fornecedor;
	categoriaConta: CategoriaContas;
	categoriaContaNaoRateado: CategoriaContas;
}