export class ComparativoFinanceiroRelFiltro {
	id_cliente: number;
	id_condominio: number;
	id_usuario: number;
	id_conta_bancaria: any;
	data_inicial: Date;
	data_final: Date;
	tipoFiltro: number;
	tipo_conta: number;
	competencia_inicial: Date;
	competencia_final: Date;
	showChamadaCapital: Boolean;
	showFundoReserva: Boolean;

	/** Limpa o filtro */
	limpar() {
		this.id_conta_bancaria = null;
		this.tipoFiltro = 1;
		this.tipo_conta = 0;
		const today = new Date();
		this.data_inicial = new Date(today.getFullYear(), today.getMonth() - 1, 1); // primeiro dia do mês passado
		this.data_final = new Date(today.getFullYear(), today.getMonth(), 0); // último dia do mês passado
		this.competencia_inicial = new Date(today.getFullYear(), today.getMonth() - 3, 1);
		this.competencia_final = new Date(today.getFullYear(), today.getMonth(), 0);
		this.showChamadaCapital = false;
		this.showFundoReserva = false;
	}

}