import { Injectable } from '@angular/core';
import { Headers, RequestOptions, RequestOptionsArgs } from '@angular/http';

import { JwtTokenService } from '../auth/jwt-token.service';

@Injectable()
export class DefaultRequestOptionsService extends RequestOptions {

    constructor(private jwtTokenService: JwtTokenService) {
        super();
    }

    /**
     * Realiza um merge dos headers default com os passados por parametro
     */
    merge(options?: RequestOptionsArgs): RequestOptions {
        options = options || new RequestOptions();

        const headers = options.headers || new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Authorization', `Bearer ${this.jwtTokenService.accessToken}`);
        options.headers = headers;

        return super.merge(options);
    }

}
