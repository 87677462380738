import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import { AuthService, MessageService } from 'app/core';
import { Bloco, Condominio, situacoesBoleto, tiposEnvioBoleto, Unidade } from 'app/models';
import { tiposFiltro, TitulosRelFiltro } from './titulos-rel-filtro';
import { datepickerMesAnoOptions } from 'app/shared';
import { BlocosHttpService, BlocosPesquisaModalComponent, UnidadesHttpService } from 'app/home/blocos';
import { RelatorioModalComponent } from '../relatorio-modal';

declare var jQuery: any;

@Component({
  selector: 'titulos-rel',
  templateUrl: './titulos-rel.component.html',
  styleUrls: ['./titulos-rel.component.css']
})

export class TitulosRelComponent implements OnInit {
  public title: string;
  public user: string;
  public date: string;
  public logoUrl: string;
  public condominio: Condominio;
  public unidades: Unidade[] = [];
  public blocos: Bloco[] = [];
  public filtro = new TitulosRelFiltro();
  public bloco: Bloco;
  public showInputBlocos: boolean = true;
  public baseUrl: string;
  public datepickerDataCompetenciaOptions = datepickerMesAnoOptions;
  public tiposFiltro = tiposFiltro;
  public situacaoBoleto = situacoesBoleto;
  public tiposEnvioBoleto = tiposEnvioBoleto;

  @ViewChild('inputBloco') inputBloco: ElementRef;
  @ViewChild('blocosPesquisaModal') blocosPesquisaModal: BlocosPesquisaModalComponent;
  @ViewChild('relatorioModal') relatorioModal: RelatorioModalComponent;

  multiselectSettings: IMultiSelectSettings = {
    enableSearch: true,
    containerClasses: 'container-multiselect',
    showUncheckAll: true,
    showCheckAll: true,
    dynamicTitleMaxItems: 3,
    checkedStyle: 'checkboxes',
    buttonClasses: 'btn btn-default',
    itemClasses: 'dropdown-menu'
  };

  multiselectOptionsUnidades: IMultiSelectOption[];
  multiselectTextsUnidades: IMultiSelectTexts = {
    checkAll: 'Selecionar todas',
    uncheckAll: 'Limpar',
    checked: 'selecionada',
    checkedPlural: 'selecionadas',
    searchPlaceholder: 'Pesquisar...',
    defaultTitle: 'Selecionar',
    allSelected: 'Todas selecionadas',
    searchEmptyResult: 'Nenhum registro encontrado',
    searchNoRenderText: 'Digite para pesquisar',
  };

  constructor(private authService: AuthService,
    private messageService: MessageService,
    private unidadesHttpService: UnidadesHttpService,
    private blocosHttpService: BlocosHttpService) { }

  ngOnInit() {
    this.setInitialFilter();
    this.filtro.id_cliente = this.authService.getIdCurrentCliente();
    this.filtro.id_condominio = this.authService.getIdCurrentCondominio();
    this.filtro.id_usuario = this.authService.getIdCurrentUser();
    this.getBlocosByCondominio(this.filtro.id_condominio);
  }

  onSelectBloco(tm: TypeaheadMatch): void {
    this.setBloco(tm.item as Bloco);
  }

  openBlocosPequisaModal() {
    this.blocosPesquisaModal.open();
  }

  public onSelectBlocosPesquisaModal(bloco: Bloco): void {
    if (bloco) this.setBloco(bloco);
    jQuery('#bloco').focus();
  }

  public setInitialFilter(): void {
    const today = new Date();
    this.filtro.dataInicial = new Date(today.getFullYear(), today.getMonth(), 1); // primeiro dia do mês
    this.filtro.dataFinal = new Date(today.getFullYear(), today.getMonth() + 1, 0); // último dia do mês
    this.filtro.tipoFiltro = 1;
    this.filtro.dataCompetenciaInicial = new Date(today.getFullYear(), today.getMonth(), 0);
    this.filtro.dataCompetenciaFinal = new Date(today.getFullYear(), today.getMonth(), 0);
    this.filtro.situacao = 0;
  }

  private getBlocosByCondominio(idCondominio: number) {
    this.blocosHttpService.getAllByCondominio(idCondominio).subscribe(
      entitites => {
        this.blocos = entitites
        if (this.blocoUnico(this.blocos)) {
          this.setBloco(this.blocos[0]);
          this.showInputBlocos = false;
        } else if (!this.blocos) {
          this.messageService.error('Não existem blocos cadastrados no Condomínio. Cadastre  um bloco e tente novamente', 'Erro')
        }
      },
      error => this.messageService.error('Erro ao carregar as unidades do condomínio. Tente novamente', 'Erro', error)
    );
  }

  private blocoUnico(blocos: Bloco[]): boolean {
    return blocos.length === 1;
  }

  private setBloco(blocoSelecionado: Bloco) {
    this.bloco = blocoSelecionado;
    this.getUnidadesByBloco(this.bloco.id);
    this.onCleanUnidades();
    this.filtro.id_bloco = this.bloco.id
  }

  private getUnidadesByBloco(idBloco: number) {
    this.filtro.unidades = [];
    this.multiselectOptionsUnidades = [];
    this.unidadesHttpService.getAllByBloco(idBloco).subscribe(
      entities => {
        if (entities) {
          const selects: IMultiSelectOption[] = [];
          entities.forEach(entity => {
            selects.push({
              id: entity.id,
              name: `${entity.nome_bloco} - ${entity.nome}`
            });
          });
          this.multiselectOptionsUnidades = selects;
        }
      },
      error => this.messageService.error('Erro ao carregar as unidades do condomínio. Tente novamente', 'Erro', error)
    );
  }

  onCleanUnidades() {
    this.filtro.unidades = [];
  }

  private validateView(): boolean {
    // Para situação diferentes de ABERTO ou VENCIDO, exige datas
    if (this.filtro.situacao !== 3 && this.filtro.situacao !== 1) {
      if (this.filtro.tipoFiltro === 1) {
        if (!this.filtro.dataCompetenciaInicial && !this.filtro.dataCompetenciaFinal) {
          this.messageService.info('É necessário informar a competência para imprimir', 'Informação');
          return false;
        }
      } else {
        if (!this.filtro.dataInicial) {
          this.messageService.info('É necessário informar a data de início para imprimir', 'Informação');
          return false;
        }
        if (!this.filtro.dataFinal) {
          this.messageService.info('É necessário informar a data final para imprimir', 'Informação');
          return false;
        }
      }
    }
    return true;
  }

  public limpar(): void {
    this.filtro.limpar();
  }

  public view(): void {
    if (this.validateView()) {
      this.relatorioModal.url = '/relatorio/titulos';
      this.relatorioModal.params = this.filtro;
      this.relatorioModal.open();
    }
  }

}