import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';

import { Crud, CrudService, ConfigService, CustomHttp } from '../../../../core';
import { HistoricoConsumo } from '../../../../models';

@Injectable()
export class TiposConsumoHistoricoHttpService implements Crud<HistoricoConsumo> {
    resource = 'tarifasconsumohistorico';
    private baseUrl: string;

    constructor(private customHttp: CustomHttp,
                private crudService: CrudService,
                private configService: ConfigService) {
        this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
    }

    get(id: number): Observable<HistoricoConsumo> {
        return this.crudService.get<HistoricoConsumo>(this.resource, id);
    }

    getAll(): Observable<HistoricoConsumo[]> {
        return this.crudService.getAll<HistoricoConsumo>(this.resource);
    }

    post(entity: HistoricoConsumo): Observable<HistoricoConsumo> {
        return this.crudService.post<HistoricoConsumo>(this.resource, entity);
    }

    put(entity: HistoricoConsumo): Observable<HistoricoConsumo> {
        return this.crudService.put<HistoricoConsumo>(this.resource, entity);
    }

    delete(entity: HistoricoConsumo): Observable<string> {
        return this.crudService.delete<HistoricoConsumo>(this.resource, entity);
    }

    getAllByTipoConsumo(idTipoConsumo: number): Observable<HistoricoConsumo[]> {
        return this.customHttp.get(`${this.baseUrl}/consultar/tipoconsumo/${idTipoConsumo}`)
                    .pipe(
                        map(response => response.json()),
                        catchError(error => _throw(error))
                    );
    }
}
