import { BaseEntity } from '../base-entity';

export class Contato extends BaseEntity {
	tipo: number;
	valor: string;
	id_unidade: number;
	enviar_email: boolean;

	constructor() {
		super();
		this.tipo = null;
		this.valor = '';
		this.id_unidade = null;
		this.enviar_email = true;
	}
}
