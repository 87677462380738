import { BaseEntity } from "../base-entity";
import { Endereco, Usuario } from "app/models";

export class Condomino extends BaseEntity {
	nome: string;
	documento: string;
	rg: string;
	id_endereco: number;
	id_usuario: number;

	endereco: Endereco;
	usuario: Usuario;

	constructor() {
		super();
	}
}