import { Component, OnInit, Input } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { AuthService, MessageService } from 'app/core';
import { LancamentosCondominioHttpService } from '../services';
import { datepickerMesAnoOptions } from 'app/shared';
import { Fornecedor, TemplateLancamentoCondominio, TemplateLancamentoCondominioItem, TipoDocumentoFiscal } from 'app/models';
import { NgxSpinnerService } from 'ngx-spinner';
import { FornecedoresHttpService } from 'app/home/fornecedores';

declare var jQuery: any;

@Component({
  selector: 'geracao-despesa-fixa-modal',
  templateUrl: './geracao-despesa-fixa-modal.component.html',
  styleUrls: ['./geracao-despesa-fixa-modal.component.css']
})
export class GeracaoDespesaFixaModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;

  public id_condominio: number;
  public datepickerDataCompetenciaOptions = datepickerMesAnoOptions;
  public competencia: Date;
  public templates: TemplateLancamentoCondominio[];
  public valorTotal: number = 0;
  public tiposDocumentoFiscal: TipoDocumentoFiscal[] = [];

  public fornecedores: Fornecedor[] = [];

  constructor(private messageService: MessageService,
      private lancamentosCondominioHttpService: LancamentosCondominioHttpService,
      private authService: AuthService,
      private spinner: NgxSpinnerService,
      private fornecedorHttpService: FornecedoresHttpService) { }
  
  ngOnInit() {
    this.buscaFornecedores();
    this.getAllTipoDocumentoFiscal()
    this.id_condominio = this.authService.getIdCurrentCondominio();
    if (this.idModal == '') {
      this.idModal = 'geracaoDespesaFixaModal';
    }
    const today = new Date();
    this.competencia = new Date(today.getFullYear(), today.getMonth(), 1);
    this.templates = [];
  }

  /**
	 * Obter todos os tipos de documentos fiscais
	 * @author Marcos Frare
	 * @since 20/04/2021
	 */
	private getAllTipoDocumentoFiscal(): void {
		this.lancamentosCondominioHttpService.obtemTiposDocumentoFiscal().subscribe(
			response => {
				this.tiposDocumentoFiscal = response;
			},
		);
	}

  /**
   * Busca os fornecedores do cliente
   * @author Marcos Frare
   * @since 01/02/2021
   */
  private buscaFornecedores(): void {
    this.fornecedores = [];
    const id_cliente = this.authService.getIdCurrentCliente();
    this.fornecedorHttpService.getAllByCliente(id_cliente).subscribe(
      res => this.fornecedores = res
    );
  }

  /**
   * Calcular total dos lançamentos
   * @author Marcos Frare
   * @since 20/09/2020
   */
  private calculaTotal(): void {
    this.valorTotal = 0;
    this.templates.map(temp => {
      this.valorTotal += Number(temp.valor);
    });
  }

  /**
   * Carrega os templates conforme competência selecionada
   * @author Marcos Frare
   * @since 10/08/2020
   */
  public loadTemplate(): void {
    this.spinner.show();
    this.templates = [];
    this.lancamentosCondominioHttpService.loadTemplatesCompetencia(this.id_condominio, this.competencia).subscribe(
      response => {
        this.spinner.hide();
        this.templates = response;
        this.calculaTotal();
      },
      error => {
        this.spinner.hide();
        this.messageService.error('Erro ao buscar configuração', 'Erro', error);
      }
    );
  }

  /**
   * Calcula o valor total do lançamento
   * @param template 
   * @author Marcos Frare
   * @since 08/09/2021
   */
  private calcTotalLancamento(template: TemplateLancamentoCondominio): void {
    template.valor = 0;
    template.templateLancamentoCondominioItems.map(item => {
      template.valor += Number(item.valor);
    });
    // Caso não tenha ítems, faz a exclusão da template
    if (!template.templateLancamentoCondominioItems.length) {
      this.templates = this.templates.filter(tmp => tmp.id != template.id);
    }
    this.calculaTotal();
  }

  /**
   * Evento de alteração do valor da template
   * @param template 
   * @param item
   * @author Marcos Frare
   * @since 21/04/2021
   */
  public changeValor(template: TemplateLancamentoCondominio, item: TemplateLancamentoCondominioItem): void {
    let msg = '';
    if (item.maximo && item.valor > item.maximo) {
      msg = 'O valor informado ultrapassa o limite superior configurado! Manter valor?';
    }
    if (item.minimo && item.valor < item.minimo) {
      msg = 'O valor informado ultrapassa o limite inferior configurado! Manter valor?';
    }

    if (msg != '') {
      if (confirm(msg)) {
        this.calcTotalLancamento(template);
      } else {
        item.valor = item.valor_original;
      }
    } else {
      this.calcTotalLancamento(template);
    }
  }

  open() {
    this.loadTemplate();
    jQuery(`#${this.idModal}`).modal('show');
  }
  
  close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Evento de alteração de da data de competência
   * @author Marcos Frare
   * @since 07/08/2020
   */
  public changeCompetencia(): void {
    this.loadTemplate();
  }

  /**
   * Persistir lançamentos
   * @author Marcos Frare
   * @since 07/08/2020
   */
  public salvar(): void {
    this.spinner.show();
    this.lancamentosCondominioHttpService.salvarLancamentosTemplate(this.id_condominio, this.templates).subscribe(
      () => {
        this.spinner.hide();
        this.messageService.success('Lançamentos realizados com sucesso!', 'Info');
      },
      error => {
        this.spinner.hide();
        this.messageService.error('Erro ao fazer lançamentos. Tente novamente', 'Erro', error);
      }
    );
  }

  /**
   * Faz exclusão de um template
   * @param template 
   * @author Marcos Frare
   * @since 10/08/2020
   */
  public delete(template: TemplateLancamentoCondominio): void {
    this.templates = this.templates.filter(item => item.id !== template.id);
    this.calculaTotal();
  }

  /**
   * Excluir um item de lancçamento da template
   * @param template 
   * @param index 
   * @author Marcos Frare
   * @since 08/09/2021
   */
  public deleteItem(template: TemplateLancamentoCondominio, index: number): void {
    template.templateLancamentoCondominioItems.splice(index, 1);
    this.calcTotalLancamento(template);
  }

}