import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseVisualizacaoComponent } from '../../componentes-base';
import { AuthService, MessageService } from '../../core';
import { Titulo, Bloco, Unidade, tiposEnvioBoleto, situacoesBoleto, SITUACAO_BOLETO, SITUACAO_EMISSAO_BOLETO, Renegociacao, ItensRenegociacao } from '../../models';
import { BoletosHttpService } from './services';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { datepickerDefaultOptions } from '../../shared';
import { DatePipe } from '@angular/common';
import { BlocosPesquisaModalComponent, UnidadesPesquisaModalComponent, BlocosHttpService, UnidadesHttpService } from '../blocos';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { BaixaModalComponent } from './baixa-modal';
import { RenegociacaoModalComponent } from './renegociacao-modal';
import { BaixaTitulo } from 'app/models/entidades/baixa-titulo';
import { tiposFiltro } from '../relatorios/titulos/titulos-rel-filtro';
import { datepickerMesAnoOptions } from '../../shared';
import { NgxSpinnerService } from 'ngx-spinner';
import { ParametrosListarTitulosModalComponent } from './parametros-listar-titulos-modal';
import { OcorrenciaTituloModalComponent } from './ocorrencia-titulo-modal';
import { IntegracaoBancariaModalComponent } from './integracao-bancaria-modal';
import { ContasBancariasHttpService } from '../contas-bancarias';
import { TituloEditarModalComponent } from './titulo-editar-modal';
import { BuscarBoletoModalComponent } from './buscar-boleto-modal';
import { CarneListarModalComponent } from './carne-listar-modal';
import { ParametrosListarRecibosModalComponent } from './parametros-listar-recibos-modal';
import { AlteracaoLiquidacaoBoletoModalComponent } from './alteracao-liquidacao-boleto-modal';

declare var jQuery: any;

@Component({
	selector: 'boletos',
	templateUrl: './boletos.component.html',
	styleUrls: ['./boletos.component.css'],
	providers: [PermissionService, DatePipe],
})
export class BoletosComponent extends BaseVisualizacaoComponent<Titulo> implements OnInit {

	public bloco: Bloco;
	public blocos: Bloco[] = [];
	public unidade: Unidade;
	public unidades: Unidade[] = [];
	public tiposEnvioBoleto = tiposEnvioBoleto;
	public situacoesBoleto = situacoesBoleto;
	public idBlocoAtual: number;
	public idUnidadeAtual: number;
	public parcelas: any[] = [];
	public isUnidadeSelected = false;
	public renegociacao: Renegociacao = new Renegociacao();
	public today: Date = new Date();
	public isCondominoUser = true;
	public idCurrentUser: number;
	public baixaTitulo: BaixaTitulo = new BaixaTitulo();
	public filtro: any = {};
	public idCondominio: number;
	public idUnidade: number;
	public possuiRemessasPendentes: boolean;
	public allSelected: boolean = false;
	public datepickerDataOptions = datepickerDefaultOptions;
	public canRenegociar = true;
	public situacaoBoleto = SITUACAO_BOLETO;
	public situacaoEmissaoBoleto = SITUACAO_EMISSAO_BOLETO;
	public valorTotal: number;
	public valorTotalAberto: number;
	public valorTotalVencido: number;
	public valorTotalVencidoCorrigido: number;
	public quantidadeTotal: number;
	public quantidadeTotalAberto: number;
	public quantidadeTotalVencido: number;
	public valorTotalPago: number;
	public quantidadeTotalPago: number;
	public showTotalZerado: boolean;
	public cancelarBaixa: boolean;
	public numeroRenegociacao: number;
	public tiposFiltro = tiposFiltro;
	public datepickerDataCompetenciaOptions = datepickerMesAnoOptions;

	public isHidden: boolean = true;

	@ViewChild('inputBloco') inputBloco: ElementRef;
	@ViewChild('inputUnidade') inputUnidade: ElementRef;
	@ViewChild('blocosPesquisaModal') blocosPesquisaModal: BlocosPesquisaModalComponent;
	@ViewChild('renegociacaoModal') renegociacaoModal: RenegociacaoModalComponent;
	@ViewChild('baixaModal') baixaModal: BaixaModalComponent;
	@ViewChild('unidadesPesquisaModal') unidadesPesquisaModal: UnidadesPesquisaModalComponent;
	@ViewChild('parametrosListarTitulosModal') parametrosListarTitulosModal: ParametrosListarTitulosModalComponent;
	@ViewChild('ocorrenciaTituloModal') ocorrenciaTituloModal: OcorrenciaTituloModalComponent;
	@ViewChild('integracaoBancariaModal') integracaoBancariaModal: IntegracaoBancariaModalComponent;
	@ViewChild('tituloEditarModal') tituloEditarModal: TituloEditarModalComponent;
	@ViewChild('buscarBoletoModal') buscarBoletoModal: BuscarBoletoModalComponent;
	@ViewChild('carneListarModal') carneListarModal: CarneListarModalComponent;
	@ViewChild('parametrosListarRecibosModal') parametrosListarRecibosModal: ParametrosListarRecibosModalComponent;
	@ViewChild('alteracaoLiquidacaoBoletoModal') alteracaoLiquidacaoBoletoModal: AlteracaoLiquidacaoBoletoModalComponent;

	constructor(public activatedRoute: ActivatedRoute,
		public router: Router,
		public messageService: MessageService,
		private authService: AuthService,
		private boletosHttpService: BoletosHttpService,
		public permissionService: PermissionService,
		private datePipe: DatePipe,
		private blocosHttpService: BlocosHttpService,
		private unidadesHttpService: UnidadesHttpService,
		private contaBancariaHttpService: ContasBancariasHttpService,
		public spinner: NgxSpinnerService) {
		super('#tableBoletos', boletosHttpService, activatedRoute, router, messageService, permissionService, spinner);
	}

	ngOnInit() {
		this.getPermissions();
		this.isHidden = true;
		this.idCondominio = this.authService.getIdCurrentCondominio();
		this.isCondominoUser = this.authService.getTipoCurrentUser() === 3;
		this.idCurrentUser = this.authService.getIdCurrentUser();
		this.setInitialFilter();
		this.getBlocosByCondominio(this.idCondominio);
		this.getAllByTipoUsuario();
	}

	public getAllByTipoUsuario(): void {
		if (this.isCondominoUser) {
			this.getAllByUnidadeCondomino();
		} else {
			this.getAll();
		}
	}

	public getAll(): void {
		this.spinner.show();
		const btn = jQuery('#btnFiltrar');
		this.loadButton(btn);
		this.entities = [];
		this.showTotalZerado = false;
		this.destroyTable();
		this.boletosHttpService.getAllByDataCompetencia(this.getFilterPayload(), this.idCondominio).subscribe(
			response => {
				this.spinner.hide();
				btn.button('reset');
				this.entities = response.blocos;
				this.valorTotal = response.valor_total;
				this.quantidadeTotal = response.quantidade_total;
				this.quantidadeTotalAberto = response.quantidade_total_emaberto;
				this.quantidadeTotalVencido = response.quantidade_total_vencido;
				this.valorTotalAberto = response.valor_total_emaberto;
				this.valorTotalVencido = response.valor_total_vencido;
				this.valorTotalVencidoCorrigido = response.valor_total_vencido_corrigido;
				this.valorTotalPago = response.valor_total_pago;
				this.quantidadeTotalPago = response.quantidade_total_pago;
				this.possuiRemessasPendentes = response.remessas_pendentes;
				this.isUnidadeSelected = !!this.unidade;
				this.numeroRenegociacao = response.numeroRenegociacao;
				this.selectAll();
				this.resetButton(btn);
				this.showTable();
			},
			error => {
				this.spinner.hide();
				switch (error.status) {
					case 404:
						this.messageService.warning('Não foram encontrados títulos para os filtros informados');
						this.showTotalZerado = true;
						break;
					case 500:
						this.messageService.error('Erro ao carregar os títulos. Tente novamente', 'Erro', error);
						break;
					default:
						break;
				}
				this.resetButton(btn);
				this.showTable();
			}
		);
	}

	private getAllByUnidadeCondomino(): void {
		this.boletosHttpService.getUnidadeByUsuario(this.idCurrentUser).subscribe(
			response => {
				this.setBloco(response.bloco);
				this.setUnidade(response.unidade);
				this.getAll();
			},
			error => this.messageService.info('Esse usuário não está associado a nenhuma unidade ou não possui títulos gerados.', 'Informação'));
	}

	// Filtros iniciais
	public setInitialFilter(): void {
		this.filtro.situacao = SITUACAO_BOLETO.EMABERTO;
		this.filtro.tipoFiltro = 1;
	}

	public getBlocosByCondominio(idCondominio: number): void {
		this.blocos = [];
		this.blocosHttpService.getAllByCondominio(idCondominio).subscribe(
			entities => {
				this.blocos = entities;
				if (this.idBlocoAtual && !isNaN(this.idBlocoAtual)) {
					const index = this.blocos.findIndex(entity => entity.id === this.idBlocoAtual);
					if (index > -1) { this.setBloco(this.blocos[index]); }
				}
			},
			error => this.messageService.error('Erro ao carregar os blocos do condomínio. Tente novamente', 'Erro', error)
		);
	}

	private setBloco(blocoSelecionado: Bloco) {
		this.bloco = blocoSelecionado;
		this.filtro.id_bloco = this.bloco.id;
		this.getAllUnidadesByBloco(this.bloco.id);
		if (!this.isCondominoUser) {
			this.onCleanUnidade(this.inputUnidade.nativeElement);
		}
	}

	public onSelectBloco(tm: TypeaheadMatch): void {
		this.setBloco(tm.item as Bloco);
	}

	public openBlocosPequisaModal(): void {
		this.blocosPesquisaModal.open();
	}

	public onSelectBlocosPesquisaModal(bloco: Bloco): void {
		if (bloco) { this.setBloco(bloco); };
		jQuery('#bloco').focus();
	}

	public onCleanBloco(input: any): void {
		if (!this.isCondominoUser) { this.onCleanUnidade(this.inputUnidade.nativeElement); }
		this.filtro.id_bloco = null;
		this.bloco = null;
		input.value = '';
		input.focus();
	}

	public getAllUnidadesByBloco(idBloco: number): void {
		this.unidades = [];
		this.unidadesHttpService.getAllByBloco(idBloco).subscribe(
			entities => {
				for (const unidade of entities) {
					if (unidade.nome) { this.unidades.push(unidade); }
				}
				if (this.idUnidadeAtual && !isNaN(this.idUnidadeAtual)) {
					const index = this.unidades.findIndex(entity => entity.id === this.idUnidadeAtual);
					if (index > -1) { this.setUnidade(this.unidades[index]); }
				}
			},
			error => this.messageService.error('Erro ao carregar as unidades do bloco. Tente novamente', 'Erro', error)
		);
	}

	private setUnidade(unidadeSelecionada: Unidade) {
		this.unidade = unidadeSelecionada;
		this.filtro.id_unidade = this.unidade.id;
		this.idUnidade = this.unidade.id;
	}

	public onSelectUnidade(tm: TypeaheadMatch): void {
		this.setUnidade(tm.item as Unidade);
	}

	public onCleanUnidade(input: any): void {
		this.unidade = null;
		this.filtro.id_unidade = null;
		input.value = '';
		input.focus();
	}

	public openUnidadesPequisaModal(): void {
		this.unidadesPesquisaModal.open();
	}

	public onSelectUnidadesPesquisaModal(unidade: Unidade): void {
		if (unidade) { this.setUnidade(unidade); }
		jQuery('#unidade').focus();
	}

	public checkSelected(): boolean {
		let isSelected = false;
		this.entities.forEach(entity => {
			entity.titulos.forEach(titulo => {
				if (titulo.selected) { isSelected = true; }
			});
		});
		return isSelected;
	}

	public showTitleRemessa(): any {
		if (this.possuiRemessasPendentes) {
			return 'Existem remessas pendentes';
		} else {
			return '';
		}
	}

	public toggleAllSelected(entity: Bloco): void {
		entity.titulos.filter(it => !this.isDisabled(it)).forEach(titulo => {
			titulo.selected = entity.selected;
		});
	}

	public selectAll(): void {
		this.allSelected = !this.allSelected;
		this.entities.forEach(entity => {
			entity.selected = this.allSelected;
			entity.titulos.filter(it => !this.isDisabled(it)).forEach(titulo => {
				titulo.selected = entity.selected;
			});
		});
	}

	public checkAllSelected(): boolean {
		let isAllSelected = true;
		this.entities.forEach(entity => {
			entity.titulos.filter(it => !this.isDisabled(it)).forEach(titulo => {
				if (!titulo.selected) { isAllSelected = false; }
			});
		});
		return isAllSelected;
	}

	public onClickGenerateArquivoRemessa(): void {
		const idsTitulos: number[] = this.getIdsSelectedsTitulos();
		this.generateArquivoRemessa(idsTitulos);
	}

	private generateArquivoRemessa(idsTitulos: number[]): void {
		const btn = jQuery('#btnArquivoRemessa');
		this.loadButton(btn);
		this.boletosHttpService.postGerarArquivoRemessa(this.idCondominio, idsTitulos).subscribe(
			file => {
				this.messageService.success('Arquivo remessa gerado com sucesso', 'Sucesso');
				this.resetButton(btn);
				this.downloadArquivoRemessa(file);
				this.getAllByTipoUsuario();
			},
			error => {
				this.messageService.error('Erro ao gerar arquivo remessa', 'Erro', error);
				this.resetButton(btn);
			}
		);
	}

	private downloadArquivoRemessa(file: any) {
		const a: any = document.createElement('a');
		document.body.appendChild(a);
		a.style = 'display: none';
		const blob = new Blob([file.content], { type: 'text/plain' });
		const url = window.URL.createObjectURL(blob);
		a.href = url;
		a.download = file.filename;
		a.click();
		window.URL.revokeObjectURL(url);
	}

	public getRenegociacoes(titulo: Titulo, buttonId: string): void {
		if (titulo.open) {
			titulo.open = false;
			return null;
		}
		const btn = jQuery(`#${buttonId}`);
		this.loadButton(btn);
		this.boletosHttpService.getRenegociacoes(titulo.id).subscribe(
			response => {
				titulo.renegociacoes = response.renegociacao;
				titulo.titulos = response.titulos;
				if (titulo.titulos.length > 0) {
					titulo.open = true;
				} else {
					this.messageService.info('Esse título não foi renegociado', 'Informação');
				}
				this.resetButton(btn);
			},
			error => {
				this.messageService.info('Esse título não foi renegociado', 'Informação');
				this.resetButton(btn);
			}
		);
	}

	public deleteRenegociacao(entity: Titulo): void {
		// Validar se tem algum
		let msg = `Tem certeza que deseja excluir o titulo ${entity.nosso_numero}?`;
		if (entity.id_arquivo_remessa) {
			msg = 'O acordo já possui arquivo de remessa gerado!! Deseja excluir o acordo mesmo assim?';
		}
		if (confirm(msg)) {
			this.boletosHttpService.delete(entity).subscribe(
				response => {
					if (response.error && response.force) {
						if (confirm(`${response.msg} Deseja excluir mesmo assim?`)) {
							this.boletosHttpService.delete(entity, true).subscribe(
								() => {
									this.getAllByTipoUsuario();
									this.messageService.success(`Título excluído com sucesso.`, 'Sucesso');
								},
								error => {
									this.messageService.error('Erro ao exlcuir acordo!', 'Erro!', error);
								}
							);
						}
					} else {
						this.getAllByTipoUsuario();
						this.messageService.success(`Título excluído com sucesso.`, 'Sucesso');
					}
				},
				error => {
					switch (error.status) {
						case 403:
							this.messageService.warning(`Não foi possível excluir o acordo.`, 'Erro', error);
							break;
						default:
							this.messageService.error(`Erro ao excluir o acordo. Tente novamente`, 'Erro', error);
					}
				}
			);
		}
	}

	public onPedirBaixaClick(): void {
		this.baixaTitulo.ids_titulos = this.getIdsSelectedsTitulos();
		this.baixaModal.open();
	}

	public onCancelaBaixaClick(titulo: any): void {
		if (titulo.id_arquivo_remessa) {
			this.cancelarBaixa = confirm('Atenção, existe arquivo remessa gerado para o título. Deseja realmente desfazer esta baixa?');
		} else {
			this.cancelarBaixa = true;
		}
		if (this.cancelarBaixa) {
			this.boletosHttpService.desfazBaixa(titulo.id, this.idCondominio).subscribe(
				() => {
					this.messageService.success('Baixa desfeita com sucesso.', 'Sucesso');
					this.getAllByTipoUsuario();
				},
				error => {
					this.messageService.error('Erro ao desfazer baixa, favor contate o administrador do sistema', 'Erro', error);
				}
			);
		}
	}

	public onCloseBaixaModal(): void {
		this.baixaTitulo = new BaixaTitulo();
		this.getAll();
	}

	// --------------------------------------------------------------------------------------
	// Renegociações
	// --------------------------------------------------------------------------------------
	// Evento de click no botão de renegociar
	public onRenegociarClick(): void {
		const btn = jQuery('#btnRenegociar');
		this.loadButton(btn);

		// Carrega os dados dos títulos selecionados para renegociação
		this.renegociacao = new Renegociacao();
		this.renegociacao.arTitulo = [];
		this.renegociacao.totalTitulos.valor_original = 0;
		this.renegociacao.totalTitulos.valor_multa = 0;
		this.renegociacao.totalTitulos.valor_juros = 0;
		this.renegociacao.totalTitulos.valor_unitario = 0;
		this.renegociacao.isensaojurosparcelamento = false;
		this.renegociacao.isensaojurosentrada = false;

		const params = this.setPayloadCalc(new Date, 1);

		// Validar a renegociação
		this.boletosHttpService.validaRenegociacao(params).subscribe(
			arMsg => {
				if (Array.isArray(arMsg) && arMsg.length > 0) {
					arMsg.forEach(msg => {
						this.messageService.warning(msg, 'Atenção');
					})
					this.resetButton(btn);
				} else {
					// Busca os títulos e carrega totalizadores
					this.boletosHttpService.getTitulosRenegociacao(params).subscribe(
						titulos => {
							if (titulos && titulos.length > 0) {
								this.renegociacao.arTitulo = titulos;
								this.renegociacao.arTitulo.map(titulo => {
									this.renegociacao.totalTitulos.valor_original += Number(titulo.valor_original);
									this.renegociacao.totalTitulos.valor_multa += titulo.valor_multa;
									this.renegociacao.totalTitulos.valor_juros += titulo.valor_juros;
									this.renegociacao.totalTitulos.valor_unitario += titulo.valor_unitario;
								});
							}
							this.resetButton(btn);
						},
						error => {
							this.resetButton(btn);
							this.messageService.error('Erro ao carregar títulos para acordo!', 'Erro', error);
						}
					);
					this.calcRenegociacao();
				}
			}
		);
	}

	public calcRenegociacao(dataVencimento: Date = new Date(), parcelas: number = 1): void {
		const params = this.setPayloadCalc(dataVencimento, parcelas);
		const btn = jQuery('#btnRenegociar');
		if (!this.canRenegociar) {
			this.resetButton(btn);
			this.messageService.warning('Para realizar o acordo é necessário selecionar os títulos de uma única unidade', 'Atenção');
			return;
		}
		this.boletosHttpService.calcRenegociacao(params).subscribe(
			response => {
				this.resetButton(btn);
				this.setRenegociacao(response.parcelas);
				this.renegociacaoModal.open();
			},
			error => error
		);
	}

	private setPayloadCalc(dataVencimento: Date, parcelas: number): any {
		return {
			data_vencimento: this.datePipe.transform(dataVencimento, 'yyyy-MM-dd'),
			id_condominio: this.idCondominio,
			quantidade_parcelas: parcelas,
			isensaojurosparcelamento: false,
			isensaojurosentrada: false,
			ids_titulos: this.getIdsTitulosSelected()
		};
	}

	private setRenegociacao(parcelas: any[]): any {
		const itens = [new ItensRenegociacao()];
		this.renegociacao = {
			data_vencimento: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
			id_condominio: this.idCondominio,
			parcelas: parcelas,
			parcelasEntrada: [],
			ids_titulos: this.getIdsTitulosSelected(),
			id_unidade: this.idUnidade,
			quantidade_parcelas: 1,
			itens: itens,
			numero: this.numeroRenegociacao,
			arTitulo: this.renegociacao.arTitulo,
			totalTitulos: this.renegociacao.totalTitulos,
			isensaojurosparcelamento: false,
			isensaojurosentrada: false
		} as Renegociacao;
	}

	private getIdsTitulosSelected(): number[] {
		const idsTitulos: number[] = [];
		const idsUnidades: number[] = [];
		this.entities.forEach(entity =>
			entity.titulos
				.filter(titulo => titulo.selected)
				.forEach(titulo => {
					idsTitulos.push(titulo.id);
					idsUnidades.push(titulo.id_unidade);
				})
		);
		const selectedUnidades: string[] = Object.keys(this.groupBy(idsUnidades));
		this.canRenegociar = selectedUnidades.length === 1;
		if (this.canRenegociar) {
			this.idUnidade = Number(selectedUnidades[0]);
		}

		return idsTitulos;
	}

	private getIdsSelectedsTitulos(): number[] {
		const idsTitulos: number[] = [];
		this.baixaTitulo.arTitulo = [];
		this.renegociacao.totalTitulos.valor_original = 0;
		this.renegociacao.totalTitulos.valor_unitario = 0;
		this.entities.forEach(entity => {
			entity.titulos.filter(titulo => titulo.selected).map(titulo => {
				this.baixaTitulo.arTitulo.push(titulo);
				idsTitulos.push(titulo.id);
				this.renegociacao.totalTitulos.valor_original += titulo.valor_original;
				this.renegociacao.totalTitulos.valor_unitario += titulo.valor_unitario;
			});
		});
		return idsTitulos;
	}

	private loadButton(btn: any) {
		btn.button('loading');
	}

	private resetButton(btn: any) {
		btn.button('reset');
	}

	public onChangeDataVencimento(titulo: Titulo): void {
		const dataMulta: Date = new Date(titulo.data_multa);
		if (titulo.data_vencimento < dataMulta) {
			titulo.data_vencimento = dataMulta;
		}
		this.recalculate(titulo);
		titulo.updated = true;
	}

	public recalculate(titulo: Titulo): void {
		titulo.updated = false;
		this.boletosHttpService.recalculate(this.idCondominio, titulo.id,
			this.datePipe.transform(titulo.data_vencimento, 'yyyy-MM-dd')).subscribe(
				(response: any) => titulo.valor_unitario = response.valor_unitario
			);
	}

	public isDisabled(titulo: Titulo): boolean {
		return titulo.situacao === SITUACAO_BOLETO.BAIXACONFIRMADA ||
			titulo.situacao === SITUACAO_BOLETO.PAGO ||
			titulo.situacao === SITUACAO_BOLETO.RENEGOCIADO;
	}

	public getFilterPayload(): any {
		const payload = {
			tipoFiltro: this.filtro.tipoFiltro,
			dataCompetenciaInicial: this.datePipe.transform(this.filtro.dataCompetenciaInicial, 'yyyy-MM'),
			dataCompetenciaFinal: this.datePipe.transform(this.filtro.dataCompetenciaFinal, 'yyyy-MM'),
			data_inicial: this.datePipe.transform(this.filtro.data_inicial, 'yyyy-MM-dd'),
			data_final: this.datePipe.transform(this.filtro.data_final, 'yyyy-MM-dd'),
			situacao: this.filtro.situacao,
			id_bloco: this.filtro.id_bloco,
			id_unidade: this.filtro.id_unidade,
			tipo_envio_boleto: this.filtro.tipo_envio_boleto,
			nome_sacado: this.filtro.nome_sacado,
			serie_rateio: this.filtro.serie_rateio,
			numero_boleto: this.filtro.numero_boleto,
			origem: this.filtro.origem,
		};
		return payload;
	}

	private groupBy(array: any[]): any[] {
		return array.reduce(function (groups, item) {
			const val = item;
			groups[val] = groups[val] || [];
			groups[val].push(item);
			return groups;
		}, {});
	}

	public labelTotalTitulos(): string {
		switch (this.filtro.situacao) {
			case SITUACAO_BOLETO.EMABERTO:
				return 'Total de Títulos abertos';
			case SITUACAO_BOLETO.VENCIDO:
				return 'Total de Títulos vencidos';
			default:
				return 'Total de Títulos';
		}
	}

	// --------------------------------------------------------------------------------------------------------------------------------------------
	// Emissão de boletos com padrão de relatórios
	// --------------------------------------------------------------------------------------------------------------------------------------------
	// A emissão do título será processado totalmente no back-end
	// Retornando um arquivo PDF com todos os títulos processados

	/**
	 * Geração de titulos conforme seleção em tela
	 * @author Marcos Frare
	 * @since 18/08/2020
	 */
	public downloadMultiple(): void {
		this.parametrosListarTitulosModal.idsTitulos = this.getIdsSelectedsTitulos();
		this.parametrosListarTitulosModal.open();
	}

	/**
	 * Geração de carnês
	 * @author Marcos Frare
	 * @since 02/12/2021
	 */
	public downloadCarne(): void {
		this.carneListarModal.open();
	}

	/**
	 * Geração de um título específico
	 * @param titulo Título selecionado
	 * @author Marcos Frare
	 * @since 27/08/2020
	 */
	public downloadSingle(titulo: Titulo): void {
		this.parametrosListarTitulosModal.idsTitulos = [titulo.id];
		this.parametrosListarTitulosModal.open();
	}

	/**
	 * Abrir tela de ocorrência do título
	 * @param titulo
	 * @auhtor Marcos Frare
	 * @since 07/09/2020
	 */
	public ocorrenciaTitulo(titulo: Titulo): void {
		this.ocorrenciaTituloModal.id_titulo = titulo.id;
		this.ocorrenciaTituloModal.id_unidade = titulo.id_unidade;
		this.ocorrenciaTituloModal.open();
	}

	/**
	 * Integrar boletos via API
	 * @author Marcos Frare
	 * @since 30/04/2021
	 */
	public onClickIntegrarAPI(): void {
		// Valida se o condomínio possui alguma conta bancária integrada
		this.contaBancariaHttpService.contaBancariaIntegradaCondominio().subscribe(
			response => {
				if (response.length > 0) {
					this.integracaoBancariaModal.open();
				} else {
					this.messageService.warning('Condomínio não possui conta bancária configurada para integração de boletos!', 'Atenção!');
				}
			},
			error => {
				this.messageService.error('Erro ao buscar contas bancárias integradas do condomínio!', 'Erro!', error);
			}
		);
	}

	/**
	 * Click com mouse direito na linha do grid
	 * @param event 
	 * @param titulo 
	 * @author Marcos Frare
	 * @since 12/08/2021
	 */
	public onDblClickTitulo(titulo: Titulo): void {
		// Somente com permissão de atualização
		if (this.permissions.atualizar) {
			if (['RATEIO', 'COBRANCAAVULSA'].includes(titulo.origem) && [1,3,7,8].includes(titulo.situacao)) {
				// Abrir modal para edição do título
				this.tituloEditarModal.idTitulo = titulo.id;
				this.tituloEditarModal.open();
			}
		}
	}

	/**
	 * Buscar boleto no banco e vincular ao título
	 * @param titulo 
	 * @author Marcos Frare
	 * @since 11/10/2021
	 */
	public includeBoleto(titulo: Titulo) {
		this.buscarBoletoModal.idTitulo = titulo.id;
		this.buscarBoletoModal.open();
	}

	/**
	 * Emitir recibos de títulos selecionados
	 * @author Marcos Frare
	 * @since 16/11/2022
	 */
	public downloadRecibo(): void {
		this.parametrosListarRecibosModal.idsTitulos = this.getIdsSelectedsTitulos();
		this.parametrosListarRecibosModal.open();
	}

	/**
	 * Estornar pagamento
	 * @param titulo 
	 * @author Marcos Frare
	 * @since 10/02/2023
	 */
	public estornarPagamento(titulo: Titulo): void {

		this.alteracaoLiquidacaoBoletoModal.titulo = titulo;
		this.alteracaoLiquidacaoBoletoModal.open();


		// if (confirm("Deseja realmente estornar pagamento do título de "+titulo.nome_sacado+"?")) {
		// 	this.boletosHttpService.estornarPagamentoTitulo(titulo.id).subscribe(
		// 		res => {
		// 			if (res.success) {
		// 				this.messageService.success((res.msg || "Pagamento estornado com sucesso!"));
		// 				this.getAllByTipoUsuario();
		// 			} else {
		// 				this.messageService.warning((res.msg || "parece que algo deu errado! Tente novamente."), "Atenção");
		// 			}
		// 		},
		// 		error => {
		// 			this.messageService.error('Parece que algo deu errado! Tente novamente.', 'Erro', error);
		// 		}
		// 	);
		// }
	}

}