import { Component, Input, OnInit, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

import { AuthService, MessageService } from '../../../../core';
import { BaseModal } from 'app/componentes-utils/modal';
import { Unidade, Bloco, tiposNotificacao, Notificacao, EventoNotificacao, Formulario, Anexo } from 'app/models';
import { isNull } from 'util';
import { FormulariosHttpService } from 'app/home/formularios';
import { BlocosHttpService, BlocosPesquisaModalComponent, UnidadesHttpService, UnidadesPesquisaModalComponent } from 'app/home/blocos';
import { NotificacoesHttpService } from '../services';

declare var jQuery: any;

@Component({
  selector: 'cadastro-notificacao-modal',
  templateUrl: './cadastro-notificacao-modal.component.html',
  styleUrls: ['./cadastro-notificacao-modal.component.css']
})
export class CadastroNotificacaoModalComponent implements OnInit, BaseModal {

  @ViewChild('inputBloco') inputBloco: ElementRef;
  @ViewChild('blocosPesquisaModal1') blocosPesquisaModal: BlocosPesquisaModalComponent;
  @ViewChild('inputUnidade') inputUnidade: ElementRef;
  @ViewChild('unidadesPesquisaModal1') unidadesPesquisaModal: UnidadesPesquisaModalComponent;

  @Output() onSave: EventEmitter<EventoNotificacao> = new EventEmitter();

  public showInputBlocos: boolean = true;
  public blocos: Bloco[];
  public bloco: Bloco;
  public formularios: Formulario[] = [];
  public unidade: Unidade;
  public unidades: Unidade[];
  public tipoNotificacao: number;
  public tipos = tiposNotificacao;
  public id_condominio: number;
  public id_cliente: number;
  public descricao: string;
  public diasVencido: number;
  public id_evento_notificacao: number;

  public editorConfig: any = {
		editable: true,
		spellcheck: true,
		height: 'auto',
		minHeight: '200px',
		width: 'auto',
		minWidth: '0',
		translate: 'yes',
		enableToolbar: true,
		showToolbar: true,
		placeholder: 'Digite a mensagem...',
		imageEndPoint: '',
		toolbar: [
			['bold', 'italic', 'underline', 'fontSize', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull']
		]
	};

  public eventoNotificacao: EventoNotificacao;
  
  @Input() idModal: string;
  @Input() notificacao: Notificacao;

  constructor(
        private authService: AuthService,
        private messageService: MessageService,
        private blocosHttpService: BlocosHttpService,
        private unidadesHttpService: UnidadesHttpService,
        private notificacoesHttpService: NotificacoesHttpService,
        private formulariosHttpService: FormulariosHttpService) {
    this.eventoNotificacao = new EventoNotificacao();
  }

  ngOnInit() {
    if (!this.idModal) {
      this.idModal = 'notificacaoCadastroModal';
    }
    this.getBlocosByCondominio();
  }

  open() {
    this.id_cliente = this.authService.getIdCurrentCliente();
    this.eventoNotificacao = new EventoNotificacao();
    this.eventoNotificacao.anexos = [new Anexo()];
    this.getAllFormulariosByCliente();
    jQuery(`#${this.idModal}`).modal('show');
  }

  close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Busca todos os formulários do cliente
   * @param idCliente 
   * @author Marcos Frare
   * @since 22/06/2021
   */
  private getAllFormulariosByCliente(): void {
		this.formulariosHttpService.getAllByCliente(this.authService.getIdCurrentCliente()).subscribe(
			formularios => this.formularios = formularios,
			error => this.messageService.error('Erro ao consultar os formulários. Tente novamente', 'Erro', error)
		);
	}

  // Ações do campo de blocos
  public onSelectBlocosPesquisaModal(bloco: Bloco) {
    if (bloco) {
      this.setBloco(bloco)
    }
    jQuery('#bloco').focus();
  }

  public openBlocosPequisaModal() {
    this.blocosPesquisaModal.open();
  }

  public onSelectBloco(tm: TypeaheadMatch): void {
    this.setBloco(tm.item as Bloco);
  }

  public onCleanBloco(input: any): void {
    this.onCleanUnidade(this.inputUnidade.nativeElement);
    this.bloco = null;
    input.value = '';
    input.focus();
  }

  /**
   * Atribui valor do bloco
   * @param blocoSelecionado Bloco selecionado
   */
  private setBloco(blocoSelecionado: Bloco): void {
    this.bloco = blocoSelecionado;
    this.getAllUnidadesByBloco(this.bloco.id);
    this.onCleanUnidade(this.inputUnidade.nativeElement);
  }

  // Ações do campo de Unidades
  /**
   * Busca todas as unidade de um bloco
   * @param idBloco 
   */
  public getAllUnidadesByBloco(idBloco: number): void {
    this.unidades = [];
    this.unidadesHttpService.getAllByBloco(idBloco).subscribe(
      entities => {
        for (const unidade of entities) {
          if (unidade.nome) {
            this.unidades.push(unidade);
          }
        }
      },
      error => this.messageService.error('Erro ao carregar as unidades do bloco. Tente novamente', 'Erro', error)
    );
  }

  /**
   * Limpa campo de Unidades
   * @param input Objeto do elemento
   */
  public onCleanUnidade(input: any): void {
    this.unidade = null;
    input.value = '';
    input.focus();
  }

  public onSelectUnidadesPesquisaModal(unidade: Unidade): void {
    if (unidade) { this.setUnidade(unidade); }
    jQuery('#unidade').focus();
  }

  private setUnidade(unidadeSelecionada: Unidade): void {
    this.unidade = unidadeSelecionada;
  }

  public openUnidadesPequisaModal(): void {
    this.unidadesPesquisaModal.open();
  }

  public onSelectUnidade(tm: TypeaheadMatch): void {
    this.setUnidade(tm.item as Unidade);
  }
  
  /**
   * Carrega os blocos do Condomínio
   */
  private getBlocosByCondominio(): void {
    this.id_condominio = this.authService.getIdCurrentCondominio();
    this.blocos = [];
    this.blocosHttpService.getAllByCondominio(this.id_condominio).subscribe(
      entities => {
        this.blocos = entities;
        if (entities.length === 1) this.setBloco(entities[0])
      },
      error => this.messageService.error('Erro ao carregar os blocos do condomínio. Tente novamente', 'Erro', error)
    );
  }

  /**
   * Valida o formulário
   */
  private validateForm(): boolean {
    if (!this.eventoNotificacao.tipo || isNull(this.eventoNotificacao.tipo)) {
      this.messageService.warning('Campo de tipo notificação é obrigatório!!', 'Atenção!');
      return false;
    }
    return true;
  }

  /**
   * Gerar as notificações conforme campos informados
   * @author Marcos Frare
   */
  public gerar(): void {
    if (this.validateForm()) {
      const btnView = jQuery('#btnGerar');
      btnView.button('loading');
      const params = {
        id_condominio: this.id_condominio,
        id_formulario: this.eventoNotificacao.id_formulario,
        tipo: this.eventoNotificacao.tipo,
        id_bloco: this.bloco ? this.bloco.id : null,
        id_unidade: this.unidade ? this.unidade.id : null,
        diasVencido: this.eventoNotificacao.diasVencido || 0,
        contato_sindico: this.eventoNotificacao.contato_sindico || false,
        contato_administradora: this.eventoNotificacao.contato_administradora || false,
        email_copia_administradora: this.eventoNotificacao.email_copia_administradora || false,
        dias_manifestacao: this.eventoNotificacao.dias_manifestacao || 0,
        data_ocorrencia: this.eventoNotificacao.data_ocorrencia,
        mensagem: this.eventoNotificacao.mensagem,
        anexos: this.eventoNotificacao.anexos
      }
      this.notificacoesHttpService.gerarNotificacao(params).subscribe(
        entity => {
          btnView.button('reset');
          this.eventoNotificacao = new EventoNotificacao();
          this.onSave.emit(entity);
          this.close();
          this.messageService.success('', 'Notificações geradas com sucesso');
        },
        error => {
          this.messageService.error('Erro ao realizar a consulta. Tente novamente', 'Erro', error);
          btnView.button('reset');
        }
      );
    }
  }

  /**
	 * Incluir anexo
	 * @author Marcos Frare
	 * @since 22/06/2021
	 */
	public addAnexos(): void {
		this.eventoNotificacao.anexos.push(new Anexo());
	}

	/**
	 * Excluir um anexo
	 * @param index
	 * @author Marcos Frare
	 * @since 22/06/2021
	 */
	public removeAnexos(index): void {
		this.eventoNotificacao.anexos.splice(index, 1);
	}

}