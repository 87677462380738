import { AcessoPainel } from '../../../../models';

export class AcessosPainelEnvio {

	public id_grupo_de_usuario: number;
	public graficos: AcessoPainel[];
	public cards: AcessoPainel[];
	public autorizacoes: AcessoPainel[];

	constructor(id_grupo_de_usuario: number,
		graficos: AcessoPainel[],
		cards: AcessoPainel[],
		autorizacoes: AcessoPainel[]) {
		this.id_grupo_de_usuario = id_grupo_de_usuario || null;
		this.graficos = graficos || [];
		this.cards = cards || [];
		this.autorizacoes = autorizacoes || [];
	}

}