import { BaseEntity } from "../base-entity";

export class Cheque extends BaseEntity {
	numero: string;
	data_emissao: Date;
	data_pre_datado: Date;
	status: string;
	valor: number;

	id_pagamento: number;

	constructor() {
		super();
		this.numero = null;
		this.data_emissao = new Date();
		this.data_pre_datado = null;
		this.status = 'EMITIDO';
		this.valor = null;
	}
}