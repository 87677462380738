import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { _throw } from 'rxjs/observable/throw';
import { CustomHttp, ConfigService } from '../../../../core';
import { AcessoPainel } from '../../../../models';
import { AcessosPainelEnvio } from './acessos-painel-envio';

@Injectable()
export class AcessosPainelHttpService {
	resource = 'itenspainel';
	baseUrl: string;
	baseUrlAlt: string;

	constructor(private customHttp: CustomHttp,
		private configService: ConfigService) {
		this.baseUrl = `${this.configService.getBaseUrl()}/${this.resource}`;
	}

	public getAll(): Observable<AcessoPainel[]> {
		return this.customHttp.get(`${this.baseUrl}/listar`)
			.pipe(
				map(response => response.json()),
				catchError(error => _throw(error))
			);
	}

	public getAllByGrupoDeUsuario(idGrupoUsuario: number): Observable<any> {
		return this.customHttp.get(`${this.baseUrl}/consultar/${idGrupoUsuario}`)
			.pipe(
				map(response => response.json()),
				catchError(error => _throw(error))
			);
	}

	public getAllByUsuarioAndCliente(id_cliente: number, id_usuario: number): Observable<AcessoPainel[]> {
		return this.customHttp.get(`${this.baseUrl}/montar`, { params: { id_cliente, id_usuario } })
			.pipe(
				map(response => response.json()),
				catchError(error => _throw(error))
			);
	}

	public save(acessosPainel: AcessosPainelEnvio): Observable<string> {
		return this.customHttp.post(`${this.baseUrl}`, acessosPainel)
			.pipe(
				map(response => response.json()),
				catchError(error => Observable.throw(error))
			);
	}
}
