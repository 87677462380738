import { Component, OnInit, Input } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { AuthService, MessageService } from 'app/core';
import { TiposConsumoTarifasHttpService, TiposConsumoTarifasCadastroService } from '../services';
import { CategoriaTarifaConsumo, TipoConsumo } from 'app/models';
import { PermissionService } from 'app/core/services/permission/permission.service';

declare var jQuery: any;

@Component({
  selector: 'categoria-tarifas-consumo-cadastro-modal',
  templateUrl: './categoria-tarifas-consumo-cadastro-modal.component.html',
  styleUrls: ['./categoria-tarifas-consumo-cadastro-modal.component.css'],
  providers: [
    TiposConsumoTarifasCadastroService,
    PermissionService
  ]
})
export class CategoriaTarifasConsumoCadastroModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @Input() tipoConsumo: TipoConsumo;

  public categoriaTarifaConsumo: CategoriaTarifaConsumo;
  public tarifasConsumoDefault: any[] = [];

  constructor(private authService: AuthService,
      private messageService: MessageService,
      private tiposConsumoTarifasCadastroService: TiposConsumoTarifasCadastroService,
      private tiposConsumoTarifasHttpService: TiposConsumoTarifasHttpService) { }
  
  ngOnInit() {
    if (!this.idModal) {
      this.idModal = 'categoriaTarifaConsumoCadastroModal';
    }
    this.categoriaTarifaConsumo = new CategoriaTarifaConsumo();
  }

  open() {
    jQuery(`#${this.idModal}`).modal('show');
  }
  
  close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  edit(categoria: CategoriaTarifaConsumo) {
    this.categoriaTarifaConsumo = categoria;
    this.categoriaTarifaConsumo.data_historico = new Date();
    jQuery(`#${this.idModal}`).modal('show');
  }

  private createTarifasDefaultValue(tarifas: any[]): void {
    tarifas.forEach((it: any) => {
      const tarifaDefault = {
        id: it.id,
        consumo_inicial: it.consumo_inicial,
        consumo_final: it.consumo_final,
        valor: it.valor,
        id_tipo_consumo: it.id_tipo_consumo,
        consumo_minimo: it.consumo_minimo
      };
      this.tarifasConsumoDefault.push(tarifaDefault);
    });
  }

  /**
   * Salvar a categoria com seu plano tarifário
   * @author Marcos Frare
   * @since 21/02/2020
   */
  save() {
    let btnSave = jQuery('#btnSalvar');
    let btnUpdateTarifas = jQuery('#btnUpdateTarifas');
    btnSave.button('loading');
    this.categoriaTarifaConsumo.id_tipo_consumo = this.tipoConsumo.id;

    if (this.tiposConsumoTarifasCadastroService.validaSave(this.categoriaTarifaConsumo.tarifas)) {
      this.tiposConsumoTarifasHttpService.saveCategoria(this.categoriaTarifaConsumo)
        .subscribe(
          response => {
            btnUpdateTarifas.click();
            this.messageService.success('', 'Categoria gravada com sucesso!!');
            btnSave.button('reset');
            this.close();
          },
          error => {
            this.messageService.error('Erro ao gravar categoria. Tente novamente.', 'Erro', error);
            btnSave.button('reset');
          }
        );
    } else {
      btnSave.button('reset');
    }
  }

}