import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CondominiosHttpService } from 'app/home/condominios';

import { smoothlyMenu } from '../../../app.helpers';
import { AuthService } from '../../../core';

import { Condominio } from '../../../models';
import { TrocaCondominioModalComponent } from './troca-condominio-modal';

declare var jQuery: any;

@Component({
	selector: 'topnavbar',
	templateUrl: 'topnavbar.template.html',
	styleUrls: ['./topnavbar.component.css']
})
export class TopnavbarComponent implements OnInit {
	condominio: Condominio;

	@ViewChild('trocaCondominioModal') trocaCondominioModal: TrocaCondominioModalComponent;

	constructor(private activatedRoute: ActivatedRoute,
			private router: Router,
			private authService: AuthService,
			private condominiosHttpService: CondominiosHttpService) {
		authService.changedCondominio$.subscribe(condominio => this.condominio = condominio);
	}

	ngOnInit() {
		this.condominio = this.authService.getCurrentCondominio();
	}

	toggleNavigation(): void {
		jQuery('body').toggleClass('mini-navbar');
		smoothlyMenu();
	}

	editarCondominio() {
		this.router.navigate(['condominios']);
	}

	showTrocaCondominioModal() {
		this.trocaCondominioModal.open();
	}

	/** Ao trocar o condomínio */
	onTrocarCondominio(condominio: Condominio) {
		this.condominio = condominio;
		this.authService.setLoggedInfo(condominio);
		this.router.navigate(['./dashboard']);
		location.reload();
	}

	logout() {
		this.authService.logout();
	}

}