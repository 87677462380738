import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { RelatorioModalComponent } from 'app/home/relatorios';
import { BoletosHttpService } from '../services';
import { MessageService, AuthService } from 'app/core';
import { EnvioEmailBoletoModalComponent } from '../envio-email-boleto-modal';
import { ConfiguracaoLayoutTitulo } from 'app/models';
import { EnviarEmailTituloModalComponent } from './enviar-email-titulo-modal';

declare var jQuery: any;

@Component({
  selector: 'parametros-listar-titulos-modal',
  templateUrl: './parametros-listar-titulos-modal.component.html',
  styleUrls: ['./parametros-listar-titulos-modal.component.css']
})
export class ParametrosListarTitulosModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @Input() idsTitulos: number[];
  
  @ViewChild('relatorioModal') relatorioModal: RelatorioModalComponent;
  @ViewChild('envioEmailBoletoModalParam') envioEmailBoletoModalParam: EnvioEmailBoletoModalComponent;
  @ViewChild('enviarEmailTituloModal') enviarEmailTituloModal: EnviarEmailTituloModalComponent;

  public filtro: ConfiguracaoLayoutTitulo;

  constructor(private authService: AuthService,
      private boletoHttpService: BoletosHttpService,
      private messageService: MessageService) { }

  ngOnInit() {
    this.filtro = new ConfiguracaoLayoutTitulo();
    this.filtro.id_condominio = this.authService.getIdCurrentCondominio();
    if (!this.idModal) {
      this.idModal = 'parametrosListarTitulosModal';
    };
  }

  /**
   * Busca as configurações de layout do relatório
   * @author Marcos Frare
   * @since 05/09/2020
   */
  private getConfigLayout(): void {
    this.boletoHttpService.getConfigLayout(this.filtro.id_condominio).subscribe(
      response => {
        if (response != null) {
          this.filtro = response;
        }
        this.filtro.id_condominio = this.authService.getIdCurrentCondominio();
        this.filtro.id_usuario = this.authService.getIdCurrentUser();
      },
      error => this.messageService.error('Não foi possível carregar a configuração de layou do relatório!', 'Erro', error)
    );
  }

  public open() {
    this.getConfigLayout();
    this.filtro.idsTitulo = this.idsTitulos;
    jQuery(`#${this.idModal}`).modal('show');
  }
  public close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Listar títulos conforme filtro
   * @author Marcos Frare
   * @since 18/08/2020
   */
  public listar(): void {
    this.filtro.idsTitulo = this.idsTitulos;
    this.relatorioModal.listarTitulos = true;
    this.relatorioModal.url = '/titulo/listar';
    this.relatorioModal.params = this.filtro;
    this.relatorioModal.open();
    this.close();
  }

  /**
   * gravar configurações de layout
   * @author Marcos Frare
   * @since 05/09/2020
   */
  public gravarLayout(): void {
    let btnConfig = jQuery('#btnConfig');
    btnConfig.button('loading');
    this.boletoHttpService.gravarConfigLayout(this.filtro).subscribe(
      response => {
        btnConfig.button('reset');
        this.filtro.id = response.id;
        this.messageService.info('Layout gravado com sucesso', 'Info');
      },
      error => {
        btnConfig.button('reset');
        this.messageService.error('Erro ao gravar layout. Tente novamente', 'Erro', error)
      }
    );
  }

  public openEnviarEmail(): void {
    this.enviarEmailTituloModal.open();
  }

  /**
   * Enviar títulos por e-mail
   * @auhtor Marcos Frare
   * @since 27/08/2020
   */
  public sendMail(param: any): void {
    this.filtro.idsTitulo = this.idsTitulos;
    this.filtro.tipo_morador = param.tipo ? param.tipo : 0;
    this.filtro.email_adicional_unidade = param.email_adicional_unidade ? param.email_adicional_unidade : false;
    this.filtro.email_adicional_condomino = param.email_adicional_condomino ? param.email_adicional_condomino : false;
    this.filtro.tipo_cobranca = param.tipo_cobranca || 'TAXACONDOMINIO';
    this.filtro.tipo_cobranca_outro = param.tipo_cobranca_outro || '';
    this.filtro.id_usuario = this.authService.getIdCurrentUser();

    let btnMail = jQuery('#btnMail');
    btnMail.button('loading');
    this.boletoHttpService.sendMailFiltro(this.filtro).subscribe(
      response => {
        btnMail.button('reset');
        this.envioEmailBoletoModalParam.objetoEmail = response;
        this.envioEmailBoletoModalParam.open();
      },
      error => {
        btnMail.button('reset');
        this.messageService.error('Erro ao enviar e-mails. Tente novamente', 'Erro!', error);
      }
    );
  }

}