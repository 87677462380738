import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { BaseVisualizacaoComponent } from '../../componentes-base';

import { ContaBancaria, tiposContasBancarias } from '../../models';
import { AuthService, MessageService } from '../../core';
import { ContasBancariasHttpService } from './services';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
	selector: 'contas-bancarias',
	templateUrl: 'contas-bancarias.template.html',
	providers: [PermissionService]
})
export class ContasBancariasComponent extends BaseVisualizacaoComponent<ContaBancaria> {

	tiposContas = tiposContasBancarias;

	constructor(activatedRoute: ActivatedRoute,
		router: Router,
		messageService: MessageService,
		private authService: AuthService,
		private contasBancariasHttpService: ContasBancariasHttpService,
		permissionService: PermissionService,
		public spinner: NgxSpinnerService) {
		super('#tableContasBancarias', contasBancariasHttpService, activatedRoute, router, messageService, permissionService, spinner);
	}

	getAll() {
		this.entities = [];
		this.destroyTable();
		this.contasBancariasHttpService.getAllByCondominio(this.authService.getIdCurrentCondominio()).subscribe(
			entities => {
				this.entities = entities;
				this.showTable();
			},
			error => this.messageService.error('Erro ao consultar contas bancárias. Tente novamente', 'Erro', error)
		);
	}

	nomeTipoConta(conta) {
		let tipoConta = this.tiposContas.find(item => item.codigo === conta.tipo);
		return tipoConta.nome;
	}
	public delete(entity: ContaBancaria): void {
		if (confirm(`Tem certeza que deseja excluir a conta bancária ${entity.nome}?`)) {
			this.contasBancariasHttpService.delete(entity).subscribe(
				response => {
					this.messageService.success('Conta bancária excluída com sucesso');
					this.getAll();
				},
				error => {
					switch (error.status) {
						case 403:
							this.messageService.warning('Não é possível excluir a conta bancária selecionadag pois a mesma possui algum lançamento no financeiro', 'Atenção');
							break;
						case 404:
							this.messageService.warning('Conta não encontrada', 'Atenção');
							break;
						default:
							this.messageService.warning('Erro ao excluir conta bancária, favor contate o administrador do sistema.')
							break;
					}
				}
			);
		}
	}

}