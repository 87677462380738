export class TitulosRelFiltro {
	id_cliente: number;
	id_condominio: number;
	id_usuario: number;
	id_bloco: number;
	unidades: number[];
	dataInicial: Date;
	dataFinal: Date;
	tipoFiltro: number;
	dataCompetenciaInicial: Date;
	dataCompetenciaFinal: Date;
	situacao: number;
	nome_sacado: string;
	showValorCorrigido: boolean;
	tipo_envio_boleto: number;
	serie_rateio?: string;
	numero_boleto?: number;
	origem?: string;

	/** Limpa o filtro */
	limpar() {
		this.id_bloco = null;
		this.unidades = [];
		this.dataInicial = null;
		this.dataFinal = null;
		this.tipoFiltro = 1;
		this.dataCompetenciaInicial = null;
		this.dataCompetenciaFinal = null;
		this.situacao = 0;
		this.nome_sacado = '';
		this.showValorCorrigido = false;
	}
}

export const tiposFiltro = [
	{ value: 1, nome: 'Competência' },
	{ value: 2, nome: 'Vencimento' },
	{ value: 3, nome: 'Pagamento' },
	{ value: 4, nome: 'Crédito' }
];