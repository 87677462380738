import { Fornecedor } from './../../models/entidades/fornecedor';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseVisualizacaoComponent } from '../../componentes-base';
import { Orcamento } from '../../models';
import { AuthService, MessageService } from '../../core';
import { OrcamentosHttpService } from './services';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
	selector: 'orcamentos',
	templateUrl: 'orcamentos.template.html',
	styleUrls: ['./orcamentos.component.css'],
	providers: [PermissionService, DatePipe]
})

export class OrcamentosComponent extends BaseVisualizacaoComponent<Orcamento> {

	public idCondominio: number;
	public filtro: any = {};
	public fornecedores: Fornecedor[] = [];

	constructor(activatedRoute: ActivatedRoute,
		router: Router,
		messageService: MessageService,
		permissionService: PermissionService,
		private authService: AuthService,
		private orcamentosHttpService: OrcamentosHttpService,
		private datePipe: DatePipe,
		public spinner: NgxSpinnerService) {
		super('#tableOrcamentos', orcamentosHttpService, activatedRoute, router, messageService, permissionService, spinner);
	}

	ngOnInit() {
		this.idCondominio = this.authService.getIdCurrentCondominio();
		this.setInitialFilter();
		this.search();
		this.getPermissions();
	}

	public getAll(): void {
		this.spinner.show();
		this.entities = [];
		this.destroyTable();
		this.orcamentosHttpService.getAllByCondominio(this.idCondominio)
			.subscribe(
				entity => {
					this.spinner.hide();
					this.entities = entity;
					this.showTable();
				},
				error => {
					this.spinner.hide();
					this.messageService.error('Erro ao carregar os orçamentos. Tente novamente', 'Erro', error);
				}
			);
	}

	public search(): void {
		this.spinner.show();
		this.entities = [];
		this.destroyTable();
		this.orcamentosHttpService.search(this.parseSearch(this.filtro))
			.subscribe(
				entities => {
					this.spinner.hide();
					this.entities = entities;
					this.showTable();
				},
				error => {
					this.spinner.hide();
					this.messageService.error('Erro ao carregar os orçamentos. Tente novamente', 'Erro', error);
				}
			);
	}

	public setInitialFilter(): void {
		const today = new Date();
		const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
		const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
		this.filtro.id_condominio = this.idCondominio;
		this.filtro.data_orcamento_inicial = firstDay;
		this.filtro.data_orcamento_final = lastDay;
	}

	public delete(orcamento: Orcamento): void {
		if (confirm(`Tem certeza que deseja excluir o orçamento?`)) {
			this.spinner.show();
			this.orcamentosHttpService.delete(orcamento).subscribe(
				response => {
					this.spinner.hide();
					this.messageService.success('Orçamento excluído com sucesso');
					this.search();
				},
				error => {
					this.spinner.hide();
					this.messageService.error('Erro', 'Erro ao excluir orçamento', error);
				}
			);
		}
	}

	private parseSearch(originalSearch: any): any {
		let search = { ...originalSearch };

		originalSearch.data_orcamento_inicial ?
			search.data_orcamento_inicial = this.datePipe.transform(originalSearch.data_orcamento_inicial, 'yyyy-MM-dd') :
			delete search.data_orcamento_inicial;

		originalSearch.data_orcamento_final ?
			search.data_orcamento_final = this.datePipe.transform(originalSearch.data_orcamento_final, 'yyyy-MM-dd') :
			delete search.data_orcamento_final;

		if (!originalSearch.id_condominio) delete search.id_condominio;

		return search;
	}
}
