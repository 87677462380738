import { Bloco } from "app/models";

export class CarneListarFiltro {
  id_cliente: number;
  id_condominio: number;
  id_usuario: number;
  tipoFiltro: number;
  vencimento_inicial: Date;
  vencimento_final: Date;
  id_bloco: number;
  ids_unidades: number[];
  origem: string;
  numero_acordo: number;
  numero_cobranca: number;
  serie_rateio: string;

  bloco: Bloco;

  /** Limpa o filtro */
  limpar() {
    const today = new Date();
    this.vencimento_inicial = new Date(today.getFullYear(), 0, 1); // primeiro dia do ano
    this.vencimento_final = new Date(today.getFullYear(), 11, 31); // último dia do ano
    this.bloco = new Bloco();
    this.id_bloco = null;
    this.ids_unidades = [];
    this.origem = "";
    this.numero_acordo = null;
    this.numero_cobranca = null;
    this.serie_rateio = '';
  }
}