import { NgModule } from '@angular/core';
import { FundosMovimentacaoRelComponent } from './fundos-movimentacao-rel.component';
import { SharedModule } from 'app/shared';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';

@NgModule({
  imports: [
    SharedModule,
    MultiselectDropdownModule,
  ],
  declarations: [FundosMovimentacaoRelComponent]
})
export class FundosMovimentacaoRelModule { }
