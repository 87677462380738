import { Bloco, Unidade } from "app/models";

export class CertidaoNegativaDebitoFiltro {
	id_cliente: number;
	id_condominio: number;
	id_usuario: number;
	id_bloco: number;
	id_unidade: number;
	dataInicial: Date;
	dataFinal: Date;

	bloco: Bloco;
	unidade: Unidade;

	/** Limpa o filtro */
	limpar() {
		const today = new Date((new Date).getFullYear(), (new Date).getMonth(), 1);
		const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
		this.dataInicial = today;
		this.dataFinal = lastDay;
		this.id_bloco = null;
		this.id_unidade = null;
		this.id_usuario = null;
		this.bloco = new Bloco();
		this.unidade = new Unidade();
	}
}