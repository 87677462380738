import { BaseEntity } from '../base-entity';
import { CategoriaTarifaConsumo } from './categoria-tarifa-consumo';

export class CategoriaServicoUnidade extends BaseEntity {
	id_categoria_tarifa_consumo: number;
	id_unidade: number;
	categoria_tarifa_consumo: CategoriaTarifaConsumo;

	constructor() {
		super();
	}
}
