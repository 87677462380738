import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService, MessageService } from 'app/core';
import { RelatorioModalComponent } from '../relatorio-modal';
import { ChequeRelatorioFiltro } from './cheque-relatorio-filtro';

@Component({
  selector: 'cheque-relatorio',
  templateUrl: './cheque-relatorio.component.html',
  styleUrls: ['./cheque-relatorio.component.css']
})
export class ChequeRelatorioComponent implements OnInit {

  @ViewChild('relatorioModal') relatorioModal: RelatorioModalComponent;

  public filtro: ChequeRelatorioFiltro = new ChequeRelatorioFiltro();

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.limpar();
    this.filtro.id_cliente = this.authService.getIdCurrentCliente();
		this.filtro.id_condominio = this.authService.getIdCurrentCondominio();
  }

  public limpar(): void {
		this.filtro.limpar();
    this.filtro.id_cliente = this.authService.getIdCurrentCliente();
		this.filtro.id_condominio = this.authService.getIdCurrentCondominio();
    this.filtro.id_usuario = this.authService.getIdCurrentUser();
	}

  public view(): void {
    this.relatorioModal.url = '/relatorio/cheque';
    this.relatorioModal.params = this.filtro;
    this.relatorioModal.open();
	}

}
