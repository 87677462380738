import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';

import { Crud, CrudService, ConfigService, CustomHttp, AuthService } from '../../../../core';
import { EventoNotificacao } from '../../../../models'

@Injectable()
export class NotificacoesHttpService implements Crud<EventoNotificacao> {

	resource = 'notificacao';
	private baseUrl: string;

	constructor(private customHttp: CustomHttp,
		private crudService: CrudService,
		private configService: ConfigService,
		private authService: AuthService) {
		this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
	}

	/**
	 * Busca as notificações pelo filtro
	 * @param filtro - Filto da busca
	 * @author Marcos Frare
	 * @since 10/02/2020
	 */
	public search(filtro: any): Observable<any> {
		return this.customHttp.get(`${this.baseUrl}/consultar`, {params: filtro}).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}
	public search_(filtro: any): Observable<any> {
		return this.customHttp.post(`${this.baseUrl}/`, filtro).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Geração de notificações
	 * @param filtro - Filtro para geração de notificações
	 * @author Marcos Frare
	 * @since 10/02/2020
	 */
	public gerarNotificacao(filtro: any) {
		return this.customHttp.post(`${this.baseUrl}/gerarNotificacao`, filtro).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Exclusão de uma notificação
	 * @param notificacao - Entidade para exclusão
	 * @author Marcos Frare
	 * @since 10/02/2020
	 */
	public deleteNotificacao(notificacao): Observable<string> {
		return this.customHttp.delete(`${this.baseUrl}/excluirNotificacao/${notificacao.id}`, { body: { id_usuario: this.authService.getCurrentUser().id } }).pipe(
			map(response => response),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Exclusão de um evento de notificação
	 * @param eventoNotificacao - Entidade para exclusão
	 * @author Marcos Frare
	 * @since 10/02/2020
	 */
	public deleteEventoNotificacao(eventoNotificacao): Observable<string> {
		return this.customHttp.delete(`${this.baseUrl}/excluirEventoNotificacao/${eventoNotificacao.id}`, { body: { id_usuario: this.authService.getCurrentUser().id } }).pipe(
			map(response => response),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Envia emails de um evento de notificação
	 * @param eventoNotificacaoId 
	 * @author Marcos Frare
	 * @since 10/06/2020
	 */
	public enviarEmailEvento(eventoNotificacaoId: number): Observable<any> {
		return this.customHttp.post(`${this.baseUrl}/email/evento`, { id: eventoNotificacaoId, id_usuario: this.authService.getIdCurrentUser() }).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}
	/**
	 * Envia emails de um evento de notificação
	 * @param eventoNotificacaoId 
	 * @author Marcos Frare
	 * @since 10/06/2020
	 */
	public enviarEmailNotificacao(notificacaoId: number): Observable<any> {
		return this.customHttp.post(`${this.baseUrl}/email`, { id: notificacaoId, id_usuario: this.authService.getIdCurrentUser() }).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public get(id: number): Observable<EventoNotificacao> {
		return this.crudService.get<EventoNotificacao>(this.resource, id);
	}

	public getAll(): Observable<EventoNotificacao[]> {
		return this.crudService.getAll<EventoNotificacao>(this.resource, { params: { id_condominio: this.authService.getIdCurrentCondominio() } });
	}

	public post(entity: EventoNotificacao): Observable<EventoNotificacao> {
		return this.crudService.post<EventoNotificacao>(this.resource, entity);
	}

	public put(entity: EventoNotificacao): Observable<EventoNotificacao> {
		return this.crudService.put<EventoNotificacao>(this.resource, entity);
	}

	public delete(entity: EventoNotificacao): Observable<string> {
		const id_lancamento_unidade = entity.id;
		const id_usuario = this.authService.getIdCurrentUser();
		return this.customHttp.post(`${this.baseUrl}/desativar`, { id_lancamento_unidade, id_usuario }).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

}