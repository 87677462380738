import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { BaseCadastroComponent } from '../../../componentes-base';
import { AuthService, MessageService } from '../../../core';
import { GrupoDeUsuario, AcessoPainel } from '../../../models';
import { GruposDeUsuariosHttpService } from '../services';
import { AcessosComponent } from '../acessos';
import { PermissionService } from '../../../core/services/permission/permission.service';
import { AcessosPainelEnvio, AcessosPainelHttpService } from '../acessos-painel/services';

declare var jQuery: any;

@Component({
	selector: 'grupos-de-usuarios-cadastro',
	templateUrl: './grupos-de-usuarios-cadastro.component.html',
	styleUrls: ['./grupos-de-usuarios-cadastro.component.css'],
	providers: [PermissionService, AcessosPainelHttpService]
})

export class GruposDeUsuariosCadastroComponent extends BaseCadastroComponent<GrupoDeUsuario> {

	public graficos: AcessoPainel[] = [];
	public cards: AcessoPainel[] = [];
	public autorizacoes: AcessoPainel[] = [];
	public allGraficos: AcessoPainel[];
	public allCards: AcessoPainel[];
	public allAutorizacoes: AcessoPainel[];

	@ViewChild('acessos') acessos: AcessosComponent;
	@ViewChild('acessosM') acessosM: AcessosComponent;

	constructor(public activatedRoute: ActivatedRoute,
			public router: Router,
			public messageService: MessageService,
			public permissionService: PermissionService,
			public gruposDeUsuariosHttpService: GruposDeUsuariosHttpService,
			private authService: AuthService,
			private acessosPainelHttpService: AcessosPainelHttpService) {
		super(GrupoDeUsuario, gruposDeUsuariosHttpService, activatedRoute, router, messageService, permissionService);
		this.getParams();
	}

	ngOnInit() {
		this.getPermissions();
		this.getAllAcessosPainel();
	}

	private getParams(): void {
		this.activatedRoute.params.subscribe((params: Params) => {
			let id = +params['id'];
			
			this.entity = new GrupoDeUsuario();
			if (!isNaN(id)) {
				this.editMode = true;
				this.load(id);
				this.entity.id = id;
			} else {
				this.editMode = false;
				this.load();
			}
		});
	}

	public load(id: number = null): void {
		this.gruposDeUsuariosHttpService.get(id).subscribe(
			entity => {
				this.entity = entity;
				this.entity.id = id;
				this.afterEdit(this.entity);
				
			},
			error => this.messageService.error('Erro ao carregar o registro', 'Erro', error)
		);
	}

	public beforeSave(): boolean {
		this.entity.id_cliente = this.authService.getIdCurrentCliente();
		this.btnSave = jQuery('#btnSave');
		this.btnSave.button('loading');
		return true;
	}

	public afterEdit(entity: GrupoDeUsuario): void {
		if (!this.editMode) return;
		this.getAllAcessosPainelByGrupo();
	}

	public afterSave(entity: GrupoDeUsuario): void {
		this.saveAcessosPainel(entity);
		this.btnSave.button('reset');
	}

	public onChangeAcessoGrafico(grafico: AcessoPainel): void {
		grafico.acesso ? this.addAcessoGrafico(grafico) : this.removeAcessoGrafico(grafico);
	}

	public onChangeAcessoCard(card: AcessoPainel): void {
		card.acesso ? this.addAcessoCard(card) : this.removeAcessoCard(card);
	}

	public onChangeAcessoAutorizacao(autorizacao: AcessoPainel): void {
		autorizacao.acesso ? this.addAcessoAutorizacao(autorizacao) : this.removeAcessoAutorizacao(autorizacao);
	}

	private saveAcessosPainel(entity: GrupoDeUsuario): void {
		let acessosPainelEnvio = new AcessosPainelEnvio(entity.id, this.graficos, this.cards, this.autorizacoes);
		this.acessosPainelHttpService.save(acessosPainelEnvio).subscribe(
			() => super.afterSave(entity),
			error => this.messageService.error('Erro ao salvar acessos do painel', 'Erro', error)
		);
	}

	private getAllAcessosPainel(): void {
		this.acessosPainelHttpService.getAll().subscribe(
			acessosPainel => this.setAllAcessosPainel(acessosPainel),
			error => this.messageService.error('Erro ao obter os acessos do painel. Favor contate o administrador do sistema', 'Erro', error)
		);
	}

	private setAllAcessosPainel(acessosPainel: any): void {
		this.allGraficos = acessosPainel.graficos;
		this.allCards = acessosPainel.cards;
		this.allAutorizacoes = acessosPainel.autorizacoes;
	}

	private getAllAcessosPainelByGrupo(): void {
		this.acessosPainelHttpService.getAllByGrupoDeUsuario(this.entity.id).subscribe(
			acessosPainel => this.setAcessosPainelByGrupoDeUsuario(acessosPainel),
			error => this.messageService.error('Erro ao obter os acessos do painel. Favor contate o administrador do sistema', 'Erro', error)
		);
	}

	private setAcessosPainelByGrupoDeUsuario(acessosPainel: any): void {
		this.graficos = acessosPainel.graficos;
		this.cards = acessosPainel.cards;
		this.autorizacoes = acessosPainel.autorizacoes;
		this.mergeGraficos();
		this.mergeCards();
		this.mergeAutorizacoes();
	}

	private mergeGraficos(): void {
		this.allGraficos.forEach(grafico => {
			let graficoAcesso = this.graficos.find(acesso => grafico.codigo === acesso.codigo);
			grafico.acesso = graficoAcesso ? true : false;
		});
	}

	private mergeCards(): void {
		this.allCards.forEach(card => {
			let cardAcesso = this.cards.find(acesso => card.codigo === acesso.codigo);
			card.acesso = cardAcesso ? true : false;
		});
	}

	private mergeAutorizacoes(): void {
		this.allAutorizacoes.forEach(autorizacao => {
			let autorizacaoAcesso = this.autorizacoes.find(acesso => autorizacao.codigo === acesso.codigo);
			autorizacao.acesso = autorizacaoAcesso ? true : false;
		});
	}

	private addAcessoGrafico(grafico: AcessoPainel): void {
		grafico.sequencia = 0;
		this.graficos.push(grafico);
	}

	private removeAcessoGrafico(grafico: AcessoPainel): void {
		let index: number = this.graficos.findIndex(item => grafico.codigo === item.codigo);
		this.graficos.splice(index, 1);
	}

	private addAcessoCard(card: AcessoPainel): void {
		card.sequencia = 0;
		this.cards.push(card);
	}

	private removeAcessoCard(card: AcessoPainel): void {
		let index: number = this.cards.findIndex(item => card.codigo === item.codigo);
		this.cards.splice(index, 1);
	}

	private addAcessoAutorizacao(autorizacao: AcessoPainel): void {
		autorizacao.sequencia = 0;
		this.autorizacoes.push(autorizacao);
	}

	private removeAcessoAutorizacao(autorizacao: AcessoPainel): void {
		let index: number = this.cards.findIndex(item => autorizacao.codigo === item.codigo);
		this.autorizacoes.splice(index, 1);
	}
	
}