import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { ClientesComponent } from './clientes.component';
import { ClientesCadastroComponent } from './cadastro/clientes-cadastro.component';

@NgModule({
	imports: [
		SharedModule,
		TypeaheadModule
	],
	declarations: [
		ClientesComponent,
		ClientesCadastroComponent
	]
})
export class ClientesModule { }