import { NgModule } from '@angular/core';

import { SharedModule } from '../../../shared';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { BlocosHttpService } from '../../blocos';
import { CertidaoNegativaDebitoComponent } from './certidao-negativa-debito.component';
import { CertidaoNegativaDebitoHttpService } from './services';
import { CertidaoNegativaDebitoCadastroComponent } from './cadastro/certidao-negativa-debito-cadastro.component';

@NgModule({
  imports: [
    SharedModule,
    TypeaheadModule,
    MultiselectDropdownModule
  ],
  declarations: [
    CertidaoNegativaDebitoComponent,
    CertidaoNegativaDebitoCadastroComponent
  ],
  providers: [
    BlocosHttpService,
    CertidaoNegativaDebitoHttpService
  ]
})
export class CertidaoNegativaDebitoModule { }