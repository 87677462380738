import { BaseEntity } from '../base-entity';
import { Endereco } from './endereco';
import { Telefone } from './telefone';

export class Cliente extends BaseEntity {
	id: number;
	nome_fantasia: string;
	razao_social: string;
	cnpj: string;
	email: string;
	id_endereco: number;
	endereco: Endereco;
	id_grupo_de_usuario: number;
	telefones: Telefone[];
	cpf: string;
	rg: string;
	data_nascimento: Date;
	nome_cliente: string;
	id_usuario: number;
	id_usuario_cliente: number;
	site: string;
	limite_condominios: number;
	constructor() {
		super();
		this.endereco = new Endereco();
		this.telefones = [];
	}
}

export const tiposCliente = [
	{ id: 1, nome: 'Ativo' },
	{ id: 2, nome: 'Inativo' },
	{ id: 3, nome: 'Cancelado' },
	{ id: 4, nome: 'Demonstração' },
	{ id: 5, nome: 'Inadimplente' },
	{ id: 6, nome: 'Bloqueado' }
];