import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseVisualizacaoComponent } from '../../componentes-base';
import { AuthService, MessageService } from '../../core';
import { DemonstrativoRateio, VIEW_MODE } from '../../models';
import { RateiosHttpService } from './services';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
	selector: 'rateios',
	templateUrl: './rateios.component.html',
	styleUrls: ['./rateios.component.css'],
	providers: [PermissionService]
})
export class RateiosComponent extends BaseVisualizacaoComponent<DemonstrativoRateio> {

	constructor(public activatedRoute: ActivatedRoute,
		public router: Router,
		public messageService: MessageService,
		private authService: AuthService,
		private rateiosHttpService: RateiosHttpService,
		public permissionService: PermissionService,
		public spinner: NgxSpinnerService) {
		super('#tableRateios', rateiosHttpService, activatedRoute, router, messageService, permissionService, spinner);
	}

	public getAll(): void {
		this.entities = [];
		this.destroyTable();
		this.rateiosHttpService.getAllByCondominio(this.authService.getIdCurrentCondominio()).subscribe(
			entities => {
				this.entities = entities;
				this.showTable(true);
			},
			error => this.messageService.error('Erro ao carregar os rateios do condomínio selecionado. Tente novamente', 'Erro', error)
		);
	}

	public visualizar(entity: DemonstrativoRateio): void {
		this.router.navigate([entity.id, 'unidades', 'editar'], {
			relativeTo: this.activatedRoute,
			queryParams: {
				viewMode: VIEW_MODE.VIEW,
			}
		});
	}

	public delete(entity: DemonstrativoRateio) {
		this.spinner.show();
		// Validar exclusão
		this.rateiosHttpService.validarExclusao(entity.id).subscribe(
			response => {
				this.spinner.hide();
				if (response.permite) {
					let msg = 'Atenção! A exclusão do rateio implicará também na exclusão de títulos e outros dados referente ao mesmo. Deseja realmente prosseguir com a exclusão?';
					if (response.mensagem) {
						msg = response.mensagem;
					}
					if (confirm(msg)) {
						this.spinner.show();
						this.rateiosHttpService.delete(entity).subscribe(
							() => {
								this.spinner.hide();
								this.getAll();
								this.messageService.success('Rateio excluído com sucesso', 'Sucesso');
							},
							error => {
								this.spinner.hide();
								switch (error.status) {
									case 403:
										this.messageService.warning('O rateio que está tentando excluir já possui um arquivo remessa gerado, ' +
											'portanto não será possível realizar a operação. Favor contate o administrador do sistema', 'Atenção');
										break;
									case 404:
										this.messageService.error('Rateio não encontrado. Favor tente novamente', 'Erro', error);
										break;
									default:
										this.messageService.error('Não foi possível excluir o rateio selecionado. Favor tente novamente' +
											' mais tarde.', 'Erro', error);
										break;
								}
							}
						);
					}
				} else {
					this.messageService.warning(`Não é possivel a exclusão do Rateio. Motivo: ${response.mensagem}`, 'Atenção!');
				}
			},
			error => {
				this.spinner.hide();
				this.messageService.error('Erro ao validar exclusão de rateio!', 'Erro', error);
			}
		);
	}

	/**
	 * Editar o rateio
	 * @param entity 
	 * @author Marcos Frare
	 * @since 08/03/2021
	 */
	public edit(entity: DemonstrativoRateio): void {
		this.spinner.show();
		this.rateiosHttpService.validarEdicao(entity.id).subscribe(
			response => {
				this.spinner.hide();
				if (response.permite) {
					if (response.mensagem) {
						if (confirm(response.mensagem)) {
							super.edit(entity);
						}
					} else {
						super.edit(entity);
					}
				} else {
					this.spinner.hide();
					this.messageService.warning(response.mensagem, 'Atenção!');
				}
			},
			error => {
				this.spinner.hide();
				this.messageService.error('Erro ao validar edição de rateio! Tente novamente', 'Erro', error);
			}
		);
	}

}