import { NgModule, APP_INITIALIZER } from '@angular/core';
import { RequestOptions, XHRBackend } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';

import { ConfigService, initConfig } from './config/config.service';
import { AuthGuard, AuthService, JwtTokenService, CrudService, MaskService, MessageService, DataTablesService,
         DataUtilsService, FormsUtilsService, SessionStorageService, LocalStorageService, MenuService } from './services';
import { CustomHttp, DefaultRequestOptionsService, customHttpFactory } from './services/http';


import { TypeaheadModule } from 'ngx-bootstrap/typeahead';


/**
 * Module de providres singletons, ou seja, que devem possuir uma única instância para a aplicação toda e
 * com imports que devem ser feitos uma única vez.
 */
@NgModule({
    imports: [
        HttpClientModule,
        TypeaheadModule.forRoot()
    ],
    providers: [
        ConfigService,
        {provide: APP_INITIALIZER, useFactory: initConfig, deps: [ConfigService], multi: true},
        AuthGuard,
        AuthService,
        JwtTokenService,
        CrudService,
        MaskService,
        MessageService,
        DataTablesService,
        DataUtilsService,
        FormsUtilsService,
        MenuService,
        SessionStorageService,
        LocalStorageService,
        DefaultRequestOptionsService,
        {provide: CustomHttp, useFactory: customHttpFactory, deps: [XHRBackend, RequestOptions, DefaultRequestOptionsService, AuthService]}
    ],
})
export class CoreModule { }
