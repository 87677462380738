import { BaseEntity } from "../base-entity";

export class EmailEnvio extends BaseEntity {
	id_cliente: number;
	id_condominio: number;
	id_usuario: number;
	situacao: string;
	remetente: string;
	unidade: string;
	nome: string;
	email: string;
	assunto: string;
	mensagem: string;
	anexo: boolean;
	created_at: Date;
}