import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';

import { Crud, CrudService, ConfigService, CustomHttp } from '../../../../core';

import { Telefone } from '../../../../models';

@Injectable()
export class TelefonesService implements Crud<Telefone> {

	resource: string = 'telefone';
	baseUrl: string;

	constructor(private customHttp: CustomHttp,
		private crudService: CrudService,
		private configService: ConfigService) {
		this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
	}

	get(id: number): Observable<Telefone> {
		return this.crudService.get<Telefone>(this.resource, id);
	}

	getAll(): Observable<Telefone[]> {
		return this.crudService.getAll<Telefone>(this.resource);
	}

	post(entity: Telefone): Observable<Telefone> {
		return this.crudService.post<Telefone>(this.resource, entity);
	}

	put(entity: Telefone): Observable<Telefone> {
		return this.crudService.put<Telefone>(this.resource, entity);
	}

	delete(entity: Telefone): Observable<string> {
		return this.crudService.delete<Telefone>(this.resource, entity);
	}

	getAllByUsuario(idUsuario: number): Observable<Telefone[]> {
		return this.customHttp.get(`${this.baseUrl}/consultar/usuario/${idUsuario}`)
			.pipe(
				map(response => response.json()),
				catchError(error => _throw(error))
			);
	}

}