
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import {LogoCliente} from '../../../models';
import { Crud, CrudService, ConfigService, CustomHttp } from '../../../core';
import {Documento} from '../../../models/entidades/documento';
import {catchError, map} from 'rxjs/operators';
import {_throw} from 'rxjs-compat/observable/throw';

@Injectable()
export class LogoClienteHttpService implements Crud<LogoCliente> {
    resource = 'logosistema';
    private baseUrl: string;

    constructor(private customHttp: CustomHttp,
                private crudService: CrudService,
                private configService: ConfigService) {

        this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
    }

    public get(id: number): Observable<LogoCliente> {
        return this.crudService.get<LogoCliente>(this.resource, id);
    }

    public getAll(): Observable<LogoCliente[]> {
        return this.crudService.getAll<LogoCliente>(this.resource);
    }

    public post(entity: LogoCliente): Observable<LogoCliente> {
        return this.crudService.post<LogoCliente>(this.resource, entity);
    }

    public put(entity: LogoCliente): Observable<LogoCliente> {
        return this.crudService.put<LogoCliente>(this.resource, entity);
    }

    public delete(entity: LogoCliente): Observable<string> {
        return this.crudService.delete<LogoCliente>(this.resource, entity);
    }

    public getByCliente(id_cliente: number): Observable<LogoCliente> {
        return this.customHttp.get(`${this.baseUrl}/consultar/cliente/${id_cliente}`)
            .pipe(
                map(response => response.json()),
                catchError(error => _throw(error))
            );
    }
}
