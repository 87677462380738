import { BaseEntity } from "../base-entity";
import { Bloco } from "./bloco";
import { Anexo } from './anexo';

export class Inventario extends BaseEntity {
	id: number;
	id_condominio: number;
	id_bloco: number;
	nome: string;
	localizacao: string;
	bloco: Bloco;
	codigo_patrimonial: number;
	marca: string;
	modelo: string;
	descricao: string;
	valor_aquisicao: any;
	data_aquisicao: Date;
	id_fornecedor: number;
	anexos: Anexo[];

	constructor() {
		super();
		this.anexos = [new Anexo()];
	}
}

export const tiposInventario = [
	{ id: 1, nome: 'Máquinas' },
	{ id: 2, nome: 'Equipamentos de piscina' },
	{ id: 3, nome: 'Equipamentos de ginástica' },
	{ id: 4, nome: 'Equipamentos de jardinagem' },
	{ id: 5, nome: 'Extintores de incêndio' },
	{ id: 6, nome: 'Hidrantes' },
	{ id: 7, nome: 'Veículos / Utilitários' },
	{ id: 8, nome: 'Utensílios de cozinha' },
	{ id: 9, nome: 'Utensílios de churrasqueira' }
];