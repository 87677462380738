import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseCadastroComponent } from '../../../componentes-base';
import { AuthService, MessageService } from '../../../core';
import { Fornecedor, Inventario, tiposInventario, Bloco, Anexo } from '../../../models';
import { InventariosHttpService } from '../services';
import { FornecedoresHttpService, FornecedoresCadastroModalComponent } from '../../fornecedores';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { PermissionService } from '../../../core/services/permission/permission.service';
import { FloatFormatPipe } from '../../../shared/pipes';
import { BlocosHttpService, BlocosPesquisaModalComponent } from 'app/home/blocos';

declare var jQuery: any;

@Component({
    selector: 'inventarios-cadastro',
    templateUrl: './inventarios-cadastro.component.html',
    styleUrls: ['./inventarios-cadastro.component.css'],
    providers: [
        PermissionService,
        FloatFormatPipe
    ]
})
export class InventariosCadastroComponent extends BaseCadastroComponent<Inventario> implements OnInit{

    @ViewChild('fornecedoresCadastroModal') fornecedoresCadastroModal: FornecedoresCadastroModalComponent;
    @ViewChild('blocosPesquisaModal') blocosPesquisaModal: BlocosPesquisaModalComponent;

    public blocos: Bloco[] = [];
    public fornecedores: Fornecedor[] = [];
    public tiposInventario: any[] = tiposInventario;
    public idCliente: number;
    public idCondominio: number;

    constructor(activatedRoute: ActivatedRoute,
                router: Router,
                messageService: MessageService,
                permissionService: PermissionService,
                inventariosHttpService: InventariosHttpService,
                private authService: AuthService,
                private fornecedoresHttpService: FornecedoresHttpService,
                private floatFormatPipe: FloatFormatPipe,
                private blocosHttpService: BlocosHttpService) {
        super(Inventario, inventariosHttpService, activatedRoute, router, messageService, permissionService);
    }

    ngOnInit() {
        this.idCliente = this.authService.getIdCurrentCliente();
        this.idCondominio = this.authService.getIdCurrentCondominio();
        this.getAllFornecedoresByCliente();
        this.getAllBlocosByCondominio();
        super.ngOnInit();
    }

    private getAllFornecedoresByCliente(): void {
        this.fornecedoresHttpService.getAllByCliente(this.idCliente).subscribe(
            entities => this.fornecedores = entities,
            error => this.messageService.error('Erro ao carregar os fornecedores', 'Erro', error)
        );
    }

    private getAllBlocosByCondominio(): void {
        this.blocosHttpService.getAllByCondominio(this.idCondominio).subscribe(
            blocos => this.blocos = blocos,
            error => this.messageService.error('Erro ao carregar os blocos do condomínio. Tente novamente', 'Erro', error)
        );
    }

    public onSelectBloco(tm: TypeaheadMatch): void {
        this.setBloco(tm.item as Bloco);
    }

    private setBloco(selectedBloco: Bloco): void {
        this.entity.bloco = selectedBloco;
        this.entity.id_bloco = selectedBloco.id;
    }

    public openBlocosPequisaModal(): void {
        this.blocosPesquisaModal.open();
    }

    public onSelectBlocosPesquisaModal(bloco: Bloco): void {
        if (bloco) {this.setBloco(bloco); };
        jQuery('#bloco').focus();
    }

    public onCleanBloco(input: any): void {
        this.entity.bloco = null;
        input.value = '';
        input.focus();
    }

    public afterEdit(entity: Inventario): void {
        entity.valor_aquisicao = this.floatFormatPipe.transform(entity.valor_aquisicao);
        if (!entity.anexos.length) {
            this.addAnexos();
        }
        this.loadBloco(entity);
    }

    private loadBloco(entity: Inventario) {
        if (!entity.id_bloco) { return; };
        this.blocosHttpService.get(entity.id_bloco).subscribe(
            bloco => this.setBloco(bloco),
            error => this.messageService.error('Erro ao carregar o bloco do inventário', 'Erro', error)
        );
    }

    public createFornecedor(): void {
        this.fornecedoresCadastroModal.open();
    }

    public onAfterSaveFornecedor(fornecedor: Fornecedor): void {
        this.entity.id_fornecedor = fornecedor.id;
        this.fornecedores.push(fornecedor);
        jQuery('#fornecedor').focus();
    }

    public beforeSave(): boolean {
        if (!this.editMode) {
            this.entity.id_condominio = this.idCondominio;
        }
        this.validateAnexos();
        return true;
    }

    private validateAnexos(): void {
        if (this.entity.anexos) {
            this.entity.anexos = this.entity.anexos.filter(anexo => anexo && anexo.arquivo);
        }
    }

    public save(): Promise<any> {
        this.btnSave = jQuery('#btnSave');
        return new Promise<any>((resolve, reject) => {
            this.makeAllFieldsDirty();

            if (this.form.valid) {
                if (this.beforeSave()) {
                    this.btnSave.button('loading');

                    const envio: any = {...this.entity};

                    if (this.editMode) { // PUT
                        this.crudService.put(envio)
                            .subscribe(
                                entity => {
                                    this.messageService.success('', 'Registro salvo com sucesso');
                                    this.afterSave(this.entity);
                                    resolve(entity);
                                },
                                error => {
                                    this.messageService.error('Erro ao atualizar o registro', 'Erro', error);
                                    this.btnSave.button('reset');
                                    reject(null);
                                }
                            );
                    } else { // POST
                        this.crudService.post(envio)
                            .subscribe(
                                entity => {
                                    this.entity = entity;
                                    this.messageService.success('', 'Registro salvo com sucesso');
                                    this.afterSave(this.entity);
                                    resolve(entity);
                                },
                                error => {
                                    this.messageService.error('Erro ao salvar o registro', 'Erro', error);
                                    this.btnSave.button('reset');
                                    reject(null);
                                }
                            );
                    }

                } else {
                    reject(null);
                }
            } else {
                this.showMessageFormInvalid();
                reject(null);
            }
        });
    }

    public afterSave(entity: Inventario): void {
        // entity.valor_aquisicao = this.floatFormatPipe.transform(entity.valor_aquisicao);
        super.afterSave(entity);
    }

    public cancel(): void {
        this.router.navigate(['../'], {relativeTo: this.activatedRoute});
    }

    public addAnexos(): void {
        this.entity.anexos.push(new Anexo());
    }

    public removeAnexos(index): void {
        this.entity.anexos.splice(index, 1);
    }
}
