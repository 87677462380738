import { BaseEntity } from "../base-entity";
import { Anexo } from "./anexo";
import { ContaBancaria } from "./conta-bancaria";

export class ExtratoAnexo extends BaseEntity {
	id_conta_bancaria: number;
	id_anexo: number;
	competencia: Date;

	contaBancaria: ContaBancaria;
	anexo: Anexo;

	constructor() {
		super();
		this.anexo = new Anexo();
	}
}