import { BaseEntity } from "../base-entity";
import { Bloco } from "./bloco";
import { CategoriaContas } from "./categoria-contas";
import { Condomino } from "./condomino";
import { Unidade } from "./unidade";

export class Antecipacao extends BaseEntity {
	id_bloco: number;
	id_unidade: number;
	id_categoria_conta: number;
	id_condomino: number;
	data_vencimento: Date;
	valor: number;
	observacao: string;

	bloco: Bloco;
	unidade: Unidade;
	categoriaConta: CategoriaContas;
	condomino: Condomino;

	constructor() {
		super();
		this.bloco = new Bloco();
		this.unidade = new Unidade();
		this.condomino = new Condomino();
	}
}