import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'integerFormat'
})
export class IntegerFormatPipe implements PipeTransform {

    transform(value: any, mode: string = 'c') {
        if (value == null) {
            return '';
        }

        switch (mode) {
            case 'c':
                return Math.round(Number(value * 100));
            case 'm':
                return Math.round(Number(value * 1000));
            case 'dm':
                return Math.round(Number(value * 10000));
            case 'cm':
                return Math.round(Number(value * 100000));
            case 'cc':
                return Math.round(Number(value * 1000000));
            default:
                return '';
        }
    }
}
