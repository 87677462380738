import { Component, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { MessageService, AuthService } from '../../../core';
import { BaseVisualizacaoComponent } from '../../../componentes-base';
import { PermissionService } from 'app/core/services/permission/permission.service';

import { Unidade, Bloco, Condominio, EventoNotificacao, tiposNotificacao, formasNotificacao, statusNotificacao } from './../../../models';
import { NotificacaoFiltro } from './notificacao-filtro';
import { NotificacoesHttpService } from './services';
import { StatusEnvioEmailComponent } from './status-envio-email';
import { datepickerDefaultOptions } from '../../../shared';
import { NgxSpinnerService } from 'ngx-spinner';
import { BlocosHttpService, BlocosPesquisaModalComponent, UnidadesHttpService, UnidadesPesquisaModalComponent } from 'app/home/blocos';
import { CadastroNotificacaoModalComponent } from './cadastro-notificacao-modal';
import { RelatorioModalComponent } from '../relatorio-modal';

declare var jQuery: any;

@Component({
  selector: 'notificacao',
  templateUrl: './notificacao.component.html',
  styleUrls: ['./notificacao.component.css'],
  providers: [PermissionService]
})
export class NotificacaoComponent extends BaseVisualizacaoComponent<EventoNotificacao> {

  @ViewChild('inputBloco') inputBloco: ElementRef;
  @ViewChild('blocosPesquisaModal') blocosPesquisaModal: BlocosPesquisaModalComponent;
  @ViewChild('inputUnidade') inputUnidade: ElementRef;
  @ViewChild('unidadesPesquisaModal') unidadesPesquisaModal: UnidadesPesquisaModalComponent;
  @ViewChild('cadastroNotificacaoModal') cadastroNotificacaoModal: CadastroNotificacaoModalComponent;
  @ViewChild('relatorioModal') relatorioModal: RelatorioModalComponent;
  @ViewChild('statusEnvioEmailModal') statusEnvioEmailModal: StatusEnvioEmailComponent;

  public blocos: Bloco[] = [];
  public bloco: Bloco;
  public unidades: Unidade[] = [];
  public unidade: Unidade;
  public showInputBlocos: boolean = true;
  public condominio: Condominio;
  public filtro = new NotificacaoFiltro();
  public tipos = tiposNotificacao;
  public formasNotificacao = formasNotificacao;
  public statusNotificacao = statusNotificacao;
  public entities = [];
  public datepickerDataOptions = datepickerDefaultOptions;

  constructor(activatedRoute: ActivatedRoute,
              router: Router,
              messageService: MessageService,
              permissionService: PermissionService,
              public route: ActivatedRoute,
              private authService: AuthService,
              private blocosHttpService: BlocosHttpService,
              private unidadesHttpService: UnidadesHttpService,
              private notificacoesHttpService: NotificacoesHttpService,
              public spinner: NgxSpinnerService) {
    super('#tableNotificacoes', notificacoesHttpService, activatedRoute, router, messageService, permissionService, spinner);
  }

  ngOnInit() {
    this.setInitialFilter();
    this.getBlocosByCondominio();
    this.getPermissions();
    super.ngOnInit();
  }

  public search(): void {
    this.spinner.show();
    this.entities = [];
    this.notificacoesHttpService.search(this.filtro).subscribe(
      response => {
        this.entities = response;
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        this.messageService.error('Erro ao realizar a consulta. Tente novamente', 'Erro', error);
      }
    );
  }

  /**
   * Filtros iniciais da consulta
   */
  public setInitialFilter(): void {
    this.filtro.limpar();
    this.filtro.id_cliente = this.authService.getIdCurrentCliente();
    this.filtro.id_condominio = this.authService.getIdCurrentCondominio();
    this.filtro.id_usuario = this.authService.getIdCurrentUser();
    this.onCleanBloco(this.inputBloco.nativeElement);
  }

  /**
   * Expande as notificações do evento
   * @param entity 
   */
  public expandEvento(entity: any): void {
    entity.open = !entity.open;
  }

  /**
   * Carrega os blocos do Condomínio
   */
  private getBlocosByCondominio(): void {
    this.blocos = [];
    this.blocosHttpService.getAllByCondominio(this.filtro.id_condominio).subscribe(
      entities => {
        this.blocos = entities;
        if (entities.length === 1) this.setBloco(entities[0])
      },
      error => this.messageService.error('Erro ao carregar os blocos do condomínio. Tente novamente', 'Erro', error)
    );
  }

  /**
   * Atribui valor do bloco
   * @param blocoSelecionado Bloco selecionado
   */
  private setBloco(blocoSelecionado: Bloco): void {
    this.bloco = blocoSelecionado;
    this.filtro.id_bloco = this.bloco.id;
    this.getAllUnidadesByBloco(this.bloco.id);
    this.onCleanUnidade(this.inputUnidade.nativeElement);
  }

  /**
   * Busca todas as unidade de um bloco
   * @param idBloco 
   */
  public getAllUnidadesByBloco(idBloco: number): void {
    this.unidades = [];
    this.unidadesHttpService.getAllByBloco(idBloco).subscribe(
      entities => {
        for (const unidade of entities) {
          if (unidade.nome) {
            this.unidades.push(unidade);
          }
        }
      },
      error => this.messageService.error('Erro ao carregar as unidades do bloco. Tente novamente', 'Erro', error)
    );
  }

  onSelectBloco(tm: TypeaheadMatch): void {
    this.setBloco(tm.item as Bloco);
  }

  /**
   * Limpa campo de Unidades
   * @param input Objeto do elemento
   */
  public onCleanUnidade(input: any): void {
    this.unidade = null;
    this.filtro.id_unidade = null;
    input.value = '';
    input.focus();
  }

  private setUnidade(unidadeSelecionada: Unidade): void {
    this.unidade = unidadeSelecionada;
    this.filtro.id_unidade = this.unidade.id;
  }

  public onSelectUnidade(tm: TypeaheadMatch): void {
    this.setUnidade(tm.item as Unidade);
  }

  /**
   * Visualiza a notificação
   * @param entity 
   */
  public visualizar(entity: any): void {
    this.cadastroNotificacaoModal.open();
  }

  openBlocosPequisaModal() {
    this.blocosPesquisaModal.open();
  }

  onSelectBlocosPesquisaModal(bloco: Bloco) {
    if (bloco) {
      this.setBloco(bloco)
    }
    jQuery('#bloco').focus();
  }

  public openUnidadesPequisaModal(): void {
    this.unidadesPesquisaModal.open();
  }

  public onSelectUnidadesPesquisaModal(unidade: Unidade): void {
    if (unidade) { this.setUnidade(unidade); }
    jQuery('#unidade').focus();
  }

  public onCleanBloco(input: any): void {
    this.onCleanUnidade(this.inputUnidade.nativeElement);
    this.filtro.id_bloco = null;
    this.bloco = null;
    input.value = '';
    input.focus();
  }

  /**
   * Imprimir a notificação em tela
   * @param notificacao 
   * @author Marcos Frare
   * @since 10/01/2020
   */
  public printNotificacao(notificacao): void {
    const params = {
      id_condominio: this.authService.getIdCurrentCondominio(),
      id_usuario: this.authService.getIdCurrentUser(),
      id_notificacao: notificacao.id
    }
    this.relatorioModal.url = '/notificacao/imprimirNotificacao';
    this.relatorioModal.params = params;
    this.relatorioModal.open();
  }
  
  /**
   * Fazer notificação individual
   * @param notificacao - Notificaçao
   * @author Marcos Frare
   * @since 11/06/2020
   */
  private notificar(notificacao): void {
    this.spinner.show();
    this.notificacoesHttpService.enviarEmailNotificacao(notificacao.id).subscribe(
      response => {
        switch (response.situacao) {
          case 2: {
            this.messageService.info(`Notificado ${response.notificado} com sucesso!`);
            break;
          }
          case 3: {
            this.messageService.error('Erro ao enviar e-mail! Tente novamente.', 'Erro!');
            break;
          }
          case 4: {
            this.messageService.warning(`${response.notificado} não possui e-mail cadastrado!`, 'Atenção!');
            break;
          }
          default: {
            this.messageService.warning('Problemas ao enviar e-mail! Tente novamente.', 'Atenção!');
            break;
          }
        }
        this.spinner.hide();
        this.search();
      },
      error => {
        this.messageService.error('Erro ao enviar e-mails!!', 'Erro', error);
        this.spinner.hide();
      }
    );
  }
  /**
   * Enviar a notificação por e-mail
   * @param notificacao 
   * @author Marcos Frare
   * @since 10/06/2020
   */
  public sendNotificacao(notificacao): void {
    if (notificacao.status == 2) {
      if (confirm('Notificação já enviada. Deseja enviar novamente?')) {
        this.notificar(notificacao);
      }
    } else {
      this.notificar(notificacao);
    }
  }

  /**
   * Faz envio das notificações
   * @param entity 
   * @author Marcos Frare
   * @since 11/06/2020
   */
  private notificarEvento(entity): void {
    this.spinner.show();
    this.notificacoesHttpService.enviarEmailEvento(entity.id).subscribe(
      response => {
        this.spinner.hide();
        this.statusEnvioEmailModal.open(response);
        this.search();
      },
      error => {
        this.messageService.error('Erro ao enviar e-mails!!', 'Erro', error);
        this.spinner.hide();
      }
    );
  }

  /**
   * Envia novamente as notificações de um evento
   * @param entity - Entidad de Evento da Notificação
   * @author Marcos Frare
   * @since 10/06/2020
   */
  public sendEventoNotificacao(entity): void {
    if (entity.notificado) {
      if (confirm('Estas notificações já foram enviadas. Deseja enviar novamente?')) {
        this.notificarEvento(entity);
      }
    } else {
      this.notificarEvento(entity);
    }
  }
  
  /**
   * Imprime todas as notificações do evento
   * @param entity 
   */
  public printEventoNotificacao(entity): void {
    const params = {
      id_condominio: this.authService.getIdCurrentCondominio(),
      id_usuario: this.authService.getIdCurrentUser(),
      id_evento_notificacao: entity.id
    }
    this.relatorioModal.url = '/notificacao/imprimirEventoNotificacao';
    this.relatorioModal.params = params;
    this.relatorioModal.open();
  }

  /**
   * Exluir um evento de notificação
   * @param entity 
   * @author Marcos Frare
   * @since 28/01/2020
   */ 
  public deleteEventoNotificacao(entity): void {
    if (confirm('Deseja exlcuir este evento de notificação?')) {
      this.spinner.show();
      this.notificacoesHttpService.deleteEventoNotificacao(entity).subscribe(
        () => {
          this.messageService.success('', 'Notificações excluídos com sucesso');
          this.afterDelete();
          this.spinner.hide();
        },
        error => {
          this.spinner.hide();
          if (error.status === 403) {
            this.messageService.warning('Não foi possível excluir as notificações!', 'Atenção', error)
          } else {
            this.messageService.error('Erro ao excluir notificações!', 'Erro', error);
          }
        }
      );
    }
  }

  /**
   * Excluir notificação
   * @param notificacao Objeto de notificação
   * @author Marcos Frare
   * @since 28/01/2020
   */
  private deleteNotificacao(notificacao): void {
    if (confirm('Deseja excluir a notificação?')) {
      this.spinner.show();
      this.notificacoesHttpService.deleteNotificacao(notificacao).subscribe(
        () => {
          this.messageService.success('', 'Notificação excluída com sucesso.');
          this.afterDelete();
          this.spinner.hide();
        },
        error => {
          this.spinner.hide();
          if (error.status === 403) {
            this.messageService.warning('Não foi possível excluir a notificação!', 'Atenção', error);
          } else {
            this.messageService.error('Erro ao excluir a notificação!', 'Erro', error);
          }
        }
      );
    }
  }
  
  /**
   * Abrir tela para inserir notificações
   * @author Marcos Frare
   * @since 10/012020
   */
  public insert(): void {
    this.cadastroNotificacaoModal.open();
  }

  public afterDelete(): void {
    this.search();
  }

  /**
   * Ir para tela de configurações de de notificação automática
   * @author Marcos Frare
   * @since 15/02/2021
   */
  public configurar(): void {
    this.router.navigate(['./configuracao'], {relativeTo: this.activatedRoute});
  }

}