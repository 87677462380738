import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseVisualizacaoComponent } from 'app/componentes-base';
import { AuthService, MessageService } from 'app/core';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { FabricanteVeiculo } from 'app/models';
import { NgxSpinnerService } from 'ngx-spinner';
import { FabricanteVeiculoCadastroModalComponent } from './fabricante-veiculo-cadastro-modal';
import { FabricanteVeiculoHttpService } from './service';

@Component({
  selector: 'fabricante-veiculo',
  templateUrl: './fabricante-veiculo.component.html',
  styleUrls: ['./fabricante-veiculo.component.css']
})
export class FabricanteVeiculoComponent extends BaseVisualizacaoComponent<FabricanteVeiculo> {

  @ViewChild('fabricanteVeiculoCadastroModal') fabricanteVeiculoCadastroModal: FabricanteVeiculoCadastroModalComponent;

  constructor(public activatedRoute: ActivatedRoute,
      public router: Router,
      public messageService: MessageService,
      private authService: AuthService,
      private fabricanteVeiculoHttpService: FabricanteVeiculoHttpService,
      public permissionService: PermissionService,
      public spinner: NgxSpinnerService) {
    super('#tableFabricanteVeiculo', fabricanteVeiculoHttpService, activatedRoute, router, messageService, permissionService, spinner);
  }

  ngOnInit() {
    if(!this.authService.isAdmin) {
      this.router.navigate(['/dashboard'], {relativeTo: this.activatedRoute});
    }
    this.search();
  }

  /**
   * Excluir um fabricante
   * @param entity Objeto a ser excluído
   * @author Marcos Frare
   * @since 10/04/2021
   */
   public delete(entity: FabricanteVeiculo): void {
    if (confirm(`Deseja excluir o fabricante ${entity.nome}?`)) {
      this.fabricanteVeiculoHttpService.delete(entity).subscribe(
        () => {
          this.search();
          this.messageService.success('Fabricante excluído com sucesso!', 'Sucesso!');
        },
        error => {
          this.messageService.error(`Erro ao excluir fabricante ${entity.nome}!`, 'Erro', error);
        }
      );
    }
  }

  /**
   * Busca dados
   * @author Marcos Frare
   * @since 10/04/2021
   */
  public search(): void {
    super.getAll();
  }

  /**
   * Editar registro
   * @param entity 
   * @author Marcos Frare
   * @since 10/04/2021
   */
  public edit(entity: FabricanteVeiculo): void {
    this.fabricanteVeiculoCadastroModal.id = entity.id;
    this.fabricanteVeiculoCadastroModal.open();
  }

  /**
   * Incluir novo fabricante
   * @author Marcos Frare
   * @since 10/04/2021
   */
  public insert(): void {
    this.fabricanteVeiculoCadastroModal.id = null;
    this.fabricanteVeiculoCadastroModal.open();
  }

}