import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthService, MessageService} from '../../../../core';
import {ExtratosHttpService} from '../services';
import {DatePipe} from '@angular/common';
import {css} from './visualizacao.css';
import {ContaBancaria} from 'app/models';

@Component({
    selector: 'visualizacao',
    templateUrl: './visualizacao.component.html',
    styleUrls: ['./visualizacao.component.css'],
    providers: [DatePipe],
})
export class VisualizacaoExtratoComponent implements OnInit {

    public filtro: any = {};
    public contas: ContaBancaria[] = [];
    public saldo_total: number;
    public condominio: string;
    public dataAtual: Date = new Date();

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private messageService: MessageService,
                private authService: AuthService,
                private datePipe: DatePipe,
                private extratosHttpService: ExtratosHttpService) {
    }

    ngOnInit() {
        this.filtro.id_condominio = this.authService.getIdCurrentCondominio();
        this.activatedRoute.queryParams.subscribe((params: Params) => {
            this.filtro.id_conta_bancaria = params['id_conta_bancaria'];
            this.filtro.data_inicial = params['data_inicial'];
            this.filtro.data_final = params['data_final'];
            this.getExtrato();
        });
    }

    public back(): void {
        this.router.navigate(['/financeiro/controle-financeiro']);
    }

    private getExtrato(): void {
        this.extratosHttpService.getAllByContaBancariaEFiltro(this.filtro).subscribe(
            response => {
                this.contas = response.contas_bancarias;
                this.saldo_total = response.saldo_total;
                this.condominio = response.razao_social;
            },
            error => this.messageService.error('Erro ao obter dados da conta', 'Erro', error)
    );

    }

    public print(): boolean {
        let printContents;
        printContents = document.getElementById('print-section').innerHTML;
        let printableWindow = window.open('', 'PRINT', 'height=400,width=600');
        printableWindow.document.write(`<html>
                                            <head>
                                                <title>Boletos</title>
                                                <style>
                                                    ${css}
                                                </style>
                                            </head>
                                            <body>
                                                ${printContents}
                                            </body>
                                        </html>`);

        printableWindow.document.close();
        printableWindow.focus();
        printableWindow.print();
        printableWindow.close();

        return true;
    }
}
