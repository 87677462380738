import { Component, OnInit, ViewChild } from '@angular/core';
import { ContaBancaria } from 'app/models';
import { datepickerMesAnoOptions } from 'app/shared';
import { ComparativoFinanceiroRelFiltro } from './comparativo-financeiro-rel-filtro';
import { RelatorioModalComponent } from '../relatorio-modal';
import { AuthService, MessageService } from 'app/core';
import { ContasBancariasHttpService } from 'app/home/contas-bancarias';

@Component({
	selector: 'comparativo-financeiro-rel',
	templateUrl: './comparativo-financeiro-rel.component.html'
})
export class ComparativoFinanceiroRelComponent implements OnInit {

	@ViewChild('relatorioModal') relatorioModal: RelatorioModalComponent;

	public contas: ContaBancaria[];
	public filtro = new ComparativoFinanceiroRelFiltro();
	public datepickerDataCompetenciaOptions = datepickerMesAnoOptions;

	constructor(private authService: AuthService,
		private messageService: MessageService,
		private contasBancariasHttpService: ContasBancariasHttpService) { }

	ngOnInit() {
		this.limpar();
		this.filtro.id_condominio = this.authService.getIdCurrentCondominio();;
		this.filtro.id_usuario = this.authService.getIdCurrentUser();
		this.filtro.id_cliente = this.authService.getIdCurrentCliente();
		this.getContasBancariasByCondominio(this.filtro.id_condominio);
	}

	private getContasBancariasByCondominio(idCondominio: number) {
		this.contasBancariasHttpService.getAllByCondominio(idCondominio)
			.subscribe(
				entitites => this.contas = entitites,
				error => this.messageService.error('Erro ao carregar as contas bancarias do condomínio. Tente novamente', 'Erro', error)
			);
	}

	public limpar(): void {
		this.filtro.limpar();
	}

	private validateView(): boolean {
		if (!this.filtro.data_inicial) {
			this.messageService.info('É necessário informar a competência início para imprimir', 'Informação');
			return false;
		}
		if (!this.filtro.data_final) {
			this.messageService.info('É necessário informar a competência fim para imprimir', 'Informação');
			return false;
		}

		return true;
	}

	public view(): void {
		if (this.validateView()) {
			this.relatorioModal.url = '/relatorio/comparativo-financeiro';
			this.relatorioModal.params = this.filtro;
			this.relatorioModal.open();
		}
	}

}