export const tiposMultaJuros = [
	{ id: 1, nome: 'R$' },
	{ id: 2, nome: '%' }
];

export const tiposDescontos = [
	{ id: 1, nome: 'R$' },
	{ id: 2, nome: '%' }
];

export const identificacaoMultaJuros = [
	{ id: 1, nome: 'Por dia' },
	{ id: 2, nome: 'Mensal' },
	{ id: 3, nome: 'Único' }
];

export const tiposFundoReserva = [
	{ id: 1, nome: 'R$' },
	{ id: 2, nome: '%' }
];

export const tiposMesVencimento = [
	{ id: 1, nome: 'Mês competência' },
	{ id: 2, nome: 'Mês seguinte' }
];