import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService, MessageService, DataTablesService } from '../../core';
import { PagamentosHttpService } from './services';
import { PagamentosConsulta } from './pagamentos-consulta';
import { PagamentosEnvio } from './pagamentos-envio';
import { NgxSpinnerService } from 'ngx-spinner';
import { PagamentoAnexoModalComponent } from './pagamento-anexo-modal';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { FornecedoresHttpService } from '../fornecedores';
import { PagamentosFiltro } from './pagamentos-filtro';
import { TipoContatoHttpService } from '../tipo-contato';
import { CategoriaContasHttpService, CategoriaContasPesquisaModalComponent } from '../plano-de-contas';
import { CategoriaContas, ConfiguracaoCondominio, ContaBancaria, Fornecedor, sindicoAprovaPagamento, TipoContato, tipoPagamento } from 'app/models';
import { CondominiosConfiguracaoHttpService } from '../condominios-configuracao';
import { ContasBancariasHttpService } from '../contas-bancarias';
import { LancamentoCondominioAnexoModalComponent } from '../lancamentos-condominio';
import { PagamentoChequeModalComponent } from './pagamento-cheque-modal';

declare var jQuery: any;

@Component({
	selector: 'pagamentos',
	templateUrl: './pagamentos.component.html',
	styleUrls: ['./pagamentos.component.css']
})
export class PagamentosComponent implements OnInit {

	@ViewChild('pagamentoAnexoModal') pagamentoAnexoModal: PagamentoAnexoModalComponent;
	@ViewChild('categoriaContasPesquisaModal') categoriaContasPesquisaModal: CategoriaContasPesquisaModalComponent;
	@ViewChild('lancamentoCondominioAnexoModal') lancamentoCondominioAnexoModal: LancamentoCondominioAnexoModalComponent;
	@ViewChild('pagamentoChequeModal') pagamentoChequeModal: PagamentoChequeModalComponent;

	public tiposPagamentos = tipoPagamento;
	public aprovacoesSindico = sindicoAprovaPagamento;
	public entities: PagamentosConsulta[];
	public contas: ContaBancaria[];
	public dataTable: any;
	public configuracaoCondominio: ConfiguracaoCondominio;
	public filtro: PagamentosFiltro;
	public allSelected = true;
	public idCondominio: number;
	public idCliente: number;
	public gridId = 'tablePagamento';
	public categoriasContas: CategoriaContas[] = [];
	public fornecedores: Fornecedor[] = [];
	public tiposContato: TipoContato[] = [];

	constructor(private pagamentoHttpService: PagamentosHttpService,
		private messageService: MessageService,
		private authService: AuthService,
		private dataTablesService: DataTablesService,
		private condominiosConfiguracaoHttpService: CondominiosConfiguracaoHttpService,
		private contasBancariasHttpService: ContasBancariasHttpService,
		private categoriaContasHttpService: CategoriaContasHttpService,
		private fornecedoresHttpService: FornecedoresHttpService,
		private spinner: NgxSpinnerService,
		private tipoContatohttpService: TipoContatoHttpService) { }

	ngOnInit() {
		this.idCondominio = this.authService.getIdCurrentCondominio();
		this.idCliente = this.authService.getIdCurrentCliente();
		this.filtro = new PagamentosFiltro();
		this.filtro.id_condominio = this.idCondominio;
		this.getAllFornecedores();
		if (this.idCondominio) {
			this.getByCondominio(this.idCondominio);
		} else {
			this.messageService.error('O condomínio não foi encontrado.', 'Erro');
		}
		this.getAll();
		this.getAllContas();
		this.getAllCategoriasByCondominio(this.idCondominio);
		this.limparFiltro();
		this.getAllTipoContato();
	}

	/**
	 * Busca os tipos de contato
	 * @author Marcos Frare
	 * @since 20/04/2021
	 */
	 private getAllTipoContato(): void {
		this.tiposContato = [];
		this.tipoContatohttpService.getAll().subscribe(
			response => {
				this.tiposContato = response;
			}
		);
	}

	public limparFiltro(): void {
		this.filtro.limpar();
	}

	private getAllFornecedores(): void {
		this.fornecedoresHttpService.getAllByCliente(this.idCliente).subscribe(
			entities => this.fornecedores = entities,
			error => this.messageService.error('Erro ao carregar os fornecedores', 'Erro', error)
		);
	}

	public getAllContas(): void {
		this.contas = [];
		this.contasBancariasHttpService.getAllByCondominio(this.idCondominio).subscribe(
			contas => {
				this.contas = contas.filter(cb => cb.situacao == 'ATIVO');
			},
			error => this.messageService.error('Erro ao carregar contas. Tente novamente', 'Erro', error)
		);
	}

	private createEnvios(): void {
		if (!this.entities) { return; }
		this.entities.forEach(entity => {
			entity.envio = new PagamentosEnvio();
			entity.envio.data_pagamento = entity.data_pagamento || new Date();
			entity.envio.tipo = entity.tipo || 4;
			entity.envio.sindico_aprova = entity.sindico_aprova;
			entity.envio.data_aprovacao_sindico = entity.data_aprovacao_sindico;
			entity.envio.id_tipo_contato = entity.id_tipo_contato;
			entity.envio.id_pagamento = entity.id_pagamento;
		});
	}

	public pagarSelecionados(): void {
		const pagamentos: any[] = this.getPagamentosSelected();
		this.pagar(pagamentos);
	}

	public pagarUnico(pagamento: PagamentosConsulta): void {
		this.pagar([pagamento]);
	}

	public pagar(pagamentos: PagamentosConsulta[]): void {
		this.spinner.show();
		const btn = jQuery('#pagarTudo');
		this.loadButton(btn);
		const pagamentosEnvio: PagamentosEnvio[] = [];
		pagamentos.forEach(pagamento => {
			if (!pagamento.envio.data_pagamento) {
				this.messageService.info('Informe a data do pagamento para realizar o pagamento', 'Informação');
				return;
			}
			if (!pagamento.envio.id_conta_bancaria) {
				this.messageService.info('Informe a conta bancária para realizar o pagamento', 'Informação');
				return;
			}
			pagamento.envio.data_aprovacao_sindico = pagamento.data_aprovacao_sindico;
			pagamento.envio.id_tipo_contato = pagamento.id_tipo_contato;

			const pagamentoEnvio: PagamentosEnvio = { ...pagamento.envio };
			pagamentosEnvio.push(pagamentoEnvio);
		});
		this.pagamentoHttpService.pagar(pagamentosEnvio).subscribe(
			() => {
				this.spinner.hide();
				this.search();
				this.resetButton(btn);
				this.messageService.success(
					`Pagamento realizado com sucesso`,
					'Sucesso');
			},
			error => {
				this.spinner.hide();
				this.resetButton(btn);
				this.messageService.error(
					`Erro ao realizar o pagamento. Tente novamente`,
					'Erro', error);
			}
		);
	}

	public estornar(pagamento: PagamentosConsulta, idBtn: string) {
		this.spinner.show();
		this.pagamentoHttpService.estornar(pagamento.id_pagamento, pagamento.envio)
			.subscribe(
				() => {
					this.spinner.hide();
					this.messageService.success(`Pagamento estornado com sucesso!`, 'Info');
					this.search();
				},
				error => {
					this.spinner.hide();
					this.messageService.error(`Erro ao estornar o pagamento. Tente novamente`, 'Erro', error);
				}
			);
	}

	/** Seleciona a conta bancaria padrão nos pagamentos */
	setContaPadrao() {
		for (const pagamento of this.entities) {
			if (this.configuracaoCondominio)
				pagamento.envio.id_conta_bancaria = this.configuracaoCondominio.id_conta_bancaria_padrao;
		}
	}

	/** Carrega a config do condomínio. **/
	private getByCondominio(idCondominio: number) {
		this.condominiosConfiguracaoHttpService.getByCondominio(idCondominio)
			.subscribe(
				config => this.configuracaoCondominio = config,
				error => this.messageService.info(
					'Configuração do condomínio não encontrada. Cadastre uma configuração para esse condomínio',
					'Informação')
			);
	}

	public getAll(): void {
		this.spinner.show();
		this.entities = [];
		this.destroyTable();
		this.pagamentoHttpService.getAllAPagar(this.idCondominio).subscribe(
			entities => {
				this.spinner.hide();
				this.entities = entities;
				this.createEnvios();
				this.setContaPadrao();
				this.showTable();
			},
			error => {
				this.spinner.hide();
				this.messageService.error('Erro ao consultar os pagamentos do condomínio selecionado. Tente novamente.', 'Erro', error);
			}
		);
	}

	public search(): void {
		this.spinner.show();
		this.entities = [];
		this.destroyTable();
		this.pagamentoHttpService.search(this.filtro).subscribe(
			entities => {
				this.spinner.hide();
				this.entities = entities;
				this.createEnvios();
				this.setContaPadrao();
				this.showTable();
			},
			error => {
				this.spinner.hide();
				this.messageService.error('Erro ao consultar os pagamentos do condomínio selecionado. Tente novamente.', 'Erro', error);
			}
		);
	}

	private showTable() {
		setTimeout(() =>
			this.dataTable = this.dataTablesService.showTable(this.gridId, {
				order: [[6, 'asc']],
				columnDefs: [
					{ orderable: false, targets: 0 },
				],
				language: { 'url': './assets/scripts/datatables-ptbr.json' }
			}), 200);
	}

	private destroyTable(): void {
		this.dataTablesService.destroyTable(this.dataTable);
	}

	private loadButton(btn: any) {
		btn.button('loading');
	}

	private resetButton(btn: any) {
		btn.button('reset');
	}

	//    PAGAR TODOS

	public checkAllSelected(): boolean {
		let isAllSelected = true;
		this.entities.forEach(entity => {
			if (!entity.selected) { isAllSelected = false; }
		});
		return isAllSelected;
	}

	public toggleAllSelected(): void {
		this.entities.filter(it => !this.isDisabled(it)).forEach(pagamento => {
			pagamento.selected = this.allSelected;
		});
	}

	public checkSelected(): boolean {
		let isSelected = false;
		this.entities.filter(it => !this.isDisabled(it)).forEach(pagamento => {
			if (pagamento.selected) { isSelected = true; }
		});
		return isSelected;
	}

	public onChangeSindicoAprova(pagamento: PagamentosConsulta): void {
		if (this.isDisabled(pagamento)) {
			pagamento.selected = false;
		}
	}

	public getPagamentosSelected(): any[] {
		return this.entities.filter(it => it.selected);
	}

	public isDisabled(pagamento: PagamentosConsulta): boolean {
		return !!pagamento.data_pagamento || pagamento.envio.sindico_aprova !== 1;
	}

	public disabledEstornar(pagamento: PagamentosConsulta): boolean {
		return !pagamento.data_pagamento;
	}

	public anexar(pagamento: PagamentosConsulta): void {
		this.pagamentoAnexoModal.idPagamento = pagamento.id_pagamento;
		this.pagamentoAnexoModal.open();
	}

	/**
	 * Mostrar anexos dos lançamentos
	 * @param pagamento 
	 * @author Marcos Frare
	 * @since 15/11/2021
	 */
	public anexarLancamento(pagamento: PagamentosConsulta): void {
		this.lancamentoCondominioAnexoModal.idLancamento = pagamento.id_lancamento_condominio;
		this.lancamentoCondominioAnexoModal.open();
	}

	private getAllCategoriasByCondominio(idCondominio: number): void {
		this.categoriasContas = [];
		if (idCondominio) {
			this.categoriaContasHttpService.getAllByCondominio(idCondominio, 2)
				.then(entities => this.categoriasContas = entities)
				.catch(() => this.messageService.info(`Configuração do condomínio não encontrada. Configure o condomínio para prosseguir.`, 'Informação'));
		}
	}

	// Categorias de contas
	public onSelectCategoriaContas(tm: TypeaheadMatch): void {
		const categoriaContas: CategoriaContas = tm.item as CategoriaContas;
		if (categoriaContas) {
			this.filtro.id_categoria_conta = categoriaContas.id;
			this.filtro.categoriaContas = categoriaContas;
		} else {
			this.filtro.id_categoria_conta = null;
			this.filtro.categoriaContas = null;
		}
	}

	public onCleanCategoriaContas(input: any): void {
		this.filtro.id_categoria_conta = null;
		this.filtro.categoriaContas = null;
		input.value = '';
		input.focus();
	}

	public openCategoriaContasPequisaModal(): void {
		this.categoriaContasPesquisaModal.open();
	}

	public onSelectCategoriaContasPesquisaModal(categoriaContas: CategoriaContas): void {
		if (categoriaContas) {
			this.filtro.id_categoria_conta = categoriaContas.id;
			this.filtro.categoriaContas = categoriaContas;
		}
		jQuery('#categoriaContas').focus();
	}

	public openChequeModal(pagamento: PagamentosEnvio): void {
		this.pagamentoChequeModal.idPagamento = pagamento.id_pagamento;
		this.pagamentoChequeModal.valor = pagamento.valor;
		this.pagamentoChequeModal.open();
	}
}