export class ContasAPagarRelFiltro {
	id_cliente: number;
	id_condominio: number;
	id_usuario: number;
	data_inicial: Date;
	data_final: Date;
	ids_fornecedores: number[];
	aprovacao: boolean;
	situacao: string;
	ordenacao: string;
	tipo_despesa: string;

	/** Limpa o filtro */
	limpar() {
		const today = new Date();
		this.data_inicial = null; // Competência fechada
		this.data_final = new Date(today.getFullYear(), today.getMonth(), today.getDay() + 3);
		this.ids_fornecedores = [];
		this.aprovacao = null;
		this.situacao = 'ABERTO';
		this.ordenacao = 'DATA';
		this.tipo_despesa = '';
	}
}

export const situacaoContasAPagar = [
	{ id: 1, tag: 'ABERTO', nome: 'Aberto' },
	{ id: 2, tag: 'PAGO', nome: 'Pago' },
	{ id: 3, tag: 'TODOS', nome: 'Todos' }
];

export const ordenacaoRelatorio = [
	{ id: 1, tag: 'DATA', nome: 'Data' },
	{ id: 2, tag: 'CONTA', nome: 'Categoria Conta' },
	{ id: 3, tag: 'FORNECEDOR', nome: 'Fornecedor' }
];