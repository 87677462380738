import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';

import {ConselhosCondominio, Bloco, tiposConselhosCondominio, Usuario} from '../../../models';

import { UsuariosCadastroModalComponent } from '../../usuarios';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { UsuariosPesquisaModalComponent } from '../../usuarios/pesquisa-modal';

interface CadastroUsuarioSetup {
    membro: ConselhosCondominio;
    input: any;
}

interface CadastroSuplenteSetup {
    membro: ConselhosCondominio;
    input: any;
}


@Component({
    selector: 'condominios-conselho-membros',
    templateUrl: './condominios-conselho-membros.component.html',
    styleUrls: ['./condominios-conselho-membros.component.css']
})
export class CondominiosConselhoMembrosComponent implements OnChanges {

    @ViewChild('usuariosCadastroModal') usuariosCadastroModalComponent: UsuariosCadastroModalComponent;
    @ViewChild('suplenteCadastroModal') suplenteCadastroModalComponent: UsuariosCadastroModalComponent;
    @ViewChild('suplentePesquisaModal') suplentePesquisaModal: UsuariosPesquisaModalComponent;
    @ViewChild('usuariosPesquisaModal') usuariosPesquisaModal: UsuariosPesquisaModalComponent;
    cadastroNovoUsuarioSetup: CadastroUsuarioSetup = {membro: null, input: null};
    cadastroNovoSuplenteSetup: CadastroSuplenteSetup = {membro: null, input: null};

    @Input() entities: ConselhosCondominio[];
    @Input() usuarios: Usuario[];
    @Input() blocos: Bloco[];
    @Input() idModalUsuario: string;
    @Input() idPesquisaModal: string;
    @Input() idPesquisaSuplenteModal: string;
    @Input() idCadastroModal: string;
    @Input() idCadastroSuplenteModal: string;
    @Input() permissions: any;
    @Output() deleteMembro = new EventEmitter<ConselhosCondominio>();

    tiposConselhos: any[] = tiposConselhosCondominio;

    ngOnChanges() {
        this.setUsuariosNosMembros();
    }

    // Seta o objeto usuário na classe ConselhosCondominio (Membro) com base no array de usuários através do seu id
    private setUsuariosNosMembros() {
        if (this.entities && this.usuarios) {
            for (const entity of this.entities) {
                entity.usuarioConselho = this.usuarios.find(x => x.id === entity.id_usuario_conselho);
                if (!entity.usuarioConselho) {
                    entity.usuarioConselho = new Usuario();
                }
            }
        }
    }

    // Ao editar algum campo do membro, setamos ela como editar para salvá-la
    onChange(membro: ConselhosCondominio) {
        membro.editado = true;
    }

    // Ao selecionar o usuário
    onSelectUsuario(tm: TypeaheadMatch, membro: ConselhosCondominio) {
        const usuario: Usuario = tm.item as Usuario;
        if (usuario) {
            membro.id_usuario_conselho = usuario.id_usuario;
            membro.usuarioConselho = usuario;
        } else {
            membro.id_usuario_conselho = null;
            membro.usuarioConselho = null;
        }
        this.onChange(membro);
    }

    // Ao limpar o usuário
    onCleanUsuario(membro: ConselhosCondominio, input: any) {
        membro.id_usuario_conselho = null;
        membro.usuarioConselho = null;
        membro.usuarioConselhoNotFound = false;
        this.onChange(membro);

        input.value = '';
        input.focus();
    }

    // Exibi o modal para criar um novo usuário quando o mesmo não for encontrado
    createUsuario(membro: ConselhosCondominio, nome: string, input: any) {
        this.cadastroNovoUsuarioSetup.membro = membro;
        this.cadastroNovoUsuarioSetup.input = input;

        this.usuariosCadastroModalComponent.open(nome);
    }

    // Após o usuário ser cadastrado via modal, setamos os valores desse novo cadastro
    onAfterSaveUsuario(usuario: Usuario) {
        let membro = this.cadastroNovoUsuarioSetup.membro;
        membro.usuarioConselho = usuario;
        membro.id_usuario_conselho = usuario.id_usuario;
        membro.usuarioConselhoNotFound = false;

        this.usuarios.push(usuario);
        this.cadastroNovoUsuarioSetup.input.focus();
        this.onChange(membro);
    }

    renovar(membro: ConselhosCondominio) {
        membro.renovando = true;
    }

    cancelarRenovacao(membro: ConselhosCondominio) {
        membro.renovando = false;
        membro.data_saida = null;
    }

    delete(membro: ConselhosCondominio) {
        if(confirm(`Deseja realmente excluir o membro ${membro.usuarioConselho.nome}?`))
            this.deleteMembro.emit(membro);
    }

    onSelectUsuariosPesquisaModal(event) {
        const usuario: Usuario = event.entity;
        if (usuario) {
            this.entities[event.position].id_usuario_conselho = usuario.id_usuario;
            this.entities[event.position].usuarioConselho = usuario;
        } else {
            this.entities[event.position].id_usuario_conselho = null;
            this.entities[event.position].usuarioConselho = null;
        }
        this.onChange(this.entities[event.position]);
    }

    onAfterCancelUsuario(event) {
    }

    openUsuariosPequisaModal(index: number) {
        this.usuariosPesquisaModal.open(index);
    }

    public getNameById(id: number, property: string): string {
        if(!this[property]) return "";
        let value: any = this[property].find(it => it.id === id)
        if(value) return value.nome;
        return "";
    }

}

