import { Injectable } from '@angular/core';
import { CustomHttp, ConfigService, AuthService } from 'app/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracaoServicoUnidadeHttpService {

  private resource = 'servicounidade';
  private baseUrl: string;
  private id_condominio: number;

  constructor(private customHttp: CustomHttp,
      private authService: AuthService,
      private configService: ConfigService) {
    this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
  }

  /**
   * Busca os dados do 
   * @param idCondominio 
   */
  getAllData(): Observable<any> {
    this.id_condominio = this.authService.getIdCurrentCondominio();
    return this.customHttp.get(`${this.baseUrl}/consultar`, { params: { id_condominio: this.id_condominio }})
      .pipe(
        map(response => response.json()),
        catchError(error => _throw(error))
      );
  }

  persistData(data: any): Observable<any> {
    return this.customHttp.post(`${this.baseUrl}/cadastrar`, {blocos: data})
      .pipe(
        map(response => response.json()),
        catchError(error => _throw(error))
      );
  }
  
}
