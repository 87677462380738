import { Injectable } from '@angular/core';
import { ConfigService, CustomHttp } from 'app/core';
import { PagamentoCancelamento } from 'app/models';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs-compat/observable/throw';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PagamentoCancelamentoHttpService {

  public resource = 'pagamento/cancelamento';
	private baseUrl: string;

  constructor(private customHttp: CustomHttp,
      private configService: ConfigService) {
    this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
  }

  /**
   * Cancelar um pagamento
   * @param pagamentoCancelamento 
   * @param id_pagamento 
   * @author Marcos Frare
   * @since 11/05/2021
   */
  public cancelarPagamento(pagamentoCancelamento: PagamentoCancelamento, id_pagamento: number): Observable<PagamentoCancelamento> {
    return this.customHttp.post(`${this.baseUrl}/${id_pagamento}`, pagamentoCancelamento).pipe(
      map(response => response.json()),
			catchError(error => _throw(error))
    );
  }

  /**
   * Desfaz cancelamento de pagamento
   * @param id - ID do cancelamento
   * @author Marcos Frare
   * @since 11/05/2021
   */
  public desfazCancelamento(id: number): Observable<PagamentoCancelamento> {
    return this.customHttp.delete(`${this.baseUrl}/${id}`).pipe(
      map(response => response.json()),
			catchError(error => _throw(error))
    );
  }
}