import { BaseEntity } from "../base-entity";

export class ExtratoBanco extends BaseEntity {
	id: number;
	id_importacao_extrato: number;
	data_evento: Date;
	descricao: string;
	documento: string;
	operacao: string;
	valor: number;
	conciliacao_automatica: boolean;

	checado: boolean;
	conciliado: boolean;
}
