import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseCadastroComponent } from '../../../componentes-base';
import { MessageService, AuthService } from '../../../core';
import { ArquivoRetorno } from '../../../models';
import { ArquivosRetornoHttpService } from '../services';
import { FileUploader } from 'ng2-file-upload';
import { ConfigService } from 'app/core';
import { JwtTokenService } from './../../../core/services/auth/jwt-token.service';
import { PermissionService } from '../../../core/services/permission/permission.service';
import { ArquivosRetornoMensagensModalComponent } from '../mensagens-modal';

@Component({
	selector: 'arquivos-retorno-cadastro',
	templateUrl: './arquivos-retorno-cadastro.component.html',
	styleUrls: ['./arquivos-retorno-cadastro.component.css'],
	providers: [PermissionService]
})
export class ArquivosRetornoCadastroComponent extends BaseCadastroComponent<ArquivoRetorno> implements OnInit {

	public url: string;
	public uploader: FileUploader;
	@ViewChild('arquivoRetornoMensagens') arquivoRetornoMensagens: ArquivosRetornoMensagensModalComponent;

	constructor(public activatedRoute: ActivatedRoute,
		public router: Router,
		private authService: AuthService,
		public messageService: MessageService,
		private arquivosRetornoHttpService: ArquivosRetornoHttpService,
		private configService: ConfigService,
		private jwtTokenService: JwtTokenService,
		public permissionService: PermissionService
	) {
		super(ArquivoRetorno, arquivosRetornoHttpService, activatedRoute, router, messageService, permissionService);
		this.url = `${configService.getBaseUrl()}/arquivoremessa/retorno`;
		this.uploader = new FileUploader({ url: this.url, authToken: `Bearer ${this.jwtTokenService.accessToken}` });
	}

	public onImportAllClick(): void {
		this.uploader.uploadAll();
		this.uploader.response.subscribe(
			response => {
				const mensagens = JSON.parse(response);
				if (mensagens.length) {
					this.arquivoRetornoMensagens.open();
					this.arquivoRetornoMensagens.addMessages(mensagens);
				} else {
					this.messageService.error(
						'Erro ao processar o arquivo de retorno, favor contate o administrador do sistema',
						'Erro'
					);
				}
			}
		);
	}

	public onImportItemClick(item: any): void {
		item.upload();
		item.uploader.response.subscribe(
			response => {
				const mensagens = JSON.parse(response);
				if (mensagens.length) {
					this.arquivoRetornoMensagens.open();
					this.arquivoRetornoMensagens.addMessages(mensagens);
				} else {
					this.messageService.error(
						'Erro ao processar o arquivo de retorno, favor contate o administrador do sistema',
						'Erro'
					);
				}
			}
		);
	}
}
