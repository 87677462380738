import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseModal } from 'app/componentes-utils/modal';
import { AuthService, MessageService } from 'app/core';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { BlocosHttpService, BlocosPesquisaModalComponent, UnidadesHttpService, UnidadesPesquisaModalComponent, UnidadesUsuariosHttpService } from 'app/home/blocos';
import { CategoriaContasHttpService, CategoriaContasPesquisaModalComponent } from 'app/home/plano-de-contas';
import { Antecipacao, Bloco, CategoriaContas, Condomino, Unidade } from 'app/models';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { CobrancaAvulsaHttpService } from '../service';

declare var jQuery: any;

@Component({
  selector: 'antecipacao-modal',
  templateUrl: './antecipacao-modal.component.html',
  styleUrls: ['./antecipacao-modal.component.css']
})
export class AntecipacaoModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @Output() onGenerate: EventEmitter<Antecipacao> = new EventEmitter();

  @ViewChild('inputBloco') inputBloco: ElementRef;
	@ViewChild('blocosPesquisaModal1') blocosPesquisaModal1: BlocosPesquisaModalComponent;
	@ViewChild('unidadesPesquisaModal1') unidadesPesquisaModal1: UnidadesPesquisaModalComponent;
  @ViewChild('categoriaContasPesquisaModal') categoriaContasPesquisaModal: CategoriaContasPesquisaModalComponent;

  public unidades: Unidade[] = [];
  public blocos: Bloco[] = [];
	public categoriasContas: CategoriaContas[] = [];
  public condominos: Condomino[] = [];

  public antecipacao: Antecipacao = new Antecipacao();

  constructor(public activatedRoute: ActivatedRoute,
    public router: Router,
    public messageService: MessageService,
    private authService: AuthService,
    private unidadesHttpService: UnidadesHttpService,
    public permissionService: PermissionService,
    public blocosHttpService: BlocosHttpService,
		public categoriaContasHttpService: CategoriaContasHttpService,
    public cobrancaAvulsaHttpService: CobrancaAvulsaHttpService,
    private unidadesUsuariosHttpService: UnidadesUsuariosHttpService) { }
  
  ngOnInit() {
    this.getAllCategoriasByCondominio();
    this.getBlocosByCondominio();
  }

  open() {
    this.antecipacao = new Antecipacao();
    jQuery(`#${this.idModal}`).modal('show');
  }
  close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Busca os condôminos da unidade
   * @author Marcos Frare
   * @since 18/08/2021
   */
   private getAllCondominos(): void {
    this.condominos = [];
    if (this.antecipacao.id_unidade) {
      this.unidadesUsuariosHttpService.getAllCondominosUnidade(this.antecipacao.id_unidade).subscribe(
        response => {
          this.condominos = response;
          if (this.antecipacao.unidade && this.antecipacao.unidade.id_sacado) {
            this.antecipacao.id_condomino = this.antecipacao.unidade.id_sacado;
          }
        },
        error => {
          this.messageService.error('Erro ao buscar condôminos da unidade! Tente novamente.', 'Erro', error);
        }
      );
    }
  }

  /**
   * Valida geração de antecipação
   * @author Marcos Frare
   * @since 02/08/2021
   */
  private validaAntecipacao(): boolean {
    let msg = '';
    
    if (!this.antecipacao.data_vencimento) {
      msg += '<br> &raquo; Campo vencimento é obrigatório!';
    }
    if (!this.antecipacao.valor) {
      msg += '<br> &raquo; Campo valor é obrigatório!';
    }
    if (!this.antecipacao.id_unidade) {
      msg += '<br> &raquo; Campo unidade é obrigatório!';
    }
    if (!this.antecipacao.id_categoria_conta) {
      msg += '<br> &raquo; Campo categoria de conta é obrigatório!';
    }
    
    if (msg) {
      this.messageService.warning(msg, 'Atenção!');
      return false;
    }
    
    return true;
  }

  /**
   * Click no botão salvar
   * @author Marcos Frare
   * @since 02/08/2021
   */
  public onGerarClick(): void {
    if (this.validaAntecipacao()) {
      let btn = jQuery("#btnGerarAntecipacao");
      btn.button('loading');
      this.cobrancaAvulsaHttpService.gerarAntecipacao(this.antecipacao).subscribe(
        response => {
          btn.button('reset');
          if (!response.data && response.code == '00') {
            if (confirm(`Unidade ${this.antecipacao.bloco.nome}-${this.antecipacao.unidade.nome} e Sacado não habilitados para antecipações! Deseja habilitar?`)) {
              btn.button('loading');
              this.cobrancaAvulsaHttpService.habilitarGerarAntecipacao(this.antecipacao).subscribe(
                () => {
                  btn.button('reset');
                  this.onGenerate.emit(this.antecipacao);
                  this.close();
                },
                error => {
                  btn.button('reset');
                  this.messageService.error('Erro ao gerar cobrança de antecipação! Tente novamente.', 'Erro!', error);
                }
              );
            }
          } else {
            this.onGenerate.emit(this.antecipacao);
            this.close();
          }
        },
        error => {
          btn.button('reset');
          this.messageService.error('Erro ao gerar cobrança de antecipação! Tente novamente.', 'Erro!', error);
        }
      );
    }
  }

  /**
   * Carrega os blocos do condomínio
   * @author Marcos Frare
   * @since 02/08/2021
   */
  public getBlocosByCondominio(): void {
		this.blocos = [];
		this.blocosHttpService.getAllByCondominio(this.authService.getIdCurrentCondominio()).subscribe(
			entities => {
				this.blocos = entities;
			},
			error => {
        this.messageService.error('Erro ao carregar os blocos do condomínio. Tente novamente', 'Erro', error);
      }
		);
	}

  /**
	 * Busca as categorias de contas do condomínio
	 * @author Marcos Frare
	 * @since 27/07/2021
	 */
	private getAllCategoriasByCondominio(): void {
		this.categoriasContas = [];
		this.categoriaContasHttpService.getAllByCondominio(this.authService.getIdCurrentCliente(), 1).then(entities => this.categoriasContas = entities);
	}

  public openCategoriaContasPequisaModal(): void {
		this.categoriaContasPesquisaModal.open();
	}

  public onSelectCategoriaConta(tm: TypeaheadMatch): void {
		const categoriaContas: CategoriaContas = tm.item as CategoriaContas;
    this.antecipacao.categoriaConta = categoriaContas;
    this.antecipacao.id_categoria_conta = categoriaContas.id;
	}

  public onCleanCategoriaContas(input: any): void {
		this.antecipacao.id_categoria_conta = null;
		this.antecipacao.categoriaConta = null;
		input.value = '';
		input.focus();
	}

	public onSelectCategoriaContasPesquisaModal(categoriaContas: CategoriaContas): void {
		if (categoriaContas) {
			this.antecipacao.id_categoria_conta = categoriaContas.id;
			this.antecipacao.categoriaConta = categoriaContas;
		}
		jQuery('#categoriaContas').focus();
	}

	private setBloco(blocoSelecionado: Bloco) {
		this.antecipacao.bloco = blocoSelecionado;
    this.getAllUnidadesByBloco(blocoSelecionado.id);
	}

	public onSelectBloco(tm: TypeaheadMatch): void {
		this.setBloco(tm.item as Bloco);
	}

	public openBlocosPequisaModal(): void {
		this.blocosPesquisaModal1.open();
	}

	public onSelectBlocosPesquisaModal(bloco: Bloco): void {
		if (bloco) {
      this.setBloco(bloco);
    };
		jQuery('#bloco').focus();
	}

	public onCleanBloco(input: any): void {
		this.antecipacao.bloco = new Bloco();
    this.antecipacao.unidade = new Unidade();
    this.antecipacao.id_unidade = null;
		input.value = '';
		input.focus();
    this.onCleanUnidade(jQuery('#unidade'));
	}

	public getAllUnidadesByBloco(idBloco: number): void {
		this.unidades = [];
		this.unidadesHttpService.getAllByBloco(idBloco).subscribe(
			entities => {
        this.unidades = entities;
			},
			error => this.messageService.error('Erro ao carregar as unidades do bloco. Tente novamente', 'Erro', error)
		);
	}

	private setUnidade(unidadeSelecionada: Unidade) {
    this.antecipacao.unidade = unidadeSelecionada;
    this.antecipacao.id_unidade = unidadeSelecionada.id;
    this.getAllCondominos();
	}

	public onSelectUnidade(tm: TypeaheadMatch): void {
		this.setUnidade(tm.item as Unidade);
	}

	public onCleanUnidade(input: any): void {
		this.antecipacao.id_unidade = null;
		input.value = '';
		input.focus();
    this.getAllCondominos();
	}

	public openUnidadesPequisaModal(): void {
		this.unidadesPesquisaModal1.open();
	}

	public onSelectUnidadesPesquisaModal(unidade: Unidade): void {
		if (unidade) {
      this.setUnidade(unidade);
    }
		jQuery('#unidade').focus();
	}

}