import { BaseEntity } from '../base-entity';

export class Acesso extends BaseEntity {
	id_grupo_de_usuario: number;
	criar: boolean;
	atualizar: boolean;
	ler: boolean;
	deletar: boolean;
	menu: string;

	constructor(menu: string) {
		super();
		this.menu = menu;
		this.criar = false;
		this.atualizar = false;
		this.ler = false;
		this.deletar = false;
	}
}