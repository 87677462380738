import { Component, OnInit, ViewChild } from '@angular/core';
import { Permission } from 'app/componentes-base';
import { ActivatedRoute } from '@angular/router';
import { MessageService, AuthService } from 'app/core';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TemplateLancamentoCondominio, tiposDivisaoRateio, tiposUnidade } from 'app/models';
import { LancamentosCondominioHttpService } from '../services';
import { TemplateLancamentoCondominioCadastroModalComponent } from './template-lancamento-condominio-cadastro-modal';

@Component({
  selector: 'template-lancamento-condominio',
  templateUrl: './template-lancamento-condominio.component.html',
  styleUrls: ['./template-lancamento-condominio.component.css']
})
export class TemplateLancamentoCondominioComponent implements OnInit {

  @ViewChild('templateLancamentoCondominioCadastroModal') templateLancamentoCondominioCadastroModal: TemplateLancamentoCondominioCadastroModalComponent;

  private id_condominio: number;
  public permissions: Permission;
  public resource: string;
  public tiposDivisaoRateio = tiposDivisaoRateio;
  public tiposUnidade = tiposUnidade;

  public templatesLancamentoCondominio: TemplateLancamentoCondominio[] = [];

  constructor(private activatedRoute: ActivatedRoute,
      private messageService: MessageService,
      private permissionService: PermissionService,
      private authService: AuthService,
      private lancamentosCondominioHttpService: LancamentosCondominioHttpService,
      private spinner: NgxSpinnerService) {
    this.resource = this.activatedRoute.data['value']['resource'];
  }

  ngOnInit() {
    this.id_condominio = this.authService.getIdCurrentCondominio();
    this.getPermissions();
    this.search();
  }

  /**
   * Busca registros
   * @author Marcos Frare
   * @since 08/07/2020
   */
  public search(): void {
    this.spinner.show();
    this.templatesLancamentoCondominio = [];
    this.lancamentosCondominioHttpService.buscaTemplatesCondominio(this.id_condominio).subscribe(
      response => {
        this.spinner.hide();
        this.templatesLancamentoCondominio = response;
      },
      error => {
        this.spinner.hide();
        this.messageService.error('Erro ao buscar configurações', 'Erro!', error);
      }
    );
  }

  /**
   * Retorna o nome do tipo de divisão do rateio
   * @param id 
   * @author Marcos Frare
   * @since 07/08/2020
   */
  public getNomeTipoDivisaoRateio(id: number): string {
    for (let tipo of this.tiposDivisaoRateio) {
      if (tipo.id == id) {
        return tipo.nome;
      }
    }
    return '';
  }

  public getNomeCobrarTipoUnidade(id: number): string {
    for (let tipoUnidade of this.tiposUnidade) {
      if (tipoUnidade.id == id) {
        return tipoUnidade.nome;
      }
    }
    return '';
  }

  /**
   * Permissoes do usuário
   * @author Marcos Frare
   * @since 26/06/2020
   */
  public getPermissions() {
    this.permissionService.getPermissions(this.resource).subscribe(
      permissions => this.permissions = permissions
    );
  }

  /**
   * Cancelar processo
   * @author Marcos Frare
   * @since 08/07/2020
   */
  public cancelar(): void {
    // this.router.navigate(['../'], {relativeTo: this.activatedRoute});
  }

  /**
   * Abrir tela para cadastro de configuração
   * @author Marcos Frare
   * @since 09/07/2020
   */
  public insert(): void {
    this.templateLancamentoCondominioCadastroModal.id = null;
    this.templateLancamentoCondominioCadastroModal.open();
  }

  /**
   * Editar uma configuração
   * @param id ID da configuração
   * @author Marcos Frare
   * @since 09/07/2020
   */
  public edit(template: TemplateLancamentoCondominio): void {
    this.templateLancamentoCondominioCadastroModal.id = template.id;
    this.templateLancamentoCondominioCadastroModal.open();
  }

  /**
   * Exclusão de uma template
   * @param template 
   * @author Marcos Frare
   * @since 07/08/2020
   */
  public delete(template: TemplateLancamentoCondominio) {
    if (confirm(`Deseja excluir a configuração de ${template.descricao}?`)) {
      this.spinner.show();
      this.lancamentosCondominioHttpService.deleteTemplate(template.id).subscribe(
        () => {
          this.spinner.hide();
          this.search();
        },
        error => {
          this.spinner.hide();
          this.messageService.error('Erro ao tentar excluir configuração! Tente novamente', 'Erro', error);
        }
      );
    }
  }

}
