import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseCadastroComponent } from '../../../../componentes-base';
import { AuthService, MessageService } from '../../../../core';
import { Telefone } from '../../../../models';
import { TelefonesService } from '../services/telefones.service';
import { PermissionService } from '../../../../core/services/permission/permission.service';

@Component({
  selector: 'telefones-editar',
  templateUrl: './telefones-editar.component.html',
  styleUrls: ['./telefones-editar.component.css'],
  providers: [PermissionService]
})
export class TelefonesEditarComponent extends BaseCadastroComponent<Telefone> implements OnInit {
  @Input('isShowOptions') isShowOptions = true;

  public tipos = [
    { id: 1, nome: 'Residencial' },
    { id: 2, nome: 'Comercial' },
    { id: 3, nome: 'Celular' },
    { id: 4, nome: 'Recado' },
    { id: 5, nome: 'Outro' },
  ];

  constructor(public telefonesService: TelefonesService,
    activatedRoute: ActivatedRoute,
    router: Router,
    messageService: MessageService,
    private authService: AuthService,
    permissionService: PermissionService) {
    super(Telefone, telefonesService, activatedRoute, router, messageService, permissionService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  cancel() {
    this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
  }

  saveToUser() {
    if (this.editMode) {
      this.entity.id_usuario_telefone = this.entity.id_usuario;
    } else {
      this.entity.id_usuario_telefone = parseInt(this.activatedRoute.snapshot.url[0].path);
    }
    this.save();
  }

}