import { Component, ElementRef, ViewChild } from '@angular/core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

import { BaseVisualizacaoComponent } from 'app/componentes-base';

import { Bloco, Unidade, CertidaoNegativaDebito, Usuario } from 'app/models'
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService, AuthService } from 'app/core';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { RelatorioModalComponent } from '../../relatorios/relatorio-modal';
import { CertidaoNegativaDebitoHttpService } from './services';
import { Permission } from 'app/componentes-base';
import { CertidaoNegativaDebitoFiltro } from './certidao-negativa-debito-filtro';
import { NgxSpinnerService } from 'ngx-spinner';
import { BlocosHttpService, BlocosPesquisaModalComponent, UnidadesHttpService, UnidadesPesquisaModalComponent } from 'app/home/blocos';

declare var jQuery: any;

@Component({
  selector: 'certidao-negativa-debito',
  templateUrl: './certidao-negativa-debito.component.html',
  styleUrls: ['./certidao-negativa-debito.component.css'],
  providers: [PermissionService]
})
export class CertidaoNegativaDebitoComponent extends BaseVisualizacaoComponent<CertidaoNegativaDebito> {

  @ViewChild('inputBloco') inputBloco: ElementRef;
  @ViewChild('blocosPesquisaModal') blocosPesquisaModal: BlocosPesquisaModalComponent;
  @ViewChild('inputUnidade') inputUnidade: ElementRef;
  @ViewChild('unidadesPesquisaModal') unidadesPesquisaModal: UnidadesPesquisaModalComponent;
  @ViewChild('relatorioModal') relatorioModal: RelatorioModalComponent;

  private id_condominio: number;
  private id_cliente: number;
  private id_usuario: number;
  public blocos: Bloco[] = [];  public bloco: Bloco;
  public unidades: Unidade[] = [];
  public showInputBlocos: boolean = true;
  public permissions: Permission;
  public certidaoNegativaDebito: CertidaoNegativaDebito;
  public usuario: Usuario;
  public filtro: CertidaoNegativaDebitoFiltro;

  constructor(
      activatedRoute: ActivatedRoute,
      router: Router,
      messageService: MessageService,
      permissionService: PermissionService,
      private authService: AuthService,
      private blocosHttpService: BlocosHttpService,
      private unidadesHttpService: UnidadesHttpService,
      private certidaoNegativaDebitoHttpService: CertidaoNegativaDebitoHttpService,
      public spinner: NgxSpinnerService) {
    super('#tableCertidao', certidaoNegativaDebitoHttpService, activatedRoute, router, messageService, permissionService, spinner);
    this.id_usuario = this.authService.getIdCurrentUser();
    this.filtro = new CertidaoNegativaDebitoFiltro();
  }

  ngOnInit() {
    this.id_condominio = this.authService.getIdCurrentCondominio();
    this.id_cliente = this.authService.getIdCurrentCliente();
    this.getPermissions();
    this.onCleanBloco(this.inputBloco.nativeElement);
    this.getBlocosByCondominio();
    this.setInitialFilter();
    this.search();
  }

  public getPermissions() {
    this.permissionService.getPermissions(this.activatedRoute.data['value']['resource']).subscribe(
      permissions => this.permissions = permissions
    );
  }

  public search(): void {
    this.spinner.show();
    this.entities = [];
    this.destroyTable();
    this.certidaoNegativaDebitoHttpService.search(this.filtro).subscribe(
      response => {
        this.spinner.hide();
        this.entities = response;
        this.showTable();
      },
      error => {
        this.spinner.hide();
        this.messageService.error('Erro ao carregar as certidões do condomínio. Tente novamente', 'Erro', error);
      }
    );
  }

  public setInitialFilter(): void {
    this.filtro = new CertidaoNegativaDebitoFiltro();
    this.filtro.id_condominio = this.id_condominio;
    this.onCleanBloco(this.inputBloco.nativeElement);
    this.filtro.limpar();
  }

  public print(entity: CertidaoNegativaDebito): void {
    const params = {
      id_condominio: this.id_condominio,
      id_usuario: this.id_usuario,
      id: entity.id
    }
    this.relatorioModal.url = '/certidaonegativadebito/emitir';
    this.relatorioModal.params = params;
    this.relatorioModal.open();
  }

  /**
   * Carrega os blocos do Condomínio
   */
  private getBlocosByCondominio(): void {
    this.blocos = [];
    this.blocosHttpService.getAllByCondominio(this.id_condominio).subscribe(
      entities => {
        this.blocos = entities;
        if (entities.length === 1) this.setBloco(entities[0])
      },
      error => this.messageService.error('Erro ao carregar os blocos do condomínio. Tente novamente', 'Erro', error)
    );
  }

  /**
   * Atribui valor do bloco
   * @param blocoSelecionado Bloco selecionado
   */
  private setBloco(blocoSelecionado: Bloco): void {
    this.filtro.bloco = blocoSelecionado;
    this.filtro.id_bloco = blocoSelecionado.id;
    this.getAllUnidadesByBloco(this.filtro.bloco.id);
    this.onCleanUnidade(this.inputUnidade.nativeElement);
  }

  /**
   * Busca todas as unidade de um bloco
   * @param idBloco 
   */
  public getAllUnidadesByBloco(idBloco: number): void {
    this.unidades = [];
    this.unidadesHttpService.getAllByBloco(idBloco).subscribe(
      entities => {
        for (const unidade of entities) {
          if (unidade.nome) {
            this.unidades.push(unidade);
          }
        }
      },
      error => this.messageService.error('Erro ao carregar as unidades do bloco. Tente novamente', 'Erro', error)
    );
  }

  onSelectBloco(tm: TypeaheadMatch): void {
    this.setBloco(tm.item as Bloco);
  }

  /**
   * Limpa campo de Unidades
   * @param input Objeto do elemento
   */
  public onCleanUnidade(input: any): void {
    this.filtro.unidade = null;
    this.filtro.id_unidade = null;
    input.value = '';
    input.focus();
  }

  private setUnidade(unidadeSelecionada: Unidade): void {
    this.filtro.unidade = unidadeSelecionada;
    this.filtro.id_unidade = unidadeSelecionada.id;
  }

  public onSelectUnidade(tm: TypeaheadMatch): void {
    this.setUnidade(tm.item as Unidade);
  }

  openBlocosPequisaModal() {
    this.blocosPesquisaModal.open();
  }

  onSelectBlocosPesquisaModal(bloco: Bloco) {
    if (bloco) {
      this.setBloco(bloco)
    }
    jQuery('#bloco').focus();
  }

  public openUnidadesPequisaModal(): void {
    this.unidadesPesquisaModal.open();
  }

  public onSelectUnidadesPesquisaModal(unidade: Unidade): void {
    if (unidade) { this.setUnidade(unidade); }
    jQuery('#unidade').focus();
  }

  public onCleanBloco(input: any): void {
    this.onCleanUnidade(this.inputUnidade.nativeElement);
    this.filtro.bloco = null;
    this.filtro.id_bloco = null;
    input.value = '';
    input.focus();
  }

}