import { Injectable } from '@angular/core';

@Injectable()
export class RelatoriosUtilsService {


    /** Abre o PDF do relatório em uma nova aba */
    abrirRelatorio(pdf: any) {
        const blob = new Blob([pdf.blob()], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(blob);
        window.open(fileURL, '_blank');
    }

}
