import { Component, AfterViewInit, OnInit, ViewChild } from '@angular/core';
import { AuthService, MenuService, MenuItem, MessageService } from '../../../core';
import { TIPO_USUARIO, Usuario } from '../../../models';
import { CondominiosHttpService } from './../../condominios/services/condominios-http.service';
import { Router } from '@angular/router';
import { Condominio } from '../../../models';
import { TrocaCondominioModalComponent } from './troca-condominio-modal';
import { AlterarSenhaModalComponent } from './alterar-senha-modal';

declare var jQuery: any;

@Component({
	selector: 'navigation',
	templateUrl: 'navigation.template.html',
	styleUrls: ['./navigation.component.css']
})

export class NavigationComponent implements OnInit {
	public user: Usuario;
	public menu: MenuItem[];
	public condominio: Condominio;
	public idUsuario: number;
	public idCliente: number;
	public tipoUsuario: number;
	public tiposUsuarios = TIPO_USUARIO;

	@ViewChild('trocaCondominioModal') trocaCondominioModal: TrocaCondominioModalComponent;
	@ViewChild('alterarSenhaModal') alterarSenhaModal: AlterarSenhaModalComponent;

	constructor(private router: Router,
		public authService: AuthService,
		private condominiosHttpService: CondominiosHttpService,
		private menuService: MenuService,
		private messageService: MessageService) {
		authService.changedUser$.subscribe(idUsuario => this.idUsuario = idUsuario);
		authService.changedCliente$.subscribe(idCliente => this.idCliente = idCliente);
		authService.changedCondominio$.subscribe(condominio => this.condominio = condominio);
		this.idUsuario = this.authService.getIdCurrentUser();
		this.idCliente = this.authService.getIdCurrentCliente();
		this.tipoUsuario = this.authService.getTipoCurrentUser();
	}

	ngOnInit() {
		this.user = this.authService.getCurrentUser();
		this.condominio = this.authService.getCurrentCondominio();
		this.loadMenu();
	}

	private loadMenu(): void {
		if (!this.idCliente || !this.idUsuario) { return null; }
		this.menu = [];
		this.menuService.getMenusByClienteAndUsuario(this.idCliente, this.idUsuario).subscribe(
			menu => {
				this.menu = menu;
				setTimeout(() => {
					jQuery('#side-menu').metisMenu({
						toggle: false,
						activeClass: 'is-expanded'
					});
				}, 200);
			},
			error => this.messageService.error('Erro ao carregar os menus. Tente novamente', 'Erro', error)
		);
	}

	public logout(): void {
		this.authService.logout();
	}

	/**
	 * Logout do condomínio
	 * @author Marcos Frare
	 * @since 03/04/2020
	 */
	public logoutCondominio(): void {
		this.authService.logoutCondominio();
	}

	public editarCondominio(idCondominio: number): void {
		this.router.navigate([`condominios/${idCondominio}`]);
	}

	public showTrocaCondominioModal(): void {
		this.trocaCondominioModal.open();
	}

	public onTrocarCondominio(condominio: Condominio): void {
		this.condominio = condominio;
		this.authService.setLoggedInfo(condominio);
		this.router.navigate(['./dashboard']);
	}

	public checkAccess(menu: MenuItem): boolean {
		return menu.gerencial ? this.isClienteLoaded() && menu.ler : this.isCondominioLoaded() && menu.ler;
	}

	public isCondominioLoaded(): boolean {
		return this.authService.isCondominioLoaded;
	}

	public isClienteLoaded(): boolean {
		return this.authService.isClienteLoaded;
	}

	public isAdmin(): boolean {
		return this.authService.isAdmin;
	}

	/**
	 * Reset de senha do usuário
	 * @author Marcos Frare
	 * @since 01/05/2021
	 */
	public resetPassword(): void {
		this.alterarSenhaModal.open();
	}

}