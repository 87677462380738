import { BaseEntity } from '../base-entity';
import { Bloco } from './bloco';
import { Unidade } from './unidade';

export class UnidadeIsencao extends BaseEntity {
	id_unidade: number;
	isentar_taxa_condominio: boolean;
	isentar_fundo_reserva: boolean;

	id_bloco: number;
	nome_bloco: string;
	nome_unidade: string;
	bloco: Bloco;
	unidade: Unidade;
	
	constructor() {
		super();
		this.id_unidade = null;
		this.isentar_taxa_condominio = false;
		this.isentar_fundo_reserva = false;
	}
}