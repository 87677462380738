import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { BaseModal } from '../../../componentes-utils/modal';
import { CategoriaContas } from '../../../models';

declare var jQuery: any;

@Component({
	selector: 'plano-de-contas-cadastro-modal',
	templateUrl: './plano-de-contas-cadastro-modal.component.html'
})
export class PlanoDeContasCadastroModalComponent implements OnInit, BaseModal {

	@Input() idModal: string;
	@Output() afterSave = new EventEmitter<CategoriaContas>();
	@ViewChild('cadastro') cadastro: any;
	initialized = false;

	ngOnInit() {
		if (!this.idModal) {
			this.idModal = 'planoDeContasCadastroModal';
		}
		this.initialized = true;
	}

	public open(): void {
		jQuery(`#${this.idModal}`).modal('show');
		if (!this.initialized) {
			this.cadastro.loadDefault();
		}
	}

	public close(): void {
		jQuery(`#${this.idModal}`).modal('hide');
	}

	public onSaveCategoria(categoriaConta: CategoriaContas): void {
		this.afterSave.emit(categoriaConta);
		this.close();
	}

}