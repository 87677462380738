import { UnidadesHistoricoMoradorFilterPipe } from './unidades/historico/pipes/unidades-historico-morador-filter.pipe';
import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared';

import { BlocosComponent } from './blocos.component';
import { BlocosCadastroComponent } from './cadastro';
import { BlocosNovoAndarModalComponent } from './novo-andar-modal';
import { BlocosHttpService } from './services';
import { UnidadesComponent, UnidadesGaragensModalComponent, UnidadesContatosModalComponent, UnidadesUsuariosModalComponent, UnidadesHistoricoModalComponent, UnidadesTrocaUsuarioComponent, UnidadesHistoricoProprietarioFilterPipe } from './unidades';

import { UnidadesHttpService } from './unidades/services/unidades-http.service';
import { UsuariosModule } from '../usuarios';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ConfiguracaoServicoUnidadeModalComponent } from './configuracao-servico-unidade-modal';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { VeiculoModule } from '../veiculo';

@NgModule({
	imports: [
		SharedModule,
		UsuariosModule,
		TypeaheadModule,
		MultiselectDropdownModule,
		VeiculoModule,
	],
	declarations: [
		// Components
		BlocosComponent,
		BlocosCadastroComponent,
		BlocosNovoAndarModalComponent,
		UnidadesComponent,
		UnidadesTrocaUsuarioComponent,
		UnidadesHistoricoModalComponent,
		UnidadesGaragensModalComponent,
		UnidadesContatosModalComponent,
		UnidadesUsuariosModalComponent,

		// Pipes
		UnidadesHistoricoProprietarioFilterPipe,
		UnidadesHistoricoMoradorFilterPipe,
		ConfiguracaoServicoUnidadeModalComponent,
	],
	providers: [
		BlocosHttpService,
		UnidadesHttpService
	]
})
export class BlocosModule { }