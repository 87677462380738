import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared';
import { PagamentosComponent } from './pagamentos.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { PlanoDeContasModule } from '../plano-de-contas';
import { FornecedoresModule } from '../fornecedores';
import { PagamentoAnexoModalComponent } from './pagamento-anexo-modal';
import { PagamentosHttpService } from './services';
import { LancamentosCondominioModule } from '../lancamentos-condominio';
import { PagamentoChequeModalComponent } from './pagamento-cheque-modal';

@NgModule({
	imports: [
		SharedModule,
		TypeaheadModule,
		PlanoDeContasModule,
		FornecedoresModule,
		LancamentosCondominioModule
	],
	declarations: [
		PagamentosComponent,
		PagamentoAnexoModalComponent,
		PagamentoChequeModalComponent
	],
	providers: [
		PagamentosHttpService
	]
})
export class PagamentosModule { }