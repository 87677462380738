import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';

import { Crud, CrudService, ConfigService, CustomHttp } from '../../../core';
import { Titulo, ConfiguracaoLayoutTitulo, Ocorrencia, PagamentoUnidade, ConsumoUnidade, ConfiguracaoLayoutRecibo } from '../../../models';

@Injectable()
export class BoletosHttpService implements Crud<Titulo> {

	public resource = 'titulo';
	private baseUrl: string;

	constructor(private customHttp: CustomHttp,
		private crudService: CrudService,
		private configService: ConfigService) {
		this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
	}

	get(id: number): Observable<Titulo> {
		return this.crudService.get<Titulo>(this.resource, id);
	}

	getAll(): Observable<Titulo[]> {
		return this.crudService.getAll<Titulo>(this.resource);
	}

	post(entity: Titulo): Observable<Titulo> {
		return this.crudService.post<Titulo>(this.resource, entity);
	}

	put(entity: Titulo): Observable<Titulo> {
		return this.crudService.put<Titulo>(this.resource, entity);
	}

	public delete(entity: Titulo, force?: boolean): Observable<any> {
		force = !force ? false : true;
		return this.customHttp.delete(`${this.baseUrl}/excluir/${entity.id}`, {params: {force}}).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public getAllByDataCompetencia(filtro: any, idCondominio: number): Observable<any> {
		return this.customHttp.post(`${this.baseUrl}/condominio/${idCondominio}`, filtro).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public getUnidadeByUsuario(idUsuario: number): Observable<any> {
		return this.customHttp.get(`${this.configService.getBaseUrl()}/unidade/consultar/usuario/${idUsuario}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public download(idCondominio: number, idRateio: number) {
		return this.customHttp.get(`${this.baseUrl}/download/condominio/${idCondominio}/rateio/${idRateio}`).pipe(
			map(() => window.open(`${this.baseUrl}/download/condominio/${idCondominio}/rateio/${idRateio}`, '_self')),
			catchError(error => _throw(error))
			);
	}

	public sendMail(boletos: any[]): Observable<Response> {
		return this.customHttp.post(`${this.baseUrl}/enviarporemail`, { boletos }).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public changeSituacaoEmissao(ids_titulos: number[]): Observable<Response> {
		return this.customHttp.post(`${this.baseUrl}/alterasituacaoemissao`, { ids_titulos }).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public gerarTitulos(id_condominio: number, ids_titulos?: number[]): Observable<Response> {
		const params = { id_condominio, ids_titulos };
		return this.customHttp.post(`${this.baseUrl}/gerar`, params).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public calcRenegociacao(params: any): Observable<any> {
		return this.customHttp.post(`${this.baseUrl}/calcularrenegociacao`, params).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public calcParcelas(params: any): Observable<any> {
		return this.customHttp.post(`${this.baseUrl}/calcularparcelasrenegociacao`, params).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public getTitulosRenegociacao(params: any): Observable<any[]> {
		return this.customHttp.post(`${this.baseUrl}/gettitulosrenegociacao`, params).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public validaRenegociacao(params: any): Observable<any[]> {
		return this.customHttp.post(`${this.baseUrl}/validarenegociacao`, params).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public getRenegociacoes(id_titulo: number): Observable<any> {
		return this.customHttp.get(`${this.baseUrl}/${id_titulo}/renegociacoes`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public postGerarArquivoRemessa(id_condominio: number, ids_titulos: number[]): Observable<any> {
		return this.customHttp.post(`${this.baseUrl}/arquivoremessa`, { id_condominio, ids_titulos }).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public renegociar(params: any): Observable<any[]> {
		return this.customHttp.post(`${this.baseUrl}/renegociar`, params).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public pedirBaixa(params: any): Observable<any> {
		return this.customHttp.post(`${this.baseUrl}/pedirbaixa`, params).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public desfazBaixa(id_titulo: number, id_condominio: number): Observable<any> {
		return this.customHttp.post(`${this.baseUrl}/cancelabaixa`, { id_titulo, id_condominio }).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public recalculate(id_condominio: number, id_titulo: number, data_vencimento: string): Observable<Response> {
		const params = { id_condominio, id_titulo, data_vencimento };
		return this.customHttp.post(`${this.baseUrl}/recalcular`, params).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public getCompetencia(data: any): Observable<Response> {
		return this.customHttp.post(`${this.baseUrl}/competencia`, data).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Busca os títulos de um condômino
	 * @param filtro 
	 * @author Marcos Frare
	 * @since 05/04/2020
	 */
	 public getTitulosCondominoFiltro(filtro: any): Observable<any[]> {
		return this.customHttp.get(`${this.baseUrl}/condomino/consultar`, { params: { filtro } }).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Envio de email de títulos conforme filtro
	 * @param filtro 
	 * @author Marcos Frare
	 * @since 29/08/2020
	 */
	public sendMailFiltro(filtro: ConfiguracaoLayoutTitulo): Observable<any[]> {
		return this.customHttp.post(`${this.baseUrl}/listar/enviar-email`, filtro).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Persistir configuração de layout de títulos
	 * @param configuracaoLayoutTitulo 
	 * @author Marcos Frare
	 * @since 05/09/2020
	 */
	public gravarConfigLayout(configuracaoLayoutTitulo: ConfiguracaoLayoutTitulo): Observable<ConfiguracaoLayoutTitulo> {
		return this.customHttp.post(`${this.baseUrl}/layout/configuracao`, configuracaoLayoutTitulo).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Busca configuração do layout do título
	 * @param id_condominio 
	 * @author Marcos Frare
	 * @since 07/09/2020
	 */
	public getConfigLayout(id_condominio: number): Observable<ConfiguracaoLayoutTitulo> {
		return this.customHttp.get(`${this.baseUrl}/layout/configuracao/${id_condominio}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Busca dados de ocorrencias de uma Unidade
	 * @param id_unidade 
	 * @author Marcos Frare
	 * @since 08/09/2020
	 */
	public buscaTitulosAbertoUnidade(id_unidade: number): Observable<any> {
		return this.customHttp.get(`${this.baseUrl}/ocorrencia/unidade/${id_unidade}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Persiste os dados da Ocorrência
	 * @param ocorrencia 
	 * @author Marcos Frare
	 * @since 08/09/2020
	 */
	public gravarOcorrenciaTitulo(ocorrencia: Ocorrencia): Observable<Ocorrencia> {
		return this.customHttp.post(`${this.baseUrl}/ocorrencia`, ocorrencia).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Exclusão de uma ocorrência
	 * @param ocorrencia 
	 * @author Marcos Frare
	 * @since 10/09/2020
	 */
	public excluirOcorrencia(ocorrencia: Ocorrencia): Observable<any> {
		return this.customHttp.delete(`${this.baseUrl}/ocorrencia/${ocorrencia.id}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Envia dados para edição do Título
	 * @param titulo 
	 * @author Marcos Frare
	 * @since 12/08/2021
	 */
	public alteracaoTitulo(titulo: Titulo, pagamentos: PagamentoUnidade[], consumos: ConsumoUnidade[]): Observable<any> {
		return this.customHttp.put(`${this.baseUrl}/alteracao/${titulo.id}`, {titulo, pagamentos, consumos}).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Busca o título e itens do titulo para alteração
	 * @param id 
	 * @author Marcos Frare
	 * @since 20/08/2021
	 */
	public getTituloEdicao(id: number): Observable<any> {
		return this.customHttp.get(`${this.baseUrl}/alteracao/${id}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Remove um pagamento contido no título
	 * @param id_pagamento 
	 * @author Marcos Frare
	 * @since 23/08/2021
	 */
	public removePagamentoTitulo(id_pagamento: number): Observable<any> {
		return this.customHttp.delete(`${this.baseUrl}/alteracao/pagamento-unidade/${id_pagamento}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Busca configuração do layout do recibo
	 * @param id_condominio 
	 * @author Marcos Frare
	 * @since 21/11/2022
	 */
	 public getConfigLayoutRecibo(id_condominio: number): Observable<ConfiguracaoLayoutRecibo> {
		return this.customHttp.get(`${this.baseUrl}/recibo/configuracao/${id_condominio}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Persistir configuração de layout do recibo
	 * @param configuracaoLayoutRecibo
	 * @author Marcos Frare
	 * @since 21/11/2022
	 */
	 public gravarConfigLayoutRecibo(configuracaoLayoutRecibo: ConfiguracaoLayoutRecibo): Observable<ConfiguracaoLayoutRecibo> {
		return this.customHttp.post(`${this.baseUrl}/recibo/configuracao`, configuracaoLayoutRecibo).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Estornar pagamento de um título
	 * @param id_titulo 
	 * @author Marcos Frare
	 * @since 10/02/2023
	 */
	public estornarPagamentoTitulo(id_titulo: number): Observable<any> {
		return this.customHttp.put(`${this.baseUrl}/pagamento/estornar/${id_titulo}`, {}).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Mudar data de liquidação de um título
	 * @param id_titulo 
	 * @param dataCredito
	 * @author Marcos Frare
	 * @since 27/02/2023
	 */
	public mudarDataCreditoTitulo(id_titulo: number, dataCredito: Date): Observable<any> {
		return this.customHttp.put(`${this.baseUrl}/pagamento/mudar-data-credito/${id_titulo}`, {dataCredito}).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}
	
}