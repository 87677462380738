import { BaseEntity } from '../base-entity';
import { CategoriaContas } from './categoria-contas';
import { TarifasConsumo } from './tarifas-consumo';
import { CategoriaTarifaConsumo } from './categoria-tarifa-consumo';

export class TipoConsumo extends BaseEntity {
	nome: string;
	cobranca: number;
	unidade_medida: number;
	id_cliente: number;
	abrangencia: number;
	id_condominio: number;
	id_categoria_conta: number;
	id_leitura: number;
	nome_leiturista: string;
	data_leitura: number;
	categoriaContas: CategoriaContas;
	tarifas_consumo: TarifasConsumo[] = [];
	id_dependencia: number;
	porcento_tarifa: number;
	tipo_tarifa: number;
	mostra_valor_titulo: boolean;
	categorias_tarifa_consumo: CategoriaTarifaConsumo[];

	id_tipo_consumo: number;
	msg: string;

	constructor() {
		super();
		this.tipo_tarifa = 2;
	}
}

export const abrangenciaTiposConsumo = [
	{ id: 1, nome: 'Global' },
	{ id: 2, nome: 'Local' }
];