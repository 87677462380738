import { Component, Input, Output, OnInit, EventEmitter} from '@angular/core';
import { BaseModal } from '../../../componentes-utils/modal';
import { Condominio } from '../../../models';

declare var jQuery: any;

@Component({
    selector: 'condominios-pesquisa-modal',
    templateUrl: './condominios-pesquisa-modal.component.html',
    styleUrls: ['./condominios-pesquisa-modal.component.css']
})
export class CondominiosPesquisaModalComponent implements OnInit, BaseModal {
    @Input() idModal: string;
    @Input() condominios: Condominio[];
    @Output() select = new EventEmitter<Condominio>();

    constructor() { }

    ngOnInit() {
        if (!this.idModal) {
            this.idModal = 'condominiosPesquisaModal';
        }
    }

    open() {
        jQuery(`#${this.idModal}`).modal('show');
    }

    close() {
        jQuery(`#${this.idModal}`).modal('hide');
    }

    selectClick(condominio: Condominio) {
        this.select.emit(condominio);
        this.close();
    }
}
