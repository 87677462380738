import { NgModule } from '@angular/core';

import { SharedModule } from '../../../shared';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { ConsumosUnidadesRelComponent } from './consumos-unidades-rel.component';

import { BlocosHttpService } from '../../blocos';
import { TiposConsumoHttpService } from '../../tipos-consumo';

@NgModule({
    imports: [
        SharedModule,
        TypeaheadModule
    ],
    declarations: [
        ConsumosUnidadesRelComponent
    ],
    providers: [
        BlocosHttpService,
        TiposConsumoHttpService
    ]
})
export class ConsumosUnidadesRelModule { }
