import { BaseEntity } from '../base-entity';

export class ContatoProprietarioMorador extends BaseEntity {
	id_tipo_contato: number;
	id_proprietario_morador: number;
	contato: string;
	enviar_email: boolean;

	constructor() {
		super();
		this.id_tipo_contato = null;
		this.id_proprietario_morador = null;
		this.contato = '';
		this.enviar_email = true;
	}
}