import { BaseEntity } from '../base-entity';

export class DemonstrativoRateio extends BaseEntity {
	data_competencia: any;
	fechado: boolean;
	selected: boolean;
	possui_titulo: boolean;
	condominio: string;
	valor_total: number;
	id_condominio: number;
	serie: string;
	cobrar_fundo_reserva: boolean;
	data_vencimento: any;

	ids_parcela_rateio: number[];
	ids_pagamentos_especificos: number[];
	ids_pagamentos_unidades: number[];
	ids_consumo: number[];
	proprietario: boolean;
	vencimento_unidade: boolean;
	consumo_unidade: boolean;
	pagamentos_especificos: number[];
	pagamentos_condominio: number[];
	parcelas_despesa_rateio: any[];
	receitas: any[];
	receitas_unidades: any[];
	consumos_unidades: any[];
	rateios_unidades: any[];
	podeGerar: boolean;
	msg: string;

	tipo_fundo_reserva: number;
	taxa_fundo_reserva: number;

	constructor() {
		super();
		this.cobrar_fundo_reserva = true;
		this.parcelas_despesa_rateio = [];
		this.receitas = [];
		this.receitas_unidades = [];
		this.consumos_unidades = [];
		this.proprietario = false;
		this.consumo_unidade = true;
		this.vencimento_unidade = true;
	}
}

export enum VIEW_MODE {
	VIEW = 1,
	UPDATE = 2,
};
