import { Fornecedor } from './../../models/entidades/fornecedor';
import { FornecedoresHttpService } from './../fornecedores/services/fornecedores-http.service';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseVisualizacaoComponent } from '../../componentes-base';
import { Seguro, CategoriaContas } from '../../models';
import { AuthService, MessageService } from '../../core';
import { SegurosHttpService } from './services';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { DatePipe } from '@angular/common';
import { CategoriaContasPesquisaModalComponent, CategoriaContasHttpService } from '../plano-de-contas/categoria-contas';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';

declare var jQuery: any;

@Component({
    selector: 'seguros',
    templateUrl: 'seguros.template.html',
    styleUrls: ['./seguros.component.css'],
    providers: [PermissionService, DatePipe]
})

export class SegurosComponent extends BaseVisualizacaoComponent<Seguro> {

    @ViewChild('categoriaContasPesquisaModal') categoriaContasPesquisaModal: CategoriaContasPesquisaModalComponent;
    
    public filtro: any = {};
    public categoriasContas: CategoriaContas[] = [];
    public fornecedores: Fornecedor[] = [];
    public idCliente: number;
    public idCondominio: number;

    constructor(activatedRoute: ActivatedRoute,
                router: Router,
                messageService: MessageService,
                permissionService: PermissionService,
                private authService: AuthService,
                private fornecedoresHttpService: FornecedoresHttpService,
                private segurosHttpService: SegurosHttpService,
                private categoriaContasHttpService: CategoriaContasHttpService,
                private datePipe: DatePipe,
                public spinner: NgxSpinnerService) {
        super('#tableSeguros', segurosHttpService, activatedRoute, router, messageService, permissionService, spinner);
    }

    ngOnInit() {
        this.idCliente = this.authService.getIdCurrentCliente();
        this.idCondominio = this.authService.getIdCurrentCondominio();
        this.getAllFornecedoresByCliente();
        this.getAllCategoriasByCondominio();
        this.setInitialFilter();
        this.search();
        this.getPermissions();
    }

    private getAllFornecedoresByCliente(): void {
        this.fornecedoresHttpService.getAllByCliente(this.idCliente).subscribe(
            entities => this.fornecedores = entities,
            error => this.messageService.error('Erro ao carregar os fornecedores', 'Erro', error)
        );
    }

     private getAllCategoriasByCondominio(): void {
        this.categoriasContas = [];
        this.categoriaContasHttpService.getAllByCondominio(this.idCondominio, 2)
            .then(entities => this.categoriasContas = entities);
    }

    public onSelectCategoriaContas(tm: TypeaheadMatch): void {
        const categoriaContas: CategoriaContas = tm.item as CategoriaContas;
        if (categoriaContas) {
            this.filtro.id_categoria_conta = categoriaContas.id;
            this.filtro.categoriaContas = categoriaContas;
        } else {
            this.filtro.id_categoria_conta = null;
            this.filtro.categoriaContas = null;
        }
    }

    public onCleanCategoriaContas(input: any): void {
        this.filtro.id_categoria_conta = null;
        this.filtro.categoriaContas = null;
        input.value = '';
        input.focus();
    }

    public openCategoriaContasPequisaModal(): void {
        this.categoriaContasPesquisaModal.open();
    }

    public onSelectCategoriaContasPesquisaModal(categoriaContas: CategoriaContas): void {
        if (categoriaContas) {
            this.filtro.id_categoria_conta = categoriaContas.id;
            this.filtro.categoriaContas = categoriaContas;
        }
        jQuery('#categoriaContas').focus();
    }

    public getAll(): void {
        this.spinner.show();
        this.entities = [];
        this.destroyTable();
        this.segurosHttpService.getAllByCondominio(this.idCondominio)
            .subscribe(
                entity => {
                    this.spinner.hide();
                    this.entities = entity;
                    this.showTable();
                },
                error => {
                    this.spinner.hide();
                    this.messageService.error('Erro ao carregar os seguros do condomínio selecionado. Tente novamente', 'Erro', error);
                }
            );
    }

    public search(): void {
        this.spinner.show();
        this.entities = [];
        this.destroyTable();
        this.segurosHttpService.search(this.parseSearch(this.filtro))
            .subscribe(
                entities => {
                    this.spinner.hide();
                    this.entities = entities;
                    this.showTable();
                },
                error => {
                    this.spinner.hide();
                    this.messageService.error('Erro ao carregar os seguros do condomínio selecionado. Tente novamente', 'Erro', error);
                }
            );
    }

    public setInitialFilter(): void {
        const today = new Date();
        const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    
        this.filtro.id_condominio = this.idCondominio;
        this.filtro.data_solicitacao_inicial = firstDay;
        this.filtro.data_solicitacao_final = lastDay;
    }

    public delete(seguro: Seguro): void {
        if (confirm(`Tem certeza que deseja excluir o seguro ${seguro.numero_apolice} - ${seguro.fornecedor}?`)) {
            this.spinner.show();
            this.segurosHttpService.delete(seguro).subscribe(
                response => {
                    this.spinner.hide();
                    this.messageService.success('Seguro excluído com sucesso');
                    this.search();
                },
                error => {
                    this.spinner.hide();
                    this.messageService.error('Erro ao excluir seguro, favor contate o administrador do sistema', 'Erro', error)
                }
            );
        }
    }

    private parseSearch(originalSearch: any): any {
        let search = {...originalSearch};
        
        if(originalSearch.data_solicitacao_inicial)
            search.data_solicitacao_inicial = this.datePipe.transform(originalSearch.data_solicitacao_inicial, 'yyyy-MM-dd')
        else
            delete search.data_solicitacao_inicial;

        if(originalSearch.data_solicitacao_final)
            search.data_solicitacao_final = this.datePipe.transform(originalSearch.data_solicitacao_final, 'yyyy-MM-dd')
        else
            delete search.data_solicitacao_final;

        if(!originalSearch.id_categoria_conta)
            delete search.id_categoria_conta;

        return search;
    }
}
