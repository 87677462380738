import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { Local, CategoriaContas, LocalConfiguracao } from 'app/models';

import { TypeaheadMatch } from 'ngx-bootstrap';
import { CategoriaContasPesquisaModalComponent, CategoriaContasHttpService } from 'app/home/plano-de-contas/categoria-contas';
import { PlanoDeContasCadastroModalComponent } from 'app/home/plano-de-contas/cadastro-modal/plano-de-contas-cadastro-modal.component';
import { MessageService, AuthService } from 'app/core';
import { LocalHttpService } from '../../services';
import { isNull } from 'util';
import { TipoDataAntecedenciaEnum } from 'app/models/enums/tipo-data-antecedencia-enum';

declare var jQuery: any;

@Component({
  selector: 'local-configuracao-modal',
  templateUrl: './local-configuracao-modal.component.html',
  styleUrls: ['./local-configuracao-modal.component.css']
})
export class LocalConfiguracaoModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @Input() local: Local;

  @ViewChild('categoriaContasPesquisaModal') categoriaContasPesquisaModal: CategoriaContasPesquisaModalComponent;
  @ViewChild('planoDeContasCadastroModal') planoDeContasCadastroModal: PlanoDeContasCadastroModalComponent;

  public categoriasContas: CategoriaContas[] = [];
  public localConfiguracao: LocalConfiguracao;
  public idCondominio: number;
  public arPeriodo: any[];
  public tabelaColuna: any[];

  public TipoDataAntecedenciaEnum = TipoDataAntecedenciaEnum;

  constructor(
      public categoriaContasHttpService: CategoriaContasHttpService,
      private localHttpService: LocalHttpService,
      public messageService: MessageService,
      private authService: AuthService) {
    this.localConfiguracao = new LocalConfiguracao();
  }

  ngOnInit() {
    this.idCondominio = this.authService.getIdCurrentCondominio();
    if (!this.idModal) {
      this.idModal = 'localConfiguracaoModal';
    }
    this.getAllCategoriasByCondominio();
  }

  public open() {
    this.loadConfiguracao();
    jQuery(`#${this.idModal}`).modal('show');
  }

  public close() {
    this.arPeriodo = [];
    this.localConfiguracao = new LocalConfiguracao();
    this.localConfiguracao.id_local = this.local.id;
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Carrega configuração do local
   * @author Marcos Frare
   * @since 09/03/2020
   */
  private loadConfiguracao(): void {
    this.localHttpService.getConfigByLocal(this.local.id)
      .subscribe(
        response => {
          this.localConfiguracao.possui_cobranca = true;
          if (isNull(response)) {
            this.localConfiguracao = new LocalConfiguracao();
            this.localConfiguracao.id_local = this.local.id;
            this.localConfiguracao.tipo_periodo_reserva = 1;
            this.localConfiguracao.quantidade_periodo = 1;
          } else {
            if (response.id) {
              this.localConfiguracao = response;
            } else {
              this.localConfiguracao = new LocalConfiguracao();
              this.localConfiguracao.id_local = this.local.id;
              this.localConfiguracao.tipo_periodo_reserva = 1;
              this.localConfiguracao.quantidade_periodo = 1;
            }
          }
          this.loadPeriodo();
        },
        error => this.messageService.error('Erro ao carregar configuração do local. Tente novamente', 'Erro', error)
      );
  }

  /**
   * Carrega os períodos configurados
   * @author Marcos Frare
   * @since 11/03/2020
   */
  private loadPeriodo(): void {
    this.localHttpService.getDisponibilidade(this.local.id).subscribe(
      response => {
        this.arPeriodo = response;
        this.changeQuantidadePeriodo();
      },
      error => this.messageService.error('Erro ao carregar configuração do local. Tente novamente', 'Erro', error)
    );
  }

  /**
   * Ação do combo de tipo de reserva
   * @author Marcos Frare
   * @since 10/03/2020
   */
  public changeTipoReserva(): void {
    if (this.localConfiguracao.tipo_periodo_reserva == 2) {
      this.localConfiguracao.quantidade_periodo = 1;
      this.changeQuantidadePeriodo();
    }
  }

  /**
   * Ação do botão que altera a quantidade de periodos
   * @author Marcos Frare
   * @since 10/03/2020
   */
  public changeQuantidadePeriodo(): void {
    this.tabelaColuna = [];
    for (let i=1; i<=this.localConfiguracao.quantidade_periodo; i++) {
      const coluna = {
        index: i,
        nome: `Período ${i}`
      }
      this.tabelaColuna.push(coluna);
      this.changePeriodo();
    }
  }

  /**
   * Ajusta a quantidade de colunas de periodos conforme quantidade de periodos selecionado
   * @author Marcos Frare
   * @since 10/03/2020
   */
  private changePeriodo(): void {
    this.arPeriodo.forEach(oPeriodo => {
      if (oPeriodo.periodo.length < this.localConfiguracao.quantidade_periodo) {
        // Carrega um objeto padrão
        const newPeriodo = {
          id: null,
          id_local: this.localConfiguracao.id_local,
          dia_da_semana: oPeriodo.dia_da_semana,
          periodo: oPeriodo.periodo,
          hora_inicial: null,
          hora_final: null
        }
        // Insere quantos objetos precisar para o array
        for (let i=oPeriodo.periodo.length; i<=this.localConfiguracao.quantidade_periodo; i++) { 
          newPeriodo.periodo = i;
          oPeriodo.periodo.push(newPeriodo);
        }
      } else {
        // Enquanto o array de periodo for maior que a quantidade, faz a exclusão do último elemento
        while (oPeriodo.periodo.length > this.localConfiguracao.quantidade_periodo) {
          oPeriodo.periodo.pop();
        }
      }
    });
  }

  /**
   * Salva dados da configuração e períodos
   * @author Marcos Frare
   * @since 11/03/2020
   */
  public salvar(): void {
    const btnView = jQuery('#btnSalvar');
    btnView.button('loading');
    this.localConfiguracao.id_local = this.local.id;
    const dados = {
      localConfiguracao: this.localConfiguracao,
      periodo: this.arPeriodo
    }
    this.localHttpService.saveConfig(dados)
      .subscribe(
        response => {
          this.localConfiguracao = response.localConfiguracao;
          this.loadPeriodo();
          this.messageService.success('', 'Configuração salvo com sucesso!');
          btnView.button('reset');
        },
        error => {
          this.messageService.error('Erro ao gravar configurações do local!! Tente novamente.', 'Erro', error);
          btnView.button('reset');
        }
      );
  }

  // Categoria de Conta
  private getAllCategoriasByCondominio(): void {
    this.categoriasContas = [];
    this.categoriaContasHttpService.getAllByCondominio(this.idCondominio, 1)
      .then(entities => this.categoriasContas = entities)
      .catch(() => this.messageService.info(`Configuração do condomínio não encontrada. Configure o condomínio para prosseguir.`, 'Informação'));
  }

  public onSelectCategoriaContas(tm: TypeaheadMatch): void {
    const categoriaContas: CategoriaContas = tm.item as CategoriaContas;
    if (categoriaContas) {
      this.localConfiguracao.id_categoria_conta = categoriaContas.id;
      this.localConfiguracao.categoriaContas = categoriaContas;
    } else {
      this.localConfiguracao.id_categoria_conta = null;
      this.localConfiguracao.categoriaContas = null;
    }
  }

  public onCleanCategoriaContas(input: any): void {
    this.localConfiguracao.id_categoria_conta = null;
    this.localConfiguracao.categoriaContas = null;
    input.value = '';
    input.focus();
  }

  public openCategoriaContasPequisaModal(): void {
    this.categoriaContasPesquisaModal.open();
  }

  public onSelectCategoriaContasPesquisaModal(categoriaContas: CategoriaContas): void {
    if (categoriaContas) {
      this.localConfiguracao.id_categoria_conta = categoriaContas.id;
      this.localConfiguracao.categoriaContas = categoriaContas;
    }
    jQuery('#categoriaContas').focus();
  }

  public openPlanoDeContasCadastroModal(): void {
    this.planoDeContasCadastroModal.open();
  }

  public onAfterSaveContaCategoriaConta(categoriaConta: CategoriaContas): void {
    this.localConfiguracao.id_categoria_conta = categoriaConta.id;
    this.localConfiguracao.categoriaContas = categoriaConta;
  }

  /**
   * Atualizar o valor dos períodos conforme valor da reserva
   * @author Marcos Frare
   * @since 27/05/2021
   */
  public atualizarValorPeriodo(): void {
    this.arPeriodo.map(i => {
      i.periodo.map(j => {
        j.valor = this.localConfiguracao.valor_reserva;
        j.taxa_limpeza = this.localConfiguracao.valor_taxa_limpeza;
      })
    });
  }

  /**
   * Evento do switch de cobranca
   * @author Marcos Frare
   * @since 28/05/2021
   */
  public changeCobranca(): void {
    this.localConfiguracao.valor_reserva = null;
    this.atualizarValorPeriodo();
  }

}