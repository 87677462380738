import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared';
import { CondominiosModule } from '../home/condominios';

import { LoginComponent, RetrievePasswordComponent } from './';
import { LoginSelecionaCondominioComponent } from './seleciona-condominio';

@NgModule({
	imports: [
		SharedModule,
		RouterModule,
		CondominiosModule
	],
	declarations: [
		LoginComponent,
		RetrievePasswordComponent,
		LoginSelecionaCondominioComponent
	],
})

export class LoginModule { }