import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared';
import { CondominiosComponent } from './condominios.component';
import { CondominiosHttpService } from './services';

import { EnderecoModule } from '../../componentes-utils/endereco';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { UiSwitchModule } from 'ngx-ui-switch';
import {CondominiosCadastroComponent} from './condominios-cadastro/condominios-cadastro.component';

@NgModule({
    imports: [
        SharedModule,
        EnderecoModule,
        TypeaheadModule,
        UiSwitchModule
    ],
    declarations: [
        CondominiosComponent,
        CondominiosCadastroComponent
    ],
    providers: [
        CondominiosHttpService
    ],
    exports: [
        CondominiosComponent
    ]
})

export class CondominiosModule { }
