import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { CondominiosConfiguracaoHttpService } from './services';
import { PlanoDeContasModule } from '../plano-de-contas';
import { ContasBancariasModule } from '../contas-bancarias';
import { CondominiosConfiguracaoComponent } from './condominios-configuracao.component';
import { VinculoTabelaConsumoModalComponent } from './vinculo-tabela-consumo-modal';
import { UnidadeIsencaoModalComponent } from './unidade-isencao-modal';
import { UnidadeAntecipacaoModalComponent } from './unidade-antecipacao-modal';
import { UnidadesUsuariosHttpService } from '../blocos';
import { UiSwitchModule } from 'ngx-ui-switch';

@NgModule({
	imports: [
		SharedModule,
		TypeaheadModule,
		UiSwitchModule,
		ContasBancariasModule,
		PlanoDeContasModule
	],
	declarations: [
		CondominiosConfiguracaoComponent,
		VinculoTabelaConsumoModalComponent,
		UnidadeIsencaoModalComponent,
		UnidadeAntecipacaoModalComponent
	],
	providers: [
		CondominiosConfiguracaoHttpService,
		UnidadesUsuariosHttpService
	]
})
export class CondominiosConfiguracaoModule { }