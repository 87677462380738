import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { tipos } from 'app/models';

declare var jQuery: any;

@Component({
  selector: 'enviar-email-titulo-modal',
  templateUrl: './enviar-email-titulo-modal.component.html',
  styleUrls: ['./enviar-email-titulo-modal.component.css']
})
export class EnviarEmailTituloModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @Output() emitTipo: EventEmitter<number> = new EventEmitter();

  tiposMoradores = tipos;

  public param: any = {
    tipo: 0,
    email_adicional_unidade: false,
    email_adicional_condomino: false,
    tipo_cobranca: 'TAXACONDOMINIO',
    tipo_cobranca_outro: ""
  }
  
  constructor() { }

  ngOnInit() {
    if (!this.idModal) {
      this.idModal = 'enviarEmailTituloModal';
    }
  }

  public open() {
    jQuery(`#${this.idModal}`).modal('show');
  }
  public close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Enviar títulos por e-mail
   * @auhtor Marcos Frare
   * @since 27/08/2020
   */
   public sendMail(): void {
    this.emitTipo.emit(this.param);
    this.close();
  }

  public changeTipoCobranca() {
    if (this.param.tipo_cobranca != 'OUTRO') {
      this.param.tipo_cobranca_outro = "";
    }
  }

}