import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import { AuthService, MessageService } from 'app/core';
import { UnidadesHttpService } from 'app/home/blocos';
import { CategoriaContasHttpService, CategoriaContasPesquisaModalComponent, PlanoDeContasCadastroModalComponent } from 'app/home/plano-de-contas';
import { CategoriaContas, TemplateLancamentoEspecifico, tiposDivisaoRateio, tiposUnidade, Unidade } from 'app/models';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { TemplateLancamentoEspecificoHttpService } from '../service';

declare var jQuery: any;

@Component({
  selector: 'template-lancamento-especifico-cadastro-modal',
  templateUrl: './template-lancamento-especifico-cadastro-modal.component.html',
  styleUrls: ['./template-lancamento-especifico-cadastro-modal.component.css']
})
export class TemplateLancamentoEspecificoCadastroModalComponent implements OnInit {

  @Input() idModal: string;
  @Input() id: number;
  @Output() onSave: EventEmitter<any> = new EventEmitter(); 

  @ViewChild('form') form: NgForm;

  @ViewChild('categoriaContasPesquisaModal') categoriaContasPesquisaModal: CategoriaContasPesquisaModalComponent;
  @ViewChild('planoDeContasCadastroModal') planoDeContasCadastroModal: PlanoDeContasCadastroModalComponent;

  public id_condominio: number;
  public categoriasContasReceita: CategoriaContas[] = [];
  public tiposDivisaoRateio = tiposDivisaoRateio;
  public tiposUnidade = tiposUnidade;
  public templateLancamentoEspecifico: TemplateLancamentoEspecifico;
  private unidades: Unidade[];

  public multiselectSettings: IMultiSelectSettings = {
    enableSearch: true,
    containerClasses: 'container-multiselect',
    showUncheckAll: true,
    showCheckAll: true,
    dynamicTitleMaxItems: 3,
    checkedStyle: 'checkboxes',
    buttonClasses: 'btn btn-default',
    itemClasses: 'dropdown-menu'
  };
  public multiselectOptionsUnidades: IMultiSelectOption[];
  public multiselectTextsUnidades: IMultiSelectTexts = {
    checkAll: 'Selecionar todas',
    uncheckAll: 'Limpar',
    checked: 'selecionada',
    checkedPlural: 'selecionadas',
    searchPlaceholder: 'Pesquisar...',
    defaultTitle: 'Selecionar',
    allSelected: 'Todas selecionadas',
    searchEmptyResult: 'Nenhum registro encontrado',
    searchNoRenderText: 'Digite para pesquisar',
  };

  constructor(private messageService: MessageService,
    private authService: AuthService,
    private categoriaContasHttpService: CategoriaContasHttpService,
    private templateLancamentoEspecificoHttpService: TemplateLancamentoEspecificoHttpService,
    private unidadesHttpService: UnidadesHttpService) { }


  ngOnInit() {
    this.id_condominio = this.authService.getIdCurrentCondominio();
    this.templateLancamentoEspecifico = new TemplateLancamentoEspecifico();
    if (this.idModal == '') {
      this.idModal = 'templateLancamentoEspecificoCadastro';
    }
    this.getAllUnidades();
    this.getAllCategoriasByCondominio(this.id_condominio);
  }

  /**
   * Busca todas as unidades do condomínio
   * @author Marcos Frare
   * @since 09/03/2021
   */
  private getAllUnidades(): void {
    this.unidades = [];
    this.unidadesHttpService.getAllByCondominioBloco(this.id_condominio, null).subscribe(
      response => {
        this.unidades = response;
        this.loadUnidadeByTipo();
      },
      error => {
        this.messageService.error('Erro ao buscar as unidades do condomínio!', 'Erro', error);
      }
    );
  }

  /**
   * Carregar as unidades conforme o tipo selcionado
   * @author Marcos Frare
   * @since 09/03/2021
   */
  public loadUnidadeByTipo(): void {
    if (this.templateLancamentoEspecifico.cobrar_tipo_unidade == 100) {
      this.multiselectOptionsUnidades = <IMultiSelectOption[]>this.unidades.map(entity => {
        return { id: entity.id, name: `${entity.nome_bloco} - ${entity.nome_unidade}` };
      });
    } else {
      this.multiselectOptionsUnidades = <IMultiSelectOption[]>this.unidades.filter(uni => uni.tipo == this.templateLancamentoEspecifico.cobrar_tipo_unidade).map(entity => {
        return { id: entity.id, name: `${entity.nome_bloco} - ${entity.nome_unidade}` };
      });
    }
  }

  /**
   * Busca as categorias de contas do condomínio
   * @param id_condominio 
   * @author Marcos Frare
   * @since 10/07/2020
   */
   private getAllCategoriasByCondominio(id_condominio: number): void {
    this.categoriasContasReceita = [];
    if (id_condominio) {
      this.categoriaContasHttpService.getAllByCondominio(id_condominio, 1)
        .then(entities => {
          this.categoriasContasReceita = entities;
        })
        .catch(() => this.messageService.info(`Configuração do condomínio não encontrada. Configure o condomínio para prosseguir.`, 'Informação'));
    }
  }

  /**
   * Abre modal com dados
   * @author Marcos Frare
   * @since 10/07/2020
   */
   open() {
    this.templateLancamentoEspecifico = new TemplateLancamentoEspecifico();
    jQuery(`#${this.idModal}`).modal('show');
    
    this.getAllCategoriasByCondominio(this.id_condominio);
    if (this.id) {
      this.templateLancamentoEspecificoHttpService.buscaTemplateId(this.id).subscribe(
        response => {
          let ids_unidades = response.ids_unidades;
          this.templateLancamentoEspecifico = response;
          this.setCategoriaContas();
          this.loadUnidadeByTipo();
          setTimeout(() => {
            this.templateLancamentoEspecifico.ids_unidades = ids_unidades;
          }, 300);
        },
        error => this.messageService.error('Erro ao carregar configurações de lançamento!', 'Erro', error)
      );
    }
  }

  close() {
    this.templateLancamentoEspecifico = new TemplateLancamentoEspecifico();
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Carregar a categoria de conta
   * @author Marcos Frare
   * @since 09/03/2021
   */
  private setCategoriaContas(): void {
    if (!this.templateLancamentoEspecifico.id_categoria_conta) {
      return;
    }
    this.categoriaContasHttpService.get(this.templateLancamentoEspecifico.id_categoria_conta).subscribe(
      templateLancamentoEspecifico => {
        this.templateLancamentoEspecifico.categoriaConta = templateLancamentoEspecifico;
      }
    );
  }

  public onSelectCategoriaContas(tm: TypeaheadMatch): void {
    const categoriaContas: CategoriaContas = tm.item as CategoriaContas;
    if (categoriaContas) {
        this.templateLancamentoEspecifico.id_categoria_conta = categoriaContas.id;
        this.templateLancamentoEspecifico.categoriaConta = categoriaContas;
        this.templateLancamentoEspecifico.tipo_divisao_rateio = categoriaContas.tipo_divisao_rateio || this.templateLancamentoEspecifico.tipo_divisao_rateio;
    } else {
      this.templateLancamentoEspecifico.id_categoria_conta = null;
      this.templateLancamentoEspecifico.categoriaConta = null;
    }
  }

  public onCleanCategoriaContas(input: any): void {
    this.templateLancamentoEspecifico.id_categoria_conta = null;
    this.templateLancamentoEspecifico.categoriaConta = null;

    input.value = '';
    input.focus();
  }

  public openCategoriaContasPequisaModal(): void {
    this.categoriaContasPesquisaModal.open();
  }

  public onSelectCategoriaContasPesquisaModal(categoriaContas: CategoriaContas): void {
    if (categoriaContas) {
      this.templateLancamentoEspecifico.id_categoria_conta = categoriaContas.id;
      this.templateLancamentoEspecifico.categoriaConta = categoriaContas;
      this.templateLancamentoEspecifico.tipo_divisao_rateio = categoriaContas.tipo_divisao_rateio || this.templateLancamentoEspecifico.tipo_divisao_rateio;
    }
    jQuery('#categoriaContas').focus();
  }

  public openPlanoDeContasCadastroModal(): void {
    this.planoDeContasCadastroModal.open();
  }

  /**
   * Persistir dados de configuração
   * @author Marcos Frare
   * @since 10/07/2020
   */
   public salvar(): void {
    let btnSave = jQuery('#btnSalvarConfiguracao');
    btnSave.button('loading');
    this.templateLancamentoEspecifico.id_condominio = this.id_condominio;
    this.templateLancamentoEspecificoHttpService.saveTemplate(this.templateLancamentoEspecifico).subscribe(
      () => {
        btnSave.button('reset');
        this.messageService.info('Configurações gravadas com sucesso!');
        this.onSave.emit();
        this.close();
      },
      error => {
        btnSave.button('reset');
        this.messageService.error('Problemas ao gravar configurações. Tente novamente.', 'Erro', error);
      }
    );
  }
}