import { BaseEntity } from '../base-entity';
import { CategoriaContas } from './categoria-contas';
import { ParcelaDespesaRateio } from './parcela-despesa-rateio';

export class LancamentoCondominioItem extends BaseEntity {
	id_lancamento_condominio: number;
	complemento: string;
	id_categoria_conta: number;
	id_categoria_conta_nao_rateado: number;
	qtd_parcela: number;
	tipo_divisao_rateio: number;
	cobrar_tipo_unidade: number;
	cobrar_fundo_reserva: boolean;
	para_proprietario: boolean;
	valor: any;
	rateado: boolean;

	categoriaConta: CategoriaContas;
	categoriaContaNaoRateado: CategoriaContas;
	
	ids_unidades: number[];

	multiselectOptionsUnidades: any;

	nome_categoria_conta: string;
	parcelas_despesa_rateio: ParcelaDespesaRateio[];

	constructor() {
		super();
		this.ids_unidades = [];
		this.parcelas_despesa_rateio = [new ParcelaDespesaRateio()];
		this.para_proprietario = false;
		this.cobrar_fundo_reserva = false;
		this.tipo_divisao_rateio = 1;
		this.cobrar_tipo_unidade = 100;
		this.multiselectOptionsUnidades = [];
		this.qtd_parcela = 1;
		this.rateado = false;
		this.valor = 0;
		this.complemento = '';
	}

}