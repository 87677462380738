import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';

import { Crud, CrudService, ConfigService, CustomHttp } from '../../../../core';
import { TarifasConsumo, CategoriaTarifaConsumo } from '../../../../models';

@Injectable()
export class TiposConsumoTarifasHttpService implements Crud<TarifasConsumo> {
    resource = 'tarifasconsumo';
    private baseUrl: string;

    constructor(private customHttp: CustomHttp,
                private crudService: CrudService,
                private configService: ConfigService) {
        this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
    }

    get(id: number): Observable<TarifasConsumo> {
        return this.crudService.get<TarifasConsumo>(this.resource, id);
    }

    getAll(): Observable<TarifasConsumo[]> {
        return this.crudService.getAll<TarifasConsumo>(this.resource);
    }

    post(entity: TarifasConsumo): Observable<TarifasConsumo> {
        return this.crudService.post<TarifasConsumo>(this.resource, entity);
    }

    put(entity: TarifasConsumo): Observable<TarifasConsumo> {
        return this.crudService.put<TarifasConsumo>(this.resource, entity);
    }

    delete(entity: TarifasConsumo): Observable<string> {
        return this.crudService.delete<TarifasConsumo>(this.resource, entity);
    }

    /**
     * Carrega todas as tarifas do tipo de consumo informado
     */
    getAllByTipoConsumo(idTipoConsumo: number): Observable<CategoriaTarifaConsumo[]> {
        return this.customHttp.get(`${this.baseUrl}/consultar/tipoconsumo/${idTipoConsumo}`)
            .pipe(
                map(response => response.json()),
                catchError(error => _throw(error))
            );
    }

    /**
     * Busca as categorias do tipo de consumo
     * @param idTipoConsumo ID do tipo de consumo
     * @author Marcos Frare
     * @since 20/02/2020
     */
    buscaCategorias(idTipoConsumo: number): Observable<CategoriaTarifaConsumo[]> {
        return this.customHttp.get(`${this.baseUrl}/categoria/consultar/${idTipoConsumo}`)
            .pipe(
                map(response => response.json()),
                catchError(error => _throw(error))
            );
    }

    /**
     * Persiste a categoria
     * @param categoria 
     * @author Marcos Frare
     * @since 20/02/2020
     */
    saveCategoria(categoria: CategoriaTarifaConsumo): Observable<CategoriaTarifaConsumo> {
        const save = categoria.id ?
            this.customHttp.put(`${this.baseUrl}/categoria/editar/${categoria.id}`, categoria) :
            this.customHttp.post(`${this.baseUrl}/categoria/cadastrar`, categoria);

        return save
            .pipe(
                map(response => response.json()),
                catchError(error => _throw(error))
            );
    }

    /**
     * Exclusão da categoria e seu plano tarifário
     * @param categoria 
     * @author Marcos Frare
     * @since 20/02/2020
     */
    exlcuirCategoria(categoria: CategoriaTarifaConsumo): Observable<any> {
        return this.customHttp.delete(`${this.baseUrl}/categoria/excluir/${categoria.id}`)
            .pipe(
                map(response => response.json()),
                catchError(error => _throw(error))
            );
    }
    
}