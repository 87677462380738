import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseVisualizacaoComponent } from '../../componentes-base';
import { AuthService, MessageService } from '../../core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Popover } from 'ngx-popover';
import { CategoriaContas, Fornecedor, LancamentosCondominio, sindicoAprovaPagamento, SITUACAO_RATEIO, tipoPagamento, tiposDivisaoRateio } from 'app/models';
import { CategoriaContasHttpService, CategoriaContasPesquisaCompletaModalComponent, CategoriaContasPesquisaModalComponent } from '../plano-de-contas';
import { LancamentosCondominioHttpService } from './services';
import { GeracaoDespesaFixaModalComponent } from './geracao-despesa-fixa-modal';
import { LancamentoCondominioAnexoModalComponent } from './lancamento-condominio-anexo-modal';
import { FornecedoresHttpService } from '../fornecedores';

declare var jQuery: any;

@Component({
	selector: 'lancamentos-condominio',
	templateUrl: 'lancamentos-condominio.template.html',
	styleUrls: ['./lancamentos-condominio.component.css'],
	providers: [PermissionService]
})
export class LancamentosCondominioComponent extends BaseVisualizacaoComponent<LancamentosCondominio> {

	@ViewChild('categoriaContasPesquisaModal') categoriaContasPesquisaModal: CategoriaContasPesquisaModalComponent;
	@ViewChild('categoriaContasPesquisaCompletaModal') categoriaContasPesquisaCompletaModal: CategoriaContasPesquisaCompletaModalComponent;
	@ViewChild('geracaoDespesaFixaModal') geracaoDespesaFixaModal: GeracaoDespesaFixaModalComponent;
	@ViewChild('lancamentoCondominioAnexoModal') lancamentoCondominioAnexoModal: LancamentoCondominioAnexoModalComponent;

	public filtro: any = {};
	public categoriasContas: CategoriaContas[] = [];
	public categoriasContasNaoRateada: CategoriaContas[] = [];
	public fornecedores: Fornecedor[] = [];
	public tiposPagamentos = tipoPagamento;
	public tiposDivisaoRateio = tiposDivisaoRateio;
	public aprovacoesSindico = sindicoAprovaPagamento;
	public competencia = Date;
	public idCondominio: number;
	public idCliente: number;
	public allSelected = true;
	public total: number = 0;
	public popover: Popover;

	constructor(activatedRoute: ActivatedRoute,
			public router: Router,
			public messageService: MessageService,
			private authService: AuthService,
			private categoriaContasHttpService: CategoriaContasHttpService,
			private fornecedoresHttpService: FornecedoresHttpService,
			private lancamentosCondominioHttpService: LancamentosCondominioHttpService,
			public permissionService: PermissionService,
			public spinner: NgxSpinnerService) {
		super('#tableLancamentosCondominio', lancamentosCondominioHttpService, activatedRoute, router, messageService, permissionService, spinner);
		this.useDatatable = false;
	}

	ngOnInit() {
		this.idCondominio = this.authService.getIdCurrentCondominio();
		this.idCliente = this.authService.getIdCurrentCliente();
		this.getAllFornecedores();
		this.getAllCategoriasByCondominio(this.idCondominio);
		this.setInitialFilter();
		this.search();
		this.getPermissions();
	}

	private getAllFornecedores(): void {
		this.fornecedoresHttpService.getAllByCliente(this.idCliente).subscribe(
			entities => this.fornecedores = entities,
			error => this.messageService.error('Erro ao carregar os fornecedores', 'Erro', error)
		);
	}

	public getDivisaoRateioLabel(item: any) {
		return tiposDivisaoRateio.find(tipo => tipo.id === item.tipo_divisao_rateio).nome;
	}

	public search(): void {
		this.spinner.show();
		this.entities = [];
		this.destroyTable();
		this.lancamentosCondominioHttpService.search(this.filtro).subscribe(
			response => {
				this.spinner.hide();
				this.entities = response.lancamentos;
				this.total = response.total;
				this.showTable();
			},
			error => {
				this.spinner.hide();
				this.messageService.error('Erro ao carregar os lançamentos do condomínio. Tente novamente', 'Erro', error);
			}
		);
	}

	public setInitialFilter(): void {
		const today = new Date();
		this.filtro.id_condominio = this.idCondominio;
		this.filtro.data_vencimento_final = new Date(today.getFullYear(), today.getMonth() + 1, 0);
		this.filtro.data_vencimento_inicial = new Date(today.getFullYear(), today.getMonth(), 1);
		this.filtro.tipo_divisao_rateio = '';
		this.filtro.forma_pagamento = '';
		this.filtro.sindico_aprova = '';
	}

	private getAllCategoriasByCondominio(idCondominio: number): void {
		this.categoriasContas = [];
		if (idCondominio) {
			this.categoriaContasHttpService.getAllByCondominio(idCondominio, 2)
				.then(entities => this.categoriasContas = entities)
				.catch(() => this.messageService.info(`Configuração do condomínio não encontrada. Configure o condomínio para prosseguir.`, 'Informação'));
			this.categoriaContasHttpService.getAllByCondominio(idCondominio)
				.then(entities => this.categoriasContasNaoRateada = entities);
		}
	}

	public onSelectCategoriaContas(tm: TypeaheadMatch): void {
		const categoriaContas: CategoriaContas = tm.item as CategoriaContas;
		if (categoriaContas) {
			this.filtro.id_categoria_conta = categoriaContas.id;
			this.filtro.categoriaContas = categoriaContas;
		} else {
			this.filtro.id_categoria_conta = null;
			this.filtro.categoriaContas = null;
		}
	}

	public onCleanCategoriaContas(input: any): void {
		this.filtro.id_categoria_conta = null;
		this.filtro.categoriaContas = null;
		input.value = '';
		input.focus();
	}

	public openCategoriaContasPequisaModal(): void {
		this.categoriaContasPesquisaModal.open();
	}

	public onSelectCategoriaContasPesquisaModal(categoriaContas: CategoriaContas): void {
		if (categoriaContas) {
			this.filtro.id_categoria_conta = categoriaContas.id;
			this.filtro.categoriaContas = categoriaContas;
		}
		jQuery('#categoriaContas').focus();
	}

	public delete(lancamento: LancamentosCondominio): void {
		if (confirm(`Tem certeza que deseja excluir o lancamento no valor de ${lancamento.valor_total}?`)) {
			this.spinner.show();
			this.lancamentosCondominioHttpService.delete(lancamento).subscribe(
				() => {
					this.spinner.hide();
					this.messageService.success('Lançamento excluído com sucesso');
					this.search();
				},
				error => {
					this.spinner.hide();
					if (error && error.status && error.status === 403) {
						this.messageService.warning('Não é possível excluir o lançamento selecionado pois o mesmo possui algum pagamento ou faz parte de um rateio', 'Atenção');
					} else {
						this.messageService.error('Erro ao excluir lançamento');
					}
				}
			);
		}
	}

	public labelTotal(): string {
		switch (this.filtro.situacao_rateio) {
			case SITUACAO_RATEIO.PROCESSADO:
				return 'Total dos lançamentos processados';
			case SITUACAO_RATEIO.NAO_PROCESSADO:
				return 'Total dos lançamentos não processados';
			case SITUACAO_RATEIO.TODOS:
				return 'Total de todos lançamentos';
			case SITUACAO_RATEIO.CATEGORIA_NAO_RATEADA:
				return 'Total dos lançamentos de categoria de conta não rateada';
			default:
				return 'Total dos lançamentos';
		}
	}

	public toggleAllSelected(): void {
		this.entities.forEach(entity => {
			entity.selected = this.allSelected;
		});
	}

	public checkSelected(): boolean {
		let isSelected = false;
		this.entities.forEach(entity => {
			if (entity.selected) { isSelected = true; }
		});
		return isSelected;
	}

	public checkAllSelected(): boolean {
		let isAllSelected = true;
		this.entities.forEach(entity => {
			if (!entity.selected) { isAllSelected = false; }
		});
		return isAllSelected;
	}

	/**
	 * Abrir tela para manutenção de templates de despesas fixas
	 * @author Marcos Frare
	 * @since 07/08/2020
	 */
	public despesaFixa(): void {
		this.router.navigate(['./template'], { relativeTo: this.activatedRoute });
	}

	/**
	 * Abrir tela para lançamentos fixos
	 * @author Marcos Frare
	 * @since 07/08/2020
	 */
	public gerarLancamentosFixos(): void {
		this.geracaoDespesaFixaModal.open();
	}

	/**
	 * Abrir modal para anexar documento em um lançamento
	 * @param entity - Lancçamento
	 * @author Marcos Frare
	 * @since 02/12/2020
	 */
	public anexar(entity: LancamentosCondominio): void {
		this.lancamentoCondominioAnexoModal.idLancamento = entity.id;
		this.lancamentoCondominioAnexoModal.open();
	}

	public expandLancamento(entity: LancamentosCondominio): void {
		entity.open = !entity.open;
	}

	public isNaoRateado(item: any) {
		return item.tipo_divisao_rateio === 4;
	}

	public isPrimeiroItemNaoRateado(items: any) {
		if (items === null || items[0] === undefined) return false;
		return items[0].tipo_divisao_rateio === 4;
	}

	public getLabelCategoriaDeContaNaoRateada(item: any) {
		let categoriaDeContaNaoRateada = this.categoriasContasNaoRateada.find(categoria => categoria.id === item.id_categoria_conta_nao_rateado)
		return categoriaDeContaNaoRateada ? categoriaDeContaNaoRateada.nome : "";
	}

}