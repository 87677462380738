import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';

import { ConfigService, Crud, CrudService, CustomHttp } from '../../../../../core';

import { Garagem } from '../../../../../models';

@Injectable()
export class UnidadesGaragensHttpService implements Crud<Garagem> {
    resource = 'garagem';
    private baseUrl: string;

    constructor(private customHttp: CustomHttp,
                private crudService: CrudService,
                private configService: ConfigService) {
        this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
    }

    get(id: number): Observable<Garagem> {
        return this.crudService.get<Garagem>(this.resource, id);
    }

    getAll(): Observable<Garagem[]> {
        return this.crudService.getAll<Garagem>(this.resource);
    }

    /**
     * Carrega todo as garagens da unidade informada
     */
    getAllByUnidade(idUnidade: number): Observable<Garagem[]> {
        return this.customHttp.get(`${this.baseUrl}/consultar/unidade/${idUnidade}`)
                    .pipe(
                        map(response => response.json()),
                        catchError(error => _throw(error))
                    );
    }

    post(entity: Garagem): Observable<Garagem> {
        return this.crudService.post<Garagem>(this.resource, entity);
    }

    put(entity: Garagem): Observable<Garagem> {
        return this.crudService.put<Garagem>(this.resource, entity);
    }

    delete(entity: Garagem): Observable<string> {
        return this.crudService.delete<Garagem>(this.resource, entity);
    }
}
