import { Component, EventEmitter, ElementRef, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MessageService } from 'app/core';

@Component({
	selector: 'attachment',
	templateUrl: './attachment.component.html',
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AttachmentComponent), multi: true }
	]
})
export class AttachmentComponent implements ControlValueAccessor {

	public file: any;
	@Input() ngModel: any;
	@Input() required: any;
	@Input() disabled: any;
	@Input() readonly: any;
	@Input() ngControl: NgControl;
	@Input() autofocus = false;
	@Input() image = false;
	@Input() bottomImage = false;
	@Input() accept: string = '';
	@Input() view: boolean = false;
	
	@Output() ngModelChange = new EventEmitter<any>();
	@Output() change = new EventEmitter<any>();
	@Output() fileView = new EventEmitter<any>();

	@ViewChild('fileInput') fileInput: ElementRef;

	private messageService: MessageService = new MessageService();
	public propagateChange: any = () => { };

	private extensionView = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];

	public writeValue(value): void {
		if (value) {
			this.file = value;
		} else {
			this.fileInput.nativeElement.value = '';
		}
	}

	public registerOnChange(fn): void {
		this.propagateChange = fn;
	}

	public registerOnTouched(): void { }

	public onFileClick(): void {
		this.fileInput.nativeElement.click();
	}

	private validaTipo(file): boolean {
		const arTypes = this.accept.split(",");
		if (arTypes.length > 0 && arTypes[0] == "") {
			return true;
		} else {
			if (arTypes.length > 0) {
				for (let type of arTypes) {
					if (file.type == type) {
						return true;
					}
				}
			}
			return false;
		}
	}

	public onFileChange(event: any): void {
		const file = event.target.files[0];
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = (e: ProgressEvent) => {
			if (this.validaTipo(file)) {
				this.file = {
					id: this.file ? this.file.id || null : null,
					nome: file.name,
					tipo: file.type,
					arquivo: reader.result
				};
				this.propagateChange(this.file);
				this.change.emit(this.file);
			} else {
				this.messageService.warning('O tipo de arquivo não pode ser anexado!', 'Aviso!')
			}
		};
	}

	public onFileClear(): void {
		this.file = undefined;
		this.fileInput.nativeElement.value = '';
		this.propagateChange(this.file);
		this.change.emit(this.file);
	}

	public onFileDownload(): void {
		if (!this.file) { return null; };
		const a = window.document.createElement('a');
		a.href = this.file.arquivo;
		a.download = this.file.nome;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}

	/**
	 * Ação para mostrar arquivo em tela
	 * @param anexo 
	 * @author Marcos Frare
	 * @since 01/03/2022
	 */
	public onFileView(): void {
		if (this.extensionView.includes(this.file.tipo)) {
			this.fileView.emit({id: this.file.id, fileType: this.file.tipo});
		} else {
			this.messageService.warning('Formato não suportado para visualização!', 'Atenção!!');
		}
	}
	
}