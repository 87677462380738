export class PagamentosEnvio {
	public id_usuario: number;
	public id_conta_bancaria: number;
	public juros: any;
	public multa: any;
	public data_pagamento: Date;
	public tipo: number;
	public sindico_aprova: number;
	public data_aprovacao_sindico: Date;
	public id_tipo_contato: number;
	public id_pagamento: number;
	public valor: number;

	constructor() { }

}