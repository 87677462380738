import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Components
import { BlocosPesquisaModalComponent } from './../home/blocos/blocos-pesquisa-modal/blocos-pesquisa-modal.component';
import { UnidadesPesquisaModalComponent } from './../home/blocos/unidades/unidades-pesquisa-modal/unidades-pesquisa-modal.component';
import { RelatorioModalComponent } from './../home/relatorios/relatorio-modal';
import { AttachmentComponent, FormControlErroComponent, DatepickerComponent, VisualizaAnexoComponent, ContextMenuComponent } from './components';
import { CondominiosPesquisaModalComponent } from 'app/home/condominios/condominios-pesquisa-modal/condominios-pesquisa-modal.component';
import { EnvioEmailBoletoModalComponent } from 'app/home/boletos/envio-email-boleto-modal';

// Directives
import { AutoFocusDirective } from './directives/auto-focus';
import { MaskDirective } from './directives/mask';

// Pipes
import { TipoPagamentoPipe } from './pipes/tipo-pagamento';
import { SindicoAprovaPipe } from './pipes/sindico-aprova';
import { TiposConselhosCondominioPipe } from './pipes/tipos-conselhos-condominio';
import { UnidadesMedidaPipe } from './pipes/unidades-medida';
import { CurrencyFormatPipe, DecimalFormatPipe, IntegerFormatPipe, FloatFormatPipe } from './pipes/number-format';
import { CnpjFormatPipe } from './pipes/cnpj-format';
import { CpfFormatPipe } from './pipes/cpf-format';
import { NgxCurrencyModule } from 'ngx-currency';
import { TipoComunicacaoPipe, TiposDestinatariosPipe, TiposRemetentesPipe } from './pipes';

/**
 * Module compartilhado, ou seja, possui components, directives, pipes e etc,
 * que podem ser utilizados na maioria dos modules da aplicação.
 */
@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		NgxCurrencyModule
	],
	declarations: [
		// Components
		FormControlErroComponent,
		DatepickerComponent,
		AttachmentComponent,
		BlocosPesquisaModalComponent,
		CondominiosPesquisaModalComponent,
		UnidadesPesquisaModalComponent,
		RelatorioModalComponent,
		EnvioEmailBoletoModalComponent,
		VisualizaAnexoComponent,
		ContextMenuComponent,

		// Directives
		AutoFocusDirective,
		MaskDirective,

		// Pipes
		TipoPagamentoPipe,
		TipoComunicacaoPipe,
		SindicoAprovaPipe,
		TiposConselhosCondominioPipe,
		TiposDestinatariosPipe,
		TiposRemetentesPipe,
		UnidadesMedidaPipe,
		CurrencyFormatPipe,
		DecimalFormatPipe,
		IntegerFormatPipe,
		FloatFormatPipe,
		CpfFormatPipe,
		CnpjFormatPipe
	],
	exports: [
		CommonModule,
		FormsModule,
		NgxCurrencyModule,

		// Components
		FormControlErroComponent,
		DatepickerComponent,
		AttachmentComponent,
		BlocosPesquisaModalComponent,
		CondominiosPesquisaModalComponent,
		UnidadesPesquisaModalComponent,
		RelatorioModalComponent,
		EnvioEmailBoletoModalComponent,
		VisualizaAnexoComponent,
		ContextMenuComponent,

		// Directives
		AutoFocusDirective,
		MaskDirective,

		// Pipes
		TipoPagamentoPipe,
		TipoComunicacaoPipe,
		SindicoAprovaPipe,
		TiposConselhosCondominioPipe,
		TiposDestinatariosPipe,
		TiposRemetentesPipe,
		UnidadesMedidaPipe,
		CurrencyFormatPipe,
		DecimalFormatPipe,
		IntegerFormatPipe,
		FloatFormatPipe,
		CpfFormatPipe,
		CnpjFormatPipe
	]
})
export class SharedModule { }