import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { AuthService, MessageService } from 'app/core';
import { CobrancaAvulsa } from 'app/models';
import { CobrancaAvulsaHttpService } from '../service';

declare var jQuery: any;

@Component({
  selector: 'cobranca-avulsa-edicao-modal',
  templateUrl: './cobranca-avulsa-edicao-modal.component.html',
  styleUrls: ['./cobranca-avulsa-edicao-modal.component.css']
})
export class CobrancaAvulsaEdicaoModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @Input() id_cobranca_avulsa: number;
  @Output() onSave: EventEmitter<any> = new EventEmitter();

  public cobranca: CobrancaAvulsa = new CobrancaAvulsa();

  constructor(private authService: AuthService,
		private messageService: MessageService,
    private cobrancaAvlsaHttpService: CobrancaAvulsaHttpService) { } 

  ngOnInit() {
    if (!this.idModal) {
      this.idModal = 'renegociacaoModal';
    }
  }

  /**
   * Carrega os dados da cobrança para edição
   * @author Marcos Frare
   * @since 28/07/2021
   */
  private loadCobranca(): void {
    this.cobranca = new CobrancaAvulsa();
    if (!this.id_cobranca_avulsa) {

    } else {
      this.messageService.warning('Não encontrado cobrança para edição!', 'Atenção');
      this.close();
    }
  }

  open() {
    this.loadCobranca();
    jQuery(`#${this.idModal}`).modal('show');
  }
  close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

}