export class NotificacaoFiltro {
	id_cliente: number;
	id_condominio: number;
	id_usuario: number;
	id_bloco: number;
	id_unidade: number;
	dataInicial: Date;
	dataFinal: Date;
	tipoNotificacao: number;
	status: number;

	/** Limpa o filtro */
	limpar() {
		const today = new Date((new Date).getFullYear(), (new Date).getMonth(), 1);
		const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
		this.dataInicial = today;
		this.dataFinal = lastDay;
		this.id_bloco = null;
		this.id_unidade = null;
		this.tipoNotificacao = null;
		this.status = null;
	}
}