import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { RenegociacoesRelComponent } from './renegociacoes-rel.component';

@NgModule({
	imports: [
		SharedModule,
		TypeaheadModule
	],
	declarations: [
		RenegociacoesRelComponent
	],
	exports: [],
	providers: []
})
export class RenegociacoesRelModule { }