import { BaseEntity } from "../base-entity";
import { Local } from 'app/models';

export class LocalDisponibilidade extends BaseEntity {
	id_local: number;
	dia_da_semana: number;
	periodo: number;
	hora_inicial: Date;
	hora_final: Date;

	local: Local;

	constructor() {
		super();
	}
}