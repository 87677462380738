import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService, MessageService } from '../../../core';
import { DemonstrativoRelFiltro } from './demonstrativo-rel-filtro';
import { RelatorioModalComponent } from '../../relatorios/relatorio-modal';
import { datepickerMesAnoOptions } from '../../../shared';

@Component({
	selector: 'demonstrativo-rel',
	templateUrl: './demonstrativo-rel.component.html',
	styleUrls: ['../shared/visualizacao.relatorio.css']
})
export class DemonstrativoRelComponent implements OnInit {

	public filtro = new DemonstrativoRelFiltro();
	public datepickerDataCompetenciaOptions = datepickerMesAnoOptions;

	@ViewChild('relatorioModal') relatorioModal: RelatorioModalComponent;

	constructor(private authService: AuthService,
		private messageService: MessageService) {
	}

	ngOnInit() {
		this.limpar();
		this.filtro.id_cliente = this.authService.getIdCurrentCliente();
		this.filtro.id_condominio = this.authService.getIdCurrentCondominio();
		this.filtro.id_usuario = this.authService.getIdCurrentUser();
	}

	private validateView(): boolean {
		if (!this.filtro.tipoFiltro) {
			this.messageService.info('É necessário informar o tipo de período', 'Informação');
			return false;
		} else {
			if (this.filtro.tipoFiltro === 1) {
				if (!this.filtro.dataCompetencia) {
					this.messageService.info('É necessário informar a competência', 'Informação');
					return false;
				}
			} else {
				if (!this.filtro.data_inicial) {
					this.messageService.info('É necessário informar a data inicial para imprimir', 'Informação');
					return false;
				}
				if (!this.filtro.data_final) {
					this.messageService.info('É necessário informar a data final para imprimir', 'Informação');
					return false;
				}
			}
		}
		return true;
	}

	public view(): void {
		if (this.validateView()) {
			this.relatorioModal.url = '/relatorio/demonstrativo-pdf';
			this.relatorioModal.params = this.filtro;
			this.relatorioModal.open();
		}
	}

	public limpar(): void {
		this.filtro.limpar();
	}

}