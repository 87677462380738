import { Component, OnInit, ViewChild } from '@angular/core';
import { Unidade, Titulo, situacoesBoleto, SITUACAO_BOLETO } from 'app/models';
import { MessageService, AuthService } from 'app/core';
import { BoletosHttpService } from '../services';
import { UnidadesHttpService } from 'app/home/blocos/unidades/services/unidades-http.service';
import { periodos, TitulosFiltro } from './titulos-filtro';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { DatePipe } from '@angular/common';
import { FloatFormatPipe } from 'app/shared/pipes';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { RelatorioModalComponent } from 'app/home/relatorios';

declare var jQuery: any;

@Component({
  selector: 'titulo-condomino',
  templateUrl: './titulo-condomino.component.html',
  styleUrls: ['./titulo-condomino.component.css'],
  providers: [PermissionService, DatePipe, FloatFormatPipe],
})

export class TituloCondominoComponent implements OnInit {

  @ViewChild('relatorioModal') relatorioModal: RelatorioModalComponent;

  public id_unidade: number;
  public unidades: Unidade[] = [];
  private id_usuario: number;
  private id_condominio: number;
  public titulos: Titulo[];
  public filtro = new TitulosFiltro();

  public periodos = periodos;
  public situacoes = situacoesBoleto;
  public situacaoBoleto = SITUACAO_BOLETO;

  constructor(public messageService: MessageService,
      private authService: AuthService,
      private boletosHttpService: BoletosHttpService,
      private unidadesHttpService: UnidadesHttpService,
      private router: Router,
      public activatedRoute: ActivatedRoute,
      private boletoHttpService: BoletosHttpService,
      private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.id_condominio = this.authService.getIdCurrentCondominio();
    this.id_usuario = this.authService.getIdCurrentUser();
    this.id_unidade = null;
    this.filtro.limpar();
    this.loadUnidades();
    this.filtro.aberto = true;
  }

  /**
   * Busca as unidades ao qual o usuário é sacado
   * @author Marcos Frare
   * @since 26/03/2020
   */
  private loadUnidades(): void {
    this.unidades = [];
    this.unidadesHttpService.getUnidadesByUsuario(this.id_condominio, this.id_usuario)
      .subscribe(
        response => {
          this.unidades = response
          this.id_unidade = null;
          if (this.unidades.length === 1) {
            this.id_unidade = this.unidades[0].id;
          }
          this.loadTitulos();
        },
        error => this.messageService.error('Erro ao carregar as unidades', 'Erro', error)
      );
  }

  /**
   * Buscar os títulos das unidades
   * @author Marcos Frare
   * @since 27/03/2020
   */
  public loadTitulos(): void {
    this.spinner.show();
    this.titulos = [];
    this.filtro.id_condominio = this.id_condominio;
    this.filtro.id_unidade = this.id_unidade;
    this.filtro.unidades_id = this.unidades.map(unidade => unidade.id);

    this.boletosHttpService.getTitulosCondominoFiltro(this.filtro)
      .subscribe(
        response => {
          this.spinner.hide();
          this.titulos = response;
          this.filtro.aberto = false;
        },
        error => {
          this.messageService.error('Erro ao carregar títulos', 'Erro', error);
          this.spinner.hide();
        }
      );
  }

  /**
   * Copia linha digitável para área de transferência
   * @param titulo 
   * @author Marcos Frare
   * @since 27/03/2020
   */
  public copyLinhaDigitavel(titulo: Titulo): void {
    const toogle = jQuery('#copy_'+titulo.id);
    toogle.show();
    if (titulo && titulo.linha_digitavel) {
      // Removendo outros caracteres que não sejam números
      let linhaDigitavel = titulo.linha_digitavel.replace(/\D+/g, '');
      const event = (e: ClipboardEvent) => {
        e.clipboardData.setData('text/plain', linhaDigitavel);
        e.preventDefault();
      }
      document.addEventListener('copy', event);
      document.execCommand('copy');
    }
    setTimeout(() => {
      toogle.hide();
    }, 1000)
  }

  /**
   * Carregar o Título
   * @param id_titulo 
   * @author Marcos Frare
   * @since 30/03/2020
   */
  public loadTitulo(id_titulo: number) {
    let filtro = {
      id_condominio: this.authService.getIdCurrentCondominio(),
      idsTitulo: [id_titulo],
      padrao: true
    }
    this.relatorioModal.listarTitulos = true;
    this.relatorioModal.url = '/titulo/listar';
    this.relatorioModal.params = filtro;
    this.relatorioModal.open();
  }

}