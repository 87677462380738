import { CategoriaContas } from './categoria-contas';
import { BaseEntity } from "../base-entity";

export const TiposExtratos = [
	{ codigo: 1, nome: 'Crédito' },
	{ codigo: 2, nome: 'Débito' },
	// {codigo: 3, nome: 'Crédito Transferência'}, // Não utilizado código de transferência para geração de crédito ou débito
	// {codigo: 4, nome: 'Débito Transferência'}
];

export const TiposOrigem = [
	{ codigo: 1, nome: 'Sistema' },
	{ codigo: 2, nome: 'Manual' },
	{ codigo: 3, nome: 'Conciliacao' },
	{ codigo: 4, nome: 'Remessa' },
	{ codigo: 5, nome: 'API' },
];

export class Extrato extends BaseEntity {
	id: number;
	tipo: number;
	valor: number;
	data: Date;
	descricao: string;
	id_conta_bancaria: number;
	id_categoria_conta: number;
	categoriaContas: CategoriaContas;
	tipo_origem: number;
	conciliacao_automatica: boolean;
	id_pagamento: number;

	checado: boolean;
	conciliado: boolean;
	pago: boolean;
}