import { Permission } from './../../../componentes-base/permission';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { MessageService } from '../../../core';
import { DemonstrativoRateio, Condominio, Bloco, VIEW_MODE } from '../../../models';
import { BlocosHttpService } from '../../blocos';
import { RateiosHttpService } from '../services';
import { RateiosUnidadesHttpService } from './services';
import { CondominiosHttpService } from '../../condominios';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { RateiosUnidadesLancamentoComponent } from './lancamento';
import { NgxSpinnerService } from 'ngx-spinner';

declare var jQuery: any;

@Component({
	selector: 'rateios-unidades',
	templateUrl: './rateios-unidades.component.html',
	styleUrls: ['./rateios-unidades.component.css'],
	providers: [
		BlocosHttpService,
		CondominiosHttpService,
		PermissionService
	]
})
export class RateiosUnidadesComponent implements OnInit {

	@ViewChild('rateiosUnidadesLancamento') rateiosUnidadesLancamento: RateiosUnidadesLancamentoComponent;

	public editMode: boolean;
	public demonstrativoRateio: DemonstrativoRateio;
	public condominio: Condominio;
	public blocos: Bloco[];
	public idBlocoAtual: number;
	public permissions: Permission;
	public resource: string;
	public pagamentos: any = {};
	public viewMode: number;
	public viewModeTypes = VIEW_MODE;

	constructor(private activatedRoute: ActivatedRoute,
		private router: Router,
		private messageService: MessageService,
		private rateiosHttpService: RateiosHttpService,
		private rateioUnidadesHttpService: RateiosUnidadesHttpService,
		private blocosHttpService: BlocosHttpService,
		private condominiosHttpService: CondominiosHttpService,
		private permissionService: PermissionService,
		private spinner: NgxSpinnerService) {
		this.resource = '/rateios';
	}

	ngOnInit() {
		this.handleQueryParams();
		this.handleRouteParams();
		this.getPermissions();
	}

	private handleRouteParams(): void {
		this.activatedRoute.url.subscribe(url => {
			const currentUrl = url.join('/');
			this.editMode = currentUrl.includes('/editar');
		});

		this.activatedRoute.params.subscribe((params: Params) => {
			const idDemonstrativo = +params['id'];
			this.getDemonstrativoRateio(idDemonstrativo);
		});
	}

	private handleQueryParams(): void {
		this.activatedRoute.queryParams.subscribe((params: Params) => {
			this.viewMode = parseInt(params['viewMode']);
		});
	}

	private getDemonstrativoRateio(idDemonstrativo: number): void {
		if (!idDemonstrativo) { return; }
		this.spinner.show();
		this.rateiosHttpService.view(idDemonstrativo).subscribe(
			entity => {
				this.demonstrativoRateio = entity;
				if (this.demonstrativoRateio) {
					this.getCondominio(this.demonstrativoRateio.id_condominio);
					// Blocos
					this.getBlocosRateio(this.demonstrativoRateio.id);
				}
				this.spinner.hide();
			},
			error => {
				this.spinner.hide();
				if (error.status === 400) {
					this.messageService.info('Demonstrativo não encontrado', 'Informação');
					this.cancel();
				} else {
					this.messageService.error('Erro ao carregar o Demonstrativo do Rateio. Tente novamente', 'Erro', error);
				}
			}
		);
	}

	private getCondominio(idCondominio: number): void {
		if (!idCondominio) { return; }
		this.spinner.show();
		this.condominiosHttpService.get(idCondominio).subscribe(
			entity => {
				this.condominio = entity;
				this.spinner.hide();
			},
			error => {
				this.messageService.error('Erro ao carregar o condomínio. Tente novamente', 'Erro', error);
				this.spinner.hide();
			}
		);
	}

	/**
	 * Busca os blocos que participaram do rateio
	 * @param id_demonstrativo_rateio 
	 * @author Marcos Frare
	 * @since 09/08/2021
	 */
	private getBlocosRateio(id_demonstrativo_rateio: number): void {
		this.blocos = [];
		this.blocosHttpService.getAllByRateio(id_demonstrativo_rateio).subscribe(
			response => {
				this.blocos = response;
				if (this.blocos && this.blocos.length > 0) {
					this.idBlocoAtual = this.blocos[0].id;
				}
			},
			error => {
				this.messageService.error('Erro ao buscar os blocos do rateio! Tente novamente.', 'Erro', error);
			}
		);
	}

	/**
	 * Validação e geração dos boletos
	 * @author Marcos Frare
	 * @since 11/05/2020
	 */
	public finalizar(): void {
		this.spinner.show();
		// Validando geração dos boletos
		this.rateioUnidadesHttpService.validarGeracaoTitulo(this.demonstrativoRateio.id).subscribe(
			response => {
				if (!response.validado) {
					this.spinner.hide();
					// Despeja o array de mensagens
					response.msg.map(msg => {
						this.messageService.warning(msg, 'Atenção');
					});
				} else {
					// Geração dos boletos
					this.rateiosHttpService.finalizar(this.demonstrativoRateio.id).subscribe(
						() => {
							this.spinner.hide();
							this.messageService.success('', 'Rateio das unidades salvo com sucesso');
							this.cancel();
						},
						error => {
							this.spinner.hide();
							this.messageService.error('Erro ao salvar o rateio das unidades. Tente novamente', 'Erro', error);
						}
					);
				}
			},
			error => {
				this.messageService.error('Erro ao validar rateio. Tente novamente', 'Erro', error);
				this.spinner.hide();
			}
		);
	}

	public cancel(): void {
		if (this.editMode) {
			this.router.navigate(['../../../'], { relativeTo: this.activatedRoute });
		} else {
			this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
		}
	}

	private getPermissions(): void {
		this.permissionService.getPermissions(this.resource)
			.subscribe(permissions => this.permissions = permissions);
	}

}