import { MessageService } from './../../../../core/services/message/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, ViewChild } from '@angular/core';
import { BaseCadastroComponent } from 'app/componentes-base';
import { Extrato, TiposExtratos } from 'app/models/entidades/extrato';
import { AuthService, ConfigService } from 'app/core';
import { ExtratosHttpService } from 'app/home/contas-bancarias/extratos/services';

import { CategoriaContas } from './../../../../models/entidades/categoria-contas';
import { CategoriaContasPesquisaModalComponent } from '../../../plano-de-contas/categoria-contas';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { CategoriaContasHttpService } from '../../../plano-de-contas/categoria-contas';
import { PermissionService } from '../../../../core/services/permission/permission.service';
import { FloatFormatPipe } from '../../../../shared/pipes';
import { PlanoDeContasCadastroModalComponent } from '../../../plano-de-contas/cadastro-modal/plano-de-contas-cadastro-modal.component';
import { ContasBancariasHttpService } from '../../services';
import { ContaBancaria } from 'app/models';

declare var jQuery: any;

@Component({
    selector: 'cadastro',
    templateUrl: './extratos-cadastro.component.html',
    styleUrls: ['./extratos-cadastro.component.css'],
    providers: [PermissionService, FloatFormatPipe]
})
export class ExtratosCadastroComponent extends BaseCadastroComponent<Extrato> {

    @Input('isShowOptions') isShowOptions = true;

    idContaBancaria: number;
    tiposExtratos = TiposExtratos;
    contas: ContaBancaria[];
    @ViewChild('categoriaContasPesquisaModal') categoriaContasPesquisaModal: CategoriaContasPesquisaModalComponent;
    @ViewChild('planoDeContasCadastroModal') planoDeContasCadastroModal: PlanoDeContasCadastroModalComponent;
    categoriasContas: CategoriaContas[] = [];

    constructor(activatedRoute: ActivatedRoute,
                router: Router,
                public route: ActivatedRoute,
                messageService: MessageService,
                private authService: AuthService,
                private categoriaContasHttpService: CategoriaContasHttpService,
                private extratosHttpService: ExtratosHttpService,
                public configService: ConfigService,
                permissionService: PermissionService,
                private floatFormatPipe: FloatFormatPipe,
                private contasBancariasHttpService: ContasBancariasHttpService) {
        super(Extrato, extratosHttpService, activatedRoute, router, messageService, permissionService);
        this.route.params.subscribe(params => {
            if (!this.editMode) {
                this.idContaBancaria = parseInt(params['idConta']);
            }
        });
    }

    ngOnInit() {
        this.getAllCategoriasByCondominio(this.authService.getIdCurrentCondominio());
        this.getContas();
        super.ngOnInit();
    }

    public getContas() {
        this.contasBancariasHttpService.getAllByCondominio(this.authService.getIdCurrentCondominio())
            .subscribe(
                response => {
                    this.contas = response;
                    if (!this.editMode) { this.entity.id_conta_bancaria = this.idContaBancaria; }
                },
                error => this.messageService.error('Erro ao carregar contas bancárias. Tente novamente', 'Erro', error)
            );
    }

    public onSaveClick(): void {
        const entity: any = { ...this.entity };
        this.save(entity);
    }

    private getAllCategoriasByCondominio(idCondominio: number) {
        this.categoriasContas = [];
        if (idCondominio) {
            this.categoriaContasHttpService.getAllByCondominio(idCondominio)
                .then(entities => this.categoriasContas = entities)
                .catch(() => this.messageService.info(
                    `Configuração do condomínio não encontrada. Configure o condomínio para prosseguir.`, 'Informação'));
        }
    }

    private setCategoriaContas() {
        if (!this.entity.id_categoria_conta) {
            return;
        }

        this.categoriaContasHttpService.get(this.entity.id_categoria_conta)
            .subscribe(
                entity => {
                    this.entity.categoriaContas = entity;
                },
                error => this.messageService.error('Erro ao carregar a categoria de contas', 'Erro', error)
            );
    }

    public openPlanoDeContasCadastroModal(): void {
        this.planoDeContasCadastroModal.open();
    }

    public onAfterSaveContaCategoriaConta(categoriaConta: CategoriaContas): void {
        this.entity.categoriaContas = categoriaConta;
        this.entity.id_categoria_conta = categoriaConta.id;
    }

    onSelectCategoriaContas(tm: TypeaheadMatch) {
        const categoriaContas: CategoriaContas = tm.item as CategoriaContas;
        if (categoriaContas) {
            this.entity.id_categoria_conta = categoriaContas.id;
            this.entity.categoriaContas = categoriaContas;
            this.entity.descricao = categoriaContas.nome;
        } else {
            this.entity.id_categoria_conta = null;
            this.entity.categoriaContas = null;
        }
    }

    onCleanCategoriaContas(input: any) {
        this.entity.id_categoria_conta = null;
        this.entity.categoriaContas = null;

        input.value = '';
        input.focus();
    }

    openCategoriaContasPequisaModal() {
        this.categoriaContasPesquisaModal.open();
    }

    onSelectCategoriaContasPesquisaModal(categoriaContas: CategoriaContas) {
        if (categoriaContas) {
            this.entity.id_categoria_conta = categoriaContas.id;
            this.entity.categoriaContas = categoriaContas;
            this.entity.descricao = categoriaContas.nome;
        }
        jQuery('#categoriaContas').focus();
    }

    afterEdit(entity: Extrato) {
        this.entity.valor = this.floatFormatPipe.transform(this.entity.valor);
        this.setCategoriaContas();
    }

    cancel() {
        this.router.navigate(['/financeiro/controle-financeiro'], { relativeTo: this.activatedRoute });
    }

}
