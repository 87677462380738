import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { MessageService, AuthService } from 'app/core';
import { ConfiguracaoTarifaBancaria, Fornecedor, CategoriaContas, tiposDivisaoRateio } from 'app/models';
import { ContasBancariasHttpService } from '../services';
import { NgForm } from '@angular/forms';
import { FornecedoresHttpService, FornecedoresCadastroModalComponent } from 'app/home/fornecedores';
import { CategoriaContasHttpService, CategoriaContasPesquisaModalComponent } from 'app/home/plano-de-contas/categoria-contas';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { PlanoDeContasCadastroModalComponent } from 'app/home/plano-de-contas/cadastro-modal/plano-de-contas-cadastro-modal.component';

declare var jQuery: any;

@Component({
  selector: 'configuracao-tarifa-bancaria-modal',
  templateUrl: './configuracao-tarifa-bancaria-modal.component.html',
  styleUrls: ['./configuracao-tarifa-bancaria-modal.component.css']
})
export class ConfiguracaoTarifaBancariaModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @Input() idContaBancaria: number;
  @Input() idCondominio: number;
  @ViewChild('form') form: NgForm;

  @ViewChild('categoriaContasPesquisaModal') categoriaContasPesquisaModal: CategoriaContasPesquisaModalComponent;
  @ViewChild('planoDeContasCadastroModal') planoDeContasCadastroModal: PlanoDeContasCadastroModalComponent;
  @ViewChild('categoriaContasPesquisaNaoRateadoModal') categoriaContasPesquisaNaoRateadoModal: CategoriaContasPesquisaModalComponent;
  @ViewChild('planoDeContasNaoRateadoCadastroModal') planoDeContasNaoRateadoCadastroModal: PlanoDeContasCadastroModalComponent;
  @ViewChild('fornecedoresCadastroModal') fornecedoresCadastroModal: FornecedoresCadastroModalComponent;

  public idCliente: number;
  public configuracaoTarifaBancaria: ConfiguracaoTarifaBancaria;
  public fornecedores: Fornecedor[] = [];
  public categoriasContasReceita: CategoriaContas[] = [];
  public categoriasContasDespesa: CategoriaContas[] = [];
  public tiposDivisaoRateio = tiposDivisaoRateio;
  public tipoDivisaoRateioDefault: number;

  constructor(private messageService: MessageService,
      private contasBancariasHttpService: ContasBancariasHttpService,
      private authService: AuthService,
      private fornecedoresHttpService: FornecedoresHttpService,
      private categoriaContasHttpService: CategoriaContasHttpService) {
    this.idCliente = this.authService.getIdCurrentCliente();
  }

  ngOnInit() {
    this.configuracaoTarifaBancaria = new ConfiguracaoTarifaBancaria();
  }

  /**
   * Busca os fornecedores do Cliente
   * @author Marcos Frare
   * @since 10/07/2020
   */
  private getAllFornecedores(): void {
    this.fornecedoresHttpService.getAllByCliente(this.idCliente)
      .subscribe(entities => this.fornecedores = entities,
        error => this.messageService.error('Erro ao carregar os fornecedores', 'Erro', error)
      );
  }

  /**
   * Busca as categorias de contas do condomínio
   * @param idCondominio 
   * @author Marcos Frare
   * @since 10/07/2020
   */
  private getAllCategoriasByCondominio(idCondominio: number): void {
    this.categoriasContasReceita = [];
    this.categoriasContasDespesa = [];
    if (idCondominio) {
      this.categoriaContasHttpService.getAllByCondominio(idCondominio)
        .then(entities => {
          this.categoriasContasReceita = entities.filter(conta => conta.tipo_conta == 1);
          this.categoriasContasDespesa = entities.filter(conta => conta.tipo_conta == 2);
        })
        .catch(() => this.messageService.info(`Configuração do condomínio não encontrada. Configure o condomínio para prosseguir.`, 'Informação'));
    }
  }

  private setCategoriaContas(): void {
    if (!this.configuracaoTarifaBancaria.id_categoria_conta) {
      return;
    }
    this.categoriaContasHttpService.get(this.configuracaoTarifaBancaria.id_categoria_conta).subscribe(
      configuracaoTarifaBancaria => {
        this.configuracaoTarifaBancaria.categoriaConta = configuracaoTarifaBancaria;
        if (configuracaoTarifaBancaria.id_fornecedor) {
          this.configuracaoTarifaBancaria.id_fornecedor = configuracaoTarifaBancaria.id_fornecedor;
        }
      }
    );
  }

  public onSelectCategoriaContas(tm: TypeaheadMatch): void {
    const categoriaContas: CategoriaContas = tm.item as CategoriaContas;
    if (categoriaContas) {
        this.configuracaoTarifaBancaria.id_categoria_conta = categoriaContas.id;
        this.configuracaoTarifaBancaria.categoriaConta = categoriaContas;
        this.configuracaoTarifaBancaria.tipo_divisao_rateio = categoriaContas.tipo_divisao_rateio || this.tipoDivisaoRateioDefault;
        // Sugestão de fornecedor apenas na criação
        if (!this.configuracaoTarifaBancaria.id && categoriaContas.id_fornecedor) {
          this.configuracaoTarifaBancaria.id_fornecedor = categoriaContas.id_fornecedor;
        }
    } else {
      this.configuracaoTarifaBancaria.id_categoria_conta = null;
      this.configuracaoTarifaBancaria.categoriaConta = null;
    }
  }

  public onCleanCategoriaContas(input: any): void {
    this.configuracaoTarifaBancaria.id_categoria_conta = null;
    this.configuracaoTarifaBancaria.categoriaConta = null;

    input.value = '';
    input.focus();
  }

  public openCategoriaContasPequisaModal(): void {
    this.categoriaContasPesquisaModal.open();
  }

  public onSelectCategoriaContasPesquisaModal(categoriaContas: CategoriaContas): void {
    if (categoriaContas) {
      this.configuracaoTarifaBancaria.id_categoria_conta = categoriaContas.id;
      this.configuracaoTarifaBancaria.categoriaConta = categoriaContas;
      this.configuracaoTarifaBancaria.tipo_divisao_rateio = categoriaContas.tipo_divisao_rateio || this.tipoDivisaoRateioDefault;
      // Sugestão de fornecedor apenas na criação
      if (!this.configuracaoTarifaBancaria.id && categoriaContas.id_fornecedor) {
        this.configuracaoTarifaBancaria.id_fornecedor = categoriaContas.id_fornecedor;
      }
    }
    jQuery('#categoriaContas').focus();
  }

  public openPlanoDeContasCadastroModal(): void {
    this.planoDeContasCadastroModal.open();
  }

  public createFornecedor(): void {
    this.fornecedoresCadastroModal.open();
  }

  public onAfterSaveFornecedor(fornecedor: Fornecedor): void {
    this.configuracaoTarifaBancaria.id_fornecedor = fornecedor.id;
    this.fornecedores.push(fornecedor);
    jQuery('#fornecedor').focus();
  }

  private setCategoriaContasNaoRateado(): void {
    if (!this.configuracaoTarifaBancaria.id_categoria_conta_nao_rateado) {
      return;
    }
    this.categoriaContasHttpService.get(this.configuracaoTarifaBancaria.id_categoria_conta_nao_rateado).subscribe(
      entity => this.configuracaoTarifaBancaria.categoriaContaNaoRateado = entity
    );
  }

  public onSelectCategoriaContasNaoRateado(tm: TypeaheadMatch): void {
    const categoriaContas: CategoriaContas = tm.item as CategoriaContas;
    if (categoriaContas) {
      this.configuracaoTarifaBancaria.id_categoria_conta_nao_rateado = categoriaContas.id;
      this.configuracaoTarifaBancaria.categoriaContaNaoRateado = categoriaContas;
    } else {
      this.configuracaoTarifaBancaria.id_categoria_conta_nao_rateado = null;
      this.configuracaoTarifaBancaria.categoriaContaNaoRateado = null;
    }
  }

  public onCleanCategoriaContasNaoRateado(input: any): void {
    this.configuracaoTarifaBancaria.id_categoria_conta_nao_rateado = null;
    this.configuracaoTarifaBancaria.categoriaContaNaoRateado = null;

    input.value = '';
    input.focus();
  }

  public openCategoriaContasNaoRateadoPequisaModal(): void {
    this.categoriaContasPesquisaNaoRateadoModal.open();
  }

  public onSelectCategoriaContasNaoRateadoPesquisaModal(categoriaContasNaoRateado: CategoriaContas): void {
    if (categoriaContasNaoRateado) {
      this.configuracaoTarifaBancaria.id_categoria_conta_nao_rateado = categoriaContasNaoRateado.id;
      this.configuracaoTarifaBancaria.categoriaContaNaoRateado = categoriaContasNaoRateado;
    }
    jQuery('#categoriaContasNaoRateado').focus();
  }

  /**
   * Abre modal com dados
   * @author Marcos Frare
   * @since 10/07/2020
   */
  open() {
    this.getAllFornecedores();
    this.getAllCategoriasByCondominio(this.idCondominio);
    jQuery(`#${this.idModal}`).modal('show');
    this.contasBancariasHttpService.getConfiguracaoTarifaBancaria(this.idContaBancaria).subscribe(
      response => {
        this.configuracaoTarifaBancaria = response;
        this.setCategoriaContas();
        this.setCategoriaContasNaoRateado();
      },
      error => this.messageService.error('Erro ao carregar configurações de tarifas!', 'Erro', error)
    );
  }

  close() {
    this.configuracaoTarifaBancaria = new ConfiguracaoTarifaBancaria();
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Persistir dados de configuração
   * @author Marcos Frare
   * @since 10/07/2020
   */
  public salvar(): void {
    let btnSave = jQuery('#btnSalvarConfiguracao');
    btnSave.button('loading');
    this.contasBancariasHttpService.saveConfiguracaoTarifaBancaria(this.configuracaoTarifaBancaria).subscribe(
      () => {
        btnSave.button('reset');
        this.messageService.info('Configurações gravadas com sucesso!')
        this.close();
      },
      error => {
        btnSave.button('reset');
        this.messageService.error('Problemas ao gravar configurações. Tente novamente.', 'Erro', error);
      }
    );
  }

}