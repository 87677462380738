import { Component, OnInit, Input } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { MessageService } from 'app/core';
import { ConfiguracaoServicoUnidadeHttpService } from './services';
import { TipoConsumo } from 'app/models';

declare var jQuery: any;

@Component({
  selector: 'configuracao-servico-unidade-modal',
  templateUrl: './configuracao-servico-unidade-modal.component.html',
  styleUrls: ['./configuracao-servico-unidade-modal.component.css']
})
export class ConfiguracaoServicoUnidadeModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;

  private blocos: any[] = [];
  private servicos: TipoConsumo[];

  constructor(private messageService: MessageService,
      private configuracaoServicoUnidadeHttpService: ConfiguracaoServicoUnidadeHttpService) { }

  ngOnInit() {
    if (!this.idModal) {
      this.idModal = 'configuracaoServicoUnidadeModal';
    }
  }

  open() {
    this.getDados();
    jQuery(`#${this.idModal}`).modal('show');
  }

  close() {
    this.blocos = [];
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Busca os dados de configurações das unidades
   * @author Marcos Frare
   * @since 26/02/2020
   */
  getDados() {
    this.blocos = [];
    this.servicos = [];
    this.configuracaoServicoUnidadeHttpService.getAllData()
      .subscribe(
        response => {
          this.blocos = response.blocos;
          this.servicos = response.servicos;
        },
        error => this.messageService.error('Erro ao carregar os blocos do condomínio. Tente novamente', 'Erro', error)
      );
  }

  salvar() {
    const btnView = jQuery('#btnSalvar');
    btnView.button('loading');
    this.configuracaoServicoUnidadeHttpService.persistData(this.blocos)
      .subscribe(
        () => {
          this.messageService.success('', 'Configuração salvo com sucesso!');
          this.getDados();
          btnView.button('reset');
        },
        error => {
          this.messageService.error('Erro ao gravar dados!!', 'Erro', error);
          btnView.button('reset');
        }
      );
  }

}
