import { Permission } from './../../../componentes-base/permission';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService, MessageService, DataTablesService } from '../../../core';
import { datepickerMesAnoOptions, datepickerDefaultOptions } from '../../../shared';
import { Condominio, DemonstrativoRateio, ConfiguracaoCondominio, TIPO_DIVISAO_RATEIO, VIEW_MODE } from '../../../models';
import { CondominiosHttpService } from '../../condominios';
import { RateiosHttpService } from '../services';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { CondominiosConfiguracaoHttpService } from 'app/home/condominios-configuracao';
import { DatePipe } from '@angular/common';
import { RateiosMensagensModalComponent } from './mensagens-modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { Popover } from 'ngx-popover';

interface Total {
	despesa: number,
	receita: number,
	fundoReserva: number
}

@Component({
	selector: 'rateios-cadastro',
	templateUrl: './rateios-cadastro.component.html',
	styleUrls: ['./rateios-cadastro.component.css'],
	providers: [
		CondominiosHttpService,
		DatePipe,
		PermissionService
	]
})
export class RateiosCadastroComponent implements OnInit {

	@ViewChild('rateiosMensagens') rateiosMensagens: RateiosMensagensModalComponent;

	private dataTable: any;
	private dataTableReceitaUnidade: any;
	private dataTableConsumoUnidade: any;
	private dataTableReceita: any;
	public mostrarConsumo: boolean;
	public editMode: boolean;
	public entity: DemonstrativoRateio = new DemonstrativoRateio();
	public datepickerDataCompetenciaOptions = datepickerMesAnoOptions;
	public datepickerDefaultOptions = datepickerDefaultOptions;
	public condominio: Condominio;
	public permissions: Permission;
	public resource: string;
	public idCondominio: number;
	public configCondominio: ConfiguracaoCondominio;
	public pagamentosFuturos: boolean;
	public pagamentosConsumosUnidades: boolean;
	public tipoDivisaoRateio = TIPO_DIVISAO_RATEIO;
	public totalUnidades: number;
	public pagamentosUnidades: any[];
	public consumoAtual: boolean;
	public consumosUnidades: any[];
	public lancamentosUnidade: any;
	public allSelectedDespesa: boolean;
	public allSelectedRecUnidade: boolean;
	public allSelectedConsumo: boolean;
	public allSelectedReceita: boolean;
	public popover: Popover;
	public podeGerar: boolean;

	public totais: Total = {despesa: 0, receita: 0, fundoReserva: 0};
	public totaisUnidade: Total = {despesa: 0, receita: 0, fundoReserva: 0};

	constructor(private activatedRoute: ActivatedRoute,
		private router: Router,
		private messageService: MessageService,
		private authService: AuthService,
		private condominiosHttpService: CondominiosHttpService,
		private rateiosHttpService: RateiosHttpService,
		private dataTablesService: DataTablesService,
		private permissionService: PermissionService,
		private datePipe: DatePipe,
		private condominiosConfiguracaoHttpService: CondominiosConfiguracaoHttpService,
		private spinner: NgxSpinnerService) {
		this.pagamentosFuturos = false;
		this.pagamentosConsumosUnidades = false;
		this.resource = '/rateios';
	}

	ngOnInit() {
		this.idCondominio = this.authService.getIdCurrentCondominio();
		this.podeGerar = true;
		this.getPermissions();
		this.handleRouteParams();
		this.getConfiguracaoCondominio();
		this.allSelectedDespesa = false;
		this.allSelectedReceita = false;
		this.allSelectedRecUnidade = true;
		this.allSelectedConsumo = true;
		this.mostrarConsumo = true;
	}

	/**
	 * Retorna dados do demonstrativo de rateio
	 * @author Marcos Frare
	 * @since 20/01/2021
	 */
	private getEntityClean(): DemonstrativoRateio {
		return <DemonstrativoRateio>{
			id: this.entity.id,
			id_condominio: this.entity.id_condominio,
			data_competencia: this.entity.data_competencia,
			data_vencimento: this.entity.data_vencimento,
			fechado: this.entity.fechado,
			vencimento_unidade: this.entity.vencimento_unidade,
			consumo_unidade: this.entity.consumo_unidade,
			valor_total: this.entity.valor_total,
			serie: this.entity.serie,
			cobrar_fundo_reserva: this.entity.cobrar_fundo_reserva,
			proprietario: this.entity.proprietario,
		}
	}

	/**
	 * Busca configuração do condomínio
	 * @author Towty
	 */
	private getConfiguracaoCondominio(): void {
		this.condominiosConfiguracaoHttpService.getByCondominio(this.idCondominio).subscribe(
			response => {
				this.configCondominio = response;
				if (this.configCondominio.tipo_divisao_rateio === TIPO_DIVISAO_RATEIO.IGUALITARIO) {
					this.getTotalUnidades();
				}
			}
		);
	}

	/**
	 * Verifica se é edição ou insersão
	 * @author Towty
	 */
	private handleRouteParams(): void {
		this.activatedRoute.params.subscribe((params: Params) => {
			const id = +params['id'];
			if (!isNaN(id)) {
				this.edit(id);
			} else {
				this.insert();
			}
		});
	}

	/**
	 * Novo rateio
	 * @author Towty
	 */
	private insert(): void {
		this.editMode = false;
		this.entity = new DemonstrativoRateio();
		this.entity.id_condominio = this.idCondominio;
		this.entity.valor_total = 0;
		this.entity.cobrar_fundo_reserva = true;
		this.entity.fechado = false;
		this.getCondominio(this.entity.id_condominio);
		this.getAllPagamentosForRateio();
	}

	/**
	 * Carrega dados do condomínio
	 * @param idCondominio ID do Condomínio
	 * @author Towty
	 */
	private getCondominio(idCondominio: number): void {
		this.condominiosHttpService.get(idCondominio).subscribe(
			entity => this.condominio = entity,
			error => this.messageService.error('Erro ao carregar dados do condomínio!', 'Erro', error)
		);
	}

	/**
	 * Busca as despesas para serem rateadas (Parcelas do Rateio)
	 * @author Towty
	 */
	private getAllPagamentosForRateio(): void {
		this.spinner.show();
		this.rateiosHttpService.getAllPagamentosForRateio(this.getEntityClean(), this.pagamentosFuturos).subscribe(
			response => {
				// Datas de competência e vencimento
				this.entity.data_competencia = new Date(response.data_competencia);
				this.entity.data_vencimento = new Date(response.data_vencimento);
				this.entity.tipo_fundo_reserva = response.tipo_fundo_reserva;
				this.entity.taxa_fundo_reserva = response.taxa_fundo_reserva;

				// Lançamentos para rateio
				this.entity.parcelas_despesa_rateio = response.parcelas_despesa_rateio;
				this.entity.receitas = response.receitas;

				// Carregar as receitas de unidades
				if (this.editMode) {
					this.entity.receitas_unidades = response.pagamentos_unidades;
				}

				this.buscaLancamentosUnidades();
				this.loadTablePagamentos();
				this.loadTableReceita();
				this.calcularValorTotal();

				this.spinner.hide();
			},
			error => {
				this.messageService.error('Erro ao carregar os pagamentos para o rateio. Tente novamente', 'Erro', error);
				this.spinner.hide();
			}
		);
	}

	/**
	 * Busca os lançamentos das unidades na competência
	 * @author Marcos Frare
	 * @since 12/05/2020
	 */
	private buscaLancamentosUnidades(): void {
		this.spinner.show();
		if (this.editMode) {
			// Receitas das unidades
			this.pagamentosUnidades = this.entity.receitas_unidades;
			this.pagamentosUnidades.map(rec => rec.checado = rec.id_demonstrativo_rateio ? true : false);
			this.allSelectedRecUnidade = false;
			// Consumos das unidades
			this.consumosUnidades = this.entity.consumos_unidades;
			this.allSelectedConsumo = true;
			this.loadTableReceitaUnidade();
			this.loadTableConsumoUnidade();
			this.loadTableReceita();
			this.spinner.hide();
		} else {
			const dataCompetencia = this.entity.data_competencia ? this.datePipe.transform(this.entity.data_competencia, 'yyyy-MM-dd') : null;
			this.rateiosHttpService.getAllPagamentosUnidadesForRateio(this.idCondominio, this.entity.id, dataCompetencia, this.pagamentosFuturos).subscribe(
				data => {
					this.lancamentosUnidade = data;

					// Receitas das Unidades
					this.pagamentosUnidades = this.lancamentosUnidade.pagamentos_unidades;
					// Consumos das unidades
					this.consumosUnidades = this.lancamentosUnidade.consumos_unidades;

					this.podeGerar = this.lancamentosUnidade.podeGerar;

					this.loadTableReceitaUnidade();
					if (this.mostrarConsumo) {
						this.loadTableConsumoUnidade();
					}

					// Caso não seja edição, Selecionar todas as receitas de unidades
					if (this.editMode) {
						this.pagamentosUnidades.forEach(pgUnidade => {
							if (Array.isArray(this.entity.receitas_unidades) && this.entity.receitas_unidades.length > 0) {
								this.entity.receitas_unidades.forEach(rec => {
									if (rec.id === pgUnidade.id && rec.id_demonstrativo_rateio) {
										pgUnidade.checado = true;
									}
								});
							}
						});
					} else {
						this.toggleAllSelectedRecUnidade();
						this.toggleAllSelectedConsumo();
					}

					if (this.lancamentosUnidade.msg != '') {
						this.messageService.warning(this.lancamentosUnidade.msg, 'Atenção');
					}
					this.spinner.hide();
				},
				error => {
					this.messageService.error('Erro ao carregar os lançamentos da unidade para o rateio. Tente novamente', 'Erro', error);
					this.spinner.hide();
				}
			);
		}
	}

	/**
	 * Mostra ou esconde consumos das unidades
	 * @author Marcos Frare
	 * @since 12/05/2020
	 */
	public onToggleConsumoUnidadesClick(): void {
		if (this.entity.data_competencia) {
			this.loadTableConsumoUnidade();
			this.mostrarConsumo = !this.mostrarConsumo;
		} else {
			this.messageService.warning('Para mostrar consumos, selecine a competência.', 'Atenção!');
		}
	}

	/**
	 * Evento de check para cobrar fundo de reserva
	 * @author Marcos Frare
	 * @since 13/05/2021
	 */
	public checkFundoReserva(): void {
		this.calcularValorTotal();
	}

	/**
	 * Busca a quantidade de unidades do condomínio
	 * @author Towty
	 */
	private getTotalUnidades(): void {
		this.condominiosHttpService.getTotalUnidades(this.idCondominio).subscribe(
			(response: any) => this.totalUnidades = response.total
		);
	}

	/**
	 * Alteração da competência
	 * @author Towty
	 */
	public onChangeDataCompetencia(): void {
		this.getAllPagamentosForRateio();
	}

	/**
	 * Atualiza tabela de pagamentos
	 * @author Marcos Frare
	 * @since 12/05/2020
	 */
	private loadTablePagamentos(): void {
		if (this.dataTable) {
			this.dataTable.destroy();
		}
		setTimeout(() =>
			this.dataTable = this.dataTablesService.showTable('#tableParcelasRateio', {
				order: [],
				columnDefs: [
					{ orderable: false, targets: [0, 5] },
				],
				language: { 'url': './assets/scripts/datatables-ptbr.json' }
			}), 200);
	}

	private calcularFundoReservaFixo(){
		this.totais.fundoReserva = this.entity.taxa_fundo_reserva;
		if (this.totalUnidades) {
			this.totais.fundoReserva *= Number(this.totalUnidades);
		}
	}

	private calculaFundoReservaPorcentual(parcela) {
		this.totais.fundoReserva += (parcela.valor*this.entity.taxa_fundo_reserva/100);
	}

	private dividirTotaisPorUnidade() {
		this.totaisUnidade.despesa = this.totais.despesa / Number(this.totalUnidades);
		this.totaisUnidade.receita = this.totais.receita / Number(this.totalUnidades);
		this.totaisUnidade.fundoReserva = this.totais.fundoReserva / Number(this.totalUnidades);
	}

	/**
	 * Calcula o valor total am taxa de condomínio
	 * @author Towty
	 */
	private calcularValorTotal(): void {
		this.totais.despesa = 0;
		this.totais.receita = 0;
		this.totais.fundoReserva = 0;

		this.totaisUnidade.despesa = 0;
		this.totaisUnidade.receita = 0;
		this.totaisUnidade.fundoReserva = 0;

		if (!this.entity.parcelas_despesa_rateio) {
			this.entity.valor_total = 0;
		}
		let valorTotal = 0;
		this.entity.parcelas_despesa_rateio.forEach(parcela => {
			if (parcela.checado) {
				let valorNumber = parcela.valor ? Number(parcela.valor) : 0;
				valorTotal += valorNumber;
				this.totais.despesa += valorNumber;
					if (this.entity.tipo_fundo_reserva == 2 && parcela.cobrar_fundo_reserva && this.entity.cobrar_fundo_reserva) {
						this.calculaFundoReservaPorcentual(parcela);
					}
			}
		});
		if (this.entity.tipo_fundo_reserva == 1 && this.entity.cobrar_fundo_reserva) {
			this.calcularFundoReservaFixo();
		}
		this.entity.receitas.forEach(receita => {
			if (receita.checado) {
				let valorNumber = receita.valor ? Number(receita.valor) : 0;
				valorTotal += valorNumber;
				this.totais.receita += valorNumber;
				if (this.entity.cobrar_fundo_reserva && this.entity.tipo_fundo_reserva == 2 && receita.cobrar_fundo_reserva) {
					this.calculaFundoReservaPorcentual(receita);
				}
			}
		});
		this.entity.valor_total = valorTotal;

		if (this.totalUnidades) {
			this.dividirTotaisPorUnidade();
		}
	}

	private load(id: number): void {
		this.spinner.show();
		this.rateiosHttpService.get(id).subscribe(
			entity => {
				this.entity = entity;
				this.loadTablePagamentos();
				this.afterEdit();
				this.buscaLancamentosUnidades();
				this.podeGerar = entity.podeGerar;
				if (entity.msg) {
					this.messageService.warning(entity.msg);
				}
			},
			error => {
				this.messageService.error('Erro ao carregar o Demonstrativo de Rateio. Tente novamente', 'Erro', error);
				this.spinner.hide();
			}
		);
	}

	private edit(id: number): void {
		this.editMode = true;
		this.load(id);
	}

	private afterEdit(): void {
		this.getCondominio(this.entity.id_condominio);
	}

	public onChecadoClick(): void {
		setTimeout(() => this.calcularValorTotal(), 100);
	}

	public onTogglePagamentosClick(): void {
		this.pagamentosFuturos = !this.pagamentosFuturos;
		this.getAllPagamentosForRateio();
	}

	/**
	 * Seleciona ou não pagamento com click na linha do grid
	 * @param pagamento 
	 * @author Marcos Frare
	 * @since 13/05/2020
	 */
	public lineCheckParcela(parcela): void {
		parcela.checado = !parcela.checado;
		this.calcularValorTotal();
	}

	/**
	 * Seleciona ou não receita com click na linha do grid
	 * @param receita 
	 * @author Marcos Frare
	 * @since 13/05/2020
	 */
	public lineCheckReceita(receita): void {
		receita.checado = !receita.checado;
		this.calcularValorTotal();
	}

	/**
	 * Seleciona ou não receita da Unidade com click na linha do grid
	 * @param pagamentoUnidade
	 * @author Marcos Frare
	 * @since 13/05/2020
	 */
	public lineCheckPagamentoUnidade(pagamentoUnidade): void {
		pagamentoUnidade.checado = !pagamentoUnidade.checado;
	}

	/**
	 * Validar dados para avançar
	 * @author Towty
	 */
	private validarAvancar(): boolean {
		if (!this.entity.data_competencia) {
			this.messageService.info('É necessário informar o mês competência para avançar', 'Informação');
			return false;
		}
		if (!this.entity.data_vencimento) {
			this.messageService.info('É necessário informar o vencimento para avançar', 'Informação');
			return false;
		}
		return true;
	}

	/**
	 * Carrega os IDs selecionados de despesas e recetas do condomínio e lançamentos das unidades
	 * @author Towty
	 */
	private getIdsPagamentosARatear(): any {
		const output: any = {
			ids_especifico: [],
			receitasUnidades: [],
			ids_parcela_rateio: []
		};
		// Despesas do condomínio
		this.entity.parcelas_despesa_rateio.forEach(parcela => {
			if (parcela.checado) {
				output.ids_parcela_rateio.push(parcela.id);
			}
		});
		// Receitas do condomínio
		this.entity.receitas.forEach(receita => {
			if (receita.checado) {
				output.ids_especifico.push(receita.id);
			}
		});
		// Receitas das Unidades
		this.pagamentosUnidades.forEach(recUnidade => {
			if (recUnidade.checado) {
				output.receitasUnidades.push(recUnidade.id);
			}
		});

		return output;
	}

	/**
	 * Avançar para próximo passo de geração dos boletos
	 * @author Towty
	 */
	private avancar(): void {
		this.spinner.show();
		if (this.validarAvancar()) {
			const pagamentos = this.getIdsPagamentosARatear();
			let rateio = this.getEntityClean();
			rateio.ids_parcela_rateio = pagamentos.ids_parcela_rateio;
			rateio.ids_pagamentos_especificos = pagamentos.ids_especifico;
			rateio.ids_pagamentos_unidades = pagamentos.receitasUnidades;
			rateio.ids_consumo = this.consumosUnidades.filter(con => con.checado).map(cons => cons.id);
			rateio.data_competencia = this.datePipe.transform(this.entity.data_competencia, 'yyyy-MM-dd');
			rateio.data_vencimento = this.datePipe.transform(this.entity.data_vencimento, 'yyyy-MM-dd');
			const save = this.editMode ? this.rateiosHttpService.put(rateio) : this.rateiosHttpService.post(rateio);
			save.subscribe(
				entity => {
					this.router.navigate(['../' + entity.id, 'unidades', 'editar'], {
						relativeTo: this.activatedRoute,
						queryParams: {
							viewMode: VIEW_MODE.UPDATE,
						}
					});
					this.spinner.hide();
				},
				error => {
					this.entity.data_competencia = new Date(this.entity.data_competencia);
					this.entity.data_vencimento = new Date(this.entity.data_vencimento);
					switch (error.status) {
						case 404:
							this.messageService.warning(
								'Não foram encontrados lançamentos para nenhuma das unidades',
								'Atenção'
							);
							break;
						default:
							this.messageService.error(
								'Erro ao salvar o Demonstrativo do Rateio. Tente novamente',
								'Erro',
								error
							);
							break;
					}
					this.spinner.hide();
				}
			);
		} else {
			this.spinner.hide();
		}
	}

	/**
	 * Click do botão avançar
	 * @author Towty
	 */
	public checkAvancar(): void {
		this.spinner.show();
		this.rateiosHttpService.validarGeracaoRateio(this.entity).subscribe(
			() => {
				this.spinner.hide();
				this.avancar();
			},
			error => {
				this.spinner.hide();
				const mensagens = error.json();
				this.rateiosMensagens.addMessages(mensagens);
				this.rateiosMensagens.open();
			}
		);
	}

	public cancel(): void {
		this.router.navigate(['../'], { relativeTo: this.activatedRoute });
	}

	private getPermissions(): void {
		this.permissionService.getPermissions(this.resource)
			.subscribe(permissions => this.permissions = permissions);
	}

	/**
	 * Verifica se todos os pagamentos de despesas estão selecionadas
	 * @author Marcos Frare
	 * @since 12/05/2020
	 */
	public checkAllSelectedDespesa(): boolean {
		for (const pgto of this.entity.parcelas_despesa_rateio) {
			if (!pgto.checado) {
				return false;
			}
		}
		return true;
	}

	/**
	 * Faz seleção de todos os pagamentos de despesa
	 * @author Marcos Frare
	 * @since 12/05/2020
	 */
	public toggleAllSelectedDespesa(): void {
		for (let pgto of this.entity.parcelas_despesa_rateio) {
			pgto.checado = this.allSelectedDespesa;
		}
		this.calcularValorTotal();
	}

	// ----------------------------------------------------------------------------------------------------
	// Table Receitas das Unidades
	// ----------------------------------------------------------------------------------------------------
	/**
	 * Atualiza Grid de receitas da unidade
	 * @author Marcos Frare
	 * @since 12/05/2020
	 */
	private loadTableReceitaUnidade(): void {
		if (this.dataTableReceitaUnidade) {
			this.dataTableReceitaUnidade.destroy();
		}
		setTimeout(() =>
			this.dataTableReceitaUnidade = this.dataTablesService.showTable('#tableReceitaUnidade', {
				ordering: false,
				columnDefs: [
					{ orderable: false, targets: 0 },
				],
				language: { 'url': './assets/scripts/datatables-ptbr.json' }
			}), 200);
	}

	/**
	 * Verifica se todos os pagamentos de despesas estão selecionadas
	 * @author Marcos Frare
	 * @since 12/05/2020
	 */
	public checkAllSelectedRecUnidade(): boolean {
		if (Array.isArray(this.pagamentosUnidades) && this.pagamentosUnidades.length > 0) {
			for (const pgUnidade of this.pagamentosUnidades) {
				if (!pgUnidade.checado) {
					return false;
				}
			}
		} else {
			return false;
		}
		return true;
	}

	/**
	 * Faz seleção de todos os pagamentos de despesa
	 * @author Marcos Frare
	 * @since 12/05/2020
	 */
	public toggleAllSelectedRecUnidade(): void {
		for (let rec of this.pagamentosUnidades) {
			rec.checado = this.allSelectedRecUnidade;
		}
	}

	// ----------------------------------------------------------------------------------------------------
	// Table Consumos das Unidades
	// ----------------------------------------------------------------------------------------------------
	/**
	 * Atualiza tabela de consumos das unidades
	 * @author Marcos Frare
	 * @since 12/05/2020
	 */
	private loadTableConsumoUnidade(): void {
		if (this.dataTableConsumoUnidade) {
			this.dataTableConsumoUnidade.destroy();
		}
		setTimeout(() =>
			this.dataTableConsumoUnidade = this.dataTablesService.showTable('#tableConsumoUnidade', {
				ordering: false,
				columnDefs: [
					{ orderable: false, targets: 0 },
				],
				language: { 'url': './assets/scripts/datatables-ptbr.json' }
			}), 200);
	}

	/**
	 * Atualiza Grid de receitas da unidade
	 * @author Marcos Frare
	 * @since 12/05/2020
	 */
	private loadTableReceita(): void {
		if (this.dataTableReceita) {
			this.dataTableReceita.destroy();
		}
		setTimeout(() =>
			this.dataTableReceita = this.dataTablesService.showTable('#tableReceita', {
				ordering: false,
				language: { 'url': './assets/scripts/datatables-ptbr.json' }
			}), 200);
	}

	/**
	 * Faz seleção de todos os pagamentos de despesa
	 * @author Marcos Frare
	 * @since 12/05/2020
	 */
	public toggleAllSelectedReceita(): void {
		for (let rec of this.entity.receitas) {
			rec.checado = this.allSelectedReceita;
		}
		this.calcularValorTotal();
	}

	/**
	 * Verifica se todos as receitas estão selecionadas
	 * @author Marcos Frare
	 * @since 13/05/2020
	 */
	public checkAllSelectedReceita(): boolean {
		for (const rec of this.entity.receitas) {
			if (!rec.checado) {
				return false;
			}
		}
		return true;
	}

	/**
	 * Seleciona ou não consumo da Unidade com click na linha do grid
	 * @param consumoUnidade
	 * @author Marcos Frare
	 * @since 06/08/2021
	 */
	 public lineCheckConsumoUnidade(consumoUnidade): void {
		consumoUnidade.checado = !consumoUnidade.checado;
	}

	/**
	 * Faz seleção de todos os consumos de unidade
	 * @author Marcos Frare
	 * @since 05/08/2021
	 */
	 public toggleAllSelectedConsumo(): void {
		for (let rec of this.consumosUnidades) {
			rec.checado = this.allSelectedConsumo;
		}
	}

	/**
	 * Verifica se todos os consumos das unidades estão selecionadas
	 * @author Marcos Frare
	 * @since 05/08/2021
	 */
	 public checkAllSelectedConsumo(): boolean {
		if (Array.isArray(this.consumosUnidades) && this.consumosUnidades.length > 0) {
			for (const consumoUnidade of this.consumosUnidades) {
				if (!consumoUnidade.checado) {
					return false;
				}
			}
		} else {
			return false;
		}
		return true;
	}

	/**
	 * Evento de click direito na linha de consumo para seleção do bloco
	 * @param event 
	 * @param consumo 
	 * @author Marcos Frare
	 * @since 06/08/2021
	 */
	public onRightClickConsumo(event, consumo): void {
		event.preventDefault();
		let id_bloco = consumo.id_bloco;
		let consumos = this.consumosUnidades.filter(con => con.id_bloco == id_bloco);
		const sel = !consumos[0].checado;
		consumos.map(con => {
			con.checado = sel;
		});
	}

}