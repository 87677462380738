import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { BoletosComponent } from './boletos.component';
import { BoletosHttpService } from './services';
import { LancamentosUnidadesModule } from '../lancamentos-unidades';
import { RenegociacaoModalComponent } from './renegociacao-modal';
import { BaixaModalComponent } from './baixa-modal';
import { PlanoDeContasModule } from '../plano-de-contas';
import { TituloCondominoComponent } from './titulo-condomino';
import { EnviarEmailTituloModalComponent, ParametrosListarTitulosModalComponent } from './parametros-listar-titulos-modal';
import { OcorrenciaTituloModalComponent } from './ocorrencia-titulo-modal';
import { IntegracaoBancariaModalComponent, RetornoEnvioIntegracaoModalComponent } from './integracao-bancaria-modal';
import { TituloEditarModalComponent } from './titulo-editar-modal';
import { UnidadesUsuariosHttpService } from '../blocos';
import { ConsumosUnidadesModule } from '../consumos-unidades';
import { BuscarBoletoModalComponent } from './buscar-boleto-modal';
import { CarneListarModalComponent } from './carne-listar-modal';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { ParametrosListarRecibosModalComponent } from './parametros-listar-recibos-modal';
import { AlteracaoLiquidacaoBoletoModalComponent } from './alteracao-liquidacao-boleto-modal';

@NgModule({
	imports: [
		SharedModule,
		TypeaheadModule,
		PlanoDeContasModule,
		LancamentosUnidadesModule,
		ConsumosUnidadesModule,
		MultiselectDropdownModule
	],
	declarations: [
		BoletosComponent,
		RenegociacaoModalComponent,
		BaixaModalComponent,
		TituloCondominoComponent,
		ParametrosListarTitulosModalComponent,
		OcorrenciaTituloModalComponent,
		IntegracaoBancariaModalComponent,
		RetornoEnvioIntegracaoModalComponent,
		TituloEditarModalComponent,
		BuscarBoletoModalComponent,
		CarneListarModalComponent,
		ParametrosListarRecibosModalComponent,
		AlteracaoLiquidacaoBoletoModalComponent,
		EnviarEmailTituloModalComponent
	],
	providers: [
		BoletosHttpService,
		UnidadesUsuariosHttpService
	]
})
export class BoletosModule { }