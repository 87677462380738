import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseCadastroComponent } from '../../componentes-base';
import { AuthService, MessageService } from '../../core';
import { LogoClienteHttpService } from './services';
import { PermissionService } from '../../core/services/permission/permission.service';
import { LogoCliente } from '../../models';
import { AttachmentComponent } from '../../shared/components/attachment';

@Component({
    selector: 'logo-cliente',
    templateUrl: 'logo-cliente-cadastro.component.html',
    providers: [
        PermissionService
    ]
})
export class LogoClienteCadastroComponent extends BaseCadastroComponent<LogoCliente> implements OnInit {

    public idCliente: number;
    @ViewChild('anexoComponent') anexoComponent: AttachmentComponent;

    constructor(public activatedRoute: ActivatedRoute,
                public router: Router,
                public messageService: MessageService,
                public logoClienteHttpService: LogoClienteHttpService,
                public permissionService: PermissionService,
                private authService: AuthService) {
        super(LogoCliente, logoClienteHttpService, activatedRoute, router, messageService, permissionService);
        this.idCliente = this.authService.getIdCurrentCliente();
    }

    ngOnInit() {
        this.entity = new LogoCliente();
        this.getByCliente(this.idCliente);
        this.getPermissions();
    }

    public afterInsert(): void {
        this.entity = new LogoCliente();
        super.afterInsert();
        this.editMode = false;
        this.entity.id_cliente = this.idCliente;
    }

    public afterEdit(entity: LogoCliente) {
        this.entity = entity;
        this.editMode = true;
        super.afterEdit(entity);

    }

    public afterSave(entity: LogoCliente) {
        this.entity.id = entity.id;
        this.editMode = true;
        this.btnSave.button('reset');
    }

    public beforeSave(): boolean {
        if(this.entity.anexo === null) {
            return false
        }
        return super.beforeSave();
    }

    private getByCliente(idCliente: number): void {
        this.logoClienteHttpService.getByCliente(idCliente).subscribe(
            entity => this.afterEdit(entity),
            () => this.afterInsert()
        );
    }

    delete(logo: LogoCliente) {
        this.logoClienteHttpService.delete(logo).subscribe(
            response => {
                this.messageService.success('Logo excluído com sucesso');
                this.anexoComponent.onFileClear();
                this.afterInsert();
            },
            error => this.messageService.error('Erro', 'Erro ao excluir a logo', error)
        );
    }
}
