import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseVisualizacaoComponent } from '../../../componentes-base';
import { Telefone } from '../../../models';
import { MessageService } from '../../../core';
import { TelefonesService } from './services/telefones.service';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'telefones',
  templateUrl: './telefones.component.html',
  styleUrls: ['./telefones.component.css'],
  providers: [PermissionService]
})
export class TelefonesComponent extends BaseVisualizacaoComponent<Telefone> {

  @Input() idUsuario: number;
  
  public telefone: Telefone = new Telefone();

  private idUsuarioTelefone: number;
  
  constructor(public telefonesService: TelefonesService,
    activatedRoute: ActivatedRoute,
    public route: ActivatedRoute,
    router: Router,
    messageService: MessageService,
    permissionService: PermissionService,
    public spinner: NgxSpinnerService) {
      super('#tableTelefones', telefonesService, activatedRoute, router, messageService, permissionService, spinner);
      this.route.params.subscribe(params => {
        this.idUsuarioTelefone = params['id'];
      });
    }

  getAll() {
    this.spinner.show();
    this.entities = [];
    this.destroyTable();
    this.telefonesService.getAllByUsuario(this.idUsuarioTelefone)
        .subscribe(
            entities => {
              this.spinner.hide();
                this.entities = entities;
                this.showTable();
            },
            error => {
              this.spinner.hide();
              this.messageService.error('Erro ao realizar a consulta. Tente novamente', 'Erro', error);
            }
        );
  }

  insert() {
    this.router.navigate(['./telefones/new'], {relativeTo: this.activatedRoute});
  }

  edit(telefone: Telefone) {
    this.router.navigate(['./telefones', telefone.id], {relativeTo: this.activatedRoute});    
  }

  cancel() {
    this.router.navigate(['./../'], {relativeTo: this.activatedRoute});        
  }

  consultarTelefonesDeUsuario() {
    this.telefonesService.getAllByUsuario(this.idUsuario).subscribe(
      entities => {
        this.entities = entities;
      }, error => {
        this.messageService.error('Erro ao realizar a consulta. Tente novamente', 'Erro', error);        
      }
    );
  }

}