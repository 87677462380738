import { NgModule } from '@angular/core';
import { ExtratoAnexoComponent } from './extrato-anexo.component';
import { SharedModule } from 'app/shared';
import { ExtratoAnexoCadastroModalComponent } from './cadastro-modal';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    ExtratoAnexoComponent,
    ExtratoAnexoCadastroModalComponent
  ]
})
export class ExtratoAnexoModule { }