import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { PlanoDeContasModule } from './../plano-de-contas/plano-de-contas.module';
import { ContasBancariasHttpService } from './services';
import { ExtratosComponent, ExtratosCadastroComponent, ExtratosHttpService, VisualizacaoExtratoComponent, TransferenciasCadastroComponent } from './extratos';
import { ConciliacaoBancariaComponent, ImportacaoExtratoComponent, ConciliacaoContaComponent, ConsultaConciliacaoComponent, ConciliacaoContaLancamentoModalComponent } from './conciliacao-bancaria';
import { ConfiguracaoTarifaBancariaModalComponent } from './configuracao-tarifa-bancaria-modal';
import { UiSwitchModule } from 'ngx-ui-switch';
import { FornecedoresModule } from '../fornecedores';
import { ExtratoAnexoHttpServiceService, ExtratoAnexoModule } from './extrato-anexo';
import { ContasBancariasComponent } from './contas-bancarias.component';
import { AutorizacaoContaBancariaModalComponent, ContasBancariasCadastroComponent } from './cadastro';
import { ContasBancariasCadastroModalComponent } from './cadastro-modal';
import { ConfiguracaoCobrancaModalComponent } from './configuracao-cobranca-modal';

@NgModule({
	imports: [
		SharedModule,
		PlanoDeContasModule,
		TypeaheadModule,
		UiSwitchModule,
		FornecedoresModule,
		ExtratoAnexoModule
	],
	declarations: [
		ContasBancariasComponent,
		ContasBancariasCadastroComponent,
		ContasBancariasCadastroModalComponent,
		TransferenciasCadastroComponent,
		ExtratosComponent,
		ExtratosCadastroComponent,
		VisualizacaoExtratoComponent,
		ConciliacaoBancariaComponent,
		ImportacaoExtratoComponent,
		ConciliacaoContaComponent,
		ConsultaConciliacaoComponent,
		ConfiguracaoTarifaBancariaModalComponent,
		ConciliacaoContaLancamentoModalComponent,
		ConfiguracaoCobrancaModalComponent,
		AutorizacaoContaBancariaModalComponent
	],
	exports: [
		ContasBancariasCadastroModalComponent
	],
	providers: [
		ContasBancariasHttpService,
		ExtratosHttpService,
		ExtratoAnexoHttpServiceService
	]
})

export class ContasBancariasModule { }