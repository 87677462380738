export class InadimplenciaRelFiltro {
	id_cliente: number;
	id_condominio: number;
	id_usuario: number;
	unidades: number[];
	id_bloco: number;
	tipoFiltro: number;
	dataCompetencia: Date;
	data_inicial: Date;
	data_final: Date;
	referencia: number;
	tipo_relatorio: string;
	tipo_dados: string;
	qtd_mes: number;

	constructor() {
		this.tipoFiltro = 1;
		this.qtd_mes = 1;
		this.tipo_relatorio = 'DETALHADO';
	}

	/** Limpa o filtro */
	limpar() {
		this.unidades = [];
		this.id_bloco = null;
		this.tipoFiltro = 1;
		const today = new Date();
		this.dataCompetencia = new Date(today.getFullYear(), today.getMonth() - 1, 1); // primeiro dia do mês passado
		this.data_inicial = new Date(today.getFullYear(), today.getMonth(), 1);
		this.data_final = new Date(today.getFullYear(), today.getMonth() + 1, -1);
		this.referencia = 1;
		this.tipo_dados = 'TODOS';
	}
}
