import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared';
import { LivroComponent } from './livro.component';
import { LivroConfiguracaoComponent, LivroConfiguracaoCadastroComponent, LivroConfiguracaoItemCadastroModalComponent } from './configuracao';
import { LivroCadastroModalComponent } from './cadastro-modal';
import { DocumentosModule } from '../documentos';
import { RelatorioHttpService } from '../relatorio';
import { EasyUIModule } from 'ng-easyui';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';

@NgModule({
  imports: [
    SharedModule,
    DocumentosModule,
    EasyUIModule,
    MultiselectDropdownModule
  ],
  declarations: [
    LivroComponent,
    LivroConfiguracaoComponent,
    LivroConfiguracaoCadastroComponent,
    LivroConfiguracaoItemCadastroModalComponent,
    LivroCadastroModalComponent
  ],
  providers: [
    RelatorioHttpService
  ]
})
export class LivroModule { }