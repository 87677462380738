import { BaseEntity } from "../base-entity";
import { Local } from "app/models";

export class LocalPolitica extends BaseEntity {
	id_local: number;
	politica: string;

	local: Local;

	constructor() {
		super();
	}
}