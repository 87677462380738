import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { LancamentosUnidadesComponent } from './lancamentos-unidades.component';
import { LancamentosUnidadesCadastroComponent } from './cadastro';
import { LancamentosUnidadesPagamentosComponent, LancamentosUnidadesPagamentosConsultaModalComponent, LancamentosUnidadesPagamentosDataCompetenciaService, LancamentosUnidadesPagamentosHttpService } from './pagamentos';
import { LancamentosUnidadesHttpService } from './services';
import { PlanoDeContasModule } from '../plano-de-contas';
import { FornecedoresModule } from '../fornecedores';
import { LancamentoPagamentosModule } from 'app/componentes-utils/lancamento-pagamentos';
import { BlocosHttpService, UnidadesHttpService } from '../blocos';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';

@NgModule({
	imports: [
		SharedModule,
		PlanoDeContasModule,
		FornecedoresModule,
		TypeaheadModule,
		LancamentoPagamentosModule,
    MultiselectDropdownModule
	],
	declarations: [
		LancamentosUnidadesComponent,
		LancamentosUnidadesCadastroComponent,
		LancamentosUnidadesPagamentosComponent,
		LancamentosUnidadesPagamentosConsultaModalComponent,
	],
	providers: [
		BlocosHttpService,
		UnidadesHttpService,
		LancamentosUnidadesHttpService,
		LancamentosUnidadesPagamentosDataCompetenciaService,
		LancamentosUnidadesPagamentosHttpService
	],
	exports: [
		LancamentosUnidadesPagamentosConsultaModalComponent,
	]
})
export class LancamentosUnidadesModule { }