import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { PagamentoUnidade } from '../../../models';
import { MessageService } from '../../../core';
import { LancamentosUnidadesPagamentosHttpService, LancamentosUnidadesPagamentosDataCompetenciaService } from './services';

import { datepickerMesAnoOptions } from '../../../shared';

declare var jQuery: any;

@Component({
	selector: 'lancamentos-unidades-pagamentos',
	templateUrl: './lancamentos-unidades-pagamentos.component.html',
	styleUrls: ['./lancamentos-unidades-pagamentos.component.css']
})
export class LancamentosUnidadesPagamentosComponent implements OnInit {

	@Input() entities: PagamentoUnidade[];
	@Input() allowDelete: boolean;

	@Output() onDeleteParcela: EventEmitter<any> = new EventEmitter();
	@Output() onChangeTotal: EventEmitter<number> = new EventEmitter();

	datepickerCompotenciaOptions = datepickerMesAnoOptions;

	constructor(private messageService: MessageService,
		private lancamentosUnidadesPagamentosHttpService: LancamentosUnidadesPagamentosHttpService,
		private lancamentosUnidadesPagamentoDataCompetenciaService: LancamentosUnidadesPagamentosDataCompetenciaService) { }

	ngOnInit() { }

	onChange(entity: PagamentoUnidade) {
		entity.editado = true;
	}

	// Ao alterar o mês competência criamos a data de competência (data completa com o dia sendo 01)
	onChangeDataCompetencia(entity: PagamentoUnidade) {
		setTimeout(() => {
			// Sempre setando o dia data de competência para o dia 1
			if (entity.data_competencia) {
				entity.data_competencia.setDate(1);
			}

			// Atribui a data de competência das demais parcelas somente quando informada a data da primeira
			if (entity.numero_parcela === 1 && entity.data_competencia) {
				this.lancamentosUnidadesPagamentoDataCompetenciaService.setProximasDatas(entity.data_competencia, this.entities);
				this.entities.forEach(pagamento => this.onChange(pagamento));
			}
		}, 200);
		this.onChange(entity);
	}

	permiteExcluirParcela(entity: PagamentoUnidade): boolean {
		if (entity.id_rateio_unidade !== null) {
			return false;
		}

		return true;
	}

	// Exclui a parcela da lista de parcelas em memória
	private deleteFromList(index: number): boolean {
		if (index > -1) {
			this.entities.splice(index, 1);

			setTimeout(() => {
				const tab = jQuery('.tab-parcelas:first > a');
				tab.click();
				const tabPane = jQuery('.tab-pane:first');
				tabPane.addClass('active');
			}, 100);
			return true;
		}
		return false;
	}

	deleteParcela(entity: PagamentoUnidade) {
		if (entity) {
			if (window.confirm(`Tem certeza que deseja excluir a ${entity.numero_parcela}ª parcela?`)) {
				// Se o pagamento possui ID é por que já esta salvo no BD
				if (entity.id) {
					this.lancamentosUnidadesPagamentosHttpService.delete(entity).subscribe(
						response => {
							this.messageService.success('', `A ${entity.numero_parcela}ª parcela foi excluida com sucesso`);
							const index = this.entities.findIndex(pagamento => pagamento.id === entity.id);
							this.deleteFromList(index);
							this.onDeleteParcela.emit();
						},
						error => this.messageService.error(`Erro ao excluir a parcela de número ${entity.numero_parcela}`, 'Erro', error)
					);
				} else {
					const index = this.entities.findIndex(pagamento => pagamento.numero_parcela === entity.numero_parcela);
					if (this.deleteFromList(index)) {
						this.messageService.success('', `A ${entity.numero_parcela}ª parcela foi excluida com sucesso`);
					}
				}
			}
		}
	}

}