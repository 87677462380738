import { Component, Input, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { MessageService } from 'app/core';
import { Banco } from 'app/models';
import { AttachmentComponent } from 'app/shared';
import { BancoHttpService } from '../service';

declare var jQuery: any;

@Component({
  selector: 'banco-cadastro-modal',
  templateUrl: './banco-cadastro-modal.component.html',
  styleUrls: ['./banco-cadastro-modal.component.css']
})
export class BancoCadastroModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @Input() id: number;
  @Output() onSave: EventEmitter<Banco> = new EventEmitter();

  @ViewChild('anexoComponent') anexoComponent: AttachmentComponent;

  public entity: Banco;

  constructor(private bancoHttpService: BancoHttpService,
      private messageService: MessageService) { }

  ngOnInit() {
    this.entity = new Banco();
  }

  public open() {
    this.entity = new Banco();
    if (this.id) {
      this.bancoHttpService.get(this.id).subscribe(
        response => {
          this.entity = response;
        },
        error => {
          this.messageService.error('Erro ao buscar dados do Banco! Tente novamente', 'Erro', error);
        }
      );
    }
    jQuery(`#${this.idModal}`).modal('show');
  }
  public close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Salvar banco
   * @author Marcos Frare
   * @since 18/03/2021
   */
  public salvar(): void {
    let btn = jQuery('#btnSaveBanco');
    btn.button('loading');
    const fn = this.entity.id ? this.bancoHttpService.put(this.entity) : this.bancoHttpService.post(this.entity);
    fn.subscribe(
      () => {
        btn.button('reset');
        this.messageService.success('Banco salvo com sucesso!');
        this.onSave.emit();
        this.close();
      },
      error => {
        btn.button('reset');
        this.messageService.error('Erro ao salvar banco! Tente novamente', 'Erro', error);
      }
    );
  }

}