import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService, AuthService } from 'app/core';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PrevisaoOrcamentaria } from 'app/models/entidades/previsao-orcamentaria';
import { BaseVisualizacaoComponent } from 'app/componentes-base';
import { PrevisaoOrcamentariaHttpService } from './service';
import { DatePipe } from '@angular/common';
import { FloatFormatPipe } from 'app/shared/pipes';
import { PrevisaoOrcamentariaFiltro } from './previsao-orcamentaria-filtro';
import { RelatorioModalComponent } from '../relatorios';

@Component({
	selector: 'previsao-orcamentaria',
	templateUrl: './previsao-orcamentaria.component.html',
	styleUrls: ['./previsao-orcamentaria.component.css'],
	providers: [PermissionService, DatePipe, FloatFormatPipe],
})
export class PrevisaoOrcamentariaComponent extends BaseVisualizacaoComponent<PrevisaoOrcamentaria> {

	@ViewChild('relatorioModal') relatorioModal: RelatorioModalComponent;

	public filtro = new PrevisaoOrcamentariaFiltro();

	constructor(public activatedRoute: ActivatedRoute,
		public router: Router,
		public messageService: MessageService,
		private authService: AuthService,
		public permissionService: PermissionService,
		private previsaoOrcamentariaHttpService: PrevisaoOrcamentariaHttpService,
		private floatFormatPipe: FloatFormatPipe,
		public spinner: NgxSpinnerService) {
		super('#tablePrevisaoOrcamentaria', previsaoOrcamentariaHttpService, activatedRoute, router, messageService, permissionService, spinner);
	}

	ngOnInit() {
		this.getPermissions();
		this.search();
	}

	/**
	 * Consultar orçamentos
	 * @author Marcos Frare
	 * @since 12/06/2020
	 */
	public search(): void {
		this.spinner.show();
		this.entities = [];
		this.previsaoOrcamentariaHttpService.getAll().subscribe(
			response => {
				this.entities = response;
				this.spinner.hide();
			},
			error => {
				this.spinner.hide();
				this.messageService.error('Erro ao realizar a consulta. Tente novamente', 'Erro', error);
			}
		);
	}

	/**
	 * Excluir previsão orçamentária
	 * @param entity 
	 * @author Marcos Frare
	 * @since 12/06/2020
	 */
	public delete(entity: PrevisaoOrcamentaria): void {
		if (confirm('Realmente deseja excluir esta Previsão Orçamentária?')) {
			this.spinner.show();
			this.previsaoOrcamentariaHttpService.delete(entity).subscribe(
				() => {
					this.spinner.hide();
					this.messageService.info('Previsão Orçamentária excluída com sucesso!');
					this.search();
				},
				error => {
					this.spinner.hide();
					this.messageService.error('Erro ao excluir Previsão Orçamentária!!', 'Erro!', error);
				}
			);
		}
	}

	/**
	 * Imprimir relatório em tela
	 * @param entity 
	 * @author Marcos Frare
	 * @since 12/08/2020
	 */
	public report(entity: PrevisaoOrcamentaria): void {
		const params = {
			...entity,
			id_cliente: this.authService.getIdCurrentCliente(),
			id_usuario: this.authService.getIdCurrentUser()
		}
		this.relatorioModal.url = '/previsaoorcamentaria/relatorio';
		this.relatorioModal.params = params;
		this.relatorioModal.open();
	}

}