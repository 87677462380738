import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import { AuthService, MessageService } from 'app/core';
import { BlocosHttpService, BlocosPesquisaModalComponent, UnidadesHttpService } from 'app/home/blocos';
import { Bloco, tipos, tiposEnvioBoleto, Unidade } from 'app/models';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { RelatorioModalComponent } from '../relatorio-modal';
import { ProtocoloRelFiltro } from './protocolo-rel-filtro';

declare var jQuery: any;

@Component({
  selector: 'protocolo-rel',
  templateUrl: './protocolo-rel.component.html',
  styleUrls: ['./protocolo-rel.component.css']
})
export class ProtocoloRelComponent implements OnInit {

  @ViewChild('relatorioModal') relatorioModal: RelatorioModalComponent;
  @ViewChild('inputBloco') inputBloco: ElementRef;
	@ViewChild('blocosPesquisaModal') blocosPesquisaModal: BlocosPesquisaModalComponent;

  public filtro: ProtocoloRelFiltro = new ProtocoloRelFiltro();
  public tiposMoradores = tipos;
  public tiposEnvioBoleto = tiposEnvioBoleto;
  public showInputBlocos: boolean = true;

	public blocos: Bloco[] = [];
  public unidades: Unidade[] = [];

	public arSituacaoFinanceira: any[] = [];

  multiselectSettings: IMultiSelectSettings = {
		enableSearch: true,
		containerClasses: 'container-multiselect',
		showUncheckAll: true,
		showCheckAll: true,
		dynamicTitleMaxItems: 3,
		checkedStyle: 'checkboxes',
		buttonClasses: 'btn btn-default',
		itemClasses: 'dropdown-menu'
	};

	multiselectOptionsUnidades: IMultiSelectOption[];
	multiselectTextsUnidades: IMultiSelectTexts = {
		checkAll: 'Selecionar todas',
		uncheckAll: 'Limpar',
		checked: 'selecionada',
		checkedPlural: 'selecionadas',
		searchPlaceholder: 'Pesquisar...',
		defaultTitle: 'Selecionar',
		allSelected: 'Todas selecionadas',
		searchEmptyResult: 'Nenhum registro encontrado',
		searchNoRenderText: 'Digite para pesquisar',
	};

  constructor(private authService: AuthService,
    private messageService: MessageService,
    private unidadesHttpService: UnidadesHttpService,
		private blocosHttpService: BlocosHttpService) { }

  ngOnInit() {
		this.arSituacaoFinanceira = [
			{ tag: 'TODOS', label: 'Todos'},
			{ tag: 'ADIMPLENTE', label: 'Adimplentes'},
			{ tag: 'INADIMPLENTE', label: 'Inadimplentes'},
		]
    this.filtro.id_cliente = this.authService.getIdCurrentCliente();
    this.filtro.id_condominio = this.authService.getIdCurrentCondominio();
    this.filtro.id_usuario = this.authService.getIdCurrentUser();
    this.limpar();
    this.getBlocosByCondominio(this.authService.getIdCurrentCondominio());
    this.getAllUnidades();
  }

  public limpar(): void {
    this.filtro.limpar();
  }

  /**
   * Busca os blocos do condomínio
   * @param idCondominio 
   * @author Marcos Frare
   * @since 21/04/2021
   */
  private getBlocosByCondominio(idCondominio: number) {
		this.blocosHttpService.getAllByCondominio(idCondominio).subscribe(
			entitites => {
				this.blocos = entitites
				if (this.blocoUnico(this.blocos)) {
					this.setBloco(this.blocos[0]);
					this.showInputBlocos = false;
				} else if (!this.blocos) {
					this.messageService.error('Não existem blocos cadastrados no Condomínio. Cadastre  um bloco e tente novamente', 'Erro')
				}
			},
			error => this.messageService.error('Erro ao carregar as unidades do condomínio. Tente novamente', 'Erro', error)
		);
	}

	private setBloco(blocoSelecionado: Bloco) {
		this.filtro.bloco = blocoSelecionado;
		this.filtro.id_bloco = this.filtro.bloco.id;
		this.filterUnidades();
		this.onCleanUnidades();
	}

	/**
	 * Busca todas as unidades do codomínio
	 * @author Marcos Frare
	 * @since 24/04/2020
	 */
	private getAllUnidades(): void {
		this.unidadesHttpService.getAllByCondominioBloco(this.filtro.id_condominio, null).subscribe(
			response => {
				this.unidades = response;
				this.filterUnidades();
			},
			error => this.messageService.error('Erro ao carregar as unidades do condomínio. Tente novamente', 'Erro', error)
		);
	}

	/**
	 * Filtra as unidades com base no campo de bloco
	 * @author Marcos Frare
	 * @since 24/04/2020
	 */
	private filterUnidades(): void {
		const selects: IMultiSelectOption[] = [];
		let arr = this.unidades;
		if (this.filtro.bloco && this.filtro.bloco.id) {
			arr = this.unidades.filter(und => und.id_bloco === this.filtro.bloco.id);
		}
		arr.forEach(entity => {
			selects.push({
				id: entity.id,
				name: `${entity.nome_bloco} - ${entity.nome_unidade}`
			});
		});
		this.multiselectOptionsUnidades = selects;
	}

	public onCleanUnidades() {
		this.filtro.unidades = [];
		this.filterUnidades();
	}

	public onSelectBloco(tm: TypeaheadMatch): void {
		this.setBloco(tm.item as Bloco);
	}

	public openBlocosPequisaModal() {
		this.blocosPesquisaModal.open();
	}

	public onSelectBlocosPesquisaModal(bloco: Bloco) {
		if (bloco) {
			this.setBloco(bloco)
		}
		jQuery('#bloco').focus();
	}

	public onCleanBloco(input: any) {
		this.filtro.bloco = null;
		this.onCleanUnidades();
		input.value = '';
		input.focus();
	}

	private blocoUnico(blocos: Bloco[]): boolean {
		return blocos.length === 1;
	}

  /**
   * Abir relatório
   * @author Marcos Frare
   * @since 26/02/2021
   */
  public view(): void {
    this.relatorioModal.url = '/relatorio/protocolo';
    this.relatorioModal.params = this.filtro;
    this.relatorioModal.open();
  }
}