import { Telefone } from './../../../models/entidades/telefone';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';

import { Crud, CrudService, ConfigService, CustomHttp } from '../../../core';

import { Fornecedor } from '../../../models';

@Injectable()
export class FornecedoresHttpService implements Crud<Fornecedor> {
	resource = 'fornecedor';
	baseUrl: string;

	constructor(private crudService: CrudService,
		private configService: ConfigService,
		private customHttp: CustomHttp) {
		this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
	}

	get(id: number): Observable<Fornecedor> {
		return this.crudService.get<Fornecedor>(this.resource, id);
	}

	getAll(): Observable<Fornecedor[]> {
		return this.crudService.getAll<Fornecedor>(this.resource);
	}

	getAllByCliente(idCliente: number): Observable<Fornecedor[]> {
		return this.customHttp.get(`${this.baseUrl}/consultar/cliente/${idCliente}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	post(entity: Fornecedor): Observable<Fornecedor> {
		if (!entity.telefones) entity.telefones = [];
		return this.crudService.post<Fornecedor>(this.resource, entity);
	}

	put(entity: Fornecedor): Observable<Fornecedor> {
		return this.crudService.put<Fornecedor>(this.resource, entity);
	}

	delete(entity: Fornecedor): Observable<string> {
		return this.crudService.delete<Fornecedor>(this.resource, entity);
	}

	deleteTelefone(entity: Telefone): Observable<string> {
		return this.crudService.delete<Telefone>('telefone', entity);
	}

}