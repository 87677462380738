import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cpfFormat'
})
export class CpfFormatPipe implements PipeTransform {

    transform(value: any) {
        let rawValue = this.clearDigitsSpecialChars(value);
        let newValue = rawValue.substr(0, 3);

        if (rawValue.length > 3)
            newValue  += '.' + rawValue.substr(3, 3);

        if (rawValue.length > 6) 
            newValue  += '.' + rawValue.substr(6, 3);

        if (rawValue.length > 9) 
            newValue += '-' + rawValue.substr(9, 2);

        return newValue;
    }

    clearDigitsSpecialChars(value) {
        if (value) {
            const anyNonDigitRegExp = /[^0-9]/g
            return value.toString().replace(anyNonDigitRegExp, '')
        }
        return "";
    }

}
