import { Component, OnInit, ViewChild } from '@angular/core';
import { Livro } from 'app/models';
import { BaseVisualizacaoComponent } from 'app/componentes-base';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'app/core';
import { LivroHttpService } from './service';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LivroCadastroModalComponent } from './cadastro-modal';
import { RelatorioModalComponent } from '../relatorios';

@Component({
  selector: 'livro',
  templateUrl: './livro.component.html',
  styleUrls: ['./livro.component.css'],
  providers: [PermissionService]
})
export class LivroComponent extends BaseVisualizacaoComponent<Livro> implements OnInit {
  
  @ViewChild('livroCadastroModal') livroCadastroModal: LivroCadastroModalComponent;
  @ViewChild('relatorioModal') relatorioModal: RelatorioModalComponent;

  constructor(public activatedRoute: ActivatedRoute,
      public router: Router,
      public messageService: MessageService,
      private livroHttpService: LivroHttpService,
      public permissionService: PermissionService,
      public spinner: NgxSpinnerService) {
    super('#tableLivro', livroHttpService, activatedRoute, router, messageService, permissionService, spinner);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  /**
   * Atualizar dados da tabela
   * @author marcos Frare
   * @since 23/07/2020
   */
  public loadTable(): void {
    // this.destroyTable();
    this.getAll();
  }

  /**
   * Incluir novo livro de prestação de contas
   * @author Marcos Frare
   * @since 22/07/2020
   */
  public adicionar(): void {
    this.livroCadastroModal.open();
  }

  /**
   * Exclusão do livro
   * @param livro 
   * @author Marcos Frare
   * @since 23/07/2020
   */
  public delete(livro: Livro): void {
    if (confirm(`Tem certeza que deseja excluir ${livro.descricao}?`)) {
      this.livroHttpService.delete(livro).subscribe(
        () => {
          this.messageService.success('', `Livro excluído com sucesso!`);
          // this.destroyTable();
          this.getAll();
        },
        error => {
          switch (error.status) {
            case 404:
              this.messageService.warning(`Livro não encontrado, atualize a consulta e tente novamente.`, 'Atenção', error);
              break;
            case 500:
              this.messageService.error(`Erro ao excluir o livro. Tente novamente`, 'Erro', error);
              break;
            default:
              break;
          }
        });
    }
  }

  /**
   * Carrega o PDF do livro em tela
   * @param livro 
   * @author Marcos Frare
   * @since 23/07/2020
   */
  public view(livro: Livro): void {
    this.relatorioModal.url = '/livro/emissao';
    this.relatorioModal.params = livro;
    this.relatorioModal.open();
  }

}