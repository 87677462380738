import { Component, Input, OnInit } from '@angular/core';

import { MessageService } from '../../../core';
import { TiposConsumoTarifasHttpService } from './services';
import { TarifasConsumo, CategoriaTarifaConsumo } from '../../../models';

declare var jQuery: any;

@Component({
    selector: 'tipos-consumo-tarifas',
    templateUrl: './tipos-consumo-tarifas.component.html',
    styleUrls: ['./tipos-consumo-tarifas.component.css']
})
export class TiposConsumoTarifasComponent implements OnInit {

    @Input() idTipoConsumo: number;
    @Input() unidadeMedida: string;
    @Input() categoriaTarifaConsumo: CategoriaTarifaConsumo;

    public consumoFinalAnterior: number;

    constructor(private tiposConsumoTarifasHttpService: TiposConsumoTarifasHttpService,
                private messageService: MessageService) {}

    ngOnInit() { }

    create() {
        const entity = new TarifasConsumo();
        entity.id_tipo_consumo = this.categoriaTarifaConsumo.id_tipo_consumo || null;
        entity.id_categoria_tarifa_consumo = this.categoriaTarifaConsumo.id || null;
        entity.editado = true;
        entity.valor = 0;
        entity.consumo_inicial = 0;
        entity.consumo_final = 0;
        entity.consumo_minimo = 0;
        if (this.categoriaTarifaConsumo.tarifas) {
            if (this.categoriaTarifaConsumo.tarifas.length > 0) {
                const lastEntity = this.categoriaTarifaConsumo.tarifas[this.categoriaTarifaConsumo.tarifas.length - 1];
                entity.consumo_inicial = Number(lastEntity.consumo_final) + 0.001;
                entity.consumo_minimo = (Number(lastEntity.consumo_final) - 
                                                Number(lastEntity.consumo_inicial)) * 
                                                Number(lastEntity.valor) + 
                                                Number(lastEntity.consumo_minimo);
            }
        } else {
            this.categoriaTarifaConsumo.tarifas = [];
        }
        this.categoriaTarifaConsumo.tarifas.push(entity);

        setTimeout(() => {
            jQuery(`#consumoInicial_${this.categoriaTarifaConsumo.tarifas.length - 1}`).focus();
        }, 100);
    }

    delete(entity: TarifasConsumo) {
        if (window.confirm('Deseja realmente remover essa tarifa?')) {
            if (entity.id) {
                this.tiposConsumoTarifasHttpService.delete(entity)
                    .subscribe(
                        () => {
                            const index = this.categoriaTarifaConsumo.tarifas.findIndex(tarifaConsumo => tarifaConsumo.id === entity.id);
                            this.deleteFromList(index);
                            this.messageService.success('', 'Tarifa excluida com sucesso');
                        },
                        error => this.messageService.error('Erro ao deletar a tarifa. Tente novamente', 'Erro', error)
                    );
            } else {
                const index = this.categoriaTarifaConsumo.tarifas.indexOf(entity);
                if (this.deleteFromList(index)) {
                    this.messageService.success('', 'Tarifa excluida com sucesso');
                }
            }
        }
    }

    private deleteFromList(index: number): boolean {
        if (index  > -1) {
            this.categoriaTarifaConsumo.tarifas.splice(index, 1);
            return true;
        }
        return false;
    }

    possuiAlgumaTarifaCadastrada(): boolean {
        return this.categoriaTarifaConsumo.tarifas && this.categoriaTarifaConsumo.tarifas.length > 0;
    }

    onChange(entity: TarifasConsumo) {
        entity.editado = true;
    }
}