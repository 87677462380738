import { Component, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { MessageService } from '../../../../core';
import { RateiosUnidadesHttpService } from '../services';
import { RateioUnidadeLancamento } from './rateio-unidade-lancamento';

interface BlocoLancamento {
	id_bloco: string;
	andares: AndarLancamento[];
}

interface AndarLancamento {
	andar: string;
	lancamentos: RateioUnidadeLancamento[];
};

@Component({
	selector: 'rateios-unidades-lancamento',
	templateUrl: './rateios-unidades-lancamento.component.html',
	styleUrls: ['./rateios-unidades-lancamento.component.css']
})
export class RateiosUnidadesLancamentoComponent implements OnChanges, OnInit {

	@Input() idDemonstrativoRateio: number;
	@Input() idBlocoAtual: number;
	@Input() entities: RateioUnidadeLancamento[] = [];
	public isLoading = false;
	public blocos: BlocoLancamento[] = [];
	public blocoAtual: BlocoLancamento;

	constructor(private messageService: MessageService,
		private rateiosUnidadesHttpService: RateiosUnidadesHttpService) { }

	ngOnInit() { }

	ngOnChanges(changes: SimpleChanges) {
		for (const propName in changes) {
			if (propName === 'entities' && this.entities) {
				this.montaRateiosUnidade();
			} else if (propName === 'idBlocoAtual') {
				const propValue = changes[propName].currentValue;
				const idBloco = propValue && !isNaN(propValue) ? Number(propValue) : null;
				this.selecionarBlocoAtual(idBloco);
			}
		}
	}

	private montaRateiosUnidade(): void {
		this.isLoading = true;
		this.agruparBlocos();
		this.selecionarBlocoAtual(this.idBlocoAtual);
		this.isLoading = false;
	}

	private selecionarBlocoAtual(idBloco: number): void {
		if (idBloco == null || !this.blocos) {
			this.blocoAtual = null;
			return;
		}
		this.blocoAtual = this.blocos.find(bloco => bloco.id_bloco === idBloco.toString());
	}

	private agruparBlocos(): void {
		const groupBlocos = this.entities.reduce((obj, item) => {
			obj[item.unidade.id_bloco] = obj[item.unidade.id_bloco] || [];
			obj[item.unidade.id_bloco].push(item);
			return obj;
		}, {});

		this.blocos = Object.keys(groupBlocos).map(idBloco => {
			const andares = this.agruparAndaresPorBloco(groupBlocos[idBloco]);
			return { id_bloco: idBloco, andares: andares };
		});
	}

	private agruparAndaresPorBloco(bloco: any): any {
		const groupAndares = bloco.reduce((obj, item) => {
			obj[item.unidade.andar] = obj[item.unidade.andar] || [];
			obj[item.unidade.andar].push(item);
			return obj;
		}, {});

		return Object.keys(groupAndares).map(andar => {
			return { andar: andar, lancamentos: groupAndares[andar] };
		});
	}

	public cadastrar(): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.rateiosUnidadesHttpService.cadastrar(this.entities).subscribe(
				() => resolve(),
				error => reject(error)
			);
		});
	}

	public editar(): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.rateiosUnidadesHttpService.editar(this.idDemonstrativoRateio, this.entities).subscribe(
				() => resolve(),
				error => reject(error)
			);
		});
	}

	public compare(a: any, b: any): number {
		if (a.mes_competencia < b.mes_competencia) { return -1; }
		if (a.mes_competencia > b.mes_competencia) { return 1; }
		return 0;
	}

}
