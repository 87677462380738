export class HistoricoMovimentacaoMensalRelFiltro {
  id_cliente: number;
  id_condominio: number;
  id_usuario: number;
  competencia_inicial: Date;
  competencia_final: Date;

  /** Limpa o filtro */
  limpar() {
    const today = new Date();
    // Como padrão, os últimos 12 meses
    this.competencia_inicial = new Date(today.getFullYear(), today.getMonth()-12, 1);
    this.competencia_final = new Date(today.getFullYear(), today.getMonth(), 0);
  }

}