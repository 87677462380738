import { BaseEntity } from '../base-entity';

export class PagamentoEspecifico extends BaseEntity {
	valor: any;
	numero_parcela: number;
	data_competencia: Date;
	id_lancamento_especifico: number;
	id_demonstrativo_rateio: number;
	tipo_divisao_rateio: number;
	cobrar_tipo_unidade: number;
}