import { Component, Input, OnInit } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { MessageService } from 'app/core';

declare var jQuery: any;

@Component({
  selector: 'retorno-envio-integracao-modal',
  templateUrl: './retorno-envio-integracao-modal.component.html',
  styleUrls: ['./retorno-envio-integracao-modal.component.css']
})
export class RetornoEnvioIntegracaoModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @Input() boletos: any[] = [];

  constructor() { }
  
  ngOnInit() {
    if (!this.idModal) {
      this.idModal = 'retornoEnvioIntegracaoModal';
    }
  }

  open() {
    jQuery(`#${this.idModal}`).modal('show');
  }

  close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

}