import { Pipe, PipeTransform } from '@angular/core';

import { tiposComunicacao } from '../../../models';

@Pipe({
    name: 'tipoComunicacao'
})
export class TipoComunicacaoPipe implements PipeTransform {
    transform(value: number): string {
        if (!value) {
            return '';
        }

        const index = tiposComunicacao.findIndex(tipo => tipo.id === value);
        if (index > -1) {
            return tiposComunicacao[index].nome;
        }
        return '';
    }
}
