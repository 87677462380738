import { map, catchError } from 'rxjs/operators';

import { Observable ,  forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';

import { MessageService } from '../../../../core';
import { TarifasConsumo } from '../../../../models';
import { TiposConsumoHistoricoHttpService } from '../../historico';
import { TiposConsumoTarifasHttpService } from './tipos-consumo-tarifas-http.service';

/**
 * Service com métodos para salvar as tarifas de um tipo de consumo
 */
@Injectable()
export class TiposConsumoTarifasCadastroService {

    constructor(private tiposConsumoTarifasHttpService: TiposConsumoTarifasHttpService,
                private tiposConsumoHistoricoHttpService: TiposConsumoHistoricoHttpService,
                private messageService: MessageService) { }

    /**
     * Valida o salvamento das tarifas do tipo de consumo
     */
     validaSave(entities: TarifasConsumo[]): boolean {
        if (!entities || entities.length === 0) {
            return true;
        }
        for (let i = 0; i < entities.length; i++) {
            let tarifaConsumo = entities[i];
            if (tarifaConsumo.consumo_inicial == null) {
                this.messageService.info('É necessário informar o consumo inicial da tarifa para salvar', 'Informação');
                return false;
            }
            if (tarifaConsumo.consumo_final == null) {
                this.messageService.info('É necessário informar o consumo final da tarifa para salvar', 'Informação');
                return false;
            }
            if (tarifaConsumo.valor == null) {
                this.messageService.info('É necessário informar o valor da tarifa para salvar', 'Informação');
                return false;
            }
            if (Number(tarifaConsumo.consumo_inicial) < 0) {
                this.messageService.info('É necessário informar um consumo inicial maior do que 0 (zero) para salvar', 'Informação');
                return false;
            }
            if (Number(tarifaConsumo.consumo_final) < 0) {
                this.messageService.info('É necessário informar um consumo final maior do que 0 (zero) para salvar', 'Informação');
                return false;
            }
            if (Number(tarifaConsumo.consumo_inicial) > Number(tarifaConsumo.consumo_final)) {
                this.messageService.info(`O consumo inicial (${tarifaConsumo.consumo_inicial}) não pode ser maior do que o consumo final (${tarifaConsumo.consumo_final})`, 'Informação');
                return false;
            }
        }
        return true;
    }

    /**
     * Cria os observables das tarifas editadas do tipo de consumo para salva-las simultaneamente
     */
    private createSave(idTipoConsumo: number, entities: TarifasConsumo[], tarifaConsumoDefault: TarifasConsumo[]): Observable<TarifasConsumo>[] {
        if (!entities) {
            return [];
        }
        let saves: Observable<TarifasConsumo>[] = [];
        entities.forEach(tarifaConsumo => {
            if (tarifaConsumo.editado) {
                if (!tarifaConsumo.id_tipo_consumo) {
                    tarifaConsumo.id_tipo_consumo = idTipoConsumo;
                }

                let observable: Observable<TarifasConsumo>;
                if (tarifaConsumo.id) { // PUT
                    const tarifaDefault = tarifaConsumoDefault.find(it => it.id === tarifaConsumo.id);
                    
                    if(tarifaDefault) {
                        const historicoTarifas: any = {
                            id_tarifa_consumo: tarifaConsumo.id,
                            consumo_inicial_de: tarifaDefault.consumo_inicial,
                            consumo_final_de: tarifaDefault.consumo_final,
                            valor_de: tarifaDefault.valor,
                            id_tipo_consumo: tarifaDefault.id_tipo_consumo,
                            consumo_minimo_de: tarifaDefault.consumo_minimo,
                            consumo_inicial_para: tarifaConsumo.consumo_inicial,
                            consumo_final_para: tarifaConsumo.consumo_final,
                            valor_para: tarifaConsumo.valor,
                            consumo_minimo_para: tarifaConsumo.consumo_minimo,
                        }
                        this.tiposConsumoHistoricoHttpService.post(historicoTarifas).subscribe(response => response)
                    }

                    observable = this.tiposConsumoTarifasHttpService.put(tarifaConsumo)
                                    .pipe(
                                        map(response => response),
                                        catchError(error => {
                                            this.messageService.error(`Preencha corretamente todos os campos marcados em vermelho da tarifa com consumo inicial de ${tarifaConsumo.consumo_inicial} e final de ${tarifaConsumo.consumo_final}. Tente novamente`, 'Erro', error);
                                            return Observable.throw(error);
                                        })
                                    );
                } else { // POST
                    observable = this.tiposConsumoTarifasHttpService.post(tarifaConsumo)
                                    .pipe(
                                        map(response => response),
                                        catchError(error => {
                                            this.messageService.error(`Preencha corretamente todos os campos marcados em vermelho da tarifa com consumo inicial de ${tarifaConsumo.consumo_inicial} e final de ${tarifaConsumo.consumo_final}. Tente novamente`, 'Erro', error);
                                            return Observable.throw(error);
                                        })
                                    );
                }
                saves.push(observable);
            }
        });
        return saves;
    }

    /**
     * Salva as tarifas do tipo de consumo informado
     */
    save(idTipoConsumo: number, entities: TarifasConsumo[], tarifaConsumoDefault: TarifasConsumo[]): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const savesTarifas = this.createSave(idTipoConsumo, entities, tarifaConsumoDefault);
            if (savesTarifas.length > 0) {
                // Salvando simultameamente as tarifas editadas
                forkJoin(savesTarifas)
                        .subscribe(
                            () => resolve(),
                            error => reject(error)
                        );
            } else {
                resolve();
            }
        });
    }

}
