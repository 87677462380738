import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { _throw } from 'rxjs/observable/throw';
import { CustomHttp, ConfigService } from '../../../core';
import { TitulosVencidosEnvio } from './titulos-vencidos-envio';

@Injectable()
export class DashboardHttpService {
	resource = 'dashboard';
	baseUrl: string;

	constructor(private customHttp: CustomHttp,
		private configService: ConfigService) {
		this.baseUrl = `${this.configService.getBaseUrl()}/${this.resource}`;
	}

	public getTitulosVencidos(titulosVencidosEnvio: TitulosVencidosEnvio): Observable<any> {
		return this.customHttp.post(`${this.baseUrl}/total-titulos-vencidos`, titulosVencidosEnvio).pipe(
			map(response => response.json()),
			catchError(error => Observable.throw(error))
		);
	}

	/**
	 * Busca as contas à pagar para os condomínios
	 * @author Marcos Frare
	 * @since 02/06/2020
	 */
	public getContasAPagar(filtro: any): Observable<any> {
		return this.customHttp.post(`${this.baseUrl}/contas-a-pagar`, filtro).pipe(
			map(response => response.json()),
			catchError(error => Observable.throw(error))
		);
	}
}