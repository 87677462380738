import { Component, AfterViewInit, EventEmitter, ElementRef, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';

import { datepickerDefaultOptions } from './datepicker-default-options';

declare var jQuery: any;

/** Component para utilização de campos do tipo data com base na biblioteca bootstrap-datepicker
 * Mais informações: https://bootstrap-datepicker.readthedocs.io/en/latest/index.html
 */
@Component({
	// tslint:disable-next-line:component-selector
	selector: 'datepicker',
	templateUrl: './datepicker.component.html',
	styleUrls: ['./datepicker.component.css'],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DatepickerComponent), multi: true }
	]
})
export class DatepickerComponent implements AfterViewInit, ControlValueAccessor {
	@Input() ngModel: any;
	@Output() ngModelChange = new EventEmitter<Date>();

	@Input() options: any; // Opções personalizadas para o datepicker
	@Input() required: any;
	@Input() disabled: any;
	@Input() readonly: any;
	@Input() ngControl: NgControl;
	@Input() autofocus = false;
	@Input() inputId: string = "";
	@Output() change = new EventEmitter<Date>();

	@ViewChild('inputDate') inputDate: ElementRef;
	datepicker: any;
	propagateChange: any = () => { };


	ngAfterViewInit() {
		if (!this.options) {
			this.options = datepickerDefaultOptions;
		}

		// Adiconando a máscara e placeholder da data
		jQuery(this.inputDate.nativeElement).mask(this.options.mask);
		this.inputDate.nativeElement.placeholder = this.options.placeholder;

		// Adicionando o bootstrap-datepicker
		this.datepicker = jQuery(this.inputDate.nativeElement)
			.datepicker(this.options)
			.on('changeDate', (e) => this.onChangeDate(e));

		if (this.autofocus) {
			jQuery(this.inputDate.nativeElement).focus();
		}
	}

	/** Function que detecta quando é alterado a data no bootstrap-datepicker */
	private onChangeDate(e: any) {
		const date = e.date as Date;
		this.propagateChange(date);
		this.change.emit(date);
	}


	/** Exibe o calendário do datepicker */
	show() {
		this.datepicker.datepicker('show');
	}

	onBlur(value: string) {
		// Se o valor do input for vazio, entãos setamos o valor do ngModel para null
		if (!value || value.trim().length === 0) {
			this.propagateChange(null);
		}
	}

	/** Quando recebe um valor */
	writeValue(value) {
		if (value) {
			let date = value;
			if (typeof value === 'string') {
				const valueSplit = value.split(' ');
				date = valueSplit.length === 1 ? value + ' 00:00:00' : value;
			}
			this.datepicker.datepicker('update', new Date(date));
		} else {
			this.inputDate.nativeElement.value = '';
		}
	}

	/** Propaga as alterações realizadas no datepicker */
	registerOnChange(fn) {
		this.propagateChange = fn;
	}

	registerOnTouched() { }
}