import { Component, OnInit, ViewChild } from '@angular/core';
import { RelatorioModalComponent } from '../relatorio-modal';
import { AuthService, MessageService } from 'app/core';
import { RazaoRelFiltro } from './razao-rel-filtro';
import { datepickerMesAnoOptions } from 'app/shared';

@Component({
  selector: 'razao-rel',
  templateUrl: './razao-rel.component.html',
  styleUrls: ['./razao-rel.component.css']
})
export class RazaoRelComponent implements OnInit {

  @ViewChild('relatorioModal') relatorioModal: RelatorioModalComponent;

  public datepickerDataCompetenciaOptions = datepickerMesAnoOptions;
  public filtro = new RazaoRelFiltro();

  constructor(private authService: AuthService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.limpar();
    this.filtro.id_cliente = this.authService.getIdCurrentCliente();
    this.filtro.id_condominio = this.authService.getIdCurrentCondominio();
    this.filtro.id_usuario = this.authService.getIdCurrentUser();
    this.filtro.tipoFiltro = 1;
  }

  private validateView(): boolean {
    if (!this.filtro.tipoFiltro) {
      this.messageService.info('É necessário informar o tipo de período', 'Informação');
      return false;
    } else {
      if (this.filtro.tipoFiltro === 1) {
        if (!this.filtro.competencia_inicial) {
          this.messageService.info('É necessário informar a competência', 'Informação');
          return false;
        }
      } else {
        if (!this.filtro.data_inicial) {
          this.messageService.info('É necessário informar a data inicial para imprimir', 'Informação');
          return false;
        }
        if (!this.filtro.data_final) {
          this.messageService.info('É necessário informar a data final para imprimir', 'Informação');
          return false;
        }
      }
    }
    return true;
  }

  public view(): void {
    if (this.validateView()) {
      this.relatorioModal.url = '/relatorio/razao';
      this.relatorioModal.params = this.filtro;
      this.relatorioModal.open();
    }
  }

  public limpar(): void {
    this.filtro.limpar();
  }

}
