import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseVisualizacaoComponent } from '../../componentes-base';
import { LancamentoEspecifico, CategoriaContas, tipoRateio, SITUACAO_RATEIO } from '../../models';
import { AuthService, MessageService } from '../../core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { CategoriaContasPesquisaModalComponent } from '../plano-de-contas/categoria-contas';
import { CategoriaContasHttpService } from '../plano-de-contas/categoria-contas';
import { LancamentosEspecificosHttpService } from './services';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Popover } from 'ngx-popover';
import { GeracaoLancamentoEspecificoComponent } from './template-lancamento-especifico';

declare var jQuery: any;

@Component({
	selector: 'lancamentos-especificos',
	templateUrl: './lancamentos-especificos.component.html',
	styleUrls: ['./lancamentos-especificos.component.css'],
	providers: [PermissionService]
})
export class LancamentosEspecificosComponent extends BaseVisualizacaoComponent<LancamentoEspecifico>{

	@ViewChild('categoriaContasPesquisaModal') categoriaContasPesquisaModal: CategoriaContasPesquisaModalComponent;
	@ViewChild('geracaoLancamentoEspecifico') geracaoLancamentoEspecifico: GeracaoLancamentoEspecificoComponent;

	public filtro: any = {};
	public categoriasContas: CategoriaContas[] = [];
	public tiposRateio = tipoRateio;
	public idCliente: number;
	public idCondominio: number;
	public total: number = 0;
	public popover: Popover;

	constructor(public activatedRoute: ActivatedRoute,
		public router: Router,
		public messageService: MessageService,
		public permissionService: PermissionService,
		private authService: AuthService,
		private categoriaContasHttpService: CategoriaContasHttpService,
		private lancamentosEspecificosHttpService: LancamentosEspecificosHttpService,
		public spinner: NgxSpinnerService) {
		super('#tableLancamentosEspecificos', lancamentosEspecificosHttpService, activatedRoute, router, messageService, permissionService, spinner);
	}

	ngOnInit() {
		this.idCondominio = this.authService.getIdCurrentCondominio();
		this.idCliente = this.authService.getIdCurrentCliente();
		this.getAllCategoriasByCondominio();
		this.setInitialFilter();
		this.search();
		this.getPermissions();
	}

	public getAll(): void {
		this.spinner.show();
		this.entities = [];
		this.filtro.situacao_rateio = SITUACAO_RATEIO.TODOS;
		this.destroyTable();
		this.lancamentosEspecificosHttpService.getAllByCondominio(this.idCondominio).subscribe(
			response => {
				this.spinner.hide();
				this.entities = response.lancamentos;
				this.total = response.total;
				this.showTable();
			},
			error => {
				this.spinner.hide();
				this.messageService.error('Erro ao carregar os lançamentos específicos. Tente novamente', 'Erro', error)
			}
		);
	}

	public search(): void {
		this.spinner.show();
		this.entities = [];
		this.destroyTable();
		this.lancamentosEspecificosHttpService.search(this.filtro).subscribe(
			response => {
				this.spinner.hide();
				this.entities = response.lancamentos;
				this.total = response.total;
				this.showTable();
			},
			error => {
				this.spinner.hide();
				this.messageService.error('Erro ao carregar os lançamentos específicos. Tente novamente', 'Erro', error)
			}
		);
	}

	public setInitialFilter(): void {
		const today = new Date();
		const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
		this.filtro.id_condominio = this.idCondominio;
		this.filtro.data_competencia_final = lastDay;
		this.filtro.situacao_rateio = SITUACAO_RATEIO.NAO_PROCESSADO;
	}

	/** Carrega as categorias de contas do plano de contas cadastrado na config. do condomínio */
	private getAllCategoriasByCondominio() {
		this.categoriasContas = [];
		if (this.idCondominio) {
			this.categoriaContasHttpService.getAllByCondominio(this.idCondominio, 1)
				.then(entities => this.categoriasContas = entities)
				.catch(() => this.messageService.info(`Configuração do condomínio não encontrada. Configure o condomínio para prosseguir.`, 'Informação'));
		}
	}

	// Ao selecionar a categoria de contas
	onSelectCategoriaContas(tm: TypeaheadMatch) {
		const categoriaContas: CategoriaContas = tm.item as CategoriaContas;
		if (categoriaContas) {
			this.filtro.id_categoria_conta = categoriaContas.id;
			this.filtro.categoriaContas = categoriaContas;
		} else {
			this.filtro.id_categoria_conta = null;
			this.filtro.categoriaContas = null;
		}
	}

	// Ao limpar a categoria de contas
	onCleanCategoriaContas(input: any) {
		this.filtro.id_categoria_conta = null;
		this.filtro.categoriaContas = null;

		input.value = '';
		input.focus();
	}

	// Abre o modal de pesquisa da categoria de conta
	openCategoriaContasPequisaModal() {
		this.categoriaContasPesquisaModal.open();
	}

	// Ao selecionar a categoria de conta no modal de pesquisa
	onSelectCategoriaContasPesquisaModal(categoriaContas: CategoriaContas) {
		if (categoriaContas) {
			this.filtro.id_categoria_conta = categoriaContas.id;
			this.filtro.categoriaContas = categoriaContas;
		}
		jQuery('#categoriaContas').focus();
	}

	public delete(lancamento: LancamentoEspecifico): void {
		if (confirm(`Tem certeza que deseja excluir o lancamento ${lancamento.nome_categoria_conta}?`)) {
			this.spinner.show();
			this.lancamentosEspecificosHttpService.delete(lancamento).subscribe(
				() => {
					this.spinner.hide();
					this.messageService.success('Lançamento excluído com sucesso');
					this.search();
				},
				error => {
					this.spinner.hide();
					if (error && error.status && error.status === 403) {
						this.messageService.error('Não é possível excluir o lançamento selecionado pois o mesmo faz parte de um rateio');
					} else {
						this.messageService.error('Erro ao excluir lançamento');
					}
				}
			);
		}
	}

	public labelTotal(): string {
		switch (this.filtro.situacao_rateio) {
			case SITUACAO_RATEIO.PROCESSADO:
				return 'Total dos lançamentos processados';
			case SITUACAO_RATEIO.NAO_PROCESSADO:
				return 'Total dos lançamentos não processados';
			case SITUACAO_RATEIO.TODOS:
				return 'Total de todos lançamentos';
			case SITUACAO_RATEIO.CATEGORIA_NAO_RATEADA:
				return 'Total dos lançamentos de categoria de conta não rateada';
			default:
				return 'Total dos lançamentos';
		}
	}

	/**
	 * Tela de templates de receitas fixas
	 * @author Marcos Frare
	 * @since 09/03/2021
	 */
	public templatesReceitasFixas(): void {
		this.router.navigate(['./template'], { relativeTo: this.activatedRoute });
	}

	/**
	 * Abrir tela para geração de lançamentos pelas templates
	 * @author Marcos Frare
	 * @since 10/03/2021
	 */
	public gerarLancamenosTemplate(): void {
		this.geracaoLancamentoEspecifico.open();
	}

}