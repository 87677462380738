import { BaseEntity } from "../base-entity";
import { FabricanteVeiculo } from "./fabricante-veiculo";
import { TipoVeiculo } from "./tipo-veiculo";

export class ModeloVeiculo extends BaseEntity {
	id_fabricante_veiculo: number;
	id_tipo_veiculo: number;
	nome: string;
	serie: string;
	
	fabricanteVeiculo: FabricanteVeiculo;
	tipoVeiculo: TipoVeiculo;

	constructor() {
		super();
		this.fabricanteVeiculo = new FabricanteVeiculo();
		this.tipoVeiculo = new TipoVeiculo();
	}
}