import { Component, OnInit, ViewChild } from '@angular/core';
import { RelatorioModalComponent } from '../relatorio-modal';
import { ContaBancaria } from 'app/models';
import { ConciliacaobancariaRelFiltro } from './conciliacao-bancaria-rel-filtro';
import { datepickerMesAnoOptions } from 'app/shared';
import { AuthService, MessageService } from 'app/core';
import { ContasBancariasHttpService } from 'app/home/contas-bancarias';

@Component({
  selector: 'conciliacao-bancaria-rel',
  templateUrl: './conciliacao-bancaria-rel.component.html',
  styleUrls: ['./conciliacao-bancaria-rel.component.css']
})
export class ConciliacaoBancariaRelComponent implements OnInit {

  @ViewChild('relatorioModal') relatorioModal: RelatorioModalComponent;

  public contas: ContaBancaria[];
  public filtro = new ConciliacaobancariaRelFiltro();
  public datepickerDataCompetenciaOptions = datepickerMesAnoOptions;

  constructor(private authService: AuthService,
    private messageService: MessageService,
    private contasBancariasHttpService: ContasBancariasHttpService) { }

  ngOnInit() {
    this.limpar();
    this.filtro.id_condominio = this.authService.getIdCurrentCondominio();; 
    this.filtro.id_usuario = this.authService.getIdCurrentUser();
    this.filtro.id_cliente = this.authService.getIdCurrentCliente();
    this.getContasBancariasByCondominio(this.filtro.id_condominio);
  }

  private getContasBancariasByCondominio(idCondominio: number) {
    this.contasBancariasHttpService.getAllByCondominioETipo(idCondominio, 1).subscribe(
      entitites => this.contas = entitites,
      error => this.messageService.error('Erro ao carregar as contas bancarias do condomínio. Tente novamente', 'Erro', error)
    );
  }

  public limpar(): void {
    this.filtro.limpar();
  }

  private validateView(): boolean {
    if (!this.filtro.data_inicial) {
      this.messageService.info('É necessário informar a competência início para imprimir', 'Informação');
      return false;
    }
    if (!this.filtro.data_final) {
      this.messageService.info('É necessário informar a competência fim para imprimir', 'Informação');
      return false;
    }

    return true;
  }

  public view(): void {
    if (this.validateView()) {
      this.relatorioModal.url = '/relatorio/conciliacao-bancaria';
      this.relatorioModal.params = this.filtro;
      this.relatorioModal.open();
    }
  }
  
}