import { BaseEntity } from '../base-entity';
import { CategoriaContas } from './categoria-contas';

export class TemplateLancamentoCondominioItem extends BaseEntity {
	id_categoria_conta: number;
	complemento: string;
	tipo_divisao_rateio: number;
	cobrar_tipo_unidade: number;
	id_categoria_conta_nao_rateado: number;
	cobrar_fundo_reserva: boolean;
	para_proprietario: boolean;
	valor: number;
	tolerancia_inferior: number;
	tolerancia_superior: number;
	
	categoriaConta: CategoriaContas;
	categoriaContaNaoRateado: CategoriaContas;
	ids_unidades: number[];

	multiselectOptionsUnidades: any[];
	
	minimo: number;
	maximo: number;
	valor_original: number;

	constructor() {
		super();
		this.valor = 0;
		this.cobrar_tipo_unidade = 100;
		this.cobrar_fundo_reserva = true;
		this.para_proprietario = false;
		this.ids_unidades = [];
		this.multiselectOptionsUnidades = [];
		this.complemento = null;
	}
}