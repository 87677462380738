import { BaseEntity } from '../base-entity';

export class Notificacao extends BaseEntity {
	id: number;
	id_evento_notificacao: number;
	id_unidade: number;
	email: string;
	assunto: string;
	conteudo: String;
	status: number;
	notificado: boolean;

	constructor() {
		super();
	}
}

export const statusNotificacao = [
	{ id: 1, nome: 'Não enviado' },
	{ id: 2, nome: 'Enviado' },
	{ id: 3, nome: 'Erro' },
	{ id: 4, nome: 'Inexistente' }
];