import { Component, EventEmitter, Output } from '@angular/core';
import { NgForm } from '@angular/forms';

import { FormsUtilsService, MessageService } from '../../core';
import { Condominio } from '../../models';

@Component({
    selector: 'login-cadastra-condominio',
    templateUrl: './login-cadastra-condominio.component.html'
})
export class LoginCadastraNovoCondominioComponent {
    @Output() cadastrar = new EventEmitter<Condominio>();
    @Output() cancel = new EventEmitter<any>();

    constructor(private messageService: MessageService,
                private formsUtilsService: FormsUtilsService) { }

    onSave(entity: Condominio) {
        this.cadastrar.emit(entity);
    }

    onCancel() {
        this.cancel.emit();
    }
}
