import { BaseEntity } from '../base-entity';
import { Endereco } from './endereco';
import { Telefone } from './telefone';

export class Usuario extends BaseEntity {
	nome: string;
	cpf: string;
	rg: string;
	documento: string;
	email: string;
	ativo: number;
	senha: string;
	telefones: Telefone[];
	tipo: number;
	id_grupo_de_usuario: number;
	endereco_condominio: boolean;
	id_endereco: number;
	id_cliente: number;
	endereco: Endereco;
	acessos_condominio: number[];
	condomino_id: number;
	data_nascimento: Date;
	id_fornecedor: number;
	funcao: number;
	data_admissao: Date;
	tipo_vinculo: number;
	logoCliente: any;

	constructor() {
		super();
		this.ativo = 1;
		this.endereco = new Endereco();
		this.acessos_condominio = [];
	}
}

export enum TIPO_USUARIO { ADMINISTRADOR = 1, FUNCIONARIO = 2, CONDOMINO = 3 };

export const tiposUsuarios = [
	{ id: 1, nome: 'Administrador' },
	{ id: 2, nome: 'Funcionário' },
	{ id: 3, nome: 'Condomino' }
];

export const tiposVinculos = [
	{ id: 1, nome: 'Funcionário' },
	{ id: 2, nome: 'Terceirizado' }
];

export const tiposFuncoes = [
	{ id: 1, nome: 'Auxiliar de serviços gerais' },
	{ id: 2, nome: 'Porteiro' },
];