import { NgModule } from '@angular/core';
import { ComparativoPrevisaoReceitaDespesaRelComponent } from './comparativo-previsao-receita-despesa-rel.component';
import { SharedModule } from 'app/shared';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';

@NgModule({
  imports: [
    SharedModule,
    MultiselectDropdownModule,
  ],
  declarations: [ComparativoPrevisaoReceitaDespesaRelComponent]
})
export class ComparativoPrevisaoReceitaDespesaRelModule { }
