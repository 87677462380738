import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import { BaseModal } from 'app/componentes-utils/modal';
import { AuthService, MessageService } from 'app/core';
import { BlocosHttpService, BlocosPesquisaModalComponent, UnidadesHttpService } from 'app/home/blocos';
import { RelatorioModalComponent } from 'app/home/relatorios';
import { Bloco, Unidade } from 'app/models';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { CarneListarFiltro } from './carne-listar-filtro';

declare var jQuery: any;

@Component({
  selector: 'carne-listar-modal',
  templateUrl: './carne-listar-modal.component.html',
  styleUrls: ['./carne-listar-modal.component.css']
})
export class CarneListarModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @ViewChild('blocosPesquisaModal1') blocosPesquisaModal1: BlocosPesquisaModalComponent;
  @ViewChild('relatorioModal1') relatorioModal1: RelatorioModalComponent;

  public filtro: CarneListarFiltro;
  public blocos: Bloco[];
  public unidades: Unidade[];
  public showInputBlocos: boolean = true;

  multiselectSettings: IMultiSelectSettings = {
    enableSearch: true,
    containerClasses: 'container-multiselect',
    showUncheckAll: true,
    showCheckAll: true,
    dynamicTitleMaxItems: 3,
    checkedStyle: 'checkboxes',
    buttonClasses: 'btn btn-default',
    itemClasses: 'dropdown-menu'
  };

  multiselectOptionsUnidades: IMultiSelectOption[];
  multiselectTextsUnidades: IMultiSelectTexts = {
    checkAll: 'Selecionar todas',
    uncheckAll: 'Limpar',
    checked: 'selecionada',
    checkedPlural: 'selecionadas',
    searchPlaceholder: 'Pesquisar...',
    defaultTitle: 'Selecionar',
    allSelected: 'Todas selecionadas',
    searchEmptyResult: 'Nenhum registro encontrado',
    searchNoRenderText: 'Digite para pesquisar',
  };

  constructor(private authService: AuthService,
    private messageService: MessageService,
    private unidadesHttpService: UnidadesHttpService,
    private blocosHttpService: BlocosHttpService) { }

  ngOnInit() {
    this.filtro = new CarneListarFiltro();
    this.filtro.id_cliente = this.authService.getIdCurrentCliente();
    this.filtro.id_condominio = this.authService.getIdCurrentCondominio();
    this.filtro.id_usuario = this.authService.getIdCurrentUser();
    this.getBlocosByCondominio(this.filtro.id_condominio);
    this.getAllUnidades();
  }

  public open() {
    this.limpar();
    jQuery(`#${this.idModal}`).modal('show');
  }

  public close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Listar carnês..
   * @author Marcos Frare
   * @since 06/12/2021
   */
  public listar(): void {
    this.relatorioModal1.url = '/carne/listar';
    this.relatorioModal1.params = this.filtro;
    this.relatorioModal1.open();
    this.close();
  }

  public limpar() {
    this.filtro.limpar();
    this.filterUnidades();
  }

  private getBlocosByCondominio(idCondominio: number) {
    this.blocosHttpService.getAllByCondominio(idCondominio).subscribe(
      entitites => {
        this.blocos = entitites;
        if (this.blocoUnico(this.blocos)) {
          this.setBloco(this.blocos[0]);
          this.showInputBlocos = false;
        } else if (!this.blocos) {
          this.messageService.error('Não existem blocos cadastrados no Condomínio. Cadastre  um bloco e tente novamente', 'Erro')
        }
      },
      error => this.messageService.error('Erro ao carregar os blocos do condomínio. Tente novamente', 'Erro', error)
    );
  }

  private setBloco(blocoSelecionado: Bloco) {
    this.filtro.bloco = blocoSelecionado;
    this.filtro.id_bloco = blocoSelecionado.id;
    this.filterUnidades();
    this.onCleanUnidades();
  }

  /**
   * Busca todas as unidades do codomínio
   * @author Marcos Frare
   * @since 24/04/2020
   */
  private getAllUnidades(): void {
    this.unidadesHttpService.getAllByCondominioBloco(this.filtro.id_condominio, null).subscribe(
      response => {
        this.unidades = response;
        this.filterUnidades();
      },
      error => this.messageService.error('Erro ao carregar as unidades do condomínio. Tente novamente', 'Erro', error)
    );
  }

  /**
   * Filtra as unidades com base no campo de bloco
   * @author Marcos Frare
   * @since 24/04/2020
   */
  private filterUnidades(): void {
    this.multiselectOptionsUnidades = [];
    if (this.filtro.id_bloco) {
      this.multiselectOptionsUnidades = this.unidades.filter(und => und.id_bloco == this.filtro.id_bloco).map(unid => {
        return {id: unid.id, name: `${unid.nome_bloco} - ${unid.nome_unidade}`}
      })
    } else {
      this.multiselectOptionsUnidades = this.unidades.map(unid => {
        return {id: unid.id, name: `${unid.nome_bloco} - ${unid.nome_unidade}`}
      });
    }
  }

  onCleanUnidades() {
    this.filtro.ids_unidades = [];
  }

  onSelectBloco(tm: TypeaheadMatch): void {
    this.setBloco(tm.item as Bloco);
  }

  openBlocosPequisaModal() {
    this.blocosPesquisaModal1.open();
  }

  onSelectBlocosPesquisaModal(bloco: Bloco) {
    if (bloco) {
      this.setBloco(bloco)
    }
    jQuery('#blocoFiltro').focus();
  }

  onCleanBloco(input: any) {
    this.filtro.bloco = null;
    this.filtro.id_bloco = null;
    input.value = '';
    this.filterUnidades();
  }

  private blocoUnico(blocos: Bloco[]): boolean {
    return blocos.length === 1;
  }

}