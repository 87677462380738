import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

import { Config } from './config';
import { ServerConfig } from './server-config';

export function initConfig(configService: ConfigService) {
    return () => configService.load();
}

@Injectable()
export class ConfigService {

    private config: Config;

    constructor(private http: HttpClient) { }

    load() {
        return new Promise((resolve, reject) => {
            this.http.get('./config/config.json')
                .pipe(
                    map(response => response),
                    catchError((error: any): any => {
                        if (error.status && error.status === 404) {
                            console.error('Erro ao carregar o arquivo config.json:',
                                        'O arquivo config.json não foi encontrado na pasta /config');
                        } else {
                            console.error('Erro ao carregar o arquivo config.json:', error);
                        }

                        return error;
                    })
                )
                .subscribe(
                    (config: Config) => {
                        this.config = config;
                        resolve(true);
                    },
                    error =>  {
                        reject(false);
                    }
                );
        });
    }

    getConfig(): Config {
        return this.config;
    }

    getServerConfig(): ServerConfig {
        if (!this.config) {
            return null;
        }
        return this.config.serverConfig;
    }

    getBaseUrl(): string {
        let serverConfig = this.getServerConfig();
        if (!serverConfig) {
            return null;
        }
        return serverConfig.baseUrl;
    }

     /** Path da API de relatórios da aplicação */
     getBaseUrlRelatorios(): string {
        return `${this.getBaseUrl()}/relatorio`;
    }

}
