import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService, MessageService } from '../../../core';
import { AnaliticoRelFiltro } from './analitico-rel-filtro';
import { ContaBancaria, Condominio } from '../../../models';
import { ContasBancariasHttpService } from '../../contas-bancarias';
import { RelatorioModalComponent } from '../../relatorios/relatorio-modal';

declare var jQuery: any;

@Component({
	selector: 'analitico-rel',
	templateUrl: './analitico-rel.component.html',
	styleUrls: ['../shared/visualizacao.relatorio.css']
})

export class AnaliticoRelComponent implements OnInit {

	public title: string;
	public user: string;
	public date: string;
	public logoUrl: string;
	public reportData: any;
	public condominio: Condominio;
	public contas: ContaBancaria[] = [];
	public filtro = new AnaliticoRelFiltro();

	@ViewChild('relatorioModal') relatorioModal: RelatorioModalComponent;

	constructor(private authService: AuthService,
		private messageService: MessageService,
		private contasBancariasHttpService: ContasBancariasHttpService) { }


	ngOnInit() {
		const idCondominio = this.authService.getIdCurrentCondominio();
		const idUsuario = this.authService.getIdCurrentUser();
		this.setInitialFilter();
		this.filtro.id_cliente = this.authService.getIdCurrentCliente();
		this.filtro.id_condominio = idCondominio;
		this.filtro.id_usuario = idUsuario;
		this.getContasBancariasByCondominio(idCondominio);
	}

	private getContasBancariasByCondominio(idCondominio: number) {
		this.contasBancariasHttpService.getAllByCondominio(idCondominio)
			.subscribe(
				entitites => this.contas = entitites,
				error => this.messageService.error('Erro ao carregar as contas bancarias do condomínio. Tente novamente', 'Erro', error)
			);
	}

	private validateView(): boolean {
		if (!this.filtro.data_inicial) {
			this.messageService.info('É necessário informar a competência início para imprimir', 'Informação');
			return false;
		}
		if (!this.filtro.data_final) {
			this.messageService.info('É necessário informar a competência fim para imprimir', 'Informação');
			return false;
		}

		return true;
	}

	public view(): void {
		if (this.validateView()) {
			this.relatorioModal.url = '/relatorio/analitico';
			this.relatorioModal.params = this.filtro;
			this.relatorioModal.open();
		}
	}

	public limpar(): void {
		this.filtro.limpar();
		this.reportData = null;
	}

	public setInitialFilter(): void {
		const today = new Date();
		this.filtro.data_inicial = new Date(today.getFullYear(), today.getMonth(), 1); // primeiro dia do mês
		this.filtro.data_final = new Date(today.getFullYear(), today.getMonth() + 1, 0); // último dia do mês
	}

}