import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseCadastroComponent } from '../../../componentes-base';
import { AuthService, MessageService } from '../../../core';
import { LocalHttpService } from './../services';
import { PermissionService } from '../../../core/services/permission/permission.service';
import { Local, tiposLocal, Bloco, Cliente, Anexo } from '../../../models';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { BlocosHttpService } from '../../blocos/services';
import { BlocosPesquisaModalComponent } from '../../blocos/blocos-pesquisa-modal';
import { CategoriaContasHttpService } from '../../plano-de-contas/categoria-contas/services';
import { LocalConfiguracaoModalComponent } from './local-configuracao-modal';
import { LocalUtensilioModalComponent } from './local-utensilio-modal';
import { LocalPoliticaModalComponent } from './local-politica-modal';
import { NgxSpinnerService } from 'ngx-spinner';

declare var jQuery: any;

@Component({
	selector: 'local-cadastro',
	templateUrl: 'local-cadastro.component.html',
	providers: [
		PermissionService
	]
})
export class LocalCadastroComponent extends BaseCadastroComponent<Local> implements OnInit {

	@ViewChild('blocosPesquisaModal') blocosPesquisaModal: BlocosPesquisaModalComponent;
	@ViewChild('localConfiguracaoModal') localConfiguracaoModal: LocalConfiguracaoModalComponent;
	@ViewChild('localUtensilioModal') localUtensilioModal: LocalUtensilioModalComponent;
	@ViewChild('localPoliticaModal') localPoliticaModal: LocalPoliticaModalComponent;

	public blocos: Bloco[] = [];
	public cliente: Cliente;
	public idCliente: number;
	public idCondominio: number;
	public tipos: any[] = tiposLocal;

	constructor(public activatedRoute: ActivatedRoute,
		public router: Router,
		public messageService: MessageService,
		public localHttpService: LocalHttpService,
		private authService: AuthService,
		public categoriaContasHttpService: CategoriaContasHttpService,
		public blocosHttpService: BlocosHttpService,
		public permissionService: PermissionService,
		private spinner: NgxSpinnerService) {
		super(Local, localHttpService, activatedRoute, router, messageService, permissionService);
	}

	ngOnInit() {
		this.idCliente = this.authService.getIdCurrentCliente();
		this.idCondominio = this.authService.getIdCurrentCondominio();
		this.getAllBlocosByCondominio();
		super.ngOnInit();
		if (Array.isArray(this.entity.anexos) && this.entity.anexos.length < 1) {
			this.addAnexos();
		}
	}

	public afterEdit(): void {
		if (!this.entity.anexos) {
			this.addAnexos();
		}
	}

	private getAllBlocosByCondominio(idCondominio: number = this.idCondominio): void {
		this.blocosHttpService.getAllByCondominio(idCondominio).subscribe(
			blocos => this.blocos = blocos,
			error => this.messageService.error('Erro ao carregar os blocos do condomínio. Tente novamente', 'Erro', error)
		);
	}

	public beforeSave(): boolean {
		this.entity.id_condominio = this.idCondominio;
		this.validateAnexos();
		return super.beforeSave();
	}

	private validateAnexos(): void {
		if (this.entity.anexos) {
			this.entity.anexos = this.entity.anexos
				.filter(anexo => anexo && anexo.arquivo);
		}
	}

	public save(): Promise<any> {
		this.btnSave = jQuery('#btnSave');
		this.spinner.show();
		return new Promise<any>((resolve, reject) => {
			this.makeAllFieldsDirty();

			if (this.form.valid) {
				if (this.beforeSave()) {
					this.btnSave.button('loading');

					if (this.editMode) { // PUT
						this.crudService.put(this.entity)
							.subscribe(
								entity => {
									this.messageService.success('', 'Local salvo com sucesso');
									resolve(entity);
									this.afterSave(entity);
								},
								error => {
									this.messageService.error(' ', 'Erro ao atualizar o local', error);
									this.btnSave.button('reset');
									this.spinner.hide();
									reject(null);
								}
							);
					} else { // POST
						this.crudService.post(this.entity)
							.subscribe(
								entity => {
									this.messageService.success('', 'Local salvo com sucesso');
									resolve(entity);
									this.afterSave(entity);
								},
								error => {
									this.messageService.error('', 'Erro ao salvar o local', error);
									this.btnSave.button('reset');
									this.spinner.hide();
									reject(null);
								}
							);
					}
				} else {
					this.spinner.hide();
					reject(null);
				}
			} else {
				this.spinner.hide();
				this.showMessageFormInvalid();
				reject(null);
			}
		});
	}

	// Para não sair da tela ao fina do cadastro
	afterSave(entity: Local) {
		this.entity = entity;
		this.editMode = true;
		this.btnSave.button('reset');
		this.spinner.hide();
	}

	// Bloco
	public onSelectBloco(tm: TypeaheadMatch): void {
		this.setBloco(tm.item as Bloco);
	}

	private setBloco(selectedBloco: Bloco): void {
		this.entity.bloco = selectedBloco;
		this.entity.id_bloco = selectedBloco.id;
	}

	public openBlocosPequisaModal(): void {
		this.blocosPesquisaModal.open();
	}

	public onSelectBlocosPesquisaModal(bloco: Bloco): void {
		if (bloco) this.setBloco(bloco);
		jQuery('#bloco').focus();
	}

	public onCleanBloco(input: any): void {
		this.entity.bloco = null;
		this.entity.id_bloco = null;
		input.value = '';
		input.focus();
	}

	// Anexos
	public addAnexos(): void {
		if (!this.entity.anexos) {
			this.entity.anexos = [];
		}
		this.entity.anexos.push(new Anexo());
	}

	public removeAnexos(index): void {
		this.entity.anexos.splice(index, 1);
	}

	public addConfiguracao(): void {
		this.localConfiguracaoModal.open();
	}

	public addPolitica(): void {
		this.localPoliticaModal.open();
	}

	public addUtensilio(): void {
		this.localUtensilioModal.open();
	}
}