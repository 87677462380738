import { Component } from '@angular/core';


@Component({
    selector: 'retrieve-password',
    templateUrl: 'retrieve-password.template.html'
})
export class RetrievePasswordComponent {

    private email: string;

    retrievePassword() { }

}
