import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { MessageService } from 'app/core';
import { Titulo } from 'app/models';
import { BoletosHttpService } from '../services';

declare var jQuery: any;

@Component({
  selector: 'alteracao-liquidacao-boleto-modal',
  templateUrl: './alteracao-liquidacao-boleto-modal.component.html',
  styleUrls: ['./alteracao-liquidacao-boleto-modal.component.css']
})
export class AlteracaoLiquidacaoBoletoModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @Input() titulo: Titulo = new Titulo();
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  public dataLiquidacao: Date = new Date();

  constructor(private boletosHttpService: BoletosHttpService, public messageService: MessageService) { }

  ngOnInit() {
    if (!this.idModal) { this.idModal = 'alteracaoLiquidacaoBoletoModal' };
  }

  public open() {
    jQuery(`#${this.idModal}`).modal('show');
  }

  public close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  public estornarPagamento(): void {
		if (confirm("Deseja realmente estornar pagamento?")) {
      let btn = jQuery('#btnEstornarTitulo');
      btn.button('loading');
			this.boletosHttpService.estornarPagamentoTitulo(this.titulo.id).subscribe(
				res => {
          btn.button('reset');
					if (res.success) {
						this.onChange.emit();
            this.close();
					} else {
						this.messageService.warning((res.msg || "parece que algo deu errado! Tente novamente."), "Atenção");
					}
				},
				error => {
          btn.button('reset');
					this.messageService.error('Parece que algo deu errado! Tente novamente.', 'Erro', error);
				}
			);
		}
	}

  public mudaCredito(): void {
    if (confirm(`Deseja realmente alterar a data da liquidação do título?`)) {
      let btn = jQuery('#btnMudaCredito');
      btn.button('loading');
      this.boletosHttpService.mudarDataCreditoTitulo(this.titulo.id, this.titulo.data_credito).subscribe(
        res => {
          btn.button('reset');
          if (res.success) {
						this.onChange.emit();
            this.close();
					} else {
						this.messageService.warning((res.msg || "parece que algo deu errado! Tente novamente."), "Atenção");
					}
        },
        error => {
          btn.button('reset');
					this.messageService.error('Parece que algo deu errado! Tente novamente.', 'Erro', error);
        }
      );
    }
  }

}
