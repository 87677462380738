import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { AuthService, MessageService } from 'app/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { CategoriaContas } from 'app/models';
import { CategoriaContasHttpService } from '../services';

declare var jQuery: any;

@Component({
	selector: 'categoria-contas-pesquisa-modal',
	templateUrl: './categoria-contas-pesquisa-modal.component.html',
	styleUrls: ['./categoria-contas-pesquisa-modal.component.css']
})
export class CategoriaContasPesquisaModalComponent implements OnInit, BaseModal {

	@Input() tipo: string;
	@Input() idModal: string; // id do modal para identificar um modal caso em uma mesma tela possa ter mais de um modal
	@Output() select = new EventEmitter<CategoriaContas>();
	public categoriasReceitas: CategoriaContas[] = [];
	public categoriasDespesas: CategoriaContas[] = [];

	constructor(private authService: AuthService,
		private categoriaContasHttpService: CategoriaContasHttpService,
		private messageService: MessageService) { }

	ngOnInit() {
		if (!this.idModal) {
			this.idModal = 'categoriaContasPesquisaModal';
		}
	}

	private loadCategoriaContas() {
		this.categoriasReceitas = [];
		this.categoriasDespesas = [];

		this.categoriaContasHttpService.getAllByCondominio(this.authService.getIdCurrentCondominio())
			.then(entities => {
				if (entities) {
					entities.forEach(entity => {
						if (entity.tipo_conta === 1) {
							this.categoriasReceitas.push(entity);
						} else {
							this.categoriasDespesas.push(entity);
						}
					});
				}
			})
			.catch(error => this.messageService.error('Erro ao carregar contas!', 'Erro', error));
	}

	private loadCategoriaContasDespesas() {
		this.categoriasDespesas = [];
		this.categoriaContasHttpService.getAllByCondominio(this.authService.getIdCurrentCondominio(), 2)
			.then(entities => this.categoriasDespesas = entities)
			.catch(error => this.messageService.error('Erro ao carregar contas de despesas!', 'Erro', error));
	}

	private loadCategoriaContasReceitas() {
		this.categoriasReceitas = [];
		this.categoriaContasHttpService.getAllByCondominio(this.authService.getIdCurrentCondominio(), 1)
			.then(entities => this.categoriasReceitas = entities)
			.catch(error => this.messageService.error('Erro ao carregar contas de receitas!', 'Erro', error));
	}

	public open(): void {
		switch (this.tipo) {
			case 'despesas':
				this.loadCategoriaContasDespesas();
				break;
			case 'receitas':
				this.loadCategoriaContasReceitas();
				break;
			default: this.loadCategoriaContas();
		}
		jQuery(`#${this.idModal}`).modal('show');
	}

	public close(): void {
		this.categoriasReceitas = [];
		this.categoriasDespesas = [];
		jQuery(`#${this.idModal}`).modal('hide');
	}

	public selectClick(categoriaContas: CategoriaContas): void {
		this.select.emit(categoriaContas);
		this.close();
	}
}
