import { ThrowStmt } from '@angular/compiler';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BaseModal } from 'app/componentes-utils/modal';
import { AuthService, MessageService } from 'app/core';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { CondominiosHttpService } from 'app/home/condominios';
import { FornecedoresHttpService } from 'app/home/fornecedores';
import { Condominio, Fornecedor } from 'app/models';
import { ReciboHttpService } from '../services';
import { ReciboGeracaoFiltro } from './recibo-geracao-filtro';

declare var jQuery: any;

@Component({
  selector: 'recibo-geracao-modal',
  templateUrl: './recibo-geracao-modal.component.html',
  styleUrls: ['./recibo-geracao-modal.component.css']
})
export class ReciboGeracaoModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @ViewChild('form') form: NgForm;
  @Output() onGenerate: EventEmitter<any> = new EventEmitter();

  public condominios: Condominio[] = [];
  public fornecedores: Fornecedor[] = [];
  public id_condominio: number;
  public filtro: ReciboGeracaoFiltro;
  public pagamentos: any = [];
  public allSelected: boolean = false;
  public dataTable: any;

  constructor(private authService: AuthService,
    private messageService: MessageService,
    public permissionService: PermissionService,
    private condominioHttpService: CondominiosHttpService,
    private fornecedoresHttpService: FornecedoresHttpService,
    private reciboHttpService: ReciboHttpService) { }
  
  ngOnInit() {
    this.showTable();
    this.id_condominio = this.authService.getIdCurrentCondominio();
    this.limparFiltro();
    if (!this.idModal) {
      this.idModal = 'reciboGeracaoModal';
    }
    this.getCondominios();
    this.getFornecedores();
  }

  open() {
    this.limparFiltro();
    this.pagamentos = [];
    this.showTable();
    jQuery(`#${this.idModal}`).modal('show');
  }

  close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Buscar os condomínios do cliente
   * @author Marcos Frare
   * @since 22/02/2023
   */
  private getCondominios(): void {
    this.condominios = [];
    this.condominioHttpService.getAllByCliente(this.filtro.id_cliente).subscribe(
      res => {
        this.condominios = this.id_condominio ? res.filter(c => c.id == this.id_condominio) : res;
      },
      error => {
        this.messageService.error('Não foi possível carregar os condomínios do cliente! Tente novamente', 'Erro', error);
      }
    );
  }

  private showTable() {
    if (this.dataTable) {
			this.dataTable.destroy();
		}
    setTimeout(() => {
      this.dataTable = jQuery("#tablePagamentos").DataTable({
        ordering: false,
        search: false,
        pageLength: 10,
        language: { 'url': './assets/scripts/datatables-ptbr.json' },
        destroy: true,
      });
    }, 200);
  }

  limparFiltro(): void {
    this.filtro = new ReciboGeracaoFiltro();
    this.filtro.id_cliente = this.authService.getIdCurrentCliente();
    this.filtro.id_condominio = this.id_condominio;
  }

  getFornecedores(): void {
    this.fornecedores = [];
    this.fornecedoresHttpService.getAllByCliente(this.authService.getIdCurrentCliente()).subscribe(
			entities => this.fornecedores = entities,
			error => this.messageService.error('Erro ao carregar os fornecedores', 'Erro', error)
		);
  }

  getPagamentos(): void {
    let btn = jQuery("#btnBuscaPagamentos");
    btn.button('loading');
    this.pagamentos = [];
    this.reciboHttpService.getPagamentosGerarReciboByParams(this.filtro).subscribe(
      res => {
        this.pagamentos = res;
        btn.button('reset');
        this.showTable();
      },
      error => {
        this.messageService.error('Erro ao buscar os pagamentos para geração! Tente novamente.', 'Erro', error);
        btn.button('reset');
      }
    );
  }

  checkAllSelected(): boolean {
    for (let p of this.pagamentos) {
      if (p.selected) {
        return false;
      }
    }
    return true;
  }

  toggleAllSelected(): void {
    this.pagamentos.map(p => p.selected = this.allSelected);
  }

  gerar(): void {
    let btn = jQuery("#btnGerarRecibo");
    btn.button('loading');
    let selecteds = this.pagamentos.filter(p => p.selected).map(pp => pp.id);
    if (!selecteds.length) {
      this.messageService.warning('Para gerar recibo precisa selecionar pagamentos.', 'Atenção');
      btn.button('reset');
      return;
    }

    this.reciboHttpService.gerarRecibos(selecteds).subscribe(
      () => {
        btn.button('reset');
        this.messageService.success('Recibos gerado com sucerro!');
        this.onGenerate.emit();
        this.close();
      },
      error => {
        this.messageService.error('Erro ao gerar recibos! Tente novamente', 'Erro', error);
        btn.button('reset');
      }
    );
  }

}
