import { Component, Input } from '@angular/core';

type opcaoAcessoAlemDoLer = 'criar' | 'atualizar' | 'deletar';
type opcaoAcesso = 'ler' | opcaoAcessoAlemDoLer;

@Component({
	selector: 'acessos',
	templateUrl: './acessos.component.html',
	styleUrls: ['./acessos.component.css']
})
export class AcessosComponent {

	@Input() acessos: any[]

	constructor() { }

	private desmarcarTodosAlemDoLer(acesso: any) {
		if (!acesso) return;
		acesso.criar = false;
		this.onChangeAlemDoLer(acesso, 'criar');
		acesso.atualizar = false;
		this.onChangeAlemDoLer(acesso, 'atualizar');
		acesso.deletar = false;
		this.onChangeAlemDoLer(acesso, 'deletar');
	}

	private marcarLer(acesso: any) {
		if (!acesso) return;
		acesso.ler = true;
		this.onChangeLer(acesso);
	}

	private getEntitiesFilhos(idMenuPai: number): any[] {
		if (!this.acessos) return [];
		return this.acessos.filter(entityFilter => entityFilter.id_pai === idMenuPai);
	}

	private getAcessoPai(idMenuPai: number): any {
		if (!this.acessos) return null;
		const acesso = this.acessos.find(entityFilter => entityFilter.id === idMenuPai);
		if (!acesso) return null;
		return acesso;
	}

	private marcarDesmarcarPai(idPai: number, opcao: opcaoAcesso): void {
		let marcado = false;
		const entitiesFilhos = this.getEntitiesFilhos(idPai);
		for (let i = 0; i < entitiesFilhos.length; i++) {
			const acesso = entitiesFilhos[i];
			if (acesso[opcao]) {
				marcado = true;
				break;
			}
		}
		const acessoPai = this.getAcessoPai(idPai);
		if (acessoPai) acessoPai[opcao] = marcado;
	}

	public onChangeLer(acesso: any): void {
		if (!acesso) return;
		if (acesso.sub_menu && acesso.sub_menu.length) {
			acesso.sub_menu.forEach(subAcesso => {
				subAcesso.ler = acesso.ler;
				if (!subAcesso.ler)
					this.desmarcarTodosAlemDoLer(subAcesso);
			});
		} else {
			if (acesso.id_pai)
				this.marcarDesmarcarPai(acesso.id_pai, 'ler');
			if (!acesso.ler)
				this.desmarcarTodosAlemDoLer(acesso);
		}
	}

	public onChangeAlemDoLer(acesso: any, opcao: opcaoAcessoAlemDoLer): void {
		if (!acesso) return;
		if (acesso.sub_menu && acesso.sub_menu.length) {
			acesso.sub_menu.forEach(subAcesso => {
				subAcesso[opcao] = acesso[opcao];
				if (subAcesso[opcao])
					this.marcarLer(subAcesso);
			});
		} else {
			if (acesso.id_pai)
				this.marcarDesmarcarPai(acesso.id_pai, opcao);
			if (acesso[opcao])
				this.marcarLer(acesso);
		}
	}
}
