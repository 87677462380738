import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';

import { AuthService, MessageService } from '../../../core';
import { BaseModal } from '../../../componentes-utils/modal';
import { datepickerDefaultOptions } from '../../../shared';
import { DatePipe } from '@angular/common';
import { CategoriaContas, ItensRenegociacao, ParcelasRenegociacao, Renegociacao, tiposItensRenegociacao } from 'app/models';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { BoletosHttpService } from '../services';
import { CategoriaContasHttpService, CategoriaContasPesquisaModalComponent, PlanoDeContasCadastroModalComponent } from 'app/home/plano-de-contas';

declare var jQuery: any;

@Component({
	selector: 'renegociacao-modal',
	templateUrl: './renegociacao-modal.component.html',
	styleUrls: ['./renegociacao-modal.component.css'],
	providers: [DatePipe]
})

export class RenegociacaoModalComponent implements OnInit, BaseModal {

	public datepickerDataOptions = datepickerDefaultOptions;
	public categoriasContas: CategoriaContas[];
	public tiposItensRenegociacao = tiposItensRenegociacao;
	public totalCapital = 0;
	public totalJuros = 0;
	public totalEntradaCapital = 0;
	public totalEntradaJuros = 0;

	@Input() idModal: string;
	@Input() idUnidade: number;
	@Input() renegociacao: Renegociacao;

	@Output() onSave: EventEmitter<any> = new EventEmitter();

	@ViewChild('categoriaContasPesquisaModal') categoriaContasPesquisaModal: CategoriaContasPesquisaModalComponent;
	@ViewChild('planoDeContasCadastroModal') planoDeContasCadastroModal: PlanoDeContasCadastroModalComponent;

	constructor(private authService: AuthService,
		private boletosHttpService: BoletosHttpService,
		private messageService: MessageService,
		private datePipe: DatePipe,
		private categoriaContasHttpService: CategoriaContasHttpService) { }

	ngOnInit() {
		if (!this.idModal) this.idModal = 'renegociacaoModal';
	}

	private calcTotalParcelas(): void {
		this.totalCapital = 0;
		this.totalJuros = 0;
		this.totalEntradaCapital = 0;
		this.totalEntradaJuros = 0;
		this.renegociacao.parcelas.map(par => {
			this.totalCapital += par.valor_capital;
			this.totalJuros += par.valor_juros;
		});
		this.renegociacao.parcelasEntrada.map(par => {
			this.totalEntradaCapital += par.valor_capital;
			this.totalEntradaJuros += par.valor_juros;
		});
	}

	public onAddItens(): void {
		this.renegociacao.itens.push(new ItensRenegociacao());
	}

	public onRemoveItens(index: number): void {
		this.renegociacao.itens.splice(index, 1);
		this.onChange();
	}

	// =========================================================================================================
	// Parcelas de entrada
	// =========================================================================================================
	/**
	 * Adiciona uma parcela de entrada
	 * @author Marcos Frare
	 * @since 26/03/2021
	 */
	public addParcelaEntrada(): void {
		// Adiciona uma nova parcela ao final do array, com valores zerados
		let parcelaTemp = new ParcelasRenegociacao();
		parcelaTemp.valor_capital = 0;
		parcelaTemp.valor_juros = 0;
		parcelaTemp.valor = 0;
		parcelaTemp.numero = 0;
		this.renegociacao.parcelasEntrada.push(parcelaTemp);
	}

	/**
	 * Remove uma parcela
	 * @param index 
	 * @author Marcos Frare
	 * @since 26/03/2021
	 */
	public removeParcelaEntrada(index: number): void {
		this.renegociacao.parcelasEntrada.splice(index, 1);
		this.updateParcelas();
	}

	public open(): void {
		this.renegociacao.parcelasEntrada = [];
		this.getAllCategoriasByCondominio(this.authService.getIdCurrentCondominio());
		setTimeout(() => {
			this.calcTotalParcelas();
		}, 1000);
		jQuery(`#${this.idModal}`).modal('show');
	}

	public close(): void {
		jQuery(`#${this.idModal}`).modal('hide');
	}

	public onChange(): void {
		// Máximo 999 parcelas
		this.renegociacao.quantidade_parcelas = this.renegociacao.quantidade_parcelas > 999 ? 999 : this.renegociacao.quantidade_parcelas;
		this.calcRenegociacao();
	}

	// Ao alterar a data da parcela, é recalculado o parcelamento
	public updateParcelas(): void {
		const params = {
			id_condominio: this.authService.getIdCurrentCondominio(),
			parcelas: this.renegociacao.parcelas,
			parcelasEntrada: this.renegociacao.parcelasEntrada,
			arItens: this.renegociacao.itens,
			isensaojurosparcelamento: this.renegociacao.isensaojurosparcelamento,
			isensaojurosentrada: this.renegociacao.isensaojurosentrada,
			total: this.getTotalRenegociacao()
		}

		this.boletosHttpService.calcParcelas(params).subscribe(
			response => {
				this.renegociacao.parcelas = response.parcelas;
				this.renegociacao.parcelasEntrada = response.parcelasEntrada;
				this.calcTotalParcelas();
			},
			error => error
		);
	}

	// Calcula o total da renegociação (Títulos e itens)
	private getTotalRenegociacao(): number {
		let total = 0;
		this.renegociacao.arTitulo.forEach(titulo => {
			total += titulo.valor_unitario;
		});
		this.renegociacao.itens.forEach(item => {
			if (item.tipo === 1) {
				total += (item.valor);
			} else {
				total -= (item.valor);
			}
		});
		// Caso esteja selecionado para conceder descontos de multa ou juros
		if (this.renegociacao.desconto_multa) {
			total -= this.renegociacao.totalTitulos.valor_multa;
		}
		if (this.renegociacao.desconto_juros) {
			total -= this.renegociacao.totalTitulos.valor_juros;
		}

		return total;
	}

	// Monta as parcelas
	private calcRenegociacao(): void {
		const params = this.setPayloadCalc(this.renegociacao.data_vencimento, this.renegociacao.quantidade_parcelas);
		this.boletosHttpService.calcRenegociacao(params).subscribe(
			response => {
				this.setRenegociacao(this.renegociacao.data_vencimento, response.parcelas, response.parcelasEntrada);
			},
			error => {
				this.messageService.warning('Não foi possível calcular as parcelas!<br>Tente novamente.', 'Atenção!');
			}
		);
	}

	private setPayloadCalc(dataVencimento: Date, parcelas: number): any {
		return {
			data_vencimento: this.datePipe.transform(dataVencimento, 'yyyy-MM-dd'),
			id_condominio: this.authService.getIdCurrentCondominio(),
			quantidade_parcelas: parcelas,
			parcelas: this.renegociacao.parcelas,
			parcelasEntrada: this.renegociacao.parcelasEntrada,
			ids_titulos: this.renegociacao.ids_titulos,
			desconto_juros: this.renegociacao.desconto_juros,
			desconto_multa: this.renegociacao.desconto_multa,
			isensaojurosparcelamento: this.renegociacao.isensaojurosparcelamento,
			isensaojurosentrada: this.renegociacao.isensaojurosentrada,
			total: this.getTotalRenegociacao()
		};
	}

	private setRenegociacao(dataVencimento: Date = new Date(), parcelas: any[], parcelasEntrada?: any[]): any {
		this.renegociacao = {
			data_vencimento: this.datePipe.transform(dataVencimento, 'yyyy-MM-dd'),
			id_condominio: this.authService.getIdCurrentCondominio(),
			parcelas: parcelas,
			parcelasEntrada: parcelasEntrada || [],
			id_unidade: this.idUnidade,
			ids_titulos: this.renegociacao.ids_titulos,
			quantidade_parcelas: this.renegociacao.quantidade_parcelas || 1,
			desconto_juros: this.renegociacao.desconto_juros,
			desconto_multa: this.renegociacao.desconto_multa,
			isensaojurosparcelamento: this.renegociacao.isensaojurosparcelamento,
			isensaojurosentrada: this.renegociacao.isensaojurosentrada,
			itens: this.renegociacao.itens,
			observacao: this.renegociacao.observacao,
			numero: this.renegociacao.numero,
			arTitulo: this.renegociacao.arTitulo,
			totalTitulos: this.renegociacao.totalTitulos,
			observacaoItem: this.renegociacao.observacaoItem,
			observacaoisencaojurosparcelamento: this.renegociacao.observacaoisencaojurosparcelamento
		} as Renegociacao;
		this.calcTotalParcelas();
	}

	private validateItem(item: ItensRenegociacao): boolean {
		return item.id_categoria_conta && item.tipo && item.valor;
	}

	public onRenegociarClick(): void {
		let btnRenegociar = jQuery('#btnRenegociarModal');
		btnRenegociar.button('loading');
		const parcelas = [];
		this.renegociacao.parcelas.forEach(parcela => {
			const obj: any = { ...parcela };
			parcelas.push(obj);
		});
		const itens = [];
		this.renegociacao.itens.forEach(item => {
			const obj: any = { ...item };
			itens.push(obj);
		});
		const envio = {
			data_vencimento: this.renegociacao.data_vencimento,
			id_condominio: this.renegociacao.id_condominio,
			parcelas: parcelas,
			parcelasEntrada: this.renegociacao.parcelasEntrada,
			id_unidade: this.renegociacao.id_unidade,
			ids_titulos: this.renegociacao.ids_titulos,
			quantidade_parcelas: this.renegociacao.quantidade_parcelas,
			desconto_juros: this.renegociacao.desconto_juros,
			desconto_multa: this.renegociacao.desconto_multa,
			isensaojurosparcelamento: this.renegociacao.isensaojurosparcelamento,
			isensaojurosentrada: this.renegociacao.isensaojurosentrada,
			itens: itens ? itens.filter(it => this.validateItem(it)) : [],
			observacao: this.renegociacao.observacao,
			numero: this.renegociacao.numero,
			total: this.getTotalRenegociacao(),
			observacaoItem: this.renegociacao.observacaoItem,
			observacaoisencaojurosparcelamento: this.renegociacao.observacaoisencaojurosparcelamento
		};
		this.boletosHttpService.renegociar(envio).subscribe(
			() => {
				btnRenegociar.button('reset');
				this.messageService.success('Renegociação efetuada com sucesso.', 'Sucesso');
				this.onSave.emit();
				this.close();
			},
			error => {
				btnRenegociar.button('reset');
				this.messageService.error('Erro ao renegociar. Tente Novamente', 'Erro', error);
			}
		);
	}

	private getAllCategoriasByCondominio(idCondominio: number) {
		this.categoriasContas = [];
		if (idCondominio) {
			this.categoriaContasHttpService.getAllByCondominio(idCondominio)
				.then(entities => this.categoriasContas = entities);
		}
	}

	public openPlanoDeContasCadastroModal(modal): void {
		modal.open();
	}

	public onAfterSaveContaCategoriaConta(categoriaConta: CategoriaContas, item: ItensRenegociacao): void {
		item.categoriaContas = categoriaConta;
		item.id_categoria_conta = categoriaConta.id;
	}

	public onSelectCategoriaContas(tm: TypeaheadMatch, item: ItensRenegociacao): void {
		const categoriaContas: CategoriaContas = tm.item as CategoriaContas;
		if (categoriaContas) {
			item.id_categoria_conta = categoriaContas.id;
			item.categoriaContas = categoriaContas;
			item.descricao = categoriaContas.nome;
		} else {
			item.id_categoria_conta = null;
			item.categoriaContas = null;
		}
	}

	public onCleanCategoriaContas(input: any, item: ItensRenegociacao): void {
		item.id_categoria_conta = null;
		item.categoriaContas = null;

		input.value = '';
		input.focus();
	}

	public openCategoriaContasPequisaModal(modal): void {
		modal.open();
	}

	public onSelectCategoriaContasPesquisaModal(categoriaContas: CategoriaContas, item: ItensRenegociacao): void {
		if (categoriaContas) {
			item.id_categoria_conta = categoriaContas.id;
			item.categoriaContas = categoriaContas;
			item.descricao = categoriaContas.nome;
		}
		jQuery('#categoriaContas').focus();
	}

}