import { Component, OnInit, ViewChild } from '@angular/core';
import { RelatorioModalComponent } from '../relatorio-modal';
import { datepickerMesAnoOptions } from 'app/shared';
import { FundosMovimentacaoRelFiltro } from './fundos-movimentacao-rel-filtro';
import { AuthService, MessageService } from 'app/core';
import { IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import { CategoriaContasHttpService } from 'app/home/plano-de-contas';

@Component({
  selector: 'fundos-movimentacao-rel',
  templateUrl: './fundos-movimentacao-rel.component.html',
  styleUrls: ['./fundos-movimentacao-rel.component.css']
})
export class FundosMovimentacaoRelComponent implements OnInit {

	public multiselectSettings: IMultiSelectSettings = {
		enableSearch: true,
		containerClasses: 'container-multiselect',
		showUncheckAll: true,
		showCheckAll: true,
		dynamicTitleMaxItems: 1,
		checkedStyle: 'checkboxes',
		buttonClasses: 'btn btn-default',
		itemClasses: 'dropdown-menu'
	};
	public multiselectTexts: IMultiSelectTexts = {
		checkAll: 'Selecionar todas',
		uncheckAll: 'Limpar',
		checked: 'selecionada',
		checkedPlural: 'selecionadas',
		searchPlaceholder: 'Pesquisar...',
		defaultTitle: 'Selecionar',
		allSelected: 'Todas selecionadas',
		searchEmptyResult: 'Nenhuma categoria de conta encontrada',
		searchNoRenderText: 'Digite para pesquisar',
	};

  @ViewChild('relatorioModal') relatorioModal: RelatorioModalComponent;

  public datepickerDataCompetenciaOptions = datepickerMesAnoOptions;
  public filtro: FundosMovimentacaoRelFiltro = new FundosMovimentacaoRelFiltro();
	public categoriasContasReceita: any = [];

  constructor(private authService: AuthService,
		private categoriaContasHttpService: CategoriaContasHttpService,
		private messageService: MessageService) { }

  ngOnInit() {
    this.filtro.id_cliente = this.authService.getIdCurrentCliente();
		this.filtro.id_condominio = this.authService.getIdCurrentCondominio();
		this.filtro.id_usuario = this.authService.getIdCurrentUser();
    this.limpar();
		this.getAllCategoriasByCondominio();
  }

  public limpar(): void {
		this.filtro.limpar();
	}

	private getAllCategoriasByCondominio(): void {
		this.categoriasContasReceita = [];
		this.categoriaContasHttpService.getAllByCondominio(this.filtro.id_condominio).then(
			entities => this.categoriasContasReceita = entities.filter(conta => conta.tipo_conta == 1).map(c => {return {id: c.id, name: `${c.codigo} - ${c.nome}`}})
		);
	}

  private validateView(): boolean {
		if (!this.filtro.competencia_inicial) {
			this.messageService.info('Informe a competência início para imprimir', 'Informação');
			return false;
		}
		if (this.filtro.categoriaContaReceitaIds.length < 1) {
			this.messageService.info('Informe ao menos uma conta de receita para fundo', 'Informação');
			return false;
		}
		return true;
	}

	public view(): void {
		if (this.validateView()) {
			this.relatorioModal.url = '/relatorio/fundos-movimentacao';
			this.relatorioModal.params = this.filtro;
			this.relatorioModal.open();
		}
	}

}
