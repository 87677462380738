export * from './base-entity';
export * from './consts';

export * from './entidades/acesso';
export * from './entidades/acesso-condominio';
export * from './entidades/acesso-painel';
export * from './entidades/anexo';
export * from './entidades/antecipacao';
export * from './entidades/arquivo-retorno';
export * from './entidades/autorizacao-conta-bancaria';
export * from './entidades/banco';
export * from './entidades/bloco';
export * from './entidades/boleto';
export * from './entidades/categoria-conta-grupo';
export * from './entidades/categoria-contas';
export * from './entidades/categoria-servico-unidade';
export * from './entidades/categoria-tarifa-consumo';
export * from './entidades/certidao-negativa-debito';
export * from './entidades/certificado-digital';
export * from './entidades/cheque';
export * from './entidades/cidade';
export * from './entidades/cliente';
export * from './entidades/cobranca-avulsa';
export * from './entidades/comunicacao';
export * from './entidades/conciliacao';
export * from './entidades/conciliacao-extrato';
export * from './entidades/condominio';
export * from './entidades/condominio-configuracao-reserva';
export * from './entidades/condominio-tipos-consumo';
export * from './entidades/condomino';
export * from './entidades/configuracao-condominio';
export * from './entidades/configuracao-email-cliente';
export * from './entidades/configuracao-layout-titulo';
export * from './entidades/configuracao-layout-recibo';
export * from './entidades/configuracao-livro';
export * from './entidades/configuracao-livro-item';
export * from './entidades/configuracao-notificacao-automatica';
export * from './entidades/configuracao-tarifa-bancaria';
export * from './entidades/configuracao-cobranca';
export * from './entidades/consumo-unidade';
export * from './entidades/consumo-unidade-historico';
export * from './entidades/conta-bancaria';
export * from './entidades/contato';
export * from './entidades/contato-proprietario-morador';
export * from './entidades/conselhos-condominio';
export * from './entidades/demonstrativo-rateio';
export * from './entidades/documento';
export * from './entidades/documento-fiscal';
export * from './entidades/documento-pasta';
export * from './entidades/email-envio';
export * from './entidades/endereco';
export * from './entidades/evento-notificacao';
export * from './entidades/extrato';
export * from './entidades/extrato-anexo';
export * from './entidades/extrato-banco';
export * from './entidades/fabricante-veiculo';
export * from './entidades/filtro';
export * from './entidades/formulario';
export * from './entidades/fornecedor';
export * from './entidades/garagem';
export * from './entidades/grupo-de-usuario';
export * from './entidades/historico-consumo';
export * from './entidades/historico-unidade';
export * from './entidades/inventario';
export * from './entidades/lancamento-condominio-item';
export * from './entidades/lancamento-especifico';
export * from './entidades/lancamento-unidade';
export * from './entidades/lancamentos-condominio';
export * from './entidades/modelo-veiculo';
export * from './entidades/livro';
export * from './entidades/local';
export * from './entidades/local-configuracao';
export * from './entidades/local-disponibilidade';
export * from './entidades/local-politica';
export * from './entidades/local-utensilio';
export * from './entidades/logo-cliente';
export * from './entidades/menu';
export * from './entidades/notas';
export * from './entidades/notificacao';
export * from './entidades/ocorrencia';
export * from './entidades/ocorrencias';
export * from './entidades/orcamento';
export * from './entidades/pagamento';
export * from './entidades/pagamento-cancelamento';
export * from './entidades/pagamento-aprovacao-sindico';
export * from './entidades/pagamento-anexo';
export * from './entidades/pagamento-especifico';
export * from './entidades/pagamento-unidade';
export * from './entidades/parcela-despesa-rateio';
export * from './entidades/plano-de-contas';
export * from './entidades/previsao-orcamentaria';
export * from './entidades/previsao-categoria-conta';
export * from './entidades/proprietarios-moradores';
export * from './entidades/recibo';
export * from './entidades/relatorio';
export * from './entidades/relatorio-filtro';
export * from './entidades/renegociacao';
export * from './entidades/reserva';
export * from './entidades/reserva-calendario-bloqueio';
export * from './entidades/seguro';
export * from './entidades/tipo-documento-fiscal';
export * from './entidades/tipo-contato';
export * from './entidades/tipo-veiculo';
export * from './entidades/titulo';
export * from './entidades/tarifas-consumo';
export * from './entidades/telefone';
export * from './entidades/template-lancamento-condominio';
export * from './entidades/template-lancamento-condominio-item';
export * from './entidades/template-lancamento-condominio-item-unidade';
export * from './entidades/template-lancamento-especifico';
export * from './entidades/template-lancamento-especifico-unidade';
export * from './entidades/tipo-consumo';
export * from './entidades/transferencia';
export * from './entidades/unidade';
export * from './entidades/unidade-isencao';
export * from './entidades/unidade-antecipacao';
export * from './entidades/usuario';
export * from './entidades/veiculo';