import { Fornecedor } from './../../models/entidades/fornecedor';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseVisualizacaoComponent } from '../../componentes-base';
import { Comunicacao, Bloco, Condominio, Unidade, tiposComunicacao } from '../../models';
import { AuthService, MessageService } from '../../core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { ComunicacoesHttpService } from './services';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { BlocosPesquisaModalComponent, BlocosHttpService, UnidadesPesquisaModalComponent, UnidadesHttpService } from '../blocos';
import { DatePipe } from '@angular/common';
import { CondominiosHttpService, CondominiosPesquisaModalComponent } from '../condominios';
import { NgxSpinnerService } from 'ngx-spinner';
import { RelatorioModalComponent } from '../relatorios';
import { EnvioEmailLogModalComponent } from 'app/componentes-utils/envio-email-log-modal';

declare var jQuery: any;

@Component({
	selector: 'comunicacoes',
	templateUrl: 'comunicacoes.template.html',
	styleUrls: ['./comunicacoes.component.css'],
	providers: [PermissionService, DatePipe]
})

export class ComunicacoesComponent extends BaseVisualizacaoComponent<Comunicacao> {
	@ViewChild('condominiosPesquisaModal') condominiosPesquisaModal: CondominiosPesquisaModalComponent;
	@ViewChild('blocosPesquisaModal') blocosPesquisaModal: BlocosPesquisaModalComponent;
	@ViewChild('unidadesPesquisaModal') unidadesPesquisaModal: UnidadesPesquisaModalComponent;
	@ViewChild('relatorioModal') relatorioModal: RelatorioModalComponent;
	@ViewChild('envioEmailLogModal') envioEmailLogModal: EnvioEmailLogModalComponent;

	public idCurrentUser: number;
	public idCliente: number;
	public idCondominio: number;
	public filtro: any = {};
	public condominios: Condominio[] = [];
	public blocos: Bloco[] = [];
	public unidades: Unidade[] = [];
	public tiposComunicacao: any[] = tiposComunicacao;
	public fornecedores: Fornecedor[] = [];

	constructor(activatedRoute: ActivatedRoute,
			router: Router,
			messageService: MessageService,
			permissionService: PermissionService,
			private authService: AuthService,
			private condominiosHttpService: CondominiosHttpService,
			private blocosHttpService: BlocosHttpService,
			private unidadesHttpService: UnidadesHttpService,
			private comunicacoesHttpService: ComunicacoesHttpService,
			private datePipe: DatePipe,
			public spinner: NgxSpinnerService) {
		super('#tableComunicacoes', comunicacoesHttpService, activatedRoute, router, messageService, permissionService, spinner);
	}

	ngOnInit() {
		this.idCurrentUser = this.authService.getIdCurrentUser();
		this.idCliente = this.authService.getIdCurrentCliente();
		this.idCondominio = this.authService.getIdCurrentCondominio();
		this.getAllCondominiosByUsuario();
		this.setInitialFilter();
		this.getPermissions();
		if (!this.idCondominio) { this.search() };
	}

	private getAllCondominiosByUsuario(): void {
		this.condominiosHttpService.getAllByUsuario(this.idCurrentUser).subscribe(
			condominios => this.condominios = condominios,
			error => this.messageService.error('Erro ao carregar os condominios do cliente. Tente novamente', 'Erro', error)
		);
	}

	private getAllBlocosByCondominio(idCondominio: number = this.idCondominio): void {
		this.blocosHttpService.getAllByCondominio(idCondominio).subscribe(
			blocos => this.blocos = blocos,
			error => this.messageService.error('Erro ao carregar os blocos do condomínio. Tente novamente', 'Erro', error)
		);
	}

	private getAllUnidadesByBloco(idBloco: number): void {
		this.unidadesHttpService.getAllByBloco(idBloco).subscribe(
			unidades => this.unidades = unidades,
			error => this.messageService.error('Erro ao carregar os unidades do bloco. Tente novamente', 'Erro', error)
		);
	}

	// Condomínios
	public onSelectCondominio(tm: TypeaheadMatch): void {
		this.setCondominio(tm.item as Condominio);
	}

	private setCondominio(selectedCondominio: Condominio): void {
		this.filtro.condominio = selectedCondominio;
		this.filtro.id_condominio = selectedCondominio.id;
		this.filtro.bloco = null;
		this.filtro.id_bloco = null;
		this.filtro.unidade = null;
		this.filtro.id_unidade = null;
		this.unidades = [];
		this.search();
		this.getAllBlocosByCondominio(selectedCondominio.id);
	}

	public openCondominiosPequisaModal(): void {
		this.condominiosPesquisaModal.open();
	}

	public onSelectCondominiosPesquisaModal(condominio: Condominio): void {
		if (condominio) this.setCondominio(condominio);
		jQuery('#condominio').focus();
	}

	public onCleanCondominio(input: any): void {
		this.filtro.condominio = null;
		this.filtro.id_condominio = null;
		this.filtro.bloco = null;
		this.filtro.id_bloco = null;
		this.filtro.unidade = null;
		this.filtro.id_unidade = null;
		this.blocos = [];
		this.unidades = [];
		input.value = '';
		input.focus();
	}

	private loadCondominio(): void {
		if (!this.idCondominio) return;
		this.condominiosHttpService.get(this.idCondominio).subscribe(
			condominio => this.setCondominio(condominio),
			error => this.messageService.error('Erro ao carregar o condominio', 'Erro', error)
		);
	}

	// Bloco
	public onSelectBloco(tm: TypeaheadMatch): void {
		this.setBloco(tm.item as Bloco);
	}

	private setBloco(selectedBloco: Bloco): void {
		this.filtro.bloco = selectedBloco;
		this.filtro.id_bloco = selectedBloco.id;
		this.getAllUnidadesByBloco(selectedBloco.id);
	}

	public openBlocosPequisaModal(): void {
		this.blocosPesquisaModal.open();
	}

	public onSelectBlocosPesquisaModal(bloco: Bloco): void {
		if (bloco) this.setBloco(bloco);
		jQuery('#bloco').focus();
	}

	public onCleanBloco(input: any): void {
		this.filtro.bloco = null;
		this.filtro.id_bloco = null;
		this.filtro.unidade = null;
		this.filtro.id_unidade = null;
		this.unidades = [];
		input.value = '';
		input.focus();
	}

	// Unidade
	public onSelectUnidade(tm: TypeaheadMatch): void {
		this.setUnidade(tm.item as Unidade);
	}

	private setUnidade(selectedUnidade: Unidade): void {
		this.filtro.unidade = selectedUnidade;
		this.filtro.id_unidade = selectedUnidade.id;
	}

	public openUnidadesPequisaModal(): void {
		this.unidadesPesquisaModal.open();
	}

	public onSelectUnidadesPesquisaModal(unidade: Unidade): void {
		if (unidade) this.setUnidade(unidade);
		jQuery('#unidade').focus();
	}

	public onCleanUnidade(input: any): void {
		this.filtro.unidade = null;
		this.filtro.id_unidade = null;
		input.value = '';
		input.focus();
	}

	public getAll(): void {
		this.entities = [];
		this.destroyTable();
		this.comunicacoesHttpService.getAllByCondominio(this.idCondominio).subscribe(
			entity => {
				this.entities = entity;
				this.showTable();
			},
			error => this.messageService.error('Erro ao carregar as comunicações. Tente novamente', 'Erro', error)
		);
	}

	public search(): void {
		this.entities = [];
		this.destroyTable();
		this.comunicacoesHttpService.search(this.parseSearch(this.filtro)).subscribe(
			entities => {
				this.entities = entities;
				this.showTable();
			},
			error => this.messageService.error('Erro ao carregar as comunicações. Tente novamente', 'Erro', error)
		);
	}

	public setInitialFilter(): void {
		const today = new Date();
		const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
		const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
		this.filtro.id_cliente = this.idCliente;
		this.loadCondominio();
		this.filtro.data_comunicado_inicial = firstDay;
		this.filtro.data_comunicado_final = lastDay;
	}

	public delete(comunicacao: Comunicacao): void {
		if (confirm(`Tem certeza que deseja excluir a comunicação?`)) {
			this.comunicacoesHttpService.delete(comunicacao).subscribe(
				() => {
					this.messageService.success('Comunicação excluída com sucesso');
					this.search();
				},
				error => this.messageService.error('Erro', 'Erro ao excluir a comunicação', error)
			);
		}
	}

	private parseSearch(originalSearch: any): any {
		const search = { ...originalSearch };
		if (originalSearch.data_comunicado_inicial) {
			search.data_comunicado_inicial = this.datePipe.transform(originalSearch.data_comunicado_inicial, 'yyyy-MM-dd')
		} else {
			delete search.data_comunicado_inicial;
		}

		if (originalSearch.data_comunicado_final) {
			search.data_comunicado_final = this.datePipe.transform(originalSearch.data_comunicado_final, 'yyyy-MM-dd')
		} else {
			delete search.data_comunicado_final;
		}

		if (!originalSearch.id_condominio) {
			delete search.id_condominio;
		}

		if (!originalSearch.id_bloco) {
			delete search.id_bloco;
		}

		if (!originalSearch.id_unidade) {
			delete search.id_unidade;
		}

		delete search.condominio;
		delete search.bloco;
		delete search.unidade;

		return search;
	}

	/**
	 * Imprimir communicação
	 * @param entity 
	 * @author Marcos Frare
	 * @since 03/03/2021
	 */
	public print(entity: Comunicacao): void {
		this.relatorioModal.url = '/comunicacao/imprimir';
		this.relatorioModal.params = entity;
		this.relatorioModal.open();
	}


	/**
	 * Carrega los de envio de e-mail
	 * @param entity 
	 * @author Marcos Frare
	 * @since 08/03/2021
	 */
	public printLogEmail(entity: Comunicacao): void {
		this.relatorioModal.url = '/comunicacao/log-email/relatorio';
		this.relatorioModal.params = entity;
		this.relatorioModal.open();
	}
}