import { BaseEntity } from '../base-entity';

export class Garagem extends BaseEntity {
	id_unidade: number;
	fracao_solo: any;
	vaga: string;
	andar: number;

	constructor() {
		super();
		this.fracao_solo = 0;
	}
}