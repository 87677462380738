import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseVisualizacaoComponent } from '../../componentes-base';
import { AuthService, MessageService } from '../../core';

import { DemonstrativoRateio } from '../../models';
import { ArquivosRemessaHttpService } from './services';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { datepickerMesAnoOptions } from '../../shared';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'arquivos-remessa',
    templateUrl: './arquivos-remessa.component.html',
    styleUrls: ['./arquivos-remessa.component.css'],
    providers: [PermissionService, DatePipe],
})
export class ArquivosRemessaComponent extends BaseVisualizacaoComponent<DemonstrativoRateio> {
    
    public filtro: any = {};
    datepickerDataCompetenciaOptions = datepickerMesAnoOptions;

    constructor(activatedRoute: ActivatedRoute,
                router: Router,
                messageService: MessageService,
                private authService: AuthService,
                private arquivosRemessaHttpService: ArquivosRemessaHttpService,
                permissionService: PermissionService,
                private datePipe: DatePipe,
                public spinner: NgxSpinnerService) {
        super('#tableArquivosRemessa', arquivosRemessaHttpService, activatedRoute, router, messageService, permissionService, spinner);
    }

    ngOnInit() {
        this.setInitialFilter();
        this.getAll();
    }

    /** Carrega os arquivosRemessa do condomínio */
    getAll() {
        this.entities = [];
        this.destroyTable();
        this.arquivosRemessaHttpService.getAllByDataCompetencia(this.datePipe.transform(this.filtro.data_competencia, 'yyyy-MM-dd'))
            .subscribe(
                entities => {
                    this.entities = entities;
                    this.entities.map((entity: any) => entity.selected = entity.possui_titulo ? true : false);
                    this.showTable();
                },
                error => this.messageService.error('Erro ao carregar os arquivos remessa. Tente novamente', 'Erro', error)
            );
    }

    gerarCobranca(entity: DemonstrativoRateio) {
        this.router.navigate([`/rateios/${entity.id}/gerar-cobranca`], { relativeTo: this.activatedRoute, queryParams: {id_condominio: entity.id_condominio}});
    }

    public setInitialFilter(): void {
        const today = new Date();
        this.filtro.data_competencia = new Date(today.getFullYear(), today.getMonth(), 1);    
    }

    /** Ao alterar a data de competência */
    onChangeDataCompetencia() {
        // Sempre setando o dia da data de competência para o dia 1
        if (this.filtro.data_competencia) {
            this.filtro.data_competencia.setDate(1);
        }
    }

    public checkSelected(): boolean {
        let isSelected: boolean = false;
        this.entities.forEach(entity => {
            if (entity.selected && entity.possui_titulo) {
                isSelected = true;
            }
        });
        return isSelected;
    }

    public getFiles(): void {
        this.entities.filter(entity => entity.selected && entity.possui_titulo).map(entity => {
            this.arquivosRemessaHttpService.getFile(entity.id).subscribe(
                response => response,
                error => {
                    const competencia = this.datePipe.transform(entity.data_competencia, 'MM/yyyy') || 'Sem data';
                    const nome_condominio = entity.condominio || 'Sem nome';
                    this.messageService.error(
                        `Não existe arquivo a ser exibido para o rateio de ${competencia} do condomínio ${nome_condominio}.`, 'Erro', error);
                }
            );
        });
    }

}
