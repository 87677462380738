import { Injectable } from '@angular/core';

import { PagamentoUnidade } from '../../../../models';

/**
 * Service com métodos para trabalhar com o mês/data competência dos
 * pagamentos dos lançamentos
 */
@Injectable()
export class LancamentosUnidadesPagamentosDataCompetenciaService {

	/**
	 * Seta a data das próximas parcelas a partir da primera e acrescenta 1 mês
	 */
	setProximasDatas(dataVencimentoBase: Date, entities: PagamentoUnidade[]) {
		let lastDate = dataVencimentoBase;
		for (const entity of entities) {
			if (entity.numero_parcela === 1) {
				continue;
			}

			lastDate = new Date(lastDate.getFullYear(), lastDate.getMonth() + 1, lastDate.getDate());
			if (!isNaN(lastDate.getTime())) {
				entity.data_competencia = lastDate;
			} else {
				entity.data_competencia = null;
			}
		}
	}
}
