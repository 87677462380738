import { BaseEntity } from '../base-entity';

export class CondominioConfiguracaoReserva extends BaseEntity {
	id_condominio: number;
	multipla_reserva_data: number;

	constructor() {
		super();
		this.id_condominio = null;
		this.multipla_reserva_data = 1;
	}
}