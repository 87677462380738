import { AfterViewInit, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgForm } from '@angular/forms';

import { BaseEntity } from '../models';
import { Crud, MessageService, notCloseMessageOptions } from '../core';
import { Permission } from './permission';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { Subscription } from 'rxjs';

declare var jQuery: any;

export class BaseCadastroComponent<T extends BaseEntity> implements OnInit, AfterViewInit, OnDestroy {

	btnSave: any;
	entity: T;
	editMode: boolean;
	public permissions: Permission;
	public resource: string;
	@ViewChild('form') form: NgForm; // variavel do form do cadastro

	private subscriptions: Subscription[] = [];
	
	constructor(private ctor: { new(): T },
			protected crudService: Crud<T>,
			protected activatedRoute: ActivatedRoute,
			protected router: Router,
			protected messageService: MessageService,
			protected permissionService?: PermissionService) {
		this.resource = activatedRoute.data['value']['resource'];
	}

	ngOnInit() {
		if (this.entity == null) {
			this.entity = new this.ctor();
		}
		this.subscriptions.push(this.activatedRoute.params.subscribe((params: Params) => {
			let id = +params['id']; // Convertendo o id para um number
			if (!isNaN(id)) { // Possui id informado na rota = edit
				this.editMode = true;
				this.load(id);
			} else { // Não possui id na rota = insert
				this.editMode = false;
				this.entity = new this.ctor();
				this.afterInsert();
			}
		}));

		this.getPermissions();
	}

	ngOnDestroy(): void {
		try {
			this.subscriptions.map(s => s.unsubscribe());
		} catch(err) {
			console.error(err);
		}
	}

	ngAfterViewInit() {
		this.btnSave = jQuery('#btnSave');
		// Valida se o cadastro possui a váriavel de template do form com o nome #form (nome padrão dos formulário)
		if (!this.form) {
			this.messageService.warning('É necessário criar uma variável de template com nome #form',
				'Variável de template (#form) não encontrada',
				notCloseMessageOptions);
		}
	}

	// Carrega a entidade para editar
	load(id: number) {
		this.subscriptions.push(this.crudService.get(id).subscribe(
			entity => {
				this.entity = entity;
				this.afterEdit(this.entity);
			},
			error => {
				if (error.status === 400) {
					this.messageService.info(error._body, 'Informação');
				} else {
					this.messageService.error('Erro ao carregar o registro', 'Erro', error);
				}
			}
		));
	}

	afterEdit(entity: T) { }

	afterInsert() { }

	/**
	 * Torna todos os campos do formulário "sujos" para exibir as mensagens de campos inválidos
	 */
	makeAllFieldsDirty() {
		for (const key in this.form.controls) {
			if (this.form.controls.hasOwnProperty(key)) {
				const control = this.form.controls[key];
				control.markAsDirty();
			}
		}
	}

	beforeSave(): boolean {
		return true;
	}

	save(entitySave: T = this.entity): Promise<T> {
		return new Promise<T>((resolve, reject) => {
			this.makeAllFieldsDirty();

			if (this.form.valid) {
				if (this.beforeSave()) {
					this.btnSave.button('loading');

					if (this.editMode) { // PUT
						this.subscriptions.push(this.crudService.put(entitySave).subscribe(
							entity => {
								this.messageService.success('', 'Registro salvo com sucesso');
								this.afterSave(entity);
								resolve(entity);
							},
							error => {
								this.messageService.error('Erro ao atualizar o registro', 'Erro', error);
								this.btnSave.button('reset');
								reject(null);
							}
						));
					} else { // POST
						this.subscriptions.push(this.crudService.post(entitySave).subscribe(
							entity => {
								this.messageService.success('', 'Registro salvo com sucesso');
								this.afterSave(entity);
								resolve(entity);
							},
							error => {
								this.messageService.error('Erro ao salvar o registro', 'Erro', error);
								this.btnSave.button('reset');
								reject(null);
							}
						));
					}
				} else {
					reject(null);
				}
			} else {
				this.showMessageFormInvalid();
				reject(null);
			}
		});
	}

	/**
	 * Exibe mensagem que o formulário está invalido ao usuário
	 */
	showMessageFormInvalid() {
		this.messageService.info('Para salvar é preciso informar corretamente todos os campos destacados em vermelho', 'Informação');
	}

	afterSave(entity: T) {
		this.btnSave.button('reset');
		this.cancel();
	}

	cancel() {
		this.router.navigate(['../'], { relativeTo: this.activatedRoute });
	}

	public getPermissions() {
		if (this.permissionService) {
			this.subscriptions.push(this.permissionService.getPermissions(this.resource).subscribe(permissions => this.permissions = permissions));
		}
	}

}