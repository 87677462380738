import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseCadastroComponent } from '../../../componentes-base';
import { AuthService, MessageService } from '../../../core';
import {
	Bloco,
	Cliente, Condominio,
	destinatarioOrcamento,
	Orcamento,
	RemetentesOrcamento,
	TIPO_USUARIO,
	TIPOS_REMETENTEORCAMENTO,
	tiposItem,
	tiposOrcamento, Unidade,
	Usuario,
	Anexo
} from '../../../models';
import { OrcamentosHttpService } from '../services';
import { PermissionService } from '../../../core/services/permission/permission.service';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import { FornecedoresHttpService } from 'app/home/fornecedores';
import { TiposDestinatariosPipe } from '../../../shared/pipes/tipos-destinatarios';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { BlocosHttpService, BlocosPesquisaModalComponent, UnidadesHttpService, UnidadesPesquisaModalComponent } from 'app/home/blocos';
import { DatePipe } from '@angular/common';
import { ClientesHttpService } from 'app/home/clientes';

declare var jQuery: any;

@Component({
	selector: 'orcamentos-cadastro',
	templateUrl: './orcamentos-cadastro.component.html',
	styleUrls: ['./orcamentos-cadastro.component.css'],
	providers: [
		PermissionService,
		TiposDestinatariosPipe,
		ClientesHttpService,
		DatePipe
	]
})
export class OrcamentosCadastroComponent extends BaseCadastroComponent<Orcamento> implements OnInit {

	@ViewChild('blocosPesquisaModal') blocosPesquisaModal: BlocosPesquisaModalComponent;
	@ViewChild('inputUnidade') inputUnidade: ElementRef;
	@ViewChild('unidadesPesquisaModal') unidadesPesquisaModal: UnidadesPesquisaModalComponent;


	public idCliente: number;
	public idCondominio: number;
	public btnSaveAndSend: any;
	public multiselectSettings: IMultiSelectSettings = {
		enableSearch: true,
		containerClasses: 'container-multiselect',
		showUncheckAll: true,
		dynamicTitleMaxItems: 3,
		checkedStyle: 'checkboxes',
		buttonClasses: 'btn btn-default',
		itemClasses: 'dropdown-menu'
	};
	public unidades: Unidade[] = [];
	public blocos: Bloco[] = [];
	public tipoRemetenteOrcamento = TIPOS_REMETENTEORCAMENTO;
	public tiposRemetentesOrcamento: any[] = RemetentesOrcamento;
	public destinatarioOrcamento: any[] = destinatarioOrcamento;
	public destinatarios: any[];
	public fornecedor: any[];
	public fornecedores: any[];
	public tipoOrcamento: any[];
	public remetente: any[];
	public currentUser: Usuario;
	public cliente: Cliente;
	public condominio: Condominio;
	public tiposItem: any[] = tiposItem;
	public idUnidadeAtual: number;
	public tiposOrcamento: any[] = tiposOrcamento;
	public multiselectOptionsFornecedores: IMultiSelectOption[];
	public multiselectTextsFornecedores: IMultiSelectTexts = {
		checkAll: 'Selecionar todos',
		uncheckAll: 'Limpar',
		checked: 'selecionado',
		checkedPlural: 'selecionados',
		searchPlaceholder: 'Pesquisar...',
		defaultTitle: 'Selecionar',
		allSelected: 'Todas selecionados',
		searchEmptyResult: 'Nenhum registro encontrado',
		searchNoRenderText: 'Digite para pesquisar',
	};

	constructor(activatedRoute: ActivatedRoute,
		router: Router,
		messageService: MessageService,
		permissionService: PermissionService,
		orcamentosHttpService: OrcamentosHttpService,
		private clientesHttpService: ClientesHttpService,
		private authService: AuthService,
		private tiposDestinatariosPipe: TiposDestinatariosPipe,
		private fornecedoresHttpService: FornecedoresHttpService,
		private unidadesHttpService: UnidadesHttpService,
		private datePipe: DatePipe,
		private blocosHttpService: BlocosHttpService) {
		super(Orcamento, orcamentosHttpService, activatedRoute, router, messageService, permissionService);
		this.currentUser = this.authService.getCurrentUser();
	}

	ngOnInit() {
		this.idCliente = this.authService.getIdCurrentCliente();
		this.idCondominio = this.authService.getIdCurrentCondominio();
		this.condominio = this.authService.getCurrentCondominio();
		this.getClienteById(this.idCliente);
		this.getAllFornecedoresByCliente();
		this.getAllBlocosByCondominio();
		super.ngOnInit();
	}

	private getAllFornecedoresByCliente(): void {
		this.fornecedoresHttpService.getAllByCliente(this.idCliente).subscribe(
			entities => this.multiselectOptionsFornecedores = entities.map(entity => ({ id: entity.id, name: entity.nome })),
			error => this.messageService.error('Erro ao carregar os fornecedores', 'Erro', error)
		);
	}

	public beforeSave(): boolean {
		if (!this.editMode) {
			this.entity.id_condominio = this.idCondominio;
		}
		return true;
	}

	public onSaveClick(): void {
		this.entity.email = false;
		this.btnSave = jQuery('#btnSave');
		this.btnSave.button('loading');
		this.save();
	}

	public onSaveAndSendClick(): void {
		this.entity.email = true;
		this.btnSaveAndSend = jQuery('#btnSaveAndSend');
		this.btnSaveAndSend.button('loading');
		this.save();
	}

	public save(): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.makeAllFieldsDirty();

			if (this.form.valid) {
				if (this.beforeSave()) {
					const envio: any = { ...this.entity };

					if (this.editMode) { // PUT
						this.crudService.put(envio)
							.subscribe(
								entity => {
									this.messageService.success('', 'Registro salvo com sucesso');
									this.afterSave(this.entity);
									resolve(entity);
								},
								error => {
									this.messageService.error('Erro ao atualizar o registro', 'Erro', error);
									this.btnSave.button('reset');
									this.btnSaveAndSend.button('reset');
									reject(null);
								}
							);
					} else { // POST
						this.crudService.post(envio)
							.subscribe(
								entity => {
									this.entity = entity;
									this.messageService.success('', 'Registro salvo com sucesso');
									this.afterSave(this.entity);
									resolve(entity);
								},
								error => {
									this.messageService.error('Erro ao salvar o registro', 'Erro', error);
									this.btnSave.button('reset');
									this.btnSaveAndSend.button('reset');
									reject(null);
								}
							);
					}

				} else {
					this.btnSave.button('reset');
					this.btnSaveAndSend.button('reset');
					reject(null);
				}
			} else {
				this.btnSave.button('reset');
				this.btnSaveAndSend.button('reset');
				this.showMessageFormInvalid();
				reject(null);
			}
		});
	}

	public afterSave(entity: Orcamento): void {
		super.afterSave(entity);
	}

	public cancel(): void {
		this.router.navigate(['../'], { relativeTo: this.activatedRoute });
	}

	public addAnexos(): void {
		this.entity.arquivo.push(new Anexo());
	}

	public removeAnexos(index): void {
		this.entity.arquivo.splice(index, 1);
	}

	private getClienteById(idCliente: number): void {
		this.clientesHttpService.get(idCliente).subscribe(
			cliente => {
				this.cliente = cliente;
				if (!this.condominio) { this.entity.remetente = this.cliente.nome_fantasia; }
			},
			error => this.messageService.error('Erro ao carregar a administradora do usuário. Favor contate o administrador do sistema', 'Erro', error)
		);
	}


	public onChangeTipoRemetente(): void {
		switch (this.entity.tipo_remetente) {
			case TIPOS_REMETENTEORCAMENTO.ADMINISTRADORA:
				this.entity.remetente = this.cliente.nome_fantasia;
				break;
			case TIPOS_REMETENTEORCAMENTO.CONDOMINIO:
				this.entity.remetente = this.condominio.nome_fantasia;
				break;
		}
	}

	//Bloco
	public onSelectBloco(tm: TypeaheadMatch): void {
		this.setBloco(tm.item as Bloco);
	}

	private setBloco(selectedBloco: Bloco): void {
		this.entity.bloco = selectedBloco;
		this.entity.id_bloco = selectedBloco.id;
		this.getAllUnidadesByBloco(this.entity.id_bloco);
		this.onCleanUnidade(this.inputUnidade.nativeElement);
	}

	public openBlocosPequisaModal(): void {
		this.blocosPesquisaModal.open();
	}

	public onCleanBloco(input: any): void {
		this.entity.bloco = null;
		input.value = '';
		input.focus();
	}

	public onSelectBlocosPesquisaModal(bloco: Bloco): void {
		if (bloco) { this.setBloco(bloco); };
		jQuery('#bloco').focus();
	}

	private getAllBlocosByCondominio(): void {
		this.blocosHttpService.getAllByCondominio(this.idCondominio).subscribe(
			blocos => this.blocos = blocos,
			error => this.messageService.error('Erro ao carregar os blocos do condomínio. Tente novamente', 'Erro', error)
		);
	}
	//Unidade
	public onSelectUnidade(tm: TypeaheadMatch): void {
		this.setUnidade(tm.item as Unidade);
	}

	private setUnidade(selectedUnidade: Unidade): void {
		this.entity.unidade = selectedUnidade;
		this.entity.id_unidade = selectedUnidade.id;
	}

	public openUnidadesPequisaModal(): void {
		this.unidadesPesquisaModal.open();
	}

	public onSelectUnidadesPesquisaModal(unidade: Unidade): void {
		if (unidade) this.setUnidade(unidade);
		jQuery('#unidade').focus();
	}

	public onCleanUnidade(input: any): void {
		this.entity.unidade = null;
		this.entity.id_unidade = null;
		input.value = '';
		input.focus();
	}

	public getAllUnidadesByBloco(idBloco: number) {
		this.unidades = [];
		this.unidadesHttpService.getAllByBloco(idBloco).subscribe(
			entities => {
				for (const unidade of entities) {
					if (unidade.nome) {
						this.unidades.push(unidade);
					}
				}
				if (this.idUnidadeAtual && !isNaN(this.idUnidadeAtual)) {
					const index = this.unidades.findIndex(entity => entity.id === this.idUnidadeAtual);
					if (index > -1) {
						this.setUnidade(this.unidades[index]);
					}
				}
			},
			error => this.messageService.error('Erro ao carregar as unidades do bloco. Tente novamente', 'Erro', error)
		);
	}

	//Print Section
	public getNameFornecedores(): string[] {
		const fornecedores: string[] = [];
		this.entity.fornecedores.forEach(idFornecedor => {
			const fornecedor = this.multiselectOptionsFornecedores.find(it => it.id === idFornecedor);
			if (fornecedor) {
				fornecedores.push(fornecedor.name);
			}
		});
		return fornecedores;
	}

	public getNameTipoOrcamento(tipoOrcamento: number): string {
		return tiposOrcamento.find(tipo => tipo.id === tipoOrcamento).nome;
	}

	public getNameTipoRemetente(tipoRemetente: number): string {
		return RemetentesOrcamento.find(remetente => remetente.id === tipoRemetente).nome;
	}

	public getNameTipoItem(tipoItem: number): string {
		return tiposItem.find(item => item.id === tipoItem).nome;
	}

	public getNomesDestinatarios(): any[] {
		return this.destinatarioOrcamento.filter(destinatario => destinatario.checked).map(destinatario => destinatario.nome);
	}

	public print(): boolean {
		let printableWindow = window.open('', 'PRINT', 'height=400,width=600');
		printableWindow.document.close();

		printableWindow.document.write(
			`<html>
                <head>
    <style>
    .loading-container {
        height: 100%;
        position: relative;
    }
    .loading-container{
        color: #676a6c;
    }
    .loading-container{
        margin-top: 10px;
    }
    .page {
        display: block;
        margin: 0 auto;
    }

    .page[size="A4"] {
        width: 21cm;
        height: 29.7cm;
    }

    .page[size="A4"][layout="portrait"] {
        width: 29.7cm;
        height: 21cm;
    }

    h1 {
        text-align: center;
        margin-bottom: 05%;
        padding-top: 05%;
    }

    h5{
        margin-bottom: 7px;
    }

    .conteudo {
        display:flex;
        justify-content: space-between;
        /*margin-left: 20%*/
    }

    .conteudo > div,
    .conteudo-final > div{
        width: 50%;
    }

    .conteudo-final{
        display:flex;
        justify-content: space-between;
        /*margin-left: 20%;*/
    }

    .descricao {
        /*margin-left: 20%;*/
    }
    </style>
                </head>
                <body>
                   <div class="loading-container" *ngIf="!isLoading">
                    <div class="col-xs-12">
                    <div id="print-section" class="pages-container">
                        <ng-template ngFor let-dado_titulo [ngForOf]="dados_titulo">
                            <div class="page" size="A4">
                                <h1>Solicitação de Orçamento</h1>
                                <div class="conteudo">
                                    <div>
                                        <div>
                                            <h5>De: </h5>
                                            <span>${this.condominio.nome_fantasia}</span>
                                        </div>
                                        <div>
                                            <h5>Para: </h5>
                                            <span>${this.getNomesDestinatarios().length ? this.getNomesDestinatarios() : 'Todos'}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <h5>Tipo de Orçamento: </h5>
                                            <span>${this.getNameTipoOrcamento(this.entity.tipo_orcamento)}</span>
                                        </div>
                                        <div>
                                            <h5>Tipo de Remetente: </h5>
                                            <span>${this.getNameTipoRemetente(this.entity.tipo_remetente)}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="conteudo">
                                    <div>
                                        <div>
                                            <span>${this.renderBlocoUnidade()}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <h5>Prazo de Entrega</h5>
                                            <span>${this.datePipe.transform(this.entity.prazo_entrega, 'dd/MM/yyyy')}</span>
                                        </div>
                                        <div>
                                            <h5>Data do Orçamento</h5>
                                            <span>${this.datePipe.transform(this.entity.data_orcamento, 'dd/MM/yyyy')}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="conteudo">
                                    <div>
                                        <h5>Tipo de Item</h5>
                                        <span>${this.getNameTipoItem(this.entity.tipo_orcamento).length ? this.getNameTipoItem(this.entity.tipo_orcamento) : 'Todos'}</span>
                                    </div>
                                    <div>
                                        <h5>Fornecedores</h5>
                                        <span>${this.getNameFornecedores()}</span>
                                        </div>
                                     </div>
                                     <div>
                                        <h5>Descrição</h5>
                                        <span>${this.entity.descricao}</span>
                                    </div>
                                </div>
                </body>
            </html>

        `);
		printableWindow.focus();

		printableWindow.print();
		printableWindow.close();

		return true;
	}

	renderBlocoUnidade(): string {
		if (this.currentUser.tipo === TIPO_USUARIO.FUNCIONARIO) {
			return `
            <div>
                <h5>Unidade: </h5>
                <span>${this.entity.unidade ? this.entity.unidade.nome : 'Todas'}</span>
            </div>
            <div>
                <h5>Bloco: </h5>
                <span>${this.entity.bloco ? this.entity.bloco.nome : 'Todos'}</span>
            </div>
        `;
		} return '';
	}
}