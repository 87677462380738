import { BaseEntity } from '../base-entity';
import { CategoriaContas } from './categoria-contas';
import { Titulo } from './titulo';

export class Renegociacao extends BaseEntity {
	valor: number;
	parcelas: ParcelasRenegociacao[];
	parcelasEntrada: ParcelasRenegociacao[];
	itens: ItensRenegociacao[];
	data_vencimento: any;
	quantidade_parcelas: number;
	ids_titulos: number[];
	desconto_juros: boolean;
	desconto_multa: boolean;
	id_condominio: number;
	id_unidade: number;
	observacao: string;
	observacaoItem: string;
	observacaoisencaojurosparcelamento: string;
	numero: number;
	arTitulo: Titulo[];
	totalTitulos: any;
	isensaojurosparcelamento: boolean;
	isensaojurosentrada: boolean;

	constructor() {
		super();
		this.valor = null;
		this.parcelas = [];
		this.parcelasEntrada = [];
		this.data_vencimento = null;
		this.quantidade_parcelas = null;
		this.ids_titulos = [];
		this.desconto_juros = false;
		this.desconto_multa = false;
		this.id_condominio = null;
		this.id_unidade = null;
		this.itens = [];
		this.observacao = "";
		this.observacaoItem = "";
		this.observacaoisencaojurosparcelamento = "";
		this.numero = 1;
		this.itens.push(new ItensRenegociacao());
		this.arTitulo = [];
		this.isensaojurosparcelamento = false;
		this.isensaojurosentrada = false;
		this.totalTitulos = {
			valor_original: 0,
			valor_unitario: 0
		};
	}
}

export class ParcelasRenegociacao extends BaseEntity {
	numero: number;
	valor_capital: number;
	valor_juros: number;
	valor: number;
	data_vencimento: string;
	quitado: boolean;

	constructor() {
		super();
		this.valor_capital = 0;
		this.valor_juros = 0;
		this.valor = 0;
		this.quitado = false;
	}
}

export class ItensRenegociacao extends BaseEntity {
	id_renegociacao: number;
	id_categoria_conta: number;
	nome: string;
	tipo: number;
	valor: any;
	categoriaContas: CategoriaContas;
	descricao: string;

	constructor() {
		super();
		this.id_renegociacao = null;
		this.id_categoria_conta = null;
		this.valor = 0;
		this.tipo = null;
		this.categoriaContas = null;
		this.descricao = null;
	}
}

export const tiposItensRenegociacao = [
	{ id: 1, nome: 'Custo' },
	{ id: 2, nome: 'Desconto' }
];