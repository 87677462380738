import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseCadastroComponent } from '../../../componentes-base';
import { AuthService, MessageService } from '../../../core';
import { Fornecedor, Seguro, CategoriaContas } from '../../../models';
import { SegurosHttpService } from '../services';
import { FornecedoresHttpService, FornecedoresCadastroModalComponent } from '../../fornecedores';
import { PermissionService } from '../../../core/services/permission/permission.service';
import { FloatFormatPipe } from '../../../shared/pipes';
import { CategoriaContasHttpService, CategoriaContasPesquisaModalComponent } from 'app/home/plano-de-contas/categoria-contas';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { PlanoDeContasCadastroModalComponent } from 'app/home/plano-de-contas/cadastro-modal/plano-de-contas-cadastro-modal.component';
import {Anexo} from '../../../models/entidades/anexo';

declare var jQuery: any;

@Component({
    selector: 'seguros-cadastro',
    templateUrl: './seguros-cadastro.component.html',
    styleUrls: ['./seguros-cadastro.component.css'],
    providers: [
        PermissionService,
        FloatFormatPipe
    ]
})
export class SegurosCadastroComponent extends BaseCadastroComponent<Seguro> implements OnInit {

    @ViewChild('fornecedoresCadastroModal') fornecedoresCadastroModal: FornecedoresCadastroModalComponent;
    @ViewChild('categoriaContasPesquisaModal') categoriaContasPesquisaModal: CategoriaContasPesquisaModalComponent;
    @ViewChild('planoDeContasCadastroModal') planoDeContasCadastroModal: PlanoDeContasCadastroModalComponent;

    public categoriasContas: CategoriaContas[] = [];
    public fornecedores: Fornecedor[] = [];
    public idCliente: number;
    public idCondominio: number;

    constructor(activatedRoute: ActivatedRoute,
                router: Router,
                messageService: MessageService,
                permissionService: PermissionService,
                segurosHttpService: SegurosHttpService,
                private authService: AuthService,
                private fornecedoresHttpService: FornecedoresHttpService,
                private floatFormatPipe: FloatFormatPipe,
                private categoriaContasHttpService: CategoriaContasHttpService) {
        super(Seguro, segurosHttpService, activatedRoute, router, messageService, permissionService);
    }

    ngOnInit() {
        this.idCliente = this.authService.getIdCurrentCliente();
        this.idCondominio = this.authService.getIdCurrentCondominio();
        this.getAllFornecedoresByCliente();
        this.getAllCategoriasByCondominio();
        super.ngOnInit();
    }

    private getAllFornecedoresByCliente(): void {
        this.fornecedoresHttpService.getAllByCliente(this.idCliente).subscribe(
            entities => this.fornecedores = entities,
            error => this.messageService.error('Erro ao carregar os fornecedores', 'Erro', error)
        );
    }

    private getAllCategoriasByCondominio(): void {
        this.categoriasContas = [];
        this.categoriaContasHttpService.getAllByCondominio(this.idCondominio, 2)
            .then(entities => this.categoriasContas = entities)
            .catch(() => this.messageService.info(`Configuração do condomínio não encontrada. Configure o condomínio para prosseguir.`, 'Informação')
        );
    }

    private setCategoriaContas(selectedCategoriaConta: CategoriaContas): void {
        this.entity.categoriaContas = selectedCategoriaConta;
        this.entity.id_categoria_conta = selectedCategoriaConta.id;
    }

    public afterEdit(entity: Seguro): void {
        entity.valor_assegurado = this.floatFormatPipe.transform(entity.valor_assegurado);
        if (!entity.anexos.length) {
            this.addAnexos();
        }
        this.loadCategoriaConta(entity);
    }

    public createFornecedor(): void {
        this.fornecedoresCadastroModal.open();
    }

    public onAfterSaveFornecedor(fornecedor: Fornecedor): void {
        this.entity.id_fornecedor = fornecedor.id;
        this.fornecedores.push(fornecedor);
        jQuery('#fornecedor').focus();
    }

    private loadCategoriaConta(entity: Seguro) {
        if (!entity.id_categoria_conta) return;
        this.categoriaContasHttpService.get(entity.id_categoria_conta).subscribe(
            categoriaContas => this.setCategoriaContas(categoriaContas),
            error => this.messageService.error('Erro ao carregar a categoria de conta', 'Erro', error)
        );
    }

    public onSelectCategoriaContas(tm: TypeaheadMatch): void {
        const categoriaContas: CategoriaContas = tm.item as CategoriaContas;
        if (categoriaContas) {
            this.entity.id_categoria_conta = categoriaContas.id;
            this.entity.categoriaContas = categoriaContas;
            this.entity.id_fornecedor = categoriaContas.id_fornecedor;
        } else {
            this.entity.id_categoria_conta = null;
            this.entity.categoriaContas = null;
        }
    }

    public onCleanCategoriaContas(input: any): void {
        this.entity.id_categoria_conta = null;
        this.entity.categoriaContas = null;
        input.value = '';
        input.focus();
    }

    public openCategoriaContasPequisaModal(): void {
        this.categoriaContasPesquisaModal.open();
    }

    public onSelectCategoriaContasPesquisaModal(categoriaContas: CategoriaContas): void {
        if (categoriaContas) {
            this.entity.id_categoria_conta = categoriaContas.id;
            this.entity.categoriaContas = categoriaContas;
            this.entity.id_fornecedor = categoriaContas.id_fornecedor;
        }
        jQuery('#categoriaContas').focus();
    }

    public openPlanoDeContasCadastroModal(): void {
        this.planoDeContasCadastroModal.open();
    }

    public onAfterSaveContaCategoriaConta(categoriaConta: CategoriaContas): void {
        this.entity.id_categoria_conta = categoriaConta.id;
        this.entity.categoriaContas = categoriaConta;
    }

    public beforeSave(): boolean {
        if (!this.editMode) {
            this.entity.id_condominio = this.idCondominio;
        }
        this.validateAnexos();
        return true;
    }

    private validateAnexos(): void {
        if (this.entity.anexos) {
            this.entity.anexos = this.entity.anexos
                .filter(anexo => anexo && anexo.arquivo);
        }
    }

    public save(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.makeAllFieldsDirty();

            if (this.form.valid) {
                if (this.beforeSave()) {
                    this.btnSave.button('loading');

                    const envio: any = {...this.entity};

                    if (this.editMode) { // PUT
                        this.crudService.put(envio)
                            .subscribe(
                                entity => {
                                    this.messageService.success('', 'Registro salvo com sucesso');
                                    this.afterSave(this.entity);
                                    resolve(entity);
                                },
                                error => {
                                    this.messageService.error('Erro ao atualizar o registro', 'Erro', error);
                                    this.btnSave.button('reset');
                                    reject(null);
                                }
                            );
                    } else { // POST
                        this.crudService.post(envio)
                            .subscribe(
                                entity => {
                                    this.entity = entity;
                                    this.messageService.success('', 'Registro salvo com sucesso');
                                    this.afterSave(this.entity);
                                    resolve(entity);
                                },
                                error => {
                                    this.messageService.error('Erro ao salvar o registro', 'Erro', error);
                                    this.btnSave.button('reset');
                                    reject(null);
                                }
                            );
                    }

                } else {
                    reject(null);
                }
            } else {
                this.showMessageFormInvalid();
                reject(null);
            }
        });
    }

    public afterSave(entity: Seguro): void {
        entity.valor_assegurado = this.floatFormatPipe.transform(entity.valor_assegurado);
        super.afterSave(entity);
    }

    public cancel(): void {
        this.router.navigate(['../'], {relativeTo: this.activatedRoute});
    }

    public addAnexos(): void {
        this.entity.anexos.push(new Anexo());
    }

    public removeAnexos(index): void {
        this.entity.anexos.splice(index, 1);
    }
}
