import { Injectable } from '@angular/core';
import { AuthService, ConfigService, Crud, CrudService, CustomHttp } from 'app/core';
import { CategoriaContaGrupo } from 'app/models';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs-compat/observable/throw';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoriaContaGrupoHttpService implements Crud<CategoriaContaGrupo> {

  resource: string = 'categoriacontas/grupo';
  private baseUrl: string;
  private url: string;

  constructor(private customHttp: CustomHttp,
      private crudService: CrudService,
      private configService: ConfigService,
      private authService: AuthService) {
    this.url = configService.getBaseUrl();
    this.baseUrl = `${this.url}/${this.resource}`;
  }

  get(id: number): Observable<CategoriaContaGrupo> {
    return this.crudService.get<CategoriaContaGrupo>(this.resource, id);
  }
  getAll(): Observable<CategoriaContaGrupo[]> {
    let id_condominio = this.authService.getIdCurrentCondominio();
    return this.crudService.getAll<CategoriaContaGrupo>(this.resource, {params: {id_condominio}});
  }
  post(entity: CategoriaContaGrupo): Observable<CategoriaContaGrupo> {
    return this.crudService.post<CategoriaContaGrupo>(this.resource, entity);
  }
  put(entity: CategoriaContaGrupo): Observable<CategoriaContaGrupo> {
    return this.crudService.put<CategoriaContaGrupo>(this.resource, entity);
  }
  delete(entity: CategoriaContaGrupo): Observable<any> {
    return this.customHttp.delete(`${this.baseUrl}/excluir/${entity.id}`).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
  }

}