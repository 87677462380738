import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { Endereco, Cidade } from '../../models';
import { MessageService } from '../../core';
import { EnderecoHttpService } from './services';
import { CidadeHttpService } from '../cidade';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { mergeMap } from 'rxjs/operators';

declare var jQuery: any;

@Component({
    selector: 'endereco',
    templateUrl: './endereco.component.html'
})
export class EnderecoComponent implements OnInit {

    public entityValue: Endereco;
    public typeaheadCidadeToken: string;
    public dataSourceCidades: Observable<Cidade[]>;
    @Input() permiteEditar = true;
    @Output() entityChange: EventEmitter<Endereco> = new EventEmitter();
    @ViewChild('form') form: NgForm;

    constructor(private messageService: MessageService,
                private enderecoHttpService: EnderecoHttpService,
                private cidadeHttpService: CidadeHttpService) {
        this.dataSourceCidades = this.createDataSourceCidades();
    }

    @Input()
    get entity() {
      return this.entityValue;
    }
    set entity(val) {
        this.entityValue = val;
        this.entityChange.emit(val);
    }

    private createDataSourceCidades(): Observable<Cidade[]> {
        return Observable.create((observer: any) => {
                    observer.next(this.typeaheadCidadeToken);
                }).pipe(
                    mergeMap(() => this.cidadeHttpService.getAllByNome(this.typeaheadCidadeToken))
                );
    }

    ngOnInit() {
        if (!this.entity) {
            this.entity = new Endereco();
        }
    }

    public onChangeCep(): void {
        if (this.entity.cep && this.entity.cep.length === 9 ) {
            this.getAddressByCep(this.entity.cep);
        }
    }

    public getAddressByCep(cep): void {
        this.enderecoHttpService.getAddressByCep(cep).subscribe(
            (response: any) => {
                this.entity = response;
                this.entity.cep = cep;
                this.getCidadeByName(response.id_cidade);
                jQuery('#numero').focus();
            },
            error => {
                this.messageService.warning(
                    'Não foi possível localizar o endereço com o CEP informado, verifique se o mesmo foi digitado corretamente',
                    'CEP é inexistente');
                jQuery('#numero').focus();
            }
        );
    }

    private getCidadeByName(idCidade: number): void {
        this.cidadeHttpService.get(idCidade).subscribe(
            cidade => this.entity.cidade = cidade
        );
    }

    public onSelectCidade(tm: TypeaheadMatch): void {
        const cidade: Cidade = tm.item as Cidade;
        if (cidade) {
            this.entity.id_cidade = cidade.id;
            this.entity.cidade = cidade;
        } else {
            this.entity.id_cidade = null;
            this.entity.cidade = null;
        }
    }

    public onCleanCidade(input: any): void {
        this.entity.id_cidade = null;
        this.entity.cidade = null;
        input.value = '';
        input.focus();
    }

    public makeAllFieldsDirty(): void {
        for (const key in this.form.controls) {
            if (this.form.controls.hasOwnProperty(key)) {
                const control = this.form.controls[key];
                control.markAsDirty();
            }
        }
    }

    public validate(): boolean {
        if (!this.entity.cep) { return false; };
        if (!this.entity.rua) { return false; };
        if (!this.entity.numero) { return false; };
        if (!this.entity.bairro) { return false; };
        if (!this.entity.id_cidade) { return false; };
        return true;
    }

    public save(): Promise<Endereco> {
        return new Promise((resolve, reject) => {
            this.makeAllFieldsDirty();

            if (this.form.valid) {
                if (this.entity.id) {
                    this.enderecoHttpService.put(this.entity)
                        .subscribe(
                            endereco => {
                                resolve(endereco);
                            },
                            error => {
                                this.messageService.error('Erro ao salvar o endereço', 'Erro', error);
                                reject(null);
                            }
                        );
                } else {
                    this.enderecoHttpService.post(this.entity)
                        .subscribe(
                            endereco => {
                                this.entity.id = endereco.id;
                                resolve(endereco);
                            },
                            error => {
                                this.messageService.error('Erro ao salvar o endereço', 'Erro', error);
                                reject(null);
                            }
                        );
                }
            } else {
                 this.messageService.info(
                     'Para salvar é preciso informar corretamente todos os campos destacados em vermelho',
                     'Informação');
                 reject(null);
            }
        });
    }
}
