export const cssResumo = `
h2 {
    margin: 0;
}

ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, 
blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

.table-bordered {
    border: 1px solid #EBEBEB;
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}

.table > thead > tr > th, 
.table > tbody > tr > th, 
.table > tfoot > tr > th, 
.table > thead > tr > td, 
.table > tbody > tr > td, 
.table > tfoot > tr > td {
    border-top: 1px solid #e7eaec;
    line-height: 1.42857;
    padding: 8px;
    vertical-align: top;
}

.table > thead > tr > th {
    border-bottom: 1px solid #DDDDDD;
    vertical-align: bottom;
}

.table-bordered > thead > tr > th, 
.table-bordered > tbody > tr > th, 
.table-bordered > tfoot > tr > th, 
.table-bordered > thead > tr > td, 
.table-bordered > tbody > tr > td, 
.table-bordered > tfoot > tr > td {
    border: 1px solid #e7e7e7;
}

.table-bordered > thead > tr > th, 
.table-bordered > thead > tr > td {
    background-color: #F5F5F6;
    border-bottom-width: 1px;
}

.table-bordered>thead>tr>td, 
.table-bordered>thead>tr>th {
    border-bottom-width: 2px;
}

.table-bordered>tbody>tr>td, 
.table-bordered>tbody>tr>th, 
.table-bordered>tfoot>tr>td, 
.table-bordered>tfoot>tr>th, 
.table-bordered>thead>tr>td, 
.table-bordered>thead>tr>th {
    border: 1px solid #ddd;
}

.table>thead>tr>th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd;
}

.table>tbody>tr>td, 
.table>tbody>tr>th, 
.table>tfoot>tr>td, 
.table>tfoot>tr>th, 
.table>thead>tr>td, 
.table>thead>tr>th {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd;
}

main:first-of-type {
    page-break-after: always;
}

@media print {
    body, .page {
        margin: 0;
        box-shadow: none;
    }
    
    #printable-area {
        display: block;
    }
    
    .page-break {
        page-break-after: always;
    }
    
}

.text-left           { text-align: left; }
.text-right          { text-align: right; }
.text-center         { text-align: center; }
.text-justify        { text-align: justify; }
.text-nowrap         { white-space: nowrap; }
#btnPrint { display: none;}
#btnClose { display: none;}
.campos {
    background-color: #f9f9f9;
};

.text-center {
    text-align: center;
}

`;
