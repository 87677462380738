import { BaseEntity } from "../base-entity";
import { Condomino } from "./condomino";
import { LancamentoUnidade } from "./lancamento-unidade";
import { Unidade } from "./unidade";

export class CobrancaAvulsa extends BaseEntity {
	descricao: string;
	tipo: string;
	id_unidade: number;
	id_sacado: number;
	data_vencimento: Date;
	situacao: string;
	valor: number;
	observacao: string

	unidade: Unidade;
	sacado: Condomino;
	lancamentosUnidade: LancamentoUnidade[];
	checado: boolean;
	possui_titulo: boolean;
	forceDelete: boolean;

	constructor() {
		super();
		this.checado = false;
		this.possui_titulo = false;
		this.tipo = 'INDIVIDUAL';
		this.sacado = new Condomino();
		this.unidade = new Unidade();
		this.lancamentosUnidade = [];
		this.situacao = 'Aberto';
		this.forceDelete = false;
	}
}