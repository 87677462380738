import { Component, OnInit, Input } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { Local, LocalPolitica } from 'app/models';
import { AuthService, MessageService } from 'app/core';
import { LocalHttpService } from '../../services';
import { isNull } from 'util';

declare var jQuery: any;

@Component({
  selector: 'local-politica-modal',
  templateUrl: './local-politica-modal.component.html',
  styleUrls: ['./local-politica-modal.component.css']
})
export class LocalPoliticaModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @Input() local: Local;

  public localPolitica: LocalPolitica;

  public editorConfig: any = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '300px',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Digite o texto de políticas de utilização..',
    imageEndPoint: '',
    toolbar: [
      ['bold', 'italic', 'underline', 'fontSize', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
      ['paragraph', 'blockquote', 'removeBlockquote', 'horizontalLine', 'orderedList', 'unorderedList']
    ]
  };

  constructor(private localHttpService: LocalHttpService,
      public messageService: MessageService,
      private authService: AuthService) {
    this.localPolitica = new LocalPolitica();
  }
  
  ngOnInit() {
    if (!this.idModal) {
      this.idModal = 'localPoliticaModal';
    }
    this.localPolitica.politica = '';
  }

  open() {
    this.localPolitica = new LocalPolitica();
    this.localPolitica.id = this.local.id;
    this.loadPolitica();
    jQuery(`#${this.idModal}`).modal('show');
  }

  close() {
    this.localPolitica = new LocalPolitica();
    this.localPolitica.id = this.local.id;
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Carrega a política do local
   * @author Marcos Frare
   * @since 23/03/2020
   */
  private loadPolitica(): void {
    this.localHttpService.getPoliticaByLocal(this.local.id)
      .subscribe(
        response => {
          if (isNull(response)) {
            this.localPolitica = new LocalPolitica();
            this.localPolitica.id_local = this.local.id;
          } else {
            if (response.id) {
              this.localPolitica = response;
            } else {
              this.localPolitica = new LocalPolitica();
              this.localPolitica.id_local = this.local.id;
            }
          }
        },
        error => this.messageService.error('Erro ao carregar configuração do local. Tente novamente', 'Erro', error)
      );
  }

  /**
   * Salva politica do local
   * @author Marcos Frare
   * @since 11/03/2020
   */
  public salvar(): void {
    const btnSave = jQuery('#btnSalvarPolitica');
    btnSave.button('loading');
    this.localPolitica.id_local = this.local.id;
    this.localHttpService.savePolitica(this.localPolitica)
      .subscribe(
        response => {
          this.localPolitica = response;
          this.messageService.success('', 'Políticas salvas com sucesso!');
          btnSave.button('reset');
          this.close();
        },
        error => {
          this.messageService.error('Erro ao gravar politicas do local!! Tente novamente.', 'Erro', error);
          btnSave.button('reset');
        }
      );
  }

}
