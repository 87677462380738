import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseCadastroComponent } from 'app/componentes-base';
import { AuthService, MessageService } from 'app/core';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { Relatorio } from 'app/models';
import { RelatorioHttpService } from '../service';

@Component({
  selector: 'relatorio-cadastro',
  templateUrl: './relatorio-cadastro.component.html',
  styleUrls: ['./relatorio-cadastro.component.css']
})
export class RelatorioCadastroComponent extends BaseCadastroComponent<Relatorio> implements OnInit {

  constructor(public activatedRoute: ActivatedRoute,
      public router: Router,
      public messageService: MessageService,
      public permissionService: PermissionService,
      private authService: AuthService,
      private relatorioHttpService: RelatorioHttpService) {
    super(Relatorio, relatorioHttpService, activatedRoute, router, messageService, permissionService);
  }

  ngOnInit() {
    super.ngOnInit();
  }
  
}