import { BaseEntity } from '../base-entity';
import { CategoriaContas, Unidade, Reserva } from 'app/models';

export class LancamentoUnidade extends BaseEntity {
	id_unidade: number;
	id_categoria_conta: number;
	id_fornecedor: number;
	nome: string;
	origem: string;
	observacao: string;
	valor_total: any;
	parcelas: number;
	edit: boolean;
	para_proprietario: boolean;

	unidade: Unidade;
	categoriaContas: CategoriaContas;
	pagamentos: any[];
	reserva: Reserva;

	nome_categoria_conta: string;

	constructor() {
		super();
		this.pagamentos = [];
		this.origem = 'LANCAMENTO';
	}

}