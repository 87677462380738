import { Pipe, PipeTransform } from '@angular/core';

import { sindicoAprovaPagamento } from '../../../models';

@Pipe({
    name: 'sindicoAprova'
})
export class SindicoAprovaPipe implements PipeTransform {
    transform(value: number): string {
        if (!value) {
            return '';
        }

        let index = sindicoAprovaPagamento.findIndex(it => it.id === value);
        if (index > -1) {
            return sindicoAprovaPagamento[index].nome;
        }
        return '';
    }
}
