import { BaseEntity } from '../base-entity';

export class ConsumoUnidadeHistorico extends BaseEntity {
	id_consumo_unidade: number;
	id_usuario: number;
	campo: string;
	valor_de: number;
	valor_para: number;
	created_at: Date;

	nome_usuario: string;
}