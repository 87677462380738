
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { _throw } from 'rxjs/observable/throw';

import { AuthService, ConfigService, CustomHttp } from '../../../../../core';
import { HistoricoUnidade } from '../../../../../models';
import { TrocaMoradorSaida } from './../troca-usuario/troca-morador-saida';
import { TrocaMoradorEntrada } from './../troca-usuario/troca-morador-entrada';
import { TrocaProprietarioSaida } from './../troca-usuario/troca-proprietario-saida';
import { TrocaProprietarioEntrada } from './../troca-usuario/troca-proprietario-entrada';

import { catchError, map } from 'rxjs/operators';

@Injectable()
export class UnidadesHistoricoHttpService {

    private baseUrl: string;

    constructor(private customHttp: CustomHttp,
                private configService: ConfigService,
                private authService: AuthService) {
        this.baseUrl = `${configService.getBaseUrl()}/historico`;
    }

    /**
     * Salva a saída do próprietário de uma unidade
     */
    saveSaidaProprietario(idUnidade: number, idProprietario: number, dataSaida: Date): Observable<HistoricoUnidade> {
        let entity = new TrocaProprietarioSaida(idProprietario, dataSaida, this.authService.getCurrentUser().id);
        return this.customHttp.post(`${this.baseUrl}/unidade/${idUnidade}/proprietario/saida`, entity)
                    .pipe(
                        map(response => response.json()),
                        catchError(error => Observable.throw(error))
                    );
    }

    /**
     * Salva a entrada de um novo próprietário de uma unidade
     */
    saveEntradaProprietario(idUnidade: number, idProprietario: number, dataEntrada: Date): Observable<HistoricoUnidade> {
        let entity = new TrocaProprietarioEntrada(idProprietario, dataEntrada, this.authService.getCurrentUser().id);
        return this.customHttp.post(`${this.baseUrl}/unidade/${idUnidade}/proprietario/entrada`, entity)
                    .pipe(
                        map(response => response.json()),
                        catchError(error => _throw(error))
                    );
    }

    /**
     * Salva a saída do morador de uma unidade
     */
    saveSaidaMorador(idUnidade: number, idMorador: number, dataSaida: Date): Observable<HistoricoUnidade> {
        let entity = new TrocaMoradorSaida(idMorador, dataSaida, this.authService.getCurrentUser().id);
        return this.customHttp.post(`${this.baseUrl}/unidade/${idUnidade}/morador/saida`, entity)
                    .pipe(
                        map(response => response.json()),
                        catchError(error => _throw(error))
                    );
    }

    /**
     * Salva a entrada de um novo morador de uma unidade
     */
    saveEntradaMorador(idUnidade: number, idMorador: number, dataEntrada: Date): Observable<HistoricoUnidade> {
        let entity = new TrocaMoradorEntrada(idMorador, dataEntrada, this.authService.getCurrentUser().id);
        return this.customHttp.post(`${this.baseUrl}/unidade/${idUnidade}/morador/entrada`, entity)
                    .pipe(
                        map(response => response.json()),
                        catchError(error => Observable.throw(error))
                    );
    }

    /**
     * Carrega todo o histórico da unidade informada
     */
    getAll(idUnidade: number): Observable<HistoricoUnidade[]> {
        return this.customHttp.get(`${this.baseUrl}/consultar/unidade/${idUnidade}`)
                    .pipe(
                        map(response => response.json()),
                        catchError(error => _throw(error))
                    );
    }
}
