import { Bloco } from "app/models";

export class IntegracaoBancariaFiltro {
  id_condominio: number;
  tipoFiltro: number;
  competenciaInicial: Date;
  competenciaFinal: Date;
  dataInicial: Date;
  dataFinal: Date;
  id_bloco: number;
  ids_unidades: number[];
  nome_sacado: string;
  origem: string;

  bloco: Bloco;

  constructor () {
    this.id_condominio = null;
    this.tipoFiltro = 1;
		this.dataInicial = null;
		this.dataFinal = null;
		this.competenciaInicial = null;
		this.competenciaFinal = null;
    this.id_bloco = null;
    this.bloco = new Bloco();
    this.ids_unidades = [];
    this.nome_sacado = null;
    this.origem = '';
  }
}