import { BaseEntity } from "../base-entity";
import { Titulo } from "./titulo";

export class Bloco extends BaseEntity {
	nome: string;
	id_condominio: number;
	quantidade: number;
	andares: any[];

	titulos: Titulo[];
	selected: boolean;
}

export class BlocoBulk extends BaseEntity {
	id_condominio: number;
	quantidade: number;
}

export const nomenclaturaBloco = [
	{ codigo: 1, nome: 'Alfabeto' },
	{ codigo: 2, nome: 'Numérica' },
	{ codigo: 3, nome: 'Outros' }
];

export class BlocoNovoAndar extends BaseEntity {
	constructor(public id_bloco?: number,
		public andar?: number,
		public quantidade_apartamentos?: number,
		public tipo?: number) {
		super();
	}
}
