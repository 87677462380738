import { Bloco } from "app/models";

export class EspelhoBoletoRelFiltro {
	id_cliente: number;
	id_condominio: number;
	id_usuario: number;
	id_bloco: number;
	unidades: number[];
	data_inicial: Date;
	data_final: Date;
	tipoFiltro: number;
	dataCompetencia: Date;
	tipo_relatorio: string;
	qtd_mes: number;
	serie: string;
	ids_categoria_conta_grupo: number[];

	bloco: Bloco;

	constructor() {
		this.tipoFiltro = 1;
		this.qtd_mes = 1;
		this.bloco = null;
		this.tipo_relatorio = 'DETALHADO';
	}

	/** Limpa o filtro */
	limpar() {
		this.id_bloco = null;
		this.unidades = [];
		this.data_inicial = null;
		this.data_final = null;
		this.tipoFiltro = 1;
		this.dataCompetencia = null;
		this.qtd_mes = 1;
		this.bloco = null;
		this.serie = null;
		this.tipo_relatorio = 'DETALHADO';
		this.ids_categoria_conta_grupo = [];
	}
}