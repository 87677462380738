export class ConsumosRelFiltro {
    id_cliente: number;
    id_condominio: number;
    id_usuario: number;
    id_bloco: number;
    ids_unidades: number[];
    competencia_inicial: Date;
    competencia_final: Date;
    id_tipo_consumo: number;
    ids_tipo_consumo: number[];

    /** Limpa o filtro */
    limpar() {
        this.id_bloco = null;
        this.ids_unidades = [];
        const today = new Date();
        this.competencia_inicial = new Date(today.getFullYear(), today.getMonth()-1, 1); // Competência fechada
        this.competencia_final = null;
        this.id_tipo_consumo = null;
        this.ids_tipo_consumo = [];
    }
}