import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { AuthService, MessageService } from 'app/core';
import { BlocosHttpService, BlocosPesquisaModalComponent, UnidadesHttpService, UnidadesPesquisaModalComponent, UnidadesUsuariosHttpService } from 'app/home/blocos';
import { Bloco, Condomino, Unidade, UnidadeAntecipacao } from 'app/models';
import { TypeaheadMatch } from 'ngx-bootstrap';

declare var jQuery: any;

@Component({
  selector: 'unidade-antecipacao-modal',
  templateUrl: './unidade-antecipacao-modal.component.html',
  styleUrls: ['./unidade-antecipacao-modal.component.css']
})
export class UnidadeAntecipacaoModalComponent implements OnInit, BaseModal  {

  @ViewChild('blocosPesquisaModal1') blocosPesquisaModal1: BlocosPesquisaModalComponent;
	@ViewChild('unidadesPesquisaModal1') unidadesPesquisaModal1: UnidadesPesquisaModalComponent;

  @Input() idModal: string;
  @Input() id: number;
  @Output() onSave: EventEmitter<UnidadeAntecipacao> = new EventEmitter();

  public unidadeAntecipacao: UnidadeAntecipacao = new UnidadeAntecipacao();
  public blocos: Bloco[] = [];
  public unidades: Unidade[] = [];
  public condominos: Condomino[] = [];

  constructor(private messageService: MessageService,
    private unidadeHttpService: UnidadesHttpService,
    private blocosHttpService: BlocosHttpService,
    private unidadesHttpService: UnidadesHttpService,
    private authService: AuthService,
    private unidadesUsuariosHttpService: UnidadesUsuariosHttpService) { }

  ngOnInit() {
    if (!this.idModal) {
      this.idModal = 'unidadeAntecipacaoModal';
    }
  }

  open() {
    this.getAllBlocosByCondominio();
    this.loadAntecipacao();
    jQuery(`#${this.idModal}`).modal('show');
  }

  close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Busca os condôminos da unidade
   * @author Marcos Frare
   * @since 18/08/2021
   */
  private getAllCondominos(): void {
    this.condominos = [];
    if (this.unidadeAntecipacao.id_unidade) {
      this.unidadesUsuariosHttpService.getAllCondominosUnidade(this.unidadeAntecipacao.id_unidade).subscribe(
        response => {
          this.condominos = response;
          if (this.unidadeAntecipacao.unidade) {
            this.unidadeAntecipacao.id_condomino = this.unidadeAntecipacao.unidade.id_sacado;
          }
        },
        error => {
          this.messageService.error('Erro ao buscar condôminos da unidade! Tente novamente.', 'Erro', error);
        }
      );
    }
  }

  /**
   * Carregar dados da isenção
   * @author Marcos Frare
   * @since 09/08/2021
   */
  private loadAntecipacao(): void {
    this.unidadeAntecipacao = new UnidadeAntecipacao();
    if (this.id) {
      this.unidadeHttpService.getUnidadeAntecipacao(this.id).subscribe(
        response => {
          this.unidadeAntecipacao = response;
          this.getAllUnidadesByBloco(this.unidadeAntecipacao.bloco.id);
          this.getAllCondominos();
        },
        error => {
          this.messageService.error('Erro o carregar dados da isenção! Tente novamente.', 'Erro!', error);
          this.unidadeAntecipacao = new UnidadeAntecipacao();
        }
      );
    }
  }

  /**
   * Salvar dados
   * @author Marcos Frare
   * @since 09/08/2021
   */
  public salvar(): void {
    let btn = jQuery('#btnSalvarAntecipacao');
    btn.button('loading');
    this.unidadeHttpService.saveUnidadeAntecipacao(this.unidadeAntecipacao).subscribe(
      () => {
        btn.button('reset');
        this.unidadeAntecipacao = new UnidadeAntecipacao();
        this.close();
        this.onSave.emit(this.unidadeAntecipacao);
      },
      error => {
        btn.button('reset');
        this.messageService.error('Erro ao gravar dados! Tente novamente.', 'Erro!', error);
      }
    );
  }

  private getAllBlocosByCondominio(): void {
		this.blocosHttpService.getAllByCondominio(this.authService.getIdCurrentCondominio()).subscribe(
			blocos => this.blocos = blocos,
			error => this.messageService.error('Erro ao carregar os blocos do condomínio. Tente novamente', 'Erro', error)
		);
	}

	private getAllUnidadesByBloco(idBloco: number): void {
		this.unidadesHttpService.getAllByBloco(idBloco).subscribe(
			unidades => this.unidades = unidades,
			error => this.messageService.error('Erro ao carregar as unidades do bloco. Tente novamente', 'Erro', error)
		);
	}

  public onSelectBloco(tm: TypeaheadMatch): void {
		this.setBloco(tm.item as Bloco);
	}

	private setBloco(selectedBloco: Bloco): void {
		this.unidadeAntecipacao.bloco = selectedBloco;
		this.unidadeAntecipacao.id_bloco = selectedBloco.id;
		this.getAllUnidadesByBloco(selectedBloco.id);
    this.onCleanUnidade(jQuery('#unidade'));
	}

	public openBlocosPequisaModal(): void {
		this.blocosPesquisaModal1.open();
	}

	public onSelectBlocosPesquisaModal(bloco: Bloco): void {
		if (bloco) this.setBloco(bloco);
		jQuery('#bloco').focus();
	}

	public onCleanBloco(input: any): void {
		this.unidadeAntecipacao.bloco = null;
		this.unidadeAntecipacao.id_bloco = null;
		this.unidadeAntecipacao.unidade = null;
		this.unidadeAntecipacao.id_unidade = null;
		this.unidades = [];
		input.value = '';
		input.focus();
    this.onCleanUnidade(jQuery('#unidade'));
	}

	// Unidade
	public onSelectUnidade(tm: TypeaheadMatch): void {
		this.setUnidade(tm.item as Unidade);
	}

	private setUnidade(selectedUnidade: Unidade): void {
		this.unidadeAntecipacao.unidade = selectedUnidade;
		this.unidadeAntecipacao.id_unidade = selectedUnidade.id;
    this.getAllCondominos();
	}

	public openUnidadesPequisaModal(): void {
		this.unidadesPesquisaModal1.open();
	}

	public onSelectUnidadesPesquisaModal(unidade: Unidade): void {
		if (unidade) this.setUnidade(unidade);
		jQuery('#unidade').focus();
	}

	public onCleanUnidade(input: any): void {
		this.unidadeAntecipacao.unidade = null;
		this.unidadeAntecipacao.id_unidade = null;
		input.value = '';
		input.focus();
    this.getAllCondominos();
	}

}