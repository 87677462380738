import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { AuthService, MessageService } from '../../../core';

import { RelatorioModalComponent } from 'app/home/relatorios/relatorio-modal';
import { datepickerMesAnoOptions } from 'app/shared';

import { CreditosLancamentosRelFiltro } from './creditos-lancamentos-rel-filtro';
import { Unidade, Bloco } from './../../../models';
import { BlocosHttpService } from './../../blocos/services/blocos-http.service';
import { UnidadesHttpService } from './../../blocos/unidades/services/unidades-http.service';
import { BlocosPesquisaModalComponent } from './../../blocos/blocos-pesquisa-modal';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';

declare var jQuery: any;

@Component({
    selector: 'creditos-lancamentos-rel',
    templateUrl: './creditos-lancamentos-rel.component.html',
    styleUrls: ['../shared/visualizacao.relatorio.css']
})
export class CreditosLancamentosRelComponent implements OnInit {

    public unidades: Unidade[] = [];
    public blocos: Bloco[] = [];
    public filtro = new CreditosLancamentosRelFiltro();
    public bloco: Bloco;
    public showInputBlocos: boolean = true;

    @ViewChild('relatorioModal') relatorioModal: RelatorioModalComponent;
    @ViewChild('inputBloco') inputBloco: ElementRef;
    @ViewChild('blocosPesquisaModal') blocosPesquisaModal: BlocosPesquisaModalComponent;

    public datepickerDataCompetenciaOptions = datepickerMesAnoOptions;

    multiselectSettings: IMultiSelectSettings = {
        enableSearch: true,
        containerClasses: 'container-multiselect',
        showUncheckAll: true,
        showCheckAll: true,
        dynamicTitleMaxItems: 3,
        checkedStyle: 'checkboxes',
        buttonClasses: 'btn btn-default',
        itemClasses: 'dropdown-menu'
    };

    multiselectOptionsUnidades: IMultiSelectOption[];
    multiselectTextsUnidades: IMultiSelectTexts = {
        checkAll: 'Selecionar todas',
        uncheckAll: 'Limpar',
        checked: 'selecionada',
        checkedPlural: 'selecionadas',
        searchPlaceholder: 'Pesquisar...',
        defaultTitle: 'Selecionar',
        allSelected: 'Todas selecionadas',
        searchEmptyResult: 'Nenhum registro encontrado',
        searchNoRenderText: 'Digite para pesquisar',
    };

    constructor(private authService: AuthService,
                private messageService: MessageService,
                private unidadesHttpService: UnidadesHttpService,
                private blocosHttpService: BlocosHttpService) {
    }

    ngOnInit() {
        this.limpar();
        this.filtro.id_condominio = this.authService.getIdCurrentCondominio();; 
        this.filtro.id_usuario = this.authService.getIdCurrentUser();
        this.filtro.id_cliente = this.authService.getIdCurrentCliente();
        this.getBlocosByCondominio(this.filtro.id_condominio);
        this.getAllUnidades();
    }

    public limpar(): void {
        this.filtro.limpar();
    }

    private validateView(): boolean {
        if (this.filtro.tipoFiltro) {
            if (this.filtro.tipoFiltro === 1) {
                if (!this.filtro.competencia_inicial) {
                    this.messageService.info('É necessário informar a competência inicial para imprimir relatório', 'Informação');
                    return false;
                }
            } else {
                if (!this.filtro.data_inicial) {
                    this.messageService.info('É necessário informar a data inicial para imprimir relatório', 'Informação');
                    return false;
                }
                if (!this.filtro.data_final) {
                    this.messageService.info('É necessário informar a data final para imprimir relatório', 'Informação');
                    return false;
                }
            }
        } else {
            this.messageService.info('É necessário Tipo para filtro de período', 'Informação');
            return false;
        }
        return true;
    }

    public view(): void {
        if (this.validateView()) {
            this.relatorioModal.url = '/relatorio/credito-por-lancamento';
            this.relatorioModal.params = this.filtro;
            this.relatorioModal.open();
        }
    }
    
    private getBlocosByCondominio(idCondominio: number) {
        this.blocosHttpService.getAllByCondominio(idCondominio)
            .subscribe(
                entitites => {
                    this.blocos = entitites
                    if (this.blocoUnico(this.blocos)) {
                        this.setBloco(this.blocos[0]);
                        this.showInputBlocos = false;
                    } else if (!this.blocos) {
                        this.messageService.error('Não existem blocos cadastrados no Condomínio. Cadastre  um bloco e tente novamente', 'Erro')
                    }
                },
                error => this.messageService.error('Erro ao carregar as unidades do condomínio. Tente novamente', 'Erro', error)
            );
    }

    private setBloco(blocoSelecionado: Bloco) {
        this.bloco = blocoSelecionado;
        this.filtro.id_bloco = this.bloco.id;
        this.filterUnidades();
        this.onCleanUnidades();
    }

    /**
     * Busca todas as unidades do codomínio
     * @author Marcos Frare
     * @since 24/04/2020
     */
    private getAllUnidades(): void {
        this.unidadesHttpService.getAllByCondominioBloco(this.filtro.id_condominio, null).subscribe(
        response => {
            this.unidades = response;
            this.filterUnidades();
        },
        error => this.messageService.error('Erro ao carregar as unidades do condomínio. Tente novamente', 'Erro', error)
        );
    }

    /**
     * Filtra as unidades com base no campo de bloco
     * @author Marcos Frare
     * @since 24/04/2020
     */
    private filterUnidades(): void {
        const selects: IMultiSelectOption[] = [];
        let arr = this.unidades;
        if (this.bloco && this.bloco.id) {
            arr = this.unidades.filter(und => und.id_bloco === this.bloco.id);
        }
        arr.forEach(entity => {
            selects.push({
                id: entity.id,
                name: `${entity.nome_bloco} - ${entity.nome_unidade}`
            });
        });
        this.multiselectOptionsUnidades = selects;
    }

    onCleanUnidades() {
        this.filtro.unidades = [];
    }

    onSelectBloco(tm: TypeaheadMatch): void {
        this.setBloco(tm.item as Bloco);
    }

    openBlocosPequisaModal() {
        this.blocosPesquisaModal.open();
    }

    onSelectBlocosPesquisaModal(bloco: Bloco) {
        if (bloco) {
            this.setBloco(bloco)
        }
        jQuery('#bloco').focus();
    }

    onCleanBloco(input: any) {
        this.bloco = null;
        input.value = '';
        this.filterUnidades();
    }

    private blocoUnico(blocos: Bloco[]): boolean {
        return blocos.length === 1;
    }

}
