import { NgModule } from '@angular/core';
import { PrevisaoOrcamentariaComponent } from './previsao-orcamentaria.component';
import { PrevisaoOrcamentariaHttpService } from './service';
import { PrevisaoOrcamentariaCadastroComponent } from './cadastro';
import { SharedModule } from 'app/shared';
import { TypeaheadModule, PopoverModule } from 'ngx-bootstrap';
import { EasyUIModule } from 'ng-easyui';

@NgModule({
  imports: [
    SharedModule,
    TypeaheadModule,
    PopoverModule,
    EasyUIModule
  ],
  declarations: [
    PrevisaoOrcamentariaComponent,
    PrevisaoOrcamentariaCadastroComponent
  ],
  providers: [
    PrevisaoOrcamentariaHttpService
  ]
})
export class PrevisaoOrcamentariaModule { }