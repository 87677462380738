import { Injectable } from '@angular/core';
import { Crud, CrudService } from 'app/core';
import { Filtro } from 'app/models';
import { Observable } from 'rxjs';

@Injectable()
export class FiltroHttpService implements Crud<Filtro> {

  resource = 'relatorio/filtro';

  constructor(private crudService: CrudService) {}

  get(id: number): Observable<Filtro> {
    return this.crudService.get<Filtro>(this.resource, id);
  }
  getAll(): Observable<Filtro[]> {
    return this.crudService.getAll<Filtro>(this.resource);
  }
  post(entity: Filtro): Observable<Filtro> {
    return this.crudService.post<Filtro>(this.resource, entity);
  }
  put(entity: Filtro): Observable<Filtro> {
    return this.crudService.put<Filtro>(this.resource, entity);
  }
  delete(entity: Filtro): Observable<string> {
    return this.crudService.delete<Filtro>(this.resource, entity);
  }
}