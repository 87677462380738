import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AuthService, MessageService } from 'app/core';
import { RelatorioModalComponent } from 'app/home/relatorios';
import { ConfiguracaoLayoutRecibo } from 'app/models';
import { BoletosHttpService } from '../services';

declare var jQuery: any;

@Component({
  selector: 'parametros-listar-recibos-modal',
  templateUrl: './parametros-listar-recibos-modal.component.html',
  styleUrls: ['./parametros-listar-recibos-modal.component.css']
})
export class ParametrosListarRecibosModalComponent implements OnInit {

  @Input() idModal: string;
  @Input() idsTitulos: number[];
  
  @ViewChild('relatorioModal') relatorioModal: RelatorioModalComponent;

  public filtro: any = {};

  constructor(private authService: AuthService,
    private boletoHttpService: BoletosHttpService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.filtro = new ConfiguracaoLayoutRecibo();
    this.filtro.id_condominio = this.authService.getIdCurrentCondominio();
    this.filtro.id_usuario = this.authService.getIdCurrentUser();
    if (!this.idModal) {
      this.idModal = 'parametrosListarReciboModal';
    };
  }

  /**
   * Busca as configurações de layout do recibo
   * @author Marcos Frare
   * @since 21/11/2022
   */
   private getConfigLayoutRecibo(): void {
    this.boletoHttpService.getConfigLayoutRecibo(this.filtro.id_condominio).subscribe(
      response => {
        if (response != null) {
          this.filtro = response;
        }
        this.filtro.id_condominio = this.authService.getIdCurrentCondominio();
        this.filtro.id_usuario = this.authService.getIdCurrentUser();
      },
      error => this.messageService.error('Não foi possível carregar a configuração de layou do relatório!', 'Erro', error)
    );
  }

  /**
   * gravar configurações de lauyout do Recibo
   * @author Marcos Frare
   * @since 21/11/2022
   */
   public gravarLayout(): void {
    let btnConfig = jQuery('#btnConfig');
    btnConfig.button('loading');
    this.boletoHttpService.gravarConfigLayoutRecibo(this.filtro).subscribe(
      response => {
        btnConfig.button('reset');
        this.filtro.id = response.id;
        this.messageService.info('Layout do recibo gravado com sucesso', 'Info');
      },
      error => {
        btnConfig.button('reset');
        this.messageService.error('Erro ao gravar layout do recibo. Tente novamente', 'Erro', error)
      }
    );
  }

  public open() {
    this.getConfigLayoutRecibo();
    jQuery(`#${this.idModal}`).modal('show');
  }
  
  public close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Listar títulos conforme filtro
   * @author Marcos Frare
   * @since 18/08/2020
   */
   public listar(): void {
    this.filtro.idsTitulo = this.idsTitulos;
    this.relatorioModal.listarTitulos = true;
    this.relatorioModal.url = '/titulo/recibo/listar';
    this.relatorioModal.params = this.filtro;
    this.relatorioModal.open();
    this.close();
  }
  
}
