import { BaseEntity } from "../base-entity";
import { CategoriaContas } from "./categoria-contas";
import { Local } from 'app/models';

export class LocalConfiguracao extends BaseEntity {
	id_local: number;
	possui_cobranca: boolean;
	id_categoria_conta: number;
	dias_cancelamento: number;
	multa_cancelamento: boolean;
	reserva_inadimplente: boolean;
	taxa_limpeza: boolean;
	tipo_periodo_reserva: number;
	quantidade_periodo: number;
	valor_reserva: any;
	valor_taxa_limpeza: any;
	valor_multa_cancelamento: any;
	email_reserva: boolean;
	email_reserva_politica: boolean;
	tipo_periodos_limite_reserva: number;
	periodos_limite_reserva: number;
	
	categoriaContas: CategoriaContas;
	local: Local;

	constructor() {
		super();
		this.email_reserva = false;
		this.email_reserva_politica = false;
	}
}