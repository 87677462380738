import { BaseEntity } from "../base-entity";
import { Bloco } from "./bloco";
import { Unidade } from "./unidade";

export class CertidaoNegativaDebito extends BaseEntity {
	id_unidade: number;
	id_usuario: number;
	data_emissao: Date;
	data_validade: Date;
	emitido: boolean;
	conteudo: string;
	observacao: string;
	bloco: Bloco;
	unidade: Unidade;
	assinatura_administradora: boolean;
	listar_ressalvas_lancamento: boolean;
	listar_ressalvas_titulos : boolean;

	constructor() {
		super();
		this.bloco = new Bloco();
		this.unidade = new Unidade();
		this.assinatura_administradora = false;
		this.listar_ressalvas_lancamento = true;
		this.listar_ressalvas_titulos = true;
	}
}