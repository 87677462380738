import { Component, OnInit, ViewChild } from '@angular/core';
import { RelatorioModalComponent } from '../relatorio-modal';
import { ComparativoPrevisaoReceitaDespesaRelFiltro } from './comparativo-previsao-receita-despesa-rel-filtro';
import { AuthService, MessageService } from 'app/core';
import { IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import { CategoriaContasHttpService } from 'app/home/plano-de-contas';
import { datepickerMesAnoOptions } from 'app/shared';
import { ComparativoPrevisaoReceitaDespesaHttpService } from './services/comparativo-previsao-receita-despesa-http.service';

@Component({
  selector: 'comparativo-previsao-receita-despesa-rel',
  templateUrl: './comparativo-previsao-receita-despesa-rel.component.html',
  styleUrls: ['./comparativo-previsao-receita-despesa-rel.component.css']
})
export class ComparativoPrevisaoReceitaDespesaRelComponent implements OnInit {

  public multiselectSettings: IMultiSelectSettings = {
		enableSearch: true,
		containerClasses: 'container-multiselect',
		showUncheckAll: true,
		showCheckAll: true,
		dynamicTitleMaxItems: 1,
		checkedStyle: 'checkboxes',
		buttonClasses: 'btn btn-default',
		itemClasses: 'dropdown-menu'
	};
	public multiselectTexts: IMultiSelectTexts = {
		checkAll: 'Selecionar todas',
		uncheckAll: 'Limpar',
		checked: 'selecionada',
		checkedPlural: 'selecionadas',
		searchPlaceholder: 'Pesquisar...',
		defaultTitle: 'Selecionar',
		allSelected: 'Todas selecionadas',
		searchEmptyResult: 'Nenhuma categoria de conta encontrada',
		searchNoRenderText: 'Digite para pesquisar',
	};

  @ViewChild('relatorioModal') relatorioModal: RelatorioModalComponent;

  public filtro: ComparativoPrevisaoReceitaDespesaRelFiltro = new ComparativoPrevisaoReceitaDespesaRelFiltro();
  public datepickerDataCompetenciaOptions = datepickerMesAnoOptions;
  public categoriasContasReceita: any = [];
  public categoriasContasDespesa: any = [];
  public mostaExpandido: boolean = false;
  public dados: any = [];

  constructor(private authService: AuthService,
    private categoriaContasHttpService: CategoriaContasHttpService,
    private messageService: MessageService,
    private comparativoPrevisaoReceitaDespesaHttpService: ComparativoPrevisaoReceitaDespesaHttpService) { }

  ngOnInit() {
    this.limpar();
    this.filtro.id_cliente = this.authService.getIdCurrentCliente();
    this.filtro.id_condominio = this.authService.getIdCurrentCondominio();
    this.filtro.id_usuario = this.authService.getIdCurrentUser();
    this.getAllCategoriasByCondominio();
  }

  /**
   * Busca as categorias de contas do condomínio
   * @author Marcos Frare
   * @since 04/07/2023
   */
  private getAllCategoriasByCondominio(): void {
    this.categoriaContasHttpService.getCategoriasMovimentoPrevisaoOrcamentaria(this.filtro.id_condominio, this.filtro.competencia_inicial, this.filtro.competencia_final).subscribe(
      entities => {
        this.categoriasContasReceita = entities.filter(conta => conta.tipo_conta == 1).map(c => {return {id: c.id, name: `${c.codigo} - ${c.nome}`}});
        this.categoriasContasDespesa = entities.filter(conta => conta.tipo_conta == 2).map(c => {return {id: c.id, name: `${c.codigo} - ${c.nome}`}});
      },
      error => {
        this.messageService.error(error);
      }
    );
	}

  private getDadosRelatorio(): void {
    this.dados = [];
    this.comparativoPrevisaoReceitaDespesaHttpService.getDados(this.filtro).subscribe(
      res => this.dados = res,
      error => {
        console.error(error);
        this.messageService.error('Erro', 'Erro ao buscar dados do relatório!');
      }
    );
  }

  public limpar() {
    this.filtro.limpar();
    this.mostaExpandido = false;
  }

  public alteraPeriodo(): void {
    this.getAllCategoriasByCondominio();
  }

  private validateView(): boolean {
    if (!this.filtro.competencia_inicial) {
      this.messageService.info('É necessário informar a competência inicial', 'Informação');
      return false;
    }
    if (!this.filtro.competencia_final) {
      this.messageService.info('É necessário informar a competência final', 'Informação');
      return false;
    }
    return true;
  }

  public view(): void {
    if (this.validateView()) {
      this.relatorioModal.url = '/relatorio/comparativo-previsao-receita-despesa';
      this.relatorioModal.params = this.filtro;
      this.relatorioModal.open();
    }
  }

  public show(): void {
    this.mostaExpandido = true;
    this.getDadosRelatorio();
  }

  public expand(dado: any): void {
    dado.expandido = !dado.expandido;
  }

}
