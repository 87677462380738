import { map, catchError } from 'rxjs/operators';

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { _throw } from 'rxjs/observable/throw';

import { Formulario } from '../../../models';
import { Crud, CrudService, ConfigService, CustomHttp } from '../../../core';

@Injectable()
export class FormulariosHttpService implements Crud<Formulario> {
    resource = 'formulario';
    private baseUrl: string;

    constructor(private customHttp: CustomHttp,
                private crudService: CrudService,
                private configService: ConfigService) {

        this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
    }

    get(id: number): Observable<Formulario> {
        return this.crudService.get<Formulario>(this.resource, id);
    }

    getAll(): Observable<Formulario[]> {
        return this.crudService.getAll<Formulario>(this.resource);
    }

    post(entity: Formulario): Observable<Formulario> {
        return this.crudService.post<Formulario>(this.resource, entity);
    }

    put(entity: Formulario): Observable<Formulario> {
        return this.crudService.put<Formulario>(this.resource, entity);
    }

    delete(entity: Formulario): Observable<string> {
        return this.crudService.delete<Formulario>(this.resource, entity);
    }

    getAllByCliente(idCliente: number): Observable<Formulario[]> {
        return this.customHttp.get(`${this.baseUrl}/consultar/cliente/${idCliente}`)
                    .pipe(
                        map(response => response.json()),
                        catchError(error => _throw(error))
                    );
    }

    getForReport(payload: any): Observable<string> {
        return this.customHttp.post(`${this.configService.getBaseUrl()}/relatorio/capa`, payload)
                    .pipe(
                        map(response => response.json()),
                        catchError(error => _throw(error))
                    );
    }
}
