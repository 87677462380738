import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { MenuService } from './../menu/menu.service';
import { AuthService } from './auth.service';
import { Permission } from 'app/componentes-base';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    
    constructor(
        private router: Router,
        private authService: AuthService, 
        private menuService: MenuService) { 
    }

    canActivateChild() {
        if (this.authService.isAuth) return true;
        this.authService.logout();
        return false;
    }   

    canActivate(route: ActivatedRouteSnapshot) {
        const resource = route.data.resource;
        const idCliente = this.authService.getIdCurrentCliente();
        const idUsuario = this.authService.getIdCurrentUser();
        return this.menuService.checkResourcePermission(resource, idCliente, idUsuario)
            .pipe(map(permission => this.checkGerencial(permission))); 
    }

    public checkGerencial(permission: Permission): boolean {
        return permission.gerencial ? this.isClienteLoaded() && permission.ler : this.isCondominioLoaded() && permission.ler;
    }

    public isCondominioLoaded(): boolean {
        return this.authService.isCondominioLoaded;
    }

    public isClienteLoaded(): boolean {
        return this.authService.isClienteLoaded;
    }

}
