import { BaseEntity } from '../base-entity';
import { Usuario } from './usuario';

export class HistoricoUnidade extends BaseEntity {
	id_unidade: number;
	id_usuario_historico: number;
	tipo: number;
	data_entrada: string;
	data_saida: string;

	usuarioHistorico: Usuario;
}