import { Pipe, PipeTransform } from '@angular/core';

import { unidadesMedida } from '../../../models';

@Pipe({
    name: 'unidadesMedida'
})
export class UnidadesMedidaPipe implements PipeTransform {
    transform(value: number): string {
        if (!value) {
            return '';
        }

        let index = unidadesMedida.findIndex(medida => medida.id === value);
        if (index > -1) {
            return unidadesMedida[index].nome;
        }
        return '';
    }
}
