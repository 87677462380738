import { BaseEntity } from '../base-entity';
import { Titulo } from './titulo';
import { Time } from '@angular/common';

export class Ocorrencia extends BaseEntity {
	data_ocorrencia: Date;
	hora_ocorrencia: Time;
	ocorrencia: string;

	titulos: Titulo[];
	numeros: string;

	constructor() {
		super();
		this.data_ocorrencia = new Date();
	}
}