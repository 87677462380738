import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { BaseCadastroComponent } from '../../../componentes-base';
import { AuthService, MessageService } from '../../../core';
import { FormulariosHttpService } from './../services';
import { PermissionService } from '../../../core/services/permission/permission.service';
import { Formulario } from 'app/models';

@Component({
    selector: 'formularios-cadastro',
    templateUrl: 'formularios-cadastro.component.html',
    providers: [
        PermissionService
    ]
})
export class FormulariosCadastroComponent extends BaseCadastroComponent<Formulario> implements OnInit {

    public entity: Formulario = new Formulario();
    private idCliente: number;
    public editorConfig: any = {
        editable: true,
        spellcheck: true,
        height: 'auto',
        minHeight: '500px',
        width: 'auto',
        minWidth: '0',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'Digite o texto do formulário...',
        imageEndPoint: '',
        toolbar: [
            ['bold', 'italic', 'underline', 'fontSize', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull']
        ]
    };

    constructor(public activatedRoute: ActivatedRoute,
                public router: Router,
                public messageService: MessageService,
                private authService: AuthService,
                private formulariosHttpService: FormulariosHttpService,
                public permissionService: PermissionService) {
        super(Formulario, formulariosHttpService, activatedRoute, router, messageService, permissionService);
        this.idCliente = this.authService.getIdCurrentCliente();
    }

    ngOnInit() {
        if (!this.entity) this.entity = new Formulario();
        this.activatedRoute.params.subscribe((params: Params) => {
            const id = +params['id'];
            if (!isNaN(id)) {
                this.editMode = true;
                this.load(id);
            } else {
                this.entity = new Formulario();
                this.entity.id_cliente = this.idCliente;
            }
        });
        this.getPermissions();
    }

    load(id: number) {
        this.formulariosHttpService.get(id)
            .subscribe(
                entity => this.entity = entity,
                error => this.messageService.error('Erro ao carregar o Plano de contas. Tente novamente','Erro', error)
            );
    }

    public afterInsert(): void {
        this.entity.id_cliente = this.authService.getIdCurrentCliente();
    }

    public save(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.makeAllFieldsDirty();

            if (this.form.valid) {
                if (this.beforeSave()) {
                    this.btnSave.button('loading');

                    if (this.editMode) { // PUT
                        this.crudService.put(this.entity)
                            .subscribe(
                                entity => {
                                    this.messageService.success('', 'Registro salvo com sucesso');
                                    resolve(entity);
                                    this.afterSave(entity);
                                },
                                error => {
                                    this.messageService.error('Erro ao atualizar o registro', 'Erro', error);
                                    this.btnSave.button('reset');
                                    reject(null);
                                }
                            );
                    } else { // POST
                        this.crudService.post(this.entity)
                            .subscribe(
                                entity => {
                                    this.messageService.success('', 'Registro salvo com sucesso');
                                    resolve(entity);
                                    this.afterSave(entity);
                                },
                                error => {
                                    this.messageService.error('Erro ao salvar o registro', 'Erro', error);
                                    this.btnSave.button('reset');
                                    reject(null);
                                }
                            );
                    }

                } else {
                    reject(null);
                }
            } else {
                this.showMessageFormInvalid();
                reject(null);
            }
        });
    }

    public cancel(): void {
        this.router.navigate(['../'], {relativeTo: this.activatedRoute});
    }

}
