import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ConfigService, Crud, CrudService, CustomHttp } from 'app/core';
import { Banco } from 'app/models';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';

@Injectable({
  providedIn: 'root'
})
export class BancoHttpService implements Crud<Banco> {

  resource = 'banco';
  private baseUrl: string;
  private url: string;

  constructor(private customHttp: CustomHttp,
      private crudService: CrudService,
      private configService: ConfigService) {
    this.url = configService.getBaseUrl();
    this.baseUrl = `${this.url}/${this.resource}`;
  }

  get(id: number): Observable<Banco> {
    return this.crudService.get<Banco>(this.resource, id);
  }
  getAll(): Observable<Banco[]> {
    return this.crudService.getAll<Banco>(this.resource);
  }
  post(entity: Banco): Observable<Banco> {
    return this.crudService.post<Banco>(this.resource, entity);
  }
  put(entity: Banco): Observable<Banco> {
    return this.crudService.put<Banco>(this.resource, entity);
  }
  delete(entity: Banco): Observable<string> {
    return this.crudService.delete<Banco>(this.resource, entity);
  }

  /**
   * Busca os tipos de protesto de um banco
   * @param id 
   * @author Marcos Frare
   * @since 21/07/2022
   */
  public getTipoProtesto(id: number): Observable<any> {
    return this.customHttp.get(`${this.baseUrl}/tipo-protesto/${id}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
  }

}