import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';

import { Crud, CrudService, ConfigService, CustomHttp } from '../../../core';
import { ConselhosCondominio } from '../../../models';

@Injectable()
export class CondominiosConselhoHttpService implements Crud<ConselhosCondominio> {
    resource = 'conselhoscondominio';
    private baseUrl: string;

    constructor(private customHttp: CustomHttp,
                private crudService: CrudService,
                private configService: ConfigService) {
        this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
    }

    get(id: number): Observable<ConselhosCondominio> {
        return this.crudService.get<ConselhosCondominio>(this.resource, id);
    }

    getAll(): Observable<ConselhosCondominio[]> {
        return this.crudService.getAll<ConselhosCondominio>(this.resource);
    }

    post(entity: ConselhosCondominio): Observable<ConselhosCondominio> {
        return this.crudService.post<ConselhosCondominio>(this.resource, entity);
    }

    put(entity: ConselhosCondominio): Observable<ConselhosCondominio> {
        return this.crudService.put<ConselhosCondominio>(this.resource, entity);
    }

    delete(entity: ConselhosCondominio): Observable<string> {
        return this.crudService.delete<ConselhosCondominio>(this.resource, entity);
    }

    /**
     * Consulta todos os conselhos vigentes pelo condomínio informado
     */
    getVigentesByCondominio(idCondominio: number): Observable<ConselhosCondominio[]> {
        return this.customHttp.get(`${this.baseUrl}/consultar/vigente/condominio/${idCondominio}`)
            .pipe(
                map(response => response.json()),
                catchError(error => _throw(error))
            );
    }

    getHistoricoDoisAnos(idCondominio: number): Observable<ConselhosCondominio[]> {
        return this.customHttp.get(`${this.baseUrl}/consultar/historicodoisanos/condominio/${idCondominio}`)
            .pipe(
                map(response => response.json()),
                catchError(error => _throw(error))
            );
    }

    /**
     * Obter todos os membros do conselhor de um condomínio
     * @param idCondominio 
     * @author Marcos Frare
     * @since 16/02/2021
     */
    public obterTodosCondominio(idCondominio: number): Observable<ConselhosCondominio[]> {
        return this.customHttp.get(`${this.baseUrl}/consultar/condominio/${idCondominio}`)
            .pipe(
                map(response => response.json()),
                catchError(error => _throw(error))
            );
    }
}
