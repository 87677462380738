import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseVisualizacaoComponent, Permission } from 'app/componentes-base';
import { AuthService, MessageService } from 'app/core';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { ContaBancaria, ExtratoAnexo } from 'app/models';
import { datepickerMesAnoOptions, VisualizaAnexoComponent } from 'app/shared';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContasBancariasHttpService } from '../services';
import { ExtratoAnexoCadastroModalComponent } from './cadastro-modal';
import { ExtratoAnexoHttpServiceService } from './services';

@Component({
  selector: 'extrato-anexo',
  templateUrl: './extrato-anexo.component.html',
  styleUrls: ['./extrato-anexo.component.css']
})
export class ExtratoAnexoComponent extends BaseVisualizacaoComponent<ExtratoAnexo> {

  @ViewChild('extratoAnexoCadastroModal') extratoAnexoCadastroModal: ExtratoAnexoCadastroModalComponent;
  @ViewChild('visualizaAnexo') visualizaAnexo: VisualizaAnexoComponent;

  public permissions: Permission;
  public contas: ContaBancaria[];
  private filtro: any = {};
  public datepickerDataCompetenciaOptions = datepickerMesAnoOptions;

  constructor(public activatedRoute: ActivatedRoute,
      router: Router,
      messageService: MessageService,
      permissionService: PermissionService,
      private authService: AuthService,
      private extratoAnexoHttpService: ExtratoAnexoHttpServiceService,
      private contasBancariasHttpService: ContasBancariasHttpService,
      public spinner: NgxSpinnerService) {
    super('#tableExtratoAnexo', extratoAnexoHttpService, activatedRoute, router, messageService, permissionService, spinner);
  }

  ngOnInit() {
    this.filtro.id_condominio = this.authService.getIdCurrentCondominio();
    this.limpar();
    this.filtro.id_conta_bancaria = null;
    this.getContas();
    super.ngOnInit();
  }

  public limpar(): void {
    this.filtro.id_conta_bancaria = null;
    const today = new Date();
    this.filtro.competencia_inicial = new Date(today.getFullYear(), today.getMonth(), 1); // primeiro dia do mês passado
    this.filtro.competencia_final = new Date(today.getFullYear(), today.getMonth()+1, -1); // último dia do mês passado
  }

  public getAll(): void {
    this.spinner.show();
    this.entities = [];
    // buscar os anexos
    this.extratoAnexoHttpService.getByFiltro(this.filtro).subscribe(
      response => {
        this.spinner.hide();
        this.entities = response;
      },
      error => {
        this.messageService.error('Erro ao buscar anexos!', 'Erro', error);
        this.spinner.hide();
      }
    );
  }

  public getContas(): void {
    this.contasBancariasHttpService.getAllByCondominio(this.filtro.id_condominio).subscribe(
      response => {
        this.contas = response.filter(cb => cb.situacao == 'ATIVO');
      },
      error => this.messageService.error('Erro ao carregar contas bancárias. Tente novamente', 'Erro', error)
    );
  }

  public addAnexo(): void {
    this.extratoAnexoCadastroModal.contasBancarias = this.contas;
    this.extratoAnexoCadastroModal.open();
  }

  public delete(entity: ExtratoAnexo): void {
    if (confirm(`Deseja excluir o anexo de extrato?`)) {
      this.spinner.show();
      this.extratoAnexoHttpService.delete(entity).subscribe(
        () => {
          this.spinner.hide();
          this.messageService.success('Anexo excluído com sucesso!');
          this.getAll();
        },
        error => {
          this.spinner.hide();
          this.messageService.error('Erro ao exlcuir anexo!', 'Erro', error);
        }
      );
    }
  }

  public voltar(): void {
    this.router.navigate([`/financeiro/controle-financeiro/`], {relativeTo: this.activatedRoute});
  }

  public mostraAnexo(anexo: any): void {
    this.visualizaAnexo.id_anexo = anexo.id_anexo;
    this.visualizaAnexo.fileType = anexo.tipo;
    this.visualizaAnexo.open();
  }

}