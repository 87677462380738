import { map, catchError } from 'rxjs/operators';

import { Observable, forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';

import { MessageService } from '../../../../core';
import { PagamentoUnidade } from '../../../../models';
import { LancamentosUnidadesPagamentosHttpService } from './lancamentos-unidades-pagamentos-http.service';

/**
 * Service com métodos para trabalhar com os pagamentos no cadastro de um lançamento de unidades
 */
@Injectable()
export class LancamentosUnidadesPagamentosCadastroService {

	constructor(private lancamentosUnidadesPagamentosHttpService: LancamentosUnidadesPagamentosHttpService,
		private messageService: MessageService) { }

	/**
	 * Cria os pagamentos ao informar o número de parcelas do lançamento
	 */
	createPagamentos(idLancamento: number, numeroParcelas: number): PagamentoUnidade[] {
		const entities: PagamentoUnidade[] = [];
		for (let i = 1; i <= numeroParcelas; i++) {
			const entity = new PagamentoUnidade();
			entity.id_lancamento_unidade = idLancamento;
			entity.numero_parcela = i;
			entities.push(entity);
		}
		return entities;
	}

	/**
	 * Calcula o valor das parcelas dividindo o valor total pelo número de parcelas do lançamento
	 */
	calcularValorParcelas(valorTotal: number, parcelas: PagamentoUnidade[]) {
		if (parcelas && parcelas.length > 0) {
			const valorParcela = Number(valorTotal) / parcelas.length;
			for (const pagamento of parcelas) {
				pagamento.valor = valorParcela;
				pagamento.editado = true;
			}
		}
	}

	/**
	 * Validando se os campos de todos as parcelas de pagamentos estão informados corretamente
	 */
	validarSave(entities: PagamentoUnidade[]): boolean {
		if (!entities) {
			return false;
		}

		for (const entity of entities) {
			// Valor
			if (!entity.valor || isNaN(entity.valor)) {
				this.messageService.info(`É necessário informar o valor da ${entity.numero_parcela}ª parcela`, 'Informação');
				return false;
			}
			// Data Competência
			if (!entity.data_competencia) {
				this.messageService.info(`É necessário informar a competência da ${entity.numero_parcela}ª parcela`, 'Informação');
				return false;
			}
		}
		return true;
	}

	private createSave(idLancamento: number, entities: PagamentoUnidade[]): Observable<PagamentoUnidade>[] {
		if (!entities) {
			return [];
		}
		let saves: Observable<PagamentoUnidade>[] = [];
		entities.forEach(entity => {
			if (entity.editado) {
				if (!entity.id_lancamento_unidade) {
					entity.id_lancamento_unidade = idLancamento;
				}
				let observable: Observable<PagamentoUnidade>;
				if (entity.id) {
					observable = this.lancamentosUnidadesPagamentosHttpService.put(entity).pipe(
						map(response => response),
						catchError(error => {
							this.messageService.error(`Erro ao salvar a parcela de número ${entity.numero_parcela}. Tente novamente`, 'Erro', error);
							return Observable.throw(error);
						})
					);
				} else {
					observable = this.lancamentosUnidadesPagamentosHttpService.post(entity).pipe(
						map(response => response),
						catchError(error => {
							this.messageService.error(`Erro ao salvar a parcela de número ${entity.numero_parcela}. Tente novamente`, 'Erro', error);
							return Observable.throw(error);
						})
					);
				}
				saves.push(observable);
			}
		});
		return saves;
	}

	save(idLancamento: number, entities: PagamentoUnidade[]): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			const saves = this.createSave(idLancamento, entities);
			if (saves.length > 0) {
				forkJoin(saves).subscribe(
					() => resolve,
					error => reject(error)
				);
			} else {
				resolve;
			}
		});
	}

}