import { UnidadesPesquisaModalComponent } from './../../blocos/unidades/unidades-pesquisa-modal/unidades-pesquisa-modal.component';
import { BlocosPesquisaModalComponent } from './../../blocos/blocos-pesquisa-modal/blocos-pesquisa-modal.component';
import { BlocosHttpService } from './../../blocos/services/blocos-http.service';
import { Bloco } from './../../../models/entidades/bloco';
import { RateiosUnidadesHttpService } from './../unidades/services/rateios-unidades-http.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthService, MessageService } from '../../../core';
import { GerarCobrancaService } from 'app/home/rateios/gerar-cobranca/gerar-cobranca.service';
import { UnidadesHttpService } from './../../blocos/unidades/services/unidades-http.service';
import { Unidade } from './../../../models/entidades/unidade';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

declare var jQuery: any;

@Component({
  selector: 'gerar-cobranca',
  templateUrl: './gerar-cobranca.component.html',
  styleUrls: ['./gerar-cobranca.component.css']
})
export class GerarCobrancaComponent implements OnInit {

  private idRateio: number;
  bloco: Bloco;
  blocos: Bloco[] = [];
  unidade: Unidade;
  unidades: Unidade[] = [];
  idBlocoAtual: number;
  idUnidadeAtual: number;
  idCondominio: number;

  @ViewChild('inputBloco') inputBloco: ElementRef;
  @ViewChild('inputUnidade') inputUnidade: ElementRef;
  @ViewChild('blocosPesquisaModal') blocosPesquisaModal: BlocosPesquisaModalComponent;
  @ViewChild('unidadesPesquisaModal') unidadesPesquisaModal: UnidadesPesquisaModalComponent;

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private gerarCobrancaService: GerarCobrancaService,
    private messageService: MessageService,
    private rateiosUnidadesHttpService: RateiosUnidadesHttpService,
    private unidadesHttpService: UnidadesHttpService,
    private blocosHttpService: BlocosHttpService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.idRateio = +params['id'];
    });

    this.activatedRoute.queryParams.subscribe((params: Params) => {
        this.idCondominio = params['id_condominio'];
    });

    if (this.idCondominio)
        this.getBlocosByCondominio(this.idCondominio);
    else
        this.getBlocosByCondominio(this.authService.getIdCurrentCondominio());
  }

  /** Carrega todos os blocos do condomínio informado */
  getBlocosByCondominio(idCondominio: number) {
    this.blocos = [];
    this.blocosHttpService.getAllByCondominio(idCondominio)
        .subscribe(
            entities => {
                this.blocos = entities;
                // Se tiver um bloco atual selecionado pelo usuário setamos ele novamente no autocomplete
                if (this.idBlocoAtual && !isNaN(this.idBlocoAtual)) {
                    const index = this.blocos.findIndex(entity => entity.id === this.idBlocoAtual);
                    if (index > -1) {
                        this.setBloco(this.blocos[index]);
                    }
                }
            },
            error => this.messageService.error('Erro ao carregar os blocos do condomínio. Tente novamente', 'Erro', error)
        );
  }

  // Seta o bloco atual com o informado e carrega suas unidades
  private setBloco(blocoSelecionado: Bloco) {
    this.bloco = blocoSelecionado;
    this.getAllUnidadesByBloco(this.bloco.id);
    this.onCleanUnidade(this.inputUnidade.nativeElement);
  }

  // Ao selecionar o bloco
  onSelectBloco(tm: TypeaheadMatch): void {
      this.setBloco(tm.item as Bloco);
  }

  // Abre o modal de pesquisa da blocos
  openBlocosPequisaModal() {
      this.blocosPesquisaModal.open();
  }

  // Ao selecionar bloco no modal de pesquisa
  onSelectBlocosPesquisaModal(bloco: Bloco) {
      if (bloco) {
          this.setBloco(bloco)
      }
      jQuery('#bloco').focus();
  }


  // Ao limpar o bloco
  onCleanBloco(input: any) {
      this.onCleanUnidade(this.inputUnidade.nativeElement);
      this.bloco = null;
      input.value = '';
      input.focus();
  }

// Carrega todas as unidade do bloco informado
  getAllUnidadesByBloco(idBloco: number) {
    this.unidades = [];
    this.unidadesHttpService.getAllByBloco(idBloco)
        .subscribe(
            entities => {
                for (const unidade of entities) {
                    // Somente as unidade que tem nome devem ser consideradas no auto complete
                    if (unidade.nome) {
                        this.unidades.push(unidade);
                    }
                }
                // Se tiver uma unidade atual selecionada pelo usuário setamos ela novamente no autocomplete
                if (this.idUnidadeAtual && !isNaN(this.idUnidadeAtual)) {
                    const index = this.unidades.findIndex(entity => entity.id === this.idUnidadeAtual);
                    if (index > -1) {
                        this.setUnidade(this.unidades[index]);
                    }
                }
            },
            error => this.messageService.error('Erro ao carregar as unidades do bloco. Tente novamente', 'Erro', error)
        );
  }

  // Seta a unidade atual com a informada
  private setUnidade(unidadeSelecionada: Unidade) {
      this.unidade = unidadeSelecionada;
  }

  // Ao selecionar a unidade
  onSelectUnidade(tm: TypeaheadMatch): void {
      this.setUnidade(tm.item as Unidade);
  }



  // Ao limpar a unidade
  onCleanUnidade(input: any) {
      this.unidade = null;
      input.value = '';
      input.focus();
  }

  // Abre o modal de pesquisa de unidades
  openUnidadesPequisaModal() {
      this.unidadesPesquisaModal.open();
  }

  // Ao selecionar unidade no modal de pesquisa
  onSelectUnidadesPesquisaModal(unidade: Unidade) {
      if (unidade) {
          this.setUnidade(unidade)
      }
      jQuery('#unidade').focus();
  }

  baixar(isArquivoRemessa: boolean) {
    if (!isArquivoRemessa) {
      const btn = jQuery('#btnBoletos');
      btn.button('loading');
      this.gerarTitulos(btn);
    } else {
      const btn = jQuery('#btnArquivoRemessa');
      btn.button('loading');
      this.baixarArquivoRemessa()
      this.afterFinalizar(btn);
    }
  }

  baixarArquivoRemessa() {
    this.gerarCobrancaService.efetuarDownloadDoArquivoRemessa(this.idRateio);
  }

  private afterFinalizar(btn: any) {
      btn.button('reset');
      this.messageService.success('', 'Download efetuado com sucesso!');
  }

  private catchErrorFinalizar(error: any, btn: any) {
      this.messageService.error('Erro ao efetuar o download. Tente novamente', 'Erro', error);
      btn.button('reset');
  }

  gerarTitulos(btn): Promise<any> {
      let unidade: number;
      this.unidade && this.unidade.id ? unidade = this.unidade.id : unidade = null; 
      return new Promise<any>((resolve, reject) => {
          this.rateiosUnidadesHttpService.gerarTitulos(this.idRateio, unidade)
              .subscribe(
                  response => {
                    this.gerarCobrancaService.efetuarDownloadDosBoletos(this.authService.getIdCurrentCondominio(), this.idRateio);
                    this.afterFinalizar(btn);
                    resolve();
                  },
                  error => { 
                    this.catchErrorFinalizar(error, btn);
                    reject(error);
                  } 
              );
      });
  }

  cancel() {
    this.router.navigate(['../../'], {relativeTo: this.activatedRoute});
  }

}
