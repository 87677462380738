import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BaseModal } from 'app/componentes-utils/modal';
import { AuthService, MessageService } from 'app/core';
import { ContaBancaria, ExtratoAnexo } from 'app/models';
import { datepickerMesAnoOptions } from 'app/shared';
import { ExtratoAnexoHttpServiceService } from '../services';

declare var jQuery: any;

@Component({
  selector: 'extrato-anexo-cadastro-modal',
  templateUrl: './extrato-anexo-cadastro-modal.component.html',
  styleUrls: ['./extrato-anexo-cadastro-modal.component.css']
})
export class ExtratoAnexoCadastroModalComponent implements OnInit, BaseModal {

  public id_condominio: number;
  public extratoAnexo: ExtratoAnexo;
  public datepickerDataCompetenciaOptions = datepickerMesAnoOptions;

  @Input() idModal: string;
  @Input() contasBancarias: ContaBancaria[] = [];
  @ViewChild('form') form: NgForm; // variavel do form do cadastro

  constructor(private authService: AuthService,
    private messageService: MessageService,
    private extratoAnexoHttpService: ExtratoAnexoHttpServiceService) { }

  ngOnInit() {
    this.extratoAnexo = new ExtratoAnexo();
    this.id_condominio = this.authService.getIdCurrentCondominio();
    if (!this.idModal) {
      this.idModal = 'extratoAnexoCadastroModal';
    }
  }

  open() {
    this.extratoAnexo = new ExtratoAnexo();
    jQuery(`#${this.idModal}`).modal('show');
  }

  close() {
    this.extratoAnexo = new ExtratoAnexo();
    jQuery(`#${this.idModal}`).modal('hide');
  }

  public salvar(): void {
    if (this.form.valid) {
      const btnSave = jQuery('#btnSalvar');
      btnSave.button('loading');
      this.extratoAnexoHttpService.post(this.extratoAnexo).subscribe(
        () => {
          btnSave.button('reset');
          this.messageService.info('Anexo salvo com sucesso!!', 'Info');
          jQuery('#btnFiltrar').click();
          this.close();
        },
        error => {
          btnSave.button('reset');
          this.messageService.error('Erro ao salvar anexo. Tente novamente!', 'Erro', error);
        }
      );
    } else {
      this.messageService.warning('Verificar campos com preenchimento obrigatório!', 'Atenção!');
    }
  }

}