import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LancamentosCondominioHttpService } from '../../services';
import { BaseModal } from 'app/componentes-utils/modal';
import { Pagamento, PagamentoItem } from 'app/models';
import { MessageService } from 'app/core';

declare var jQuery: any;

@Component({
  selector: 'ajuste-parcela-pagamento-modal',
  templateUrl: './ajuste-parcela-pagamento-modal.component.html',
  styleUrls: ['./ajuste-parcela-pagamento-modal.component.css']
})
export class AjusteParcelaPagamentoModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @Input() id_lancamento_condominio: number;
  @Output() saveEmit: EventEmitter<Date> = new EventEmitter()

  public parcelas: Pagamento[] = [];

  constructor(private messageService: MessageService, private lancamentosCondominioHttpService: LancamentosCondominioHttpService) { }

  ngOnInit() {
    if (this.idModal == '') {
      this.idModal = 'geracaoDespesaFixaModal';
    }
  }

  open() {
    this.loadParcelas();
    jQuery(`#${this.idModal}`).modal('show');
  }
  
  close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  private loadParcelas(): void {
    this.parcelas = [];
    if (this.id_lancamento_condominio) {
      this.lancamentosCondominioHttpService.getAllByLancamentoId(this.id_lancamento_condominio).subscribe(
        res => this.parcelas = res.filter(p => !p.data_pagamento),
        error => this.messageService.error('Erro', 'Erro ao buscar pagamentos', error)
      );
    }
  }

  public updateParcela(p: Pagamento): void {
    p.updated = true;
    p.valor = p.itens.reduce((acc, i) => {
      return acc + Number(i.valor);
    }, 0);
  }

  public salvar(): void {
    var btnSave = jQuery('#btnAjusteParcelas').button('loading');
    this.lancamentosCondominioHttpService.setPagamentosByLancamento(this.id_lancamento_condominio, this.parcelas.filter(p => p.updated)).subscribe(
      () => {
        this.messageService.success('Parcelas atualizadas com sucesso!');
        this.saveEmit.emit();
        btnSave.button('reset');
        this.close();
      },
      error => {
        this.messageService.error('Parece que algo de errado aconteceu! Tente novamente', 'Erro', error);
        btnSave.button('reset');
      }
    );
  }

}
