import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';

import { ConfigService, Crud, CrudService, CustomHttp } from '../../../../../core';

import { Contato } from '../../../../../models';

@Injectable()
export class UnidadesContatosHttpService implements Crud<Contato> {
    resource = 'contato';
    private baseUrl: string;

    constructor(private customHttp: CustomHttp,
                private crudService: CrudService,
                private configService: ConfigService) {
        this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
    }

    get(id: number): Observable<Contato> {
        return this.crudService.get<Contato>(this.resource, id);
    }

    getAll(): Observable<Contato[]> {
        return this.crudService.getAll<Contato>(this.resource);
    }

    /**
     * Carrega todo os contatos da unidade informada
     */
    getAllByUnidade(idUnidade: number): Observable<Contato[]> {
        return this.customHttp.get(`${this.baseUrl}/consultar/unidade/${idUnidade}`)
            .pipe(
                map(response => response.json()),
                catchError(error => _throw(error))
            );
    }

    post(entity: Contato): Observable<Contato> {
        return this.crudService.post<Contato>(this.resource, entity);
    }

    put(entity: Contato): Observable<Contato> {
        return this.crudService.put<Contato>(this.resource, entity);
    }

    delete(entity: Contato): Observable<string> {
        return this.crudService.delete<Contato>(this.resource, entity);
    }
}
