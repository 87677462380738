
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Crud, CrudService, ConfigService, CustomHttp } from '../../../core';
import { CondominioConfiguracaoReserva, Reserva } from 'app/models';
import { map, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';

@Injectable()
export class ReservaAreaHttpService implements Crud<Reserva> {
	resource = 'reservas';
	private baseUrl: string;

	constructor(private customHttp: CustomHttp,
		private crudService: CrudService,
		private configService: ConfigService) {
		this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
	}

	get(id: number): Observable<Reserva> {
		return this.crudService.get<Reserva>(this.resource, id);
	}

	getAll(): Observable<Reserva[]> {
		return this.crudService.getAll<Reserva>(this.resource);
	}

	post(entity: Reserva): Observable<Reserva> {
		return this.crudService.post<Reserva>(this.resource, entity);
	}

	put(entity: Reserva): Observable<Reserva> {
		return this.crudService.put<Reserva>(this.resource, entity);
	}

	delete(entity: Reserva): Observable<string> {
		return this.crudService.delete<Reserva>(this.resource, entity);
	}

	/**
	 * Busca as reservas
	 */
	public getReservas(params: any): Observable<Reserva[]> {
		return this.customHttp.get(`${this.baseUrl}/listar`, { params }).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Persiste dados da reserva
	 * @param reserva 
	 */
	public salvaReserva(reserva: Reserva): Observable<Reserva> {
		return this.customHttp.post(`${this.baseUrl}/cadastrar`, reserva).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Cancelamento da reserva
	 * @param id 
	 * @author Marcos Frare
	 */
	public cancelaReserva(id: number): Observable<any> {
		return this.customHttp.delete(`${this.baseUrl}/cancelar/${id}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Exclusão da reserva
	 * @param id 
	 * @author Marcos Frare
	 * @since 03/06/2021
	 */
	public excluirReserva(id: number): Observable<any> {
		return this.customHttp.delete(`${this.baseUrl}/excluir/${id}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Montar resumo da reserva
	 * @param reserva 
	 * @author Marcos Frare
	 * @since 08/06/2021
	 */
	public montaResumo(reserva: Reserva): Observable<any> {
		return this.customHttp.post(`${this.baseUrl}/monta-resumo`, reserva).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Busca configurações de reserva de um condomínio
	 * @param idCondominio 
	 * @returns CondominioConfiguracaoReserva
	 * @author Marcos Frare
	 * @since 23/08/2022
	 */
	public getConfiguracao(idCondominio: number): Observable<CondominioConfiguracaoReserva> {
		return this.customHttp.get(`${this.baseUrl}/configuracao/condominio/${idCondominio}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Salvar configuração de reservas de um condomínio
	 * @param condominioConfiguracaoReserva 
	 * @author Marcos Frare
	 * @since 23/08/2022
	 * @returns CondominioConfiguracaoReserva
	 */
	public saveConfiguracao(condominioConfiguracaoReserva: CondominioConfiguracaoReserva): Observable<CondominioConfiguracaoReserva> {
		return this.customHttp.post(`${this.baseUrl}/configuracao`, condominioConfiguracaoReserva).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

}