import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseVisualizacaoComponent } from 'app/componentes-base';
import { AuthService, MessageService } from 'app/core';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { Filtro } from 'app/models';
import { NgxSpinnerService } from 'ngx-spinner';
import { FiltroHttpService } from './service';

@Component({
  selector: 'filtro',
  templateUrl: './filtro.component.html',
  styleUrls: ['./filtro.component.css']
})
export class FiltroComponent extends BaseVisualizacaoComponent<Filtro> {

  constructor(public activatedRoute: ActivatedRoute,
      public router: Router,
      public messageService: MessageService,
      private authService: AuthService,
      private filtroHttpService: FiltroHttpService,
      public permissionService: PermissionService,
      public spinner: NgxSpinnerService) {
    super('#tableRelatorioFiltro', filtroHttpService, activatedRoute, router, messageService, permissionService, spinner);
  }

  ngOnInit() {
    if(!this.authService.isAdmin) {
      this.router.navigate(['/dashboard'], {relativeTo: this.activatedRoute});
    }
    super.ngOnInit();
  }

  /**
   * Excluir um filtro
   * @param entity Objeto a ser excluído
   * @author Marcos Frare
   * @since 05/12/2020
   */
  public delete(entity: Filtro): void {
    if (confirm(`Deseja excluir o filtro ${entity.label}?`)) {
      this.filtroHttpService.delete(entity).subscribe(
        () => {
          super.getAll();
          this.messageService.success('Filtro excluído com sucesso!', 'Info');
        },
        error => {
          this.messageService.error(`Erro ao excluir o filtro ${entity.label}!`, 'Erro', error);
        }
      );
    }
  }

}