import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseCadastroComponent } from '../../../componentes-base';
import { AuthService, MessageService } from '../../../core';
import { Fornecedor, LancamentosCondominio, Pagamento, TYPES_PAGAMENTO, CategoriaContas, tiposDivisaoRateio, tiposUnidade, Unidade, TipoDocumentoFiscal, DocumentoFiscal, Anexo } from '../../../models';
import { LancamentosCondominioHttpService } from '../services';
import { FornecedoresHttpService, FornecedoresCadastroModalComponent } from '../../fornecedores';
import { PermissionService } from '../../../core/services/permission/permission.service';
import { FloatFormatPipe, DecimalFormatPipe } from '../../../shared/pipes';
import { LancamentoPagamentosComponent } from 'app/componentes-utils/lancamento-pagamentos';
import { isNull } from 'util';
import { NgxSpinnerService } from 'ngx-spinner';
import { LancamentoCondominioItemComponent } from './lancamento-condominio-item';
import { VisualizaAnexoComponent } from 'app/shared';
import { AjusteParcelaPagamentoModalComponent } from './ajuste-parcela-pagamento-modal';

declare var jQuery: any;

@Component({
	selector: 'lancamentos-condominio-cadastro',
	templateUrl: './lancamentos-condominio-cadastro.component.html',
	styleUrls: ['./lancamentos-condominio-cadastro.component.css'],
	providers: [ PermissionService, FloatFormatPipe, DecimalFormatPipe ]
})
export class LancamentosCondominioCadastroComponent extends BaseCadastroComponent<LancamentosCondominio> implements OnInit {

	@ViewChild('fornecedoresCadastroModal') fornecedoresCadastroModal: FornecedoresCadastroModalComponent;
	@ViewChild('lancamentoPagamentos') lancamentoPagamentos: LancamentoPagamentosComponent;
	@ViewChild('lancamentoCondominioItem') lancamentoCondominioItem: LancamentoCondominioItemComponent;
	@ViewChild('visualizaAnexoModal') visualizaAnexoModal: VisualizaAnexoComponent;
	@ViewChild('ajusteParcelaPagamentoModal') ajusteParcelaPagamentoModal: AjusteParcelaPagamentoModalComponent;

	public categoriasContasReceita: CategoriaContas[] = [];
	public categoriasContasDespesa: CategoriaContas[] = [];
	public fornecedores: Fornecedor[] = [];
	public idCliente: number;
	public idCondominio: number;
	public tiposDivisaoRateio = tiposDivisaoRateio;
	public tiposUnidade = tiposUnidade;
	public typesPagamento = TYPES_PAGAMENTO;
	public readOnly = false;
	public edit = true;
	
	public unidades: Unidade[] = [];
	public valor_total_inicial: number;
	public tiposDocumentoFiscal: TipoDocumentoFiscal[] = [];
	
	constructor(public activatedRoute: ActivatedRoute,
			public router: Router,
			public messageService: MessageService,
			public permissionService: PermissionService,
			public lancamentosCondominioHttpService: LancamentosCondominioHttpService,
			private authService: AuthService,
			private fornecedoresHttpService: FornecedoresHttpService,
			private floatFormatPipe: FloatFormatPipe,
			private decimalFormatPipe: DecimalFormatPipe,
			public spinner: NgxSpinnerService) {
		super(LancamentosCondominio, lancamentosCondominioHttpService, activatedRoute, router, messageService, permissionService);
	}

	ngOnInit() {
		this.idCondominio = this.authService.getIdCurrentCondominio();
		this.idCliente = this.authService.getIdCurrentCliente();
		this.getAllTipoDocumentoFiscal();
		this.getAllFornecedores();
		super.ngOnInit();
	}

	/**
	 * Obter todos os tipos de documentos fiscais
	 * @author Marcos Frare
	 * @since 15/10/2020
	 */
	private getAllTipoDocumentoFiscal(): void {
		this.spinner.show();
		this.lancamentosCondominioHttpService.obtemTiposDocumentoFiscal().subscribe(
			response => {
				this.spinner.hide();
				this.tiposDocumentoFiscal = response;
			},
		);
	}

	private getAllFornecedores(): void {
		this.spinner.show();
		this.fornecedoresHttpService.getAllByCliente(this.idCliente).subscribe(
			entities => {
				this.spinner.hide();
				this.fornecedores = entities
			},
			error => {
				this.spinner.hide();
				this.messageService.error('Erro ao carregar os fornecedores', 'Erro', error)
			}
		);
	}

	private parsePagamentos(pagamentos: Pagamento[]): void {
		this.readOnly = true;
		this.entity.pagamentos = pagamentos.map(pagamento => {
			pagamento.valor_pago = this.floatFormatPipe.transform(pagamento.valor || 0 + pagamento.multa || 0 + pagamento.juros || 0);
			pagamento.permite_exclusao = pagamento.data_pagamento || pagamento.id_demonstrativo_rateio ? false : true;
			if (!pagamento.id_demonstrativo_rateio) {
				this.readOnly = false;
			}
			if (pagamento.id_demonstrativo_rateio && !isNull(pagamento.id)) {
				this.edit = false;
			}

			return pagamento;
		});
	}

	public allowEdit(): boolean {
		return !this.readOnly && this.entity.pago;
	}

	public afterEdit(entity: LancamentosCondominio): void {
		this.valor_total_inicial = this.entity.valor_total;
		this.entity.anexos = this.entity.anexos && this.entity.anexos.length ? this.entity.anexos : [new Anexo()];
		this.parsePagamentos(entity.pagamentos);
	}

	public afterInsert(): void {
		this.entity.id_condominio = this.idCondominio;
	}

	public createFornecedor(): void {
		this.fornecedoresCadastroModal.open();
	}

	public onAfterSaveFornecedor(fornecedor: Fornecedor): void {
		this.entity.id_fornecedor = fornecedor.id;
		this.fornecedores.push(fornecedor);
		jQuery('#fornecedor').focus();
	}

	public updatePagamentos(): void {
		this.lancamentoPagamentos.updatePagamentos();
	}

	public dividirEntreParcelas(): void {
		this.lancamentoPagamentos.dividirEntreParcelas();
	}

	public canEditPagamento(pagamento: Pagamento): boolean {
		return this.lancamentoPagamentos.canEditPagamento(pagamento);
	}

	private checkSindicoAprovaOnInsert(pagamento: Pagamento): void {
		if (!this.editMode) {
			pagamento.sindico_aprova = pagamento.isSindicoAprova ? 1 : 2;
		}
	}

	public beforeSave(): boolean {
		let totalPagamentos = 0;
		for (const pg of this.entity.pagamentos) {
			totalPagamentos += parseFloat(<any>pg.valor);
		}
		this.entity.valor_total = this.entity.valor_total != null ? this.entity.valor_total : totalPagamentos;
		if (Number(this.entity.valor_total) !== Number(totalPagamentos.toFixed(2))) {
			this.messageService.warning('O valor total do lançamento está diferente da soma de suas parcelas, ' +
				'favor corrigir os valores e tentar novamente', 'Atenção');
			return false;
		}

		this.validateAnexos();

		if (this.editMode) {
			if (this.entity.valor_total !== this.valor_total_inicial) {
				if (!confirm(`O valor total do lançamento foi alterado de 
                    ${this.decimalFormatPipe.transform(this.valor_total_inicial, 1, 2, 2)} 
                    para ${this.decimalFormatPipe.transform(this.entity.valor_total, 1, 2, 2)}, 
                    confirma essa alteração?`)) {
					return false;
				}
			}
		}

		if (!this.validaSavePagamento()) {
			return false;
		}
		return this.itensValid();
	}

	private validateAnexos(): void {
		if (this.entity.anexos) {
			this.entity.anexos = this.entity.anexos.filter(anexo => anexo && anexo.arquivo);
		}
	}

	/**
	 * Validação de campos obrigatórios dos ítens do lançamento
	 * @author Marcos Frare
	 * @returns Boolean
	 * @since 24/01/2021
	 */
	private itensValid(): boolean {
		for (let item of this.entity.lancamentoCondominioItems) {
			if (!item.id_categoria_conta) {
				this.messageService.warning('Ítem com categoria de conta não informada. Verifique!', 'Atenção!');
				return false;
			}
			if (!item.valor || item.valor == 0) {
				this.messageService.warning('Ítem com valor não informado ou informado zero (0). Verifique!', 'Atenção!');
				return false;
			}
		}
		return true;
	}

	public salvar(addNew: boolean): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.makeAllFieldsDirty();
			if (this.form.valid) {
				if (this.beforeSave()) {
					this.btnSave = jQuery('#btnSave');
					this.btnSave.button('loading');

					const envio: any = { ...this.entity };
					const pagamentos: Pagamento[] = [];
					this.entity.pagamentos.forEach(pagamento => {
						const obj: any = { ...pagamento };
						pagamentos.push(obj);
					});
					envio.pagamentos = pagamentos.map(pagamento => {
						this.checkSindicoAprovaOnInsert(pagamento);
						// Verifica se faz o rateio ou não
						if (isNull(envio.id_categoria_conta_nao_rateado)) {
							pagamento.ratear = true;
						} else {
							pagamento.ratear = false;
						}
						return pagamento;
					});
					// Carrega o Documento Fiscal
					envio.documentoFiscal = new DocumentoFiscal();
					envio.documentoFiscal.id = this.entity.documentoFiscal.id;
					envio.documentoFiscal.id_lancamento_condominio = this.entity.id;
					envio.documentoFiscal.id_tipo_documento_fiscal = this.entity.documentoFiscal.id_tipo_documento_fiscal;
					envio.documentoFiscal.numero_documento = this.entity.documentoFiscal.numero_documento;
					envio.documentoFiscal.data_documento = this.entity.documentoFiscal.data_documento;
					this.spinner.show();
					if (this.editMode) {
						this.crudService.put(envio).subscribe(
							entity => {
								this.messageService.success('', 'Registro salvo com sucesso');
								this.afterSave(this.entity);
								this.btnSave.button('reset');
								if (addNew) {
									this.clean();
								}
								resolve(entity);
								this.spinner.hide();
							},
							error => {
								this.spinner.hide();
								this.messageService.error('Erro ao atualizar o registro', 'Erro', error);
								this.btnSave.button('reset');
								reject(null);
							}
						);
					} else {
						this.crudService.post(envio).subscribe(
							entity => {
								this.spinner.hide();
								this.entity = entity;
								this.messageService.success('', 'Registro salvo com sucesso');
								this.btnSave.button('reset');
								this.afterSave(this.entity);
								resolve(entity);
							},
							error => {
								this.spinner.hide();
								this.messageService.error('Erro ao salvar o registro', 'Erro', error);
								this.btnSave.button('reset');
								reject(null);
							}
						);
					}
				} else {
					reject(null);
				}
			} else {
				this.showMessageFormInvalid();
				reject(null);
			}
		});
	}

	public afterSave(entity: LancamentosCondominio): void {
		super.afterSave(entity);
	}

	private validaSavePagamento(): boolean {
		for (const pagamento of this.entity.pagamentos) {
			if (!pagamento.valor || isNaN(pagamento.valor)) {
				this.messageService.info(`É necessário informar o valor da ${pagamento.numero_parcela}ª parcela`, 'Informação');
				return false;
			}
			if (!pagamento.data_vencimento) {
				this.messageService.info(`É necessário informar a data de vencimento da ${pagamento.numero_parcela}ª parcela`, 'Informação');
				return false;
			}
		}
		return true;
	}

	public cancel(): void {
		this.router.navigate(['../'], { relativeTo: this.activatedRoute });
	}

	public insert(): void {
		this.router.navigate(['../new'], { relativeTo: this.activatedRoute });
	}

	private clean(): void {
		this.entity = new LancamentosCondominio();
		this.afterInsert();
		this.valor_total_inicial = null;
	}

	public addAnexos(): void {
		this.entity.anexos.push(new Anexo());
	}

	public removeAnexos(index): void {
		this.entity.anexos.splice(index, 1);
	}

	/**
	 * Evento de atualização de um item do lançamento
	 * @author Marcos Frare
	 * @since 03/09/2021
	 */
	public updateItem(): void {
		let valor_total = 0;
		this.entity.lancamentoCondominioItems.map(item => {
			valor_total += Number(item.valor);
		});
		this.entity.valor_total = valor_total.toFixed(2);
		this.dividirEntreParcelas();
	}

	/**
	 * Mostra anexo
	 * @param id
	 * @author Marcos Frare
	 * @since 05/03/2022
	 */
	 public fileView(file: any): void {
		if (file.id) {
			this.visualizaAnexoModal.id_anexo = file.id;
			this.visualizaAnexoModal.fileType = file.fileType;
			this.visualizaAnexoModal.open();
		}
	}

	public ajustarParcelas(): void {
		this.ajusteParcelaPagamentoModal.id_lancamento_condominio = this.entity.id;
		this.ajusteParcelaPagamentoModal.open();
	}

	public ajusteParcelasEmit(): void {
		this.ngOnInit();
	}
}