import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared';
import { FornecedoresModule } from '../fornecedores';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { InventariosComponent } from './inventarios.component';
import { InventariosCadastroComponent } from './cadastro/inventarios-cadastro.component';
import { InventariosHttpService } from './services';
import { BlocosModule } from '../blocos';

@NgModule({
    imports: [
        SharedModule,
        FornecedoresModule,
        BlocosModule,
        TypeaheadModule
    ],
    declarations: [
        InventariosComponent,
        InventariosCadastroComponent
    ],
    providers: [
        InventariosHttpService
    ]
})
export class InventariosModule { }
