import { Fornecedor } from './../../models/entidades/fornecedor';
import { FornecedoresHttpService } from './../fornecedores/services/fornecedores-http.service';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseVisualizacaoComponent } from '../../componentes-base';
import { Inventario, Bloco, tiposInventario,} from '../../models';
import { AuthService, MessageService } from '../../core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { InventariosHttpService } from './services';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { BlocosPesquisaModalComponent, BlocosHttpService } from '../blocos';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

declare var jQuery: any;

@Component({
    selector: 'inventarios',
    templateUrl: 'inventarios.template.html',
    styleUrls: ['./inventarios.component.css'],
    providers: [PermissionService, DatePipe]
})

export class InventariosComponent extends BaseVisualizacaoComponent<Inventario> {
    
    @ViewChild('blocosPesquisaModal') blocosPesquisaModal: BlocosPesquisaModalComponent;

    public filtro: any = {};
    public blocos: Bloco[] = [];
    public fornecedores: Fornecedor[] = [];
    public tiposInventario: any[] = tiposInventario;
    public idCliente: number;
    public idCondominio: number;

    constructor(activatedRoute: ActivatedRoute,
                router: Router,
                messageService: MessageService,
                permissionService: PermissionService,
                private authService: AuthService,
                private fornecedoresHttpService: FornecedoresHttpService,
                private inventariosHttpService: InventariosHttpService,
                private blocosHttpService: BlocosHttpService,
                private datePipe: DatePipe,
                public spinner: NgxSpinnerService) {
        super('#tableInventarios', inventariosHttpService, activatedRoute, router, messageService, permissionService, spinner);
    }

    ngOnInit() {
        this.idCliente = this.authService.getIdCurrentCliente();
        this.idCondominio = this.authService.getIdCurrentCondominio();
        this.getAllFornecedoresByCliente();
        this.getAllBlocosByCondominio();
        this.setInitialFilter();
        this.search();
        this.getPermissions();
    }

    private getAllFornecedoresByCliente(): void {
        this.fornecedoresHttpService.getAllByCliente(this.idCliente).subscribe(
            entities => this.fornecedores = entities,
            error => this.messageService.error('Erro ao carregar os fornecedores', 'Erro', error)
        );
    }

    private getAllBlocosByCondominio(): void {
        this.blocosHttpService.getAllByCondominio(this.idCondominio).subscribe(
            blocos => this.blocos = blocos,
            error => this.messageService.error('Erro ao carregar os blocos do condomínio. Tente novamente', 'Erro', error)
        )
    }

    public onSelectBloco(tm: TypeaheadMatch): void {
        this.setBloco(tm.item as Bloco);
    }

    private setBloco(selectedBloco: Bloco): void {
        this.filtro.bloco = selectedBloco;
        this.filtro.id_bloco = selectedBloco.id;
    }

    public openBlocosPequisaModal(): void {
        this.blocosPesquisaModal.open();
    }

    public onSelectBlocosPesquisaModal(bloco: Bloco): void {
        if (bloco) this.setBloco(bloco);
        jQuery('#bloco').focus();
    }

    public onCleanBloco(input: any): void {
        this.filtro.bloco = null;
        delete this.filtro.id_bloco;
        input.value = '';
        input.focus();
    }

    public getAll(): void {
        this.spinner.show();
        this.entities = [];
        this.destroyTable();
        this.inventariosHttpService.getAllByCondominio(this.idCondominio)
            .subscribe(
                entity => {
                    this.spinner.hide();
                    this.entities = entity;
                    this.showTable();
                },
                error => {
                    this.spinner.hide();
                    this.messageService.error('Erro ao carregar o inventário do condomínio selecionado. Tente novamente', 'Erro', error);
                }
            );
    }

    public search(): void {
        this.spinner.show();
        this.entities = [];
        this.destroyTable();
        this.inventariosHttpService.search(this.parseSearch(this.filtro))
            .subscribe(
                entities => {
                    this.spinner.hide();
                    this.entities = entities;
                    this.showTable();
                },
                error => {
                    this.spinner.hide();
                    this.messageService.error('Erro ao carregar o inventário do condomínio selecionado. Tente novamente', 'Erro', error);
                }
            );
    }

    public setInitialFilter(): void {
        this.filtro.id_condominio = this.idCondominio;
        this.filtro.data_aquisicao_inicial = null;
        this.filtro.data_aquisicao_final = null;
    }

    public delete(inventario: Inventario): void {
        if (confirm(`Tem certeza que deseja excluir o inventário ${inventario.marca} - ${inventario.modelo}?`)) {
            this.spinner.show();
            this.inventariosHttpService.delete(inventario).subscribe(
                response => {
                    this.spinner.hide();
                    this.messageService.success('Inventário excluído com sucesso');
                    this.search();
                },
                error => {
                    this.spinner.hide();
                    this.messageService.error('Erro', 'Erro ao excluir inventário', error);
                }
            );
        }
    }

    private parseSearch(originalSearch: any): any {
        let search = {...originalSearch};
        
        if(originalSearch.data_aquisicao_inicial)
            search.data_aquisicao_inicial = this.datePipe.transform(originalSearch.data_aquisicao_inicial, 'yyyy-MM-dd')
        else
            delete search.data_aquisicao_inicial;

        if(originalSearch.data_aquisicao_final)
            search.data_aquisicao_final = this.datePipe.transform(originalSearch.data_aquisicao_final, 'yyyy-MM-dd')
        else
            delete search.data_aquisicao_final;
        
        delete search.bloco;
        return search;
    }
}
