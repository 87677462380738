import { BaseEntity } from '../base-entity';
import { LancamentosCondominio } from './lancamentos-condominio';
import { Pagamento } from './pagamento';

export class PagamentoAprovacaoSindico extends BaseEntity {
	id_pagamento: number;
	id_tipo_contato: number;
	data_aprovacao: Date;
	observacao: string;

	pagamento: Pagamento;
	lancamentoCondominio: LancamentosCondominio;

	constructor() {
		super();
		this.pagamento = new Pagamento();
		this.lancamentoCondominio = new LancamentosCondominio();
	}
}
