import { Pipe, PipeTransform } from '@angular/core';

import { HistoricoUnidade } from '../../../../../models';

/**
 * Pipe para filtar o histórico do morador dentro de uma unidade
 */
@Pipe({
    name: 'unidadesHistoricoMoradorFilter',
    pure: false
})
export class UnidadesHistoricoMoradorFilterPipe implements PipeTransform {

    transform(values: HistoricoUnidade[]): HistoricoUnidade[] {
         if (!values) {
             return [];
         }
         return values.filter(value => {
            return value.tipo === 1; // 1 - Morador
         });
    }
}
