import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Auth, AuthService } from '../core/services/auth';
import { CondominiosHttpService } from '../home/condominios/services/condominios-http.service';

import { Condominio, Cliente } from '../models';

@Component({
	selector: 'login',
	templateUrl: 'login.template.html',
	styleUrls: ['./login.component.css'],
	providers: [CondominiosHttpService]
})

export class LoginComponent implements OnInit {
	auth: Auth;
	message = 'Efetue login para acessar';
	error = false;
	condominios: Condominio[];
	clientes: Cliente[];

	constructor(private authService: AuthService,
		private router: Router,
		private condominiosHttpService: CondominiosHttpService) { }

	ngOnInit() {
		this.auth = new Auth();
	}

	public login(): void {
		this.message = 'Efetuando login...';

		// Forçar limpar dados de condomínios na sessão
		this.authService.removeLoggedInfo();

		this.authService.login(this.auth)
			.subscribe(
				() => {
					this.error = false;
					this.message = 'Redirecionando...';
					this.onLogin();
				},
				error => {
					if (error.status === 401) {
						this.message = 'Usuário ou senha inválidos';
					} else {
						this.message = 'Erro ao efetuar o login. Tente novamente';
					}
					this.error = true;
				}
			);
	}

	private onLogin(): void {
		this.authService.isAdmin ? this.goToClientes() : this.carregaCondominiosUsuario();
	}

	private carregaCondominiosUsuario(): void {
		this.getUserAccess().then(condominios => {
			this.condominios = condominios;
			if (this.condominios.length === 1) {
				this.authService.setLoggedInfo(this.condominios[0]);
			} else {
				this.authService.setLoggedInfo();
			}
			this.goToDashboard();
		}).catch(() => {
			this.authService.removeLoggedInfo();
		});
	}

	private getUserAccess(): Promise<any> {
		const idUsuario: number = Number(this.authService.getIdCurrentUser());

		return new Promise<Condominio[]>((resolve, reject) => {
			this.condominiosHttpService.getAllByUsuario(idUsuario).subscribe(
				condominios => resolve(condominios),
				error => reject()
			);
		});
	}

	private goToDashboard(): void {
		this.router.navigate(['/dashboard']);
	}

	private goToClientes(): void {
		this.router.navigate(['/clientes']);
	}

}