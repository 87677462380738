import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import { AuthService, MessageService } from 'app/core';
import { BlocosHttpService, BlocosPesquisaModalComponent, UnidadesHttpService } from 'app/home/blocos';
import { ContasBancariasHttpService } from 'app/home/contas-bancarias';
import { Bloco, ContaBancaria, Titulo, Unidade } from 'app/models';
import { datepickerMesAnoOptions } from 'app/shared';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { IntegracaoBancariaFiltro } from './integracao-bancaria-filtro';
import { RetornoEnvioIntegracaoModalComponent } from './retorno-envio-integracao-modal';
import { BoletoIntegracaoHttpService } from './service';

declare var jQuery: any;

export const tiposFiltro = [
	{ value: 1, nome: 'Competência' },
	{ value: 2, nome: 'Vencimento' }
];

@Component({
  selector: 'integracao-bancaria-modal',
  templateUrl: './integracao-bancaria-modal.component.html',
  styleUrls: ['./integracao-bancaria-modal.component.css']
})
export class IntegracaoBancariaModalComponent implements OnInit {

  @Input() idModal: string;

  @ViewChild('retornoEnvioIntegracaoModal') retornoEnvioIntegracaoModal: RetornoEnvioIntegracaoModalComponent;
  @ViewChild('blocosPesquisaModal1') blocosPesquisaModal1: BlocosPesquisaModalComponent;

  public titulos: Titulo[] = [];
  public allSelected = true;
  public contasBancarias: ContaBancaria[] = [];
  public id_conta_bancaria: number = null;
  public filtro: IntegracaoBancariaFiltro;
  public datepickerDataCompetenciaOptions = datepickerMesAnoOptions;
  public tiposFiltro = tiposFiltro;
  public blocos: Bloco[];
  public unidades: Unidade[];
  public showInputBlocos: boolean = true;

  multiselectSettings: IMultiSelectSettings = {
    enableSearch: true,
    containerClasses: 'container-multiselect',
    showUncheckAll: true,
    showCheckAll: true,
    dynamicTitleMaxItems: 3,
    checkedStyle: 'checkboxes',
    buttonClasses: 'btn btn-default',
    itemClasses: 'dropdown-menu'
  };

  multiselectOptionsUnidades: IMultiSelectOption[];
  multiselectTextsUnidades: IMultiSelectTexts = {
    checkAll: 'Selecionar todas',
    uncheckAll: 'Limpar',
    checked: 'selecionada',
    checkedPlural: 'selecionadas',
    searchPlaceholder: 'Pesquisar...',
    defaultTitle: 'Selecionar',
    allSelected: 'Todas selecionadas',
    searchEmptyResult: 'Nenhum registro encontrado',
    searchNoRenderText: 'Digite para pesquisar',
  };

  constructor(private messageService: MessageService,
    private authService: AuthService,
    private boletoIntegracaoHttpService: BoletoIntegracaoHttpService,
    private contaBancariaHttpService: ContasBancariasHttpService,
    private blocosHttpService: BlocosHttpService,
    private unidadesHttpService: UnidadesHttpService) { }

  ngOnInit() {
    if (!this.idModal) {
      this.idModal = 'integracaoBancariaModal';
    }
    this.clearFilters();
  }
  
  open() {
    this.id_conta_bancaria = null;
    this.clearFilters();
    this.loadTitulosIntegracao();
    this.loadContasBancarias();
    this.getBlocosByCondominio(this.filtro.id_condominio);
    this.getAllUnidades();
    jQuery(`#${this.idModal}`).modal('show');
  }

  close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Busca as contas bancárias integradas do condomínio
   * @author Marcos Frare
   * @since 19/07/2021
   */
  private loadContasBancarias(): void {
    this.contasBancarias = [];
    this.contaBancariaHttpService.contaBancariaIntegradaCondominio().subscribe(
      res => {
        this.contasBancarias = res;
        // Sempre seleciona a primeira conta
        this.id_conta_bancaria = this.contasBancarias.length ? this.contasBancarias[0].id : null;
      },
      error => {
        this.contasBancarias = [];
        this.messageService.error('Não foi possível buscar as contas bancárias do condomínio! Tente novamente.', 'Erro', error);
      }
    );
  }

  /**
   * Limpar filtros
   * @author Marcos Frare
   * @since 17/02/2022
   */
  public clearFilters() {
    this.filtro = new IntegracaoBancariaFiltro();
    this.filtro.id_condominio = this.authService.getIdCurrentCondominio();
  }

  private getBlocosByCondominio(idCondominio: number) {
    this.blocosHttpService.getAllByCondominio(idCondominio).subscribe(
      entitites => {
        this.blocos = entitites;
        if (this.blocoUnico(this.blocos)) {
          this.setBloco(this.blocos[0]);
          this.showInputBlocos = false;
        } else if (!this.blocos) {
          this.messageService.error('Não existem blocos cadastrados no Condomínio. Cadastre  um bloco e tente novamente', 'Erro')
        }
      },
      error => this.messageService.error('Erro ao carregar os blocos do condomínio. Tente novamente', 'Erro', error)
    );
  }

  private setBloco(blocoSelecionado: Bloco) {
    this.filtro.bloco = blocoSelecionado;
    this.filtro.id_bloco = blocoSelecionado.id;
    this.filterUnidades();
    this.onCleanUnidades();
  }

  /**
   * Busca todas as unidades do codomínio
   * @author Marcos Frare
   * @since 24/04/2020
   */
  private getAllUnidades(): void {
    this.unidadesHttpService.getAllByCondominioBloco(this.filtro.id_condominio, null).subscribe(
      response => {
        this.unidades = response;
        this.filterUnidades();
      },
      error => this.messageService.error('Erro ao carregar as unidades do condomínio. Tente novamente', 'Erro', error)
    );
  }

  /**
   * Filtra as unidades com base no campo de bloco
   * @author Marcos Frare
   * @since 24/04/2020
   */
  private filterUnidades(): void {
    this.multiselectOptionsUnidades = [];
    if (this.filtro.id_bloco) {
      this.multiselectOptionsUnidades = this.unidades.filter(und => und.id_bloco == this.filtro.id_bloco).map(unid => {
        return {id: unid.id, name: `${unid.nome_bloco} - ${unid.nome_unidade}`}
      })
    } else {
      this.multiselectOptionsUnidades = this.unidades.map(unid => {
        return {id: unid.id, name: `${unid.nome_bloco} - ${unid.nome_unidade}`}
      });
    }
  }

  onCleanUnidades() {
    this.filtro.ids_unidades = [];
  }

  onSelectBloco(tm: TypeaheadMatch): void {
    this.setBloco(tm.item as Bloco);
  }

  openBlocosPequisaModal() {
    this.blocosPesquisaModal1.open();
  }

  onSelectBlocosPesquisaModal(bloco: Bloco) {
    if (bloco) {
      this.setBloco(bloco)
    }
    jQuery('#blocoFiltro').focus();
  }

  onCleanBloco(input: any) {
    this.filtro.bloco = null;
    this.filtro.id_bloco = null;
    input.value = '';
    this.filterUnidades();
  }

  private blocoUnico(blocos: Bloco[]): boolean {
    return blocos.length === 1;
  }

  /**
   * Busca os títulos para integração
   * @author Marcos Frare
   * @since 17/05/2021
   */
  public loadTitulosIntegracao(): void {
    const btn = jQuery(`#${this.idModal} #btnFiltrar`);
    btn.button('loading');
    this.titulos = [];
    this.boletoIntegracaoHttpService.getTitulosIntegracao(this.filtro).subscribe(
      response => {
        this.titulos = response;
        btn.button('reset');
      },
      error => {
        this.messageService.error('Erro ao buscar boletos para integração! Tente novamente.', 'Erro!', error);
        btn.button('reset');
      }
    );
  }

  /**
   * Click n alinha do titulo
   * @param titulo 
   * @author Marcos Frare
   * @since 17/05/2021
   */
  public clickLinhaTitulo(titulo: Titulo): void {
    titulo.selected = !titulo.selected;
  }

  /**
   * Selecionar todos
   * @author Marcos Frare
   * @since 27/05/2021
   */
  public toggleAllSelected(): void {
    this.titulos.map(tt => {
      tt.selected = this.allSelected;
    });
	}

  /**
   * Verifica se todos selecionados
   * @returns 
   * @author Marcos Frare
   * @since 27/05/2021
   */
  public checkAllSelected(): boolean {
    for (let tt of this.titulos) {
      if (!tt.selected) {
        return false;
      }
    }
    return true;
	}

  /**
   * Faz integração dos boletos
   * @author Marcos Frare
   * @since 01/06/2021
   */
  public enviarBoletos(): void {
    let btn = jQuery('#btnIntegrarModal');
    btn.button('loading');
    let titulos = <Titulo[]>this.titulos.filter(tt => tt.selected).map(ttId => {
      return {id: ttId.id, id_boleto: ttId.id_boleto, acao: ttId.acao};
    });
    if (titulos.length > 0 && this.id_conta_bancaria) {
      if (confirm('Deseja integrar títulos selecionados?')) {
        this.boletoIntegracaoHttpService.envioBoletosIntegracao(titulos, this.id_conta_bancaria).subscribe(
          response => {
            btn.button('reset');
            // Atualizar os titulos pendentes e abrir tela com retorno do envio
            this.loadTitulosIntegracao();
            this.retornoEnvioIntegracaoModal.boletos = response;
            this.retornoEnvioIntegracaoModal.open();
          },
          error => {
            btn.button('reset');
            this.messageService.error('Erro ao gerar boletos para integração! Tente novamente.', 'Erro!', error);
          }
        );
      } else {
        btn.button('reset');
      }
    } else {
      btn.button('reset');
      if (!this.id_conta_bancaria) {
        this.messageService.warning('Necessário selecionar uma conta bancária com integração ativa!', 'Atenção!');
      }
      if (titulos.length < 1) {
        this.messageService.warning('Necessário selecionar título(s) para integração!', 'Atenção!');
      }
    }
  }

}