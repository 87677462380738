import { Component, Input, ViewChild, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { BaseCadastroComponent } from '../../../componentes-base';
import { Usuario, Endereco, GrupoDeUsuario, tiposFuncoes, tiposVinculos, Telefone, tiposTelefones, Fornecedor, TIPO_USUARIO } from '../../../models';
import { AuthService, MessageService, FormsUtilsService } from '../../../core';
import { EnderecoComponent, EnderecoHttpService } from '../../../componentes-utils/endereco';
import { UsuariosHttpService } from '../services';
import { GruposDeUsuariosHttpService } from '../../grupos-de-usuarios';
import { PermissionService } from '../../../core/services/permission/permission.service';
import { CpfFormatPipe, CnpjFormatPipe } from '../../../shared/pipes';
import { CidadeHttpService } from '../../../componentes-utils/cidade';
import { FornecedoresCadastroModalComponent, FornecedoresHttpService } from 'app/home/fornecedores';

declare var jQuery: any;

@Component({
	selector: 'usuarios-cadastro',
	templateUrl: './usuarios-cadastro.component.html',
	styleUrls: ['./usuarios-cadastro.component.css'],
	providers: [
		GruposDeUsuariosHttpService,
		PermissionService,
		CpfFormatPipe,
		CnpjFormatPipe
	]
})
export class UsuariosCadastroComponent extends BaseCadastroComponent<Usuario> implements OnInit {

	@Input('isShowOptions') isShowOptions = true;
	@Input('isSimpleForm') isSimpleForm = false;
	@Input('idModal') idModal = 'usuariosCadastroModal';
	@Input('mode') mode = 'screen';
	@Input('idUnidade') idUnidade;
	@ViewChild('fornecedoresCadastroModal') fornecedoresCadastroModal: FornecedoresCadastroModalComponent;
	@ViewChild('endereco') enderecoComponent: EnderecoComponent;

	public gruposDeUsuarios: GrupoDeUsuario[];
	public idUsuario: number;
	public entity: Usuario = new Usuario();
	public tiposTelefones = tiposTelefones;
	public tiposFuncoes = tiposFuncoes;
	public tiposVinculos = tiposVinculos;
	public tipoUsuario: number;
	public pageTitle: string;
	public validEmail: boolean;
	public emailDefault: string;
	public fornecedores: Fornecedor[] = [];
	public idCondominio: number;
	public idCliente: number;
	public TIPO_USUARIO = TIPO_USUARIO;

	constructor(private usuariosHttpService: UsuariosHttpService,
		activatedRoute: ActivatedRoute,
		router: Router,
		messageService: MessageService,
		permissionService: PermissionService,
		private authService: AuthService,
		private gruposDeUsuariosHttpService: GruposDeUsuariosHttpService,
		private fornecedoresHttpService: FornecedoresHttpService,
		private cpfFormatPipe: CpfFormatPipe,
		private cnpjFormatPipe: CnpjFormatPipe,
		private formsUtilsService: FormsUtilsService,
		private cidadeHttpService: CidadeHttpService,
		private enderecoHttpService: EnderecoHttpService) {
		super(Usuario, usuariosHttpService, activatedRoute, router, messageService, permissionService);
		this.resource = this.activatedRoute.data['value']['resource'];
		this.tipoUsuario = this.resource.includes('/condominos') ? TIPO_USUARIO.CONDOMINO : TIPO_USUARIO.FUNCIONARIO;
		this.setTipoCadastro(this.tipoUsuario);
	}

	ngOnInit() {
		this.idCondominio = this.authService.getIdCurrentCondominio();
		this.idCliente = this.authService.getIdCurrentCliente();
		this.getAllFornecedores();
		this.activatedRoute.params.subscribe((params: Params) => {
			const id = +params['id'];
			if (!isNaN(id)) {
				this.editMode = true;
				this.idUsuario = id;
				this.load(id);
			} else {
				this.editMode = false;
				this.entity = new Usuario();
				this.entity.telefones = [];
				this.entity.tipo = this.tipoUsuario;
				if (this.tipoUsuario === TIPO_USUARIO.CONDOMINO && this.idCondominio) {
					this.entity.acessos_condominio.push(this.idCondominio);
					this.carregarEnderecoCondominio(this.idCondominio);
				}
				this.addTelefone();
				this.afterInsert();
			}
		});
		this.getPermissions();
		this.carregarGruposDeUsuarios();
	}

	public setTipoCadastro(tipo: number): void {
		this.pageTitle = tipo === TIPO_USUARIO.CONDOMINO ? 'Condômino' : 'Colaborador';
		this.tipoUsuario = tipo;
	}

	private getAllFornecedores() {
		this.fornecedoresHttpService.getAllByCliente(this.idCliente).subscribe(
			entities => this.fornecedores = entities,
			error => this.messageService.error('Erro ao carregar os fornecedores', 'Erro', error)
		);
	}

	public createFornecedor(): void {
		this.fornecedoresCadastroModal.open();
	}

	public onAfterSaveFornecedor(fornecedor: Fornecedor): void {
		this.entity.id_fornecedor = fornecedor.id;
		this.fornecedores.push(fornecedor);
		jQuery('#fornecedor').focus();
	}

	public onChangeCpf(event: any): void {
		this.entity.cpf = this.cpfFormatPipe.transform(event);
		if (this.entity.cpf && this.entity.cpf.length === 14)
			jQuery('#rg').focus();
	}

	public checkEmail(email: string): void {
		if (!email) return;
		this.usuariosHttpService.checkEmail(email).subscribe(
			response => this.validEmail = true,
			error => this.validEmail = false
		);
	}

	public onChangeDocumento(event: any): void {
		if (event && event.length < 15)
			this.entity.documento = this.cpfFormatPipe.transform(event);

		if (event && event.length > 14)
			this.entity.documento = this.cnpjFormatPipe.transform(event);

		if (this.entity.documento && this.entity.documento.length === 18)
			jQuery('#rg').focus();
	}

	public beforeSave(): boolean {
		this.btnSave = jQuery('#btnSave');
		this.btnSave.button('loading');
		return this.validateForm();
	}

	public load(id: number) {
		this.crudService.get(id).subscribe(
			entity => this.afterEdit(entity),
			error => this.catchErrorLoad(error)
		);
	}

	public afterEdit(entity: Usuario): void {
		this.entity = entity;
		this.emailDefault = entity.email;
		if (this.entity.telefones.length === 0)
			this.addTelefone();

		if (this.entity.tipo === TIPO_USUARIO.FUNCIONARIO && this.entity.cpf)
			this.onChangeCpf(this.entity.cpf);

		if (this.entity.tipo === TIPO_USUARIO.CONDOMINO && this.entity.documento)
			this.onChangeDocumento(this.entity.documento);

		if (!this.entity.endereco_condominio)
			this.getCidadeByName(this.entity.endereco.id_cidade);
	}

	private catchErrorLoad(error: any): void {
		if (error.status === 400)
			this.messageService.info(error._body, 'Informação');
		else
			this.messageService.error('Erro ao carregar o registro', 'Erro', error);
	}

	private getCidadeByName(idCidade: number): void {
		this.cidadeHttpService.get(idCidade).subscribe(
			cidade => this.entity.endereco.cidade = cidade
		);
	}

	private carregarGruposDeUsuarios() {
		this.gruposDeUsuarios = [];
		this.gruposDeUsuariosHttpService.getAllByCliente(this.idCliente).subscribe(
			entities => this.gruposDeUsuarios = entities
		);
	}

	public carregarEnderecoCondominio(idCondominio: number) {
		this.enderecoHttpService.getAddressByCondominio(idCondominio, this.entity.id, this.idUnidade).subscribe(
			endereco => {
				delete endereco.id;
				this.entity.endereco = endereco;
				this.entity.endereco_condominio = true;
			}),
			error => this.messageService.error('Erro ao carregar o endereço do condomínio para inserir no cadastro', 'Erro', error);
	}

	public onChangeMesmoEnderecoCondominio(): void {
		if (!this.entity.endereco_condominio) {
			this.entity.endereco = new Endereco();
		} else {
			if (this.entity.acessos_condominio && this.entity.acessos_condominio.length === 1) {
				this.carregarEnderecoCondominio(this.entity.acessos_condominio[0]);
			} else {
				this.entity.endereco_condominio = false;
				this.messageService.warning(
					'Para possuir o mesmo endereço do condomínio, o usuário só pode ter acesso a um único condomínio', 'Atenção');
			}
		}
	}

	public onChangeAcessos(acessos: number[]): void {
		if (this.entity.tipo === TIPO_USUARIO.FUNCIONARIO) {
			return;
		}
		if (this.entity.endereco_condominio && acessos.length > 0) {
			if (acessos.length > 1) {
				this.entity.endereco_condominio = false;
				this.entity.endereco = new Endereco();
				this.messageService.warning(
					'Para possuir o mesmo endereço do condomínio, o usuário só pode ter acesso a um único condomínio', 'Atenção');
			} else {
				this.carregarEnderecoCondominio(acessos[0]);
			}
		} else {
			this.entity.endereco_condominio = false;
			this.entity.id_endereco = null;
			this.entity.endereco = new Endereco();
		}
	}

	public addTelefone(): void {
		this.entity.telefones.push(new Telefone());
	}

	public removeTelefone(telefone): void {
		const index = this.entity.telefones
			.findIndex(it => it.numero === telefone.numero);
		this.entity.telefones.splice(index, 1);
	}

	private checkTelefones(): void {
		const telefones = this.entity.telefones;
		const invalidNumbers = telefones.filter(it => !it.numero);
		invalidNumbers.forEach(it => telefones.splice(telefones.indexOf(it), 1));
	}

	public save(): Promise<Usuario> {
		this.checkTelefones();
		return new Promise<Usuario>((resolve, reject) => {
			this.makeAllFieldsDirty();
			this.enderecoComponent.makeAllFieldsDirty();
			this.entity.endereco_condominio = this.isSimpleForm;
			if (this.form.valid) {
				this.entity.id_cliente = this.idCliente;
				const save = this.editMode ? this.crudService.put(this.entity) : this.crudService.post(this.entity);
				save.subscribe(
					entity => {
						this.messageService.success('', 'Condômino salvo com sucesso!');
						this.btnSave.button('reset');
						this.afterSave(entity);
						resolve(entity);
					},
					error => {
						switch (error.status) {
							case 404:
								this.messageService.warning('Condômino não encontrado!', 'Erro');
								break;
							case 403:
								this.messageService.error('O CPF informado já consta cadastrado no sistema', 'Erro');
								break;
							case 400:
							default:
								this.messageService.error('Erro ao salvar o condômino', 'Erro');
								break;
						}
						this.btnSave.button('reset');
						reject(null);
					}
				);
			} else {
				this.btnSave.button('reset');
				this.showMessageFormInvalid();
				reject(null);
			}
		});
	}

	public sendAccessData(): void {
		const btn = jQuery('#btnSendDataAcess');
		btn.button('loading');
		this.usuariosHttpService.sendAccessData(this.entity).subscribe(
			() => {
				this.messageService.success('Email com os dados de acesso enviado com sucesso', 'Sucesso');
				btn.button('reset');
			},
			error => {
				switch (error.status) {
					case 404:
						this.messageService.warning('Usuário não encontrado!', 'Erro');
						break;
					case 400:
					default:
						this.messageService.error('Erro ao  enviar dados de acesso, favor contate o administrador do sistema', 'Erro');
						break;
				}
				btn.button('reset');
			}
		);
	}

	public validateForm(): boolean {
		let isValid = true;
		if (this.entity.cpf) {
			isValid = this.validateCpf(this.entity.cpf);
			if (!isValid) {
				this.btnSave.button('reset');
				return false;
			}
		}
		if (this.entity.documento && this.entity.documento.length === 18) {
			isValid = this.validateCnpj(this.entity.documento);
			if (!isValid) {
				this.btnSave.button('reset');
				return false;
			}
		}
		if (this.entity.documento && this.entity.documento.length === 14) {
			isValid = this.validateCpf(this.entity.documento);
			if (!isValid) {
				this.btnSave.button('reset');
				return false;
			}
		}
		if (this.entity.email) {
			isValid = this.validateEmail(this.entity.email);
			if (!isValid) {
				this.btnSave.button('reset');
				return false;
			}
		}
		isValid = this.validateEndereco();
		if (!isValid) {
			this.btnSave.button('reset');
			return false;
		}

		return isValid;
	}

	private validateEmail(email: string): boolean {
		if (!email) return;
		if (this.editMode) {
			if (email !== this.emailDefault) {
				if (!this.validEmail) {
					this.messageService.info('O email informado já consta cadastrado no sistema, tente outro email', ' Informação');
					return false;
				}
			}
		} else {
			if (!this.validEmail) {
				this.messageService.info('O email informado já consta cadastrado no sistema, tente outro email', ' Informação');
				return false;
			}
		}
		return true;
	}

	private validateCpf(cpf: string): boolean {
		if (this.tipoUsuario === TIPO_USUARIO.FUNCIONARIO && !cpf) {
			this.messageService.info('O campo CPF é de preenchimento obrigatório', ' Informação');
			return false;
		}
		if (cpf) {
			if (!this.formsUtilsService.isValidCpf(cpf)) {
				this.messageService.info('O CPF informado não é um CPF válido', ' Informação');
				return false;
			}
			return true;
		}
		return false;
	}

	private validateCnpj(cnpj: string): boolean {
		if (cnpj) {
			if (!this.formsUtilsService.isValidCnpj(cnpj)) {
				this.messageService.info('O CNPJ informado não é um CNPJ válido', ' Informação');
				return false;
			}
			return true;
		}
		return false;
	}

	private validateEndereco(): boolean {
		if (!this.enderecoComponent.validate()) {
			this.messageService.info('Preencha corretamente todos os campos da aba endereço', ' Informação');
			return false;
		}
		return true;
	}

	public formatPhone(telefone, value): void {
		// Com primeiro dígito '+' significa número internacional
		if (value.substr(0, 1) != '+') {
			let newValue = '';
			const rawValue = this.clearDigitsSpecialChars(value);

			let front = 4;
			if (rawValue.length > 10)
				front = 5;
			if (rawValue.length > 0)
				newValue += '(' + rawValue.substr(0, 2);
			if (rawValue.length > 2)
				newValue += ') ' + rawValue.substr(2, front);
			if (rawValue.length >= 7)
				newValue += '-' + rawValue.substr(front + 2, 4);
			telefone.numero = newValue;
		} else {
			telefone.numero = value;
		}
	}

	private clearDigitsSpecialChars(value): string {
		if (value) {
			const anyNonDigitRegExp = /[^0-9]/g;
			return value.replace(anyNonDigitRegExp, '');
		}
		return '';
	}

	public showDadosFuncionario(): boolean {
		return this.entity.tipo === TIPO_USUARIO.FUNCIONARIO;
	}

}

