import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { TiposConsumoComponent } from './tipos-consumo.component';
import { TiposConsumoCadastroComponent } from './cadastro';
import { TiposConsumoHttpService, TiposConsumoUtilsService } from './services';
import { CategoriaTarifasConsumoCadastroModalComponent, TiposConsumoTarifasComponent, TiposConsumoTarifasHttpService } from './tarifas';
import { TiposConsumoHistoricoComponent, TiposConsumoHistoricoHttpService } from './historico';
import { PlanoDeContasModule } from '../plano-de-contas';

@NgModule({
    imports: [
        SharedModule,
        PlanoDeContasModule,
        TypeaheadModule
    ],
    declarations: [
        TiposConsumoComponent,
        TiposConsumoCadastroComponent,
        TiposConsumoTarifasComponent,
        TiposConsumoHistoricoComponent,
        CategoriaTarifasConsumoCadastroModalComponent
    ],
    providers: [
        TiposConsumoHttpService,
        TiposConsumoUtilsService,
        TiposConsumoTarifasHttpService,
        TiposConsumoHistoricoHttpService
    ]
})
export class TiposConsumoModule { }
