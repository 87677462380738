import { BaseEntity } from '../base-entity';

export class Filtro extends BaseEntity {
	label: string;
	nome: string;
	tipo: string;
	fonte_dados: string;
	descricao: string;

	checado: boolean;
	content: string;
	valor: any;
	dados: any;
	node: any;

	constructor() {
		super();
		this.tipo = '';
		this.node = null;
	}
}

export const tiposRelatorioFiltro = [
	{ codigo: 'SELECAO', nome: 'Campo de seleção (combobox)' },
	{ codigo: 'MULTISELECAO', nome: 'Campo de multi seleção (combobox)' },
	{ codigo: 'ARVORE', nome: 'Campo de seleção em árvore (combobox)' },
	{ codigo: 'NUMERICO', nome: 'Campo numérico' },
	{ codigo: 'INTEIRO', nome: 'Campo inteiro' },
	{ codigo: 'TEXTO', nome: 'Campo de Texto' },
	{ codigo: 'CHECKBOX', nome: 'Campo Checkbox' },
];