import { Injectable } from '@angular/core';
import { Livro } from 'app/models';
import { Crud, CustomHttp, CrudService, ConfigService, AuthService } from 'app/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';

@Injectable({
  providedIn: 'root'
})
export class LivroHttpService implements Crud<Livro> {

  public resource = 'livro';
  private baseUrl: string;

  constructor(private customHttp: CustomHttp,
      private crudService: CrudService,
      private configService: ConfigService,
      private authService: AuthService) {
    this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
  }
  
  public get(id: number): Observable<Livro> {
    return this.crudService.get<Livro>(this.resource, id);
  }

  public getAll(): Observable<Livro[]> {
    return this.crudService.getAll<Livro>(this.resource, {params:{id_condominio: this.authService.getIdCurrentCondominio()}});
  }

  public post(entity: Livro): Observable<Livro> {
    return this.crudService.post<Livro>(this.resource, entity);
  }

  public put(entity: Livro): Observable<Livro> {
    return this.crudService.put<Livro>(this.resource, entity);
  }

  public delete(entity: Livro): Observable<string> {
    return this.crudService.delete<Livro>(this.resource, entity);
  }

  /**
   * Validar geração do livro
   * @param livro 
   * @author Marcos Frare
   * @since 27/07/2020
   */
  public validar(livro: Livro): Observable<any> {
    return this.customHttp.post(`${this.baseUrl}/validar`, livro)
      .pipe(
        map(response => response.json()),
        catchError(error => _throw(error))
      );
  }

  /**
   * Processa e gera o livro de prestação de contas
   * @param livro 
   * @author Marcos Frare
   * @since 23/07/2020
   */
  public processaLivro(livro: Livro): Observable<Livro> {
    return this.customHttp.post(`${this.baseUrl}/processar`, livro)
      .pipe(
        map(response => response.json()),
        catchError(error => _throw(error))
      );
  }
 
}