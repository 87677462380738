import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared';
import { TipoContatoCadastroModalComponent } from './tipo-contato-cadastro-modal';
import { TipoContatoComponent } from './tipo-contato.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    TipoContatoComponent,
    TipoContatoCadastroModalComponent
  ]
})
export class TipoContatoModule { }