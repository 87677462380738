import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';

import { Crud, CrudService, ConfigService, CustomHttp } from 'app/core';

import { PlanoDeContas } from 'app/models';

@Injectable()
export class PlanoDeContasHttpService implements Crud<PlanoDeContas> {
	resource = 'planodecontas';
	configuracaoResource = 'configuracaocondominio';
	baseUrl: string;
	configuracaoUrl: string;

	constructor(
		private customHttp: CustomHttp,
		private crudService: CrudService,
		private configService: ConfigService) {
		this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
		this.configuracaoUrl = `${configService.getBaseUrl()}/${this.configuracaoResource}`;
	}

	get(id: number): Observable<PlanoDeContas> {
		return this.crudService.get<PlanoDeContas>(this.resource, id);
	}

	getAll(): Observable<PlanoDeContas[]> {
		return this.crudService.getAll<PlanoDeContas>(this.resource);
	}

	getAllByCliente(idCliente: number): Observable<PlanoDeContas[]> {
		return this.customHttp.get(`${this.baseUrl}/consultar/cliente/${idCliente}`)
			.pipe(
				map(response => response.json()),
				catchError(error => _throw(error))
			);
	}

	getAllByCondominio(idCondominio: number): Observable<PlanoDeContas[]> {
		return this.customHttp.get(`${this.baseUrl}/consultar/condominio/${idCondominio}`)
			.pipe(
				map(response => response.json()),
				catchError(error => _throw(error))
			);
	}

	post(entity: PlanoDeContas): Observable<PlanoDeContas> {
		return this.crudService.post<PlanoDeContas>(this.resource, entity);
	}

	put(entity: PlanoDeContas): Observable<PlanoDeContas> {
		return this.crudService.put<PlanoDeContas>(this.resource, entity);
	}

	delete(entity: PlanoDeContas): Observable<string> {
		return this.crudService.delete<PlanoDeContas>(this.resource, entity);
	}

	getIdPlanoDeContaPadraoByCondominio(idCondominio: number): Observable<number> {
		return this.customHttp.get(`${this.configuracaoUrl}/consultar/condominio/${idCondominio}`)
			.pipe(
				map(response => response.json().id_plano_de_contas),
				catchError(error => _throw(error))
			);
	}
}
