import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseVisualizacaoComponent } from '../../componentes-base';
import { AuthService, MessageService } from '../../core';
import { TipoConsumo, abrangenciaTiposConsumo } from 'app/models';
import { TiposConsumoHttpService } from './services';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { isNull } from 'util';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'tipos-consumo',
    templateUrl: './tipos-consumo.component.html',
    styleUrls: ['./tipos-consumo.component.css'],
    providers: [PermissionService]
})
export class TiposConsumoComponent extends BaseVisualizacaoComponent<TipoConsumo> {

    public abrangenciaTiposConsumo = abrangenciaTiposConsumo;
    public tabelaGlobal: boolean;
    public mostraOpcaoGlobal: boolean;

    constructor(activatedRoute: ActivatedRoute,
                router: Router,
                messageService: MessageService,
                permissionService: PermissionService,
                private authService: AuthService,
                private tiposConsumoHttpService: TiposConsumoHttpService,
                public spinner: NgxSpinnerService) {
        super('#tableTiposConsumo', tiposConsumoHttpService, activatedRoute, router, messageService, permissionService, spinner);
    }

    public getAll(): void {
        this.mostraOpcaoGlobal = !isNull(this.authService.getIdCurrentCondominio());
        this.spinner.show();
        this.entities = [];
        this.destroyTable();
        this.tiposConsumoHttpService.getAllTipos(this.tabelaGlobal).subscribe(
            entities => {
                this.spinner.hide();
                this.entities = entities;
                this.showTable();
            },
            error => {
                this.spinner.hide();
                this.messageService.error('Erro carregar os tipos de consumo do condomínio. Tente novamente', 'Erro', error);
            }
        );
    }

    public delete(tipoConsumo: TipoConsumo): void {
        if (confirm(`Tem certeza que deseja excluir o tabela de serviço ${tipoConsumo.nome}?`)) {
            this.spinner.show();
            this.tiposConsumoHttpService.delete(tipoConsumo).subscribe(
                () => {
                    this.spinner.hide();
                    this.messageService.success('Tabela de serviço excluído com sucesso', 'Sucesso');
                    this.getAll();
                },
                error => {
                    this.spinner.hide();
                    switch (error.status) {
                        case 403:
                            this.messageService.warning(
                            `Não foi possível excluir a tabela de serviço ${tipoConsumo.nome}, o mesmo já possui lançamentos no financeiro`,
                                'Comunicacao');
                            break;
                        case 404:
                            this.messageService.warning(
                                `Não foi possível localizar a tabela de serviço ${tipoConsumo.nome}, favor tente novamente mais tarde`,
                                'Comunicacao');
                            break;
                        default:
                            this.messageService.error(
                                `Erro ao excluir a tabela de serviço ${tipoConsumo.nome}, favor contate o administrador do sistema`,
                                'Erro', error);
                    }
                }
            );
        }
    }

    public loadTabela() {
        this.getAll();
    }

}