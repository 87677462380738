import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { MessageService } from 'app/core';
import { TipoContato } from 'app/models';
import { TipoContatoHttpService } from '../service';

declare var jQuery: any;

@Component({
  selector: 'tipo-contato-cadastro-modal',
  templateUrl: './tipo-contato-cadastro-modal.component.html',
  styleUrls: ['./tipo-contato-cadastro-modal.component.css']
})
export class TipoContatoCadastroModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @Input() id: number;
  @Output() onSave: EventEmitter<TipoContato> = new EventEmitter();

  public entity: TipoContato;

  constructor(private tipoContatoHttpService: TipoContatoHttpService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.entity = new TipoContato();
    if (!this.idModal) {
      this.idModal = 'tipoContatoCadastroModal';
    }
  }

  public open() {
    this.entity = new TipoContato();
    if (this.id) {
      this.tipoContatoHttpService.get(this.id).subscribe(
        response => {
          this.entity = response;
        },
        error => {
          this.messageService.error('Erro ao buscar dados do tipo! Tente novamente', 'Erro', error);
        }
      );
    }
    jQuery(`#${this.idModal}`).modal('show');
  }
  public close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Salvar tipo de contato
   * @author Marcos Frare
   * @since 19/04/2021
   */
  public salvar(): void {
    let btn = jQuery('#btnSaveContato');
    btn.button('loading');
    const fn = this.entity.id ? this.tipoContatoHttpService.put(this.entity) : this.tipoContatoHttpService.post(this.entity);
    fn.subscribe(
      response => {
        btn.button('reset');
        this.messageService.success('Tipo salvo com sucesso!');
        this.onSave.emit(response);
        this.close();
      },
      error => {
        btn.button('reset');
        this.messageService.error('Erro ao salvar tipo! Tente novamente', 'Erro', error);
      }
    );
  }

}