import { BaseEntity } from '../base-entity';
import { CategoriaContas } from './categoria-contas';

export class PlanoDeContas extends BaseEntity {
	nome: string;
	categoria_contas: CategoriaContas[];
	id_cliente: number;
	id_condominio: number;
	constructor() {
		super();
		this.categoria_contas = [];
	}
}