import { BaseEntity } from "../base-entity";
import { Inventario } from "./inventario";

export class LocalUtensilio extends BaseEntity {
	id_local: number;
	id_inventario: number;
	tipo: number;
	valor_reserva: any;
	inventario: Inventario;

	selected: boolean;

	constructor() {
		super();
		this.valor_reserva = 0;
		this.inventario = new Inventario();
		this.selected = false;
	}
}

export const tipo_local_utensilio = [
	{ id: 1, nome: 'Incluso' },
	{ id: 2, nome: 'Opcional' }
]