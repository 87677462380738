import { BaseEntity } from '../base-entity';
import { Bloco } from './bloco';
import { Condomino } from './condomino';
import { Unidade } from './unidade';

export class UnidadeAntecipacao extends BaseEntity {
	id_unidade: number;
	id_condomino: number;
	ativo: boolean;

	id_bloco: number;
	nome_bloco: string;
	nome_unidade: string;
	nome_condomino: string;
	documento: string;
	bloco: Bloco;
	unidade: Unidade;
	condomino: Condomino;
	
	constructor() {
		super();
		this.id_unidade = null;
		this.ativo = true;
		this.condomino = new Condomino();
	}
}