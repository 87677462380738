import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { AuthService, MessageService } from '../../../core';
import { BaseModal } from '../../../componentes-utils/modal';
import { BoletosHttpService } from '../services/boletos-http.service';
import { BaixaTitulo, tiposBaixaTitulo } from 'app/models/entidades/baixa-titulo';
import { ContaBancaria } from 'app/models';
import { ContasBancariasHttpService } from 'app/home/contas-bancarias';

declare var jQuery: any;

@Component({
	selector: 'baixa-modal',
	templateUrl: './baixa-modal.component.html',
	styleUrls: ['./baixa-modal.component.css']
})
export class BaixaModalComponent implements OnInit, BaseModal {

	public tiposBaixaTitulo = tiposBaixaTitulo;
	public situacoesBoleto = [{ id: 5, nome: 'Baixa solicitada' }, { id: 6, nome: 'Baixa confirmada' }, { id: 7, nome: 'Cobrança extra/judicial' }];
	public arContaBancaria: ContaBancaria[] = [];

	@Input() idModal: string;
	@Input() baixaTitulo: BaixaTitulo;
	@Output() onBaixa: EventEmitter<any> = new EventEmitter();

	constructor(private boletosHttpService: BoletosHttpService,
		private authService: AuthService,
		private messageService: MessageService,
		private contaBancariaHttpService: ContasBancariasHttpService) { }

	ngOnInit() {
		if (!this.idModal) { this.idModal = 'baixaModal' };
		this.getContasBancarias();
	}

	/**
	 * Busca as contas bancárias do condomínio
	 * @author Marcos Frare
	 * @since 14/06/2021
	 */
	private getContasBancarias(): void {
		this.arContaBancaria = [];
		this.contaBancariaHttpService.getAllByCondominio(this.authService.getIdCurrentCondominio()).subscribe(
			response => {
				this.arContaBancaria = response;
			}
		);
	}

	public open(): void {
		// Carregar somente títulos não liquidados
		this.baixaTitulo.arTitulo = this.baixaTitulo.arTitulo.filter(tt => [1,3,7,8].includes(tt.situacao));
		if (!this.baixaTitulo.arTitulo.length) {
			this.messageService.warning('Título(s) selecionado(s) não podem ser baixados!', 'Atenção!');
		} else {
			if (!this.baixaTitulo.situacao) {
				this.baixaTitulo.situacao = 5;
			}
			jQuery(`#${this.idModal}`).modal('show');
		}
	}

	public close(): void {
		jQuery(`#${this.idModal}`).modal('hide');
	}

	/**
	 * Solicitar baixa do título
	 * @author Marcos Frare
	 */
	public onBaixaClick(): void {
		let btnBaixa = jQuery('#btnBaixaTitulo');
		btnBaixa.button('loading');
		this.boletosHttpService.pedirBaixa(this.baixaTitulo).subscribe(
			() => {
				this.onBaixa.emit();
				this.messageService.success('Baixa solicitada com sucesso.', 'Sucesso');
				btnBaixa.button('reset');
				this.close();
			},
			error => {
				btnBaixa.button('reset');
				this.messageService.error('Erro ao solicitar baixa, favor contate o administrador do sistema', 'Erro', error);
			}
		);
	}
}