import { map, catchError } from 'rxjs/operators';

import { Observable ,  forkJoin } from 'rxjs';
import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { MessageService, FormsUtilsService } from '../../../../../core';

import { Garagem, andaresGaragem } from '../../../../../models';
import { UnidadesGaragensHttpService } from '../services';
import { FloatFormatPipe } from '../../../../../shared/pipes';

declare var jQuery: any;

@Component({
    selector: 'unidades-garagens-modal',
    templateUrl: './unidades-garagens-modal.component.html',
    styleUrls: ['./unidades-garagens-modal.component.css'],
    providers: [
        UnidadesGaragensHttpService,
        FloatFormatPipe
    ]
})
export class UnidadesGaragensModalComponent {
    @ViewChild('formUnidadesGaragensModal') form: NgForm;

    idUnidade: number;
    nomeUnidade: string;
    entities: Garagem[];
    andares = andaresGaragem;

    constructor(private messageService: MessageService,
                private formsUtilsService: FormsUtilsService,
                private unidadesGaragensHttpService: UnidadesGaragensHttpService,
                private floatFormatPipe: FloatFormatPipe) { }


    open(idUnidade: number, nomeUnidade: string) {
        this.idUnidade = idUnidade;
        this.nomeUnidade = nomeUnidade;
        this.unidadesGaragensHttpService.getAllByUnidade(idUnidade)
            .subscribe(
                entities => {
                    this.entities = entities;
                    jQuery('#unidadesGaragensModal').modal('show');
                },
                error => this.messageService.error('Erro ao carregar as garagens da unidade. Tente novamente', 'Erro', error)
            );
    }

    close() {
        jQuery(`#unidadesGaragensModal`).modal('hide');
    }


    /** Adiciona uma nova garagem */
    insert() {
        const entity = new Garagem();
        entity.id_unidade = this.idUnidade;
        this.entities.push(entity);
    }

    /** Alterar alguma informação da garagem */
    onChange(entity: Garagem) {
        entity.editado = true;
    }

    /** Cria os observables para salvar as garagens alteradas simultaneamente **/
    private createSave(): Observable<Garagem>[] {
        const list: Observable<Garagem>[] = [];
        for (const entity of this.entities) {
            if (entity.editado) {
                let observable: Observable<Garagem>;
                if (entity.id) { // PUT
                    observable = this.unidadesGaragensHttpService.put(entity)
                                    .pipe(
                                        map(response => response),
                                        catchError(error => {
                                            this.messageService.error(`Erro ao salvar a garagem ${entity.vaga}. Tente novamente`, 'Erro', error);
                                            return Observable.throw(error);
                                        })
                                    );
                } else { // POST
                    observable = this.unidadesGaragensHttpService.post(entity)
                                    .pipe(
                                        map(response => {
                                            entity.id = response.id;
                                            return response;
                                        }),
                                        catchError(error => {
                                            this.messageService.error(`Erro ao salvar a garagem ${entity.vaga}. Tente novamente`, 'Erro', error);
                                            return Observable.throw(error);
                                        })
                                    );
                }
                list.push(observable);
            }
        }
        return list;
    }

    /** Salva as garagens */
    save() {
        const saves = this.createSave();
        if (saves.length > 0) {
            this.formsUtilsService.makeAllFieldsDirty(this.form);

            if (this.form.valid) {
                const btnSave = jQuery('#btnSaveUnidadesGaragensModal');
                btnSave.button('loading');
                forkJoin(saves)
                        .subscribe(
                            () => {
                                btnSave.button('reset');
                                this.messageService.success('', 'Garagens salvas com sucesso');
                                this.close();
                            },
                            error => {
                                btnSave.button('reset');
                            }
                        );
            } else {
                this.formsUtilsService.showMsgFormInvalid();
            }
        } else {
            this.messageService.info('É necessário alterar alguma garagem para salvar', 'Informação');
        }
    }

    /** Deleta a garagem informada */
    delete(entity: Garagem) {
        const vaga = entity.vaga ? ' ' + entity.vaga.toString() : '';
        if (confirm(`Tem certeza que deseja excluir a garagem${vaga}?`)) {
            if (entity.id) { // Com id deleta do banco de dados
                this.unidadesGaragensHttpService.delete(entity)
                    .subscribe(
                        () => {
                            const index = this.entities.findIndex(nota => nota.id === entity.id);
                            this.deleteOfList(index);
                            this.messageService.success('', `A garagem${vaga} foi excluída com sucesso`);
                        },
                        error => this.messageService.error(`Erro ao exlcuir a garagem${vaga}. Tente novamente`, 'Erro', error)
                    );
            } else { // Sem id deleta em memória
                const index = this.entities.indexOf(entity);
                if (this.deleteOfList(index)) {
                    this.messageService.success('', `A garagem${vaga} foi excluída com sucesso`);
                }
            }
        }
    }

    /** Deleta a garagem do array de garagens em memória */
    private deleteOfList(index: number): boolean {
        if (index > -1) {
            this.entities.splice(index, 1);
            return true;
        }
        return false;
    }

}
