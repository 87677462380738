import { Injectable } from '@angular/core';

import { WebStorage } from './web-storage';

@Injectable()
export class SessionStorageService implements WebStorage {
    private sessionStorage = window.sessionStorage;

    set(key: string, value: string) {
        this.sessionStorage.setItem(key, value);
    }

    get(key: string, defaultValue = null): string {
        return this.sessionStorage.getItem(key) || defaultValue;
    }

    setObject(key: string, value: Object) {
        this.sessionStorage.setItem(key, JSON.stringify(value));
    }

    getObject(key: string): Object {
        return JSON.parse(this.sessionStorage.getItem(key));
    }

    remove(key: string) {
        this.sessionStorage.removeItem(key);
    }
}
