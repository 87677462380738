import { map, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';

import { Crud, CrudService, AuthService, ConfigService, CustomHttp } from '../../../../core';
import { BlocoNovoAndar, Unidade, UnidadeAntecipacao, UnidadeBulk, UnidadeIsencao, } from '../../../../models';

@Injectable()
export class UnidadesHttpService implements Crud<Unidade> {
	resource = 'unidade';
	private baseUrl: string;

	constructor(private crudService: CrudService,
		private customHttp: CustomHttp,
		private authService: AuthService,
		private configService: ConfigService) {
		this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
	}

	get(id: number): Observable<Unidade> {
		return this.crudService.get<Unidade>(this.resource, id);
	}

	getAll(): Observable<Unidade[]> {
		return this.crudService.getAll<Unidade>(this.resource);
	}

	post(entity: Unidade): Observable<Unidade> {
		return this.crudService.post<Unidade>(this.resource, entity);
	}

	postBulk(entity: UnidadeBulk): Observable<UnidadeBulk> {
		return this.crudService.post<UnidadeBulk>('unidadebulk', entity);
	}

	put(entity: Unidade): Observable<Unidade> {
		return this.crudService.put<Unidade>(this.resource, entity);
	}

	delete(entity: Unidade): Observable<string> {
		return this.crudService.delete<Unidade>(this.resource, entity);
	}

	public getAllByTipo(idCondominio: number, tipoUnidade: number): Observable<Unidade[]> {
		return this.customHttp.get(`${this.baseUrl}/consultar/condominio/${idCondominio}/tipo/${tipoUnidade}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Carrega todas as unidade do bloco informado
	 */
	public getAllByBloco(idBloco: number): Observable<Unidade[]> {
		return this.customHttp.get(`${this.baseUrl}/consultar/bloco/${idBloco}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Cria um novo andar para um bloco
	 */
	public criarNovoAndar(entity: BlocoNovoAndar): Observable<any> {
		entity.id_usuario = this.authService.getCurrentUser().id;
		return this.customHttp.post(`${this.baseUrl}/cadastrar/andar`, entity).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Delete o andar do bloco
	 */
	public deleteAndar(idBloco: number, numeroAndar: number): Observable<string> {
		return this.customHttp.delete(`${this.baseUrl}/excluir/bloco/${idBloco}/andar/${numeroAndar}`, {body: {id_usuario: this.authService.getIdCurrentUser()}}).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public getAllTipoByCondominio(): Observable<any[]> {
		const idCondominio = this.authService.getIdCurrentCondominio();
		return this.customHttp.get(`${this.baseUrl}/tipos/condominio/${idCondominio}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public getTotalFracaoSoloByCondominio(id_condominio: number, id_bloco: number): Observable<number> {
		return this.customHttp.get(`${this.baseUrl}/consultar/fracaosolo/total`, { params: { id_condominio, id_bloco } }).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public getFracaoSoloByCondominio(idCondominio: number): Observable<number> {
		return this.customHttp.get(`${this.configService.getBaseUrl()}/configuracaocondominio/consultar/condominio/${idCondominio}`).pipe(
			map(response => response.json().fracao_ideal),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Busca as unidades de um condomínio de um usuário
	 * @param idCondominio 
	 * @param idUsuario 
	 * @author Marcos Frare
	 * @since 24/03/2020
	 */
	public getUnidadesByUsuario(id_condominio: number, id_usuario: number): Observable<Unidade[]> {
		return this.customHttp.get(`${this.baseUrl}/usuario/consultar/${id_usuario}`, { params: { id_condominio } }).pipe(
			map(response => Array.isArray(response.json()) ? response.json() : [response.json()]),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Buscar unidades por condomínio e bloco(opcional)
	 * @param id_condominio ID do condomínio
	 * @param id_bloco ID do bloco
	 * @author Marcos Frare
	 * @since 22/04/2020
	 */
	public getAllByCondominioBloco(id_condominio: number, id_bloco: number): Observable<Unidade[]> {
		return this.customHttp.get(`${this.baseUrl}/consultarTodos/condominio/${id_condominio}`, { params: { id_bloco } }).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	// ========================================================================================================================
	// Isenções da unidade
	// ========================================================================================================================
	/**
	 * Unidades configuradas de um condomínio
	 * @author Marcos Frare
	 * @since 06/08/2021
	 */
	public getUnidadesIsencaoCondominio(): Observable<UnidadeIsencao[]> {
		return this.customHttp.get(`${this.baseUrl}/isencao/condominio/${this.authService.getIdCurrentCondominio()}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Busca registro de isenção
	 * @param id 
	 * @author Marcos Frare
	 * @since 09/08/2021
	 */
	public getUnidadeIsencao(id: number): Observable<UnidadeIsencao> {
		return this.customHttp.get(`${this.baseUrl}/isencao/${id}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Salvar isenção de uma unidade
	 * @param unidadeIsencao 
	 * @author Marcos Frare
	 * @since 09/08/2021
	 */
	public saveUnidadeIsencao(unidadeIsencao: UnidadeIsencao): Observable<UnidadeIsencao> {
		return this.customHttp.post(`${this.baseUrl}/isencao`, unidadeIsencao).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Excluir isenção de unidade
	 * @param id 
	 * @author Marcos Frare
	 * @since 09/08/2021
	 */
	public excluirIsencao(id: number) {
		return this.customHttp.delete(`${this.baseUrl}/isencao/${id}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	// ========================================================================================================================
	// Unidade com antecipações
	// ========================================================================================================================
	/**
	 * Unidades com antecipações no condomínio
	 * @author Marcos Frare
	 * @since 18/08/2021
	 */
	public getUnidadesAntecipacaoCondominio(): Observable<UnidadeAntecipacao[]> {
		return this.customHttp.get(`${this.baseUrl}/antecipacao/condominio/${this.authService.getIdCurrentCondominio()}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Busca registro de isenção
	 * @param id 
	 * @author Marcos Frare
	 * @since 18/08/2021
	 */
	public getUnidadeAntecipacao(id: number): Observable<UnidadeAntecipacao> {
		return this.customHttp.get(`${this.baseUrl}/antecipacao/${id}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Salvar isenção de uma unidade
	 * @param unidadeAntecipacao 
	 * @author Marcos Frare
	 * @since 18/08/2021
	 */
	public saveUnidadeAntecipacao(unidadeAntecipacao: UnidadeAntecipacao): Observable<UnidadeAntecipacao> {
		return this.customHttp.post(`${this.baseUrl}/antecipacao`, unidadeAntecipacao).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Excluir isenção de unidade
	 * @param id 
	 * @author Marcos Frare
	 * @since 18/08/2021
	 */
	public excluirUnidadeAntecipacao(id: number) {
		return this.customHttp.delete(`${this.baseUrl}/antecipacao/${id}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

}