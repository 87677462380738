import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { _throw } from 'rxjs/observable/throw';
import { CustomHttp, ConfigService } from '../../../../core';
import { Observable } from 'rxjs';

@Injectable()
export class AcessosHttpService {
	resource = 'acessos';
	baseUrl: string;
	baseUrlAlt: string;

	constructor(private customHttp: CustomHttp,
		public configService: ConfigService) {
		this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
		this.baseUrlAlt = `${configService.getBaseUrl()}/gruposdeusuario`;
	}

	public getAllByCliente(idCliente: number): Observable<any> {
		return this.customHttp.get(`${this.baseUrlAlt}/consultar/cliente/${idCliente}`)
			.pipe(
				map(response => response.json()),
				catchError(error => _throw(error))
			);
	}
}
