import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared';
import { LocalComponent } from './local.component';
import { LocalHttpService } from './services';

import { EnderecoModule } from '../../componentes-utils/endereco';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { UiSwitchModule } from 'ngx-ui-switch';
import { LocalCadastroComponent } from './local-cadastro/local-cadastro.component';
import { PlanoDeContasModule } from '../plano-de-contas';
import { LocalConfiguracaoModalComponent } from './local-cadastro/local-configuracao-modal/local-configuracao-modal.component';
import { LocalUtensilioModalComponent } from './local-cadastro/local-utensilio-modal/local-utensilio-modal.component';
import { LocalPoliticaModalComponent } from './local-cadastro/local-politica-modal/local-politica-modal.component';
import { NgxEditorModule } from 'ngx-editor';

@NgModule({
	imports: [
		SharedModule,
		EnderecoModule,
		TypeaheadModule,
		UiSwitchModule,
		PlanoDeContasModule,
		NgxEditorModule
	],
	declarations: [
		LocalComponent,
		LocalCadastroComponent,
		LocalConfiguracaoModalComponent,
		LocalUtensilioModalComponent,
		LocalPoliticaModalComponent
	],
	providers: [
		LocalHttpService
	],
	exports: [
		LocalComponent
	]
})

export class LocalModule { }
