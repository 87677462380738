import { NgModule } from '@angular/core';

import { SharedModule } from 'app/shared';

import { PlanoDeContasComponent } from './plano-de-contas.component';
import { CategoriaContasComponent, CategoriaContasHttpService, UtilsCategoriaContasService, CategoriaContasPesquisaModalComponent, CategoriaContasPesquisaCompletaModalComponent, CategoriaContaGrupoComponent, CategoriaContaGrupoCadastroModalComponent } from './categoria-contas';
import { UiSwitchModule } from 'ngx-ui-switch';
import { PlanoDeContasHttpService } from './services';
import { EasyUIModule } from 'ng-easyui';
import { PlanoDeContasCadastroModalComponent } from './cadastro-modal';
import { PlanoDeContasCadastroComponent } from './cadastro';

@NgModule({
	imports: [
		SharedModule,
		UiSwitchModule,
		EasyUIModule
	],
	declarations: [
		PlanoDeContasComponent,
		PlanoDeContasCadastroComponent,
		CategoriaContasComponent,
		CategoriaContasPesquisaModalComponent,
		PlanoDeContasCadastroModalComponent,
		CategoriaContasPesquisaCompletaModalComponent,
		CategoriaContaGrupoComponent,
		CategoriaContaGrupoCadastroModalComponent
	],
	exports: [
		CategoriaContasPesquisaModalComponent,
		CategoriaContasPesquisaCompletaModalComponent,
		PlanoDeContasCadastroModalComponent
	],
	providers: [
		PlanoDeContasHttpService,
		UtilsCategoriaContasService,
		CategoriaContasHttpService,
	]
})
export class PlanoDeContasModule { }