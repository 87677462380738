import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared';
import { FornecedoresModule } from '../fornecedores';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ComunicacoesComponent } from './comunicacoes.component';
import { ComunicacoesCadastroComponent } from './cadastro';
import { ComunicacoesHttpService } from './services';
import { BlocosModule } from '../blocos';
import { NgxEditorModule } from 'ngx-editor';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { EnvioEmailLogModalModule } from 'app/componentes-utils/envio-email-log-modal';

@NgModule({
	imports: [
		SharedModule,
		FornecedoresModule,
		BlocosModule,
		TypeaheadModule,
		MultiselectDropdownModule,
		NgxEditorModule,
		EnvioEmailLogModalModule,
	],
	declarations: [
		ComunicacoesComponent,
		ComunicacoesCadastroComponent,
	],
	providers: [
		ComunicacoesHttpService
	]
})
export class ComunicacoesModule { }