import { BaseEntity } from "../base-entity";
import { Unidade, Local, Condomino } from 'app/models';
import { LocalDisponibilidade } from "./local-disponibilidade";
import { Bloco } from "./bloco";

export class Reserva extends BaseEntity {
	id_local: number;
	id_unidade: number;
	id_condomino: number;
	data_reserva: Date;
	hora_inicial: Date;
	hora_final: Date;
	valor_reserva: number;
	id_local_disponibilidade: number;
	tipo: string;
	motivo_isencao: string;

	validacao: boolean;
	msg: string;

	nome_local: string;
	nome_unidade: string;
	id_usuario: number;
	nome: string;
	local: Local;
	unidade: Unidade;
	bloco: Bloco;
	condomino: Condomino;
	localDisponibilidade: LocalDisponibilidade;

	id_condominio: number;
	id_bloco: number;
	remetente: string;
	disponibilidade: any;

	success: boolean;

	constructor() {
		super();
		this.valor_reserva = 0;
		this.validacao = true;
		this.tipo = 'NORMAL';
		this.motivo_isencao = '';
		this.success = true;
	}
}