import { BaseEntity } from '../base-entity';
import { DocumentoFiscal } from './documento-fiscal';
import { TemplateLancamentoCondominioItem } from './template-lancamento-condominio-item';

export class TemplateLancamentoCondominio extends BaseEntity {
	descricao: string;
	id_condominio: number;
	id_fornecedor: number;
	tipo_despesa: string;
	dia_vencimento: number;
	valor: number;
	tolerancia_inferior: number;
	tolerancia_superior: number;
	observacao: string;
	utilidade: string;
	templateLancamentoCondominioItems: TemplateLancamentoCondominioItem[];
	items: TemplateLancamentoCondominioItem[];

	documentoFiscal: DocumentoFiscal;

	minimo: number;
	maximo: number;
	valor_original: number;

	id_tipo_documento_fiscal: number;
	numero_documento: string;
	data_documento: Date;
	open: boolean;

	constructor() {
		super();
		this.valor = 0;
		this.utilidade = 'TODOS';
		this.documentoFiscal = new DocumentoFiscal();
		this.tipo_despesa = 'ORDINARIA';
		this.templateLancamentoCondominioItems = [];
		this.open = false;
	}
}

export const templateUtilidades = [
	{ value: 'FIXO', nome: 'Lançamentos Fixos' },
	{ value: 'CONCILIACAO', nome: 'Conciliação Bancária' },
	{ value: 'TODOS', nome: 'Todos' },
];