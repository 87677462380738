import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseVisualizacaoComponent } from 'app/componentes-base';
import { AuthService, MessageService } from 'app/core';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { TipoContato } from 'app/models';
import { NgxSpinnerService } from 'ngx-spinner';
import { TipoContatoHttpService } from './service';
import { TipoContatoCadastroModalComponent } from './tipo-contato-cadastro-modal';

@Component({
  selector: 'tipo-contato',
  templateUrl: './tipo-contato.component.html',
  styleUrls: ['./tipo-contato.component.css']
})
export class TipoContatoComponent extends BaseVisualizacaoComponent<TipoContato> {

  @ViewChild('tipoContatoCadastroModal') tipoContatoCadastroModal: TipoContatoCadastroModalComponent;

  constructor(public activatedRoute: ActivatedRoute,
      public router: Router,
      public messageService: MessageService,
      private authService: AuthService,
      private tipoContatoHttpService: TipoContatoHttpService,
      public permissionService: PermissionService,
      public spinner: NgxSpinnerService) {
    super('#tableTipoContato', tipoContatoHttpService, activatedRoute, router, messageService, permissionService, spinner);
  }

  ngOnInit() {
    if(!this.authService.isAdmin) {
      this.router.navigate(['/dashboard'], {relativeTo: this.activatedRoute});
    }
    this.search();
  }

  /**
   * Excluir um tipo
   * @param entity Objeto a ser excluído
   * @author Marcos Frare
   * @since 10/04/2021
   */
   public delete(entity: TipoContato): void {
    if (confirm(`Deseja excluir o fabricante ${entity.nome}?`)) {
      this.tipoContatoHttpService.delete(entity).subscribe(
        () => {
          this.search();
          this.messageService.success('Fabricante excluído com sucesso!', 'Sucesso!');
        },
        error => {
          this.messageService.error(`Erro ao excluir fabricante ${entity.nome}!`, 'Erro', error);
        }
      );
    }
  }

  /**
   * Busca dados
   * @author Marcos Frare
   * @since 10/04/2021
   */
  public search(): void {
    super.getAll();
  }

  /**
   * Editar registro
   * @param entity 
   * @author Marcos Frare
   * @since 10/04/2021
   */
  public edit(entity: TipoContato): void {
    this.tipoContatoCadastroModal.id = entity.id;
    this.tipoContatoCadastroModal.open();
  }

  /**
   * Incluir novo fabricante
   * @author Marcos Frare
   * @since 10/04/2021
   */
  public insert(): void {
    this.tipoContatoCadastroModal.id = null;
    this.tipoContatoCadastroModal.open();
  }

}