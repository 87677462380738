import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';

import { Crud, CrudService, ConfigService, CustomHttp } from '../../../core';
import { DemonstrativoRateio } from '../../../models';

@Injectable()
export class ArquivosRemessaHttpService implements Crud<DemonstrativoRateio> {
    
    public resource = 'demonstrativorateio';
    private arquivoResource: string = 'arquivoremessa/download';
    private baseUrl: string;
    private url: string;
    private arquivoUrl: string;

    constructor(private customHttp: CustomHttp,
                private crudService: CrudService,
                private configService: ConfigService) {
        this.url = configService.getBaseUrl();
        this.baseUrl = `${this.url}/${this.resource}`;
        this.arquivoUrl = `${configService.getBaseUrl()}/${this.arquivoResource}`;
    }

    get(id: number): Observable<DemonstrativoRateio> {
        return this.crudService.get<DemonstrativoRateio>(this.resource, id);
    }

    getAll(): Observable<DemonstrativoRateio[]> {
        return this.crudService.getAll<DemonstrativoRateio>(this.resource);
    }

    post(entity: DemonstrativoRateio): Observable<DemonstrativoRateio> {
        return this.crudService.post<DemonstrativoRateio>(this.resource, entity);
    }

    put(entity: DemonstrativoRateio): Observable<DemonstrativoRateio> {
        return this.crudService.put<DemonstrativoRateio>(this.resource, entity);
    }

    delete(entity: DemonstrativoRateio): Observable<string> {
        return this.crudService.delete<DemonstrativoRateio>(this.resource, entity);
    }

    /** Carrega todos os Demonstrativos Rateio por data de competência*/
    getAllByDataCompetencia(dataCompetencia: string): Observable<DemonstrativoRateio[]> {
        return this.customHttp.get(`${this.baseUrl}/consultar/datacompetencia/${dataCompetencia}`)
                    .pipe(
                        map(response => response.json()),
                        catchError(error => _throw(error))
                    );
    }

    getFile(idRateio: number): Observable<any> {
        return this.customHttp.get(`${this.arquivoUrl}/rateio/${idRateio}`)
                    .pipe(
                        map(response => window.open(`${this.arquivoUrl}/rateio/${idRateio}`, '_self')),
                        catchError(error => _throw(error))
                    )
    }

}
