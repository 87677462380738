export const css = `
td {
    font-size:11px;
}
h2 {
    margin: 0;
}

.page {
    background: white;
    display: block;
    color: #333;
    margin: 0 auto;
    margin-bottom: 0.5cm;
    box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
}

.page[size="A4"] {
    width: 21cm;
    height: 29.7cm;
}

.page[size="A4"][layout="portrait"] {
    width: 29.7cm;
    height: 21cm;
}

ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

.table-bordered {
    border: 1px solid #EBEBEB;
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
    border-top: 1px solid #e7eaec;
    line-height: 1.42857;
    padding: 8px;
    vertical-align: top;
}

.table > thead > tr > th {
    border-bottom: 1px solid #DDDDDD;
    vertical-align: bottom;
}

.table-bordered > thead > tr > th, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td {
    border: 1px solid #e7e7e7;
}

.table-bordered > thead > tr > th, .table-bordered > thead > tr > td {
    background-color: #F5F5F6;
    border-bottom-width: 1px;
}

.table-bordered>thead>tr>td, .table-bordered>thead>tr>th {
    border-bottom-width: 2px;
}

.table-bordered>tbody>tr>td, .table-bordered>tbody>tr>th, .table-bordered>tfoot>tr>td, .table-bordered>tfoot>tr>th, .table-bordered>thead>tr>td, .table-bordered>thead>tr>th {
    border: 1px solid #ddd;
}

.table>thead>tr>th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd;
}

.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd;
}

main:first-of-type {
    page-break-after: always;
}

.conteudo {
    line-height: 1;
    margin: 20px;
    padding-top: 20px;
    height: calc(100% - 40px);
}

@media print {

    body, .page {
        margin: 0;
        box-shadow: none;
    }

    .conteudo {
        margin: 0;
        padding-top: 0;
    }

    .page[size="A4"] {
        height: auto;
    }

    .page[size="A4"][layout="portrait"] {
        height: auto;
    }

    .page-break {
        page-break-after: always;
    }

    .pages-container {
        margin-top: 0;
    }

}

.conteudo section {
    height: 335px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.table-currency {
    text-align: right;
}
`;
