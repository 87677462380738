import { AuthService } from 'app/core';
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MessageService } from '../../../../core';
import { Condominio } from '../../../../models';
import { CondominiosHttpService } from 'app/home/condominios';
declare var jQuery: any;

@Component({
	selector: 'troca-condominio-modal',
	templateUrl: './troca-condominio-modal.component.html',
	styleUrls: ['./troca-condominio-modal.component.css']
})
export class TrocaCondominioModalComponent {

	@Output() trocar = new EventEmitter<Condominio>();
	@ViewChild('formTrocaCondominio') form: NgForm;

	public allCondominios: Condominio[] = [];
	public condominios: Condominio[] = [];
	public razao_social: string;

	constructor(private messageService: MessageService,
		private condominiosHttpService: CondominiosHttpService,
		private authService: AuthService) { }

	private getCondominios(): Promise<any> {
		const idUsuario: any = this.authService.getIdCurrentUser();
		return new Promise<any>((resolve, reject) => {
			this.condominiosHttpService.getAllByUsuario(idUsuario).subscribe(
				cond => {
					this.allCondominios = cond;
					this.condominios = this.allCondominios;
					resolve(null);
				},
				error => {
					this.allCondominios = [];
					this.messageService.error('Erro ao consultar os condomínios. Tente novamente', 'Erro', error);
					reject(error);
				}
			);
		});
	}

	public open(): void {
		this.getCondominios()
			.then(() => jQuery('#trocaCondominioModal').modal('show'))
			.catch(() => { });
	}

	public close(): void {
		jQuery('#trocaCondominioModal').modal('hide');
	}

	public selecionarClick(condominio: Condominio): void {
		this.trocar.emit(condominio);
		this.close();
	}

	/**
	 * Filtra os condomínios
	 * @author Marcos Frare
	 * @since 29/01/2021
	 */
	public onChangeRazaoSocial(): void {
		this.condominios = this.allCondominios.filter(cdm => cdm.razao_social.indexOf(this.razao_social) > -1);
	}
}
