import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { MessageService } from 'app/core';
import { TipoVeiculo } from 'app/models';
import { TipoVeiculoHttpService } from '../service';

declare var jQuery: any;

@Component({
  selector: 'tipo-veiculo-cadastro-modal',
  templateUrl: './tipo-veiculo-cadastro-modal.component.html',
  styleUrls: ['./tipo-veiculo-cadastro-modal.component.css']
})
export class TipoVeiculoCadastroModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @Input() id: number;
  @Output() onSave: EventEmitter<TipoVeiculo> = new EventEmitter();

  public entity: TipoVeiculo;

  constructor(private tipoVeiculoHttpService: TipoVeiculoHttpService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.entity = new TipoVeiculo();
    if (!this.idModal) {
      this.idModal = 'tipoVeiculoCadastroModal';
    }
  }

  public open() {
    this.entity = new TipoVeiculo();
    if (this.id) {
      this.tipoVeiculoHttpService.get(this.id).subscribe(
        response => {
          this.entity = response;
        },
        error => {
          this.messageService.error('Erro ao buscar dados do tipo! Tente novamente', 'Erro', error);
        }
      );
    }
    jQuery(`#${this.idModal}`).modal('show');
  }
  public close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Salvar banco
   * @author Marcos Frare
   * @since 18/03/2021
   */
  public salvar(): void {
    let btn = jQuery('#btnSaveVeiculo');
    btn.button('loading');
    const fn = this.entity.id ? this.tipoVeiculoHttpService.put(this.entity) : this.tipoVeiculoHttpService.post(this.entity);
    fn.subscribe(
      response => {
        btn.button('reset');
        this.messageService.success('Tipo salvo com sucesso!');
        this.onSave.emit(response);
        this.close();
      },
      error => {
        btn.button('reset');
        this.messageService.error('Erro ao salvar tipo! Tente novamente', 'Erro', error);
      }
    );
  }

}