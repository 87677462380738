import { Component, OnInit, ViewChild } from '@angular/core';

import { ContasAPagarRelFiltro, ordenacaoRelatorio, situacaoContasAPagar } from './contas-a-pagar-rel-filtro';
import { RelatorioModalComponent } from 'app/home/relatorios/relatorio-modal';
import { AuthService, MessageService } from 'app/core';
import { IMultiSelectSettings, IMultiSelectTexts, IMultiSelectOption } from 'angular-2-dropdown-multiselect';
import { FornecedoresHttpService } from 'app/home/fornecedores';

@Component({
	selector: 'contas-a-pagar-rel',
	templateUrl: './contas-a-pagar-rel.component.html',
	styleUrls: ['./contas-a-pagar-rel.component.css']
})
export class ContasAPagarRelComponent implements OnInit {

	public filtro: ContasAPagarRelFiltro;
	public situacoes = situacaoContasAPagar;
	public ordenacoes = ordenacaoRelatorio;
	public multiselectOptionsFornecedores: IMultiSelectOption[];
	public multiselectSettings: IMultiSelectSettings = {
		enableSearch: true,
		containerClasses: 'container-multiselect',
		showUncheckAll: true,
		dynamicTitleMaxItems: 1,
		checkedStyle: 'checkboxes',
		buttonClasses: 'btn btn-default',
		itemClasses: 'dropdown-menu'
	};
	public multiselectTextsFornecedores: IMultiSelectTexts = {
		checkAll: 'Selecionar todos',
		uncheckAll: 'Limpar',
		checked: 'selecionado',
		checkedPlural: 'selecionados',
		searchPlaceholder: 'Pesquisar...',
		defaultTitle: 'Selecionar',
		allSelected: 'Todas selecionados',
		searchEmptyResult: 'Nenhum registro encontrado',
		searchNoRenderText: 'Digite para pesquisar',
	};

	@ViewChild('relatorioModal') relatorioModal: RelatorioModalComponent;

	constructor(private authService: AuthService,
		private messageService: MessageService,
		private fornecedoresHttpService: FornecedoresHttpService) {
		this.filtro = new ContasAPagarRelFiltro();
		this.filtro.id_usuario = this.authService.getIdCurrentUser();
	}

	ngOnInit() {
		this.filtro.id_cliente = this.authService.getIdCurrentCliente();
		this.filtro.id_condominio = this.authService.getIdCurrentCondominio();
		this.limpar();
		this.getAllFornecedoresByCliente();
	}

	/**
	 * Busca todos os fornecedores do cliente
	 * @author Marcos Frare
	 * @since 01/06/2020
	 */
	private getAllFornecedoresByCliente(): void {
		this.fornecedoresHttpService.getAllByCliente(this.filtro.id_cliente).subscribe(
			entities => this.multiselectOptionsFornecedores = entities.map(entity => ({ id: entity.id, name: entity.nome })),
			error => this.messageService.error('Erro ao carregar os fornecedores', 'Erro', error)
		);
	}

	/**
	 * Limpar filtro
	 */
	public limpar(): void {
		this.filtro.limpar();
	}

	/**
	 * Validação de filtros para emissão do relatório
	 */
	private validateView(): boolean {
		return true;
	}

	/**
	 * Emissão do relatório
	 */
	public view(): void {
		if (this.validateView()) {
			this.relatorioModal.url = '/relatorio/contas-a-pagar';
			this.relatorioModal.params = this.filtro;
			this.relatorioModal.open();
		}
	}

}