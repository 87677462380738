import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared';
import { FornecedoresModule } from '../fornecedores';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { OrcamentosComponent } from './orcamentos.component';
import { OrcamentosHttpService } from './services';
import { OrcamentosCadastroComponent } from './cadastro';

@NgModule({
	imports: [
		SharedModule,
		FornecedoresModule,
		TypeaheadModule,
		MultiselectDropdownModule
	],
	declarations: [
		OrcamentosComponent,
		OrcamentosCadastroComponent
	],
	providers: [
		OrcamentosHttpService
	]
})
export class OrcamentosModule { }