import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { AuthService, MessageService } from 'app/core';
import { ConsumoUnidade } from 'app/models';
import { ConsumosUnidadesHttpService } from '../services';

declare var jQuery: any;

@Component({
  selector: 'consumo-unidade-pesquisa-modal',
  templateUrl: './consumo-unidade-pesquisa-modal.component.html',
  styleUrls: ['./consumo-unidade-pesquisa-modal.component.css']
})
export class ConsumoUnidadePesquisaModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @Input() idUnidade: number;
	@Output() select = new EventEmitter<ConsumoUnidade[]>();

  public arConsumoUnidade: ConsumoUnidade[] = [];
  public allSelected: boolean = false;

  constructor(private messageService: MessageService,
    private consumoUnidadeHttpService: ConsumosUnidadesHttpService) { }

  ngOnInit() {
    if (!this.idModal) {
			this.idModal = 'consumoUnidadePesquisaModal';
		}
  }

  /**
   * Busca consumos das unidades
   */
  private getAllConsumos(): void {
    this.arConsumoUnidade = [];
    this.consumoUnidadeHttpService.obtemAbertoCondominioUnidade(this.idUnidade, null).subscribe(
      response => {
        this.arConsumoUnidade = response;
      },
      error => {
        this.messageService.error('Erro ao buscar consumos de unidades no condomínio! Tente novamente.', 'Erro!', error);
      }
    );
  }

  open() {
    this.getAllConsumos();
		jQuery(`#${this.idModal}`).modal('show');
	}

	close() {
		jQuery(`#${this.idModal}`).modal('hide');
	}

  /**
   * Evento de click na linha da parcela do lançamento
   * @param pagamentoUnidade 
   * @autor Marcos Frare
   * @since 16/08/2021
   */
   public onLineClick(consumoUnidade: ConsumoUnidade): void {
    consumoUnidade.selected = !consumoUnidade.selected;
  }

  /**
   * Finalizar seleção
   * @author Marcos Frare
   * @since 16/08/2021
   */
  public onSelect(): void {
    let arItem = this.arConsumoUnidade.filter(item => item.selected);
    this.select.emit(arItem);
		this.close();
  }

  /**
	 * Faz seleção de todos os lançamentos
	 * @author Marcos Frare
	 * @since 16/08/2021
	 */
	 public toggleAllSelected(): void {
		for (let rec of this.arConsumoUnidade) {
			rec.selected = this.allSelected;
		}
	}

  /**
	 * Verifica se todos os consumos das unidades estão selecionadas
	 * @author Marcos Frare
	 * @since 05/08/2021
	 */
	 public checkAllSelected(): boolean {
		if (Array.isArray(this.arConsumoUnidade) && this.arConsumoUnidade.length > 0) {
			for (const pgUnidade of this.arConsumoUnidade) {
				if (!pgUnidade.selected) {
					return false;
				}
			}
		} else {
			return false;
		}
		return true;
	}

}