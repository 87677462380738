import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';

import { Crud, CrudService, ConfigService, CustomHttp } from '../../../core';
import { Comunicacao, EmailEnvio } from '../../../models';

@Injectable()
export class ComunicacoesHttpService implements Crud<Comunicacao> {
	resource = 'comunicacao';
	private baseUrl: string;

	constructor(private customHttp: CustomHttp,
		private crudService: CrudService,
		private configService: ConfigService) {
		this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
	}

	get(id: number): Observable<Comunicacao> {
		return this.crudService.get<Comunicacao>(this.resource, id);
	}

	getAll(): Observable<Comunicacao[]> {
		return this.crudService.getAll<Comunicacao>(this.resource);
	}

	post(entity: Comunicacao): Observable<Comunicacao> {
		return this.crudService.post<Comunicacao>(this.resource, entity);
	}

	put(entity: Comunicacao): Observable<Comunicacao> {
		return this.crudService.put<Comunicacao>(this.resource, entity);
	}

	delete(entity: Comunicacao): Observable<string> {
		return this.crudService.delete<Comunicacao>(this.resource, entity);
	}

	getAllByCondominio(idCondominio: number): Observable<Comunicacao[]> {
		return this.customHttp.get(`${this.baseUrl}/consultar`, { params: { id_condominio: idCondominio } }).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	search(params: any): Observable<Comunicacao[]> {
		return this.customHttp.get(`${this.baseUrl}/consultar`, { params }).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	searchIdCondomino(idCondomino: number): Observable<Comunicacao[]> {
		return this.customHttp.get(`${this.baseUrl}/consultar/${idCondomino}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Enviar e-mail da comunicação
	 * @param entity 
	 * @author Marcos Frare
	 * @since 03/03/2021
	 */
	public sendMail(entity: Comunicacao): Observable<EmailEnvio[]> {
		return this.customHttp.post(`${this.baseUrl}/enviar`, entity).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Busca logs de email de uma comunicação
	 * @param id_comunicacao 
	 * @returns 
	 * @author Marcos Frare
	 * @since 08/03/2021
	 */
	public getLogEnvioEmail(id_comunicacao: number): Observable<EmailEnvio[]> {
		return this.customHttp.get(`${this.baseUrl}/log-email/${id_comunicacao}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

}