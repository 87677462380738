import { BaseEntity } from '../base-entity';
import { Documento } from './documento';

export class DocumentoPasta extends BaseEntity {
	id_condominio: number;
	nome: string;

	documentos: Documento[];
	showDocumentos: boolean;
	
	constructor() {
		super();
		this.nome = null;
		this.documentos = [];
		this.showDocumentos = false;
	}
};
