import { Injectable } from '@angular/core';

import { WebStorage } from './web-storage';

@Injectable()
export class LocalStorageService implements WebStorage {
    private localStorage = window.localStorage;

    set(key: string, value: string) {
        this.localStorage.setItem(key, value);
    }

    get(key: string, defaultValue = null): string {
        return this.localStorage.getItem(key) || defaultValue;
    }

    setObject(key: string, value: Object) {
        this.localStorage.setItem(key, JSON.stringify(value));
    }

    getObject(key: string): Object {
        return JSON.parse(this.localStorage.getItem(key));
    }

    remove(key: string) {
        this.localStorage.removeItem(key);
    }
}
