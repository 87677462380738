import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared';
import { AnaliticoRelComponent } from './analitico-rel.component';
import { ContasBancariasHttpService } from '../../contas-bancarias';

@NgModule({
	imports: [
		SharedModule
	],
	declarations: [
		AnaliticoRelComponent
	],
	exports: [],
	providers: [
		ContasBancariasHttpService
	]
})
export class AnaliticoRelModule { }