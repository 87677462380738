import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { tipoPagamento, sindicoAprovaPagamento, TYPES_PAGAMENTO, TipoContato, Pagamento, PagamentoCancelamento } from 'app/models';
import { AuthService, MessageService } from 'app/core';
import { TipoContatoHttpService } from 'app/home/tipo-contato';
import { PagamentoCancelamentoHttpService, PagamentoCancelamentoModalComponent } from './pagamento-cancelamento-modal';

@Component({
	selector: 'lancamento-pagamentos',
	templateUrl: './lancamento-pagamentos.component.html',
	styleUrls: ['./lancamento-pagamentos.component.css'],
})
export class LancamentoPagamentosComponent implements OnInit {

	@ViewChild('pagamentoCancelamentoModal') pagamentoCancelamentoModal: PagamentoCancelamentoModalComponent;

	@Input() editMode: boolean;
	@Input() lancamento: any;
	@Input() typePagamento: number;
	@Output() entitiesChange: EventEmitter<any[]> = new EventEmitter();
	@Output() changeDate: EventEmitter<Date> = new EventEmitter()

	public entitiesValue: any[];
	public aprovacoesSindico = sindicoAprovaPagamento;
	public tiposPagamentos = tipoPagamento;
	public idCondominio: number;
	public typesPagamento = TYPES_PAGAMENTO;
	public tiposContato: TipoContato[];

	constructor(private authService: AuthService,
			private tipoContatohttpService: TipoContatoHttpService,
			private pagamentoCancelamentoHttpService: PagamentoCancelamentoHttpService,
			private messageService: MessageService) {
		this.idCondominio = this.authService.getIdCurrentCondominio();
	}

	@Input()
	get entities() {
		return this.entitiesValue;
	}
	set entities(val) {
		this.entitiesValue = val;
		this.entitiesChange.emit(this.entitiesValue);
	}

	ngOnInit() {
		this.getAllTipoContato();
	}

	/**
	 * Busca os tipos de contato
	 * @author Marcos Frare
	 * @since 20/04/2021
	 */
	private getAllTipoContato(): void {
		this.tiposContato = [];
		this.tipoContatohttpService.getAll().subscribe(
			response => {
				this.tiposContato = response;
			}
		);
	}

	/**
	 * Atualiza valores de parcela, conforme parcelas alteradas no lançamento
	 * @author Towty
	 */
	public updatePagamentos(): void {
		// parcelas já existentes antes da alteração
		const parcelas = this.entities;
		
		this.entities = this.entities.filter(pagamento => !this.canEditPagamento(pagamento));
		let totalPagamentos = this.entities.length;
		const totalNewPagamentos = Math.abs(totalPagamentos - this.lancamento.parcelas);

		let dataParcela = null;

		for (let i = totalPagamentos + 1 || 1; i <= totalPagamentos + totalNewPagamentos; i++) {
			const pagamento: any = {};

			pagamento.numero_parcela = i;

			// Gravar na primeira parcela a data
			if (!dataParcela) {
				if (Array.isArray(parcelas) && parcelas.length > 0 && i <= parcelas.length) {
					switch (this.typePagamento) {
						case TYPES_PAGAMENTO.CONDOMINIO: {
							pagamento.data_vencimento = parcelas[0].data_vencimento;
							dataParcela = new Date(pagamento.data_vencimento);
							break;
						}
						case TYPES_PAGAMENTO.ESPECIFICO: {
							pagamento.data_competencia = parcelas[0].data_competencia;
							dataParcela = new Date(pagamento.data_competencia);
							break;
						}
						case TYPES_PAGAMENTO.UNIDADE: {
							pagamento.data_competencia = parcelas[0].data_competencia;
							dataParcela = new Date(pagamento.data_competencia);
							break;
						}
					}
				}
			}
			switch (this.typePagamento) {
				case TYPES_PAGAMENTO.CONDOMINIO:
					pagamento.id_lancamento_condominio = this.lancamento.id;
					pagamento.ratear = !this.lancamento.id_categoria_conta_nao_rateado;
					pagamento.cobrar_tipo_unidade = this.lancamento.cobrar_tipo_unidade;
					pagamento.tipo_divisao_rateio = this.lancamento.tipo_divisao_rateio;
					pagamento.sindico_aprova = 1;
					break;
				case TYPES_PAGAMENTO.ESPECIFICO:
					pagamento.id_lancamento_especifico = this.lancamento.id;
					pagamento.cobrar_tipo_unidade = this.lancamento.cobrar_tipo_unidade;
					pagamento.tipo_divisao_rateio = this.lancamento.tipo_divisao_rateio;
					pagamento.id_condominio = this.idCondominio;
					break;
				case TYPES_PAGAMENTO.UNIDADE:
					pagamento.id_lancamento_unidade = this.lancamento.id;
					break;
				default:
					break;
			}

			this.entities.push(pagamento);
		}
		// Atualiza data das parcelas conforme data da primeira parcela
		if (this.entities.length > 0 && dataParcela) {
			this.setProximasDatasVencimentos(dataParcela);
		}
		// Calcula o valor das parcelas
		this.dividirEntreParcelas();
	}

	/**
	 * Calcula o valor das parcelas, incluíndo a diferença de arredondamento na última parcela
	 * Processo executado quando altera o valor total no lançamento
	 * @author Towty - Marcos Frare
	 */
	public dividirEntreParcelas(): void {
		const editablePagamentos: any[] = this.entities.filter(pagamento => this.canEditPagamento(pagamento));
		const blockPagamentos: any[] = this.entities.filter(pagamento => !this.canEditPagamento(pagamento));
		const totalNewPagamentos = editablePagamentos.length;
		let totalPago = 0;

		// Quantidade de parcelas bloqueadas
		let parcela = blockPagamentos.length;

		let saldo = 0
		if (totalNewPagamentos) {
			blockPagamentos.forEach(pagamento => totalPago += Number(pagamento.valor));
			const totalToDivide = Number(this.lancamento.valor_total) - Number(totalPago);
			const valorParcela = Number((totalToDivide / totalNewPagamentos).toFixed(2));
			saldo = totalToDivide;
			this.entities
				.filter(pagamento => this.canEditPagamento(pagamento))
				.forEach(pagamento => {
					parcela++;
					pagamento.valor = valorParcela;
					pagamento.numero_parcela = parcela;
					saldo -= valorParcela;
				});
		}
		// Verificar o saldo, para incluir diferença na última parcela ainda não paga, ou adiciona nova parcela
		let pagamentos = this.entities.filter(pagamento => this.canEditPagamento(pagamento));
		if (pagamentos.length > 0) {
			pagamentos[pagamentos.length - 1].valor = pagamentos[pagamentos.length - 1].valor + saldo;
		} else {
			this.addParcela();
		}
	}

	/**
	 * Verifica se a parcela pode ser editada
	 * @param pagamento 
	 * @author Towty
	 */
	public canEditPagamento(pagamento: any): boolean {
		switch (this.typePagamento) {
			case TYPES_PAGAMENTO.CONDOMINIO:
				return !pagamento.id_demonstrativo_rateio && !pagamento.id_conta_bancaria && !pagamento.data_pagamento;
			case TYPES_PAGAMENTO.ESPECIFICO:
				return !pagamento.id_demonstrativo_rateio;
			case TYPES_PAGAMENTO.UNIDADE:
				return !pagamento.id_rateio_unidade;
			default:
				break;
		}
	}

	/**
	 * Atualiza as parcelas sequentes com o valor residual
	 * @param entity 
	 * @author Marcos Frare
	 * @since 12/08/2020
	 */
	public recalcularParcelas(entity: any): void {
		const editablePagamentos: any[] = this.entities.filter(pagamento => (this.canEditPagamento(pagamento) && pagamento.numero_parcela > entity.numero_parcela));
		const underPagamentos: any[] = this.entities.filter(pagamento => (pagamento.numero_parcela <= entity.numero_parcela));

		let saldo = this.lancamento.valor_total;
		// Decrementa valores de parcelas não editáveis
		for (let pag of underPagamentos) {
			saldo -= pag.valor;
		}
		// Divide o saldo entre as parcelas editáveis
		const vlParcela = Number((saldo / editablePagamentos.length).toFixed(2));
		for (let pgto of editablePagamentos) {
			pgto.valor = vlParcela;
			saldo -= vlParcela;
		}
		this.entities[this.entities.length - 1].valor += saldo;
	}

	public onRemoveParcelaClick(pagamento: any): void {
		if (confirm(`Tem certeza que deseja excluir a ${pagamento.numero_parcela}ª parcela?`)) {
			this.removeParcela(pagamento);
		}
	}

	private removeParcela(pagamento: any): void {
		this.lancamento.parcelas = this.lancamento.parcelas - 1;
		this.removeParcelaFromList(pagamento);
		this.dividirEntreParcelas();
	}

	private removeParcelaFromList(pagamento: any): void {
		const index = this.entities.findIndex(item => item.numero_parcela === pagamento.numero_parcela);
		if (index < 0) {
			return;
		}
		this.entities.splice(index, 1);
	}

	public onChangeDataVencimento(pagamento: any): void {
		this.changeDate.emit(pagamento.data_vencimento);
		switch (this.typePagamento) {
			case TYPES_PAGAMENTO.CONDOMINIO:
				if (pagamento && pagamento.numero_parcela === 1 && pagamento.data_vencimento) {
					this.setProximasDatasVencimentos(pagamento.data_vencimento);
				}
				break;
			case TYPES_PAGAMENTO.ESPECIFICO:
			case TYPES_PAGAMENTO.UNIDADE:
				if (pagamento && pagamento.numero_parcela === 1 && pagamento.data_competencia) {
					this.setProximasDatasVencimentos(pagamento.data_competencia);
				}
				break;
			default:
				break;
		}
	}

	private setProximasDatasVencimentos(dataVencimentoBase: Date): void {
		let lastDate = dataVencimentoBase;
		for (const pagamento of this.entities) {
			if (pagamento.numero_parcela === 1) { continue; }
			lastDate = new Date(
				lastDate.getFullYear(),
				lastDate.getMonth() + 1,
				lastDate.getDate());
			switch (this.typePagamento) {
				case TYPES_PAGAMENTO.CONDOMINIO:
					if (lastDate) {
						pagamento.data_vencimento = lastDate;
					} else {
						pagamento.data_vencimento = null;
					}
					break;
				case TYPES_PAGAMENTO.ESPECIFICO:
				case TYPES_PAGAMENTO.UNIDADE:
					if (lastDate) {
						pagamento.data_competencia = lastDate;
					} else {
						pagamento.data_competencia = null;
					}
					break;
				default:
					break;
			}
		}
	}

	public alteraTodasParcelas(valor: any, campo: string): void {
		this.entities
			.filter(pagamento => this.canEditPagamento(pagamento))
			.forEach(pagamento => pagamento[campo] = valor);
	}

	/**
	 * Cancelamento de pagamento de uma parcela do lançamento
	 * @param pagamento 
	 * @author Marcos Frare
	 * @since 10/05/2021
	 */
	public onCancelaPagamentoParcelaClick(pagamento: Pagamento): void {
		this.pagamentoCancelamentoModal.id_pagamento = pagamento.id;
		this.pagamentoCancelamentoModal.open();
	}

	/**
	 * Desfaz cancelamento de pagamento
	 * @param pagamento 
	 * @author Marcos Frare
	 * @since 11/05/2021
	 */
	public onDesfazCacelamentoPagamentoParcelaClick(pagamento: Pagamento): void {
		if (pagamento.id_cancelamento) {
			if (confirm(`Deseja realmente desfazer o cancelamento de pagamento?`)) {
				this.pagamentoCancelamentoHttpService.desfazCancelamento(pagamento.id_cancelamento).subscribe(
					() => {
						this.messageService.success('Cancelamento desfeito com sucesso!');
						pagamento.id_cancelamento = null;
					},
					error => {
						this.messageService.error('Erro ao desfazer cancelamento! Tente novamente.', 'Erro', error);
					}
				);
			}
		}
	}

	/**
	 * Evento de salvar cancelamento da parcela de pagamento
	 * @param pagamentoCancelamento 
	 * @author Marcos Frare
	 * @since 11/05/2021
	 */
	public updatePagamento(pagamentoCancelamento: PagamentoCancelamento): void {
		if (pagamentoCancelamento.id && pagamentoCancelamento.id_pagamento) {
			this.entities.filter(parcela => parcela.id == pagamentoCancelamento.id_pagamento).map(parc => parc.id_cancelamento = pagamentoCancelamento.id);
		}
	}

	/**
	 * Adicionar parcela para pagamento
	 * @author Marcos Frare
	 * @since 06/09/2021
	 */
	public addParcela(): void {
		if (this.entities.length) {
			this.lancamento.parcelas++;
		}
		this.updatePagamentos();
	}

}