import { NgModule } from '@angular/core';
import { ChequeRelatorioComponent } from './cheque-relatorio.component';
import { SharedModule } from 'app/shared';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [ChequeRelatorioComponent]
})
export class ChequeRelatorioModule { }
