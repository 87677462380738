import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { MessageService } from 'app/core';
import { TipoVeiculoCadastroModalComponent, TipoVeiculoHttpService } from 'app/home/veiculo/tipo-veiculo';
import { ModeloVeiculo, FabricanteVeiculo, TipoVeiculo } from 'app/models';
import { FabricanteVeiculoCadastroModalComponent } from '../../fabricante-veiculo-cadastro-modal';
import { FabricanteVeiculoHttpService } from '../../service';
import { ModeloVeiculoHttpService } from '../service';

declare var jQuery: any;

@Component({
  selector: 'modelo-veiculo-cadastro-modal',
  templateUrl: './modelo-veiculo-cadastro-modal.component.html',
  styleUrls: ['./modelo-veiculo-cadastro-modal.component.css']
})
export class ModeloVeiculoCadastroModalComponent implements OnInit, BaseModal {

  @ViewChild('fabricanteVeiculoCadastroModal') fabricanteVeiculoCadastroModal: FabricanteVeiculoCadastroModalComponent;
  @ViewChild('tipoVeiculoCadastroModal') tipoVeiculoCadastroModal: TipoVeiculoCadastroModalComponent;

  @Input() idModal: string;
  @Input() id: number;
  @Output() onSave: EventEmitter<ModeloVeiculo> = new EventEmitter();

  public entity: ModeloVeiculo;
  public fabricantesVeiculo: FabricanteVeiculo[] = [];
  public tiposVeiculo: TipoVeiculo[] = [];

  constructor(private modeloVeiculoHttpService: ModeloVeiculoHttpService,
    private fabricanteVeiculoHttpService: FabricanteVeiculoHttpService,
    private tipoVeiculoHttpService: TipoVeiculoHttpService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.entity = new ModeloVeiculo();
    if (!this.idModal) {
      this.idModal = 'modeloVeiculoCadastroModal';
    }
  }

  /**
   * Busca todas os fabricantes de veículos
   */
  private getAllFabricantes(): void {
    this.fabricanteVeiculoHttpService.getAll().subscribe(
      response => {
        this.fabricantesVeiculo = response;
      },
      error => {
        this.messageService.warning('Erro ao buscar os fabricantes de veículos!', 'Atenção');
      }
    );
  }

  /**
   * Busca todos os tipos de veículos
   */
  private getAllTipos(): void {
    this.tipoVeiculoHttpService.getAll().subscribe(
      response => {
        this.tiposVeiculo = response;
      },
      error => {
        this.messageService.warning('Erro ao buscar os tipos de veículos!', 'Atenção');
      }
    );
  }

  public open() {
    this.getAllFabricantes();
    this.getAllTipos();
    this.entity = new ModeloVeiculo();
    if (this.id) {
      this.modeloVeiculoHttpService.get(this.id).subscribe(
        response => {
          this.entity = response;
        },
        error => {
          this.messageService.error('Erro ao buscar dados da Marca! Tente novamente', 'Erro', error);
        }
      );
    }
    jQuery(`#${this.idModal}`).modal('show');
  }
  public close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Salvar modelo
   * @author Marcos Frare
   * @since 18/03/2021
   */
  public salvar(): void {
    let btn = jQuery('#btnSaveModelo');
    btn.button('loading');
    const fn = this.entity.id ? this.modeloVeiculoHttpService.put(this.entity) : this.modeloVeiculoHttpService.post(this.entity);
    fn.subscribe(
      response => {
        btn.button('reset');
        this.messageService.success('Modelo salvo com sucesso!');
        this.onSave.emit(response);
        this.close();
      },
      error => {
        btn.button('reset');
        this.messageService.error('Erro ao salvar modelo! Tente novamente', 'Erro', error);
      }
    );
  }

  /**
   * Abrir tela para cadastro de fabricante
   * @author Marcos Frare
   * @since 12/04/2021
   */
  public createFabricante(): void {
    this.fabricanteVeiculoCadastroModal.id = null;
    this.fabricanteVeiculoCadastroModal.open();
  }

  /**
   * Callback de persistencia de fabricante de veículo
   * @param fabricante 
   * @author Marcos Frare
   * @since 12/04/2021
   */
  public saveFabricante(fabricante: FabricanteVeiculo): void {
    this.fabricantesVeiculo.push(fabricante);
    this.entity.id_fabricante_veiculo = fabricante.id;
  }

  /**
   * Abrir tela para cadastro de tipo de veículo
   * @author Marcos Frare
   * @since 14/04/2021
   */
   public createTipo(): void {
    this.tipoVeiculoCadastroModal.id = null;
    this.tipoVeiculoCadastroModal.open();
  }

  /**
   * Callback de persistencia de tipo de veículo
   * @param fabricante 
   * @author Marcos Frare
   * @since 14/04/2021
   */
  public saveTipo(tipo: TipoVeiculo): void {
    this.tiposVeiculo.push(tipo);
    this.entity.id_tipo_veiculo = tipo.id;
  }

}