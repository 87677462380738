import { BaseEntity } from "../base-entity";
import { FabricanteVeiculo } from "./fabricante-veiculo";
import { ModeloVeiculo } from "./modelo-veiculo";

export class Veiculo extends BaseEntity {
	id_fabricante_veiculo: number;
	id_modelo_veiculo: number;
	placa: string;
	renavan: string;
	cor: string;
	ano_fabricacao: number;
	ano_modelo: number;
	portas: number;

	fabricanteVeiculo: FabricanteVeiculo;
	modeloVeiculo: ModeloVeiculo;
	
	constructor() {
		super();
		this.portas = 2;
	}
}