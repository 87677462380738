import { RateioUnidadeLancamentoPagamento } from './rateio-unidade-lancamento-pagamento';
import { RateioUnidadeLancamentoConsumo } from './rateio-unidade-lancamento-consumo';

export class RateioUnidadeLancamentoUnidade {
	id_bloco: number;
	nome_bloco: string;
	id: number;
	nome_unidade: string;
	andar: number;
	fracao_solo: number;
	pagamentos: RateioUnidadeLancamentoPagamento[] = [];
	consumos: RateioUnidadeLancamentoConsumo[] = [];
}
