import { CondominiosHttpService } from './../../../condominios/services/condominios-http.service';
import {Component, Input, OnInit, Output, EventEmitter, SimpleChanges, OnChanges} from '@angular/core';
import { AuthService } from '../../../../core';

@Component({
    selector: 'acessos-condominio',
    templateUrl: './acessos-condominio.component.html',
    styleUrls: ['./acessos-condominio.component.less']
})
export class AcessosCondominioComponent implements OnInit, OnChanges {
    
    @Input() permiteEditar = true;
    @Input() idUsuario: any;
    @Output() entitiesChange: EventEmitter<number[]> = new EventEmitter();
    @Output() onChange: EventEmitter<number[]> = new EventEmitter();
    
    public entitiesValue: number[];
    public condominios: any[] = [];
    public idCliente: number;
    public allSelected: boolean = true;

    constructor(private condominiosHttpService: CondominiosHttpService,
                private authService: AuthService) {
        this.idCliente = this.authService.getIdCurrentCliente();
    }


    @Input()
    get entities() {
      return this.entitiesValue;
    }
    set entities(val) {
        this.entitiesValue = val;
        this.entitiesChange.emit(this.entitiesValue);
    }


    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.entities) {
            this.getAllCondominiosByCliente();
        }
    }

    private getAllCondominiosByCliente(): void  {
        this.condominiosHttpService.getAllByCliente(this.idCliente).subscribe(
            condominios => {
                this.condominios = condominios
                this.condominios.map(condominio => condominio.selected = this.entities.includes(condominio.id))
            }); 
    }

    public onItemClick(): void {
        this.entities = this.getIdsCondominiosSelected();
        this.onChange.emit(this.entitiesValue);
    }

    public toggleAllSelected(): void{
        this.condominios.forEach(entity => entity.selected = this.allSelected); 
        this.onItemClick();
    }

    public checkAllSelected(): boolean {
        let isAllSelected: boolean = true;
        this.condominios.forEach(entity => {
            if (!entity.selected) isAllSelected = false;
        });
        return isAllSelected;
    }

    public checkSelected(): boolean {
        let isSelected: boolean;
        this.condominios.forEach(entity => isSelected = entity.selected ? true : false);
        return isSelected;
    }

    private getIdsCondominiosSelected(): number[] {
        return this.condominios.filter(entity => entity.selected).map(entity => entity.id);
    }

}
