import { Injectable } from '@angular/core';

import { SessionStorageService } from '../web-storage';

import { JwtHelperService } from '@auth0/angular-jwt';

const accessTokenKey = 'access_token';

@Injectable()
export class JwtTokenService {

    jwtHelper: JwtHelperService;
    
    constructor(private sessionStorage: SessionStorageService) {
        this.jwtHelper = new JwtHelperService();
    }

    get accessToken(): string {
        return this.sessionStorage.get(accessTokenKey);
    }

    set accessToken(accessToken: string) {
        // Se o token possui valor
        if (accessToken) {
            this.sessionStorage.set(accessTokenKey, accessToken);
        } else {
            // Caso o token não possui valor, removemos do local storage
            this.remove();
        }
    }

    get accessTokenWithBearer(): string {
        return `Bearer ${this.accessToken}`;
    }

    /**
     * Decodifica o token de acesso
     */
    decodeToken(): any {
        return this.jwtHelper.decodeToken(this.accessToken);
    }

    /**
     * Pega a data de expiração do token de acesso
     */
    getTokenExpirationDate(): Date {
        return this.jwtHelper.getTokenExpirationDate(this.accessToken);
    }

    /**
     * Verifica se o token de acesso está expirado ou não
     */
    isTokenExpired(): boolean {
        return this.jwtHelper.isTokenExpired(this.accessToken);
    }

    /**
     * Remove o access_token do local storage
     */
    remove() {
        this.sessionStorage.remove(accessTokenKey);
    }
}
