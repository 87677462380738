import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';

import { Crud, CrudService, ConfigService, CustomHttp } from '../../../core';
import { Notas } from '../../../models';

@Injectable()
export class NotasHttpService implements Crud<Notas> {
    resource = 'notas';
    private baseUrl: string;

    constructor(private customHttp: CustomHttp,
                private crudService: CrudService,
                private configService: ConfigService) {
        this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
    }

    get(id: number): Observable<Notas> {
        return this.crudService.get<Notas>(this.resource, id);
    }

    getAll(): Observable<Notas[]> {
        return this.crudService.getAll<Notas>(this.resource);
    }

    post(entity: Notas): Observable<Notas> {
        return this.crudService.post<Notas>(this.resource, entity);
    }

    put(entity: Notas): Observable<Notas> {
        return this.crudService.put<Notas>(this.resource, entity);
    }

    delete(entity: Notas): Observable<string> {
        return this.crudService.delete<Notas>(this.resource, entity);
    }

    /**
     * Carrega todas as notas do lançamento informado
     */
    getAllByLancamento(idLancamento: number): Observable<Notas[]> {
        return this.customHttp.get(`${this.baseUrl}/consultar/lancamento/${idLancamento}`)
                    .pipe(
                        map(response => response.json()),
                        catchError(error => _throw(error))
                    );
    }

    /**
     * Carrega todas as notas do pagamento informado
     */
    getAllByPagamento(idPagamento: number): Observable<Notas[]> {
        return this.customHttp.get(`${this.baseUrl}/consultar/pagamento/${idPagamento}`)
                    .pipe(
                        map(response => response.json()),
                        catchError(error => _throw(error))
                    );
    }
}