import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { AuthService, MessageService } from '../../../core';
import { EspelhoBoletoRelFiltro } from './espelho-boleto-rel-filtro';
import { Unidade, Bloco, Condominio } from './../../../models';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import { RelatorioModalComponent } from '../../relatorios/relatorio-modal';
import { datepickerMesAnoOptions } from '../../../shared';
import { BlocosHttpService, BlocosPesquisaModalComponent, UnidadesHttpService } from 'app/home/blocos';
import { CategoriaContaGrupoHttpService } from 'app/home/plano-de-contas';

declare var jQuery: any;

@Component({
	selector: 'espelho-boleto-rel',
	templateUrl: './espelho-boleto-rel.component.html',
	styleUrls: ['../shared/visualizacao.relatorio.css']
})
export class EspelhoBoletoRelComponent implements OnInit {

	public title: string;
	public user: string;
	public date: string;
	public logoUrl: string;
	public reportData: any;
	public condominio: Condominio;
	public unidades: Unidade[] = [];
	public blocos: Bloco[] = [];
	public filtro = new EspelhoBoletoRelFiltro();
	public showInputBlocos: boolean = true;
	public baseUrl: string;
	public datepickerDataCompetenciaOptions = datepickerMesAnoOptions;

	@ViewChild('inputBloco') inputBloco: ElementRef;
	@ViewChild('blocosPesquisaModal') blocosPesquisaModal: BlocosPesquisaModalComponent;
	@ViewChild('relatorioModal') relatorioModal: RelatorioModalComponent;

	multiselectSettings: IMultiSelectSettings = {
		enableSearch: true,
		containerClasses: 'container-multiselect',
		showUncheckAll: true,
		showCheckAll: true,
		dynamicTitleMaxItems: 3,
		checkedStyle: 'checkboxes',
		buttonClasses: 'btn btn-default',
		itemClasses: 'dropdown-menu'
	};

	multiselectTextsUnidades: IMultiSelectTexts = {
		checkAll: 'Selecionar todas',
		uncheckAll: 'Limpar',
		checked: 'selecionada',
		checkedPlural: 'selecionadas',
		searchPlaceholder: 'Pesquisar...',
		defaultTitle: 'Todos',
		allSelected: 'Todas selecionadas',
		searchEmptyResult: 'Nenhum registro encontrado',
		searchNoRenderText: 'Digite para pesquisar',
	};
	multiselectOptionsUnidades: IMultiSelectOption[];
	multiselectOptionsGrupo: IMultiSelectOption[];

	constructor(private authService: AuthService,
		private messageService: MessageService,
		private unidadesHttpService: UnidadesHttpService,
		private blocosHttpService: BlocosHttpService,
		private categoriaContaGrupoHttpService: CategoriaContaGrupoHttpService) { }

	ngOnInit() {
		this.setInitialFilter();
		this.filtro.id_cliente = this.authService.getIdCurrentCliente();
		this.filtro.id_condominio = this.authService.getIdCurrentCondominio();
		this.filtro.id_usuario = this.authService.getIdCurrentUser();
		this.getBlocosByCondominio(this.filtro.id_condominio);
		this.getAllUnidades();
		this.getAllCatContaGrupo();
	}

	/**
	 * Busca todos os grupos de categoria de contas do condomínio
	 * @author Marcos Frare
	 * @since 22/09/2021
	 */
	private getAllCatContaGrupo(): void {
		this.multiselectOptionsGrupo = [];
		this.categoriaContaGrupoHttpService.getAll().subscribe(
			response => {
				let selects: IMultiSelectOption[] = [];
				response.map(grupo => {
					selects.push({id: grupo.id, name: grupo.nome});
				});
				this.multiselectOptionsGrupo = selects;
			}
		);
	}

	private getBlocosByCondominio(idCondominio: number) {
		this.blocosHttpService.getAllByCondominio(idCondominio).subscribe(
			entitites => {
				this.blocos = entitites
				if (this.blocoUnico(this.blocos)) {
					this.setBloco(this.blocos[0]);
					this.showInputBlocos = false;
				} else if (!this.blocos) {
					this.messageService.error('Não existem blocos cadastrados no Condomínio. Cadastre  um bloco e tente novamente', 'Erro')
				}
			},
			error => this.messageService.error('Erro ao carregar as unidades do condomínio. Tente novamente', 'Erro', error)
		);
	}

	private setBloco(blocoSelecionado: Bloco) {
		this.filtro.bloco = blocoSelecionado;
		this.filtro.id_bloco = this.filtro.bloco.id
		this.filterUnidades();
		this.onCleanUnidades();
	}

	/**
	 * Busca todas as unidades do codomínio
	 * @author Marcos Frare
	 * @since 24/04/2020
	 */
	private getAllUnidades(): void {
		this.unidadesHttpService.getAllByCondominioBloco(this.filtro.id_condominio, null).subscribe(
			response => {
				this.unidades = response;
				this.filterUnidades();
			},
			error => this.messageService.error('Erro ao carregar as unidades do condomínio. Tente novamente', 'Erro', error)
		);
	}

	/**
	 * Filtra as unidades com base no campo de bloco
	 * @author Marcos Frare
	 * @since 24/04/2020
	 */
	private filterUnidades(): void {
		const selects: IMultiSelectOption[] = [];
		let arr = this.unidades;
		if (this.filtro.bloco && this.filtro.bloco.id) {
			arr = this.unidades.filter(und => und.id_bloco === this.filtro.bloco.id);
		}
		arr.forEach(entity => {
			selects.push({
				id: entity.id,
				name: `${entity.nome_bloco} - ${entity.nome_unidade}`
			});
		});
		this.multiselectOptionsUnidades = selects;
	}

	onCleanUnidades() {
		this.filtro.unidades = [];
		this.filterUnidades();
	}

	onSelectBloco(tm: TypeaheadMatch): void {
		this.setBloco(tm.item as Bloco);
	}

	openBlocosPequisaModal() {
		this.blocosPesquisaModal.open();
	}

	onSelectBlocosPesquisaModal(bloco: Bloco) {
		if (bloco) {
			this.setBloco(bloco)
		}
		jQuery('#bloco').focus();
	}

	onCleanBloco(input: any) {
		this.filtro.bloco = null;
		this.onCleanUnidades();
		input.value = '';
		input.focus();
	}

	private blocoUnico(blocos: Bloco[]): boolean {
		return blocos.length === 1;
	}

	private validateView(): boolean {
		if (this.filtro.tipoFiltro == 1) {
			if (!this.filtro.dataCompetencia) {
				this.messageService.info('É necessário informar a data de competência para imprimir', 'Informação');
				return false;
			}
		} else {
			if (!this.filtro.data_inicial) {
				this.messageService.info('É necessário informar a data de início para imprimir', 'Informação');
				return false;
			}
			if (!this.filtro.data_final) {
				this.messageService.info('É necessário informar a data final para imprimir', 'Informação');
				return false;
			}
		}

		return true;
	}

	public view(): void {
		if (this.validateView()) {
			this.relatorioModal.url = '/relatorio/espelho-boleto';
			this.relatorioModal.params = this.filtro;
			this.relatorioModal.open();
		}
	}

	/**
	 * Evento de alteração do tipo de relatório
	 * @author Marcos Frare
	 * @since 15/03/2021
	 */
	public changeTipoRelatorio(): void {
		if (this.filtro.tipo_relatorio == 'RESUMIDO') {
			this.filtro.tipoFiltro = 1;
		}
	}

	public limpar(): void {
		this.filtro.limpar();
		this.reportData = null;
	}

	public setInitialFilter(): void {
		const today = new Date();
		this.filtro.data_inicial = new Date(today.getFullYear(), today.getMonth(), 1); // primeiro dia do mês
		this.filtro.data_final = new Date(today.getFullYear(), today.getMonth() + 1, 0); // último dia do mês
		this.filtro.tipoFiltro = 1;
	}
}