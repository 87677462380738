import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cnpjFormat'
})
export class CnpjFormatPipe implements PipeTransform {

    transform(value: any) {

        let rawValue = this.clearDigitsSpecialChars(value);
        let newValue = rawValue.substr(0, 2);

        if (rawValue.length > 2)
            newValue  += '.' + rawValue.substr(2, 3);

        if (rawValue.length > 5)
            newValue  += '.' + rawValue.substr(5, 3);

        if (rawValue.length > 8)
            newValue  += '/' + rawValue.substr(8, 4);

        if (rawValue.length > 12)
            newValue += '-' + rawValue.substr(12, 2);

        return newValue;
    }

    clearDigitsSpecialChars(value) {
        if (value) {
            const anyNonDigitRegExp = /[^0-9]/g
            return value.toString().replace(anyNonDigitRegExp, '')
        }
        return "";
    }

}