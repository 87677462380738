import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService, MessageService } from '../../../core';
import { BalanceteRelFiltro } from './balancete-rel-filtro';
import { ContaBancaria, Condominio } from '../../../models';
import { ContasBancariasHttpService } from '../../contas-bancarias';
import { datepickerMesAnoOptions } from 'app/shared';
import { RelatorioModalComponent } from '../relatorio-modal';

@Component({
	selector: 'balancete-rel',
	templateUrl: './balancete-rel.component.html',
	styleUrls: ['../shared/visualizacao.relatorio.css']
})
export class BalanceteRelComponent implements OnInit {

	@ViewChild('relatorioModal') relatorioModal: RelatorioModalComponent;

	public condominio: Condominio;
	public contas: ContaBancaria[] = [];
	public filtro = new BalanceteRelFiltro();
	public datepickerDataCompetenciaOptions = datepickerMesAnoOptions;

	constructor(private authService: AuthService,
		private messageService: MessageService,
		private contasBancariasHttpService: ContasBancariasHttpService) {
	}

	ngOnInit() {
		this.filtro.id_cliente = this.authService.getIdCurrentCliente();
		this.filtro.id_condominio = this.authService.getIdCurrentCondominio();
		this.filtro.id_usuario = this.authService.getIdCurrentUser();
		this.getContasBancariasByCondominio(this.filtro.id_condominio);
		this.limpar();
	}

	public limpar(): void {
		this.filtro.limpar();
	}

	/**
	 * Busca as contas bancárias do condomínio
	 * @param idCondominio 
	 * @autor Towty
	 */
	private getContasBancariasByCondominio(idCondominio: number) {
		this.contasBancariasHttpService.getAllByCondominio(idCondominio).subscribe(
			entitites => this.contas = entitites,
			error => this.messageService.error('Erro ao carregar as contas bancarias do condomínio. Tente novamente', 'Erro', error)
		);
	}

	private validateView(): boolean {
		if (!this.filtro.competencia_inicial) {
			this.messageService.info('É necessário informar a competência início para imprimir', 'Informação');
			return false;
		}
		return true;
	}

	public view(): void {
		if (this.validateView()) {
			this.relatorioModal.url = '/relatorio/balancete';
			this.relatorioModal.params = this.filtro;
			this.relatorioModal.open();
		}
	}

}