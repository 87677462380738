import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared';

import { FormulariosComponent } from './formularios.component';
import { FormulariosHttpService } from './services';
import { NgxEditorModule } from 'ngx-editor';
import { FormulariosCadastroComponent } from './cadastro/formularios-cadastro.component';

@NgModule({
    imports: [
        SharedModule,
        NgxEditorModule
    ],
    declarations: [
        FormulariosComponent,
        FormulariosCadastroComponent
    ],
    providers: [
        FormulariosHttpService
    ]
})
export class FormulariosModule { }
