import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseVisualizacaoComponent } from '../../componentes-base';
import { Condominio, Cliente } from '../../models';

import { AuthService, MessageService } from '../../core';
import { CondominiosHttpService } from './services';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { isNull } from 'util';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'condominios',
  templateUrl: './condominios.component.html',
  styleUrls: ['./condominios.component.css'],
  providers: [PermissionService]
})
export class CondominiosComponent extends BaseVisualizacaoComponent<Condominio> implements OnInit {

  private cliente: Cliente;

  constructor(activatedRoute: ActivatedRoute,
      router: Router,
      messageService: MessageService,
      private authService: AuthService,
      private condominiosHttpService: CondominiosHttpService,
      permissionService: PermissionService,
      public spinner: NgxSpinnerService) {
    super('#tableCondominios', condominiosHttpService, activatedRoute, router, messageService, permissionService, spinner);
  }

  ngOnInit() {
    this.cliente = this.authService.getCurrentClient();
    super.ngOnInit();
  }

  insert() {
    if (!isNull(this.cliente.limite_condominios) && this.entities.length >= this.cliente.limite_condominios) {
      this.messageService.warning(`Atingido o número máximo de ${this.cliente.limite_condominios} condomínios!!`, 'Atenção');
    } else {
      super.insert();
    }
  }

  // Carrega todos os condominios do condomínio selecionado
  getAll() {
    this.entities = [];
    this.destroyTable();
    this.condominiosHttpService.getAllByCliente(this.authService.getIdCurrentCliente())
      .subscribe(
        entities => {
          this.entities = entities;
          this.showTable();
        },
        error => this.messageService.error('Erro ao carregar os condominios do condomínio. Tente novamente', 'Erro', error)
      );
  }

  delete(condominio: Condominio) {
    if (confirm(`Tem certeza que deseja excluir o condomínio ${condominio.nome_fantasia}?`)) {
      this.condominiosHttpService.delete(condominio)
        .subscribe(
          () => {
            this.messageService.success('', `O condomínio ${condominio.nome_fantasia} foi excluido com sucesso`);
            this.getAll();
          },
          error => {
            switch (error.status) {
              case 403:
                this.messageService.warning(`O condomínio ${condominio.nome_fantasia}, não pode ser excluído pois o mesmo possui lançamentos no financeiro.`, 'Atenção', error);
                break;
              case 404:
                this.messageService.warning(`Condomínio não encontrado, atualize a consulta e tente novamente.`, 'Atenção', error);
                break;
              case 500:
                this.messageService.error(`Erro ao excluir o condomínio ${condominio.nome_fantasia}. Tente novamente`, 'Erro', error);
                break;
              default:
                break;
            }
          }
        );
    }
  }
}