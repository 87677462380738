import { Component } from '@angular/core';

import { ConfirmService } from '../componentes-base/confirm/confirm.service';

@Component({
	selector: 'home',
	templateUrl: 'home.template.html',
	styleUrls: ['./home.component.css'],
	providers: [
		ConfirmService
	]
})

export class HomeComponent {
	constructor(private _confirmService: ConfirmService) { }

	showConfirmDialog() {
		this._confirmService.activate("Are you sure?")
			.then(res => console.log(`Confirmed: ${res}`));
	}
}