
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { _throw } from 'rxjs/observable/throw';

import { CategoriaContas } from '../../../../models';
import { AuthService, Crud, CrudService, ConfigService, CustomHttp } from '../../../../core';
import { AtivaDesativaCategoriaContas } from '../ativa-desativa-categoria-contas';

import { CondominiosConfiguracaoHttpService } from '../../../condominios-configuracao/services/condominios-configuracao-http.service';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class CategoriaContasHttpService implements Crud<CategoriaContas> {
	resource = 'categoriacontas';
	private baseUrl: string;

	constructor(private customHttp: CustomHttp,
			private authService: AuthService,
			private crudService: CrudService,
			private configService: ConfigService,
			private condominiosConfiguracaoHttpService: CondominiosConfiguracaoHttpService) {
		this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
	}

	get(id: number): Observable<CategoriaContas> {
		return this.crudService.get<CategoriaContas>(this.resource, id);
	}

	getAll(): Observable<CategoriaContas[]> {
		return this.crudService.getAll<CategoriaContas>(this.resource);
	}

	post(entity: CategoriaContas): Observable<CategoriaContas> {
		return this.crudService.post<CategoriaContas>(this.resource, entity);
	}

	put(entity: CategoriaContas): Observable<CategoriaContas> {
		return this.crudService.put<CategoriaContas>(this.resource, entity);
	}

	delete(entity: CategoriaContas): Observable<string> {
		return this.crudService.delete<CategoriaContas>(this.resource, entity);
	}

	public getFilhos(id: number): Observable<CategoriaContas[]> {
		return this.customHttp.get(`${this.baseUrl}/consultar/filhos/${id}`).pipe(
			map(response => {
				const entities: CategoriaContas[] = response.json();
				// Percorremos as categorias retornadas para criar a propriedade "categoria_contas_filhas"
				// que não vem no JSON e somente é utilizada aqui
				entities.forEach(entitiy => {
					entitiy.categoria_contas_filhas = [];
				});
				return entities;
			}),
			catchError(error => Observable.throw(error.json()))
		);
	}

	/**
	 * Consulta todas as categorias pelo Plano de Contas
	 */
	public getAllByPlanoDeContas(idPlanoDeContas: number, tipo?: number): Observable<CategoriaContas[]> {
		if (idPlanoDeContas) {
			return this.customHttp.get(`${this.baseUrl}/consultar/planodecontas/${idPlanoDeContas}`, { params: { tipo } }).pipe(
				map(response => response.json()),
				catchError(error => _throw(error))
			);
		} else {
			return null;
		}
	}

	/**
	 * Carrega todas as categorias de contas do plano de contas cadastrado na configuração do condomínio informado
	 */
	public getAllByCondominio(idCondominio: number, tipo?: number): Promise<CategoriaContas[]> {
		return new Promise<CategoriaContas[]>((resolve, reject) => {
			// 1 - Carrega a config. do condomínio para obter o plano de contas
			this.condominiosConfiguracaoHttpService.getByCondominio(idCondominio).subscribe(
				entity => {
					if (entity && entity.id_plano_de_contas) {
						// 2 - Carrega as categorias de contas do plano de contas
						this.getAllByPlanoDeContas(entity.id_plano_de_contas, tipo).subscribe(
							entities => resolve(entities),
							error => {
								reject(error);
							}
						);
					} else {
						resolve([]);
					}
				},
				error => reject(error)
			);
		});
	}

	public ativaDesativa(id: number, ativo: boolean): Observable<Response> {
		const entity = new AtivaDesativaCategoriaContas(this.authService.getCurrentUser().id, ativo);
		return this.customHttp.put(`${this.baseUrl}/ativardesativar/${id}`, entity).pipe(
			map(response => response.json()),
			catchError(error => Observable.throw(error.json()))
		);
	}

	/**
	 * Busca plano de contas completo do condomínio
	 * @param id_condominio 
	 * @author Marcos Frare
	 * @since 02/11/2020
	 */
	public getPlanoCompletoCondominio(id_condominio: number): Observable<any> {
		return this.customHttp.get(`${this.baseUrl}/arvore/${id_condominio}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Buscar as contas com movimento no periodo
	 * @param id_condominio 
	 * @param competencia_inicial 
	 * @param competencia_final 
	 * @since 05/07/2023
	 */
	public getCategoriasMovimentoPrevisaoOrcamentaria(id_condominio: number, competencia_inicial: Date = null, competencia_final: Date = null): Observable<CategoriaContas[]> {
		return this.customHttp.get(`${this.baseUrl}/previsao-orcamentaria/movimentacao/${id_condominio}`, { params: {competencia_inicial, competencia_final}}).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

}