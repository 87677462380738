import { map, catchError } from 'rxjs/operators';

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { _throw } from 'rxjs/observable/throw';

import { AuthService, ConfigService, CustomHttp } from '../../../../core';
import { RateioUnidadeLancamento } from '../lancamento';
import { RateioUnidadeEnvio } from '../rateio-unidade-envio';

@Injectable()
export class RateiosUnidadesHttpService {
	private baseUrl: string;

	constructor(private customHttp: CustomHttp,
		private authService: AuthService,
		private configService: ConfigService) {
		this.baseUrl = `${configService.getBaseUrl()}/rateiounidade`;
	}

	gerarBoletosEArquivoRemessaDoRateio(idDemonstrativoRateio: number): Observable<Response> {
		return this.customHttp.get(`${this.baseUrl}/lancamento/demonstrativorateio/${idDemonstrativoRateio}/boleto/verso`);
	}

	/**
	 * Gera o lançamento dos rateios das unidades para o Demonstrativo Rateio informado
	 */
	gerarLancamento(idDemonstrativoRateio: number, pagamentos: any, cobrarFundoReserva: boolean): Observable<RateioUnidadeLancamento[]> {
		return this.customHttp.post(`${this.baseUrl}/lancamento/demonstrativorateio/${idDemonstrativoRateio}`, {
			pagamentos: pagamentos.id_pagamentos,
			pagamentos_especificos: pagamentos.id_pagamentos_especificos,
			cobrar_fundo_reserva: cobrarFundoReserva
		})
			.pipe(
				map(response => response.json()),
				catchError(error => _throw(error))
			);
	}

	/** Salva pela primeira vez os rateios das unidades informadas */
	cadastrar(rateios: RateioUnidadeLancamento[]): Observable<Response> {
		const entity = new RateioUnidadeEnvio(this.authService.getCurrentUser().id, this.authService.getCurrentCondominio().id, rateios);
		return this.customHttp.post(`${this.baseUrl}/cadastrar`, entity)
			.pipe(
				map(response => response),
				catchError(error => _throw(error))
			);
	}

	/** Salva a edição dos rateios das unidades informadas */
	editar(idDemonstrativoRateio: number, rateios: RateioUnidadeLancamento[]): Observable<Response> {
		const entity = new RateioUnidadeEnvio(this.authService.getCurrentUser().id, this.authService.getCurrentCondominio().id, rateios);
		return this.customHttp.put(`${this.baseUrl}/editar/${idDemonstrativoRateio}`, entity)
			.pipe(
				map(response => response),
				catchError(error => _throw(error))
			);
	}

	gerarTitulos(idDemonstrativoRateio: number, idUnidade?: number): Observable<Response> {
		let idCondominio = this.authService.getCurrentCondominio().id;
		let requestUrl: string = `${this.configService.getBaseUrl()}/boleto/gerar/condominio/${idCondominio}/rateio/${idDemonstrativoRateio}/unidades`;
		idUnidade ? requestUrl = `${requestUrl}/${idUnidade}` : requestUrl;
		return this.customHttp.get(requestUrl)
			.pipe(
				map(response => response),
				catchError(error => _throw(error))
			);
	}

	getResumoLancamento(lancamento: RateioUnidadeLancamento): Observable<Response> {
		let idCondominio = this.authService.getCurrentCondominio().id;
		return this.customHttp
			.get(`${this.configService.getBaseUrl()}/demonstrativorateio/${lancamento.id_demonstrativo_rateio}/unidade/${lancamento.unidade.id}/condominio/${idCondominio}`)
			.pipe(
				map(response => response.json()),
				catchError(error => _throw(error))
			);
	}

	/**
	 * Valuidação da geração dos titulos de um rateio
	 * @param id_demonstrativo_rateio ID do rateio
	 * @author Marcos Frare
	 * @since 11/05/2020
	 */
	public validarGeracaoTitulo(id_demonstrativo_rateio: number): Observable<any> {
		return this.customHttp.get(`${this.baseUrl}/validageracaotitulo/${id_demonstrativo_rateio}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

}