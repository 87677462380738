import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared';
import { TypeaheadModule } from 'ngx-bootstrap';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';

import { ConsumosRelComponent } from './consumos-rel.component';
import { BlocosHttpService } from 'app/home/blocos';

@NgModule({
  imports: [
    SharedModule,
    TypeaheadModule,
    MultiselectDropdownModule
  ],
  declarations: [
    ConsumosRelComponent
  ],
  providers: [
    BlocosHttpService
  ]
})
export class ConsumosRelModule { }
