import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseCadastroComponent } from '../../../componentes-base';
import { AuthService, MessageService } from '../../../core';
import { CondominiosConfiguracaoHttpService } from '../../condominios-configuracao';
import { TiposConsumoHttpService } from '../services';

import { Condominio, TipoConsumo, abrangenciaTiposConsumo, unidadesMedida, CategoriaTarifaConsumo } from '../../../models';
import { CategoriaTarifasConsumoCadastroModalComponent, TiposConsumoTarifasHttpService } from '../tarifas';
import { CondominiosHttpService } from '../../condominios';
import { PermissionService } from '../../../core/services/permission/permission.service';
import { TiposConsumoHistoricoComponent } from '../historico';
import { isNull } from 'util';

@Component({
    selector: 'tipos-consumo-cadastro',
    templateUrl: './tipos-consumo-cadastro.component.html',
    styleUrls: ['./tipos-consumo-cadastro.component.css'],
    providers: [
        CondominiosHttpService,
        CondominiosConfiguracaoHttpService,
        PermissionService
    ]
})

export class TiposConsumoCadastroComponent extends BaseCadastroComponent<TipoConsumo> implements OnInit{

    condominio: Condominio;
    public arTipoConsumo: TipoConsumo[] = [];
    public tiposConsumo: TipoConsumo[] = [];
    public medidas = unidadesMedida;
    public escopoCliente: boolean;
    public arCategoriaTarifaConsumo: CategoriaTarifaConsumo[] = [];
    public abrangenciaTiposConsumo = abrangenciaTiposConsumo;

    @ViewChild('historicoTipoConsumoModal') historicoTipoConsumoModal: TiposConsumoHistoricoComponent;
    @ViewChild('categoriaTarifaConsumoCadastroModal') categoriaTarifaConsumoCadastroModal: CategoriaTarifasConsumoCadastroModalComponent;
    
    constructor(public activatedRoute: ActivatedRoute,
            public router: Router,
            public messageService: MessageService,
            private authService: AuthService,
            private condominiosHttpService: CondominiosHttpService,
            private tiposConsumoHttpService: TiposConsumoHttpService,
            private tiposConsumoTarifasHttpService: TiposConsumoTarifasHttpService,
            public permissionService: PermissionService) {
        super(TipoConsumo, tiposConsumoHttpService, activatedRoute, router, messageService, permissionService);
    }

    ngOnInit() {
        this.escopoCliente = isNull(this.authService.getIdCurrentCondominio());
        super.ngOnInit();
        this.entity.id_cliente = this.authService.getIdCurrentCliente();
        this.entity.id_condominio = this.authService.getIdCurrentCondominio();
        this.entity.abrangencia = isNull(this.entity.id_condominio) ? 1 : 2;
        this.getTipoConsumo();
    }

    // Carrega o condomínio que é o "dono" do tipo de consumo
    private getCondominio(idCondominio: number) {
        this.condominiosHttpService.get(idCondominio)
            .subscribe(
                entity => {
                    this.condominio = entity;
                },
                error => {
                    this.messageService.error('Erro ao carregar o condomínio', 'Erro', error)
                }
            );
    }

    /**
     * Filtra os tipos de consumo para dependência
     * @author Marcos Frare
     * @since 22/04/2020
     */
    public filterDependence(): void {
        this.arTipoConsumo = this.tiposConsumo.filter(item => (item.id !== this.entity.id && item.abrangencia === this.entity.abrangencia));
    }

    public getTipoConsumo(): void {
        // Sempre buscar todas as tabelas do cliente
        this.tiposConsumoHttpService.getAllTipos(true)
            .subscribe(
                entities => {
                    this.tiposConsumo = entities;
                    this.filterDependence();
                },
                error => this.messageService.error('Erro carregar tabelas de serviço do condomínio. Tente novamente', 'Erro', error)
            );
    }

    afterSave(entity: TipoConsumo) {
        this.editMode = true;
        this.entity = entity;
        this.btnSave.button('reset');
    }

    /**
     * Busca as categorias e suas tarifas configuradas
     * @author Marcos Frare
     * @since 21/02/2020
     */
    private getAllTarifas() {
        if (!this.entity) {
            return;
        }
        this.tiposConsumoTarifasHttpService.getAllByTipoConsumo(this.entity.id)
            .subscribe(
                entities => {
                    this.arCategoriaTarifaConsumo = entities;
                },
                error => this.messageService.error('Erro ao carregar as tarifas do tipo de consumo. Tente novamente', 'Erro', error)
            );
    }

    public openHistorico(): void {
        this.historicoTipoConsumoModal.open();
    }

    afterInsert() {
        this.entity.id_cliente = this.authService.getIdCurrentCliente();
        this.entity.id_condominio = this.authService.getIdCurrentCondominio();
        if (!this.escopoCliente) {
            this.getCondominio(this.entity.id_condominio);
        }
    }

    afterEdit(entity: TipoConsumo) {
        if (!this.escopoCliente) {
            this.entity.id_condominio = this.authService.getIdCurrentCondominio();
            this.getCondominio(this.entity.id_condominio);
        }
        this.getAllTarifas();
    }

    /**
     * Abrir modal para adicionar nova categoria
     * @author Marcos Frare
     * @since 20/02/20
     */
    addCategoriaTipoConsumo(): void {
        const categoria = new CategoriaTarifaConsumo();
        categoria.id_tipo_consumo = this.entity.id;
        this.categoriaTarifaConsumoCadastroModal.edit(new CategoriaTarifaConsumo());
    }

    /**
     * Editar a categoria e plano tarifário
     * @param entity Categoria a ser editada
     * @author Marcos Frare
     * @since 20/02/2020
     */
    editCategoriaTipoConsumo(entity: CategoriaTarifaConsumo) {
        this.categoriaTarifaConsumoCadastroModal.edit(entity);
    }

    /**
     * Remover a categoria e plano tarifário
     * @param entity Categoria a ser removida
     * @author Marcos Frare
     * @since 20/02/2020
     */
    removeCategoriaTipoConsumo(entity: CategoriaTarifaConsumo) {
        if (window.confirm(`Esta ação excluirá o plano tarifário desta categoria.\nTem certeza que deseja excluir a categoria?`)) {
            this.tiposConsumoTarifasHttpService.exlcuirCategoria(entity)
                .subscribe(
                    response => {
                        this.messageService.success('', 'Categoria excluida com sucesso');
                        // Atualiza as tarifas
                        this.getAllTarifas();
                    },
                    error => this.messageService.error('Erro ao excluir a categoria. Tente novamente!', 'Erro', error)
                );
        }
    }

}