import { BaseEntity } from "../base-entity";

export class CategoriaContaGrupo extends BaseEntity {
	id_condominio: number;
	tipo: string;
	nome: string;
	
	constructor() {
		super();
	}
}