import { Injectable } from '@angular/core';
import { AuthService, ConfigService, Crud, CrudService, CustomHttp } from 'app/core';
import { ReservaCalendarioBloqueio } from 'app/models';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs-compat/observable/throw';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReservaBloqueioHttpService implements Crud<ReservaCalendarioBloqueio> {

  public resource = 'reservas/bloqueio';
	private baseUrl: string;
	private baseAcessoUrl: string;

  constructor(private customHttp: CustomHttp,
      private crudService: CrudService,
      private configService: ConfigService,
      private authService: AuthService) {
    this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
		this.baseAcessoUrl = `${configService.getBaseUrl()}/${this.resource}`;
  }

  public get(id: number): Observable<ReservaCalendarioBloqueio> {
		return this.crudService.get<ReservaCalendarioBloqueio>(this.resource, id);
	}

	public getAll(): Observable<ReservaCalendarioBloqueio[]> {
		return this.crudService.getAll<ReservaCalendarioBloqueio>(this.resource, { params: { id_condominio: this.authService.getIdCurrentCondominio() } });
	}

	public post(entity: ReservaCalendarioBloqueio): Observable<ReservaCalendarioBloqueio> {
		return this.crudService.post<ReservaCalendarioBloqueio>(this.resource, entity);
	}

	public put(entity: ReservaCalendarioBloqueio): Observable<ReservaCalendarioBloqueio> {
		return this.crudService.put<ReservaCalendarioBloqueio>(this.resource, entity);
	}

	public delete(entity: ReservaCalendarioBloqueio): Observable<string> {
		return this.crudService.delete<ReservaCalendarioBloqueio>(this.resource, entity);
	}

  /**
   * Consultar bloqueios pelo filtro
   * @param filtro 
   * @returns 
   * @author Marcos Frare
   * @since 28/05/2021
   */
  public getByFiltro(filtro: any): Observable<ReservaCalendarioBloqueio[]> {
    return this.customHttp.get(`${this.baseUrl}/consultar`, {params: filtro}).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
  }
}