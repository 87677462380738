import { Pipe, PipeTransform } from '@angular/core';

import {TIPO_REMETENTE, tiposRemetentes} from '../../../models';

@Pipe({
    name: 'tiposRemetentes'
})
export class TiposRemetentesPipe implements PipeTransform {
    transform(value: any[], tipo: string, condominio?: any): any[] {
        if (!value) {
            return [];
        };
        switch (tipo) {
            case 'funcionarioTodos':
                return tiposRemetentes.filter(destinatario => (
                    destinatario.id === TIPO_REMETENTE.ADMINISTRADORA));
            case 'funcionarioSindico':
                if (condominio) {
                    return tiposRemetentes.filter(destinatario => (
                        destinatario.id === TIPO_REMETENTE.CONDOMINIO ||
                        destinatario.id === TIPO_REMETENTE.SINDICO));
                } else {
                    return tiposRemetentes.filter(destinatario => (
                        destinatario.id === TIPO_REMETENTE.SINDICO));
                }
            case 'funcionarioNormal':
                if (condominio) {
                    return tiposRemetentes.filter(destinatario => (
                        destinatario.id === TIPO_REMETENTE.CONDOMINIO ||
                        destinatario.id === TIPO_REMETENTE.ADMINISTRADORA));
                } else {
                    return tiposRemetentes.filter(destinatario => (
                        destinatario.id === TIPO_REMETENTE.ADMINISTRADORA));
                }
            case 'condominoSindico':
                return tiposRemetentes.filter(destinatario => (
                    destinatario.id === TIPO_REMETENTE.CONDOMINIO ||
                    destinatario.id === TIPO_REMETENTE.CONDOMINO ||
                    destinatario.id === TIPO_REMETENTE.SINDICO));
            case 'condominoNormal':
                return tiposRemetentes.filter(destinatario => (
                    destinatario.id === TIPO_REMETENTE.CONDOMINO));
            default:
                return [];
        }
    }
}
