import { Injectable } from '@angular/core';
import { ConfigService, Crud, CrudService, CustomHttp } from 'app/core';
import { Veiculo } from 'app/models';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs-compat/observable/throw';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VeiculoHttpService implements Crud<Veiculo> {

  resource: string = 'veiculo';
  private baseUrl: string;
  private url: string;

  constructor(private customHttp: CustomHttp,
      private crudService: CrudService,
      private configService: ConfigService) {
    this.url = configService.getBaseUrl();
    this.baseUrl = `${this.url}/${this.resource}`;
  }

  get(id: number): Observable<Veiculo> {
    return this.crudService.get<Veiculo>(this.resource, id);
  }
  getAll(): Observable<Veiculo[]> {
    return this.crudService.getAll<Veiculo>(this.resource);
  }
  post(entity: Veiculo): Observable<Veiculo> {
    return this.crudService.post<Veiculo>(this.resource, entity);
  }
  put(entity: Veiculo): Observable<Veiculo> {
    return this.crudService.put<Veiculo>(this.resource, entity);
  }
  delete(entity: Veiculo): Observable<string> {
    return this.crudService.delete<Veiculo>(this.resource, entity);
  }

  /**
   * Obter veículo pela placa
   * @param placa 
   * @author Marcos Frare
   * @since 16/04/2021
   */
  public obterPorPlaca(placa: string): Observable<Veiculo> {
    return this.customHttp.get(`${this.baseUrl}/placa`, {params:{placa}}).pipe(
      map(response => response.json()),
			catchError(error => _throw(error))
    );
  }
}