import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';

import { Crud, CrudService, ConfigService, CustomHttp } from '../../../core';
import { Inventario, LocalUtensilio } from '../../../models';

@Injectable()
export class InventariosHttpService implements Crud<Inventario> {
    resource = 'inventario';
    private baseUrl: string;

    constructor(private customHttp: CustomHttp,
                private crudService: CrudService,
                private configService: ConfigService) {
        this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
    }

    get(id: number): Observable<Inventario> {
        return this.crudService.get<Inventario>(this.resource, id);
    }

    getAll(): Observable<Inventario[]> {
        return this.crudService.getAll<Inventario>(this.resource);
    }

    post(entity: Inventario): Observable<Inventario> {
        return this.crudService.post<Inventario>(this.resource, entity);
    }

    put(entity: Inventario): Observable<Inventario> {
        return this.crudService.put<Inventario>(this.resource, entity);
    }

    delete(entity: Inventario): Observable<string> {
        return this.crudService.delete<Inventario>(this.resource, entity);
    }

    getAllByCondominio(idCondominio: number): Observable<Inventario[]> {
        return this.customHttp.get(`${this.baseUrl}/consultar`, { params: {id_condominio: idCondominio} })
                    .pipe(
                        map(response => response.json()),
                        catchError(error => _throw(error))
                    );
    }

    search(params: any): Observable<Inventario[]> {
        return this.customHttp.get(`${this.baseUrl}/consultar`, { params })
                    .pipe(
                        map(response => response.json()),
                        catchError(error => _throw(error))
                    );
    }

    /**
     * Busca os ítens de inventário ainda não relacionados com locais
     * @param id_condominio ID do condomínio
     * @param id_local_utensilio ID do localUtensilio no caso de edição
     * @author Marcos Frare
     * @since 12/03/2020
     */
    getNotAlocateAllByCondominio(id_condominio: number, id_local_utensilio: number): Observable<Inventario[]> {
        return this.customHttp.get(`${this.baseUrl}/consultar-ambiente`, { params: {id_condominio, id_local_utensilio} })
                    .pipe(
                        map(response => response.json()),
                        catchError(error => _throw(error))
                    );
    }

}
