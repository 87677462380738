import { CategoriaContas } from "app/models";

export class PagamentosFiltro {
    id_condominio: number;
    data_vencimento_inicial: Date;
    data_vencimento_final: Date;
    id_categoria_conta: number;
    id_fornecedor: number;
    forma_pagamento: number;
    valor_operacao: string;
    valor: number;
    valor_ate: number;
    
    categoriaContas: CategoriaContas;

    /** Limpa o filtro */
    limpar() {
        const today = new Date();
        this.data_vencimento_inicial = new Date(today.getFullYear(), today.getMonth(), 1);
        this.data_vencimento_final = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        this.id_categoria_conta = null;
        this.id_fornecedor = null;
        this.forma_pagamento = null;
        this.valor_operacao = '';
        this.valor = null;
        this.valor_ate = null;

        this.categoriaContas = null;
    }
}