import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared';
import { ComparativoFinanceiroRelComponent } from './comparativo-financeiro-rel.component';

@NgModule({
	imports: [
		SharedModule
	],
	declarations: [
		ComparativoFinanceiroRelComponent
	],
	exports: [],
	providers: []
})
export class ComparativoFinanceiroRelModule { }