import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import { AuthService, MessageService } from 'app/core';
import { UnidadesHttpService } from 'app/home/blocos';
import { CondominiosConfiguracaoHttpService } from 'app/home/condominios-configuracao';
import { CategoriaContasHttpService, CategoriaContasPesquisaModalComponent } from 'app/home/plano-de-contas';
import { CategoriaContas, LancamentoCondominioItem, LancamentosCondominio, ParcelaDespesaRateio, tiposDivisaoRateio, tiposUnidade, Unidade } from 'app/models';
import { TypeaheadMatch } from 'ngx-bootstrap';

@Component({
  selector: 'lancamento-condominio-item',
  templateUrl: './lancamento-condominio-item.component.html',
  styleUrls: ['./lancamento-condominio-item.component.css']
})
export class LancamentoCondominioItemComponent implements OnInit {

  @Input() lancamento: LancamentosCondominio;
	@Output() changeItem: EventEmitter<LancamentoCondominioItem> = new EventEmitter();

  @ViewChild('categoriaContasPesquisaModal') categoriaContasPesquisaModal: CategoriaContasPesquisaModalComponent;
  @ViewChild('categoriaContasPesquisaNaoRateadoModal') categoriaContasPesquisaNaoRateadoModal: CategoriaContasPesquisaModalComponent;
	
  public multiselectSettings: IMultiSelectSettings = {
		enableSearch: true,
		containerClasses: 'container-multiselect',
		showUncheckAll: true,
		showCheckAll: true,
		dynamicTitleMaxItems: 3,
		checkedStyle: 'checkboxes',
		buttonClasses: 'btn btn-default',
		itemClasses: 'dropdown-menu'
	};
	public multiselectTextsUnidades: IMultiSelectTexts = {
		checkAll: 'Selecionar todas',
		uncheckAll: 'Limpar',
		checked: 'selecionada',
		checkedPlural: 'selecionadas',
		searchPlaceholder: 'Pesquisar...',
		defaultTitle: 'Selecionar',
		allSelected: 'Todas selecionadas',
		searchEmptyResult: 'Nenhuma unidade do tipo',
		searchNoRenderText: 'Digite para pesquisar',
	};

  public multiselectOptionsUnidades: IMultiSelectOption[];

  public categoriasContasReceita: CategoriaContas[] = [];
  public categoriasContasDespesa: CategoriaContas[] = [];
  public unidades: Unidade[] = [];
  public tiposUnidade = tiposUnidade;
  public tiposDivisaoRateio = tiposDivisaoRateio;
	
  // Variável para carregar o item atual para edição de campos
  public currentItem: LancamentoCondominioItem = null;

  constructor(private authService: AuthService,
    private messageService: MessageService,
    private categoriaContasHttpService: CategoriaContasHttpService,
    private unidadesHttpService: UnidadesHttpService,
		private condominiosconfiguracaoHttpService: CondominiosConfiguracaoHttpService) { }

  ngOnInit() {
		this.getAllUnidades();
    this.getAllCategoriasByCondominio();
		this.reloadTipoDivisaoRateio();
  }

	/**
	 * Recarrega as opções de rateio conforme configuração do condomínio
	 * @author Marcos Frare
	 * @since 04/02/2020
	 */
	 private reloadTipoDivisaoRateio(): void {
		this.condominiosconfiguracaoHttpService.getByCondominio(this.authService.getIdCurrentCondominio()).subscribe(
			response => {
				if (response.tipo_rateio !== 1) {
					this.tiposDivisaoRateio = [];
					for (let oTipo of tiposDivisaoRateio) {
						if (oTipo.id !== 5) {
							this.tiposDivisaoRateio.push(oTipo);
						}
					}
				}
			}
		);
	}

  /**
   * Busca as categorias de contas do condomínio
   * @author Marcos Frare
   * @since 01/09/2021
   */
  private getAllCategoriasByCondominio(): void {
		this.categoriasContasReceita = [];
		this.categoriasContasDespesa = [];
    this.categoriaContasHttpService.getAllByCondominio(this.authService.getIdCurrentCondominio()).then(entities => {
      this.categoriasContasReceita = entities.filter(conta => conta.tipo_conta == 1);
      this.categoriasContasDespesa = entities.filter(conta => conta.tipo_conta == 2);
    });
	}

  /**
	 * Carregar todas as unidades do condomínio
	 * @author Marcos Frare
	 * @since 23/08/2021
	 */
	private getAllUnidades():void {
		this.unidades = [];
		this.unidadesHttpService.getAllByCondominioBloco(this.authService.getIdCurrentCondominio(), null).subscribe(
			response => {
				this.unidades = response;
				this.lancamento.lancamentoCondominioItems.map(item => {
					this.getUnidadesByTipo(item);
				});
			},
			error => {
				this.messageService.error('Erro ao carregar as unidades do condomínio! Tente novamente.', 'Erro!', error);
			}
		);
	}

  /**
	 * Carregar as opções de unidades pelo tipo
	 * @author Marcos Frare
	 * @since 01/09/2021
	 */
	public getUnidadesByTipo(item: LancamentoCondominioItem): void {
		item.multiselectOptionsUnidades = (item.cobrar_tipo_unidade != 100 ? this.unidades.filter(un => un.tipo == item.cobrar_tipo_unidade) : this.unidades).map(uni => {
			return {
				id: uni.id,
				name: `${uni.nome_bloco} - ${uni.nome_unidade}`
			};
		});
	}

  /**
   * Adiciona novo item ao lançamento
   * @author Marcos Frare
   * @since 01/09/02021
   */
  public addItem(): void {
    let item = new LancamentoCondominioItem();
    this.getUnidadesByTipo(item);
    this.lancamento.lancamentoCondominioItems.unshift(item);
  }

  /**
   * Excluir item
   * @param index - Posição do item no array
   * @author Marcos Frare
   * @since 01/09/2021
   */
  public removeItem(index: number): void {
    this.lancamento.lancamentoCondominioItems.splice(index, 1);
    if (this.lancamento.lancamentoCondominioItems.length < 1) {
      this.lancamento.lancamentoCondominioItems.push(new LancamentoCondominioItem());
    }
		this.changeItem.emit();
  }

  /**
	 * Evento de alteração do tipo de divisão do Rateio
	 * @author Marcos Frare
	 * @since 01/09/2021
	 */
	public changeTipoRateio(item: LancamentoCondominioItem): void {
		item.categoriaContaNaoRateado = new CategoriaContas();
		item.id_categoria_conta_nao_rateado = null;
		// Se for selecionado para não ratear
		if ([4, 5].includes(item.tipo_divisao_rateio)) {
			item.parcelas_despesa_rateio = [];
		} else {
			if (!item.parcelas_despesa_rateio.length) {
				let parcela = new ParcelaDespesaRateio();
				parcela.valor = item.valor;
				parcela.numero_parcela = 1;
				item.parcelas_despesa_rateio.push(parcela);
			}
		}
	}

  public onSelectCategoriaContas(tm: TypeaheadMatch, item: LancamentoCondominioItem): void {
    const categoriaConta: CategoriaContas = tm.item as CategoriaContas;
		if (categoriaConta) {
      item.id_categoria_conta = categoriaConta.id;
      item.categoriaConta = categoriaConta;
      if (!this.lancamento.nome) {
        this.lancamento.nome = categoriaConta.nome;
      }
		} else {
			item.id_categoria_conta = null;
      item.categoriaConta = null;
		}
	}

	public onCleanCategoriaContas(input: any, item: LancamentoCondominioItem): void {
		item.id_categoria_conta = null;
    item.categoriaConta = null;
		input.value = '';
		input.focus();
	}

	public openCategoriaContasPequisaModal(item: LancamentoCondominioItem): void {
    this.currentItem = item;
		this.categoriaContasPesquisaModal.open();
	}

	public onSelectCategoriaContasPesquisaModal(categoriaConta: CategoriaContas): void {
		if (categoriaConta && this.currentItem) {
			this.currentItem.id_categoria_conta = categoriaConta.id;
			this.currentItem.categoriaConta = categoriaConta;
      if (!this.lancamento.nome) {
        this.lancamento.nome = categoriaConta.nome;
      }
		}
	}

	public onSelectCategoriaContaNaoRateado(tm: TypeaheadMatch, item: LancamentoCondominioItem): void {
		const categoriaConta: CategoriaContas = tm.item as CategoriaContas;
		if (categoriaConta) {
			item.id_categoria_conta_nao_rateado = categoriaConta.id;
			item.categoriaContaNaoRateado = categoriaConta;
		} else {
			item.id_categoria_conta_nao_rateado = null;
			item.categoriaContaNaoRateado = null;
		}
	}

	public onCleanCategoriaContaNaoRateado(input: any, item: LancamentoCondominioItem): void {
		item.id_categoria_conta_nao_rateado = null;
		item.categoriaContaNaoRateado = null;
		input.value = '';
		input.focus();
	}

	public openCategoriaContaNaoRateadoPequisaModal(item: LancamentoCondominioItem): void {
    this.currentItem = item;
		this.categoriaContasPesquisaNaoRateadoModal.open();
	}

	public onSelectCategoriaContasNaoRateadoPesquisaModal(categoriaContaNaoRateado: CategoriaContas): void {
		if (categoriaContaNaoRateado && this.currentItem) {
			this.currentItem.id_categoria_conta_nao_rateado = categoriaContaNaoRateado.id;
			this.currentItem.categoriaContaNaoRateado = categoriaContaNaoRateado;
		}
	}

	/**
	 * Ação de alteração do valor do item
	 * @author Marcos Frare
	 * @since 03/09/2021
	 */
	public changeValor(item: LancamentoCondominioItem): void {
		this.changeItem.emit();
		// Calcula as parcelas para rateio do item
		this.updateLancamentoItem(item);
	}

	/**
	 * Caso uma parcela já tenha sido rateada, não possibilida a edição da percela de rateio
	 * @author Marcos Frare
	 * @since 03/09/2021
	 */
	public editFormaRateio(item: LancamentoCondominioItem): boolean {
		if (item.parcelas_despesa_rateio && item.parcelas_despesa_rateio.length > 0) {
			for (let parcela of item.parcelas_despesa_rateio) {
				if (parcela.id_demonstrativo_rateio) {
					return true;
				}
			}
		}
		return false;
	}

	// ================================================================================================================================================================
	// Parcelamento para rateio
	// ================================================================================================================================================================
	
	/**
	 * Atualização do item de lançamento
	 * @param item 
	 * @param dataReferencia 
	 * @author Marcos Frare
	 * @since 03/09/2021
	 */
	public updateLancamentoItem(item: LancamentoCondominioItem, dataReferencia?: Date): void {
    // Se a despesa for para rateio, cria as parcelas de rateio
    if (![4, 5].includes(item.tipo_divisao_rateio)) {
      if (item.parcelas_despesa_rateio && item.parcelas_despesa_rateio.length > 0) {
        // Se a quantidade de parcelas do lancamento for maior que a quantidade de parcelas para o rateio, adiciona as parcelas
        if (item.qtd_parcela > item.parcelas_despesa_rateio.length) {
          let lastDate = new Date(item.parcelas_despesa_rateio[item.parcelas_despesa_rateio.length-1].data_vencimento);
          for (let i=item.parcelas_despesa_rateio.length+1; i<=item.qtd_parcela; i++) {
            let parcela = new ParcelaDespesaRateio();
            parcela.id_lancamento_condominio_item = item.id;
            parcela.numero_parcela = item.parcelas_despesa_rateio.length+1;
            parcela.data_vencimento = new Date(lastDate.getFullYear(), (lastDate.getMonth()+1), lastDate.getDate());
            parcela.valor = 0;
            item.parcelas_despesa_rateio.push(parcela);
  
            lastDate = new Date(parcela.data_vencimento);
          }
        } else {
          // Eliminar as parcelas excedentes
          item.parcelas_despesa_rateio = item.parcelas_despesa_rateio.filter(parc => parc.numero_parcela <= item.qtd_parcela);
        }
  
        let totalAberto = item.valor ? item.valor : 0;
        const parcelasRateadas = item.parcelas_despesa_rateio.filter(parc => parc.id_demonstrativo_rateio);
        const parcelasAbertas = item.parcelas_despesa_rateio.filter(parc => !parc.id_demonstrativo_rateio);
  
        for (let parcela of parcelasRateadas) {
          totalAberto -= parcela.valor;
        }
  
        const vlParcela = (totalAberto/parcelasAbertas.length).toFixed(2);
        for (let parcela of parcelasAbertas) {
          parcela.valor = Number(vlParcela);
          totalAberto -= Number(vlParcela);
        }
        if (totalAberto != 0) {
          parcelasAbertas[parcelasAbertas.length-1].valor += totalAberto;
        }
      } else {
        item.qtd_parcela = item.qtd_parcela;
        item.parcelas_despesa_rateio = [];
        let lastDate = dataReferencia || new Date();
        let valorAberto = item.valor ? item.valor : 0;
        for (let i=1; i<=item.qtd_parcela; i++) {
          let parcela = new ParcelaDespesaRateio();
          parcela.id_lancamento_condominio_item = item.id;
          parcela.numero_parcela = i;
          parcela.data_vencimento = i>1 ? new Date(lastDate.getFullYear(), (lastDate.getMonth()+1), lastDate.getDate()) : lastDate;
          parcela.valor = (item.valor / item.qtd_parcela).toFixed(2);
          item.parcelas_despesa_rateio.push(parcela);
  
          valorAberto -= parcela.valor;
          lastDate = parcela.data_vencimento;
        }
      }
    } else {
      item.parcelas_despesa_rateio = [];
    }
  }

	/**
   * Recalcular parcelas
   * @author Marcos Frare
   * @since 03/09/2021
   */
	 public changeValorParcela(item: LancamentoCondominioItem, parcela: ParcelaDespesaRateio): void {
    // Calcula valor em aberto
    let valorAberto = parseFloat(item.valor);
    let numParcAberto = 0;
    for (let parc of item.parcelas_despesa_rateio) {
      if (parc.numero_parcela <= parcela.numero_parcela) {
        valorAberto -= parc.valor;
      } else {
        numParcAberto++;
      }
    }
    if (valorAberto > 0) {
      // Calcula valor das parcelas posteriores
      const vlParcela = (valorAberto/numParcAberto).toFixed(2);

      // Atualiza valores nas parcelas
      for (let parc of item.parcelas_despesa_rateio) {
        if (parc.numero_parcela > parcela.numero_parcela) {
          parc.valor = Number(vlParcela);
          valorAberto -= Number(vlParcela);
        }
      }
    } else {
      // Excluir as próximas parcelas
      item.parcelas_despesa_rateio = item.parcelas_despesa_rateio.filter(parc => parc.numero_parcela <= parcela.numero_parcela);
    }

    // Ajuste de diferença de divisão
    if (valorAberto != 0) {
      item.parcelas_despesa_rateio[item.parcelas_despesa_rateio.length - 1].valor += valorAberto;
    }
  }

	/**
   * Alteração da data de vencimento da parcela do rateio
   * @param parcela 
   * @author Marcos Frare
   * @since 03/09/2021
   */
	 public onChangeDataVencimento(item: LancamentoCondominioItem, parcela: ParcelaDespesaRateio): void {
    // Ao alterar a data de vencimento da parcela, atualiza as parcelas posteriores mês a mês
    let lastDate = new Date();
    for (let parc of item.parcelas_despesa_rateio) {
      if (parc.numero_parcela > parcela.numero_parcela && parc.numero_parcela > 1) {
        parc.data_vencimento = new Date(lastDate.getFullYear(), (lastDate.getMonth()+1), lastDate.getDate());
      }
      lastDate = new Date(parc.data_vencimento);
    }
  }

  /**
   * Remover uma parcela
   * @param parcela 
   * @author Marcos Frare
   * @since 03/09/2021
   */
  public onRemoveParcela(item: LancamentoCondominioItem, parcela: ParcelaDespesaRateio): void {
    parcela.excluir = true;
    if (parcela.id_demonstrativo_rateio) {
      this.messageService.warning('Não é possível exlcuir parcela já rateada!', 'Atenção!');
    } else if (item.parcelas_despesa_rateio.length <= 1) {
      this.messageService.warning('Necessário ao menos uma parcela!', 'Atenção!');
    } else {
      // Parcelas já rateadas
      item.parcelas_despesa_rateio = item.parcelas_despesa_rateio.filter(parc => !parc.excluir);
      // Busca valor total em aberto
      let totalAberto = item.valor;
      let parcAberto = item.parcelas_despesa_rateio.length;
      for (let parc of item.parcelas_despesa_rateio) {
        if (parc.id_demonstrativo_rateio) {
          totalAberto -= parc.valor;
          parcAberto--;
        }
      }
      
      let valorParcela = totalAberto/parcAberto;
      valorParcela = Number(valorParcela.toFixed(2));

      let numParcela = 0;
      let total = item.valor;
      let lastDate = new Date();
      for (parcela of item.parcelas_despesa_rateio) {
        numParcela++;
        if (!parcela.id_demonstrativo_rateio) {
          parcela.numero_parcela = numParcela;
          if (parcela.numero_parcela != 1) {
            parcela.data_vencimento = new Date(lastDate.getFullYear(), (lastDate.getMonth()+1), lastDate.getDate());
          }
          parcela.valor = valorParcela;
        }

        total -= parcela.valor;
        lastDate = new Date(parcela.data_vencimento);
      }
      
      if (total != 0) {
        if (item.parcelas_despesa_rateio[item.parcelas_despesa_rateio.length -1].id_demonstrativo_rateio) {
          this.addParcela(item);
        } else {
          item.parcelas_despesa_rateio[item.parcelas_despesa_rateio.length -1].valor += total;
        }
      }
      // Atualiza quantidade de parcelas do rateio no lançamento
      item.qtd_parcela = item.parcelas_despesa_rateio.length;
    }
  }

  /**
   * Adiciona nova parcela
   * @author Marcos Frare
   * @since 03/09/2021
   */
  public addParcela(item: LancamentoCondominioItem): void {
    // Parcelas já rateadas (Não pode alterar);
    let parcelas: ParcelaDespesaRateio[] = [];
    let parcelasAberto: ParcelaDespesaRateio[] = [];
    let totalAberto = item.valor;
    // Separa parcelas abertas e parcelas já rateadas
    for (let parcela of item.parcelas_despesa_rateio) {
      if (parcela.id_demonstrativo_rateio) {
        parcelas.push(parcela);
        totalAberto -= parcela.valor;
      } else {
        parcelasAberto.push(parcela);
      }
    }

    // Valor das parcelas
    const valorParcela = (totalAberto / (parcelasAberto.length + 1)).toFixed(2);

    // Inclui as parcelas com valores e datas atualizadas
    let valorCalculado = 0;
    let lastDate = new Date();
    for (let parc of parcelasAberto) {
      valorCalculado += Number(valorParcela);
      parc.valor = Number(valorParcela);
      parcelas.push(parc);
      lastDate = new Date(parc.data_vencimento);
    }
    // Inclui a nova parcela com valor restante e data um mês posterior
    let parcela = new ParcelaDespesaRateio();
    parcela.id_lancamento_condominio_item = item.id;
    parcela.numero_parcela = (item.parcelas_despesa_rateio.length + 1);
    parcela.data_vencimento = new Date(lastDate.getFullYear(), (lastDate.getMonth() +1), lastDate.getDate());
    parcela.valor = totalAberto - valorCalculado;
    parcelas.push(parcela);

    item.parcelas_despesa_rateio = parcelas;
    item.qtd_parcela = parcelas.length
  }

  /**
   * Atualiza datas de vencimento
   * @param date 
   * @author Marcos Frare
   * @since 03/09/2021
   */
  public changeFirstDate(item: LancamentoCondominioItem, date: Date): void {
    if (item.parcelas_despesa_rateio && item.parcelas_despesa_rateio.length) {
      let parcela = item.parcelas_despesa_rateio[0];
      parcela.data_vencimento = date;
      this.onChangeDataVencimento(item, parcela);
    }
  }

}