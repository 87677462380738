import { Component } from '@angular/core';
import { BaseVisualizacaoComponent } from 'app/componentes-base';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { Cliente, tiposCliente } from '../../models';
import { MessageService, AuthService } from '../../core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientesHttpService } from './services';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
	selector: 'clientes',
	styleUrls: ['./clientes.component.css'],
	templateUrl: './clientes.component.html',
	providers: [ClientesHttpService, PermissionService]
})
export class ClientesComponent extends BaseVisualizacaoComponent<Cliente>{

	public entities: Cliente[];
	public filtro: any = {};
	public tiposCliente = tiposCliente;

	constructor(
		activatedRoute: ActivatedRoute,
		router: Router,
		messageService: MessageService,
		private authService: AuthService,
		private clientesHttpService: ClientesHttpService,
		permissionService: PermissionService,
		public spinner: NgxSpinnerService) {
		super('#tableClientes', clientesHttpService, activatedRoute, router, messageService, permissionService, spinner);
	}

	ngOnInit() {
		if (!this.authService.isAdmin) this.router.navigate(['/dashboard'], { relativeTo: this.activatedRoute });
		this.setInitialFilter();
		this.search();
	}

	public getAll(): void {
		this.entities = [];
		this.destroyTable();
		this.clientesHttpService.getAll().subscribe(
			entity => {
				this.entities = entity;
				this.showTable();
			},
			error => this.messageService.error('Erro ao carregar os lançamentos do condomínio selecionado. Tente novamente', 'Erro', error)
		);
	}

	public search(): void {
		this.entities = [];
		this.destroyTable();
		this.clientesHttpService.search(this.filtro).subscribe(
			entities => {
				this.entities = entities;
				this.showTable();
			},
			error => this.messageService.error('Erro ao carregar os lançamentos específicos. Tente novamente.', 'Erro', error)
		);
	}

	public onNewClienteClick(): void {
		this.router.navigate(['./new'], { relativeTo: this.activatedRoute });
	}

	public onEditClienteClick(cliente): void {
		this.router.navigate([`./${cliente.id}`], { relativeTo: this.activatedRoute });
	}

	public onRemoveClienteClick(cliente): void {
		const nome = cliente.nome;
		if (confirm(`Tem certeza que deseja excluir o cliente ${nome}?`)) {
			if (confirm(`Após confirmar, não há como recuperar os dados do cliente, excluir mesmo assim?`)) {
				if (cliente.id) { // Com id deleta do banco de dados
					this.clientesHttpService.delete(cliente)
						.subscribe(
							() => {
								const index = this.entities.findIndex(cli => cli.id === cliente.id);
								this.deleteOfList(index);
								this.messageService.success('', `Cliente ${nome} foi excluído com sucesso`);
							},
							error => this.messageService.error(`Erro ao excluir o cliente ${cliente}. Tente novamente`, 'Erro', error)
						);
				}
			}
		}
	}

	private deleteOfList(index: number): boolean {
		if (index > -1) {
			this.entities.splice(index, 1);
			return true;
		}
		return false;
	}

	private setInitialFilter(): void {
		this.filtro.tipo = 1;
	}

}
