import { Component, ElementRef, OnInit } from '@angular/core';

import { BaseNotasModal } from '../base-notas-modal';
import { MessageService } from '../../../core';
import { NotasHttpService } from '../services';
import { Notas } from '../../../models';

declare var jQuery: any;

@Component({
    selector: 'notas-lancamentos-condominio-modal',
    templateUrl: '../notas-modal.component.html',
    styleUrls: ['../notas-modal.component.css']
})
export class NotasLancamentosCondominioModalComponent extends BaseNotasModal implements OnInit {


    constructor(private elementRef: ElementRef,
                protected notasHttpService: NotasHttpService,
                protected messageService: MessageService) {
        super(notasHttpService, messageService);
        setTimeout(() => {
            this.btnSave = jQuery(elementRef.nativeElement).find('#btnSaveNotasModal');
        }, 500);
    }

    ngOnInit() {
        this.title = 'Notas do lançamento';
    }

    getAll(idLancamento: number): Promise<Notas[]> {
        return new Promise<Notas[]>((resolve, reject) => {
            this.notasHttpService.getAllByLancamento(idLancamento)
                .subscribe(
                    entities => resolve(entities),
                    error => {
                        this.messageService.error('Erro ao carregar as notas do lançamento. Tente novamente', 'Erro', error);
                        reject(error);
                    }
                );
        });
    }

    insert() {
        let nota = new Notas();
        nota.id_lancamento_condominio = this.idOwner;
        this.entities.push(nota);
    }
}
