import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { ConsumosUnidadesCadastroComponent } from './cadastro';
import { ConsumosUnidadesEstruturaComponent, ConsumosUnidadesEstruturaService, ConsumosUnidadesEstruturaCadastroService } from './estrutura';
import { ConsumosUnidadesHttpService, ConsumosUnidadesDataCompetenciaService, ConsumosUnidadesUtilsService } from './services';

import { BlocosHttpService } from '../blocos/services';
import { TiposConsumoUtilsService, TiposConsumoHttpService } from '../tipos-consumo';

import { TiposConsumoOrderByPipe, UnidadeEstruturaItemOrderByPipe } from './pipes';
import { ConsumoUnidadePesquisaModalComponent } from './consumo-unidade-pesquisa-modal';
import { ConsumoUnidadeHistoricoModalComponent } from './consumo-unidade-historico-modal';

@NgModule({
	imports: [
		SharedModule,
		TypeaheadModule
	],
	declarations: [
		// Components
		ConsumosUnidadesCadastroComponent,
		ConsumosUnidadesEstruturaComponent,
		ConsumoUnidadePesquisaModalComponent,

		// Pipes
		TiposConsumoOrderByPipe,
		UnidadeEstruturaItemOrderByPipe,
		ConsumoUnidadeHistoricoModalComponent
	],
	exports: [
		ConsumoUnidadePesquisaModalComponent
	],
	providers: [
		BlocosHttpService,
		ConsumosUnidadesHttpService,
		ConsumosUnidadesDataCompetenciaService,
		ConsumosUnidadesUtilsService,
		ConsumosUnidadesEstruturaService,
		ConsumosUnidadesEstruturaCadastroService,
		TiposConsumoHttpService,
		TiposConsumoUtilsService
	]
})
export class ConsumosUnidadesModule { }