import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Crud, CrudService, ConfigService } from '../../../core';

import { ArquivoRetorno } from '../../../models';

@Injectable()
export class ArquivosRetornoHttpService implements Crud<ArquivoRetorno> {
	resource = 'arquivosretorno';
	baseUrl: string;

	constructor(private crudService: CrudService,
		private configService: ConfigService) {
		this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
	}

	get(id: number): Observable<ArquivoRetorno> {
		return this.crudService.get<ArquivoRetorno>(this.resource, id);
	}

	getAll(): Observable<ArquivoRetorno[]> {
		return this.crudService.getAll<ArquivoRetorno>(this.resource);
	}

	post(entity: ArquivoRetorno): Observable<ArquivoRetorno> {
		return this.crudService.post<ArquivoRetorno>(this.resource, entity);
	}

	put(entity: ArquivoRetorno): Observable<ArquivoRetorno> {
		return this.crudService.put<ArquivoRetorno>(this.resource, entity);
	}

	delete(entity: ArquivoRetorno): Observable<string> {
		return this.crudService.delete<ArquivoRetorno>(this.resource, entity);
	}
}
