import { Injectable } from '@angular/core';

@Injectable()
export class UtilsCategoriaContasService {

    /** Gera o código formatado para a CategoriaContas com base no código da 
    categoria pai + a próxima sequência de categorias filhas do pai. Ex:
        1. Receitas
            1.1 Pagamentos Condôminos
        2. Despesas
            2.2 Pagamentos Funcionários
                2.2.1 Funcionário zelador
    */
    gerarCodigo(codigoCategoriaPai: string, proximaSequencia: number) {
        if (!codigoCategoriaPai) {
            codigoCategoriaPai = '0';
        }
        if (!proximaSequencia) {
            proximaSequencia = 0;
        }
        return `${codigoCategoriaPai}.${proximaSequencia}`;
    }
}
