import { BaseEntity } from '../base-entity';
import { Titulo } from './titulo';

export class BaixaTitulo extends BaseEntity {
	public observacao: string;
	public ids_titulos: number[];
	public manual: boolean;
	public arTitulo: Titulo[];
	public dataPagamento: Date;
	public dataCredito: Date;
	public situacao: any;
	public id_conta_bancaria: number;

	constructor() {
		super();
		this.ids_titulos = [];
		this.arTitulo = [];
		this.dataPagamento = new Date();
		this.dataCredito = new Date(this.dataPagamento.getTime() + (1000 * 60 * 60 * 24));
		this.situacao = null;
		this.id_conta_bancaria = null;
	}
}

export const tiposBaixaTitulo = [
	{ id: 1, nome: 'Renegociado' },
	{ id: 2, nome: 'Divergência de Valores' }
];