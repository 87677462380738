import { NgModule } from '@angular/core';
import { ConfiguracaoEmailClienteComponent } from './configuracao-email-cliente.component';
import { SharedModule } from 'app/shared';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    ConfiguracaoEmailClienteComponent
  ]
})
export class ConfiguracaoEmailClienteModule { }