import { Injectable } from '@angular/core';

declare var jQuery: any;

@Injectable()
export class DataTablesService {

    constructor() {
        // Configuração default das DataTables
        const fn = jQuery.fn as any;
        jQuery.extend(fn.dataTable.defaults, {
            columnDefs: [
                { orderable: false, targets: -1, width: '40px' }
            ],
            language: {'url': './assets/scripts/datatables-ptbr.json'}
        });
    }

    /**
     * Executa a biblioteca DataTables
     */
    showTable(selector: string, options: any = {}): any {
        return jQuery(selector).DataTable(options);
    }

    /**
     * Destroi a table para poder reexibi-la novamente em seguida
     */
    destroyTable(dataTable: any) {
        if (dataTable) {
            dataTable.destroy();
        }
    }
}
