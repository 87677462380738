import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { ContaBancaria, tiposContasBancarias, CategoriaContas, Extrato, TiposOrigem } from '../../../models';
import { ContasBancariasHttpService } from './../services/contas-bancarias-http.service';
import { MessageService, AuthService } from '../../../core';
import { BaseVisualizacaoComponent } from '../../../componentes-base';
import { ExtratosHttpService } from './services/extratos-http.service';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { CategoriaContasPesquisaModalComponent } from '../../plano-de-contas/categoria-contas';
import { CategoriaContasHttpService } from '../../plano-de-contas/categoria-contas';
import { NgxSpinnerService } from 'ngx-spinner';

declare var jQuery: any;

@Component({
	selector: 'extratos',
	templateUrl: './extratos.component.html',
	styleUrls: ['./extratos.component.css'],
	providers: [PermissionService, DatePipe]
})
export class ExtratosComponent extends BaseVisualizacaoComponent<Extrato> {

	@ViewChild('categoriaContasPesquisaModal') categoriaContasPesquisaModal: CategoriaContasPesquisaModalComponent;

	private idCondominio: number;
	public total: number = 0;
	public tableNotOrdered: boolean = true;
	public filtro: any = {};
	public saldoTotal: any = 0;
	public tiposContas = tiposContasBancarias;
	public contas: ContaBancaria[];
	public categoriasContas: CategoriaContas[] = [];
	public tiposOrigem = TiposOrigem;

	constructor(activatedRoute: ActivatedRoute,
		router: Router,
		messageService: MessageService,
		permissionService: PermissionService,
		public route: ActivatedRoute,
		private authService: AuthService,
		private categoriaContasHttpService: CategoriaContasHttpService,
		private extratosHttpService: ExtratosHttpService,
		private contasBancariasHttpService: ContasBancariasHttpService,
		private datePipe: DatePipe,
		public spinner: NgxSpinnerService) {
		super('#tableExtratos', extratosHttpService, activatedRoute, router, messageService, permissionService, spinner);
	}

	ngOnInit() {
		this.idCondominio = this.authService.getIdCurrentCondominio();
		this.getAllCategoriasByCondominio();
		this.getContas();
		super.ngOnInit();
	}

	public getTipoOrigem(codigo: number): String {
		let nome = '';
		const arTipos = this.tiposOrigem.filter(tp => tp.codigo == codigo);
		if (arTipos.length) {
			nome = arTipos[0].nome;
		}
		return nome;
	}

	getAll() {
		this.setInitialFilter();
		this.search();
	}

	public search(): void {
		this.spinner.show();
		this.entities = [];
		this.filtro.id_condominio = this.idCondominio;
		this.extratosHttpService.getAllByContaBancariaEFiltro(this.filtro).subscribe(
			response => {
				this.spinner.hide();
				this.afterSearch(response)
			},
			error => {
				this.spinner.hide();
				this.messageService.error('Erro ao realizar a consulta. Tente novamente', 'Erro', error);
			}
		);
	}

	private afterSearch(response: any): void {
		this.entities = response.contas_bancarias;
		this.saldoTotal = response.saldo_total;
	}

	public setInitialFilter(): void {
		const today = new Date();
		const firstDay = new Date(today.getFullYear(), today.getMonth(), 1,);
		this.filtro.data_inicial = firstDay;
		this.filtro.data_final = today;
	}

	private getAllCategoriasByCondominio(): void {
		this.categoriasContas = [];
		if (this.idCondominio) {
			this.categoriaContasHttpService.getAllByCondominio(this.idCondominio)
				.then(entities => this.categoriasContas = entities)
				.catch(() => this.messageService.info(`Configuração do condomínio não encontrada. Configure o condomínio para prosseguir.`, 'Informação'));
		}
	}

	public onSelectCategoriaContas(tm: TypeaheadMatch): void {
		const categoriaContas: CategoriaContas = tm.item as CategoriaContas;
		if (categoriaContas) {
			this.filtro.id_categoria_conta = categoriaContas.id;
			this.filtro.categoriaContas = categoriaContas;
		} else {
			this.filtro.id_categoria_conta = null;
			this.filtro.categoriaContas = null;
		}
	}

	public onCleanCategoriaContas(input: any): void {
		this.filtro.id_categoria_conta = null;
		this.filtro.categoriaContas = null;
		input.value = '';
		input.focus();
	}

	public openCategoriaContasPequisaModal(): void {
		this.categoriaContasPesquisaModal.open();
	}

	public onSelectCategoriaContasPesquisaModal(categoriaContas: CategoriaContas): void {
		if (categoriaContas) {
			this.filtro.id_categoria_conta = categoriaContas.id;
			this.filtro.categoriaContas = categoriaContas;
		}
		jQuery('#categoriaContas').focus();
	}

	public insert(): void {
		this.router.navigate([`/financeiro/controle-financeiro/new`], { relativeTo: this.activatedRoute });
	}

	public transferir(): void {
		this.router.navigate([`/financeiro/transferencia/new`], { relativeTo: this.activatedRoute });
	}

	public getContas(): void {
		this.contasBancariasHttpService.getAllByCondominio(this.idCondominio).subscribe(
			response => this.contas = response,
			error => this.messageService.error('Erro ao carregar contas bancárias. Tente novamente', 'Erro', error)
		);
	}

	public deletar(extrato): void {
		if (confirm('Deseja realmente excluir esses item?')) {
			this.extratosHttpService.delete(extrato).subscribe(
				() => {
					this.messageService.success('', 'Extrato deletado com sucesso');
					this.afterDelete();
				},
				error => {
					if (error.status === 403)
						this.messageService.warning('Não foi possível excluir o extrato pois o mesmo foi criado a partir de um pagamento.', 'Atenção', error)
					else
						this.messageService.error('Erro ao deletar o extrato. Tente novamente', 'Erro', error);
				}
			);
		}
	}

	public edit(extrato: Extrato): void {
		// Edição da transferência
		if ([3, 4].includes(extrato.tipo)) {
			this.router.navigate([`/financeiro/transferencia/`, extrato.id], { relativeTo: this.activatedRoute });
		} else {
			super.edit(extrato);
		}
	}

	public afterDelete(): void {
		this.search();
	}

	public expandExtratos(conta: ContaBancaria): void {
		conta.open = !conta.open;
	}

	public nomeTipoConta(conta): string {
		const tipoConta = this.tiposContas.find(item => item.codigo === conta.tipo);
		return tipoConta.nome;
	}

	public goToPrintView(idContaBancaria: number[]): void {
		this.router.navigate([`/movimentacao`], {
			relativeTo: this.activatedRoute,
			queryParams: {
				id_conta_bancaria: idContaBancaria,
				data_inicial: this.datePipe.transform(this.filtro.data_inicial, 'yyyy-MM-dd', ''),
				data_final: this.datePipe.transform(this.filtro.data_final, 'yyyy-MM-dd')
			}
		});
	}

	public anexos(): void {
		this.router.navigate([`/financeiro/controle-financeiro/anexo/`], { relativeTo: this.activatedRoute });
	}

}