import { Component, Input, OnInit } from '@angular/core';
import { MessageService } from 'app/core';
import { LancamentoCondominioItem, ParcelaDespesaRateio } from 'app/models';

@Component({
  selector: 'parcela-despesa-rateio',
  templateUrl: './parcela-despesa-rateio.component.html',
  styleUrls: ['./parcela-despesa-rateio.component.css']
})
export class ParcelaDespesaRateioComponent implements OnInit {

  @Input() editMode: boolean;
  @Input() lancamentoCondominioItem: LancamentoCondominioItem;

  constructor(private messageService: MessageService) { }

  ngOnInit() { }

  /**
   * Monta as parcelas conforme lançamento
   * @param dataReferencia - Data de referência
   * @author Marcos Frare
   * @since 30/09/2020
   */
  public updateLancamento(dataReferencia?: Date): void {
    // Se a despesa for para rateio, cria as parcelas de rateio
    if (![4, 5].includes(this.lancamentoCondominioItem.tipo_divisao_rateio)) {
      if (this.lancamentoCondominioItem.parcelas_despesa_rateio && this.lancamentoCondominioItem.parcelas_despesa_rateio.length > 0) {
        // Se a quantidade de parcelas do lancamento for maior que a quantidade de parcelas para o rateio, adiciona as parcelas
        if (this.lancamentoCondominioItem.qtd_parcela > this.lancamentoCondominioItem.parcelas_despesa_rateio.length) {
          let lastDate = new Date(this.lancamentoCondominioItem.parcelas_despesa_rateio[this.lancamentoCondominioItem.parcelas_despesa_rateio.length-1].data_vencimento);
          for (let i=this.lancamentoCondominioItem.parcelas_despesa_rateio.length+1; i<=this.lancamentoCondominioItem.qtd_parcela; i++) {
            let parcela = new ParcelaDespesaRateio();
            parcela.id_lancamento_condominio_item = this.lancamentoCondominioItem.id;
            parcela.numero_parcela = this.lancamentoCondominioItem.parcelas_despesa_rateio.length+1;
            parcela.data_vencimento = new Date(lastDate.getFullYear(), (lastDate.getMonth()+1), lastDate.getDate());
            parcela.valor = 0;
            this.lancamentoCondominioItem.parcelas_despesa_rateio.push(parcela);
  
            lastDate = new Date(parcela.data_vencimento);
          }
        } else {
          // Eliminar as parcelas excedentes
          this.lancamentoCondominioItem.parcelas_despesa_rateio = this.lancamentoCondominioItem.parcelas_despesa_rateio.filter(parc => parc.numero_parcela <= this.lancamentoCondominioItem.qtd_parcela);
        }
  
        let totalAberto = this.lancamentoCondominioItem.valor ? this.lancamentoCondominioItem.valor : 0;
        const parcelasRateadas = this.lancamentoCondominioItem.parcelas_despesa_rateio.filter(parc => parc.id_demonstrativo_rateio);
        const parcelasAbertas = this.lancamentoCondominioItem.parcelas_despesa_rateio.filter(parc => !parc.id_demonstrativo_rateio);
  
        for (let parcela of parcelasRateadas) {
          totalAberto -= parcela.valor;
        }
  
        const vlParcela = (totalAberto/parcelasAbertas.length).toFixed(2);
        for (let parcela of parcelasAbertas) {
          parcela.valor = Number(vlParcela);
          totalAberto -= Number(vlParcela);
        }
        if (totalAberto != 0) {
          parcelasAbertas[parcelasAbertas.length-1].valor += totalAberto;
        }
      } else {
        this.lancamentoCondominioItem.qtd_parcela = this.lancamentoCondominioItem.qtd_parcela;
        this.lancamentoCondominioItem.parcelas_despesa_rateio = [];
        let lastDate = dataReferencia || new Date();
        let valorAberto = this.lancamentoCondominioItem.valor ? this.lancamentoCondominioItem.valor : 0;
        for (let i=1; i<=this.lancamentoCondominioItem.qtd_parcela; i++) {
          let parcela = new ParcelaDespesaRateio();
          parcela.id_lancamento_condominio_item = this.lancamentoCondominioItem.id;
          parcela.numero_parcela = i;
          parcela.data_vencimento = i>1 ? new Date(lastDate.getFullYear(), (lastDate.getMonth()+1), lastDate.getDate()) : lastDate;
          parcela.valor = (this.lancamentoCondominioItem.valor / this.lancamentoCondominioItem.qtd_parcela).toFixed(2);
          this.lancamentoCondominioItem.parcelas_despesa_rateio.push(parcela);
  
          valorAberto -= parcela.valor;
          lastDate = parcela.data_vencimento;
        }
      }
    } else {
      this.lancamentoCondominioItem.parcelas_despesa_rateio = [];
    }
  }

  /**
   * Recalcular parcelas
   * @author Marcos Frare
   * @since 30/09/2020
   */
  public changeValor(parcela: ParcelaDespesaRateio): void {
    // Calcula valor em aberto
    let valorAberto = parseFloat(this.lancamentoCondominioItem.valor);
    let numParcAberto = 0;
    for (let parc of this.lancamentoCondominioItem.parcelas_despesa_rateio) {
      if (parc.numero_parcela <= parcela.numero_parcela) {
        valorAberto -= parc.valor;
      } else {
        numParcAberto++;
      }
    }
    if (valorAberto > 0) {
      // Calcula valor das parcelas posteriores
      const vlParcela = (valorAberto/numParcAberto).toFixed(2);

      // Atualiza valores nas parcelas
      for (let parc of this.lancamentoCondominioItem.parcelas_despesa_rateio) {
        if (parc.numero_parcela > parcela.numero_parcela) {
          parc.valor = Number(vlParcela);
          valorAberto -= Number(vlParcela);
        }
      }
    } else {
      // Excluir as próximas parcelas
      this.lancamentoCondominioItem.parcelas_despesa_rateio = this.lancamentoCondominioItem.parcelas_despesa_rateio.filter(parc => parc.numero_parcela <= parcela.numero_parcela);
    }

    // Ajuste de diferença de divisão
    if (valorAberto != 0) {
      this.lancamentoCondominioItem.parcelas_despesa_rateio[this.lancamentoCondominioItem.parcelas_despesa_rateio.length - 1].valor += valorAberto;
    }
  }

  /**
   * Alteração da data de vencimento da parcela do rateio
   * @param parcela 
   * @author Marcos Frare
   * @since 01/10/2020
   */
  public onChangeDataVencimento(parcela: ParcelaDespesaRateio): void {
    // Ao alterar a data de vencimento da parcela, atualiza as parcelas posteriores mês a mês
    let lastDate = new Date();
    for (let parc of this.lancamentoCondominioItem.parcelas_despesa_rateio) {
      if (parc.numero_parcela > parcela.numero_parcela && parc.numero_parcela > 1) {
        parc.data_vencimento = new Date(lastDate.getFullYear(), (lastDate.getMonth()+1), lastDate.getDate());
      }
      lastDate = new Date(parc.data_vencimento);
    }
  }

  /**
   * Remover uma parcela
   * @param parcela 
   * @author Marcos Frare
   * @since 01/10/2020
   */
  public onRemoveParcela(parcela: ParcelaDespesaRateio): void {
    parcela.excluir = true;
    if (parcela.id_demonstrativo_rateio) {
      this.messageService.warning('Não é possível exlcuir parcela já rateada!', 'Atenção!');
    } else if (this.lancamentoCondominioItem.parcelas_despesa_rateio.length <= 1) {
      this.messageService.warning('Necessário ao menos uma parcela!', 'Atenção!');
    } else {
      // Parcelas já rateadas
      this.lancamentoCondominioItem.parcelas_despesa_rateio = this.lancamentoCondominioItem.parcelas_despesa_rateio.filter(parc => !parc.excluir);
      // Busca valor total em aberto
      let totalAberto = this.lancamentoCondominioItem.valor;
      let parcAberto = this.lancamentoCondominioItem.parcelas_despesa_rateio.length;
      for (let parc of this.lancamentoCondominioItem.parcelas_despesa_rateio) {
        if (parc.id_demonstrativo_rateio) {
          totalAberto -= parc.valor;
          parcAberto--;
        }
      }
      
      let valorParcela = totalAberto/parcAberto;
      valorParcela = Number(valorParcela.toFixed(2));

      let numParcela = 0;
      let total = this.lancamentoCondominioItem.valor;
      let lastDate = new Date();
      for (parcela of this.lancamentoCondominioItem.parcelas_despesa_rateio) {
        numParcela++;
        if (!parcela.id_demonstrativo_rateio) {
          parcela.numero_parcela = numParcela;
          if (parcela.numero_parcela != 1) {
            parcela.data_vencimento = new Date(lastDate.getFullYear(), (lastDate.getMonth()+1), lastDate.getDate());
          }
          parcela.valor = valorParcela;
        }

        total -= parcela.valor;
        lastDate = new Date(parcela.data_vencimento);
      }
      
      if (total != 0) {
        if (this.lancamentoCondominioItem.parcelas_despesa_rateio[this.lancamentoCondominioItem.parcelas_despesa_rateio.length -1].id_demonstrativo_rateio) {
          this.addParcela();
        } else {
          this.lancamentoCondominioItem.parcelas_despesa_rateio[this.lancamentoCondominioItem.parcelas_despesa_rateio.length -1].valor += total;
        }
      }
      // Atualiza quantidade de parcelas do rateio no lançamento
      this.lancamentoCondominioItem.qtd_parcela = this.lancamentoCondominioItem.parcelas_despesa_rateio.length;
    }
  }

  /**
   * Adiciona nova parcela
   * @author Marcos Frare
   * @since 01/10/2020
   */
  public addParcela(): void {
    // Parcelas já rateadas (Não pode alterar);
    let parcelas: ParcelaDespesaRateio[] = [];
    let parcelasAberto: ParcelaDespesaRateio[] = [];
    let totalAberto = this.lancamentoCondominioItem.valor;
    // Separa parcelas abertas e parcelas já rateadas
    for (let parcela of this.lancamentoCondominioItem.parcelas_despesa_rateio) {
      if (parcela.id_demonstrativo_rateio) {
        parcelas.push(parcela);
        totalAberto -= parcela.valor;
      } else {
        parcelasAberto.push(parcela);
      }
    }

    // Valor das parcelas
    const valorParcela = (totalAberto / (parcelasAberto.length + 1)).toFixed(2);

    // Inclui as parcelas com valores e datas atualizadas
    let valorCalculado = 0;
    let lastDate = new Date();
    for (let parc of parcelasAberto) {
      valorCalculado += Number(valorParcela);
      parc.valor = Number(valorParcela);
      parcelas.push(parc);
      lastDate = new Date(parc.data_vencimento);
    }
    // Inclui a nova parcela com valor restante e data um mês posterior
    let parcela = new ParcelaDespesaRateio();
    parcela.id_lancamento_condominio_item = this.lancamentoCondominioItem.id;
    parcela.numero_parcela = (this.lancamentoCondominioItem.parcelas_despesa_rateio.length + 1);
    parcela.data_vencimento = new Date(lastDate.getFullYear(), (lastDate.getMonth() +1), lastDate.getDate());
    parcela.valor = totalAberto - valorCalculado;
    parcelas.push(parcela);

    this.lancamentoCondominioItem.parcelas_despesa_rateio = parcelas;
    this.lancamentoCondominioItem.qtd_parcela = parcelas.length
  }

  /**
   * Atualiza datas de vencimento
   * @param date 
   * @author Marcos Frare
   * @since 09/01/2021
   */
  public changeFirstDate(date: Date): void {
    if (this.lancamentoCondominioItem.parcelas_despesa_rateio && this.lancamentoCondominioItem.parcelas_despesa_rateio.length) {
      let parcela = this.lancamentoCondominioItem.parcelas_despesa_rateio[0];
      parcela.data_vencimento = date;
      this.onChangeDataVencimento(parcela);
    }
  }

}