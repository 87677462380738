import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ContaBancaria, Extrato, ExtratoBanco, ConciliacaoExtrato, Resumo } from 'app/models';
import { MessageService, AuthService } from 'app/core';
import { ContasBancariasHttpService } from '../../services';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConciliacaoBancariaHttpService } from '../service';
import { datepickerMesAnoOptions } from 'app/shared';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { Permission } from 'app/componentes-base';
import { DatePipe } from '@angular/common';
import { ConciliacaoContaLancamentoModalComponent } from './conciliacao-conta-lancamento-modal';

@Component({
  selector: 'conciliacao-conta',
  templateUrl: './conciliacao-conta.component.html',
  styleUrls: ['./conciliacao-conta.component.css'],
  providers: [
    PermissionService,
    DatePipe
  ]
})
export class ConciliacaoContaComponent implements OnInit {

  @ViewChild('conciliacaoContaLancamentoModal') conciliacaoContaLancamentoModal: ConciliacaoContaLancamentoModalComponent;

  public contaBancaria: ContaBancaria;
  public extratos: Extrato[];
  public extratosBanco: ExtratoBanco[];
  public conciliacoes: ConciliacaoExtrato[];
  public conciliacoesManual: any[];
  public competencia: Date;
  public datepickerDataCompetenciaOptions = datepickerMesAnoOptions;
  private resource: string;
  public permissions: Permission;
  private id_condominio: number;
  private id_usuario: number;
  public sumExtrato: number;
  public sumExtratoBanco: number;

  public contDebitoExtrato: number;
  public contCreditoExtrato: number;
  public contDebitoExtratoBanco: number;
  public contCreditoExtratoBanco: number;

  public resumo: Resumo;

  // Chave sequencial para manipulação de conciliações manuais
  private conciliacaoKey: number;
  public previaConciliacao: boolean;

  public checkAllConciliacaoAuto: boolean;

  constructor(private router: Router,
      private activatedRoute: ActivatedRoute,
      private messageService: MessageService,
      private authService: AuthService,
      private contaBancariaHttpService: ContasBancariasHttpService,
      private conciliacaoBancariaHttpService: ConciliacaoBancariaHttpService,
      private permissionService: PermissionService,
      private datePipe: DatePipe,
      private spinner: NgxSpinnerService) {
    this.contaBancaria = new ContaBancaria();
    this.competencia = new Date();
    this.resource = this.activatedRoute.data['value']['resource'];
    this.id_usuario = this.authService.getIdCurrentUser();
    this.resumo = new Resumo();
  }

  ngOnInit() {
    this.id_condominio = this.authService.getIdCurrentCondominio();
    this.inicializeValues();
    this.getPermissions();
    
    // buscar a conta bancária
    this.activatedRoute.params.subscribe((params: Params) => {
      let id = +params['id']; // Convertendo o id para um number
      this.loadContaBancaria(id);
    });
  }

  /**
   * Inicialização dos valores da tela
   * @author Marcos Frare
   * @since 06/07/2020
   */
  private inicializeValues() {
    this.extratos = [];
    this.extratosBanco = [];
    this.conciliacoes = [];
    this.conciliacoesManual = [];
    this.sumExtrato = 0;
    this.sumExtratoBanco = 0;
    this.contDebitoExtrato = 0;
    this.contDebitoExtratoBanco = 0;
    this.contCreditoExtrato = 0;
    this.contCreditoExtratoBanco = 0;
    
    // Resumo
    this.resumo.qtdCreditoBanco = 0;
    this.resumo.qtdCreditoSistema = 0;
    this.resumo.qtdDebitoBanco = 0;
    this.resumo.qtdDebitoSistema = 0;
    this.resumo.valorCreditoBanco = 0;
    this.resumo.valorCreditoSistema = 0;
    this.resumo.valorDebitoBanco = 0;
    this.resumo.valorDebitoSistema = 0;

    this.checkAllConciliacaoAuto = true;
  }

  /**
   * Seleciona os itens
   * @author Marcos Frare
   * @since 15/07/2020
   */
  public checkAllConciliacao(): void {
    this.conciliacoes.map(item => item.checado = this.checkAllConciliacaoAuto);
    this.checkAllConciliacaoAuto = !this.conciliacoes.filter(item => !item.checado).length;
  }

  /**
   * getAllChecked
   */
  public getAllChecked(): boolean {
    this.conciliacoes.map(item => {
      if (!item.checado) {
        return false;
      }
    });
    return true;
  }

  /**
   * Marca itens para não passar mais pela conciliação automática
   * @author Marcos Frare
   * @since 15/07/2020
   */
  public ExcluirConciliacaoAutomatico(): void {
    this.spinner.show();
    const contas = this.conciliacoes.filter(item => item.checado);
    
    const params = {
      conciliacoes: contas,
      id_condominio: this.id_condominio,
      id_usuario: this.id_usuario,
      id_conta_bancaria: this.contaBancaria.id
    }

    this.conciliacaoBancariaHttpService.desfazerConciliacaoSistema(params).subscribe(
      () => {
        this.spinner.hide();
        this.messageService.info('Conciliações desfeitas com sucesso!');
        this.loadPendentes();
      },
      error => {
        this.spinner.hide();
        this.messageService.error('Erro para desfazer conciliações. Tente novamente!', 'Erro', error);
      }
    );
  }

  /**
   * Busca permissões do usuário
   * @author Marcos Frare
   * @since 19/06/2020
   */
  private getPermissions(): void {
    this.permissionService.getPermissions(this.resource).subscribe(permissions => {
      this.permissions = permissions;
    });
  }

  /**
   * Busca os dados da conta bancária que será feito a conciliação
   * @param id - ID da conta bancária
   * @author Marcos Frare
   * @since 29/06/2020
   */
  private loadContaBancaria(id: number) {
    this.spinner.show();
    this.contaBancariaHttpService.get(id).subscribe(
      response => {
        this.spinner.hide();
        this.contaBancaria = response;
        this.loadPendentes();
      },
      error => {
        this.messageService.error('Não foi possível buscar informações da conta bancária. Tente novamente', 'Erro.', error);
        this.spinner.hide();
      }
    );
  }

  /**
   * Carrega ítens para conciliação automática
   * @author Marcos Frare
   * @since 01/07/2020
   */
  private loadConciliacaoSistema(): void {
    for (let oExtrato of this.extratos) {
      if (oExtrato.conciliacao_automatica) {
        // Normatiza dados para comparação
        let operacao = oExtrato.tipo == 1 || oExtrato.tipo == 3 ? 'CREDITO' : 'DEBITO';
        let valor = oExtrato.valor;
        let data_evento = this.datePipe.transform(oExtrato.data, 'yyyy-MM-dd');

        let arExtratoBanco = this.extratosBanco.filter(item => (item.valor == valor && item.operacao == operacao && this.datePipe.transform(item.data_evento, 'yyyy-MM-dd') == data_evento && !item.conciliado && item.conciliacao_automatica));

        // Se encontrou algum item equivalente, carrega no array
        if (arExtratoBanco.length > 0) {
          // Marca o extrato e extrato do banco com conciliado
          oExtrato.conciliado = true;
          arExtratoBanco[0].conciliado = true;

          let obj = new ConciliacaoExtrato();
          obj.extrato = oExtrato;
          obj.extratoBanco = arExtratoBanco[0];
          obj.checado = true;
          obj.sistema = true;

          this.conciliacoes.push(obj);
        }
      }
    }
    this.contadores();
  }

  /**
   * Carregar dados para conciliação
   * @author Marcos Frare
   * @since 19/06/2020
   */
  private loadPendentes(): void {
    this.conciliacoes = [];
    this.conciliacoesManual = [];

    this.sumExtrato = 0;
    this.sumExtratoBanco = 0;

    this.conciliacaoKey = 0;
    const params = {
      id_conta_bancaria: this.contaBancaria.id,
      competencia: this.competencia ? this.datePipe.transform(this.competencia, 'yyyy-MM-dd') : null
    }
    this.spinner.show();
    this.conciliacaoBancariaHttpService.getPendentes(params).subscribe(
      response => {
        this.spinner.hide();
        this.extratos = response.extratos;
        this.extratosBanco = response.extratosBanco;
        this.resumo = response.resumo;
        this.loadConciliacaoSistema();
      },
      error => {
        this.spinner.hide();
        this.messageService.error('Erro ao buscar extratos pendentes de conciliação', 'Erro', error);
      }
    );
  }

  /**
   * Contadores de itens dos quadros para conciliação
   * @author Marcos Frare
   * @since 06/07/2020
   */
  private contadores(): void {
    this.contDebitoExtrato = 0;
    this.contDebitoExtratoBanco = 0;
    this.contCreditoExtrato = 0;
    this.contCreditoExtratoBanco = 0;

    this.contCreditoExtrato = (this.extratos.filter(item => (([1,3]).includes(item.tipo) && !item.conciliado))).length;
    this.contDebitoExtrato = (this.extratos.filter(item => (([2,4]).includes(item.tipo) && !item.conciliado))).length;
    
    this.contCreditoExtratoBanco = (this.extratosBanco.filter(item => (item.operacao == 'CREDITO' && !item.conciliado))).length;
    this.contDebitoExtratoBanco = (this.extratosBanco.filter(item => (item.operacao == 'DEBITO' && !item.conciliado))).length;
  }

  /**
   * Check do item ao clicar na linha
   * @param extrato 
   * @author Marcos Frare
   * @since 19/06/2020
   */
  public lineCheckExtrato(extrato: Extrato): void {
    extrato.checado = !extrato.checado;
    this.validarPrevia();
  }

  /**
   * Check do item ao clicar na linha
   * @param extrato 
   * @author Marcos Frare
   * @since 19/06/2020
   */
  public lineCheckExtratoBanco(extratoBanco: ExtratoBanco): void {
    extratoBanco.checado = !extratoBanco.checado;
    this.validarPrevia();
  }

  /**
   * Check do item ao clicar na linha
   * @param extrato 
   * @author Marcos Frare
   * @since 19/06/2020
   */
  public lineCheckConciliacao(conciliacao: any): void {
    conciliacao.checado = !conciliacao.checado;
  }

  /**
   * Alteração da competência
   * @author Marcos Frare
   * @since 19/06/2020
   */
  public onChangeCompetencia(): void {
    this.loadPendentes();
  }

  /**
   * Validação dos itens selecionados em tempo de execução
   * @author Marcos Frare
   * @since 02/07/2020
   */
  public validarPrevia(): void {
    let arExtrato = this.extratos.filter(extrato => extrato.checado);
    let arExtratoBanco = this.extratosBanco.filter(extratoBanco => extratoBanco.checado);

    this.previaConciliacao = this.validarConciliacao(arExtrato, arExtratoBanco, false);
  }

  /**
   * Validar itens selecionados para conciliação
   * @author Marcos Frare
   * @since 29/06/2020
   */
  private validarConciliacao(arExtrato: Extrato[], arExtratoBanco: ExtratoBanco[], mostraMensagem: boolean = true): boolean {
    let dataReferencia = null;
    let operacao = null;
    let totalExtrato = 0;
    let totalExtratoBanco = 0;

    this.sumExtrato = 0;
    this.sumExtratoBanco = 0;

    arExtrato.map(item => {
      this.sumExtrato += Number(item.valor);
    });
    arExtratoBanco.map(item => {
      this.sumExtratoBanco += Number(item.valor);
    });

    for (let extratoBanco of arExtratoBanco) {
      totalExtratoBanco += Number(extratoBanco.valor);
      if (!dataReferencia) {
        dataReferencia = this.datePipe.transform(extratoBanco.data_evento, 'yyyy-MM-dd');
      } else {
        if (this.datePipe.transform(extratoBanco.data_evento, 'yyyy-MM-dd') != dataReferencia) {
          if (mostraMensagem) {
            this.messageService.warning('Data do extrato inválida. Verifique itens selecionados', 'Atenção!');
          }
          return false;
        }
      }
      if (!operacao) {
        operacao = extratoBanco.operacao;
      } else {
        if (extratoBanco.operacao != operacao) {
          if (mostraMensagem) {
            this.messageService.warning('Selecionado itens de extrato com operações distintas. Verifique!', 'Atenção!');
          }
          return false;
        }
      }
    }

    // validar datas (Se for item de débito e ainda não pago, não valida data)
    for (let extrato of arExtrato) {
      totalExtrato += Number(extrato.valor);
      if (extrato.id && this.datePipe.transform(extrato.data, 'yyyy-MM-dd') != dataReferencia) {
        if (mostraMensagem) {
          this.messageService.warning('Data do extrato inválida. Verifique itens selecionados', 'Atenção!');
        }
        return false;
      }
      if ((extrato.tipo == 1 || extrato.tipo == 3 ? 'CREDITO' : 'DEBITO') != operacao) {
        if (mostraMensagem) {
          this.messageService.warning('Selecionado itens de extrato com operações distintas. Verifique!', 'Atenção!');
        }
        return false;
      }
    }

    // Selecionar ao menos um ítem em uma tabela
    if (arExtrato.length < 1) {
      if (mostraMensagem) {
        this.messageService.warning('Necessário selecionar ao menos um item na tabela de Extrato do sistema.', 'Atenção!');
      }
      return false;
    }

    if (arExtratoBanco.length < 1) {
      if (mostraMensagem) {
        this.messageService.warning('Necessário selecionar ao menos um item na tabela de Extrato importado.', 'Atenção!');
      }
      return false;
    }

    totalExtratoBanco = Number(totalExtratoBanco.toFixed(2));

    // Soma dos valores de um array deve ser igual em relação a soma dos valores do outro array
    totalExtrato = Number(totalExtrato.toFixed(2));
    if (totalExtrato != totalExtratoBanco) {
      if (mostraMensagem) {
        this.messageService.warning('Totais de valores de Extrato do Sistema e Extrato importado divergentes.', 'Atenção!');
      }
      return false;
    }
    return true;
  }

  /**
   * Conciliar par de contas
   * @author Marcos Frare
   * @since 19/06/2020
   */
  public conciliar(): void {
    let arExtrato = this.extratos.filter(extrato => extrato.checado);
    let arExtratoBanco = this.extratosBanco.filter(extratoBanco => extratoBanco.checado);

    if (this.validarConciliacao(arExtrato, arExtratoBanco)) {
      this.previaConciliacao = false;
      // Marcar itens como conciliado
      arExtrato.map(item => {
        item.conciliado = true;
        item.checado = false;
      });
      arExtratoBanco.map(item => {
        item.conciliado = true;
        item.checado = false;
      });

      this.conciliacaoKey++;

      let obj = {
        key: this.conciliacaoKey,
        arExtrato,
        arExtratoBanco,
        id_conta_bancaria: this.contaBancaria.id,
        id_condominio: this.id_condominio,
        id_usuario: this.id_usuario
      }

      this.conciliacoesManual.push(obj);

      this.sumExtrato = 0;
      this.sumExtratoBanco = 0;

      this.contadores();
    }
  }

  /**
   * Persistir conciliações realizadas manualmente
   * @author Marcos Frare
   * @since 03/07/2020
   */
  public conciliarManual(): void {
    this.spinner.show();

    const params = {
      conciliacoes: this.conciliacoesManual,
      id_condominio: this.id_condominio,
      id_usuario: this.id_usuario,
      id_conta_bancaria: this.contaBancaria.id
    }

    this.conciliacaoBancariaHttpService.conciliarManual(params).subscribe(
      () => {
        this.spinner.hide();
        this.messageService.info('Conciliações realizadas com sucesso!');
        this.loadPendentes();
      },
      error => {
        this.spinner.hide();
        this.messageService.error('Erro na conciliação de contas. Tente novamente!', 'Erro', error);
      }
    );
  }

  /**
   * Conciliar contas encontradas pelo sistema e posteriormente selecionada pelo usuário
   * @author Marcos Frare
   * @since 02/07/2020
   */
  public conciliarAutomatico(): void {
    this.spinner.show();
    const contas = this.conciliacoes.filter(item => item.checado);
    
    const params = {
      conciliacoes: contas,
      id_condominio: this.id_condominio,
      id_usuario: this.id_usuario,
      id_conta_bancaria: this.contaBancaria.id
    }

    this.conciliacaoBancariaHttpService.conciliarLote(params).subscribe(
      () => {
        this.spinner.hide();
        this.messageService.info('Conciliações realizadas com sucesso!');
        this.loadPendentes();
      },
      error => {
        this.spinner.hide();
        this.messageService.error('Erro na conciliação de contas. Tente novamente!', 'Erro', error);
      }
    );
  }

  /**
   * Cancela o processo
   * @author Marcos Frare
   * @since 29/06/2020
   */
  public cancel() {
    this.router.navigate(['../../'], {relativeTo: this.activatedRoute});
  }

  /**
   * Excluir uma conciliação manual
   * @param conciliacao 
   * @author Marcos Frare
   * @since 02/07/2020
   */
  public deleteManual(conciliacao: any): void {
    // Marca os itens como não conciliados
    conciliacao.arExtrato.map(item => {
      item.conciliado = false;
      item.checado = false;
    });
    conciliacao.arExtratoBanco.map(item => {
      item.conciliado = false;
      item.checado = false;
    });

    this.conciliacoesManual = this.conciliacoesManual.filter(item => item.key != conciliacao.key);

    this.contadores();
  }

  /**
   * Valida os itens selecionados para gerar o lançamento
   * @param extratosLancamento 
   * @author Marcos Frare
   * @since 11/08/2020
   */
  private validaLancamento(extratosLancamento: ExtratoBanco[]): boolean {
    let operacao = '';
    let dataEvento = '';

    if (extratosLancamento.length > 0) {
      for (let item of extratosLancamento) {
        let dataItem = this.datePipe.transform(item.data_evento, 'yyyy-MM-dd');
  
        if (operacao == '') {
          operacao = item.operacao;
        }
        if (dataEvento == '') {
          dataEvento = dataItem;
        }
        if (item.operacao != operacao) {
          this.messageService.warning('Necessário selecionar ítens com mesma operação!', 'Atenção!');
          return false;
        }
        if (dataItem != dataEvento) {
          this.messageService.warning('Necessário selecionar ítens com mesma data!', 'Atenção!');
          return false;
        }
      }
    } else {
      this.messageService.warning('Necessário selecionar ao menos um item do extrato!', 'Atenção!');
      return false;
    }
    
    return true;
  }

  /**
   * Faz lançamento manual
   * @author Marcos Frare
   * @since 08/07/2020
   */
  public lancamento(): void {
    const extratosLancamento = this.extratosBanco.filter(item => item.checado);

    if (this.validaLancamento(extratosLancamento)) {
      this.conciliacaoContaLancamentoModal.extratosLancamento = extratosLancamento;
      this.conciliacaoContaLancamentoModal.contaBancaria = this.contaBancaria;
      this.conciliacaoContaLancamentoModal.open();
    }
  }

  /**
   * Excluir extrato bancário
   * @param extrato 
   * @author Marcos Frare
   * @since 13/02/2023
   */
  public excluirExtratoBanco(extrato: ExtratoBanco): void {
    if (confirm(`Deseja realmente excluir extrato de ${(extrato.operacao == 'CREDITO' ? 'Credito' : 'Debito')} no valor de R$ ${extrato.valor}?`)) {
      this.conciliacaoBancariaHttpService.excluirExtratoBanco(extrato.id).subscribe(
        () => {
          this.loadPendentes();
          this.messageService.success('Extrato excluído com sucesso!');
        },
        error => {
          this.messageService.error('Parece que algo de errado aconteceu! Tente novamente', 'Erro', error);
        }
      );
    }
  }

}