import { AuthService } from 'app/core';
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { DataTablesService, MessageService } from '../../../../core';
import { CondominiosHttpService } from '../../../condominios/services/condominios-http.service';

import { Condominio } from '../../../../models';

declare var jQuery: any;

@Component({
	selector: 'troca-condominio-modal',
	templateUrl: './troca-condominio-modal.component.html',
	styleUrls: ['./troca-condominio-modal.component.css']
})
export class TrocaCondominioModalComponent {
	condominios: Condominio[] = [];
	dataTable: any;

	@ViewChild('formTrocaCondominio') form: NgForm;
	@Output() trocar = new EventEmitter<Condominio>();


	constructor(private messageService: MessageService,
		private dataTablesService: DataTablesService,
		private condominiosHttpService: CondominiosHttpService,
		private authService: AuthService) { }


	// Carrega todos os condomínios cadastrados
	private getCondominios(): Promise<any> {
		let idUsuario: any = this.authService.getIdCurrentUser();
		idUsuario = parseInt(idUsuario);

		return new Promise<any>((resolve, reject) => {
			this.condominiosHttpService.getAllByUsuario(idUsuario).subscribe(
					entities => {
						this.condominios = entities;
						resolve();
					},
					error => {
						this.condominios = [];
						this.messageService.error('Erro ao consultar os condomínios. Tente novamente', 'Erro', error);
						reject(error);
					}
				);
		});
	}

	private showTable() {
		if (this.dataTable) {
			this.dataTablesService.destroyTable(this.dataTable);
		}

		setTimeout(() => {
			this.dataTable = this.dataTablesService.showTable('#tableSelecionaCondominios', {
				lengthChange: false,
				paging: false,
				info: false
			});
		}, 200);
	}

	open() {
		this.getCondominios()
			.then(() => {
				jQuery('#trocaCondominioModal').modal('show');
				this.showTable();
			})
			.catch(() => { });
	}

	close() {
		jQuery('#trocaCondominioModal').modal('hide');
	}

	selecionarClick(condominio: Condominio) {
		this.trocar.emit(condominio);
		this.close();
	}
}
