import { Injectable } from '@angular/core';
import { CustomHttp, ConfigService } from 'app/core';
import { ContaBancaria, ConciliacaoExtrato, Conciliacao } from 'app/models';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';

@Injectable({
  providedIn: 'root'
})
export class ConciliacaoBancariaHttpService {

  resource = 'conciliacaobancaria';
  private baseUrl: string;

  constructor(private customHttp: CustomHttp,
      private configService: ConfigService) {
    this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
  }

  /**
   * Busca as contas bancárias e pendências de cada
   * @author Marcos Frare
   * @since 26/06/2020
   */
  public index(params: any): Observable<ContaBancaria[]> {
    return this.customHttp.get(`${this.baseUrl}`, {params}).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
  }

  /**
   * Busca extratos pendentes de conciliação
   * @param params 
   * @author Marcos Frare
   * @since 19/06/2020
   */
  public getPendentes(params: any): Observable<any> {
    return this.customHttp.get(`${this.baseUrl}/conciliacao/pendentes`, {params}).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
  }

  /**
   * Conciliações mnuais
   * @param conciliacoes
   * @author Marcos Frare
   * @since 19/06/2020
   */
  public conciliarManual(params: any): Observable<ConciliacaoExtrato> {
    return this.customHttp.post(`${this.baseUrl}/conciliacao/manual`, params).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
  }

  /**
   * Persistir conciliações propostas pelo sistema e selecionados pelo usuário
   * @param conciliacoes 
   * @author Marcos Frare
   * @since 02/07/2020
   */
  public conciliarLote(params: any): Observable<any> {
    return this.customHttp.post(`${this.baseUrl}/conciliacao/lote`, params).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
  }

  /**
   * Importação de arquivo de extrato bancario
   * @param params 
   * @author Marcos Frare
   * @since 30/06/2020
   */
  public importar(params: any): Observable<any> {
    return this.customHttp.post(`${this.baseUrl}/extratobanco/importar`, params).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
  }

  /**
   * Busca as conciliações realizadas para uma conta bancária em uma competência
   * @param contaBancaria 
   * @param competencia 
   * @author Marcos Frare
   * @since 03/07/2020
   */
  public consultarConciliaces(contaBancaria: ContaBancaria, competencia: string): Observable<Conciliacao[]> {
    const params = { competencia }
    return this.customHttp.get(`${this.baseUrl}/${contaBancaria.id}`, {params}).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
  }

  /**
   * Excluir conciliação pelo ID
   * @param id 
   * @author Marcos Frare
   * @since 06/07/2020
   */
  public excluirConciliacao(id: number): Observable<any> {
    return this.customHttp.delete(`${this.baseUrl}/${id}`).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
  }

  /** Persistir conciliações propostas pelo sistema e selecionados pelo usuário
   * @param params 
   * @author Marcos Frare
   * @since 15/07/2020
   */
  public desfazerConciliacaoSistema(params: any): Observable<any> {
    return this.customHttp.post(`${this.baseUrl}/conciliacao/desfazer`, params).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
  }

  /**
   * Excluir extrado bancário
   * @param id 
   * @author Marcos Frare
   * @since 13/02/2023
   */
  public excluirExtratoBanco(id: number): Observable<any> {
    return this.customHttp.delete(`${this.baseUrl}/extratobanco/${id}`).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
  }

}