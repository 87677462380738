import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared';

import { FornecedoresComponent } from './fornecedores.component';
import { FornecedoresCadastroComponent } from './cadastro';
import { FornecedoresCadastroModalComponent } from './cadastro-modal';
import { FornecedoresHttpService } from './services/fornecedores-http.service';
import { PlanoDeContasModule } from '../plano-de-contas';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

@NgModule({
	imports: [
		SharedModule,
		PlanoDeContasModule,
		TypeaheadModule
	],
	declarations: [
		FornecedoresComponent,
		FornecedoresCadastroComponent,
		FornecedoresCadastroModalComponent
	],
	exports: [
		FornecedoresCadastroModalComponent
	],
	providers: [
		FornecedoresHttpService
	]
})
export class FornecedoresModule { }