import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { AuthService, MessageService } from '../../../core';
import { PrevisaoPorFracaoRelFiltro } from './previsao-por-fracao-rel-filtro';
import { Unidade, Bloco } from './../../../models';
import { BlocosHttpService, BlocosPesquisaModalComponent } from './../../blocos';
import { UnidadesHttpService } from './../../blocos/unidades';
import { datepickerMesAnoOptions } from '../../../shared';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { RelatorioModalComponent } from '../relatorio-modal';
import { IMultiSelectSettings, IMultiSelectOption, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';

declare var jQuery: any;

@Component({
	selector: 'previsao-por-fracao-rel',
	templateUrl: './previsao-por-fracao-rel.component.html'
})
export class PrevisaoPorFracaoRelComponent implements OnInit {

	public unidade: Unidade;
	public unidades: Unidade[] = [];
	public blocos: Bloco[] = [];
	public idUnidadeAtual: number;
	public datepickerMesCompetenciaOptions = datepickerMesAnoOptions;
	public filtro = new PrevisaoPorFracaoRelFiltro();
	public bloco: Bloco;
	public showInputBlocos: boolean = true;

	public multiselectSettings: IMultiSelectSettings = {
		enableSearch: true,
		containerClasses: 'container-multiselect',
		showUncheckAll: true,
		showCheckAll: true,
		dynamicTitleMaxItems: 3,
		checkedStyle: 'checkboxes',
		buttonClasses: 'btn btn-default',
		itemClasses: 'dropdown-menu'
	};
	public multiselectOptionsUnidades: IMultiSelectOption[];
	public multiselectTextsUnidades: IMultiSelectTexts = {
		checkAll: 'Selecionar todas',
		uncheckAll: 'Limpar',
		checked: 'selecionada',
		checkedPlural: 'selecionadas',
		searchPlaceholder: 'Pesquisar...',
		defaultTitle: 'Selecionar',
		allSelected: 'Todas selecionadas',
		searchEmptyResult: 'Nenhum registro encontrado',
		searchNoRenderText: 'Digite para pesquisar',
	};

	@ViewChild('inputBloco') inputBloco: ElementRef;
	@ViewChild('blocosPesquisaModal') blocosPesquisaModal: BlocosPesquisaModalComponent;
	@ViewChild('relatorioModal') relatorioModal: RelatorioModalComponent;

	constructor(private authService: AuthService,
		private messageService: MessageService,
		private unidadesHttpService: UnidadesHttpService,
		private blocosHttpService: BlocosHttpService) {
	}

	ngOnInit() {
		this.limpar();
		this.filtro.id_cliente = this.authService.getIdCurrentCliente();
		this.filtro.id_condominio = this.authService.getIdCurrentCondominio();;
		this.filtro.id_usuario = this.authService.getIdCurrentUser();
		this.getBlocosByCondominio(this.filtro.id_condominio);
		this.getUnidades();
	}

	public limpar(): void {
		this.filtro.limpar();
		this.getUnidades();
	}

	private validateView(): boolean {
		if (!this.filtro.competencia) {
			this.messageService.info('É necessário informar a competência início para imprimir', 'Informação');
			return false;
		}
		return true;
	}

	public view(): void {
		if (this.validateView()) {
			this.relatorioModal.url = '/relatorio/previsao-por-fracao';
			this.relatorioModal.params = this.filtro;
			this.relatorioModal.open();
		}
	}

	private getUnidades(): void {
		this.unidades = [];
		this.multiselectOptionsUnidades = [];
		this.unidadesHttpService.getAllByCondominioBloco(this.filtro.id_condominio, this.filtro.id_bloco).subscribe(
			entities => {
				if (entities) {
					const selects: IMultiSelectOption[] = entities.map(entity => {
						return {
							id: entity.id,
							name: `${entity.nome_bloco} - ${entity.nome_unidade}`
						};
					});
					this.multiselectOptionsUnidades = selects;
				}
			},
			error => this.messageService.error('Erro ao carregar as unidades do condomínio. Tente novamente', 'Erro', error)
		);
	}

	private getBlocosByCondominio(idCondominio: number) {
		this.blocosHttpService.getAllByCondominio(idCondominio).subscribe(
			entitites => {
				this.blocos = entitites
				if (this.blocoUnico(this.blocos)) {
					this.setBloco(this.blocos[0]);
					this.showInputBlocos = false;
				} else if (!this.blocos) {
					this.messageService.error('Não existem blocos cadastrados no Condomínio. Cadastre  um bloco e tente novamente', 'Erro')
				}
			},
			error => this.messageService.error('Erro ao carregar as unidades do condomínio. Tente novamente', 'Erro', error)
		);
	}

	private setBloco(blocoSelecionado: Bloco) {
		this.bloco = blocoSelecionado;
		this.filtro.id_bloco = this.bloco.id;
		this.getUnidades();
	}

	onSelectBloco(tm: TypeaheadMatch): void {
		this.setBloco(tm.item as Bloco);
	}

	openBlocosPequisaModal() {
		this.blocosPesquisaModal.open();
	}

	onSelectBlocosPesquisaModal(bloco: Bloco) {
		if (bloco) {
			this.setBloco(bloco)
		}
		jQuery('#bloco').focus();
	}

	onCleanBloco(input: any) {
		this.bloco = null;
		this.filtro.id_bloco = null;
		this.getUnidades();
		input.value = '';
		input.focus();
	}

	onChangeCompetencia() {
		if (this.filtro.competencia) {
			this.filtro.competencia.setDate(1);
		}
	}

	private blocoUnico(blocos: Bloco[]): boolean {
		return blocos.length === 1;
	}

}
