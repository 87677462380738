import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { MyAccountComponent } from './my-account.component';
import { MyAccountSecurityComponent } from './security/my-account.security.component';

@NgModule({
    declarations: [
        MyAccountComponent,
        MyAccountSecurityComponent
    ],
    imports     : [BrowserModule],
})

export class MyAccountModule {}
