import { Injectable } from '@angular/core';
import { ConfigService, Crud, CrudService, CustomHttp } from 'app/core';
import { CertificadoDigital } from 'app/models';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs-compat/observable/throw';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CertificadoDigitalHttpService implements Crud<CertificadoDigital> {

  resource = 'certificado-digital';
  private baseUrl: string;
  private url: string;

  constructor(private customHttp: CustomHttp,
      private crudService: CrudService,
      private configService: ConfigService) {
    this.url = configService.getBaseUrl();
    this.baseUrl = `${this.url}/${this.resource}`;
  }

  get(id: number): Observable<CertificadoDigital> {
    return this.crudService.get<CertificadoDigital>(this.resource, id);
  }
  getAll(): Observable<CertificadoDigital[]> {
    return this.crudService.getAll<CertificadoDigital>(this.resource);
  }
  post(entity: CertificadoDigital): Observable<CertificadoDigital> {
    return this.crudService.post<CertificadoDigital>(this.resource, entity);
  }
  put(entity: CertificadoDigital): Observable<CertificadoDigital> {
    return this.crudService.put<CertificadoDigital>(this.resource, entity);
  }
  delete(entity: CertificadoDigital): Observable<string> {
    return this.crudService.delete<CertificadoDigital>(this.resource, entity);
  }

  public passwordValidate(entity: CertificadoDigital): Observable<any> {
    return this.customHttp.post(`${this.baseUrl}/valida-senha`, entity).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
  }
}
