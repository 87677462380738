import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { AuthService, MessageService } from 'app/core';
import { CategoriaContaGrupo } from 'app/models';
import { CategoriaContaGrupoHttpService } from '../service';

declare var jQuery: any;

@Component({
  selector: 'categoria-conta-grupo-cadastro-modal',
  templateUrl: './categoria-conta-grupo-cadastro-modal.component.html',
  styleUrls: ['./categoria-conta-grupo-cadastro-modal.component.css']
})
export class CategoriaContaGrupoCadastroModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @Input() id: number;
  @Output() onSave: EventEmitter<CategoriaContaGrupo> = new EventEmitter();

  public entity: CategoriaContaGrupo;

  constructor(private categoriaContaGrupoHttpService: CategoriaContaGrupoHttpService,
    private messageService: MessageService,
    private authService: AuthService) { }

  ngOnInit() {
    this.entity = new CategoriaContaGrupo();
    if (!this.idModal) {
      this.idModal = 'categoriaContaGrupoCadastroModal';
    }
  }

  /**
   * Carregar objeto caso exista
   * @author Marcos Frare
   * @since 26/08/2021
   */
  private loadEntity(): void {
    this.entity = new CategoriaContaGrupo();
    this.entity.id_condominio = this.authService.getIdCurrentCondominio();
    if (this.id) {
      this.categoriaContaGrupoHttpService.get(this.id).subscribe(
        response => {
          this.entity = response;
        },
        error => {
          this.messageService.error('Erro ao buscar dados do grupo! Tente novamente', 'Erro!', error);
        }
      );
    }
  }

  /**
   * Abrir tela para cadastro de grupo
   * @author Marcos Frare
   * @since 26/08/2021
   */
  public open() {
    this.loadEntity();
    jQuery(`#${this.idModal}`).modal('show');
  }
  public close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Salvar grupo
   * @author Marcos Frare
   * @since 18/03/2021
   */
  public salvar(): void {
    let btn = jQuery('#btnSaveGrupoCategoriaConta');
    btn.button('loading');
    const fn = this.entity.id ? this.categoriaContaGrupoHttpService.put(this.entity) : this.categoriaContaGrupoHttpService.post(this.entity);
    fn.subscribe(
      response => {
        btn.button('reset');
        this.messageService.success('Grupo categoria de conta salvo com sucesso!');
        this.onSave.emit(response);
        this.close();
      },
      error => {
        btn.button('reset');
        this.messageService.error('Erro ao salvar grupo categoria de conta! Tente novamente', 'Erro', error);
      }
    );
  }

}