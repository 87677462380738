import { Component, OnInit, Input, EventEmitter, ElementRef, forwardRef, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseModal } from 'app/componentes-utils/modal';
import { MessageService, AuthService } from 'app/core';
import { ConciliacaoBancariaHttpService } from '../service';

declare var jQuery: any;

@Component({
  selector: 'importacao-extrato',
  templateUrl: './importacao-extrato.component.html',
  styleUrls: ['./importacao-extrato.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ImportacaoExtratoComponent), multi: true }
  ]
})
export class ImportacaoExtratoComponent implements OnInit, BaseModal, ControlValueAccessor {

  @Input() idModal: string;
  @Input() id_conta_bancaria: number;
  
  @Input() ngControl: NgControl;
  @Output() ngModelChange = new EventEmitter<any>();
  @Output() change = new EventEmitter<any>();
  @ViewChild('fileInput') fileInput: ElementRef;
  
  public propagateChange: any = () => {};
  public file: any;

  constructor(private messageService: MessageService,
      private conciliacaoBancariaHttpService: ConciliacaoBancariaHttpService,
      private authService: AuthService) { }

  ngOnInit() {
    this.file = null;
  }

  /**
   * Importação do arquivo para processar
   * @author Marcos Frare
   * @since 30/06/2020
   */
  public importar(): void {
    let btnImport = jQuery('#btnImportar');
    btnImport.button('loading');

    let params = {
      id_usuario: this.authService.getIdCurrentUser(),
      id_conta_bancaria: this.id_conta_bancaria,
      file: this.file,
    }
    this.conciliacaoBancariaHttpService.importar(params).subscribe(
      response => {
        btnImport.button('reset');
        if (!response.valido) {
          this.messageService.warning(response.msg, 'Atenção!');
        } else {
          this.messageService.success('Arquivo importado com sucesso!');
          this.close();
        }
      },
      error => {
        btnImport.button('reset');
        this.messageService.error('Erro na importação do arquivo. Tente novamente!', 'Erro!');
      }
    );
  }
  
  open() {
    this.onFileClear();
    jQuery(`#${this.idModal}`).modal('show');
  }
  close() {
    this.onFileClear();
    jQuery(`#${this.idModal}`).modal('hide');
  }

  public writeValue(value): void {
    if (value) {
      this.file = value;
    } else {
      this.fileInput.nativeElement.value = '';
    }
  }

  public registerOnChange(fn): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(): void {}

  public onFileClick(): void {
    this.fileInput.nativeElement.click();
  }

  public onFileChange(event: any): void {
    const file = event.target.files[0];
    const reader = new FileReader();
    // reader.readAsDataURL(file);
    reader.readAsBinaryString(file); // Carregar sempre como texto puro
    reader.onload = (e: ProgressEvent) => {
      this.file = {
        id: this.file ? this.file.id || null : null,
        nome: file.name,
        tipo: file.type,
        arquivo: reader.result
      };
      this.propagateChange(this.file);
      this.change.emit(this.file);
    };
  }

  public onFileClear(): void {
    this.file = undefined;
    this.fileInput.nativeElement.value = '';
    this.propagateChange(this.file);
    this.change.emit(this.file);
  }

  public onFileDownload(): void {
    if (!this.file) { return null; };
    const a = window.document.createElement('a');
    a.href = this.file.arquivo;
    a.download = this.file.nome;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

}