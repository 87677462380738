import { Pipe, PipeTransform } from '@angular/core';

import { tipoPagamento } from '../../../models';

@Pipe({
    name: 'tipoPagamento'
})
export class TipoPagamentoPipe implements PipeTransform {
    transform(value: number): string {
        if (!value) {
            return '';
        }

        let index = tipoPagamento.findIndex(tipo => tipo.id === value);
        if (index > -1) {
            return tipoPagamento[index].nome;
        }
        return '';
    }
}
