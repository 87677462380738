import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseVisualizacaoComponent } from 'app/componentes-base';
import { AuthService, MessageService } from 'app/core';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { TipoVeiculo } from 'app/models';
import { NgxSpinnerService } from 'ngx-spinner';
import { TipoVeiculoHttpService } from './service';
import { TipoVeiculoCadastroModalComponent } from './tipo-veiculo-cadastro-modal';

@Component({
  selector: 'tipo-veiculo',
  templateUrl: './tipo-veiculo.component.html',
  styleUrls: ['./tipo-veiculo.component.css']
})
export class TipoVeiculoComponent extends BaseVisualizacaoComponent<TipoVeiculo> {

  @ViewChild('tipoVeiculoCadastroModal') tipoVeiculoCadastroModal: TipoVeiculoCadastroModalComponent;

  constructor(public activatedRoute: ActivatedRoute,
      public router: Router,
      public messageService: MessageService,
      private authService: AuthService,
      private tipoVeiculoHttpService: TipoVeiculoHttpService,
      public permissionService: PermissionService,
      public spinner: NgxSpinnerService) {
    super('#tableTipoVeiculo', tipoVeiculoHttpService, activatedRoute, router, messageService, permissionService, spinner);
  }

  ngOnInit() {
    if(!this.authService.isAdmin) {
      this.router.navigate(['/dashboard'], {relativeTo: this.activatedRoute});
    }
    this.search();
  }

  /**
   * Excluir um tipo
   * @param entity Objeto a ser excluído
   * @author Marcos Frare
   * @since 10/04/2021
   */
   public delete(entity: TipoVeiculo): void {
    if (confirm(`Deseja excluir o fabricante ${entity.nome}?`)) {
      this.tipoVeiculoHttpService.delete(entity).subscribe(
        () => {
          this.search();
          this.messageService.success('Fabricante excluído com sucesso!', 'Sucesso!');
        },
        error => {
          this.messageService.error(`Erro ao excluir fabricante ${entity.nome}!`, 'Erro', error);
        }
      );
    }
  }

  /**
   * Busca dados
   * @author Marcos Frare
   * @since 10/04/2021
   */
  public search(): void {
    super.getAll();
  }

  /**
   * Editar registro
   * @param entity 
   * @author Marcos Frare
   * @since 10/04/2021
   */
  public edit(entity: TipoVeiculo): void {
    this.tipoVeiculoCadastroModal.id = entity.id;
    this.tipoVeiculoCadastroModal.open();
  }

  /**
   * Incluir novo fabricante
   * @author Marcos Frare
   * @since 10/04/2021
   */
  public insert(): void {
    this.tipoVeiculoCadastroModal.id = null;
    this.tipoVeiculoCadastroModal.open();
  }

}