import { BaseEntity } from "../base-entity";
import { CategoriaContas } from "./categoria-contas";
import { TipoConsumo } from "./tipo-consumo";

export class CondominioTiposConsumo extends BaseEntity {
	id: number;
	id_condominio: number;
	id_tipo_consumo: number;
	id_categoria_conta: number;
	meses_calculo: number;
	tolerancia_superior: number;
	tolerancia_inferior: number;

	categoriaConta: CategoriaContas;
	tipoConsumo: TipoConsumo;

	constructor() {
		super();
	}
}