import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseVisualizacaoComponent } from 'app/componentes-base';
import { AuthService, MessageService } from 'app/core';
import { CertificadoDigital } from 'app/models';
import { CertificadoDigitalHttpService } from './service';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CertificadoDigitalCadastroModalComponent } from './certificado-digital-cadastro-modal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'certificado-digital',
  templateUrl: './certificado-digital.component.html',
  styleUrls: ['./certificado-digital.component.css']
})
export class CertificadoDigitalComponent extends BaseVisualizacaoComponent<CertificadoDigital> implements OnDestroy {

  @ViewChild('certificadoDigitalCadastroModal') certificadoDigitalCadastroModal: CertificadoDigitalCadastroModalComponent;

  private subs: Subscription[] = [];

  constructor(public activatedRoute: ActivatedRoute,
      public router: Router,
      public messageService: MessageService,
      private authService: AuthService,
      private certificadoDigitalHttpService: CertificadoDigitalHttpService,
      public permissionService: PermissionService,
      public spinner: NgxSpinnerService) {
    super('#tableCertificadoDigital', certificadoDigitalHttpService, activatedRoute, router, messageService, permissionService, spinner);
  }

  ngOnInit() {
    if(!this.authService.isAdmin) {
      this.router.navigate(['/dashboard'], {relativeTo: this.activatedRoute});
    }
    this.search();
  }

  ngOnDestroy(): void {
    try {
			this.subs.map(s => s.unsubscribe());
		} catch (err) {
			console.error(err);
		}
  }

  public search(): void {
    super.getAll();
  }

  /**
   * Incluir novo banco
   * @author Marcos Frare
   * @since 18/03/2021
   */
  public insert(): void {
    this.certificadoDigitalCadastroModal.id = null;
    this.certificadoDigitalCadastroModal.open();
  }

  public delete(entity: CertificadoDigital): void {
    if (confirm(`Deseja excluir o certificado?`)) {
      this.subs.push(this.certificadoDigitalHttpService.delete(entity).subscribe(
        () => {
          this.search();
          this.messageService.success('Banco excluído com sucesso!', 'Sucesso!');
        },
        error => this.messageService.error(`Erro ao excluir certificado!`, 'Erro', error)
      ));
    }
  }

}
