import { BaseEntity } from '../base-entity';
import { Renegociacao } from './renegociacao';

export class Titulo extends BaseEntity {
	id: number;
	id_arquivo_remessa: number;
	id_rateio_unidade: number;
	id_demonstrativo_rateio: number;
	id_unidade: number;
	id_bloco: number;
	nome_bloco: string;
	nome_unidade: string;
	origem: string;
	selected: boolean;
	id_sacado: number;
	codigo_baixa: number;
	codigo_multa: number;
	codigo_juros: number;
	data_documento: Date;
	data_juros: Date;
	data_multa: Date;
	data_processamento: Date;
	data_vencimento: Date;
	juros_mora: number;
	nosso_numero: number;
	numero_documento: number;
	percentual_multa: number;
	prazo_baixa: number;
	prazo_protesto: number;
	valor_unitario: number;
	valor_original: number;
	valor_multa: number;
	valor_juros: number;
	valor_pago: number;
	data_pagamento: Date;
	titulos: any[];
	renegociacoes: Renegociacao;
	updated: boolean;
	open: boolean;
	situacao: number;
	situacao_emissao: number;

	situacaoDesc: string;
	linha_digitavel: string;
	data_competencia: Date;
	acao: string;
	id_boleto: number;

	renegociacao: number;
	alterarSacadoUnidade: boolean;
	nome_sacado: string;
	data_credito: Date;
}

export enum SITUACAO_BOLETO {
	VENCIDO = 1,
	PAGO = 2,
	EMABERTO = 3,
	RENEGOCIADO = 4,
	BAIXASOLICITADA = 5,
	BAIXACONFIRMADA = 6,
	BAIXAJUDICIAL = 7,
	BAIXABANCO = 8
}

export enum STATUS_ENVIO_BOLETO {
	ENVIADO = 1,
	INEXISTENTE = 2,
	INVALIDO = 3,
}

export const situacoesBoleto = [
	{ id: 1, nome: 'Vencido' },
	{ id: 2, nome: 'Pago' },
	{ id: 3, nome: 'Em aberto' },
	{ id: 4, nome: 'Renegociado' },
	{ id: 5, nome: 'Baixa solicitada' },
	{ id: 6, nome: 'Baixa confirmada' },
	{ id: 7, nome: 'Cobranca extra/judicial' },
	{ id: 8, nome: 'Baixado pelo Banco' }
];

export enum SITUACAO_EMISSAO_BOLETO {
	IMPRESSO = 1,
	ENVIADO = 2,
	IMPRESSOENVIADO = 3
};

export const situacoesEmissaoBoleto = [
	{ id: 1, nome: 'Impresso' },
	{ id: 2, nome: 'Enviado' },
	{ id: 3, nome: 'Impresso e enviado' },
];