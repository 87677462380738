import { BaseEntity } from "../base-entity";
import { Anexo } from './anexo';

export class Seguro extends BaseEntity {
	id: number;
	id_fornecedor: number;
	id_condominio: number;
	id_categoria_conta: number;
	categoriaContas: any;
	numero_apolice: number;
	data_solicitacao: Date;
	fornecedor: string;
	conta_bancaria: string;
	valor_assegurado: any;
	anexos: Anexo[];
	descricao: string;
	data_vigencia_inicio: Date;
	data_vigencia_fim: Date;

	constructor() {
		super();
		this.anexos = [new Anexo()];
	}
}