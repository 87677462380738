import { BaseEntity } from "../base-entity";

export class Banco extends BaseEntity {
	nome: string;
	tag: string;
	numero: string;
	digito_numero: string;
	frase_local_pagamento: string;
	versao_lote: string;
	versao_arquivo: string;
	situacao: string;
	integra_api: boolean;
	integra_pix: boolean;

	logo_boleto: any;

	constructor() {
		super();
		this.situacao = 'ATIVO';
	}
}