import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MessageService, AuthService } from '../../core';
import { PlanoDeContas } from '../../models';
import { PlanoDeContasHttpService } from './services';

declare var jQuery: any;

@Component({
	selector: 'plano-de-contas',
	templateUrl: './plano-de-contas.component.html'
})
export class PlanoDeContasComponent implements OnInit {

	public entities: PlanoDeContas[] = [];
	public idCondominio: number;
	public idCliente: number;

	constructor(private activatedRoute: ActivatedRoute,
		private router: Router,
		private messageService: MessageService,
		private planoDeContasHttpService: PlanoDeContasHttpService,
		private authService: AuthService) {
	}

	ngOnInit() {
		this.idCliente = this.authService.getIdCurrentCliente();
		this.idCondominio = this.authService.getIdCurrentCondominio();
		this.getAll();
	}

	getAll() {
		const callback = this.idCondominio ? this.planoDeContasHttpService.getAllByCondominio(this.idCondominio) : this.planoDeContasHttpService.getAllByCliente(this.idCliente);
		callback.subscribe(
			entities => {
				this.entities = entities;
				this.showTable();
			},
			error => this.messageService.error('Erro ao consultar os planos de contas. Tente novamente', 'Erro', error)
		);
	}

	private showTable() {
		setTimeout(() =>
			jQuery('#tablePlanoDeConta').DataTable({
				columnDefs: [
					{ orderable: false, targets: -1, width: '40px' }
				],
				language: { 'url': './assets/scripts/datatables-ptbr.json' }
		}), 200);
	}

	insert() {
		this.router.navigate(['./new'], { relativeTo: this.activatedRoute });
	}

	edit(entity: PlanoDeContas) {
		this.router.navigate(['./', entity.id], { relativeTo: this.activatedRoute });
	}
}