import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseVisualizacaoComponent } from '../../componentes-base';
import { Bloco } from '../../models';

import { AuthService, MessageService } from '../../core';
import { BlocosHttpService } from './services';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { ConfiguracaoServicoUnidadeModalComponent } from './configuracao-servico-unidade-modal';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
    selector: 'blocos',
    templateUrl: './blocos.component.html',
    styleUrls: ['./blocos.component.css'],
    providers: [PermissionService]
})
export class BlocosComponent extends BaseVisualizacaoComponent<Bloco> implements OnInit {

    @ViewChild('configuracaoServicoUnidadeModal') configuracaoServicoUnidadeModal: ConfiguracaoServicoUnidadeModalComponent;

    constructor(activatedRoute: ActivatedRoute,
                router: Router,
                messageService: MessageService,
                private authService: AuthService,
                private blocosHttpService: BlocosHttpService,
                permissionService: PermissionService,
                public spinner: NgxSpinnerService) {
        super('#tableBlocos', blocosHttpService, activatedRoute, router, messageService, permissionService, spinner);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    // Carrega todos os blocos do condomínio selecionado
    public getAll() {
        this.entities = [];
        this.destroyTable();
        this.blocosHttpService.getAllByCondominio(this.authService.getIdCurrentCondominio())
            .subscribe(
                entities => {
                    this.entities = entities;
                    this.showTable();
                },
                error => this.messageService.error('Erro ao carregar os blocos do condomínio. Tente novamente', 'Erro', error)
            );
    }

    public delete(bloco: Bloco) {
        if (confirm(`Tem certeza que deseja excluir o bloco ${bloco.nome}?`)) {
            this.blocosHttpService.delete(bloco)
                .subscribe(
                    () => {
                        this.messageService.success('', `O bloco ${bloco.nome} foi excluido com sucesso`);
                        this.getAll();
                    },
                    error => {
                        switch (error.status) {
                            case 403:
                                this.messageService.warning(`O bloco ${bloco.nome}, não pode ser excluído pois o mesmo possui lançamentos no financeiro.`, 'Atenção', error);
                                break;
                            case 404:
                                this.messageService.warning(`Bloco não encontrado, atualize a consulta e tente novamente.`, 'Atenção', error);
                                break;
                            case 500:
                                this.messageService.error(`Erro ao excluir o bloco ${bloco.nome}. Tente novamente`, 'Erro', error);
                                break;
                            default:
                                break;
                        }
                    }
                );
        }
    }

    /**
     * Abre tela de configuração dos serviços nas unidades
     * @author Marcos Frare
     * @since 26/02/2020
     */
    public configServicos() {
        this.configuracaoServicoUnidadeModal.open();
    }

    /**
     * Abrir modal para configuração de de filtro para emissão do relatório
     * @author Marcos Frare
     * @since 01/02/2021
     */
    public relatorioCondomino(): void {
        // this.condominosRel.open();
        alert('Relatório de condôminos');
    }
}