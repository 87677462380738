import { map, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RequestOptions, RequestOptionsArgs } from '@angular/http';

import { CustomHttp } from '../http';
import { ConfigService } from '../../config';
import { AuthService } from '../auth';

import { BaseEntity } from '../../../models';

/**
 * Service com os métodos de CRUD das entidades
 */
@Injectable()
export class CrudService {

	private baseUrl: string;

	constructor(private customHttp: CustomHttp,
		private configService: ConfigService,
		private authService: AuthService) {
		this.baseUrl = configService.getBaseUrl();
	}

	get<T extends BaseEntity>(resource: string, id: number, options?: RequestOptionsArgs): Observable<T> {
		return this.customHttp.get(`${this.baseUrl}/${resource}/consultar/${id}`, options)
			.pipe(
				map(response => response.json()),
				catchError(error => _throw(error))
			);
	}

	getAll<T extends BaseEntity>(resource: string, options?: RequestOptionsArgs): Observable<T[]> {
		return this.customHttp.get(`${this.baseUrl}/${resource}/consultar`, options)
			.pipe(
				map(response => response.json()),
				catchError(error => _throw(error))
			);
	}

	post<T extends BaseEntity>(resource: string, entity: T, options?: RequestOptionsArgs): Observable<T> {
		entity.id_usuario = this.authService.getIdCurrentUser();
		return this.customHttp.post(`${this.baseUrl}/${resource}/cadastrar`, entity, options)
			.pipe(
				map(response => response.json()),
				catchError(error => _throw(error))
			);
	}

	put<T extends BaseEntity>(resource: string, entity: T, options?: RequestOptionsArgs): Observable<T> {
		entity.id_usuario = this.authService.getIdCurrentUser();
		return this.customHttp.put(`${this.baseUrl}/${resource}/editar/${entity.id}`, entity, options)
			.pipe(
				map(response => response.json()),
				catchError(error => _throw(error))
			);
	}

	delete<T extends BaseEntity>(resource: string, entity: T, options?: RequestOptionsArgs): Observable<string> {
		entity.id_usuario = this.authService.getIdCurrentUser();
		if (!options) {
			options = new RequestOptions();
		}
		options.body = entity;

		return this.customHttp.delete(`${this.baseUrl}/${resource}/excluir/${entity.id}`, options)
			.pipe(
				map(response => response),
				catchError(error => _throw(error))
			);
	}

	getNew<T extends BaseEntity>(resource: string, id: number, options?: RequestOptionsArgs): Observable<T> {
		return this.customHttp.get(`${this.baseUrl}/${resource}/${id}`, options)
			.pipe(
				map(response => response.json()),
				catchError(error => _throw(error))
			);
	}

	getAllNew<T extends BaseEntity>(resource: string, options?: RequestOptionsArgs): Observable<T[]> {
		return this.customHttp.get(`${this.baseUrl}/${resource}`, options)
			.pipe(
				map(response => response.json()),
				catchError(error => _throw(error))
			);
	}

	postNew<T extends BaseEntity>(resource: string, entity: T, options?: RequestOptionsArgs): Observable<T> {
		entity.id_usuario = this.authService.getIdCurrentUser();
		return this.customHttp.post(`${this.baseUrl}/${resource}`, entity, options)
			.pipe(
				map(response => response.json()),
				catchError(error => _throw(error))
			);
	}

	putNew<T extends BaseEntity>(resource: string, entity: T, options?: RequestOptionsArgs): Observable<T> {
		entity.id_usuario = this.authService.getIdCurrentUser();
		return this.customHttp.put(`${this.baseUrl}/${resource}/${entity.id}`, entity, options)
			.pipe(
				map(response => response.json()),
				catchError(error => _throw(error))
			);
	}

	deleteNew<T extends BaseEntity>(resource: string, entity: T, options?: RequestOptionsArgs): Observable<string> {
		entity.id_usuario = this.authService.getIdCurrentUser();
		if (!options) {
			options = new RequestOptions();
		}
		options.body = entity;

		return this.customHttp.delete(`${this.baseUrl}/${resource}/${entity.id}`, options)
			.pipe(
				map(response => response),
				catchError(error => _throw(error))
			);
	}

}
