import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { MessageService, FormsUtilsService, AuthService } from '../../../core';
import { ClientesHttpService } from '../services';
import { Cliente, Cidade, tiposTelefones, Telefone, tiposCliente } from '../../../models';

import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { mergeMap } from 'rxjs/operators';
import { CidadeHttpService } from '../../../componentes-utils/cidade';
import { Observable } from 'rxjs';
import { EnderecoHttpService } from '../../../componentes-utils/endereco';
import { CpfFormatPipe, CnpjFormatPipe } from '../../../shared/pipes';

declare var jQuery: any;

@Component({
	selector: 'clientes-cadastro',
	templateUrl: './clientes-cadastro.component.html',
	styleUrls: ['./clientes-cadastro.component.css'],
	providers: [ClientesHttpService, CpfFormatPipe, CnpjFormatPipe]
})
export class ClientesCadastroComponent implements OnInit {

	public entity: Cliente = new Cliente();
	public editMode: boolean = false;
	public btnSave: any;
	public typeaheadCidadeToken: string;
	public dataSourceCidades: Observable<Cidade[]>;
	public tiposTelefones: any[] = tiposTelefones;
	public tiposCliente: any[] = tiposCliente;
	public validEmail: boolean;
	public email: string;

	constructor(private activatedRoute: ActivatedRoute,
		private authService: AuthService,
		private router: Router,
		private messageService: MessageService,
		private clientesHttpService: ClientesHttpService,
		private enderecoHttpService: EnderecoHttpService,
		private cidadeHttpService: CidadeHttpService,
		private formsUtilsService: FormsUtilsService,
		private cpfFormatPipe: CpfFormatPipe,
		private cnpjFormatPipe: CnpjFormatPipe) {
		this.dataSourceCidades = this.createDataSourceCidades();
	}

	ngOnInit() {
		if (!this.authService.isAdmin) this.router.navigate(['/dashboard'], { relativeTo: this.activatedRoute });
		this.activatedRoute.params.subscribe((params: Params) => {
			let id = +params['id'];
			if (!isNaN(id)) {
				this.editMode = true;
				this.load(id);
			} else {
				this.editMode = false;
				this.addTelefone();
			}
		});
	}

	ngAfterViewInit() {
		this.btnSave = jQuery('#btnSave');
	}

	private load(id: number): void {
		this.clientesHttpService.get(id).subscribe(
			entity => {
				this.entity = entity;
				this.email = this.entity.email;
				this.addTelefone();
				this.getCidadeByName(this.entity.endereco.id_cidade);
			},
			error => this.messageService.error('Erro', 'Erro ao obter dados do cliente, Favor tente novamente!', error)
		);
	}

	public save() {
		this.btnSave = jQuery('#btnSave');
		this.btnSave.button('loading');
		if (!this.validateForm()) return false;
		this.checkTelefones();
		let save: Observable<Cliente>;
		if (this.editMode) {
			this.entity.id_usuario_cliente = this.entity.id_usuario;
			save = this.clientesHttpService.put(this.entity);
		} else {
			save = this.clientesHttpService.post(this.entity);
		}
		save.subscribe(
			entity => this.afterSave(),
			error => {
				this.messageService.error('Erro ao cadastrar cliente', 'Erro', error);
				this.btnSave.button('reset');
			}
		);
	}

	public afterSave(): void {
		this.btnSave.button('reset');
		this.messageService.success('Cliente cadastrado com sucesso', 'Sucesso')
		this.cancel();
	}

	public cancel() {
		this.router.navigate(['../'], { relativeTo: this.activatedRoute });
	}

	private createDataSourceCidades(): Observable<Cidade[]> {
		return Observable.create((observer: any) => {
			observer.next(this.typeaheadCidadeToken);
		}).pipe(
			mergeMap(() => this.cidadeHttpService.getAllByNome(this.typeaheadCidadeToken))
		);
	}

	public checkEmail(email: string): void {
		if (!email) return
		this.clientesHttpService.checkEmail(email).subscribe(
			response => this.validEmail = true,
			error => {
				this.validEmail = false
				if (this.editMode && this.email === this.entity.email) {
					this.validEmail = true;
				}
			}
		);
	}

	public onChangeCpf(event: any): void {
		this.entity.cpf = this.cpfFormatPipe.transform(event);
	}

	public onChangeCnpj(event: any): void {
		this.entity.cnpj = this.cnpjFormatPipe.transform(event);
		if (this.entity.cnpj && this.entity.cnpj.length === 18)
			jQuery('#tipo').focus();
	}

	public onChangeCep(): void {
		if (this.entity.endereco.cep && this.entity.endereco.cep.length === 9)
			this.getAddressByCep(this.entity.endereco.cep);
	}

	public getAddressByCep(cep): void {
		this.enderecoHttpService.getAddressByCep(cep).subscribe(
			(response: any) => {
				this.entity.endereco = response;
				this.entity.endereco.cep = cep;
				this.getCidadeByName(response.id_cidade);
				jQuery('#numero').focus();
			},
			error => this.messageService.warning('Não foi possível localizar o endereço com o CEP informado, verifique se o mesmo foi digitado corretamente', 'CEP é inexistente')
		);
	}

	private getCidadeByName(idCidade: number): void {
		this.cidadeHttpService.get(idCidade).subscribe(
			cidade => this.entity.endereco.cidade = cidade
		);
	}

	public onSelectCidade(tm: TypeaheadMatch) {
		const cidade: Cidade = tm.item as Cidade;
		if (cidade) {
			this.entity.endereco.id_cidade = cidade.id;
			this.entity.endereco.cidade = cidade;
		} else {
			this.entity.endereco.id_cidade = null;
			this.entity.endereco.cidade = null;
		}
	}

	public onCleanCidade(input: any) {
		this.entity.endereco.id_cidade = null;
		this.entity.endereco.cidade = null;

		input.value = '';
		input.focus();
	}

	public onChangeRazaoSocial(razaoSocial: string): void {
		this.entity.nome_fantasia = razaoSocial;
	}

	public addTelefone(): void {
		this.entity.telefones.push(new Telefone());
	}

	public removeTelefone(telefone): void {
		const index = this.entity.telefones
			.findIndex(it => it.numero === telefone.numero);
		this.entity.telefones.splice(index, 1)
	}

	private checkTelefones(): void {
		const telefones = this.entity.telefones;
		const invalidNumbers = telefones.filter(it => !it.numero);
		invalidNumbers.forEach(it => {
			telefones.splice(telefones.indexOf(it), 1);
		});
	}

	public validateForm(): boolean {
		if (this.entity.cnpj) {
			if (!this.formsUtilsService.isValidCnpj(this.entity.cnpj)) {
				this.messageService.info('O CNPJ informado não é um CNPJ válido', ' Informação');
				this.btnSave.button('reset');
				return false;
			}
		}
		if (!this.entity.cpf) {
			this.messageService.info('O CPF informado não é um CPF válido', ' Informação');
			this.btnSave.button('reset');
			return false;
		}
		if (this.entity.email) {
			if (this.editMode && this.email === this.entity.email) {
				this.btnSave.button('reset');
				return true;
			}
			if (!this.validEmail) {
				this.messageService.info('O email informado já consta cadastrado no sistema, tente outro email', ' Informação');
				this.btnSave.button('reset');
				return false;
			}
		}
		if (!this.entity.endereco) {
			this.messageService.info('O CEP informado não é um CEP válido', ' Informação');
			this.btnSave.button('reset');
			return false;
		}
		return true;
	}

	public formatPhone(telefone, value): void {
		let rawValue = this.clearDigitsSpecialChars(value)

		let front = 4
		if (rawValue.length > 10)
			front = 5
		let newValue = ''

		if (rawValue.length > 0)
			newValue += '(' + rawValue.substr(0, 2)
		if (rawValue.length > 2)
			newValue += ') ' + rawValue.substr(2, front)
		if (rawValue.length >= 7)
			newValue += '-' + rawValue.substr(front + 2, 4)

		telefone.numero = newValue;
	}

	private clearDigitsSpecialChars(value): string {
		if (value) {
			const anyNonDigitRegExp = /[^0-9]/g
			return value.replace(anyNonDigitRegExp, '')
		}

		return ""
	}

}
