import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';
import { CustomHttp } from '../http';
import { ConfigService } from '../../config';
import { Permission } from 'app/componentes-base';
import { MenuItem } from './menu-item';

@Injectable()
export class MenuService {
    private baseUrl: string;
    private baseUrlAcessos: string;

    constructor(private customHttp: CustomHttp,
                public configService: ConfigService) {
        this.baseUrl = `${configService.getBaseUrl()}/menu`;
        this.baseUrlAcessos = `${configService.getBaseUrl()}/acessos`;
    }

    public getMenusByClienteAndUsuario(idCliente: number, idUsuario: number): Observable<MenuItem[]> {
        return this.customHttp.get(`${this.baseUrl}/montar/cliente/${idCliente}/usuario/${idUsuario}`)
                    .pipe(
                        map(response => response.json()),
                        catchError(error => _throw(error))
                    );
    }

    public carregarListagem(): Observable<MenuItem[]> {
        return this.customHttp.get(`${this.baseUrl}/listar`)
                    .pipe(
                        map(response => response.json()),
                        catchError(error => _throw(error))
                    );
    }

    public checkResourcePermission(recurso: string, id_cliente: number, id_usuario: number): Observable<Permission> {
        return this.customHttp.post(`${this.baseUrlAcessos}/recurso`, {recurso, id_cliente, id_usuario})
                    .pipe(
                        map(response => response.json()),
                        catchError(error => _throw(error))
                    );
    }

   
}
