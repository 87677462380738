import { Injectable } from '@angular/core';

import { ToastrOptions } from './toastr-options';

declare var toastr: any;

const configDefaultError: ToastrOptions = {
    // O padrão não é fechar automaticamente
    extendedTimeOut: 0,
    timeOut: 0,
    closeButton: true
};

export const notCloseMessageOptions: ToastrOptions = {
    extendedTimeOut: 0,
    timeOut: 0,
    closeButton: true
};

@Injectable()
export class MessageService {

    /**
     * Exibe uma mensagem de sucesso
     */
    success(message: string, title?: string, options?: ToastrOptions) {
        toastr.success(message, title, options);
    }

    /**
     * Exibe uma mensagem de informação
     */
    info(message: string, title?: string, options?: ToastrOptions) {
        toastr.info(message, title, options);
    }

    /**
     * Exibe uma mensagem de atenção
     */
    warning(message: string, title?: string, options?: ToastrOptions) {
        toastr.warning(message, title, options);
    }

    /**
     * Exibe uma mensagem de erro
     * Por padrão a mensagem não fecha automaticamente
     */
    error(message: string, title?: string, error?: any, options?: ToastrOptions) {
        // if (!options) {
        //     options = configDefaultError;
        // }
        // Se tiver o erro informado logamos ele no console
        if (error) {
            console.log(error);
        }

        toastr.error(message, title, options);
    }
 
}
