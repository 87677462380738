import { NgModule } from '@angular/core';
import { RelatorioComponent } from './relatorio.component';
import { SharedModule } from 'app/shared';
import { RelatorioCadastroComponent } from './cadastro/relatorio-cadastro.component';
import { FiltroComponent } from './filtro/filtro.component';
import { FiltroCadastroComponent } from './filtro/cadastro/filtro-cadastro.component';
import { FiltroHttpService } from './filtro';
import { RelatorioFiltroModalComponent } from './relatorio-filtro-modal';
import { RelatorioFiltroHttpService } from './relatorio-filtro-modal/service/relatorio-filtro-http.service';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    RelatorioComponent,
    RelatorioCadastroComponent,
    FiltroComponent,
    FiltroCadastroComponent,
    RelatorioFiltroModalComponent
  ],
  providers: [
    FiltroHttpService,
    RelatorioFiltroHttpService
  ]
})
export class RelatorioModule { }
