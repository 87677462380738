import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService, MessageService } from 'app/core';
import { BlocosHttpService, BlocosPesquisaModalComponent, UnidadesHttpService, UnidadesPesquisaModalComponent } from 'app/home/blocos';
import { Bloco, Unidade } from 'app/models';
import { datepickerMesAnoOptions } from 'app/shared';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { RelatorioModalComponent } from '../relatorio-modal';
import { AntecipacaoRelFiltro } from './antecipacao-rel-filtro';

declare var jQuery: any;

@Component({
  selector: 'antecipacao-taxa-condominio-rel',
  templateUrl: './antecipacao-taxa-condominio-rel.component.html',
  styleUrls: ['./antecipacao-taxa-condominio-rel.component.css']
})
export class AntecipacaoTaxaCondominioRelComponent implements OnInit {
  @ViewChild('inputBloco') inputBloco: ElementRef;
	@ViewChild('inputUnidade') inputUnidade: ElementRef;
	@ViewChild('blocosPesquisaModal') blocosPesquisaModal: BlocosPesquisaModalComponent;
	@ViewChild('unidadesPesquisaModal') unidadesPesquisaModal: UnidadesPesquisaModalComponent;
	@ViewChild('relatorioModal') relatorioModal: RelatorioModalComponent;

  public filtro = new AntecipacaoRelFiltro();
  public blocos: Bloco[] = [];
	public unidades: Unidade[] = [];
  public datepickerDataCompetenciaOptions = datepickerMesAnoOptions;

  constructor(private authService: AuthService,
		private messageService: MessageService,
		private blocosHttpService: BlocosHttpService,
		private unidadesHttpService: UnidadesHttpService) { }

  ngOnInit() {
    this.filtro.id_cliente = this.authService.getIdCurrentCliente();
		this.filtro.id_condominio = this.authService.getIdCurrentCondominio();
		this.filtro.id_usuario = this.authService.getIdCurrentUser();
		this.limpar();
		this.getBlocosByCondominio(this.filtro.id_condominio);
  }

  private validateView(): boolean {
		if (!this.filtro.data_inicial) {
			this.messageService.info('É necessário informar a data inicial para imprimir', 'Informação');
			return false;
		}
		return true;
	}

	public view(): void {
		if (this.validateView()) {
			this.relatorioModal.url = '/relatorio/antecipacoes';
			this.relatorioModal.params = this.filtro;
			this.relatorioModal.open();
		}
	}

	public limpar(): void {
		this.filtro.limpar();
		this.onCleanBloco(this.inputBloco.nativeElement);
	}

	public getBlocosByCondominio(idCondominio: number): void {
		this.blocos = [];
		this.blocosHttpService.getAllByCondominio(idCondominio).subscribe(
			entities => {
				this.blocos = entities;
			},
			error => this.messageService.error('Erro ao carregar os blocos do condomínio. Tente novamente', 'Erro', error)
		);
	}

	private setBloco(blocoSelecionado: Bloco) {
		this.filtro.bloco = blocoSelecionado;
		this.filtro.id_bloco = blocoSelecionado.id;
		this.getAllUnidadesByBloco(this.filtro.id_bloco);
		this.onCleanUnidade(this.inputUnidade.nativeElement);
	}

	public onSelectBloco(tm: TypeaheadMatch): void {
		this.setBloco(tm.item as Bloco);
	}

	public openBlocosPequisaModal(): void {
		this.blocosPesquisaModal.open();
	}

	public onSelectBlocosPesquisaModal(bloco: Bloco): void {
		if (bloco) this.setBloco(bloco);
		jQuery('#bloco').focus();
	}

	public onCleanBloco(input: any): void {
		this.onCleanUnidade(this.inputUnidade.nativeElement);
		this.filtro.id_bloco = null;
		this.filtro.bloco = null;
		input.value = '';
		input.focus();
	}

	public getAllUnidadesByBloco(idBloco: number): void {
		this.unidades = [];
		this.unidadesHttpService.getAllByBloco(idBloco).subscribe(
			entities => {
        this.unidades = entities;
			},
			error => this.messageService.error('Erro ao carregar as unidades do bloco. Tente novamente', 'Erro', error)
		);
	}

	private setUnidade(unidadeSelecionada: Unidade) {
		this.filtro.unidade = unidadeSelecionada;
		this.filtro.id_unidade = unidadeSelecionada.id;
	}

	public onSelectUnidade(tm: TypeaheadMatch): void {
		this.setUnidade(tm.item as Unidade);
	}

	public onCleanUnidade(input: any): void {
		this.filtro.unidade = null;
		this.filtro.id_unidade = null;
		input.value = '';
		input.focus();
	}

	public openUnidadesPequisaModal(): void {
		this.unidadesPesquisaModal.open();
	}

	public onSelectUnidadesPesquisaModal(unidade: Unidade): void {
		if (unidade) this.setUnidade(unidade);
		jQuery('#unidade').focus();
	}

}
