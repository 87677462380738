import { NgModule } from '@angular/core';
import { ReservaBloqueioComponent } from './reserva-bloqueio.component';
import { SharedModule } from 'app/shared';
import { ReservaBloqueioCadastroModalComponent } from './reserva-bloqueio-cadastro-modal';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    ReservaBloqueioComponent,
    ReservaBloqueioCadastroModalComponent
  ],
  exports: []
})
export class ReservaBloqueioModule { }