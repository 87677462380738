import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseModal } from '../../../componentes-utils/modal';
import { UsuariosCadastroComponent } from '../cadastro/usuarios-cadastro.component';
import { Usuario, TIPO_USUARIO } from '../../../models';

declare var jQuery: any;

@Component({
    selector: 'usuarios-cadastro-modal',
    templateUrl: './usuarios-cadastro-modal.component.html'
})
export class UsuariosCadastroModalComponent implements OnInit, BaseModal {

    @Input() idModal: string;
    @Input() data: any;
    @Input() isSimpleForm: boolean = true;
    @Output() afterSave = new EventEmitter<any>();
    @Output() afterCancel = new EventEmitter<any>();
    
    @ViewChild('cadastro') cadastroComponent: UsuariosCadastroComponent;
    
    private btnSave: any;
    public idUnidade: number;
    public arrayPosition: number;

    constructor() { }

    ngOnInit() {
        if (!this.idModal) 
            this.idModal = 'usuariosCadastroModal';

        this.btnSave = jQuery('#btnSaveUsuariosModal');
        this.cadastroComponent.cancel = () => { };
        this.cadastroComponent.load = () => { };
    }

    private clear() {
        this.btnSave.button('reset');
        this.cadastroComponent.form.resetForm();
    }

    open(nome?: string, tipoUsuario?: number, index?: number, idUnidade: number = null) {
        this.clear();
        this.idUnidade = idUnidade;
        this.cadastroComponent.setTipoCadastro(tipoUsuario);

        if(index !== undefined) {
            this.arrayPosition = index;
        }

        jQuery(`#${this.idModal}`).modal('show');
        setTimeout(() => {
            this.cadastroComponent.editMode = false;
            this.cadastroComponent.entity = new Usuario();
            this.cadastroComponent.entity.nome = nome;
            this.cadastroComponent.entity.telefones = [];
            this.cadastroComponent.entity.tipo =  tipoUsuario;
            this.cadastroComponent.entity.endereco_condominio = true;
            if(tipoUsuario === TIPO_USUARIO.CONDOMINO && this.cadastroComponent.idCondominio) {
                this.cadastroComponent.entity.acessos_condominio.push(this.cadastroComponent.idCondominio);
                this.cadastroComponent.carregarEnderecoCondominio(this.cadastroComponent.idCondominio);
            }
            this.cadastroComponent.addTelefone(); 
            this.cadastroComponent.afterInsert();
            jQuery('.modal-body').find('input:first').focus();
        }, 200);
    }

    close() {
        jQuery(`#${this.idModal}`).modal('hide');
    }

    cancel() {
        this.afterCancel.emit(this.data);
        jQuery(`#${this.idModal}`).modal('hide');
    }

    save() {
        this.btnSave.button('loading');
        this.cadastroComponent.save()
            .then(entity => {
                if(this.arrayPosition) {
                    let event = {entity:{}, position:{}, data:{}};
                    event.entity = entity;
                    event.position = this.arrayPosition;
                    event.data = this.data
                    this.afterSave.emit(event);
                } else {
                    this.afterSave.emit(entity);
                }
                this.btnSave.button('reset');
                this.close();
            }).catch(() => {
                this.btnSave.button('reset');
            });
    }
}
