export class TitulosVencidosEnvio {

	public id_condominio: number;
	public id_cliente: number;
	public id_usuario: number;

	constructor(id_condominio: number, id_cliente: number, id_usuario: number) {
		this.id_condominio = id_condominio || null;
		this.id_cliente = id_cliente || null;
		this.id_usuario = id_usuario || null;
	}
}