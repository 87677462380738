import { BaseEntity } from '../base-entity';

export class TemplateLancamentoCondominioItemUnidade extends BaseEntity {
	id_template_lancamento_condominio_item: number;
	id_unidade: number;

	constructor() {
		super();
	}

}