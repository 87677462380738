export class Permission {

    public atualizar: boolean;
    public criar: boolean;
    public deletar: boolean;
    public ler: boolean;
    public gerencial: boolean;

    constructor(params?: any) {
        this.atualizar = params.atualizar || false;
        this.criar = params.criar || false;
        this.deletar = params.deletar || false;
        this.ler = params.ler || false;
        this.gerencial = params.gerencial || false;
    }

}