import { BaseEntity } from '../base-entity';
import { CategoriaContas } from './categoria-contas';

export class PrevisaoCategoriaConta extends BaseEntity {
	id_previsao_orcamentaria: number;
	id_categoria_conta: number;
	id_previsao_categoria_conta_pai: number;
	recebe_valor: boolean;
	valor_orcado_referencia: number;
	valor_executado_referencia: number;
	referencia_calculo: string;
	indice: number;
	valor_orcado: number;

	// Propriedades abstratas
	nome: string;
	codigo: string;
	categoria_conta: CategoriaContas;
	children: any[];

	constructor() {
		super();
		this.referencia_calculo = 'EXECUTADO';
	}
}