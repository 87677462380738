import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';

import { Crud, CrudService, ConfigService, CustomHttp } from '../../../core';
import { DemonstrativoRateio } from '../../../models';
import { PagamentoRateio } from '../../pagamentos';

@Injectable()
export class RateiosHttpService implements Crud<DemonstrativoRateio> {

	public resource = 'demonstrativorateio';
	private baseUrl: string;
	private url: string;

	constructor(private customHttp: CustomHttp,
		private crudService: CrudService,
		private configService: ConfigService) {
		this.url = configService.getBaseUrl();
		this.baseUrl = `${this.url}/${this.resource}`;
	}

	get(id: number): Observable<DemonstrativoRateio> {
		return this.crudService.get<DemonstrativoRateio>(this.resource, id);
	}

	getAll(): Observable<DemonstrativoRateio[]> {
		return this.crudService.getAll<DemonstrativoRateio>(this.resource);
	}

	post(entity: DemonstrativoRateio): Observable<DemonstrativoRateio> {
		return this.crudService.post<DemonstrativoRateio>(this.resource, entity);
	}

	put(entity: DemonstrativoRateio): Observable<DemonstrativoRateio> {
		return this.crudService.put<DemonstrativoRateio>(this.resource, entity);
	}

	delete(entity: DemonstrativoRateio): Observable<string> {
		return this.crudService.delete<DemonstrativoRateio>(this.resource, entity);
	}

	public view(id: number): Observable<DemonstrativoRateio> {
		return this.customHttp.get(`${this.baseUrl}/visualizar/${id}`)
			.pipe(
				map(response => response.json()),
				catchError(error => _throw(error))
			);
	}

	public finalizar(id: number): Observable<DemonstrativoRateio> {
		return this.customHttp.get(`${this.baseUrl}/finalizar/${id}`)
			.pipe(
				map(response => response.json()),
				catchError(error => _throw(error))
			);
	}

	public getAllByCondominio(idCondominio: number): Observable<DemonstrativoRateio[]> {
		return this.customHttp.get(`${this.baseUrl}/consultar/condominio/${idCondominio}`)
			.pipe(
				map(response => response.json()),
				catchError(error => _throw(error))
			);
	}

	public getAllPagamentosForRateio(entity: DemonstrativoRateio, futuros: boolean = false): Observable<any> {
		const params = { entity, futuros: futuros ? 1 : 0 };
		return this.customHttp.get(`${this.baseUrl}/consultar/pagamentos`, { params })
			.pipe(
				map(response => response.json()),
				catchError(error => _throw(error))
			);
	}

	public getAllPagamentosUnidadesForRateio(id_condominio: number, id_demonstrativo_rateio: number = null, data_competencia: string, futuros: boolean): Observable<PagamentoRateio[]> {
		const params = { id_condominio, id_demonstrativo_rateio, data_competencia, futuros: futuros ? 1 : 0 };
		return this.customHttp.get(`${this.baseUrl}/consultar/pagamentosunidades`, { params })
			.pipe(
				map(response => response.json()),
				catchError(error => _throw(error))
			);
	}

	/**
	 * Validar a geração do Rateio
	 * @param entity 
	 * @author Marcos Frare
	 * @since 08/02/2021
	 */
	public validarGeracaoRateio(entity: DemonstrativoRateio): Observable<any> {
		let entityTemp = <DemonstrativoRateio>{
			id_condominio: entity.id_condominio,
			proprietario: entity.proprietario,
			cobrar_fundo_reserva: entity.cobrar_fundo_reserva
		}
		return this.customHttp.get(`${this.baseUrl}/validageracao`, { params: entityTemp }).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Busca dados de validação de exlcusão de Rateio
	 * @param id - ID do rateio
	 * @author Marcos Frare
	 * @since 05/05/2020
	 */
	public validarExclusao(id: number): Observable<any> {
		return this.customHttp.get(`${this.baseUrl}/valida-exclusao/${id}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Validação da edição do rateio
	 * @param id 
	 * @returns 
	 * @author Marcos Frare
	 * @since 08/03
	 */
	public validarEdicao(id: number): Observable<any> {
		return this.customHttp.get(`${this.baseUrl}/valida-edicao/${id}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

}