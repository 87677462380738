import { Component, OnInit } from '@angular/core';
import { AuthService, MessageService } from 'app/core';
import { ConfiguracaoEmailCliente } from 'app/models';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfiguracaoEmailClienteHttpService } from './service';

@Component({
  selector: 'configuracao-email-cliente',
  templateUrl: './configuracao-email-cliente.component.html',
  styleUrls: ['./configuracao-email-cliente.component.css']
})
export class ConfiguracaoEmailClienteComponent implements OnInit {

  public configuracaoEmailCliente: ConfiguracaoEmailCliente;
  private id_cliente: number;

  constructor(private authService: AuthService,
      private spinner: NgxSpinnerService,
      private configuracaoEmailClienteHttpService: ConfiguracaoEmailClienteHttpService,
      private messageService: MessageService) { }

  ngOnInit() {
    this.id_cliente = this.authService.getIdCurrentCliente();
    this.configuracaoEmailCliente = new ConfiguracaoEmailCliente();
    this.configuracaoEmailCliente.id_cliente = this.id_cliente;
    this.loadConfigCliente();
  }

  /**
   * Carrega a configuração do cliente
   * @author Marcos Frare
   * @since 10/03/2021
   */
  private loadConfigCliente(): void {
    this.spinner.show();
    this.configuracaoEmailClienteHttpService.getByCliente(this.id_cliente).subscribe(
      response => {
        this.spinner.hide();
        this.configuracaoEmailCliente = response.host ? response : new ConfiguracaoEmailCliente();
      },
      error => {
        this.spinner.hide();
        this.messageService.error('Erro ao buscar configurações!', 'Erro', error);
      }
    );
  }

  /**
   * Salvar configuração
   * @author Marcos Frare
   * @since 10/03/2021
   */
  public salvar(): void {
    this.spinner.show();
    this.configuracaoEmailCliente.id_cliente = this.authService.getIdCurrentCliente();
    this.configuracaoEmailClienteHttpService.salvarCliente(this.configuracaoEmailCliente).subscribe(
      response => {
        this.spinner.hide();
        this.configuracaoEmailCliente = response;
        this.messageService.success('Configuração salva com sucesso!', 'Info');
      },
      error => {
        this.spinner.hide();
        this.messageService.error('Erro ao salvar configurações! Tente novamente.', 'Erro', error);
      }
    );
  }

  /**
   * Ação de testes de envio
   * @author Marcos Frare
   * @since 10/03/2021
   */
  public testeEnvio(): void {
    this.spinner.show();
    if (this.configuracaoEmailCliente.id) {
      this.configuracaoEmailClienteHttpService.getTesteEnvio(this.configuracaoEmailCliente.id).subscribe(
        response => {
          this.spinner.hide();
          if (response.sucesso) {
            this.messageService.success('E-mail enviado com sucesso! Verifique seua caixa de entrada.', 'Sucesso');
          } else {
            this.messageService.warning(response.msg, 'Atenção!');
          }
        },
        error => {
          this.spinner.hide();
          this.messageService.error('Erro ao fazer testes de evio de e-mail! Tente novamente.', 'Erro', error);
        }
      );
    } else {
      this.spinner.hide();
      this.messageService.warning('Não encontrado configuração! Tente novamente.', 'Atenção!');
    }
  }

  /**
   * Excluir configuração
   * @author Marcos Frare
   * @since 16/03/2021
   */
  public excluir(): void {
    if (confirm('Deseja realmente excluir sua configuração de e-mail?')) {
      this.spinner.show();
      this.configuracaoEmailClienteHttpService.excluir(this.configuracaoEmailCliente.id).subscribe(
        () => {
          this.spinner.hide();
          this.messageService.success('Configuração excluída com sucesso!');
          this.configuracaoEmailCliente = new ConfiguracaoEmailCliente();
        },
        error => {
          this.spinner.hide();
          this.messageService.error('Erro ao excluir configuração! Tente novamente.', 'Erro', error);
        }
      );
    }
  }

}