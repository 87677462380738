import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { LancamentosEspecificosComponent } from './lancamentos-especificos.component';
import { LancamentosEspecificosCadastroComponent } from './cadastro';
import { LancamentosEspecificosHttpService } from './services';
import { PlanoDeContasModule } from '../plano-de-contas';
import { LancamentoPagamentosModule } from 'app/componentes-utils/lancamento-pagamentos';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { PopoverModule } from 'ngx-popover';
import { TemplateLancamentoEspecificoCadastroModalComponent, TemplateLancamentoEspecificoComponent } from './template-lancamento-especifico';
import { UiSwitchModule } from 'ngx-ui-switch';
import { GeracaoLancamentoEspecificoComponent } from './template-lancamento-especifico/geracao-lancamento-especifico/geracao-lancamento-especifico.component';

@NgModule({
	imports: [
		SharedModule,
		PlanoDeContasModule,
		TypeaheadModule,
		LancamentoPagamentosModule,
		MultiselectDropdownModule,
		PopoverModule,
		UiSwitchModule,
	],
	declarations: [
		LancamentosEspecificosComponent,
		LancamentosEspecificosCadastroComponent,
		TemplateLancamentoEspecificoComponent,
		TemplateLancamentoEspecificoCadastroModalComponent,
		GeracaoLancamentoEspecificoComponent,
	],
	providers: [
		LancamentosEspecificosHttpService,
	]
})
export class LancamentosEspecificosModule { }