
import { Observable, forkJoin } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Component, ViewChild, Output, EventEmitter, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

import { MessageService, FormsUtilsService } from '../../../../../core';

import { ContatoProprietarioMorador, grausParentesco, ProprietariosMoradores, tipos, TIPO_USUARIO, Usuario, Veiculo } from '../../../../../models';
import { UnidadesUsuariosHttpService } from '../services';
import { UsuariosPesquisaModalComponent } from '../../../../usuarios/pesquisa-modal';
import { isNull } from 'util';
import { UsuariosCadastroModalComponent } from 'app/home/usuarios';
import { VeiculoCadastroModalComponent } from 'app/home/veiculo';

declare var jQuery: any;

@Component({
	selector: 'unidades-usuarios-modal',
	templateUrl: './unidades-usuarios-modal.component.html',
	styleUrls: ['./unidades-usuarios-modal.component.css'],
	providers: [
		UnidadesUsuariosHttpService
	]
})
export class UnidadesUsuariosModalComponent implements OnInit {
	@ViewChild('formUnidadesUsuariosModal') form: NgForm;

	@ViewChild('usuariosCadastroModal') usuariosCadastroModalComponent: UsuariosCadastroModalComponent;
	@ViewChild('usuariosPesquisaModal') usuariosPesquisaModal: UsuariosPesquisaModalComponent;
	@ViewChild('veiculoCadastroModal') veiculoCadastroModal: VeiculoCadastroModalComponent;

	@Output() onClose: EventEmitter<any> = new EventEmitter();

	idUnidade: number;
	nomeUnidade: string;
	usuarios: Usuario[];
	entities: ProprietariosMoradores[] = [];
	tiposMoradores = tipos;
	grausParentesco = grausParentesco;
	data: any;
	idCondominoTemp: number = null;

	constructor(private messageService: MessageService,
		private formsUtilsService: FormsUtilsService,
		private unidadesUsuariosHttpService: UnidadesUsuariosHttpService) { }
	
	ngOnInit(): void { }

	public open(idUnidade?: number, nomeUnidade?: string, usuarios?: Usuario[], modal?: boolean) {
		this.idCondominoTemp = null;
		this.entities = [];
		this.idUnidade = idUnidade;
		this.nomeUnidade = nomeUnidade;
		this.usuarios = usuarios;
		this.data = { idUnidade, nomeUnidade, usuarios };
		jQuery('#unidadesUsuariosModal').modal('show');
		if (!modal) {
			this.unidadesUsuariosHttpService.getAllByUnidade(idUnidade).subscribe(
				entities => {
					entities.forEach(entity => entity.morador = this.usuarios.find(x => x.condomino_id === entity.id_condomino));
					this.entities = entities;
					jQuery('#unidadesUsuariosModal').modal('show');
				},
				error => this.messageService.error('Erro ao carregar os moradores e propietário da unidade. Tente novamente', 'Erro', error)
			);
		}
	}

	public close() {
		jQuery(`#unidadesUsuariosModal`).modal('hide');
		this.onClose.emit({ usuarios: this.entities, idUnidade: this.idUnidade });
	}

	/** Adiciona uma nova Usuario */
	public insert() {
		let entity = new ProprietariosMoradores();
		entity.id_unidade = this.idUnidade;
		this.entities.push(entity);
	}

	/** Alterar alguma informação da Usuario */
	public onChange(entity: ProprietariosMoradores) {
		entity.editado = true;
	}

	private validateSave(): boolean {
		// Verificar se possui ao menos um vínculo
		if (!this.entities.length) {
			this.messageService.info('Informar ao menos um vínculo.', 'Atenção!');
			return false;
		}

		// Verificar se algum dos vínculos cadastrados é proprietário
		let possuiProprietario = false;
		for (let entity of this.entities) {
			// Validar se informou a data de entrada
			if (!entity.data_entrada || isNull(entity.data_entrada)) {
				this.messageService.warning('O campo de Data de Entrada é obrigatório!', 'Atenção');
				return false;
			}
			// Verifica se possui algum item do tipo proprietário
			if (entity.tipo == 2) {
				const hoje = new Date();
				// Carregando datas de entrada e saída
				let entrada = entity.data_entrada;
				let saida = entity.data_saida || null;

				// Verifica se é do tipo Date
				if (!(entrada instanceof Date)) {
					entrada = new Date(entrada);
				}
				if (saida && !(saida instanceof Date)) {
					saida = new Date(saida);
				}

				// Valida data para verificar validade
				if (entrada <= hoje && (!saida || saida >= hoje)) {
					possuiProprietario = true;
				}
			}
		}
		if (!possuiProprietario) {
			this.messageService.warning('Informar o proprietário do imóvel com data vigente.', 'Atenção!');
			return false;
		}
		return true;
	}

	/** Cria os observables para salvar os Usuarios alteradas simultaneamente **/
	private createSave(): Observable<ProprietariosMoradores>[] {
		const list: Observable<ProprietariosMoradores>[] = [];
		for (const entity of this.entities) {
			if (entity.editado) {
				let observable: Observable<ProprietariosMoradores>;
				if (entity.id) { // PUT
					observable = this.unidadesUsuariosHttpService.put(entity).pipe(
						map(response => response),
						catchError(error => {
							this.messageService.error(`Erro ao salvar o morador. Tente novamente`, 'Erro', error);
							return _throw(error);
						})
					);
				} else { // POST
					observable = this.unidadesUsuariosHttpService.post(entity).pipe(
						map(response => {
							return response;
						}),
						catchError(error => {
							this.messageService.error(`Erro ao salvar o morador. Tente novamente`, 'Erro', error);
							return _throw(error);
						})
					);
				}
				list.push(observable);
			}
		}
		return list;
	}

	/**
	 * Salva vínculos cadastrados
	 * @author Towty
	 */
	public save() {
		if (!this.validateSave()) {
			return;
		}
		const saves = this.createSave();
		if (saves.length > 0) {
			this.formsUtilsService.makeAllFieldsDirty(this.form);

			if (this.form.valid) {
				const btnSave = jQuery('#btnSaveUnidadesUsuariosModal');
				btnSave.button('loading');
				forkJoin(saves).subscribe(
					() => {
						btnSave.button('reset');
						this.messageService.success('', 'Usuarios salvos com sucesso');
						this.close();
					},
					error => {
						btnSave.button('reset');
					}
				);
			} else {
				this.formsUtilsService.showMsgFormInvalid();
			}
		} else {
			this.close();
		}
	}

	/** Deleta o usuario informado */
	public delete(entity: ProprietariosMoradores) {
		if (confirm(`Tem certeza que deseja excluir o morador?`)) {
			if (entity.id) { // Com id deleta do banco de dados
				this.unidadesUsuariosHttpService.delete(entity).subscribe(
					() => {
						const index = this.entities.findIndex(nota => nota.id === entity.id);
						this.deleteOfList(index);
						this.messageService.success('', `Morador excluído com sucesso`);
					},
					error => this.messageService.error(`Erro ao excluir morador. Tente novamente`, 'Erro', error)
				);
			} else { // Sem id deleta em memória
				const index = this.entities.indexOf(entity);
				if (this.deleteOfList(index)) {
					this.messageService.success('', `O morador foi excluído com sucesso`);
				}
			}
		}
	}

	/** Deleta a Usuario do array de Usuarios em memória */
	private deleteOfList(index: number): boolean {
		if (index > -1) {
			this.entities.splice(index, 1);
			return true;
		}
		return false;
	}

	public addMorador(index: number) {
		this.usuariosCadastroModalComponent.open(null, TIPO_USUARIO.CONDOMINO, index, this.idUnidade);
	}

	public openUsuariosPequisaModal(index: number) {
		this.usuariosPesquisaModal.open(index);
	}

	public onAfterSaveUsuario(event) {
		this.onSelectUsuariosPesquisaModal(event);
	}

	public onSelectUsuariosPesquisaModal(event) {
		let entity = this.entities[event.position];
		entity.morador = event.entity;
		entity.morador.id_usuario = entity.morador.id;
		entity.id_proprietario_morador = event.entity.condomino_id;
		entity.id_condomino = event.entity.condomino_id;
		this.onChange(entity);
	}

	// Ao selecionar o morador
	public onSelectMorador(tm: TypeaheadMatch, entity: ProprietariosMoradores) {
		const morador = tm.item;
		if (morador) {
			entity.id_proprietario_morador = morador.condomino_id;
			entity.id_condomino = morador.condomino_id;
		} else {
			entity.id_proprietario_morador = null;
		}
		this.onChange(entity);
	}

	// Ao limpar o campo do morador
	public onCleanMorador(entity: ProprietariosMoradores, input: any) {
		entity.id_proprietario_morador = null;
		entity.moradorNotFound = false;
		this.onChange(entity);
		input.value = '';
		input.focus();
	}

	// ============================================================================================================================
	// Veículos
	// ============================================================================================================================

	/**
	 * Mostra ou esconde veículos
	 * @param entity 
	 * @author Marcos Frare
	 * @since 14/04/2021
	 */
	public toggleVeiculo(entity: ProprietariosMoradores): void {
		entity.show_veiculos = !entity.show_veiculos;
		entity.show_contatos = false;
	}

	/**
	 * Abrir modal para cadastro de novo veículo
	 * @param entity 
	 * @author Marcos Frare
	 * @since 14/04/2021
	 */
	public addVeiculo(entity: ProprietariosMoradores): void {
		this.idCondominoTemp = entity.id_condomino;
		this.veiculoCadastroModal.id = null;
		this.veiculoCadastroModal.open();
	}

	/**
	 * Atualizar veículos do dondômino
	 * @param id_condomino 
	 * @author Marcos Frare
	 * @since 16/04/2021
	 */
	private atualizarVeiculosCondomino(): void {
		// Atualiza os veículos do condômino
		this.unidadesUsuariosHttpService.getVeiculosCondomino(this.idCondominoTemp).subscribe(
			response => {
				this.entities.map(entity => {
					if (entity.id_condomino == this.idCondominoTemp) {
						entity.veiculos = response;
					}
				});
			},
			error => {
				this.messageService.error('Erro ao buscar veículos do condômino!', 'Erro', error);
			}
		);
	}

	/**
	 * Evento ao salvar novo veículo
	 * @param veiculo 
	 * @author Marcos Frare
	 * @since 15/04/2021
	 */
	public onSaveVeiculo(veiculo: Veiculo): void {
		// Relacionar o veículo ao Condômino
		if (veiculo && veiculo.id) {
			this.unidadesUsuariosHttpService.relacionarVeiculoCondomino(veiculo.id, this.idCondominoTemp).subscribe(
				() => {
					// Atualiza os veículos do condômino
					this.atualizarVeiculosCondomino();
				},
				error => {
					this.messageService.error('Erro ao relacionar veículo ao condômino! Tente novamente.', 'Erro', error);
				}
			);
		}
	}

	public editarVeiculo(entity: ProprietariosMoradores, veiculo: Veiculo): void {
		this.idCondominoTemp = entity.id_condomino;
		this.veiculoCadastroModal.id = veiculo.id;
		this.veiculoCadastroModal.open();
	}

	/**
	 * Exclusão de veículos
	 * @param entity 
	 * @param index 
	 * @author Marcos Frare
	 * @since 15/04/2021
	 */
	public deleteVeiculo(entity: ProprietariosMoradores, veiculo: Veiculo): void {
		this.idCondominoTemp = entity.id_condomino;
		if (confirm('Deseja excluir o veículo?')) {
			this.unidadesUsuariosHttpService.excluirVeiculoCondomino(entity.id_condomino, veiculo.id).subscribe(
				() => {
					this.messageService.success('Veículo excluído com sucesso!');
					// Atualiza os veículos do condômino
					this.atualizarVeiculosCondomino();
				}
			);
		}
	}

	// ============================================================================================================================
	// Contatos
	// ============================================================================================================================

	/**
	 * Mostra ou esconde contatos
	 * @param entity 
	 * @author Marcos Frare
	 * @since 22/04/2021
	 */
	 public toggleContato(entity: ProprietariosMoradores): void {
		entity.show_veiculos = false;
		entity.show_contatos = !entity.show_contatos;
	}

	/**
	 * Adicionar novo contato para morador
	 * @param entity 
	 * @author Marcos Frare
	 * @since 22/04/2021
	 */
	public addContato(entity: ProprietariosMoradores): void {
		this.idCondominoTemp = entity.id_condomino;
		const contato = new ContatoProprietarioMorador();
		entity.contatos.push(contato);
	}
	
	/**
	 * Busca contatos do morador
	 * @param entity 
	 * @author Marcos Frare
	 * @since 23/04/2021
	 */
	private buscaContatosMorador(entity: ProprietariosMoradores): void {
		// entity.contatos = [];
		this.unidadesUsuariosHttpService.obterContatosMorador(entity).subscribe(
			response => {
				entity.contatos = response;
			},
			error => {
				this.messageService.error('Erro ao buscar contatos do morador!!', 'Erro', error);
			}
		);
	}

	/**
	 * Excluir contato
	 * @param entity 
	 * @param contato 
	 * @author Marcos Frare
	 * @since 23/04/2021
	 */
	public deleteContato(entity: ProprietariosMoradores, contato: ContatoProprietarioMorador): void {
		if (confirm(`Deseja excluir o contato ${contato.contato}?`)) {
			this.unidadesUsuariosHttpService.excluirContatoMorador(contato.id).subscribe(
				() => {
					this.buscaContatosMorador(entity);
					this.messageService.success('Contato excluído com sucesso!!');
				},
				error => {
					this.messageService.error('Erro ao excluir contato!! Tente novamente.', 'Erro', error);
				}
			);
		}
	}

	/**
	 * Alteração do contato
	 * @param entity 
	 * @param contato 
	 * @author Marcos Frare
	 * @since 23/04/2021
	 */
	public changeContato(entity: ProprietariosMoradores, contato: ContatoProprietarioMorador): void {
		this.unidadesUsuariosHttpService.gravarContato(entity, contato).subscribe(
			() => {
				this.buscaContatosMorador(entity);
				this.messageService.success('Contato salvo com sucesso!');
			},
			error => {
				this.messageService.error('Erro ao gravar contato para o usuário! Tente novamente', 'Erro', error);
			}
		);
	}

}