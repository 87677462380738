import { Injectable } from '@angular/core';
import { ConfigService, CustomHttp } from 'app/core';
import { Filtro } from 'app/models';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class RelatorioFiltroHttpService {

  public resource = 'relatorio-filtro';
  private baseUrl: string;
  private url: string;

  constructor(private customHttp: CustomHttp,
      private configService: ConfigService) {
    this.url = configService.getBaseUrl();
    this.baseUrl = `${this.url}/${this.resource}`;
  }

  /**
   * Buscar os dados para configuração de filtros do relatório
   * @param id 
   * @author Marcos Frare
   * @since 08/12/2020
   */
  public load(id: number): Observable<any> {
    return this.customHttp.get(`${this.baseUrl}/${id}`)
      .pipe(
        map(response => response.json()),
        catchError(error => _throw(error))
      );
  }

  /**
   * Salvar dados de configuração dos filtros do relatório
   * @param id 
   * @param filtros 
   * @author Marcos Frare
   * @since 08/12/2020
   */
  public salvar(id: number, filtros: Filtro[]): Observable<any> {
    return this.customHttp.post(`${this.baseUrl}/${id}`, {filtros})
      .pipe(
        map(response => response.json()),
        catchError(error => _throw(error))
      );
  }
}
