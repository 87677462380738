import { NgModule } from '@angular/core';
import { HistoricoMovimentacaoMensalRelComponent } from './historico-movimentacao-mensal-rel.component';
import { SharedModule } from 'app/shared';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    HistoricoMovimentacaoMensalRelComponent
  ]
})
export class HistoricoMovimentacaoMensalRelModule { }
