import { Component, OnInit, Input } from '@angular/core';
import { statusNotificacao } from 'app/models';

declare var jQuery: any;

@Component({
  selector: 'status-envio-email',
  templateUrl: './status-envio-email.component.html',
  styleUrls: ['./status-envio-email.component.css']
})
export class StatusEnvioEmailComponent implements OnInit {

  @Input() idModal: string;

  public dados: any[] = [];
  public statusNotificacao = statusNotificacao;

  constructor() { }

  ngOnInit() {
  }

  open(dados: any[]) {
    this.dados = dados;
    jQuery(`#${this.idModal}`).modal('show');
  }

  close() {
    jQuery(`#${this.idModal}`).modal('hide');
    this.dados = [];
  }

}