import { Component, Input, OnInit } from '@angular/core';
import { BaseModal } from '../../../componentes-utils/modal';
import { DatePipe } from '@angular/common';
import { FloatFormatPipe } from '../../../shared/pipes';

declare var jQuery: any;

@Component({
	selector: 'arquivos-retorno-mensagens-modal',
	templateUrl: './arquivos-retorno-mensagens-modal.component.html',
	styleUrls: ['./arquivos-retorno-mensagens-modal.component.css'],
	providers: [DatePipe, FloatFormatPipe]
})
export class ArquivosRetornoMensagensModalComponent implements OnInit, BaseModal {

	@Input() idModal: string;
	public mensagens: any[];

	constructor() {
		this.mensagens = [];
	}

	ngOnInit() {
		if (!this.idModal) { this.idModal = 'arquivosRetornoMensagensModal'; };
	}

	public open(): void {
		jQuery(`#${this.idModal}`).modal('show');
	}

	public addMessages(mensagens: any[]): void {
		mensagens.forEach(mensagem => this.mensagens.push(mensagem));
	}

	public close(): void {
		jQuery(`#${this.idModal}`).modal('hide');
	}
}
