import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseCadastroComponent } from '../../../componentes-base';
import { AuthService, MessageService } from '../../../core';
import { Bloco, Cliente, Comunicacao, Condominio, destinatarios, Formulario, DESTINATARIO, TIPO_REMETENTE, TIPO_USUARIO, tiposComunicacao, tiposRemetentes, Unidade, Usuario, tipos, Anexo } from '../../../models';
import { ComunicacoesHttpService } from '../services';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { PermissionService } from '../../../core/services/permission/permission.service';
import { BlocosHttpService, BlocosPesquisaModalComponent, UnidadesHttpService, UnidadesPesquisaModalComponent } from 'app/home/blocos';
import { CondominiosHttpService, CondominiosPesquisaModalComponent } from 'app/home/condominios';
import { ClientesHttpService } from '../../clientes/services';
import { FormulariosHttpService } from '../../formularios/services';
import { UsuariosHttpService } from '../../usuarios/services';
import { TiposDestinatariosPipe } from '../../../shared/pipes/tipos-destinatarios';
import { TiposRemetentesPipe } from '../../../shared/pipes/tipos-remetentes';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import { EnvioEmailLogModalComponent } from 'app/componentes-utils/envio-email-log-modal';

declare var jQuery: any;

@Component({
	selector: 'comunicacoes-cadastro',
	templateUrl: './comunicacoes-cadastro.component.html',
	styleUrls: ['./comunicacoes-cadastro.component.css'],
	providers: [
		PermissionService,
		ClientesHttpService,
		TiposDestinatariosPipe,
		TiposRemetentesPipe,
		DatePipe
	]
})
export class ComunicacoesCadastroComponent extends BaseCadastroComponent<Comunicacao> implements OnInit {

	@ViewChild('condominiosPesquisaModal') condominiosPesquisaModal: CondominiosPesquisaModalComponent;
	@ViewChild('blocosPesquisaModal') blocosPesquisaModal: BlocosPesquisaModalComponent;
	@ViewChild('unidadesPesquisaModal') unidadesPesquisaModal: UnidadesPesquisaModalComponent;
	@ViewChild('envioEmailLogModal') envioEmailLogModal: EnvioEmailLogModalComponent;

	public idCliente: number;
	public idCondominio: number;
	public idCurrentUser: number;
	public isSindico: boolean;
	public currentUser: Usuario;
	public tiposComunicacao: any[] = tiposComunicacao;
	public condominios: Condominio[] = [];
	public blocos: Bloco[] = [];
	public cliente: Cliente;
	public condominio: Condominio;
	public unidades: Unidade[] = [];
	public btnSaveAndSend: any;
	public tiposUsuarios = TIPO_USUARIO;
	public tipoRemetente = TIPO_REMETENTE;
	public tiposRemetentes: any[];
	public allTiposRemetentes: any[] = tiposRemetentes;
	public destinatarios: any[];
	public allDestinatarios: any[] = destinatarios;
	public formularios: Formulario[];
	public formulario: Formulario;
	public condominioSelected: boolean;
	public tiposVinculo = tipos;
	public showMessage = false;
	public editorConfig: any = {
		editable: true,
		spellcheck: true,
		height: 'auto',
		minHeight: '500px',
		width: 'auto',
		minWidth: '0',
		translate: 'yes',
		enableToolbar: true,
		showToolbar: true,
		placeholder: 'Digite a mensagem...',
		imageEndPoint: '',
		toolbar: [
			['bold', 'italic', 'underline', 'fontSize', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull']
		]
	};

	public multiselectSettings: IMultiSelectSettings = {
		enableSearch: false,
		containerClasses: 'container-multiselect',
		showUncheckAll: true,
		showCheckAll: true,
		dynamicTitleMaxItems: 1,
		checkedStyle: 'checkboxes',
		buttonClasses: 'btn btn-default',
		itemClasses: 'dropdown-menu'
	};
	public multiselectOptionsVinculos: IMultiSelectOption[] = [];
	public multiselectTextsVinculos: IMultiSelectTexts = {
		checkAll: 'Selecionar todas',
		uncheckAll: 'Limpar',
		checked: 'selecionada',
		checkedPlural: 'selecionadas',
		searchPlaceholder: 'Pesquisar...',
		defaultTitle: 'Selecionar',
		allSelected: 'Todas selecionadas',
		searchEmptyResult: 'Nenhum registro encontrado',
		searchNoRenderText: 'Digite para pesquisar',
	};

	constructor(public activatedRoute: ActivatedRoute,
			public router: Router,
			public messageService: MessageService,
			public permissionService: PermissionService,
			public comunicacoesHttpService: ComunicacoesHttpService,
			private authService: AuthService,
			private clientesHttpService: ClientesHttpService,
			private condominiosHttpService: CondominiosHttpService,
			private blocosHttpService: BlocosHttpService,
			private unidadesHttpService: UnidadesHttpService,
			private formulariosHttpService: FormulariosHttpService,
			private usuariosHttpService: UsuariosHttpService,
			private tiposDestinatariosPipe: TiposDestinatariosPipe,
			private tiposRemetentesPipe: TiposRemetentesPipe,
			private datePipe: DatePipe,
			private spinner: NgxSpinnerService) {
		super(Comunicacao, comunicacoesHttpService, activatedRoute, router, messageService, permissionService);
		this.condominioSelected = false;
	}

	ngOnInit() {
		this.idCurrentUser = this.authService.getIdCurrentUser();
		this.currentUser = this.authService.getCurrentUser();
		this.idCliente = this.authService.getIdCurrentCliente();
		this.idCondominio = this.authService.getIdCurrentCondominio();
		this.condominio = this.authService.getCurrentCondominio();
		this.getClienteById(this.idCliente);
		this.getAllFormulariosByCliente(this.idCliente);
		this.getAllCondominiosByUsuario();
		super.ngOnInit();
		this.loadFields();
		this.tiposVinculo.map(tp => {
			this.multiselectOptionsVinculos.push(<IMultiSelectOption>{id: tp.id, name: tp.nome});
		});
	}

	private loadFields(): void {
		if (this.idCondominio) {
			this.checkSindico(this.idCurrentUser, this.idCondominio);
		} else {
			this.isSindico = false;
		}
	}

	private checkSindico(idUsuario: number, idCondominio: number): void {
		this.usuariosHttpService.checkSindico(idUsuario, idCondominio).subscribe(
			yes => {
				this.isSindico = true;
				this.setEnvio();
			},
			no => {
				this.isSindico = false;
				this.setEnvio();
			}
		);
	}

	private getAllCondominiosByUsuario(): void {
		this.condominiosHttpService.getAllByUsuario(this.idCurrentUser).subscribe(
			condominios => this.condominios = condominios,
			error => this.messageService.error('Erro ao carregar os condominios do cliente. Tente novamente', 'Erro', error)
		);
	}

	private getAllBlocosByCondominio(idCondominio: number = this.idCondominio): void {
		this.blocosHttpService.getAllByCondominio(idCondominio).subscribe(
			blocos => this.blocos = blocos,
			error => this.messageService.error('Erro ao carregar os blocos do condomínio. Tente novamente', 'Erro', error)
		);
	}

	private getAllUnidadesByBloco(idBloco: number): void {
		this.unidadesHttpService.getAllByBloco(idBloco).subscribe(
			unidades => this.unidades = unidades,
			error => this.messageService.error('Erro ao carregar as unidades do bloco. Tente novamente', 'Erro', error)
		);
	}

	private getAllFormulariosByCliente(idCliente: number): void {
		this.formulariosHttpService.getAllByCliente(idCliente).subscribe(
			formularios => this.formularios = formularios,
			error => this.messageService.error('Erro ao consultar os formulários. Tente novamente', 'Erro', error)
		);
	}

	private getClienteById(idCliente: number): void {
		this.clientesHttpService.get(idCliente).subscribe(
			cliente => {
				this.cliente = cliente;
				if (!this.condominio) { this.entity.remetente = this.cliente.nome_fantasia; }
			},
			error => this.messageService.error('Erro ao carregar a administradora do usuário. Favor contate o administrador do sistema', 'Erro', error)
		);
	}

	// Condomínios
	public onSelectCondominio(tm: TypeaheadMatch): void {
		this.setCondominio(tm.item as Condominio);
	}

	private setCondominio(selectedCondominio: Condominio): void {
		this.entity.condominio = selectedCondominio;
		this.entity.id_condominio = selectedCondominio.id;
		this.entity.bloco = null;
		this.entity.id_bloco = null;
		this.entity.unidade = null;
		this.entity.id_unidade = null;
		this.unidades = [];
		this.checkSindico(this.idCurrentUser, selectedCondominio.id);
		this.getAllBlocosByCondominio(selectedCondominio.id);
	}

	public openCondominiosPequisaModal(): void {
		this.condominiosPesquisaModal.open();
	}

	public onSelectCondominiosPesquisaModal(condominio: Condominio): void {
		if (condominio) this.setCondominio(condominio);
		jQuery('#condominio').focus();
	}

	public onCleanCondominio(input: any): void {
		this.entity.condominio = null;
		this.entity.id_condominio = null;
		this.entity.bloco = null;
		this.entity.id_bloco = null;
		this.entity.unidade = null;
		this.entity.id_unidade = null;
		this.blocos = [];
		this.unidades = [];
		input.value = '';
		input.focus();

		this.isSindico = false;
		this.setEnvio();
	}

	private loadCondominio(entity?: Comunicacao): void {
		const idCondominio: number = entity ? entity.id_condominio : this.idCondominio;
		if (!idCondominio) return;
		this.condominiosHttpService.get(idCondominio).subscribe(
			condominio => this.onLoadCondominio(condominio),
			error => this.messageService.error('Erro ao carregar o condominio do inventário', 'Erro', error)
		);
	}

	private onLoadCondominio(condominio: Condominio): void {
		this.entity.condominio = condominio;
		this.entity.id_condominio = condominio.id;
		this.getAllBlocosByCondominio(condominio.id);
		this.loadBloco(this.entity);
	}

	// Bloco
	public onSelectBloco(tm: TypeaheadMatch): void {
		this.setBloco(tm.item as Bloco);
	}

	private setBloco(selectedBloco: Bloco): void {
		this.entity.bloco = selectedBloco;
		this.entity.id_bloco = selectedBloco.id;
		this.getAllUnidadesByBloco(selectedBloco.id);
	}

	public openBlocosPequisaModal(): void {
		this.blocosPesquisaModal.open();
	}

	public onSelectBlocosPesquisaModal(bloco: Bloco): void {
		if (bloco) this.setBloco(bloco);
		jQuery('#bloco').focus();
	}

	public onCleanBloco(input: any): void {
		this.entity.bloco = null;
		this.entity.id_bloco = null;
		this.entity.unidade = null;
		this.entity.id_unidade = null;
		this.unidades = [];
		input.value = '';
		input.focus();
	}

	private loadBloco(entity: Comunicacao): void {
		if (!entity.id_bloco) return;
		this.blocosHttpService.get(entity.id_bloco).subscribe(
			bloco => this.onLoadBloco(bloco),
			error => this.messageService.error('Erro ao carregar o bloco do inventário', 'Erro', error)
		);
	}

	private onLoadBloco(bloco: Bloco): void {
		this.setBloco(bloco);
		this.loadUnidade(this.entity);
	}

	// Unidade
	public onSelectUnidade(tm: TypeaheadMatch): void {
		this.setUnidade(tm.item as Unidade);
	}

	private setUnidade(selectedUnidade: Unidade): void {
		this.entity.unidade = selectedUnidade;
		this.entity.id_unidade = selectedUnidade.id;
	}

	public openUnidadesPequisaModal(): void {
		this.unidadesPesquisaModal.open();
	}

	public onSelectUnidadesPesquisaModal(unidade: Unidade): void {
		if (unidade) this.setUnidade(unidade);
		jQuery('#unidade').focus();
	}

	public onCleanUnidade(input: any): void {
		this.entity.unidade = null;
		this.entity.id_unidade = null;
		input.value = '';
		input.focus();
	}

	private loadUnidade(entity: Comunicacao): void {
		if (!entity.id_unidade) return;
		this.unidadesHttpService.get(entity.id_unidade).subscribe(
			unidade => this.setUnidade(unidade),
			error => this.messageService.error('Erro ao carregar o unidade do inventário', 'Erro', error)
		);
	}

	public afterEdit(entity: Comunicacao): void {
		this.loadCondominio(entity);
		if (!this.entity.anexos || !this.entity.anexos.length) {
			this.entity.anexos.push(new Anexo());
		}
		this.showMessage = this.entity.descricao ? true : false;
	}

	public toogleShowMessage(): void {
		this.showMessage = !this.showMessage;
	}

	public afterInsert(): void {
		this.loadCondominio();
		this.setEnvio();
	}

	public setEnvio(): void {
		switch (this.currentUser.tipo) {
			case TIPO_USUARIO.CONDOMINO:
				this.setEnvioCondomino();
				break;
			case TIPO_USUARIO.FUNCIONARIO:
				this.setEnvioFuncionario();
		}
	}

	private setEnvioCondomino(): void {
		this.entity.remetente = this.currentUser.nome;
		if (this.isSindico) {
			this.tiposRemetentes = this.tiposRemetentesPipe.transform(this.allTiposRemetentes, 'condominoSindico');
			this.entity.tipo_remetente = TIPO_REMETENTE.SINDICO;
			this.destinatarios = this.tiposDestinatariosPipe.transform(this.allDestinatarios, 'condominoSindico');
		} else {
			this.tiposRemetentes = this.tiposRemetentesPipe.transform(this.allTiposRemetentes, 'condominoNormal');
			this.entity.tipo_remetente = TIPO_REMETENTE.CONDOMINO;
			this.destinatarios = this.tiposDestinatariosPipe.transform(this.allDestinatarios, 'condominoNormal');
		}
		this.destinatarios.forEach(destinatario => {
			if (destinatario.id === DESTINATARIO.SINDICO) {
				destinatario.checked = true;
			}
		});
	}

	private setEnvioFuncionario(): void {
		if (this.isSindico) {
			this.tiposRemetentes = this.tiposRemetentesPipe.transform(this.allTiposRemetentes, 'funcionarioSindico', this.condominio);
			this.entity.tipo_remetente = TIPO_REMETENTE.SINDICO;
			this.entity.remetente = this.currentUser.nome;
			this.destinatarios = this.tiposDestinatariosPipe.transform(this.allDestinatarios, 'funcionarioSindico');
		} else {
			this.tiposRemetentes = this.tiposRemetentesPipe.transform(this.allTiposRemetentes, 'funcionarioNormal', this.condominio);
			this.destinatarios = this.tiposDestinatariosPipe.transform(this.allDestinatarios, 'funcionarioNormal');
		}
		this.destinatarios.forEach(destinatario => {
			if (destinatario.id === DESTINATARIO.CONDOMINO) {
				destinatario.checked = true;
			}
		});
	}

	public onChangeTipoRemetente(): void {
		switch (this.entity.tipo_remetente) {
			case TIPO_REMETENTE.ADMINISTRADORA:
				this.entity.remetente = this.cliente.nome_fantasia;
				break;
			case TIPO_REMETENTE.CONDOMINIO:
				this.entity.remetente = this.condominio.nome_fantasia;
				break;
			case TIPO_REMETENTE.CONDOMINO:
				this.entity.remetente = this.currentUser.nome;
				break;
			case TIPO_REMETENTE.SINDICO:
				this.entity.remetente = this.currentUser.nome;
				this.destinatarios = this.tiposDestinatariosPipe.transform(this.allDestinatarios, 'condominoSindico', true);
		}
	}

	public beforeSave(): boolean {
		if (!this.editMode) {
			this.entity.id_cliente = this.idCliente;
			this.entity.id_usuario_envio = this.idCurrentUser;
			this.entity.sindico = this.isSindico;
			this.entity.tipo_usuario = this.currentUser.tipo;
		}
		this.entity.destinatarios = this.getIdsDestinatarios();
		return true;
	}

	public getIdsDestinatarios(): any[] {
		return this.destinatarios
			.filter(destinatario => destinatario.checked)
			.map(destinatario => destinatario.id);
	}

	public getNomesDestinatarios(): any[] {
		return this.destinatarios
			.filter(destinatario => destinatario.checked)
			.map(destinatario => destinatario.nome);
	}

	public onSaveClick(): void {
		this.entity.email = false;
		this.btnSave = jQuery('#btnSave');
		this.btnSave.button('loading');
		this.save();
	}

	public save(): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.makeAllFieldsDirty();

			if (this.form.valid) {
				if (this.beforeSave()) {
					const envio: any = { ...this.entity };

					if (this.editMode) { // PUT
						this.crudService.put(envio).subscribe(
							entity => {
								this.messageService.success('', 'Registro salvo com sucesso');
								this.afterSave(this.entity);
								resolve(entity);
							},
							error => {
								this.messageService.error('Erro ao atualizar o registro', 'Erro', error);
								this.btnSave.button('reset');
								this.btnSaveAndSend.button('reset');
								reject(null);
							}
						);
					} else { // POST
						this.crudService.post(envio).subscribe(
							entity => {
								this.entity = entity;
								this.messageService.success('', 'Registro salvo com sucesso');
								this.afterSave(this.entity);
								resolve(entity);
							},
							error => {
								this.messageService.error('Erro ao salvar o registro', 'Erro', error);
								this.btnSave.button('reset');
								this.btnSaveAndSend.button('reset');
								reject(null);
							}
						);
					}
				} else {
					reject(null);
				}
			} else {
				this.btnSave.button('reset');
				this.btnSaveAndSend.button('reset');
				this.showMessageFormInvalid();
				reject(null);
			}
		});
	}

	public afterSave(entity: Comunicacao): void {
		super.afterSave(entity);
	}

	public cancel(): void {
		this.router.navigate(['../'], { relativeTo: this.activatedRoute });
	}

	public getNameTipo(tipoComunicado: number): string {
		return this.tiposComunicacao.find(tipo => tipo.id === tipoComunicado).nome;
	}

	public getNameTipoRemetente(tipoRemetente: number): string {
		return this.tiposRemetentes.find(remetente => remetente.id === tipoRemetente).nome;
	}

	/**
	 * Ação de envio de e-mail
	 * @author Marcos Frare
	 * @since 13/03/2021
	 */
	private enviar() {
		this.entity.id_cliente = this.idCliente;
		this.entity.id_usuario_envio = this.idCurrentUser;
		this.entity.destinatarios = this.getIdsDestinatarios();
		let message = `Deseja enviar a comunicação?`;
		if (this.entity.email_enviado) {
			message = `Esta comunicação já foi enviada! Deseja enviar novamente?`;
		}
		if (confirm(message)) {
			this.spinner.show();
			this.comunicacoesHttpService.sendMail(this.entity).subscribe(
				response => {
					this.spinner.hide();
					this.entity.email_enviado = true;
					this.messageService.success('E-mail(s) enviados com sucesso!', 'Sucesso!');
					// Abre modal com dados de envio de e-mail
					this.envioEmailLogModal.data = response;
					this.envioEmailLogModal.open();
				},
				error => {
					this.spinner.hide();
					this.messageService.error('Erro ao enviar e-mail(s)! Tente novamente.', 'Erro!', error);
				}
			);
		}
	}

	/**
	 * Enviar e-mail da convocação
	 * @author Marcos Frare
	 * @since 03/03/2021
	 */
	public onSendEmail(): void {
		this.entity.nao_enviado = false;
		this.enviar();
	}

	/**
	 * Enviar e-mail da convocação
	 * @author Marcos Frare
	 * @since 03/03/2021
	 */
	public onSendEmailErro(): void {
		this.entity.nao_enviado = true;
		this.enviar();
	}

	/**
	 * Incluir anexo
	 * @author Marcos Frare
	 * @since 08/04/2021
	 */
	public addAnexos(): void {
		this.entity.anexos.push(new Anexo());
	}

	/**
	 * Excluir um anexo
	 * @param index
	 * @author Marcos Frare
	 * @since 08/04/2021
	 */
	public removeAnexos(index): void {
		this.entity.anexos.splice(index, 1);
	}
	
}