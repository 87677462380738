import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';

import { ConfigService, CustomHttp, AuthService, Crud, CrudService } from '../../../../core';
import { CertidaoNegativaDebito } from 'app/models';

@Injectable()
export class CertidaoNegativaDebitoHttpService implements Crud<CertidaoNegativaDebito> {

  resource = 'certidaonegativadebito'
  private baseUrl: string;

  constructor(
      private customHttp: CustomHttp,
      private crudService: CrudService,
      private configService: ConfigService,
      private authService: AuthService) {
    this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
  }
  
  /**
   * Valida se unidade pode emitir CND
   * @param idUnidade ID da unidade
   * @author Marcos Frare
   * @since 30/01/2020
   */
  public validar(entity: CertidaoNegativaDebito): Observable<any> {
    return this.customHttp.post(`${this.baseUrl}/validar`, entity).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
  }

  /**
   * Consulta certidões emitidas
   * @param filtro 
   * @author Marcos Frare
   * @since 31/01/2020
   */
  public search(filtro: any): Observable<any> {
    return this.customHttp.post(`${this.baseUrl}/filtrar`, filtro).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
  }

  public get(id: number): Observable<CertidaoNegativaDebito> {
    return this.crudService.get<CertidaoNegativaDebito>(this.resource, id);
  }

  public getAll(): Observable<CertidaoNegativaDebito[]> {
    return this.crudService.getAll<CertidaoNegativaDebito>(this.resource);
  }

  public post(entity: CertidaoNegativaDebito): Observable<CertidaoNegativaDebito> {
    return this.crudService.post<CertidaoNegativaDebito>(this.resource, entity);
  }

  public put(entity: CertidaoNegativaDebito): Observable<CertidaoNegativaDebito> {
    return this.crudService.put<CertidaoNegativaDebito>(this.resource, entity);
  }

  public delete(entity: CertidaoNegativaDebito): Observable<string> {
    const id = entity.id;
    const id_usuario = this.authService.getIdCurrentUser();
    return this.customHttp.post(`${this.baseUrl}/desativar`, {id, id_usuario}).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
  }

}