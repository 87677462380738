import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';

import { Crud, CrudService, ConfigService, CustomHttp } from '../../../core';
import { Bloco } from '../../../models';

@Injectable()
export class BlocosHttpService implements Crud<Bloco> {
	resource = 'bloco';
	private baseUrl: string;

	constructor(private customHttp: CustomHttp,
		private crudService: CrudService,
		private configService: ConfigService) {
		this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
	}

	get(id: number): Observable<Bloco> {
		return this.crudService.get<Bloco>(this.resource, id);
	}

	getAll(): Observable<Bloco[]> {
		return this.crudService.getAll<Bloco>(this.resource);
	}

	post(entity: Bloco): Observable<Bloco> {
		return this.crudService.post<Bloco>(this.resource, entity);
	}

	put(entity: Bloco): Observable<Bloco> {
		return this.crudService.put<Bloco>(this.resource, entity);
	}

	delete(entity: Bloco): Observable<string> {
		return this.crudService.delete<Bloco>(this.resource, entity);
	}

	/**
	 * Carrega todos os blocos do condomínio informado
	 */
	public getAllByCondominio(idCondominio: number): Observable<Bloco[]> {
		return this.customHttp.get(`${this.baseUrl}/consultar/condominio/${idCondominio}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Buscar os que participaram do rateio
	 * @param id_demonstrativo_rateio 
	 * @author Marcos Frare
	 * @since 09/08/2021
	 */
	public getAllByRateio(id_demonstrativo_rateio: number): Observable<Bloco[]> {
		return this.customHttp.get(`${this.baseUrl}/consultar/rateio/${id_demonstrativo_rateio}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

}