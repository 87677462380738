import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared';
import { RelatorioReservasComponent } from './relatorio-reservas.component';
import { TypeaheadModule } from 'ngx-bootstrap';
import { BlocosHttpService } from 'app/home/blocos';
import { UnidadesHttpService } from 'app/home/blocos/unidades';

@NgModule({
  imports: [
    SharedModule,
    TypeaheadModule
  ],
  declarations: [
    RelatorioReservasComponent
  ],
  providers: [
    BlocosHttpService,
    UnidadesHttpService
  ]
})
export class RelatorioReservasModule { }