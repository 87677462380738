import { NgModule } from '@angular/core';
import { ParcelaDespesaRateioComponent } from './parcela-despesa-rateio.component';
import { SharedModule } from 'app/shared';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    ParcelaDespesaRateioComponent
  ],
  exports: [
    ParcelaDespesaRateioComponent
  ],
  providers: []
})
export class ParcelaDespesaRateioModule { }
