import { BaseEntity } from '../base-entity';

export class ParcelaDespesaRateio extends BaseEntity {
	id_lancamento_condominio_item: number;
	id_demonstrativo_rateio: number;
	numero_parcela: number;
	data_vencimento: Date;
	valor: any;
	
	editado: boolean;
	excluir: boolean;

	constructor() {
		super();
		this.editado = false;
		this.excluir = false;
		this.numero_parcela = 1;
	}
}