import { Bloco } from '../../../models';

export class ConsumosUnidadesRelFiltro {
    id_bloco: number;
    bloco: Bloco;
    id_tipo_consumo: number;
    mes_competencia: Date;

    /** Limpa o filtro */
    limpar() {
        this.id_bloco = null;
        this.bloco = null;
        this.id_tipo_consumo = null;
        this.mes_competencia = null;
    }
}
