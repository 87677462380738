import { BaseEntity } from '../base-entity';
import { ConfiguracaoLivroItem } from './configuracao-livro-item';

export class ConfiguracaoLivro extends BaseEntity {
	id_cliente: number;
	id_condominio: number;
	descricao: string;
	observacao: string;

	configuracaoLivroItens: ConfiguracaoLivroItem[];
}