import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared';
import { ConciliacaoBancariaComponent } from './conciliacao-bancaria.component';
import { ConciliacaoBancariaHttpService } from './service';
import { ImportacaoExtratoComponent } from './importacao-extrato';
import { ConsultaConciliacaoComponent } from './consulta-conciliacao';
import { ConciliacaoContaComponent, ConciliacaoContaLancamentoModalComponent } from './conciliacao-conta';
import { UiSwitchModule } from 'ngx-ui-switch';

@NgModule({
  imports: [
    SharedModule,
    UiSwitchModule,
  ],
  declarations: [
    ConciliacaoBancariaComponent,
    ImportacaoExtratoComponent,
    ConciliacaoContaComponent,
    ConsultaConciliacaoComponent,
    ConciliacaoContaLancamentoModalComponent
  ],
  providers: [
    ConciliacaoBancariaHttpService
  ]
})
export class ConciliacaoBancariaModule { }