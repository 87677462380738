import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseVisualizacaoComponent } from '../../componentes-base';
import { Bloco, Local, tiposLocal } from '../../models';
import { MessageService } from '../../core';
import { LocalHttpService } from './services';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
	selector: 'local',
	templateUrl: './local.component.html',
	styleUrls: ['./local.component.css'],
	providers: [PermissionService]
})
export class LocalComponent extends BaseVisualizacaoComponent<Local> implements OnInit {

	public blocos: Bloco[] = [];

	constructor(public activatedRoute: ActivatedRoute,
		public router: Router,
		public messageService: MessageService,
		private localHttpService: LocalHttpService,
		public permissionService: PermissionService,
		public spinner: NgxSpinnerService) {
		super('#tableLocais', localHttpService, activatedRoute, router, messageService, permissionService, spinner);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	/**
	 * Busca descrição do local
	 * @param id 
	 * @author Marcos Frare
	 * @since 18/03/2020
	 */
	public getTipo(id: number): string {
		let tipo = '';
		tiposLocal.forEach(local => {
			if (local.id == id) {
				tipo = local.nome;
			}
		});
		return tipo;
	}

	delete(local: Local) {
		if (confirm(`Tem certeza que deseja excluir o local ${local.nome}?`)) {
			this.localHttpService.delete(local)
				.subscribe(
					() => {
						this.messageService.success('', `O local ${local.nome} foi excluído com sucesso`);
						this.destroyTable();
						this.getAll();
					},
					error => {
						switch (error.status) {
							case 404:
								this.messageService.warning(`Local não encontrado, atualize a consulta e tente novamente.`, 'Atenção', error);
								break;
							case 500:
								this.messageService.error(`Erro ao excluir o local ${local.nome}. Tente novamente`, 'Erro', error);
								break;
							default:
								break;
						}
					}
				);
		}
	}
}