import { Component, OnInit, ViewChild } from '@angular/core';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService, AuthService } from 'app/core';
import { tiposContasBancarias, ContaBancaria } from 'app/models';
import { ConciliacaoBancariaHttpService } from './service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Permission } from 'app/componentes-base';
import { ImportacaoExtratoComponent } from './importacao-extrato';

@Component({
  selector: 'conciliacao-bancaria',
  templateUrl: './conciliacao-bancaria.component.html',
  styleUrls: ['./conciliacao-bancaria.component.css'],
  providers: [PermissionService]
})
export class ConciliacaoBancariaComponent implements OnInit {

  @ViewChild('importacaoExtratoModal') importacaoExtratoModal: ImportacaoExtratoComponent;

  private id_condominio: number;
  public tiposContas = tiposContasBancarias;
  public contasBancarias: ContaBancaria[];
  public permissions: Permission;
  public resource: string;

  constructor(private activatedRoute: ActivatedRoute,
      private router: Router,
      private messageService: MessageService,
      private permissionService: PermissionService,
      private authService: AuthService,
      private conciliacaoBancariaHttpService: ConciliacaoBancariaHttpService,
      private spinner: NgxSpinnerService) {
    this.contasBancarias = [];
    this.resource = this.activatedRoute.data['value']['resource'];
  }

  ngOnInit() {
    this.id_condominio = this.authService.getIdCurrentCondominio();
    this.getPermissions();
    this.search();
  }

  /**
   * Permissoes do usuário
   * @author Marcos Frare
   * @since 26/06/2020
   */
  public getPermissions() {
    this.permissionService.getPermissions(this.resource).subscribe(
      permissions => this.permissions = permissions
    );
  }

  /**
   * Buscar os dados de contas e pendências
   * @author Marcos Frare
   * @since 26/06/2020
   */
  public search(): void {
    this.spinner.show();
    const params = {
      id_condominio: this.id_condominio
    }
    this.conciliacaoBancariaHttpService.index(params).subscribe(
      response => {
        this.spinner.hide();
        this.contasBancarias = response;
      },
      error => {
        this.spinner.hide();
        this.messageService.error('Erro ao buscar contas. Tente novamente!', 'Erro', error);
      }
    );
  }

  /**
   * Busca o nome do tipo de conta
   * @param conta 
   * @author Marcos Frare
   * @since 26/06/2020
   */
  public nomeTipoConta(conta: ContaBancaria): string{
    const tipoConta = this.tiposContas.find(item => item.codigo === conta.tipo);
    return tipoConta.nome;
  }

  /**
   * Rotear para tela de conciliações da conta
   * @param conta 
   * @author Marcos Frare
   * @since 01/07/2020
   */
  public conciliar(conta: ContaBancaria): void {
    this.router.navigate([`./conciliar/${conta.id}`], {relativeTo: this.activatedRoute});
  }

  /**
   * Abrir modal para importação de extrato
   * @param conta 
   * @author Marcos Frare
   * @since 01/07/2020
   */
  public importar(conta: ContaBancaria): void {
    this.importacaoExtratoModal.id_conta_bancaria = conta.id;
    this.importacaoExtratoModal.open();
  }

  /**
   * Rotear para tela de conculta de conciliações da conta
   * @param conta 
   * @author Marcos Frare
   * @since 03/07/2020
   */
  public consulta(conta: ContaBancaria): void {
    this.router.navigate([`./consulta/${conta.id}`], {relativeTo: this.activatedRoute});
  }

  /**
   * Abre tela para configuração de lançamentos de itens importados
   * @author Marcos Frare
   * @since 08/07/2020
   */
  public configuracaoLancamento(): void {
    this.router.navigate(['/despesas-condominio/template']);
  }

}