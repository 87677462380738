import { Component, Input, OnChanges, ViewChild, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Bloco, Unidade, Usuario, tiposUnidade, tiposEnvioBoleto, cobrarFundoReserva } from '../../../models';
import { MessageService } from '../../../core';
import { UnidadesHttpService } from './services/unidades-http.service';
import { UsuariosCadastroModalComponent, tipoUsuario } from '../../usuarios';
import { UnidadesHistoricoModalComponent } from './historico/modal/unidades-historico-modal.component';
import { UnidadesGaragensModalComponent } from './garagens';
import { UnidadesContatosModalComponent } from './contatos';
import { UnidadesUsuariosModalComponent } from './usuarios';
import { UsuariosPesquisaModalComponent } from 'app/home/usuarios/pesquisa-modal';
import { TypeaheadMatch } from 'ngx-bootstrap';

declare var jQuery: any;

interface CadastroUsuarioSetup {
	tipo: tipoUsuario;
	unidade: Unidade;
	input: any;
}

@Component({
	selector: 'unidades',
	templateUrl: 'unidades.component.html',
	styleUrls: ['unidades.component.css']
})
export class UnidadesComponent implements OnChanges {

	@Input() bloco: Bloco;
	@Input() entities: Unidade[];
	@Input() usuarios: Usuario[];
	@Output() onBlocoEdit: EventEmitter<any> = new EventEmitter();

	@ViewChild('unidadesHistoricoModal') unidadesHistoricoModal: UnidadesHistoricoModalComponent;
	@ViewChild('unidadesGaragensModal') unidadesGaragensModal: UnidadesGaragensModalComponent;
	@ViewChild('unidadesContatosModal') unidadesContatosModal: UnidadesContatosModalComponent;
	@ViewChild('unidadesUsuariosModal') unidadesUsuariosModal: UnidadesUsuariosModalComponent;
	@ViewChild('usuariosCadastroModalUnidades') usuariosCadastroModalUnidades: UsuariosCadastroModalComponent;
	@ViewChild('usuariosPesquisaModalUnidades') usuariosPesquisaModalUnidades: UsuariosPesquisaModalComponent;

	public cadastroNovoUsuarioSetup: CadastroUsuarioSetup = { tipo: null, unidade: null, input: null };
	public unidadesPorAndar: any;
	public tiposUnidade = tiposUnidade;
	public tiposEnvioBoleto = tiposEnvioBoleto;
	public cobrarFundoReserva = cobrarFundoReserva;
	public selectedUnidade: Unidade;

	constructor(private messageService: MessageService,
		private unidadesHttpService: UnidadesHttpService,
		private activatedRoute: ActivatedRoute,
		private router: Router) { }

	ngOnChanges() {
		if (this.entities) {
			this.agruparUnidades();
		}
	}

	// Agrupa as unidades por andar
	private agruparUnidades() {
		const group_to_values = this.entities.reduce((obj, item) => {
			obj[item.andar] = obj[item.andar] || [];
			obj[item.andar].push(item);
			return obj;
		}, {});

		this.unidadesPorAndar = Object.keys(group_to_values).map(key => {
			return { andar: key, unidades: group_to_values[key] };
		});
	}

	// Ao editar algum campo da unidade, setamos ela como editar para salvá-la
	public onChange(unidade: Unidade) {
		unidade.editado = true;
	}

	public create(andar: number, tabIndex: number) {
		const unidade = new Unidade();
		unidade.tipo = tiposUnidade[0].id; // 1 - Apartamento
		unidade.id_bloco = this.bloco.id;
		unidade.andar = andar;
		unidade.editado = true;

		this.entities.push(unidade);
		this.ngOnChanges(); // Chamando para atualizar o array que agrupa as unidade por andar

		setTimeout(() => {
			// Clicando na aba que foi criada a unidade
			const tab = jQuery(`#navTab${tabIndex}  > a`);
			tab.click();

			// Setando foco no input do nome da unidade criada
			const tabPane = jQuery(`#tab${tabIndex}`);
			tabPane.find('input[id="nome"]:last').focus();
		}, 50);
	}

	public delete(unidade: Unidade, tabIndex: number) {
		const nomeUnidade = unidade.nome ? unidade.nome : '';
		if (confirm(`Tem certeza que deseja excluir a unidade ${nomeUnidade}?`)) {
			if (unidade.id) {
				this.unidadesHttpService.delete(unidade).subscribe(
					() => {
						this.messageService.success('', `A unidade ${nomeUnidade} foi excluida com sucesso`);
						const index = this.entities.findIndex(entity => entity.id === unidade.id);
						this.deleteOfList(index, tabIndex);
					},
					error => {
						if (error.status === 404)
							this.messageService.error(`Erro ao excluir a unidade ${nomeUnidade}. Tente novamente`, 'Erro', error)
						if (error.status === 409)
							this.messageService.warning(`Não é possível excluir a unidade ${nomeUnidade}, pois a mesma possui lançamentos no financeiro.`, 'Atenção', error)
						if (error.status === 500)
							this.messageService.error(`Erro ao excluir a unidade ${nomeUnidade}. Tente novamente`, 'Erro', error)
					}
				);
			} else {
				const index = this.entities.indexOf(unidade);
				this.deleteOfList(index, tabIndex)
				this.messageService.success('', `A unidade ${nomeUnidade} foi excluida com sucesso`);
			}
		}
	}

	// Deleta a unidade da lista de unidades em memória
	private deleteOfList(index: number, tabIndex: number = null): void {
		if (index > -1) {
			this.entities.splice(index, 1);
			this.ngOnChanges();
			if (tabIndex) {
				setTimeout(() => {
					const tab = jQuery(`#navTab${tabIndex} > a`);
					tab.click();
				}, 50);
			}
		}
	}

	public onDeleteAndar(andar: number): void {
		if (confirm(`Tem certeza que deseja excluir esse andar?`)) {
			this.deleteAndar(andar);
		}
	}

	// Deleta o andar informado
	private deleteAndar(andar: number): void {
		this.unidadesHttpService.deleteAndar(this.bloco.id, andar)
			.subscribe(
				() => {
					// Removendo todas as unidade que pertenciando ao andar excluido do array em memória
					this.entities.filter(entity => entity.andar === Number(andar)).map(unidade => {
						const index = this.entities.findIndex(entity => entity.id === unidade.id);
						this.deleteOfList(index);
					});
					this.messageService.success('', `O ${andar}º andar foi excluido com sucesso`);
				},
				error => {
					switch (error.status) {
						case 400:
							this.messageService.warning(`O ${andar}º andar não pode ser excluído, o mesmo possui lançamentos no financeiro.`, 'Erro')
							break;
						default:
							this.messageService.error(`Erro ao excluir o ${andar}º andar. Tente novamente`, 'Erro', error)
					}
				}
			);
	}

	public trocarUsuario(unidade: Unidade, tipo: string) {
		this.router.navigate(['./unidade', unidade.id, 'troca', tipo], { relativeTo: this.activatedRoute });
	}

	public visualizarHistorico(unidade: Unidade) {
		this.unidadesHistoricoModal.open(unidade.id);
	}

	public visualizarGaragens(unidade: Unidade) {
		this.unidadesGaragensModal.open(unidade.id, unidade.nome);
	}

	public visualizarContatos(unidade: Unidade) {
		this.unidadesContatosModal.open(unidade.id, unidade.nome);
	}

	public visualizarUsuarios(unidade: Unidade) {
		this.unidadesUsuariosModal.open(unidade.id, `${this.bloco.nome} - ${unidade.nome}`, this.usuarios);
	}

	public reloadUnidades(unidade: any) {
		if (!unidade.usuarios.length) {
			let entity = this.entities.find(entity => entity.id === unidade.idUnidade)
			entity.editado = true;
			this.unidadesHttpService.put(entity).subscribe(
				response => response,
				error => error
			);
		}
		this.onBlocoEdit.emit();
	}

	public setSelectedUnidade(unidade: Unidade): void {
		this.selectedUnidade = unidade;
	}

	public addSacado(unidade: Unidade): void {
		this.setSelectedUnidade(unidade);
		this.usuariosCadastroModalUnidades.open(null, 3, null, unidade.id);
	}

	public openUsuariosPequisaModal(unidade: Unidade): void {
		this.setSelectedUnidade(unidade);
		this.usuariosPesquisaModalUnidades.open();
	}

	public onAfterSaveUsuario(usuario: Usuario): void {
		this.onSelectUsuariosPesquisaModal(usuario);
	}

	public onSelectUsuariosPesquisaModal(usuario: Usuario): void {
		this.selectedUnidade.sacado = usuario;
		this.selectedUnidade.id_sacado = usuario.condomino_id;
		this.onChange(this.selectedUnidade);
	}

	public onAfterCancelUsuario(event: any): void {
		//
	}

	public onSelectSacado(tm: TypeaheadMatch, unidade: Unidade): void {
		let usuario = tm.item;
		if (usuario) unidade.id_sacado = usuario.condomino_id;
		else unidade.id_sacado = null;
		this.onChange(unidade);
	}

	public onCleanSacado(unidade: Unidade, input: any) {
		this.setSelectedUnidade(unidade);
		unidade.id_sacado = null;
		unidade.sacado = null;
		unidade.sacadoNotFound = false;
		this.onChange(unidade);
		input.value = '';
		input.focus();
	}
}