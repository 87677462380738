import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { DocumentosComponent } from './documentos.component';
import { DocumentosHttpService } from './services';
import { DocumentosPesquisaModalComponent } from './documentos-pesquisa-modal';
import { DocumentoPastaModalComponent } from './documento-pasta-modal';
import { DocumentosCadastroComponent } from './cadastro';

@NgModule({
	imports: [
		SharedModule,
		TypeaheadModule
	],
	declarations: [
		DocumentosCadastroComponent,
		DocumentosComponent,
		DocumentosPesquisaModalComponent,
		DocumentoPastaModalComponent
	],
	providers: [
		DocumentosHttpService
	],
	exports: [
		DocumentosPesquisaModalComponent
	]
})
export class DocumentosModule { }