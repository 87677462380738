import { BaseEntity } from '../base-entity';

export class Recibo extends BaseEntity {
	id_pagamento: number;
	id_anexo: number;
	numero: number;
	observacao: string;

	nome_lancamento: string;
	nome_fornecedor: string;
}