import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfiguracaoLivro, ConfiguracaoLivroItem } from 'app/models';
import { BaseCadastroComponent } from 'app/componentes-base';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService, AuthService } from 'app/core';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { LivroConfiguracaoHttpService } from '../service';
import { LivroConfiguracaoItemCadastroModalComponent } from '../livro-configuracao-item-cadastro-modal';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'livro-configuracao-cadastro',
  templateUrl: './livro-configuracao-cadastro.component.html',
  styleUrls: ['./livro-configuracao-cadastro.component.css'],
  providers: [ PermissionService ]
})
export class LivroConfiguracaoCadastroComponent extends BaseCadastroComponent<ConfiguracaoLivro> implements OnInit {

  @ViewChild('livroConfiguracaoItemCadastroModal') livroConfiguracaoItemCadastroModal: LivroConfiguracaoItemCadastroModalComponent;

  private arSequencia: number[];

  constructor(activatedRoute: ActivatedRoute,
      router: Router,
      messageService: MessageService,
      permissionService: PermissionService,
      public livroConfiguracaoHttpService: LivroConfiguracaoHttpService,
      private authService: AuthService,
      public spinner: NgxSpinnerService) {
    super(ConfiguracaoLivro, livroConfiguracaoHttpService, activatedRoute, router, messageService, permissionService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.entity.id_cliente = this.authService.getIdCurrentCliente();
    this.entity.id_condominio = this.authService.getIdCurrentCondominio();
  }

  /**
   * Carrega sequencia
   * @author Marcos Frare
   * @since 22/07/2020
   */
  private loadSequence(): void {
    // Carregando o array com opções de sequencia
    this.arSequencia = [];
    let max = 0;
    for (let item of this.entity.configuracaoLivroItens) {
      max = item.sequencia > max ? item.sequencia : max;
      this.arSequencia.push(item.sequencia);
    }
    this.arSequencia.push(max+1);
  }

  /**
   * Carrega os ítens da configuração
   * @author Marcos Frare
   * @since 22/07/2020
   */
  public loadItens(): void {
    this.spinner.show();
    this.entity.configuracaoLivroItens = [];
    this.livroConfiguracaoHttpService.getItensByIdConfiguracao(this.entity.id).subscribe(
      response => {
        this.spinner.hide();
        this.entity.configuracaoLivroItens = response;
      },
      error => {
        this.spinner.hide();
        this.messageService.error('Erro ao carregar itens da configuração!', 'Erro', error);
      }
    );
  }

  /**
   * Editar um ítem da configuração
   * @param item 
   * @author Marcos Frare
   * @since 21/07/2020
   */
  public editItem(item: ConfiguracaoLivroItem): void {
    this.loadSequence();
    this.livroConfiguracaoItemCadastroModal.idConfiguracaoLivroItem = item.id;
    this.livroConfiguracaoItemCadastroModal.arSequencia = this.arSequencia;
    this.livroConfiguracaoItemCadastroModal.open();
  }

  /**
   * excluir um ítem da configuração
   * @param item 
   * @author Marcos Frare
   * @since 21/07/2020
   */
  public deleteItem(item: ConfiguracaoLivroItem): void {
    if (confirm(`Deseja excluir o item da sequencia ${item.sequencia}?`)) {
      this.spinner.show();
      this.livroConfiguracaoHttpService.deleteItem(item.id).subscribe(
        () => {
          this.spinner.hide();
          this.messageService.info('Item excluído com sucesso!');
          this.loadItens();
        },
        error => {
          this.spinner.hide();
          this.messageService.error('Erro ao exlcuir item. Tente novamente.', 'Erro', error);
        }
      );
    }
  }

  /**
   * Abre modal para adicionar item 
   * @author Marcos Frare
   * @since 21/07/2020
   */
  public addItem(): void {
    this.loadSequence();
    this.livroConfiguracaoItemCadastroModal.idConfiguracaoLivroItem = null;
    this.livroConfiguracaoItemCadastroModal.arSequencia = this.arSequencia;
    this.livroConfiguracaoItemCadastroModal.open();
  }

  /**
   * Após salvar
   * @param entity 
   * @author Marcos Frare
   * @since 22/07/2020
   */
  afterSave(entity: ConfiguracaoLivro): void {
    this.router.navigate(['../', entity.id], {relativeTo: this.activatedRoute});
  }

}