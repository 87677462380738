import { Component, OnInit } from '@angular/core';
import { PrevisaoOrcamentaria, PrevisaoCategoriaConta } from 'app/models';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { MessageService, AuthService } from 'app/core';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { datepickerMesAnoOptions } from 'app/shared';
import { DatePipe } from '@angular/common';
import { Permission } from 'app/componentes-base';
import { PrevisaoOrcamentariaHttpService } from '../service';
import { NgxSpinnerService } from 'ngx-spinner';

interface Total {
  orcado: number;
  executado: number;
  previsto: number;
};

@Component({
  selector: 'previsao-orcamentaria-cadastro',
  templateUrl: './previsao-orcamentaria-cadastro.component.html',
  styleUrls: ['./previsao-orcamentaria-cadastro.component.css'],
  providers: [PermissionService, DatePipe, PrevisaoOrcamentariaHttpService],
})
export class PrevisaoOrcamentariaCadastroComponent implements OnInit {

  private resource: string;

  public datepickerDataCompetenciaOptions = datepickerMesAnoOptions;
  public previsaoOrcamentaria: PrevisaoOrcamentaria;
  public permissions: Permission;
  public editMode: boolean;
  public data: any[];
  public totalReceita: Total = {orcado: 0, executado: 0, previsto: 0};
  public totalDespesa: Total = {orcado: 0, executado: 0, previsto: 0};

  private alterado: PrevisaoCategoriaConta[] = [];

  constructor(public activatedRoute: ActivatedRoute,
      public router: Router,
      public messageService: MessageService,
      public permissionService: PermissionService,
      private authService: AuthService,
      private spinner: NgxSpinnerService,
      private previsaoOrcamentariaHttpService: PrevisaoOrcamentariaHttpService) {
    this.resource = '/financeiro/previsao-orcamentaria';
  }

  ngOnInit() {
    this.zeraTotal();
    this.previsaoOrcamentaria = new PrevisaoOrcamentaria();
    this.handleRouteParams();
    this.getPermissions();
  }

  /**
   * Inicializa valore do quadro totalizador
   */
  private zeraTotal(): void {
    this.totalReceita.orcado = 0;
    this.totalReceita.executado = 0;
    this.totalReceita.previsto = 0;
    this.totalDespesa.orcado = 0;
    this.totalDespesa.executado = 0;
    this.totalDespesa.previsto = 0;
  }
  
  /**
   * Verifica se edição ou insersão
   * @auhor Marcos Frare
   * @since 12/06/2020
   */
  private handleRouteParams(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      const id = +params['id'];
      if (!isNaN(id)) {
        this.edit(id);
      } else {
        this.insert();
      }
    });
  }

  /**
   * Busca as contas
   * @author Marcos Frare
   * @since 24/06/2020
   */
  private buscarContas(): void {    
    this.previsaoOrcamentariaHttpService.buscarContas(this.previsaoOrcamentaria.id).subscribe(
      response => {
        this.zeraTotal();
        this.data = response;
        this.spinner.hide();
        this.atualizaContasPai(null);
      },
      error => {
        this.spinner.hide();
        this.messageService.warning('Não possível buscar as contas!', 'Atenção');
      }
    );
  }

  /**
   * Atualiza as contas conforme alteração das contas filhos
   * @param conta 
   * @author Marcos Frare
   * @since 24/06/2020
   */
  private atualizaContasPai(conta: any): any[] {
    let arContas = [];
    if (conta) {
      arContas = conta.children;
    } else {
      arContas = this.data;
    }

    for (let oConta of arContas) {
      const arFilhas = this.atualizaContasPai(oConta);
      if (arFilhas.length > 0) {
        oConta.valor_orcado = 0;
        for (let oContaFilho of arFilhas) {
          oConta.valor_orcado += parseFloat(oContaFilho.valor_orcado);

          // Quadro totalizador
          if (oContaFilho.recebe_valor) {
            if (oConta.tipo_conta == 1) {
              this.totalReceita.orcado += parseFloat(oContaFilho.valor_orcado_referencia);
              this.totalReceita.executado += parseFloat(oContaFilho.valor_executado_referencia);
              this.totalReceita.previsto += parseFloat(oContaFilho.valor_orcado);
            } else {
              this.totalDespesa.orcado += parseFloat(oContaFilho.valor_orcado_referencia);
              this.totalDespesa.executado += parseFloat(oContaFilho.valor_executado_referencia);
              this.totalDespesa.previsto += parseFloat(oContaFilho.valor_orcado);
            }
          }
        }

        const valor_referencia = oConta.referencia_calculo == 'ORCADO' ? oConta.valor_orcado_referencia : oConta.valor_executado_referencia;
        if (valor_referencia != 0) {
          oConta.indice = oConta.valor_orcado != 0 ? (((oConta.valor_orcado / valor_referencia) -1)*100) : 0;
        }
      }
    }
    return arContas;
  }
  
  /**
   * Ao passar pela conta, se a mesma for alterada, persiste dados e atualiza o grid
   * @param conta Dados da conta alterada
   * @author Marcos Frare
   * @since 19/06/2020
   */
  public blurConta(conta: any, campo: string): void {
    const valor_referencia = conta.referencia_calculo == 'ORCADO' ? conta.valor_orcado_referencia : conta.valor_executado_referencia;
    if (valor_referencia != 0 && conta.indice != 0) {
      switch (campo) {
        case 'indice': {
          conta.valor_orcado = (parseFloat(valor_referencia) + (parseFloat(valor_referencia) * parseFloat(conta.indice) / 100));
          break;
        }
        case 'valor_orcado': {
          conta.indice = conta.valor_orcado != 0 ? (((conta.valor_orcado / valor_referencia) -1)*100) : 0;
          break;
        }
      }  
    }

    let formatConta = new PrevisaoCategoriaConta();
    formatConta.id = conta.id;
    formatConta.valor_orcado = conta.valor_orcado;
    formatConta.valor_executado_referencia = conta.valor_executado_referencia;
    formatConta.indice = conta.indice;

    let contaTemp = this.alterado.filter(node => node.id === formatConta.id);

    if (!contaTemp.length) {
      this.alterado.push(formatConta);
    } else {
      this.alterado.map(conta => {
        if (conta.id === formatConta.id) {
          conta.valor_orcado = formatConta.valor_orcado;
          conta.indice = formatConta.indice;
        }
      });
    }
    this.zeraTotal();
    this.atualizaContasPai(null);
  }

  /**
   * Edição de uma Previsão
   * @param id 
   * @author Marcos Frare
   * @since 12/06/2020
   */
  private edit(id: number): void {
    this.spinner.show();
    this.editMode = true;
    this.previsaoOrcamentariaHttpService.buscarDadosId(id).subscribe(
      response => {
        this.previsaoOrcamentaria = response;
        this.data = response.contas;
        this.zeraTotal();
        this.atualizaContasPai(null);
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        this.messageService.error('Erro ao carregar dados da previsão', 'Erro!!', error);
      }
    );
  }

  /**
   * Inserção de nova Previsão
   * @param id_condominio 
   * @author Marcos Frare
   * @since 12/06/2020
   */
  private insert(): void {
    this.previsaoOrcamentaria.id_condominio = this.authService.getIdCurrentCondominio();
    this.editMode = false;
    // Carrega período sugeridos
    this.previsaoOrcamentariaHttpService.sugerirPeriodo(this.previsaoOrcamentaria).subscribe(
      response => {
        this.previsaoOrcamentaria.competencia_inicial = new Date(response.competencia_inicial);
        this.previsaoOrcamentaria.quantidade_mes = response.quantidade_mes;
      },
    );
  }

  /**
   * Ao selecionar parâmetros, processar dados iniciais
   * @author Marcos Frare
   * @since 16/06/2020
   */
  public processar(): void {
    this.loadData();
  }

  /**
   * Aplicar o índice especificado em todas as contas que recebem valor
   * @author Marcos Frare
   * @since 22/06/2020
   */
  public aplicarIndice(): void {
    if (confirm('Esta ação altera todos os índices e valores de contas. Deseja continuar?')) {
      this.spinner.show();
      this.previsaoOrcamentariaHttpService.aplicarIndice(this.previsaoOrcamentaria).subscribe(
        () => {
          // Carregar as contas atualizadas
          this.buscarContas();
        },
        error => {
          this.spinner.hide();
          this.messageService.error('Erro ao aplicar índices', 'Erro!', error);
        }
      );
    }
  }

  /**
   * Carregar os dados iniciais para previsão orçamentária
   * @author Marcos Frare
   * @since 15/06/2020
   */
  private loadData():void {
    this.spinner.show();
    this.previsaoOrcamentariaHttpService.carregar(this.previsaoOrcamentaria).subscribe(
      response => {
        this.spinner.hide();
        if (response.arMsg.length > 0) {
          response.arMsg.forEach(msg => {
            this.messageService.warning(msg, 'Atenção');
          });
        } else {
          // Redireciona para a rota de edição
          this.router.navigate([`../${response.id}`], {relativeTo: this.activatedRoute});
        }
      },
      error => {
        this.data = [];
        this.messageService.error('Erro ao carregar dados iniciais para previsão orçamentária', 'Erro', error);
        this.spinner.hide();
      }
    );
  }

  /**
   * Permissões do Usuário
   * @author Marcos Frare
   * @since 12/06/2020
   */
  private getPermissions(): void {
    this.permissionService.getPermissions(this.resource)
      .subscribe(
        permissions => this.permissions = permissions
      );
  }

  /**
   * Ação para sincronizar contas do plano de contas com a previsão orçamentária
   * @author Marcos Frare
   * @since 22/06/2020
   */
  public sicronizarContas(): void {
    if (confirm('Esta ação faz inclusão de contas que não estão na previsão. Deseja continuar?')) {
      this.spinner.show();
      this.previsaoOrcamentariaHttpService.sincronizarContas(this.previsaoOrcamentaria).subscribe(
        () => {
          // Carregar as contas atualizadas
          this.buscarContas();
        },
        error => {
          this.spinner.hide();
          this.messageService.error('Erro ao sincronizar contas! Tente novamente.', 'Erro', error);
        }
      );
    }
  }

  /**
   * Salvar todo o orçamento
   * @author Marcos Frare
   * @since 23/06/2020
   */
  public save(): void {
    this.spinner.show();

    let payload = this.previsaoOrcamentaria;
    payload.contas = this.alterado;

    this.previsaoOrcamentariaHttpService.saveOrcamento(payload).subscribe(
      () => {
        // Limpar o array de contas alteradas
        this.alterado = [];
        // Carregar as contas atualizadas
        this.buscarContas();
      },
      error => {
        this.spinner.hide();
        this.messageService.error('Erro ao gravar contas! Tente novamente.', 'Erro', error);
      }
    );
  }
  
  /**
   * Ação do botão cancelar
   * @autor Marcos Frare
   * @since 12/06/2020
   */
  public cancel(): void {
    if (this.alterado.length > 0) {
      if (confirm('Possuem contas alteradas e não salvas! Deseja salvar as alterações?')) {
        this.save();
        this.router.navigate(['../'], {relativeTo: this.activatedRoute});
      } else {
        this.router.navigate(['../'], {relativeTo: this.activatedRoute});
      }
    } else {
      this.router.navigate(['../'], {relativeTo: this.activatedRoute});
    }
  }

}