import { BaseEntity } from "../base-entity";
import { Anexo } from './anexo';
import { Bloco } from './bloco';
import { Unidade } from './unidade';

export class Orcamento extends BaseEntity {
	id: number;
	id_condominio: number;
	prazo_entrega: Date;
	data_orcamento: Date;
	descricao: string;
	email: boolean;
	fornecedores: number[];
	tipo_orcamento: number;
	arquivo: Anexo[];
	destinatarios: number[];
	remetente: string;
	tipo_remetente: number;
	bloco: Bloco;
	id_bloco: number;
	unidade: Unidade;
	id_unidade: number;

	constructor() {
		super();
		this.arquivo = [new Anexo()];
	}
}

export enum TIPOS_REMETENTEORCAMENTO {
	ADMINISTRADORA = 1,
	CONDOMINIO = 2
};

export const tiposItem = [
	{ id: 1, nome: 'Inventário' },
	{ id: 2, nome: 'Local' },
	{ id: 3, nome: 'Seguros' },
	{ id: 4, nome: 'Alvarás' },
	{ id: 5, nome: 'Licença' }
];

export const tiposOrcamento = [
	{ id: 1, nome: 'Revisao' },
	{ id: 2, nome: 'Limpeza' },
	{ id: 3, nome: 'Revalidação' },
	{ id: 4, nome: 'Renovação' },
	{ id: 5, nome: 'Recarga' },
	{ id: 6, nome: 'Construção' },
	{ id: 7, nome: 'Melhorias' },
	{ id: 8, nome: 'Reparos' },
	{ id: 9, nome: 'Serviços' },
	{ id: 10, nome: 'Aquisição' },
	{ id: 11, nome: 'Aluguel' }
];

export const destinatarioOrcamento = [
	{ id: 1, nome: 'Administradora', checked: false, disabled: false },
	{ id: 2, nome: 'Síndico', checked: false, disabled: false }
];

export const RemetentesOrcamento = [
	{ id: 1, nome: 'Administradora' },
	{ id: 2, nome: 'Condomínio' }
];


