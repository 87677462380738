import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';

import { Crud, CrudService, ConfigService, CustomHttp, AuthService } from '../../../core';
import { LancamentosCondominio, Pagamento, TemplateLancamentoCondominio, TipoDocumentoFiscal } from '../../../models';

@Injectable()
export class LancamentosCondominioHttpService implements Crud<LancamentosCondominio> {
	resource = 'lancamentoscondominio';
	private baseUrl: string;

	constructor(private customHttp: CustomHttp,
		private crudService: CrudService,
		private configService: ConfigService,
		private authService: AuthService) {
		this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
	}

	get(id: number): Observable<LancamentosCondominio> {
		return this.crudService.get<LancamentosCondominio>(this.resource, id);
	}

	getAll(): Observable<LancamentosCondominio[]> {
		return this.crudService.getAll<LancamentosCondominio>(this.resource);
	}

	post(entity: LancamentosCondominio): Observable<LancamentosCondominio> {
		return this.crudService.post<LancamentosCondominio>(this.resource, entity);
	}

	put(entity: LancamentosCondominio): Observable<LancamentosCondominio> {
		return this.crudService.put<LancamentosCondominio>(this.resource, entity);
	}

	delete(entity: LancamentosCondominio): Observable<string> {
		const id_lancamento_condominio = entity.id;
		const id_usuario = this.authService.getIdCurrentUser();
		return this.customHttp.post(`${this.baseUrl}/desativar`, { id_lancamento_condominio, id_usuario }).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Carrega todos os lançamentos do condomínio informado
	 */
	public getAllByCondominio(idCondominio: number): Observable<any> {
		return this.customHttp.get(`${this.baseUrl}/consultar/condominio/${idCondominio}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public search(filtro: any): Observable<any> {
		return this.customHttp.post(`${this.baseUrl}/filtrar`, filtro).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Busca as templates de lançamentos do condomínio
	 * @author Marcos Frare
	 * @since 06/08/2020
	 */
	public buscaTemplatesCondominio(id_condominio: number): Observable<TemplateLancamentoCondominio[]> {
		return this.customHttp.get(`${this.baseUrl}/template`, { params: { id_condominio } }).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Busca as templates de lançamentos do condomínio
	 * @author Marcos Frare
	 * @since 06/08/2020
	 */
	public buscaTemplatesCondominioConciliacao(id_condominio: number): Observable<TemplateLancamentoCondominio[]> {
		return this.customHttp.get(`${this.baseUrl}/template`, { params: { id_condominio, utilidade: 'CONCILIACAO' } }).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Busca as templates de lançamentos do condomínio
	 * @author Marcos Frare
	 * @since 06/08/2020
	 */
	public buscaTemplateId(id: number): Observable<TemplateLancamentoCondominio> {
		return this.customHttp.get(`${this.baseUrl}/template/${id}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Persistir os dados de uma template de lançamento
	 * @param template 
	 * @author Marcos Frare
	 * @since 07/08/2020
	 */
	public saveTemplate(template: TemplateLancamentoCondominio): Observable<TemplateLancamentoCondominio> {
		return this.customHttp.post(`${this.baseUrl}/template`, template).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Exclusão de uma template
	 * @author Marcos Frare
	 * @since 06/08/2020
	 */
	public deleteTemplate(id: number): Observable<TemplateLancamentoCondominio> {
		return this.customHttp.delete(`${this.baseUrl}/template/${id}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Buscar templates de lançamento conforme competência informada
	 * @param id_condominio 
	 * @param competencia 
	 * @author Marcos Frare
	 * @since 10/08/2020
	 */
	public loadTemplatesCompetencia(id_condominio: number, competencia: Date): Observable<TemplateLancamentoCondominio[]> {
		return this.customHttp.get(`${this.baseUrl}/template/competencia`, { params: { id_condominio, competencia, utilidade: 'FIXO' } }).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Salvar os lançamentos
	 * @param templates 
	 * @author Marcos Frare
	 * @since 10/08/2020
	 */
	public salvarLancamentosTemplate(id_condominio: number, templates: TemplateLancamentoCondominio[]): Observable<any> {
		return this.customHttp.post(`${this.baseUrl}/template/lancamento`, { id_condominio, templates }).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Persistir dos dados de lançamento de uma conciliação
	 * @param id_template 
	 * @param extratosLancamento 
	 * @author Marcos Frare
	 * @since 11/08/2020
	 */
	public salvarLancamentoConciliacao(entity: any): Observable<any> {
		return this.customHttp.post(`${this.baseUrl}/template/conciliacao`, entity).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Obtem todos os tipos de documento fiscal
	 * @author Marcos Frare
	 * @since 14/10/2020
	 */
	public obtemTiposDocumentoFiscal(): Observable<TipoDocumentoFiscal[]> {
		return this.customHttp.get(`${this.baseUrl}/documentofiscal/tipo`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Busca o lançamento e anexos
	 * @param id 
	 * @author Marcos Frare
	 * @since 01/12/2020
	 */
	public getLancamentoAnexos(id: number): Observable<LancamentosCondominio> {
		return this.customHttp.get(`${this.baseUrl}/anexo/${id}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Persiste os anexos em banco de dados
	 * @param lancamento
	 * @author Marcos Frare
	 * @since 01/12/2020
	 */
	public gravarAnexos(lancamento: LancamentosCondominio): Observable<LancamentosCondominio> {
		return this.customHttp.post(`${this.baseUrl}/anexo`, lancamento).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Busca os pagamentos de um lancamento
	 * @param id_lancamento 
	 * @returns Array Pagamento
	 */
	public getAllByLancamentoId(id_lancamento: number): Observable<Pagamento[]> {
		return this.customHttp.get(`${this.baseUrl}/pagamentos/ajuste/lancamento/${id_lancamento}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Atualização dos pagamentos
	 * @param id_lancamento 
	 * @param pagamentos 
	 * @author Marcos Frare
	 * @since 14/08/2023
	 */
	public setPagamentosByLancamento(id_lancamento: number, pagamentos: Pagamento[]): Observable<any> {
		return this.customHttp.put(`${this.baseUrl}/pagamentos/ajuste/lancamento/${id_lancamento}`, {pagamentos}).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

}