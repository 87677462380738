import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseCadastroComponent } from '../../../componentes-base';
import { AuthService, MessageService } from '../../../core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { CategoriaContas, LancamentoUnidade, PagamentoUnidade, TYPES_PAGAMENTO, Fornecedor, Bloco, Unidade } from '../../../models';
import { LancamentosUnidadesHttpService } from '../services';
import { PermissionService } from '../../../core/services/permission/permission.service';
import { FloatFormatPipe, DecimalFormatPipe } from '../../../shared/pipes';
import { FornecedoresHttpService, FornecedoresCadastroModalComponent } from 'app/home/fornecedores';
import { LancamentoPagamentosComponent } from 'app/componentes-utils/lancamento-pagamentos';
import { BlocosHttpService, BlocosPesquisaModalComponent, UnidadesHttpService, UnidadesPesquisaModalComponent } from 'app/home/blocos';
import { CategoriaContasHttpService, CategoriaContasPesquisaModalComponent, PlanoDeContasCadastroModalComponent } from 'app/home/plano-de-contas';
import { NgxSpinnerService } from 'ngx-spinner';

declare var jQuery: any;

@Component({
	selector: 'lancamentos-unidades-cadastro',
	templateUrl: './lancamentos-unidades-cadastro.component.html',
	providers: [
		UnidadesHttpService,
		PermissionService,
		FloatFormatPipe,
		DecimalFormatPipe
	]
})
export class LancamentosUnidadesCadastroComponent extends BaseCadastroComponent<LancamentoUnidade> implements OnInit {

	@ViewChild('categoriaContasPesquisaModal') categoriaContasPesquisaModal: CategoriaContasPesquisaModalComponent;
	@ViewChild('planoDeContasCadastroModal') planoDeContasCadastroModal: PlanoDeContasCadastroModalComponent;
	@ViewChild('fornecedoresCadastroModal') fornecedoresCadastroModal: FornecedoresCadastroModalComponent;
	@ViewChild('lancamentoPagamentos') lancamentoPagamentos: LancamentoPagamentosComponent;
	@ViewChild('inputBloco') inputBloco: ElementRef;
	@ViewChild('inputUnidade') inputUnidade: ElementRef;
	@ViewChild('blocosPesquisaModal') blocosPesquisaModal: BlocosPesquisaModalComponent;
	@ViewChild('unidadesPesquisaModal') unidadesPesquisaModal: UnidadesPesquisaModalComponent;

	public idCliente: number;
	public idCondominio: number;
	public idBloco: number;
	public idUnidade: number;
	public bloco: Bloco;
	public blocos: Bloco[] = [];
	public unidade: Unidade;
	public unidades: Unidade[] = [];
	public fornecedores: Fornecedor[] = [];
	public categoriasContas: CategoriaContas[] = [];
	public pagamentos: PagamentoUnidade[];
	public allowDeletePagamentos = true;
	public valor_total_inicial: number;
	public typesPagamento = TYPES_PAGAMENTO;

	constructor(public activatedRoute: ActivatedRoute,
			public router: Router,
			public messageService: MessageService,
			public lancamentosUnidadesHttpService: LancamentosUnidadesHttpService,
			public permissionService: PermissionService,
			private authService: AuthService,
			private categoriaContasHttpService: CategoriaContasHttpService,
			private unidadesHttpService: UnidadesHttpService,
			private floatFormatPipe: FloatFormatPipe,
			private decimalFormatPipe: DecimalFormatPipe,
			private fornecedoresHttpService: FornecedoresHttpService,
			private blocosHttpService: BlocosHttpService,
			private spinner: NgxSpinnerService) {
		super(LancamentoUnidade, lancamentosUnidadesHttpService, activatedRoute, router, messageService, permissionService);
	}

	ngOnInit() {
		super.ngOnInit();
		this.idCondominio = this.authService.getIdCurrentCondominio();
		this.idCliente = this.authService.getIdCurrentCliente();
		this.getAllFornecedores();
		this.getAllCategoriasByCondominio();
		this.getBlocosByCondominio();
	}

	/**
	 * Verifica se o lançamento pode ser editado
	 * @author Marcos Frare
	 * @since 13/08/2021
	 */
	public getEdit(): boolean {
		for (let pg of this.entity.pagamentos) {
			if (pg.cobrado) {
				return true;
			}
		}
		return false;
	}

	private getAllFornecedores(): void {
		this.fornecedoresHttpService.getAllByCliente(this.idCliente).subscribe(entities => this.fornecedores = entities,
			error => this.messageService.error('Erro ao carregar os fornecedores', 'Erro', error)
		);
	}

	private getAllCategoriasByCondominio(): void {
		this.categoriasContas = [];
		// Contas de Receita
		if (this.idCondominio) {
			this.categoriaContasHttpService.getAllByCondominio(this.idCondominio, 1).then(entities => this.categoriasContas = entities);
		}
	}

	private getBloco(idBloco: number): void {
		this.blocosHttpService.get(idBloco).subscribe(
			entity => this.bloco = entity,
			error => this.messageService.error('Erro ao carregar o bloco do lançamento. Tente novamente', 'Erro', error)
		);
	}

	private getUnidade(idUnidade: number): void {
		this.unidadesHttpService.get(idUnidade).subscribe(
			entity => {
				this.getBloco(entity.id_bloco);
				this.entity.unidade = entity;
			},
			error => this.messageService.error('Erro ao carregar a unidade do lançamento. Tente novamente', 'Erro', error)
		);
	}

	private parsePagamentos(pagamentos: any[]): void {
		this.entity.pagamentos = pagamentos.map(pagamento => {
			pagamento.valor = this.floatFormatPipe.transform(pagamento.valor);
			pagamento.permite_exclusao = pagamento.data_pagamento || pagamento.id_rateio_unidade ? false : true;
			return pagamento;
		});
	}

	public updatePagamentos(): void {
		this.lancamentoPagamentos.updatePagamentos();
	}

	public dividirEntreParcelas(): void {
		this.lancamentoPagamentos.dividirEntreParcelas();
	}

	public canEditPagamento(pagamento: any): boolean {
		return this.lancamentoPagamentos.canEditPagamento(pagamento);
	}

	public afterEdit(entity: LancamentoUnidade): void {
		this.entity.valor_total = this.floatFormatPipe.transform(this.entity.valor_total);
		this.valor_total_inicial = this.entity.valor_total;
		this.parsePagamentos(entity.pagamentos);
		this.getUnidade(entity.id_unidade);
		this.setCategoriaContas();
		super.afterEdit(entity);
	}

	public afterInsert(): void {
		this.entity.id_unidade = this.idUnidade;
		super.afterInsert();
	}

	private setCategoriaContas(): void {
		if (!this.entity.id_categoria_conta) {
			return;
		}
		this.categoriaContasHttpService.get(this.entity.id_categoria_conta).subscribe(
			entity => this.entity.categoriaContas = entity
		);
	}

	public onCleanCategoriaContas(input: any): void {
		this.entity.id_categoria_conta = null;
		this.entity.categoriaContas = null;
		input.value = '';
		input.focus();
	}

	public openPlanoDeContasCadastroModal(): void {
		this.planoDeContasCadastroModal.open();
	}

	public openCategoriaContasPequisaModal(): void {
		this.categoriaContasPesquisaModal.open();
	}

	public onSelectCategoriaContas(tm: TypeaheadMatch): void {
		const categoriaContas: CategoriaContas = tm.item as CategoriaContas;
		if (categoriaContas) {
			this.entity.id_categoria_conta = categoriaContas.id;
			this.entity.categoriaContas = categoriaContas;
			this.entity.id_fornecedor = categoriaContas.id_fornecedor;
		} else {
			this.entity.id_categoria_conta = null;
			this.entity.categoriaContas = null;
		}
	}

	public onSelectCategoriaContasPesquisaModal(categoriaContas: CategoriaContas): void {
		if (categoriaContas) {
			this.entity.id_categoria_conta = categoriaContas.id;
			this.entity.categoriaContas = categoriaContas;
			this.entity.id_fornecedor = categoriaContas.id_fornecedor;
		}
		jQuery('#categoriaContas').focus();
	}

	public onAfterSaveContaCategoriaConta(categoriaContas: CategoriaContas): void {
		this.onSelectCategoriaContasPesquisaModal(categoriaContas);
	}

	public createFornecedor(): void {
		this.fornecedoresCadastroModal.open();
	}

	public onAfterSaveFornecedor(fornecedor: Fornecedor): void {
		this.entity.id_fornecedor = fornecedor.id;
		this.fornecedores.push(fornecedor);
		jQuery('#fornecedor').focus();
	}

	public beforeSave(): boolean {
		const totalPagamentos = Number((this.entity.pagamentos.map(x => x.valor).reduce((x, y) => x + y)).toFixed(2));
		if (!this.entity.id_unidade) {
			this.messageService.info('Para salvar é preciso informar corretamente todos os campos destacados em vermelho', 'Informação');
			return false;
		}
		if (!this.bloco) {
			this.messageService.info('Para salvar é preciso informar corretamente todos os campos destacados em vermelho', 'Informação');
			return false;
		}
		if (!this.entity.id_categoria_conta) {
			this.messageService.info('Para salvar é preciso informar corretamente todos os campos destacados em vermelho', 'Informação');
			return false;
		}
		if (this.entity.valor_total !== totalPagamentos) {
			this.messageService.warning('O valor total do lançamento está diferente da soma de suas parcelas, favor corrigir os valores e tentar novamente', 'Atenção');
			return false;
		}
		if (this.editMode) {
			if (this.entity.valor_total !== this.valor_total_inicial) {
				if (!confirm(`O valor total do lançamento foi alterado de ${this.decimalFormatPipe.transform(this.valor_total_inicial, 1, 2, 2)} para ${this.decimalFormatPipe.transform(this.entity.valor_total, 1, 2, 2)}, confirma essa alteração?`)) {
					return false;
				}
			}
		}
		if (!this.validaSavePagamento()) return false;
		return true;
	}

	public save(): Promise<any> {
		this.spinner.show();
		return new Promise<any>((resolve, reject) => {
			this.makeAllFieldsDirty();

			if (this.form.valid) {
				if (this.beforeSave()) {
					this.btnSave = jQuery('#btnSave');
					this.btnSave.button('loading');

					const envio: any = { ...this.entity };
					const pagamentos: any[] = [];
					this.entity.pagamentos.forEach(pagamento => {
						const obj: any = { ...pagamento };
						pagamentos.push(obj);
					});
					envio.pagamentos = pagamentos.filter(pagamento => this.canEditPagamento(pagamento)).map(pagamento => {
						return pagamento;
					});
					if (this.editMode) { // PUT
						this.crudService.put(envio).subscribe(
							entity => {
								this.spinner.hide();
								this.messageService.success('', 'Registro salvo com sucesso');
								this.btnSave.button('reset');
								this.afterSave(this.entity);
								resolve(entity);
							},
							error => {
								this.spinner.hide();
								this.messageService.error('Erro ao atualizar o registro', 'Erro', error);
								this.btnSave.button('reset');
								reject(null);
							}
						);
					} else { // POST
						this.crudService.post(envio).subscribe(
							entity => {
								this.spinner.hide();
								this.entity = entity;
								this.messageService.success('', 'Registro salvo com sucesso');
								this.btnSave.button('reset');
								this.afterSave(this.entity);
								resolve(entity);
							},
							error => {
								this.spinner.hide();
								this.messageService.error('Erro ao salvar o registro', 'Erro', error);
								this.btnSave.button('reset');
								reject(null);
							}
						);
					}
				} else {
					this.spinner.hide();
					reject(null);
				}
			} else {
				this.spinner.hide();
				this.showMessageFormInvalid();
				reject(null);
			}
		});
	}

	public afterSave(entity: LancamentoUnidade): void {
		this.entity.valor_total = this.floatFormatPipe.transform(this.entity.valor_total);
		super.afterSave(entity);
	}

	private validaSavePagamento(): boolean {
		for (const pagamento of this.entity.pagamentos) {
			if (!pagamento.valor || isNaN(pagamento.valor)) {
				this.messageService.info(`É necessário informar o valor da ${pagamento.numero_parcela}ª parcela`, 'Informação');
				return false;
			}
			if (!pagamento.data_competencia) {
				this.messageService.info(`É necessário informar a competência da ${pagamento.numero_parcela}ª parcela`, 'Informação');
				return false;
			}
		}
		return true;
	}

	public getBlocosByCondominio(): void {
		this.blocos = [];
		this.blocosHttpService.getAllByCondominio(this.idCondominio).subscribe(
			entities => this.blocos = entities,
			error => this.messageService.error('Erro ao carregar os blocos do condomínio. Tente novamente', 'Erro', error)
		);
	}

	private setBloco(blocoSelecionado: Bloco): void {
		this.bloco = blocoSelecionado;
		this.idBloco = this.bloco.id;
		this.getAllUnidadesByBloco(this.bloco.id);
		this.onCleanUnidade(this.inputUnidade.nativeElement);
	}

	public onSelectBloco(tm: TypeaheadMatch): void {
		this.setBloco(tm.item as Bloco);
	}

	public openBlocosPequisaModal(): void {
		this.blocosPesquisaModal.open();
	}

	public onSelectBlocosPesquisaModal(bloco: Bloco): void {
		if (bloco) this.setBloco(bloco);
		jQuery('#bloco').focus();
	}

	public onCleanBloco(input: any): void {
		this.onCleanUnidade(this.inputUnidade.nativeElement);
		this.bloco = null;
		this.idBloco = null;
		input.value = '';
		input.focus();
	}

	public getAllUnidadesByBloco(idBloco: number): void {
		this.unidades = [];
		this.unidadesHttpService.getAllByBloco(idBloco).subscribe(
			entities => {
				for (const unidade of entities) {
					if (unidade.nome) {
						this.unidades.push(unidade);
					}
				}
			},
			error => this.messageService.error('Erro ao carregar as unidades do bloco. Tente novamente', 'Erro', error)
		);
	}

	private setUnidade(unidadeSelecionada: Unidade): void {
		this.entity.unidade = unidadeSelecionada;
		this.entity.id_unidade = unidadeSelecionada.id;
	}

	public onSelectUnidade(tm: TypeaheadMatch): void {
		this.setUnidade(tm.item as Unidade);
	}

	public onCleanUnidade(input: any): void {
		this.entity.unidade = null;
		this.entity.id_unidade = null;
		input.value = '';
		input.focus();
	}

	public openUnidadesPequisaModal(): void {
		this.unidadesPesquisaModal.open();
	}

	public onSelectUnidadesPesquisaModal(unidade: Unidade): void {
		if (unidade) {
			this.setUnidade(unidade);
		}
		jQuery('#unidade').focus();
	}

}