import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';

import { Crud, CrudService, ConfigService, CustomHttp, AuthService } from '../../../core';
import { TipoConsumo, CondominioTiposConsumo } from '../../../models';
import { isNull } from 'util';

@Injectable()
export class TiposConsumoHttpService implements Crud<TipoConsumo> {
	resource = 'tipoconsumo';
	private baseUrl: string;
	private id_cliente: number;
	private id_condominio: number;

	constructor(private customHttp: CustomHttp,
		private crudService: CrudService,
		private authService: AuthService,
		private configService: ConfigService) {
		this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
		this.id_cliente = this.authService.getIdCurrentCliente();
		this.id_condominio = this.authService.getIdCurrentCondominio();
	}

	get(id: number): Observable<TipoConsumo> {
		return this.crudService.get<TipoConsumo>(this.resource, id);
	}

	getAll(): Observable<TipoConsumo[]> {
		return this.crudService.getAll<TipoConsumo>(this.resource);
	}

	post(entity: TipoConsumo): Observable<TipoConsumo> {
		return this.crudService.post<TipoConsumo>(this.resource, entity);
	}

	put(entity: TipoConsumo): Observable<TipoConsumo> {
		return this.crudService.put<TipoConsumo>(this.resource, entity);
	}

	delete(entity: TipoConsumo): Observable<string> {
		return this.crudService.delete<TipoConsumo>(this.resource, entity);
	}

	/**
	 * Carrega todos tipos de consumo pelo condomínio informado
	 */
	public getAllByCondominio(idCondominio: number): Observable<TipoConsumo[]> {
		return this.customHttp.get(`${this.baseUrl}/condominio/consultar/${idCondominio}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public getConfiguradasByCondominio(idCondominio: number): Observable<TipoConsumo[]> {
		return this.customHttp.get(`${this.baseUrl}/condominio/configuracao/${idCondominio}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public getAllByCliente(idCliente: number): Observable<TipoConsumo[]> {
		return this.customHttp.get(`${this.baseUrl}/cliente/consultar/${idCliente}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public getAllTipos(global: boolean): Observable<TipoConsumo[]> {
		this.id_condominio = this.authService.getIdCurrentCondominio();
		const url = isNull(this.id_condominio) ? `cliente/consultar/${this.id_cliente}` : `condominio/consultar/${this.id_condominio}`;
		return this.customHttp.get(`${this.baseUrl}/${url}`, { params: { global, id_cliente: this.authService.getIdCurrentCliente() } }).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public getAllByCondominioComTarifas(id_condominio: number, data_competencia: string): Observable<TipoConsumo[]> {
		return this.customHttp.get(`${this.baseUrl}/tarifasconsumo`, { params: { id_condominio, data_competencia } }).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Remove o vínculo da tabela com o Condomínio
	 * @param tipoConsumo 
	 * @author Marcos Frare
	 * @since 18/02/2020
	 */
	public desvincularTabelaCondominio(tipoConsumo: any): Observable<string> {
		let options = {
			body: {
				id_usuario: this.authService.getIdCurrentUser(),
				id_cliente: this.authService.getIdCurrentCliente(),
				id_condominio: this.authService.getIdCurrentCondominio()
			}
		}
		return this.customHttp.delete(`${this.baseUrl}/condominio/desvincular/${tipoConsumo.id_tipo_consumo}`, options).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Vincula uma tabela com o Condomínio
	 * @param tipoConsumo 
	 * @author Marcos Frare
	 * @since 18/02/2020
	 */
	public vincularTabelaCondominio(condominioTiposConsumo: CondominioTiposConsumo): Observable<CondominioTiposConsumo> {
		const save = condominioTiposConsumo.id ?
			this.customHttp.put(`${this.baseUrl}/condominio/editar/${condominioTiposConsumo.id}`, condominioTiposConsumo) :
			this.customHttp.post(`${this.baseUrl}/condominio/vincular`, condominioTiposConsumo);
		return save.pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

}