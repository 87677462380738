import { NgModule } from '@angular/core';

import { SharedModule } from '../../../shared';

import { BalanceteRelComponent } from './balancete-rel.component';
import { ContasBancariasHttpService } from '../../contas-bancarias';

@NgModule({
	imports: [
		SharedModule
	],
	declarations: [
		BalanceteRelComponent
	],
	exports: [],
	providers: [
		ContasBancariasHttpService
	]
})
export class BalanceteRelModule { }