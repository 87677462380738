import { Injectable } from '@angular/core';

import { UnidadeEstrutura } from '../unidade-estrutura';
import { UnidadeEstruturaItem } from '../unidade-estrutura-item';
import { ConsumoUnidade, TipoConsumo } from '../../../../models';

import { MessageService } from '../../../../core';
import { TiposConsumoHttpService } from '../../../tipos-consumo';
import { ConsumosUnidadesHttpService} from '../../services';


interface UnidadeEstruturaCreate {
    blocos: any[];
    tiposConsumo: TipoConsumo[];
    consumos: ConsumoUnidade[];
}

export interface UnidadeEstruturaResponse {
    estrutura: UnidadeEstrutura[];
    tiposConsumo: TipoConsumo[];
}

export interface BlocosEstruturaResponse {
    blocos: any[];
    tiposConsumo: TipoConsumo[];
}

/**
 * Service que auxilia na criação da estrutura de consumos das unidades
 */
@Injectable()
export class ConsumosUnidadesEstruturaService {

    constructor(private messageService: MessageService,
                private consumosUnidadesHttpService: ConsumosUnidadesHttpService,
                private tiposConsumoHttpService: TiposConsumoHttpService) { }

     /* Carrega as unidades do bloco, os tipos de consumos do condomínio e os consumos
    das unidades do bloco no mês/data competência para gerar a estrutura de lançamento dos consumos */
    private getDadosParaCriarEstrutura(idCondominio: number, dataCompetencia: string): Promise<UnidadeEstruturaCreate> {
        return new Promise<UnidadeEstruturaCreate>((resolve, reject) => {
            let unidadeEstruturaCreate: UnidadeEstruturaCreate = {blocos: [], tiposConsumo: [], consumos: []};
            // 1 - Carrega as unidades do bloco
            this.consumosUnidadesHttpService.getAllUnidadesByCondominio(idCondominio)
                .subscribe(
                    blocos => {
                        unidadeEstruturaCreate.blocos = blocos;
                        // 2 - Carrega os tipos de consumos do condomínio
                        this.tiposConsumoHttpService.getAllByCondominioComTarifas(idCondominio, dataCompetencia)
                            .subscribe(
                                tiposConsumo => {
                                    unidadeEstruturaCreate.tiposConsumo = tiposConsumo;
                                    // Caso o tipo de consumo tenha alguma mensagem, mosta a mensagem
                                    for (const oTipoConsumo of tiposConsumo) {
                                        if (oTipoConsumo.msg && oTipoConsumo.msg != '') {
                                            this.messageService.warning(oTipoConsumo.msg, 'Atenção!');
                                        }
                                    }
                                    // 3 - Carrega os consumos das unidades
                                    this.consumosUnidadesHttpService.getConsumosUnidades(idCondominio, dataCompetencia)
                                        .subscribe(
                                            consumos => {
                                                unidadeEstruturaCreate.consumos = consumos;
                                                resolve(unidadeEstruturaCreate);
                                            },
                                            error => {
                                                this.messageService.error('Erro ao carregar os consumos do bloco e mês competência. Tente novamente', 'Erro', error);
                                                reject(error);
                                            }
                                        );
                                },
                                error => {
                                    this.messageService.error('Erro ao carregar os tipos de consumos do condomínio. Tente novamente', 'Erro', error);
                                    reject(error);
                                }
                            );
                    },
                    error => {
                        this.messageService.error('Erro ao carregar as unidades do bloco selecionado. Tente novamente', 'Erro', error);
                        reject(error);
                    }
                );
        });
    }

    /**
     * Cria a estrutura de consumos para as unidades do bloco informado e os tipos de consumos do condomínio informado
     * dentro de uma data de competência
     */
    createEstutura(idCondominio: number, dataCompetencia: string): Promise<BlocosEstruturaResponse> {
        return new Promise<BlocosEstruturaResponse>((resolve, reject) => {
            this.getDadosParaCriarEstrutura(idCondominio, dataCompetencia)
                .then(unidadeEstruturaCreate => {
                    const response: BlocosEstruturaResponse = { blocos:[], tiposConsumo: unidadeEstruturaCreate.tiposConsumo };

                    for (const bloco of unidadeEstruturaCreate.blocos) {

                        const blocoEstrutura = {id: bloco.id, nome: bloco.nome, estrutura:[]};

                        for(const unidade of bloco.unidades) {
                            const unidadeEstrutura = new UnidadeEstrutura();
                            unidadeEstrutura.unidade = unidade;
                            unidadeEstrutura.unidade.bloco = bloco.nome;

                            for (const tipoConsumo of unidadeEstruturaCreate.tiposConsumo) {
                                const unidadeEstruturaItem = new UnidadeEstruturaItem();
                                unidadeEstruturaItem.consumoUnidade = new ConsumoUnidade();
                                unidadeEstruturaItem.consumoUnidade.id_unidade = unidade.id;
                                unidadeEstruturaItem.consumoUnidade.id_tipo_consumo = tipoConsumo.id;
                                unidadeEstruturaItem.consumoUnidade.data_competencia = dataCompetencia;
                                unidadeEstruturaItem.tipoConsumo = tipoConsumo;
                                unidadeEstrutura.items.push(unidadeEstruturaItem);
                            }
                            blocoEstrutura.estrutura.push(unidadeEstrutura);
                        }
                        blocoEstrutura.estrutura = this.setConsumos(blocoEstrutura.estrutura, unidadeEstruturaCreate.consumos);
                        response.blocos.push(blocoEstrutura);
                    }

                    resolve(response);
                })
                .catch(error => reject(error));
        });
    }

    /**
     * Atribui os conumos de cada tipo de consumo para as unidades que possuirem os mesmos
     */
    private setConsumos(estrutura: UnidadeEstrutura[], consumos: ConsumoUnidade[]): UnidadeEstrutura[] {
        if (!estrutura || !consumos) {
            return estrutura;
        }
        // 1 - Percorre os consumos existes
        for (let consumo of consumos) {
            // 2 - Percorre a estrutura criada para o lançamentos dos consumos
            for (let unidadeEstrutura of estrutura) {
                // 3 - Percorre os itens da estrutra (consumos por tipo de consumo)
                for (let unidadeEstruturaItem of unidadeEstrutura.items) {
                    // Verifica se a unidade possui o consumo através do id da unidade e id do tipo de consumo
                    if (consumo.id_unidade === unidadeEstruturaItem.consumoUnidade.id_unidade && consumo.id_tipo_consumo === unidadeEstruturaItem.consumoUnidade.id_tipo_consumo) {
                        unidadeEstruturaItem.consumoUnidade = consumo;
                    }
                }
            }
        }
        return estrutura;
    }
}
