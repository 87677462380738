import { BaseEntity } from '../base-entity';
import { ContaBancaria } from './conta-bancaria';

export class ConfiguracaoCobranca extends BaseEntity {
	id_conta_bancaria: number;
	tipo_protesto: number;
	dias_protesto: number;
	instrucao_protesto: string;

	conta_bancaria: ContaBancaria;

	constructor() {
		super();
		this.id_conta_bancaria = null;
		this.tipo_protesto = 3;
		this.dias_protesto = 0;
		this.instrucao_protesto = null;
		this.conta_bancaria = new ContaBancaria();
	}
}

export const tiposProtestoPadrao = [
	{ "codigo": 3, "descricao": "Não protestar" },
	{ "codigo": 1, "descricao": "Protestar dias corridos" }
];