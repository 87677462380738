import { Component, OnInit, Input } from '@angular/core';
import { LocalHttpService } from '../../services';
import { MessageService, AuthService } from 'app/core';
import { Local, LocalUtensilio, tipo_local_utensilio, Inventario } from 'app/models';
import { BaseModal } from 'app/componentes-utils/modal';
import { InventariosHttpService } from 'app/home/inventarios/services';
import { PermissionService } from 'app/core/services/permission/permission.service';

declare var jQuery: any;

@Component({
  selector: 'local-utensilio-modal',
  templateUrl: './local-utensilio-modal.component.html',
  styleUrls: ['./local-utensilio-modal.component.css'],
  providers: [ PermissionService ]
})
export class LocalUtensilioModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @Input() local: Local;

  public localUtensilio: LocalUtensilio;
  public tiposLocalUtensilio = tipo_local_utensilio;
  public inventarios: Inventario[] = [];
  public utensilios: LocalUtensilio[] = [];

  constructor(private localHttpService: LocalHttpService,
      public messageService: MessageService,
      private authService: AuthService,
      private inventariosHttpService: InventariosHttpService) {
    this.localUtensilio = new LocalUtensilio();
  }

  ngOnInit() {
    if (!this.idModal) {
      this.idModal = 'localUtensilioModal';
    }
  }

  open() {
    this.buscaInventario();
    jQuery(`#${this.idModal}`).modal('show');
    this.getAllUtensilio();
  }
  close() {
    this.localUtensilio = new LocalUtensilio();
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Busca os inventários
   * @author Marcos Frare
   * @since 12/03/2020
   */
  private buscaInventario(): void {
    this.inventarios = [];
    this.inventariosHttpService.getNotAlocateAllByCondominio(this.authService.getIdCurrentCondominio(), this.localUtensilio.id)
      .subscribe(
        response => {
          this.inventarios = response;
        }
      );
  }

  /**
   * Busca todos utensílios do local
   * @autor Marcos Frare
   * @since 12/03/2020
   */
  private getAllUtensilio(): void {
    this.utensilios = [];
    this.localHttpService.getUtensiliosByLocal(this.local.id)
      .subscribe(
        response => {
          this.utensilios = response;
        }
      );
  }

  /**
   * Valida campos obrigatórios
   * @author Marcos Frare
   * @since 12/03/2020
   */
  private validateSave(): boolean {
    if (!this.localUtensilio.id_inventario) {
      this.messageService.warning('Selecione um ítem de ativo!', 'Atenção');
      return false;
    }
    if (!this.localUtensilio.tipo) {
      this.messageService.warning('Selecione um tipo!', 'Atenção');
      return false;
    }
    return true;
  }

  public limpar(): void {
    this.localUtensilio = new LocalUtensilio();
    this.buscaInventario();
  }

  /**
   * Adicionar um utensílio
   * @param entity - Utensílio
   * @author Marcos Frare
   * @since 12/03/2020
   */
  public adicionar(): void {
    if (this.validateSave()) {
      const btnAdd = jQuery('#btnAdicionar');
      btnAdd.button('loading');

      this.localUtensilio.id_local = this.local.id;

      this.localHttpService.saveUtensilio(this.localUtensilio)
        .subscribe(
          () => {
            this.localUtensilio = new LocalUtensilio();
            this.buscaInventario();
            this.messageService.success('', 'Utensílio salvo com sucesso!');
            btnAdd.button('reset');
            this.getAllUtensilio();

          },
          error => {
            this.messageService.error('Erro ao gravar utensílio do local!! Tente novamente.', 'Erro', error);
            btnAdd.button('reset');
          }
        );
    }
  }

  /**
   * Edição de um utensílio - Apenas carrega o objeto
   * @param entity - Utensílio
   * @author Marcos Frare
   * @since 12/03/2020
   */
  public edit(entity: LocalUtensilio): void {
    this.localUtensilio = entity;
    this.buscaInventario();
  }

  /**
   * Exclusão de um utensílio
   * @param entity - Utensílio
   * @author Marcos Frare
   * @since 12/03/2020
   */
  public delete(entity: LocalUtensilio): void {
    // Exlui o utensílio do local
    if (confirm('Deseja excluir a o utensílio?')) {
      this.localHttpService.deleteUtensilio(entity).subscribe(
        () => {
          this.messageService.success('', 'Utensílio excluído com sucesso!');
          this.buscaInventario();
          this.getAllUtensilio();
        },
        error => {
          if (error.status === 403) {
            this.messageService.warning('Não foi possível excluir o utensílio!', 'Atenção', error);
          } else {
            this.messageService.error('Erro ao excluir o utensílio!', 'Erro', error);
          }
        }
      );
    }
  }
}
