import { Injectable } from '@angular/core';
import { AuthService, ConfigService, Crud, CrudService, CustomHttp } from 'app/core';
import { ConfiguracaoNotificacaoAutomatica } from 'app/models';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracaoNotificacaoAutomaticaHttpService implements Crud<ConfiguracaoNotificacaoAutomatica> {

  resource = 'notificacao/configuracao';
  private baseUrl: string;

  constructor(private customHttp: CustomHttp,
      private crudService: CrudService,
      private configService: ConfigService,
      private authService: AuthService) {
    this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
  }

  get(id: number): Observable<ConfiguracaoNotificacaoAutomatica> {
    return this.crudService.get<ConfiguracaoNotificacaoAutomatica>(this.resource, id);
  }
  getAll(): Observable<ConfiguracaoNotificacaoAutomatica[]> {
    return this.crudService.getAll<ConfiguracaoNotificacaoAutomatica>(this.resource, {params: {id_condominio: this.authService.getIdCurrentCondominio()}});
  }
  post(entity: ConfiguracaoNotificacaoAutomatica): Observable<ConfiguracaoNotificacaoAutomatica> {
    return this.crudService.post<ConfiguracaoNotificacaoAutomatica>(this.resource, entity);
  }
  put(entity: ConfiguracaoNotificacaoAutomatica): Observable<ConfiguracaoNotificacaoAutomatica> {
    return this.crudService.put<ConfiguracaoNotificacaoAutomatica>(this.resource, entity);
  }
  delete(entity: ConfiguracaoNotificacaoAutomatica): Observable<string> {
    return this.customHttp.delete(`${this.baseUrl}/${entity.id}`).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
  }

  /**
   * Muda situação Ativo/inativo
   * @param entity 
   * @author Marcos Frare
   * @since 22/02/2021
   */
  public mudaSituacao(entity: ConfiguracaoNotificacaoAutomatica): Observable<any> {
    return this.customHttp.put(`${this.baseUrl}/muda-situacao/${entity.id}`, {}).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
  }

}