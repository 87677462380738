import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { MessageService } from 'app/core';
import { FabricanteVeiculo } from 'app/models';
import { FabricanteVeiculoHttpService } from '../service';

declare var jQuery: any;

@Component({
  selector: 'fabricante-veiculo-cadastro-modal',
  templateUrl: './fabricante-veiculo-cadastro-modal.component.html',
  styleUrls: ['./fabricante-veiculo-cadastro-modal.component.css']
})
export class FabricanteVeiculoCadastroModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @Input() id: number;
  @Output() onSave: EventEmitter<FabricanteVeiculo> = new EventEmitter();

  public entity: FabricanteVeiculo;

  constructor(private fabricanteVeiculoHttpService: FabricanteVeiculoHttpService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.entity = new FabricanteVeiculo();
    if (!this.idModal) {
      this.idModal = 'fabricanteVeiculoCadastroModal';
    }
  }

  public open() {
    this.entity = new FabricanteVeiculo();
    if (this.id) {
      this.fabricanteVeiculoHttpService.get(this.id).subscribe(
        response => {
          this.entity = response;
        },
        error => {
          this.messageService.error('Erro ao buscar dados do fabricante! Tente novamente', 'Erro', error);
        }
      );
    }
    jQuery(`#${this.idModal}`).modal('show');
  }
  public close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Salvar banco
   * @author Marcos Frare
   * @since 18/03/2021
   */
  public salvar(): void {
    let btn = jQuery('#btnSaveFabricante');
    btn.button('loading');
    const fn = this.entity.id ? this.fabricanteVeiculoHttpService.put(this.entity) : this.fabricanteVeiculoHttpService.post(this.entity);
    fn.subscribe(
      response => {
        btn.button('reset');
        this.messageService.success('Fabricante salvo com sucesso!');
        this.onSave.emit(response);
        this.close();
      },
      error => {
        btn.button('reset');
        this.messageService.error('Erro ao salvar fabricante! Tente novamente', 'Erro', error);
      }
    );
  }

}