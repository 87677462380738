import { Component, Input, Output, OnInit, EventEmitter} from '@angular/core';

import { AuthService, MessageService } from '../../../../core';
import { BaseModal } from '../../../../componentes-utils/modal';
import { Unidade } from '../../../../models';
import { UnidadesHttpService } from '../services/unidades-http.service';

declare var jQuery: any;

@Component({
    selector: 'unidades-pesquisa-modal',
    templateUrl: './unidades-pesquisa-modal.component.html',
    styleUrls: ['./unidades-pesquisa-modal.component.css']
})
export class UnidadesPesquisaModalComponent implements OnInit, BaseModal {
    @Input() idModal: string; // id do modal para identificar um modal caso em uma mesma tela possa ter mais de um modal
    @Input() unidades: Unidade[];
    @Output() select = new EventEmitter<Unidade>();

    constructor(private authService: AuthService,
                private messageService: MessageService,
                private unidadesHttpService: UnidadesHttpService) { }

    ngOnInit() {
        if (!this.idModal) {
            this.idModal = 'unidadesPesquisaModal';
        }
    }

    open() {
        jQuery(`#${this.idModal}`).modal('show');
    }

    close() {
        jQuery(`#${this.idModal}`).modal('hide');
    }

    /** Ao selecionar uma categoria de conta */
    selectClick(unidade: Unidade) {
        this.select.emit(unidade);
        this.close();
    }
}
