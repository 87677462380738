import { BaseEntity } from '../base-entity';
import { PrevisaoCategoriaConta } from './previsao-categoria-conta';

export class PrevisaoOrcamentaria extends BaseEntity {
	id_condominio: number;
	competencia_inicial: Date;
	quantidade_mes: number;
	situacao: number;
	valor_total: number;
	indice_calculo: number;
	referencia_calculo: string;
	observacao: string;

	contas: PrevisaoCategoriaConta[];

	constructor() {
		super();
		this.indice_calculo = 0;
		this.competencia_inicial = new Date();
		this.referencia_calculo = 'EXECUTADO';
		this.quantidade_mes = 12;
	}
}