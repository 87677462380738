import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { MessageService, AuthService } from 'app/core';
import { Anexo, Pagamento } from 'app/models';
import { PagamentosHttpService } from '../services';
import { AttachmentComponent } from 'app/shared';

declare var jQuery: any;

@Component({
  selector: 'pagamento-anexo-modal',
  templateUrl: './pagamento-anexo-modal.component.html',
  styleUrls: ['./pagamento-anexo-modal.component.css']
})
export class PagamentoAnexoModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @Input() idPagamento: number;

  @ViewChild('anexoComponent') anexoComponent: AttachmentComponent;

  public pagamento: Pagamento;

  constructor(private messageService: MessageService,
      private pagamentosHttpService: PagamentosHttpService) {
    this.pagamento = new Pagamento();
  }

  ngOnInit() {
  }

  /**
   * Carrega dados do pagamento e anexos
   * @author Marcos Frare
   * @since 28/07/2020
   */
  private load(): void {
    this.pagamento = new Pagamento();
    this.pagamentosHttpService.getPagamentoAnexos(this.idPagamento).subscribe(
      response => {
        this.pagamento = response;
        if (!this.pagamento.anexos.length) {
          this.addAnexos();
        }
      },
      error => {
        this.messageService.error('Erro ao buscar anexos do pagamento! Tente novamente', 'Erro', error);
      }
    );
  }

  /**
   * Persiste os anexos
   * @author Marcos Frare
   * @since 28/07/2020
   */
  public anexar(): void {
    let btnAnexar = jQuery('#btnAnexar');
    btnAnexar.button('loading');
    this.pagamentosHttpService.gravarAnexos(this.pagamento).subscribe(
      () => {
        btnAnexar.button('reset');
        this.messageService.success('Arquivos anexados com sucesso!');
        this.close();
      },
      error => {
        btnAnexar.button('reset');
        this.messageService.error('Erro ao anexar arquivos! Tente novamente.', 'Erro', error);
      }
    );
  }

  open() {
    this.load();
    jQuery(`#${this.idModal}`).modal('show');
  }

  close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  public addAnexos(): void {
    this.pagamento.anexos.push(new Anexo());
  }

  public removeAnexos(index): void {
    this.pagamento.anexos.splice(index, 1);
  }

}