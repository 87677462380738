import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';
import { Crud, CrudService, ConfigService, CustomHttp } from '../../../core';

import { Usuario, Telefone } from '../../../models';

@Injectable()
export class UsuariosHttpService implements Crud<Usuario> {
	resource = 'usuario';
	baseUrl: string;

	constructor(private crudService: CrudService,
		private configService: ConfigService,
		private customHttp: CustomHttp) {
		this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
	}

	get(id: number): Observable<Usuario> {
		return this.crudService.getNew<Usuario>(this.resource, id);
	}

	getAll(): Observable<Usuario[]> {
		return this.crudService.getAllNew<Usuario>(this.resource);
	}

	getAllByClienteCondominioAndTipo(filterParams: any): Observable<Usuario[]> {
		let params = {...filterParams};
		if (!params.id_condominio) delete params.id_condominio;
		return this.customHttp.get(`${this.baseUrl}`, { params }).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	post(entity: Usuario): Observable<Usuario> {
		return this.crudService.postNew<Usuario>(this.resource, entity);
	}

	put(entity: Usuario): Observable<Usuario> {
		return this.crudService.putNew<Usuario>(this.resource, entity);
	}

	delete(entity: Usuario): Observable<string> {
		return this.crudService.deleteNew<Usuario>(this.resource, entity);
	}

	deleteTelefone(entity: Telefone): Observable<string> {
		return this.crudService.delete<Telefone>('telefone', entity);
	}

	checkEmail(email: string): Observable<string> {
		return this.customHttp.get(`${this.baseUrl}/verificar/${email}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	checkSindico(idUsuario: number, idCondominio: number): Observable<any> {
		return this.customHttp.get(`${this.configService.getBaseUrl()}/conselhoscondominio/consultar/sindico/${idUsuario}/condominio/${idCondominio}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	sendAccessData(entity: Usuario): Observable<any> {
		return this.customHttp.put(`${this.baseUrl}/${entity.id}/enviaracesso`, entity).pipe(
			map(response => response),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Alteração da senha do usuário
	 * @param idUsuario 
	 * @param senhaAtual 
	 * @param senha 
	 * @returns 
	 * @author Marcos Frare
	 * @since 21/05/2021
	 */
	public resetPassword(idUsuario: number, senhaAtual: string, senha: string): Observable<any> {
		return this.customHttp.post(`${this.baseUrl}/reset-senha/${idUsuario}`, {senhaAtual, senha}).pipe(
			map(response => response),
			catchError(error => _throw(error))
		);
	}
}