import { map, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { Crud, CrudService, ConfigService, CustomHttp, AuthService } from '../../../core';
import { Local, LocalConfiguracao, LocalUtensilio, LocalPolitica, Reserva } from '../../../models';

@Injectable()
export class LocalHttpService implements Crud<Local> {
	public resource = 'locais';
	private baseUrl: string;
	private baseAcessoUrl: string;

	constructor(private customHttp: CustomHttp,
			private crudService: CrudService,
			private configService: ConfigService,
			private authService: AuthService) {
		this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
		this.baseAcessoUrl = `${configService.getBaseUrl()}/${this.resource}`;
	}

	public get(id: number): Observable<Local> {
		return this.crudService.get<Local>(this.resource, id);
	}

	public getAll(): Observable<Local[]> {
		return this.crudService.getAll<Local>(this.resource, { params: { id_condominio: this.authService.getIdCurrentCondominio() } });
	}

	public post(entity: Local): Observable<Local> {
		return this.crudService.post<Local>(this.resource, entity);
	}

	public put(entity: Local): Observable<Local> {
		return this.crudService.put<Local>(this.resource, entity);
	}

	public delete(entity: Local): Observable<string> {
		return this.crudService.delete<Local>(this.resource, entity);
	}

	public getAllByCliente(idCliente: number): Observable<Local[]> {
		return this.customHttp.get(`${this.baseUrl}/cliente/consultar/${idCliente}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public getAllByUsuario(idUsuario: number): Observable<Local[]> {
		return this.customHttp.get(`${this.baseAcessoUrl}/consultar/usuario/${idUsuario}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Busca os locais do condomínio
	 * @param id_condominio 
	 * @author Marcos Frare
	 * @since 16/03/2020
	 */
	public getAllByCondominio(id_condominio: number, ativo: boolean = false): Observable<Local[]> {
		return this.customHttp.get(`${this.baseUrl}/condominio/consultar/${id_condominio}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Buscar locais pelo filtro
	 * @param filtro 
	 * @returns 
	 * @author Marcos Frare
	 * @since 18/08/2022
	 */
	public getAllByFiltro(params: any): Observable<Local[]> {
		return this.customHttp.get(`${this.baseUrl}/consultar`, {params}).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Busca os dados do local para efetuar a reserva
	 * @param id_local ID do local
	 * @author Marcos Frare
	 * @since 20/03/2020
	 */
	public getDadosByReserva(res: Reserva, isAdmin: boolean): Observable<Local> {
		let params = {
			reserva: {
				id: res.id,
				data_reserva: res.data_reserva,
				isAdmin: isAdmin
			}
		}
		return this.customHttp.get(`${this.baseUrl}/reserva/${res.id_local}`, { params }).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	// Configuração
	/**
	 * Busca a configuração do local
	 * @param idLocal ID do local
	 * @author Marcos Frare
	 * @since 09/03/2020
	 */
	public getConfigByLocal(idLocal: number): Observable<LocalConfiguracao> {
		return this.customHttp.get(`${this.baseUrl}/configuracao/consultar/${idLocal}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Submete dados para persistencia
	 * @param entity Dados do objeto
	 * @author Marcos Frare
	 * @since 09/03/2020
	 */
	public saveConfig(dados: any): Observable<any> {
		return this.customHttp.post(`${this.baseUrl}/configuracao/cadastrar`, dados).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Busca os períodos configurados de um local
	 * @param idLocal Id do local
	 * @author Marcos Frare
	 * @since 11/03/2020
	 */
	public getDisponibilidade(idLocal: number): Observable<any> {
		return this.customHttp.get(`${this.baseUrl}/disponibilidade/consultar/${idLocal}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Busca diponibilidade de reserva
	 * @param id_local 
	 * @param data_reserva 
	 * @param id_reserva 
	 * @author Marcos Frare
	 * @since 18/03/2020
	 */
	public getDisponibilidadeReserva(id_local: number, data_reserva: string, id_reserva: number): Observable<any> {
		const params = { id_local, data_reserva, id_reserva };
		return this.customHttp.get(`${this.baseUrl}/disponibilidade/disponibilidade-reserva`, { params }).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Busca os utensílios de um local
	 * @param idLocal ID do local
	 * @author Marcos Frare
	 * @since 12/03/2020
	 */
	public getUtensiliosByLocal(id_local: number): Observable<LocalUtensilio[]> {
		return this.customHttp.get(`${this.baseUrl}/utensilio/consultar`, { params: { id_local } }).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Persistir os dados
	 * @param localUtensilio Dados do objeto a ser persistido
	 * @author Marcos Frare
	 * @since 12/03/2020
	 */
	public saveUtensilio(localUtensilio: LocalUtensilio): Observable<LocalUtensilio> {
		const method = localUtensilio.id ?
			this.customHttp.put(`${this.baseUrl}/utensilio/editar/${localUtensilio.id}`, localUtensilio) :
			this.customHttp.post(`${this.baseUrl}/utensilio/cadastrar`, localUtensilio);
		return method.pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Exclusão da entidade de Utensílio
	 * @param localUtensilio - Entidade
	 * @author Marcos Frare
	 * @since 12/03/2020
	 */
	public deleteUtensilio(localUtensilio: LocalUtensilio): Observable<string> {
		return this.customHttp.delete(`${this.baseUrl}/utensilio/excluir/${localUtensilio.id}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Busca as politicas do local
	 * @param idLocal ID do local
	 * @author Marcos Frare
	 * @since 09/03/2020
	 */
	public getPoliticaByLocal(idLocal: number): Observable<LocalPolitica> {
		return this.customHttp.get(`${this.baseUrl}/politica/consultar/${idLocal}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Submete dados para persistencia
	 * @param entity Dados do objeto
	 * @author Marcos Frare
	 * @since 09/03/2020
	 */
	public savePolitica(localPolitica: LocalPolitica): Observable<LocalPolitica> {
		// Verifica ser for edição
		const method = localPolitica.id ?
			this.customHttp.put(`${this.baseUrl}/politica/editar/${localPolitica.id}`, localPolitica) :
			this.customHttp.post(`${this.baseUrl}/politica/cadastrar`, localPolitica);

		return method.pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

}