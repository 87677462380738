import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { BaseModal } from '../../../componentes-utils/modal';
import { FornecedoresCadastroComponent } from '../cadastro/fornecedores-cadastro.component';
import { Fornecedor } from '../../../models';

declare var jQuery: any;

@Component({
	selector: 'fornecedores-cadastro-modal',
	templateUrl: './fornecedores-cadastro-modal.component.html'
})
export class FornecedoresCadastroModalComponent implements OnInit, BaseModal {

	@Input() idModal: string; // id do modal para identificar um modal caso em uma mesma tela possa ter mais de um modal
	@Output() afterSave = new EventEmitter<Fornecedor>();

	@ViewChild('cadastro') cadastroComponent: FornecedoresCadastroComponent;

	private btnSave: any;

	ngOnInit() {
		if (!this.idModal) {
			this.idModal = 'fornecedoresCadastroModal';
		}

		this.btnSave = jQuery('#btnSaveFornecedoresModal');
		this.cadastroComponent.cancel = () => { }; // Alterando método cancel do FornecedoresCadastroComponent para ao salvar não excutar esse método que faz retornar para a rota de visualização
	}

	private clear() {
		this.btnSave.button('reset');
		this.cadastroComponent.form.resetForm();
	}

	open() {
		this.clear();
		jQuery(`#${this.idModal}`).modal('show');
		setTimeout(() => {
			this.cadastroComponent.entity = new Fornecedor();
			this.cadastroComponent.editMode = false;
			jQuery('.modal-body').find('input:first').focus();
		}, 200);
	}

	close() {
		jQuery(`#${this.idModal}`).modal('hide');
	}

	save() {
		this.btnSave.button('loading');
		this.cadastroComponent.save()
			.then(entity => {
				this.afterSave.emit(entity);
				this.btnSave.button('reset');
				this.close();
			}).catch(() => {
				this.btnSave.button('reset');
			});
	}
}