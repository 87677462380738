import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { AuthService, MessageService } from 'app/core';
import { FormulariosHttpService } from 'app/home/formularios';
import { ConfiguracaoNotificacaoAutomatica, Formulario, tiposNotificacao } from 'app/models';
import { ConfiguracaoNotificacaoAutomaticaHttpService } from '../service';

declare var jQuery: any;

@Component({
  selector: 'configuracao-notificacao-automatica-cadastro-modal',
  templateUrl: './configuracao-notificacao-automatica-cadastro-modal.component.html',
  styleUrls: ['./configuracao-notificacao-automatica-cadastro-modal.component.css']
})
export class ConfiguracaoNotificacaoAutomaticaCadastroModalComponent implements OnInit, BaseModal {

  @Input() id?: number;
  @Input() idModal: string;
  @Input() configuracaoNotificacaoAutomatica: ConfiguracaoNotificacaoAutomatica;

  @Output() saveEmit = new EventEmitter();

  public formularios: Formulario[] = [];
  public tipos = tiposNotificacao;

  constructor(private authService: AuthService,
      private messageService: MessageService,
      private configuracaoNotificacaoAutomaticaHttpService: ConfiguracaoNotificacaoAutomaticaHttpService,
      private formularioHttpService: FormulariosHttpService) {
    this.configuracaoNotificacaoAutomatica = new ConfiguracaoNotificacaoAutomatica();
  }

  ngOnInit() {
    this.loadFormularios();
  }

  open() {
    this.loadEntity();
    jQuery(`#${this.idModal}`).modal('show');
  }
  close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Carrega os formulários do cliente
   * @author Marcos Frare
   * @since 22/02/2021
   */
  private loadFormularios(): void {
    this.formularioHttpService.getAllByCliente(this.authService.getIdCurrentCliente()).subscribe(
      response => {
        this.formularios = response;
      },
      error => {
        this.messageService.error('Erro ao buscar formulários!. Tente novamente.', 'Erro', error);
      }
    );
  }

  /**
   * Carrega a entidade a ser alterada
   * @author Marcos Frare
   * @since 19/02/2021
   */
  private loadEntity(): void {
    if (this.id) {
      this.configuracaoNotificacaoAutomaticaHttpService.get(this.id).subscribe(
        response => {
          this.configuracaoNotificacaoAutomatica = response;
        },
        error => {
          this.messageService.error('Erro ao buscar a configuração. Tente novamente', 'Erro', error);
        }
      );
    } else {
      this.configuracaoNotificacaoAutomatica = new ConfiguracaoNotificacaoAutomatica();
      this.configuracaoNotificacaoAutomatica.id_condominio = this.authService.getIdCurrentCondominio();
    }
  }

  /**
   * Salvar configuração
   * @author Marcos Frare
   * @since 22/02/2021
   */
  public salvar(): void {
    let btn = jQuery('#btnSalvar');
    btn.button('loading');
    // Verifica se é inclusão ou alteração
    let fn = this.configuracaoNotificacaoAutomatica.id ? this.configuracaoNotificacaoAutomaticaHttpService.put(this.configuracaoNotificacaoAutomatica) : this.configuracaoNotificacaoAutomaticaHttpService.post(this.configuracaoNotificacaoAutomatica);
    fn.subscribe(
      () => {
        btn.button('reset');
        this.messageService.success('Configuração salva com sucesso!', 'Sucesso!');
        this.saveEmit.emit();
        this.close();
      },
      error => {
        btn.button('reset');
        this.messageService.error('Erro ao salvar dados da configuração. Tente novamente!', 'Erro', error);
      }
    );
  }

}