import { Component, Input, OnInit } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { AuthService, MessageService } from 'app/core';
import { BancoHttpService } from 'app/home/banco';
import { ConfiguracaoCobranca, tiposProtestoPadrao } from 'app/models';
import { ContasBancariasHttpService } from '../services';

declare var jQuery: any;

@Component({
  selector: 'configuracao-cobranca-modal',
  templateUrl: './configuracao-cobranca-modal.component.html',
  styleUrls: ['./configuracao-cobranca-modal.component.css']
})
export class ConfiguracaoCobrancaModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @Input() idContaBancaria: number;
  @Input() idBanco: number;
  @Input() nomeContaBancaria: string;

  public configuracaoCobranca: ConfiguracaoCobranca;
  public tiposProtesto: any = [];

  constructor(private messageService: MessageService,
    private contasBancariasHttpService: ContasBancariasHttpService,
    private bancoHttpServive: BancoHttpService,
    private authService: AuthService) { }

  ngOnInit() {
    this.configuracaoCobranca = new ConfiguracaoCobranca();
  }

  /**
   * Carrega os tipos de protestos
   * @author Marcos Frare
   * @since 21/07/2022
   */
  private getTipoProtesto(): void {
    this.tiposProtesto = [];
    if (this.idBanco) {
      this.bancoHttpServive.getTipoProtesto(this.idBanco).subscribe(
        response => {
          this.tiposProtesto = response;
        },
        error => {
          this.messageService.error('Erro ao buscar tipos de protesto do banco!', 'Erro', error);
          this.tiposProtesto = [];
        }
      );
    } else {
      this.tiposProtesto = tiposProtestoPadrao;
    }
  }

  /**
   * Abre modal com dados
   * @author Marcos Frare
   * @since 19/07/2022
   */
   open() {
    this.getTipoProtesto();
    this.contasBancariasHttpService.getConfiguracaoCobranca(this.idContaBancaria).subscribe(
      response => {
        this.configuracaoCobranca = response;
        jQuery(`#${this.idModal}`).modal('show');
      },
      error => {
        this.messageService.error('Erro ao buscar configurações de cobrança!', 'Erro', error);
      }
    )
  }

  close() {
    this.configuracaoCobranca = new ConfiguracaoCobranca();
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Salvar configurações de cobrança
   * @author Marcos Frare
   * @since 19/07/2022
   */
  public salvar(): void {
    let btnSave = jQuery('#btnSalvarConfiguracaoCobranca');
    btnSave.button('loading');
    this.contasBancariasHttpService.saveConfiguracaoCobranca(this.configuracaoCobranca).subscribe(
      () => {
        btnSave.button('reset');
        this.messageService.info('Configurações gravadas com sucesso!')
        this.close();
      },
      error => {
        btnSave.button('reset');
        this.messageService.error('Problemas ao gravar configurações. Tente novamente.', 'Erro', error);
      }
    );
  }

}
