import { BaseEntity } from '../base-entity';

export class ConfiguracaoLayoutTitulo extends BaseEntity {
	id_cliente: number;
	id_condominio: number;
	id_usuario: number;
	tipo_prestacao_conta: number;
	correspondencia: boolean;
	recibo_pagador: boolean;
	totalizador_demonstrativo: boolean;
	fundo_reserva: boolean;
	chamada_capital: boolean;
	frente_verso: boolean;
	email_administradora: boolean;
	rateio_mes_anterior: boolean;
	meses_competencia_anterior: number;
	mensagem_titulo: string;
	imprimir_complemento: boolean;
	tipo_morador: number;
	email_adicional_unidade: boolean;
	email_adicional_condomino: boolean;

	tipo_cobranca: string;
	tipo_cobranca_outro: string;

	idsTitulo: number[];

	constructor() {
		super();
		this.meses_competencia_anterior = 0;
		this.idsTitulo = [];
		this.tipo_prestacao_conta = 0;
		this.imprimir_complemento = false;
		this.tipo_morador = 0;
		this.email_adicional_unidade = false;
		this.email_adicional_condomino = false;
		this.tipo_cobranca = 'TAXACONDOMINIO';
		this.tipo_cobranca_outro = '';
	}
}