import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService, MessageService } from 'app/core';
import { TemplateLancamentoEspecifico } from 'app/models';
import { datepickerMesAnoOptions } from 'app/shared';
import { TemplateLancamentoEspecificoHttpService } from '../service';

declare var jQuery: any;

@Component({
  selector: 'geracao-lancamento-especifico',
  templateUrl: './geracao-lancamento-especifico.component.html',
  styleUrls: ['./geracao-lancamento-especifico.component.css']
})
export class GeracaoLancamentoEspecificoComponent implements OnInit {

  @Input() idModal: string;
  @Output() onSave: EventEmitter<any> = new EventEmitter(); 

  public id_condominio: number;
  public datepickerDataCompetenciaOptions = datepickerMesAnoOptions;
  public competencia: Date;
  public templates: TemplateLancamentoEspecifico[] = [];
  public valorTotal: number = 0;

  constructor(private messageService: MessageService,
    private authService: AuthService,
    private templateLancamentoEspecificoHttpService: TemplateLancamentoEspecificoHttpService) { }

  ngOnInit() {
    this.id_condominio = this.authService.getIdCurrentCondominio();
    if (this.idModal == '') {
      this.idModal = 'geracaoLancamentoEspecifico';
    }
    const today = new Date();
    this.competencia = new Date(today.getFullYear(), today.getMonth(), 1);
    this.templates = [];
  }

  open() {
    this.loadTemplate();
    jQuery(`#${this.idModal}`).modal('show');
  }
  
  close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Calcular total dos lançamentos
   * @author Marcos Frare
   * @since 10/03/2021
   */
   public calculaTotal(): void {
    this.valorTotal = 0;
    this.templates.map(temp => {
      this.valorTotal += Number(temp.valor);
    });
  }

  /**
   * Carrega os templates conforme competência selecionada
   * @author Marcos Frare
   * @since 10/03/2021
   */
  public loadTemplate(): void {
    this.templates = [];
    this.templateLancamentoEspecificoHttpService.loadTemplatesCompetencia(this.id_condominio, this.competencia).subscribe(
      response => {
        this.templates = response;
        this.calculaTotal();
      },
      error => {
        this.messageService.error('Erro ao buscar configuração', 'Erro', error);
      }
    );
  }

  /**
   * Evento de alteração de da data de competência
   * @author Marcos Frare
   * @since 10/03/2021
   */
  public changeCompetencia(): void {
    this.loadTemplate();
  }

  /**
   * Faz exclusão de uma template
   * @param template 
   * @author Marcos Frare
   * @since 10/03/2021
   */
  public delete(template: TemplateLancamentoEspecifico): void {
    this.templates = this.templates.filter(item => item.id !== template.id);
    this.calculaTotal();
  }

  /**
   * Salvar os lançamentos
   * @author Marcos Frare
   * @since 10/03/2021
   */
  public salvar(): void {
    let btnSalvar = jQuery('#btnSalvarLancamento');
    btnSalvar.button('loading');
    this.templateLancamentoEspecificoHttpService.salvarLancamentosTemplate(this.id_condominio, this.templates).subscribe(
      () => {
        btnSalvar.button('reset');
        this.messageService.success('Lançamentos gerados com sucesso!');
        this.onSave.emit();
        this.close();
      },
      error => {
        btnSalvar.button('reset');
        this.messageService.error('Erro ao efetuar lançamentos! Tente novamente', 'Erro', error);
      }
    );
  }

}