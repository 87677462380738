import { NgModule } from '@angular/core';
import { CobrancaAvulsaComponent } from './cobranca-avulsa.component';
import { SharedModule } from 'app/shared';
import { BlocosHttpService } from '../blocos';
import { TypeaheadModule } from 'ngx-bootstrap';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { CobrancaAvulsaCadastroComponent, CobrancaAvulsaFaturaModalComponent } from './cobranca-avulsa-cadastro';
import { PlanoDeContasModule } from '../plano-de-contas';
import { CobrancaAvulsaEdicaoModalComponent } from './cobranca-avulsa-edicao-modal';
import { AntecipacaoModalComponent } from './antecipacao-modal';
import { LancamentosUnidadesModule } from '../lancamentos-unidades';

@NgModule({
  imports: [
    SharedModule,
    TypeaheadModule,
    MultiselectDropdownModule,
    PlanoDeContasModule,
    LancamentosUnidadesModule
  ],
  declarations: [
    CobrancaAvulsaComponent,
    CobrancaAvulsaCadastroComponent,
    CobrancaAvulsaFaturaModalComponent,
    CobrancaAvulsaEdicaoModalComponent,
    AntecipacaoModalComponent
  ],
  providers: [
    BlocosHttpService
  ]
})
export class CobrancaAvulsaModule { }