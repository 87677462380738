import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import { AuthService, MessageService } from 'app/core';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { BlocosHttpService, BlocosPesquisaModalComponent, UnidadesHttpService } from 'app/home/blocos';
import { FornecedoresCadastroModalComponent, FornecedoresHttpService } from 'app/home/fornecedores';
import { CategoriaContasHttpService, CategoriaContasPesquisaModalComponent } from 'app/home/plano-de-contas';
import { Bloco, CategoriaContas, Fornecedor, Unidade } from 'app/models';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { GeracaoCobrancaAvulsa } from './geracao-cobranca-avulsa';

declare var jQuery: any;

@Component({
  selector: 'cobranca-avulsa-fatura-modal',
  templateUrl: './cobranca-avulsa-fatura-modal.component.html',
  styleUrls: ['./cobranca-avulsa-fatura-modal.component.css']
})
export class CobrancaAvulsaFaturaModalComponent implements OnInit {

  @Input() idModal: string;

  @ViewChild('inputBloco') inputBloco: ElementRef;
	@ViewChild('blocosPesquisaModal1') blocosPesquisaModal1: BlocosPesquisaModalComponent;
  @ViewChild('categoriaContasPesquisaModal') categoriaContasPesquisaModal: CategoriaContasPesquisaModalComponent;
	@ViewChild('fornecedoresCadastroModal') fornecedoresCadastroModal: FornecedoresCadastroModalComponent;

  @Output() onGenerate: EventEmitter<GeracaoCobrancaAvulsa> = new EventEmitter();

  public unidades: Unidade[] = [];
  public blocos: Bloco[] = [];
  public bloco: Bloco;
  public geracaoCobranca: GeracaoCobrancaAvulsa = new GeracaoCobrancaAvulsa();
  public fornecedores: Fornecedor[] = [];
	public categoriasContas: CategoriaContas[] = [];

  public multiselectSettings: IMultiSelectSettings = {
		enableSearch: true,
		containerClasses: 'container-multiselect',
		showUncheckAll: true,
		showCheckAll: true,
		dynamicTitleMaxItems: 3,
		checkedStyle: 'checkboxes',
		buttonClasses: 'btn btn-default',
		itemClasses: 'dropdown-menu'
	};
	public multiselectOptionsUnidades: IMultiSelectOption[];
	public multiselectTextsUnidades: IMultiSelectTexts = {
		checkAll: 'Selecionar todas',
		uncheckAll: 'Limpar',
		checked: 'selecionada',
		checkedPlural: 'selecionadas',
		searchPlaceholder: 'Pesquisar...',
		defaultTitle: 'Selecionar',
		allSelected: 'Todas selecionadas',
		searchEmptyResult: 'Nenhum registro encontrado',
		searchNoRenderText: 'Digite para pesquisar',
	};

  constructor(public activatedRoute: ActivatedRoute,
    public router: Router,
    public messageService: MessageService,
    private authService: AuthService,
    private unidadesHttpService: UnidadesHttpService,
    public permissionService: PermissionService,
    public blocosHttpService: BlocosHttpService,
		public categoriaContasHttpService: CategoriaContasHttpService,
    private fornecedoresHttpService: FornecedoresHttpService) { }

  ngOnInit() {
    this.geracaoCobranca.ids_unidades = [];
		this.getAllCategoriasByCondominio();
    this.getUnidades();
    this.getBlocosByCondominio();
    this.getAllFornecedores();
  }

  private getAllFornecedores(): void {
		this.fornecedoresHttpService.getAllByCliente(this.authService.getIdCurrentCliente()).subscribe(entities => this.fornecedores = entities,
			error => this.messageService.error('Erro ao carregar os fornecedores', 'Erro', error)
		);
	}

	/**
	 * Busca as categorias de contas do condomínio
	 * @author Marcos Frare
	 * @since 27/07/2021
	 */
	private getAllCategoriasByCondominio(): void {
		this.categoriasContas = [];
		this.categoriaContasHttpService.getAllByCondominio(this.authService.getIdCurrentCondominio(), 1).then(entities => this.categoriasContas = entities);
	}

  public open(): void {
    this.geracaoCobranca = new GeracaoCobrancaAvulsa();
    jQuery(`#${this.idModal}`).modal('show');
  }

  public close(): void {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Busca as unidades do condomínio
   * @author Marcos Frare
   * @since 26/07/2021
   */
   private getUnidades(): void {
		this.unidades = [];
		this.multiselectOptionsUnidades = [];
		this.unidadesHttpService.getAllByCondominioBloco(this.authService.getIdCurrentCondominio(), null).subscribe(
			entities => {
        this.unidades = entities;
        this.loadUnidades();
			},
			error => this.messageService.error('Erro ao carregar as unidades do condomínio. Tente novamente', 'Erro', error)
		);
	}

  /**
   * Carregar a unidade conforme bloco selecionado
   * @author Marcos Frare
   * @since 26/07/2021
   */
   private loadUnidades(): void {
    this.multiselectOptionsUnidades = [];
    if (this.geracaoCobranca.id_bloco) {
      this.geracaoCobranca.ids_unidades = [];
      this.multiselectOptionsUnidades = <IMultiSelectOption[]>this.unidades.filter(un => un.id_bloco == this.geracaoCobranca.id_bloco).map(und => {
        return <IMultiSelectOption>{id: und.id, name: `${und.nome_bloco} - ${und.nome_unidade}`}
      });
    } else {
      this.multiselectOptionsUnidades = <IMultiSelectOption[]>this.unidades.map(und => {
        return <IMultiSelectOption>{id: und.id, name: `${und.nome_bloco} - ${und.nome_unidade}`}
      });
    }
  }

  /**
   * Todos os blocos do condomínio
   * @author Marcos Frare
   * @since 26/07/2021
   */
   private getBlocosByCondominio() {
		this.blocosHttpService.getAllByCondominio(this.authService.getIdCurrentCondominio()).subscribe(
			entitites => {
				this.blocos = entitites;
			},
			error => this.messageService.error('Erro ao carregar as unidades do condomínio. Tente novamente', 'Erro', error)
		);
	}

	private setBloco(blocoSelecionado: Bloco) {
		this.geracaoCobranca.bloco = blocoSelecionado;
		this.geracaoCobranca.id_bloco = blocoSelecionado.id;
		this.loadUnidades();
	}

	public onSelectBloco(tm: TypeaheadMatch): void {
		this.setBloco(tm.item as Bloco);
	}

  public openBlocosPequisaModal() {
		this.blocosPesquisaModal1.open();
	}

  public onSelectBlocosPesquisaModal(bloco: Bloco) {
		if (bloco) {
			this.setBloco(bloco);
		}
		jQuery('#bloco').focus();
	}

	public onCleanBloco(input: any) {
		this.geracaoCobranca.bloco = null;
		this.geracaoCobranca.id_bloco = null;
		this.loadUnidades();
		input.value = '';
		input.focus();
	}

  public openCategoriaContasPequisaModal(): void {
		this.categoriaContasPesquisaModal.open();
	}

  public onSelectCategoriaConta(tm: TypeaheadMatch): void {
		const categoriaContas: CategoriaContas = tm.item as CategoriaContas;
		if (categoriaContas) {
			this.geracaoCobranca.id_categoria_conta = categoriaContas.id;
			this.geracaoCobranca.categoriaConta = categoriaContas;
			this.geracaoCobranca.id_fornecedor = categoriaContas.id_fornecedor;
		} else {
			this.geracaoCobranca.id_categoria_conta = null;
			this.geracaoCobranca.categoriaConta = null;
		}
	}

	public onSelectCategoriaContasPesquisaModal(categoriaContas: CategoriaContas): void {
		if (categoriaContas) {
			this.geracaoCobranca.id_categoria_conta = categoriaContas.id;
			this.geracaoCobranca.categoriaConta = categoriaContas;
			this.geracaoCobranca.id_fornecedor = categoriaContas.id_fornecedor;
		}
		jQuery('#categoriaContas').focus();
	}

  /**
   * Evento de click no botão para gerar lançamentos
   * @author Marcos Frare
   * @since 26/07/2021
   */
  public onGerarClick(): void {
    this.onGenerate.emit(this.geracaoCobranca);
    this.close();
  }

	/**
	 * Limpar categoria de contas
	 * @author Marcos Frare
	 * @since 16/08/2021
	 */
	public onCleanCategoriaContas(): void {
		this.geracaoCobranca.categoriaConta = null;
		this.geracaoCobranca.id_categoria_conta = null;
	}

}