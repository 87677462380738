import { Component, OnInit, Input } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { ConfiguracaoLivro, ConfiguracaoLivroItem, Filtro, Formulario, Relatorio, tiposItemConfiguracaoLivro } from 'app/models';
import { MessageService } from 'app/core';
import { LivroConfiguracaoHttpService } from '../service';
import { FormulariosHttpService } from 'app/home/formularios';
import { RelatorioHttpService } from 'app/home/relatorio';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';

declare var jQuery: any;

@Component({
  selector: 'livro-configuracao-item-cadastro-modal',
  templateUrl: './livro-configuracao-item-cadastro-modal.component.html',
  styleUrls: ['./livro-configuracao-item-cadastro-modal.component.css']
})
export class LivroConfiguracaoItemCadastroModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @Input() configuracaoLivro: ConfiguracaoLivro;
  @Input() idConfiguracaoLivroItem: number;
  @Input() arSequencia: number[];

  public configuracaoLivroItem: ConfiguracaoLivroItem;
  public formularios: Formulario[];
  public relatorios: Relatorio[];
  public tiposItem = tiposItemConfiguracaoLivro;

  multiselectSettings: IMultiSelectSettings = {
		enableSearch: true,
		containerClasses: 'container-multiselect',
		showUncheckAll: true,
		showCheckAll: true,
		dynamicTitleMaxItems: 3,
		checkedStyle: 'checkboxes',
		buttonClasses: 'btn btn-default',
		itemClasses: 'dropdown-menu'
	};

	multiselectTextsUnidades: IMultiSelectTexts = {
		checkAll: 'Selecionar todas',
		uncheckAll: 'Limpar',
		checked: 'selecionada',
		checkedPlural: 'selecionadas',
		searchPlaceholder: 'Pesquisar...',
		defaultTitle: 'Todos',
		allSelected: 'Todas selecionadas',
		searchEmptyResult: 'Nenhum registro encontrado',
		searchNoRenderText: 'Digite para pesquisar',
	};

  constructor(private messageService: MessageService,
    private livroConfiguracaoHttpService: LivroConfiguracaoHttpService,
    private formularioHttpService: FormulariosHttpService,
    private relatorioHttpService: RelatorioHttpService) { }
  
  ngOnInit() {
    this.arSequencia = [1];
    this.loadFormulario();
    this.loadRelatorio();
    this.configuracaoLivroItem = new ConfiguracaoLivroItem();
    if (!this.idModal) {
      this.idModal = 'livroConfiguracaoItemCadastroModal';
    }
  }

  open() {
    if (this.idConfiguracaoLivroItem) {
      this.livroConfiguracaoHttpService.getItemById(this.idConfiguracaoLivroItem).subscribe(
        response => {
          response.filtros.map(res => {
            if (res.tipo == 'MULTISELECAO') {
              let selects: IMultiSelectOption[] = [];
              res.dados.map(dd => {
                selects.push({id: dd.id, name: dd.name});
              });
              res.dados = selects;
            }
          });
          this.configuracaoLivroItem = response;
        },
        error => this.messageService.error('Erro ao buscar dados do registro', 'Erro', error)
      );
    }
    jQuery(`#${this.idModal}`).modal('show');
  }

  close() {
    this.configuracaoLivroItem = new ConfiguracaoLivroItem();
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Carregar os formulários do cliente
   * @author Marcos Frare
   * @since 22/07/2020
   */
  private loadFormulario(): void {
    this.formularioHttpService.getAllByCliente(this.configuracaoLivro.id_cliente).subscribe(
      response => this.formularios = response,
      error => this.messageService.error('Erro ao buscar formulários do cliente', 'Erro', error)
    );
  }

  /**
   * Carregar todos os relatorios do sistema
   * @author Marcos Frare
   * @since 22/07/2020
   */
  private loadRelatorio(): void {
    this.relatorios = [];
    this.relatorioHttpService.getAll().subscribe(
      response => this.relatorios = response,
      error => this.messageService.error('Erro ao buscar os relatorios', 'Erro', error)
    );
  }

  /**
   * Evento de alteração do item para carregar filtros
   * @author Marcos Frare
   * @since 09/12/2020
   */
  public changeRelatorio(): void {
    if (this.configuracaoLivroItem.tipo == 'RELATORIO' && this.configuracaoLivroItem.id_relatorio) {
      this.livroConfiguracaoHttpService.getFiltros(this.configuracaoLivroItem.id_relatorio, this.configuracaoLivroItem.id).subscribe(
        response => {
          response.map(res => {
            if (res.tipo == 'MULTISELECAO') {
              let selects: IMultiSelectOption[] = [];
              res.dados.map(dd => {
                selects.push({id: dd.id, name: dd.name});
              })
              res.dados = selects;
            }
          });
          this.configuracaoLivroItem.filtros = response;
        },
        error => {
          this.messageService.error('Erro ao buscar filtros do relatório!', 'Erro', error);
        }
      );
    } else {
      this.configuracaoLivroItem.filtros = [];
      this.configuracaoLivroItem.id_relatorio = null;
    }
  }

  /**
   * Salvar dados
   * @autor Marcos Frare
   * @since 21/07/2020
   */
  public save(): void {
    // Remove os dados de opções do filtro para não carregar o request
    if (this.configuracaoLivroItem.filtros) {
      this.configuracaoLivroItem.filtros.map(fil => {
        fil.dados = null;
        fil.node = null;
        return fil;
      });
    }
    let btnSave = jQuery('#btnSalvarModal');
    let btnLoad = jQuery('#btnLoadItem');
    btnSave.button('loading');
    this.configuracaoLivroItem.id_configuracao_livro = this.configuracaoLivro.id;
    this.livroConfiguracaoHttpService.saveItem(this.configuracaoLivroItem).subscribe(
      () => {
        btnSave.button('reset');
        this.messageService.success('Dados gravados com sucesso!');
        this.close();
        btnLoad.click();
      },
      error => {
        // Busca os dados de filtros
        this.changeRelatorio();
        btnSave.button('reset');
        this.messageService.error('Erro ao gravar os dados. Tente novamente.', 'Erro', error);
      }
    );
  }

  /**
   * Seleciona o valor do filtro para seleção em árvore
   * @param filtro 
   * @param node 
   * @author Marcos Frare
   * @since 15/04/2021
   */
  public onSelectNode(filtro: Filtro, node: any): void {
    filtro.node = node;
    filtro.valor = node.id;
  }

  /**
   * Limpa o valor selecionado no filtro
   * @param filtro 
   * @author Marcos Frare
   * @since 15/04/2021
   */
  public onCleanNode(filtro: Filtro): void {
    filtro.node = null;
    filtro.valor = null;
  }

}