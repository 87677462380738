import { BaseEntity } from '../base-entity';
import { ContaBancaria } from './conta-bancaria';

export class AutorizacaoContaBancaria extends BaseEntity {
	id_conta_bancaria: number;
	state: string;
	code: string;
	url_autorizacao: string;

	conta_bancaria: ContaBancaria;

	constructor() {
		super();
		this.id_conta_bancaria = null;
		this.state = '';
		this.code = '';
		this.url_autorizacao = '';
		this.conta_bancaria = new ContaBancaria();
	}
}