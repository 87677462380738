import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import { AuthService, MessageService } from 'app/core';
import { BlocosHttpService, BlocosPesquisaModalComponent, UnidadesHttpService } from 'app/home/blocos';
import { Bloco, Unidade } from 'app/models';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { RelatorioModalComponent } from '../relatorio-modal';
import { EnvioEmailRelatorioFiltro } from './envio-email-relatorio-filtro';

declare var jQuery: any;

@Component({
  selector: 'envio-email-relatorio',
  templateUrl: './envio-email-relatorio.component.html',
  styleUrls: ['./envio-email-relatorio.component.css']
})
export class EnvioEmailRelatorioComponent implements OnInit {

  @ViewChild('inputBloco') inputBloco: ElementRef;
	@ViewChild('blocosPesquisaModal') blocosPesquisaModal: BlocosPesquisaModalComponent;
	@ViewChild('relatorioModal') relatorioModal: RelatorioModalComponent;

  public showInputBlocos: boolean = true;
  public blocos: Bloco[] = [];
  public bloco: Bloco;
  public unidades: Unidade[] = [];
  public allUnidades: Unidade[] = [];
  public filtro: EnvioEmailRelatorioFiltro = new EnvioEmailRelatorioFiltro();

  multiselectSettings: IMultiSelectSettings = {
		enableSearch: true,
		containerClasses: 'container-multiselect',
		showUncheckAll: true,
		showCheckAll: true,
		dynamicTitleMaxItems: 3,
		checkedStyle: 'checkboxes',
		buttonClasses: 'btn btn-default',
		itemClasses: 'dropdown-menu'
	};

	multiselectOptionsUnidades: IMultiSelectOption[];
	multiselectTextsUnidades: IMultiSelectTexts = {
		checkAll: 'Selecionar todas',
		uncheckAll: 'Limpar',
		checked: 'selecionada',
		checkedPlural: 'selecionadas',
		searchPlaceholder: 'Pesquisar...',
		defaultTitle: 'Selecionar',
		allSelected: 'Todas selecionadas',
		searchEmptyResult: 'Nenhum registro encontrado',
		searchNoRenderText: 'Digite para pesquisar',
	};

  constructor(private authService: AuthService,
		private messageService: MessageService,
		private unidadesHttpService: UnidadesHttpService,
		private blocosHttpService: BlocosHttpService) { }

  ngOnInit() {
    this.filtro.limpar();
    this.filtro.id_cliente = this.authService.getIdCurrentCliente();
    this.filtro.id_condominio = this.authService.getIdCurrentCondominio();
    this.filtro.id_usuario = this.authService.getIdCurrentUser();
    this.getBlocosByCondominio(this.filtro.id_condominio);
    this.getAllUnidadesCondominio();
  }

  /**
	 * Busca todas as unidades do condomínio
	 * @author Marcos Frare
	 * @since 22/03/2023
	 */
	private getAllUnidadesCondominio(): void {
		this.allUnidades = [];
		this.unidadesHttpService.getAllByCondominioBloco(this.filtro.id_condominio, null).subscribe(
			response => {
				this.allUnidades = response;
				this.getUnidadesByBloco(null);
			},
			error => {
				this.messageService.error('Erro ao buscar unidades do condomínio! Tente novamente.', 'Erro', error);
			}
		);
	}

  /**
	 * Filtra unidades conforme bloco selecionado
	 * @param idBloco 
	 */
	private getUnidadesByBloco(idBloco: number) {
		this.filtro.unidades = [];
		this.multiselectOptionsUnidades = [];
		if (idBloco) {
			this.allUnidades.filter(unid => unid.id_bloco == idBloco).map(un => {
				this.multiselectOptionsUnidades.push({id: un.id, name: `${un.nome_bloco}-${un.nome_unidade}`});
			});
		} else {
			this.allUnidades.map(un => {
				this.multiselectOptionsUnidades.push({id: un.id, name: `${un.nome_bloco}-${un.nome_unidade}`});
			});
		}
	}

  private blocoUnico(blocos: Bloco[]): boolean {
		return blocos.length === 1;
	}

  private getBlocosByCondominio(idCondominio: number) {
		this.blocosHttpService.getAllByCondominio(idCondominio).subscribe(
      entitites => {
        this.blocos = entitites
        if (this.blocoUnico(this.blocos)) {
          this.setBloco(this.blocos[0]);
          this.showInputBlocos = false;
        } else if (!this.blocos) {
          this.messageService.error('Não existem blocos cadastrados no Condomínio. Cadastre  um bloco e tente novamente', 'Erro')
        }
      },
      error => this.messageService.error('Erro ao carregar as unidades do condomínio. Tente novamente', 'Erro', error)
    );
	}

	private setBloco(blocoSelecionado: Bloco) {
		this.bloco = blocoSelecionado;
		this.getUnidadesByBloco(this.bloco.id);
		this.onCleanUnidades();
		this.filtro.id_bloco = this.bloco.id;
	}

  onCleanUnidades() {
		this.filtro.unidades = [];
		this.filtro.id_bloco = null;
	}

	onSelectBloco(tm: TypeaheadMatch): void {
		this.setBloco(tm.item as Bloco);
	}

	openBlocosPequisaModal() {
		this.blocosPesquisaModal.open();
	}

	onSelectBlocosPesquisaModal(bloco: Bloco) {
		if (bloco) {
			this.setBloco(bloco)
		}
		jQuery('#bloco').focus();
	}

	onCleanBloco(input: any) {
		this.getUnidadesByBloco(null);
		this.bloco = null;
		input.value = '';
		input.focus();
		this.onCleanUnidades();
	}

	public view(): void {
		this.relatorioModal.url = '/relatorio/envio-email';
		this.relatorioModal.params = this.filtro;
		this.relatorioModal.open();
	}

	public limpar(): void {
		this.filtro.limpar();
	}

}
