import { ContasBancariasHttpService } from './../../services/contas-bancarias-http.service';
import { MessageService } from './../../../../core/services/message/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, ViewChild } from '@angular/core';
import { BaseCadastroComponent } from 'app/componentes-base';
import { Transferencia } from 'app/models';
import { AuthService, ConfigService } from 'app/core';
import { ExtratosHttpService } from 'app/home/contas-bancarias/extratos/services';

import { ContaBancaria } from './../../../../models/entidades/conta-bancaria';
import { CategoriaContas } from './../../../../models/entidades/categoria-contas';
import { CategoriaContasPesquisaModalComponent } from '../../../plano-de-contas/categoria-contas';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { CategoriaContasHttpService } from '../../../plano-de-contas/categoria-contas';
import { PermissionService } from '../../../../core/services/permission/permission.service';
import { FloatFormatPipe } from '../../../../shared/pipes';
import { PlanoDeContasCadastroModalComponent } from '../../../plano-de-contas/cadastro-modal/plano-de-contas-cadastro-modal.component';

declare var jQuery: any;

@Component({
  selector: 'cadastro',
  templateUrl: './transferencias-cadastro.component.html',
  styleUrls: ['./transferencias-cadastro.component.css'],
  providers: [PermissionService, FloatFormatPipe]
})
export class TransferenciasCadastroComponent extends BaseCadastroComponent<any> {

    @Input('isShowOptions') isShowOptions = true;

    public idContaBancaria: number;

    @ViewChild('categoriaContasPesquisaModal') categoriaContasPesquisaModal: CategoriaContasPesquisaModalComponent;
    @ViewChild('planoDeContasCadastroModal') planoDeContasCadastroModal: PlanoDeContasCadastroModalComponent;
    public categoriasContas: CategoriaContas[] = [];
    public contas: ContaBancaria[];

    constructor(activatedRoute: ActivatedRoute,
            router: Router,
            public route: ActivatedRoute,
            messageService: MessageService,
            private authService: AuthService,
            private categoriaContasHttpService: CategoriaContasHttpService,
            private extratosHttpService: ExtratosHttpService,
            private contasBancariasHttpService: ContasBancariasHttpService,
            public configService: ConfigService, 
            permissionService: PermissionService,
            private floatFormatPipe: FloatFormatPipe) {
        super(Transferencia, extratosHttpService, activatedRoute, router, messageService, permissionService);
        this.route.params.subscribe(params => {
            if (!this.editMode){
                this.idContaBancaria = parseInt(params['idConta']);
            }
        });
    }

    ngOnInit() {
        this.getAllCategoriasByCondominio(this.authService.getIdCurrentCondominio());
        this.getContas();
        super.ngOnInit();
    }

    onSaveClick() {
        let entity: any = {...this.entity};
        this.customSave(entity);
    }

    customSave(entity: any) {
        this.extratosHttpService.transferir(entity).subscribe(
            response => {
                this.cancel();
                this.messageService.success('Transferência efetuada com sucesso', 'Sucesso');
            },
            error => this.messageService.error('Erro ao realizar a transferência, favor tentar novamente.', 'Erro')
        );
    }
                
    /** Carrega as categorias de contas do plano de contas cadastrado na config. do condomínio */
    private getAllCategoriasByCondominio(idCondominio: number) {
        this.categoriasContas = [];
        if (idCondominio) {
            this.categoriaContasHttpService.getAllByCondominio(idCondominio)
                .then(entities => this.categoriasContas = entities)
                .catch(() => this.messageService.info(`Configuração do condomínio não encontrada. Configure o condomínio para prosseguir.`, 'Informação'));
        }
    }

    /** Atribui a categoria de conta (Objeto que representa o id da categoria) para o lançamento */
    private setCategoriaContas() {
        if (!this.entity.id_categoria_conta) {
            return;
        }
        this.categoriaContasHttpService.get(this.entity.id_categoria_conta)
            .subscribe(
                entity => {
                    this.entity.categoriaContas = entity
                },
                error => this.messageService.error('Erro ao carregar a categoria de contas', 'Erro', error)
            );
    }

    public openPlanoDeContasCadastroModal(): void {
        this.planoDeContasCadastroModal.open();
    }
    
    public onAfterSaveContaCategoriaConta(categoriaConta: CategoriaContas): void {
        this.entity.categoriaContas = categoriaConta;
        this.entity.id_categoria_conta = categoriaConta.id;
    }

    // Ao selecionar a categoria de contas
    onSelectCategoriaContas(tm: TypeaheadMatch) {
        const categoriaContas: CategoriaContas = tm.item as CategoriaContas;
        if (categoriaContas) {
            this.entity.id_categoria_conta = categoriaContas.id;
            this.entity.categoriaContas = categoriaContas;
            this.entity.descricao = categoriaContas.nome;
        } else {
            this.entity.id_categoria_conta = null;
            this.entity.categoriaContas = null;
        }
    }

    // Ao limpar a categoria de contas
    onCleanCategoriaContas(input: any) {
        this.entity.id_categoria_conta = null;
        this.entity.categoriaContas = null;

        input.value = '';
        input.focus();
    }

    // Abre o modal de pesquisa da categoria de conta
    openCategoriaContasPequisaModal() {
        this.categoriaContasPesquisaModal.open();
    }

    // Ao selecionar a categoria de conta no modal de pesquisa
    onSelectCategoriaContasPesquisaModal(categoriaContas: CategoriaContas) {
        if (categoriaContas) {
            this.entity.id_categoria_conta = categoriaContas.id;
            this.entity.categoriaContas = categoriaContas;
            this.entity.descricao = categoriaContas.nome;
        }
        jQuery('#categoriaContas').focus();
    }

    afterEdit(entity: Transferencia) {
        this.setCategoriaContas();
    }

    cancel() {
        this.router.navigate(['/financeiro/controle-financeiro'], {relativeTo: this.activatedRoute});
    }

    private getContas(): void {
        this.contasBancariasHttpService.getAllByCondominio(this.authService.getIdCurrentCondominio())
        .subscribe(
            response => {
                this.contas = response.filter(cnt => cnt.situacao == 'ATIVO');
                if (!this.editMode) {
                    this.entity.id_conta_bancaria_origem = this.idContaBancaria;
                }
            },
            error => this.messageService.error('Erro ao carregar contas bancárias. Tente novamente', 'Erro', error)
        );
    }

    public onChangeContaOrigem(contaOrigemId: number): void {
        if (contaOrigemId === this.entity.id_conta_bancaria_destino){
            this.entity.id_conta_bancaria_destino = null;
        }
    }

    public onChangeContaDestino(contaDestinoId: number): void {
        if (contaDestinoId === this.entity.id_conta_bancaria_origem){
            this.entity.id_conta_bancaria_origem = null;
        }
    }

    private removeConta(removedContaId: number, contas: ContaBancaria[]): void {
        const index = contas.findIndex(conta => conta.id === removedContaId);
        contas.splice(index, 1);
    }

    private addConta(addedConta: ContaBancaria, contas: ContaBancaria[]): void {
        contas.push(addedConta);
    }

}
