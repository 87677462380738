import { NgModule } from '@angular/core';
import { CondominosRelComponent } from './condominos-rel.component';
import { SharedModule } from 'app/shared';
import { TypeaheadModule } from 'ngx-bootstrap';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { BlocosHttpService } from 'app/home/blocos';

@NgModule({
  imports: [
    SharedModule,
    TypeaheadModule,
    MultiselectDropdownModule
  ],
  declarations: [
    CondominosRelComponent
  ],
  providers: [
    BlocosHttpService
  ]
})
export class CondominosRelModule { }