import { CustomHttp } from 'app/core';
import { Component, OnInit } from '@angular/core';

import { AuthService, ConfigService, DataUtilsService, MessageService } from '../../../core';
import { BaseRelatorio } from '../base-relatorio';
import { ConsumosUnidadesRelFiltro } from './consumos-unidades-rel-filtro';

import { Bloco, TipoConsumo } from '../../../models';
import { BlocosHttpService } from '../../blocos';
import { TiposConsumoHttpService } from '../../tipos-consumo';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

import { RelatoriosUtilsService } from '../shared';
import { ResponseContentType } from '@angular/http';

declare var jQuery: any;

@Component({
    selector: 'consumos-unidades-rel',
    templateUrl: './consumos-unidades-rel.component.html'
})
export class ConsumosUnidadesRelComponent implements BaseRelatorio, OnInit {
    baseUrl: string;
    bloco: Bloco;
    blocos: Bloco[] = [];
    tiposConsumo: TipoConsumo[] = [];
    filtro = new ConsumosUnidadesRelFiltro();

    constructor(private authService: AuthService,
                private configService: ConfigService,
                private dataUtilsService: DataUtilsService,
                private messageService: MessageService,
                private blocosHttpService: BlocosHttpService,
                private relatoriosUtilsService: RelatoriosUtilsService,
                private tiposConsumoHttpService: TiposConsumoHttpService,
                private customHttp: CustomHttp) {
        this.baseUrl = `${configService.getBaseUrl()}/relatorio/consumo`;
    }

    ngOnInit() {
        const idCondominio = this.authService.getIdCurrentCondominio();
        this.getBlocosByCondominio(idCondominio);
        this.getTiposConsumoByCondominio(idCondominio);
    }

    /** Carrega todos os blocos do condomínio informado */
    private getBlocosByCondominio(idCondominio: number) {
        this.blocos = [];
        this.blocosHttpService.getAllByCondominio(idCondominio)
            .subscribe(
                entities => this.blocos = entities,
                error => this.messageService.error('Erro ao carregar os blocos do condomínio. Tente novamente', 'Erro', error)
            );
    }

    // Ao selecionar o bloco
    onSelectBloco(tm: TypeaheadMatch): void {
        const bloco = tm.item as Bloco;
        this.filtro.id_bloco = bloco.id;
        this.filtro.bloco = bloco;
    }

    // Ao limpar o bloco
    onCleanBloco(input: any) {
        this.filtro.id_bloco = null;
        this.filtro.bloco = null;

        input.value = '';
        input.focus();
    }

    /** Carrega todos os tipos de consumo do condomínio informado */
    private getTiposConsumoByCondominio(idCondominio: number) {
        // this.tiposConsumoHttpService.getAllByCondominio(idCondominio)
        this.tiposConsumoHttpService.getAllTipos(false)
            .subscribe(
                entitites => this.tiposConsumo = entitites,
                error => this.messageService.error('Erro ao carregar os tipos de consumo do condomínio. Tente novamente', 'Erro', error)
            );
    }

    /** Ao alterar o mês de competência */
    onChangeMesCompetencia() {
        // Sempre setando o dia do mês de competência para o dia 1
        if (this.filtro.mes_competencia) {
            this.filtro.mes_competencia.setDate(1);
        }
    }

    /** Valida se todos os filtros do relatório foram informados */
    private validarImpressao(): boolean {
        if (!this.filtro.id_bloco) {
            this.messageService.info('É necessário informar o bloco para imprimir', 'Informação');
            return false;
        }
        if (!this.filtro.id_tipo_consumo) {
            this.messageService.info('É necessário informar o tipo do consumo para imprimir', 'Informação');
            return false;
        }
        if (!this.filtro.mes_competencia) {
            this.messageService.info('É necessário informar mês competência para imprimir', 'Informação');
            return false;
        }

        return true;
    }

    getUrl(): string {
        let url = this.baseUrl;
        url += `/bloco/${this.filtro.id_bloco}`;
        url += `/tipo/${this.filtro.id_tipo_consumo}`;
        const dataReferencia = this.dataUtilsService.formatarDataParaRelatorio(this.filtro.mes_competencia);
        url += `/mesreferencia/${dataReferencia}`;
        return url;
    }

    imprimir() {
        if (this.validarImpressao()) {
            const btnImprimir = jQuery('#btnImprimir');
            btnImprimir.button('loading');
            this.customHttp.get(`${this.getUrl()}`, { responseType: ResponseContentType.Blob })
                .subscribe(
                    pdf => {
                        this.relatoriosUtilsService.abrirRelatorio(pdf);
                        btnImprimir.button('reset');
                    },
                    error => {
                        this.messageService.error('Erro ao imprimir o relatório de Resultados do Consenso. Tente novamente', 'Error', error);
                        btnImprimir.button('reset');
                    }
                );
        }
    }

    limpar() {
        this.filtro.limpar();
    }
}
