import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared';

import { ArquivosRetornoCadastroComponent } from './cadastro';
import { ArquivosRetornoHttpService } from './services';
import { FileUploadModule } from 'ng2-file-upload';
import { ArquivosRetornoMensagensModalComponent } from './mensagens-modal';


@NgModule({
	imports: [
		SharedModule,
		FileUploadModule
	],
	declarations: [
		ArquivosRetornoCadastroComponent,
		ArquivosRetornoMensagensModalComponent
	],
	exports: [
	],
	providers: [
		ArquivosRetornoHttpService
	]
})
export class ArquivosRetornoModule { }
