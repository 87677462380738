import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { RateiosComponent } from './rateios.component';
import { RateiosUnidadesComponent, RateiosUnidadesHttpService } from './unidades';
import { RateiosUnidadesLancamentoComponent } from './unidades/lancamento';
import { RateiosHttpService } from './services';
import { LancamentosUnidadesModule } from '../lancamentos-unidades';
import { RateiosMensagensModalComponent } from './cadastro/mensagens-modal';
import { PopoverModule } from 'ngx-popover';
import { RateiosCadastroComponent } from './cadastro';
import { GerarCobrancaComponent } from './gerar-cobranca';
import { GerarCobrancaService } from './gerar-cobranca/gerar-cobranca.service';

@NgModule({
	imports: [
		SharedModule,
		TypeaheadModule,
		LancamentosUnidadesModule,
		PopoverModule
	],
	declarations: [
		RateiosComponent,
		RateiosCadastroComponent,
		RateiosUnidadesComponent,
		RateiosUnidadesLancamentoComponent,
		RateiosMensagensModalComponent,
		GerarCobrancaComponent
	],
	providers: [
		RateiosHttpService,
		RateiosUnidadesHttpService,
		GerarCobrancaService
	]
})
export class RateiosModule { }