import { NgModule } from '@angular/core';
import { ReciboComponent } from './recibo.component';
import { SharedModule } from 'app/shared';
import { ReciboGeracaoModalComponent } from './recibo-geracao-modal';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    ReciboComponent,
    ReciboGeracaoModalComponent
  ],
  exports: [
    ReciboComponent
  ]
})
export class ReciboModule { }
