import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import { BaseVisualizacaoComponent } from 'app/componentes-base';
import { AuthService, MessageService } from 'app/core';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { Antecipacao, Bloco, CobrancaAvulsa, Unidade } from 'app/models';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { BlocosHttpService, BlocosPesquisaModalComponent, UnidadesHttpService } from '../blocos';
import { AntecipacaoModalComponent } from './antecipacao-modal';
import { CobrancaAvulsaHttpService } from './service';

declare var jQuery: any;

@Component({
  selector: 'cobranca-avulsa',
  templateUrl: './cobranca-avulsa.component.html',
  styleUrls: ['./cobranca-avulsa.component.css']
})
export class CobrancaAvulsaComponent extends BaseVisualizacaoComponent<CobrancaAvulsa> {

  @ViewChild('antecipacaoModal') antecipacaoModal: AntecipacaoModalComponent;
  @ViewChild('inputBloco') inputBloco: ElementRef;
	@ViewChild('blocosPesquisaModal') blocosPesquisaModal: BlocosPesquisaModalComponent;

  public filtro: any = {};
  public allSelected: boolean = false;
  public blocos: Bloco[] = [];
  public unidades: Unidade[] = [];

  public multiselectSettings: IMultiSelectSettings = {
		enableSearch: true,
		containerClasses: 'container-multiselect',
		showUncheckAll: true,
		showCheckAll: true,
		dynamicTitleMaxItems: 3,
		checkedStyle: 'checkboxes',
		buttonClasses: 'btn btn-default',
		itemClasses: 'dropdown-menu'
	};
	public multiselectOptionsUnidades: IMultiSelectOption[];
	public multiselectTextsUnidades: IMultiSelectTexts = {
		checkAll: 'Selecionar todas',
		uncheckAll: 'Limpar',
		checked: 'selecionada',
		checkedPlural: 'selecionadas',
		searchPlaceholder: 'Pesquisar...',
		defaultTitle: 'Selecionar',
		allSelected: 'Todas selecionadas',
		searchEmptyResult: 'Nenhum registro encontrado',
		searchNoRenderText: 'Digite para pesquisar',
	};

  constructor(public activatedRoute: ActivatedRoute,
      public router: Router,
      public messageService: MessageService,
      private authService: AuthService,
      private cobrancaAvulsaHttpService: CobrancaAvulsaHttpService,
      public permissionService: PermissionService,
      public spinner: NgxSpinnerService,
      public blocosHttpService: BlocosHttpService,
      public unidadesHttpService: UnidadesHttpService) {
    super('#tableCobrancaAvulsa', cobrancaAvulsaHttpService, activatedRoute, router, messageService, permissionService, spinner);
  }

  ngOnInit() {
    this.getUnidades();
    this.getBlocosByCondominio();
    this.setInitialFilter();
    this.getPermissions();
    this.search();
  }

  /**
   * Todos os blocos do condomínio
   * @author Marcos Frare
   * @since 15/10/2021
   */
   private getBlocosByCondominio() {
		this.blocosHttpService.getAllByCondominio(this.authService.getIdCurrentCondominio()).subscribe(
			entitites => {
				this.blocos = entitites;
			},
			error => this.messageService.error('Erro ao carregar as unidades do condomínio. Tente novamente', 'Erro', error)
		);
	}

  /**
   * Busca as unidades do condomínio
   * @author Marcos Frare
   * @since 15/10/2021
   */
  private getUnidades(): void {
		this.unidades = [];
		this.multiselectOptionsUnidades = [];
		this.unidadesHttpService.getAllByCondominioBloco(this.authService.getIdCurrentCondominio(), null).subscribe(
			entities => {
        this.unidades = entities;
        this.loadUnidades();
			},
			error => this.messageService.error('Erro ao carregar as unidades do condomínio. Tente novamente', 'Erro', error)
		);
	}

  /**
   * Carregar a unidade conforme bloco selecionado
   * @author Marcos Frare
   * @since 26/07/2021
   */
  private loadUnidades(): void {
    this.multiselectOptionsUnidades = [];
    if (this.filtro.id_bloco) {
      this.filtro.ids_unidades = [];
      this.multiselectOptionsUnidades = <IMultiSelectOption[]>this.unidades.filter(un => un.id_bloco == this.filtro.id_bloco).map(und => {
        return <IMultiSelectOption>{id: und.id, name: `${und.nome_bloco} - ${und.nome_unidade}`}
      });
    } else {
      this.multiselectOptionsUnidades = <IMultiSelectOption[]>this.unidades.map(und => {
        return <IMultiSelectOption>{id: und.id, name: `${und.nome_bloco} - ${und.nome_unidade}`}
      });
    }
  }

  private setBloco(blocoSelecionado: Bloco) {
		this.filtro.bloco = blocoSelecionado;
		this.filtro.id_bloco = blocoSelecionado.id;
		this.loadUnidades();
	}

	public onSelectBloco(tm: TypeaheadMatch): void {
		this.setBloco(tm.item as Bloco);
	}

  public openBlocosPequisaModal() {
		this.blocosPesquisaModal.open();
	}

  public onSelectBlocosPesquisaModal(bloco: Bloco) {
		if (bloco) {
			this.setBloco(bloco);
		}
		jQuery('#bloco').focus();
	}

	public onCleanBloco(input: any) {
		this.filtro.bloco = null;
		this.filtro.id_bloco = null;
		this.loadUnidades();
		input.value = '';
		input.focus();
	}

  /**
   * Descrição da situação
   * @param entity 
   * @author Marcos Frare
   * @since 29/07/2021
   */
  public getSituacao(entity: CobrancaAvulsa): string {
    switch (entity.situacao) {
      case 'ABERTO': {
        return 'Em aberto';
      }
      case 'COBRANCA': {
        return 'Em cobrança';
      }
      default : {
        return 'Pago';
      }
    }
  }

  /**
   * Filtrar cobrança conforme filtros
   * @author Marcos Frare
   * @since 26/07/2021
   */
  public search(): void {
    this.spinner.show();
    this.entities = [];
    const params = {
      id_condominio: this.filtro.id_condominio,
      data_vencimento_inicial: this.filtro.data_vencimento_inicial,
      data_vencimento_final: this.filtro.data_vencimento_final,
      id_bloco: this.filtro.id_bloco,
      ids_unidades: this.filtro.ids_unidades ? this.filtro.ids_unidades.join(',') : [],
      tipo: this.filtro.tipo,
      situacao: this.filtro.situacao
    }
    this.destroyTable();
    this.cobrancaAvulsaHttpService.search(params).subscribe(
      response => {
        this.spinner.hide();
        this.entities = response;
        this.showTable();
      },
      error => {
        this.spinner.hide();
        this.messageService.error('Erro ao buscar cobranças!', 'Erro!', error);
      }
    );
  }

  /**
   * Filtro inicial
   * @author Marcos Frare
   * @since 26/04/2021
   */
  public setInitialFilter(): void {
		const today = new Date();
		this.filtro.id_condominio = this.authService.getIdCurrentCondominio();
		this.filtro.data_vencimento_final = new Date(today.getFullYear(), today.getMonth() + 1, 0);
		this.filtro.data_vencimento_inicial = null;
    this.filtro.tipo = '';
    this.filtro.situacao = 'ABERTO';
	}

  /**
   * Exclusão de cobrança
   * @param entity 
   * @author Marcos Frare
   * @since 23/07/2021
   */
  public delete(entity: CobrancaAvulsa): void {
    if (confirm(`Deseja excluir lançamento ${entity.descricao}?`)) {
      this.spinner.show();
      this.cobrancaAvulsaHttpService.delete(entity).subscribe(
        response => {
          this.spinner.hide();
          if (response.exclusao) {
            this.messageService.success('Lançamento excluído com sucesso!');
            this.search();
          } else {
            if (response.force) {
              if (confirm(response.msg+'\nDeseja excluir mesmo assim?')) {
                entity.forceDelete = true;
                this.delete(entity);
              }
            } else {
              if (response.error) {
                this.messageService.error(response.msg, 'Erro!');
              } else {
                this.messageService.warning(response.msg, 'Atenção!');
              }
            }
          }
        },
        error => {
          this.spinner.hide();
          this.messageService.error('Erro ao exlcuir lançamento! Tente novamente.', 'Erro!', error);
        }
      );
    }
  }

  /**
   * Evento para selecionar a cobrança ao clicar na linha
   * @param cobranca 
   * @auhor Marcos Frare
   * @since 28/07/2021
   */
  public lineCheckCobranca(cobranca: CobrancaAvulsa): void {
    cobranca.checado = !cobranca.checado;
  }

  /**
   * Comando para geração de títulos de cobranças selecionadas
   * @author Marcos Frare
   * @since 28/07/2021
   */
  public gerarTitulo(): void {
    let selectCobranca = this.entities.filter(ent => ent.checado && !ent.possui_titulo);
    if (selectCobranca.length > 0) {
      if (confirm(`Deseja gerar título(s) da(s) ${selectCobranca.length} cobrança(s) selecionada(s)?`)) {
        this.spinner.show();
        this.cobrancaAvulsaHttpService.gerarTitulo(selectCobranca).subscribe(
          () => {
            this.spinner.hide();
            this.messageService.success('Títulos gerados com sucesso!');
            this.search();
          },
          error => {
            this.spinner.hide();
            this.messageService.error('Erro na geração de títulos! Tente novamente.', 'Erro!', error);
          }
        );
      }
    } else {
      this.messageService.warning('Selecione cobranças para geração de títulos!', 'Atenção!');
    }
  }

  /**
	 * Faz seleção de todos os lançamentos
	 * @author Marcos Frare
	 * @since 17/08/2021
	 */
	 public toggleAllSelected(): void {
		for (let cob of this.entities) {
			cob.checado = this.allSelected;
		}
	}

  /**
	 * Verifica se todos os consumos das unidades estão selecionadas
	 * @author Marcos Frare
	 * @since 05/08/2021
	 */
	 public checkAllSelected(): boolean {
		if (Array.isArray(this.entities) && this.entities.length > 0) {
			for (const cob of this.entities) {
				if (!cob.checado) {
					return false;
				}
			}
		} else {
			return false;
		}
		return true;
	}

  // =================================================================================================================================================
  // Antecipações
  // =================================================================================================================================================
  public insertAntecipacao(): void {
    this.antecipacaoModal.open();
  }

  /**
   * Evento de geração da cobrança de antecipação
   * @param antecipacao 
   * @author Marcos Frare
   * @since 02/08/2021
   */
  public gerarAntecipacao(antecipacao: Antecipacao): void {
    this.search();
  }

}