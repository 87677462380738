export class ConciliacaobancariaRelFiltro {
	id_cliente: number;
	id_condominio: number;
	id_usuario: number;
	id_conta_bancaria: number;
	data_inicial: Date;
	data_final: Date;
	tipoFiltro: number;
	competencia: Date;

	/** Limpa o filtro */
	limpar() {
		this.id_conta_bancaria = null;
		this.tipoFiltro = 1;
		const today = new Date();
		this.data_inicial = new Date(today.getFullYear(), today.getMonth() - 1, 1); // primeiro dia do mês passado
		this.data_final = new Date(today.getFullYear(), today.getMonth(), 0); // último dia do mês passado
		this.competencia = new Date(today.getFullYear(), today.getMonth() - 1, 1);
	}

}