import { BaseEntity } from '../base-entity';

export class HistoricoConsumo extends BaseEntity {
	constructor(public consumo_inicial_de?: number,
		public consumo_final_de?: number,
		public valor_de?: any,
		public id_tipo_consumo?: number,
		public consumo_minimo_de?: any,
		public consumo_inicial_para?: number,
		public consumo_final_para?: number,
		public valor_para?: any,
		public consumo_minimo_para?: any,
		public data_alteracao?: any) {
		super();
	}
}