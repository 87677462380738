import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseVisualizacaoComponent } from 'app/componentes-base';
import { AuthService, MessageService } from 'app/core';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { ConfiguracaoNotificacaoAutomatica } from 'app/models';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfiguracaoNotificacaoAutomaticaCadastroModalComponent } from './configuracao-notificacao-automatica-cadastro-modal';
import { ConfiguracaoNotificacaoAutomaticaHttpService } from './service';

@Component({
  selector: 'configuracao-notificacao-automatica',
  templateUrl: './configuracao-notificacao-automatica.component.html',
  styleUrls: ['./configuracao-notificacao-automatica.component.css']
})
export class ConfiguracaoNotificacaoAutomaticaComponent extends BaseVisualizacaoComponent<ConfiguracaoNotificacaoAutomatica> {

  @ViewChild('configuracaoNotificacaoAutomaticaCadastroModal') configuracaoNotificacaoAutomaticaCadastroModal: ConfiguracaoNotificacaoAutomaticaCadastroModalComponent;

  public id_cliente: number;
  public id_condominio: number;

  constructor(public activatedRoute: ActivatedRoute,
      public router: Router,
      public messageService: MessageService,
      public permissionService: PermissionService,
      private authService: AuthService,
      private configuracaoNotificacaoAutomaticaHttpService: ConfiguracaoNotificacaoAutomaticaHttpService,
      public spinner: NgxSpinnerService) {
    super('#tableConfiguracaoNotificacaoAutomatica', configuracaoNotificacaoAutomaticaHttpService, activatedRoute, router, messageService, permissionService, spinner);
  }

  ngOnInit() {
    this.id_cliente = this.authService.getIdCurrentCliente();
    this.id_condominio = this.authService.getIdCurrentCondominio();
    this.getPermissions();
    this.getAll();
  }

  /**
   * Voltar à página de notificações
   * @author Marcos Frare
   * @since 19/02/2021
   */
  public return(): void {
    this.router.navigate(['/relatorios-administrativos/notificacao']);
  }

  /**
   * Buscar todas as configurações do condomínio
   * @author Marcos Frare
   * @since 19/02/2021
   */
  public getAll(): void {
    this.spinner.show();
    this.entities = [];
    this.configuracaoNotificacaoAutomaticaHttpService.getAll().subscribe(
      response => {
        this.entities = response;
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        this.messageService.error('Erro ao realizar a consulta. Tente novamente', 'Erro', error);
      }
    );
  }

  /**
   * Abrir tela para adicionar configuração
   * @author Marcos Frare
   * @since 22/02/2021
   */
  public adicionar(): void {
    this.configuracaoNotificacaoAutomaticaCadastroModal.id = null;
    this.configuracaoNotificacaoAutomaticaCadastroModal.open();
  }

  /**
   * Abre a tela para edição do registro
   * @param entity 
   * @author Marcos Frare
   * @since 22/02/2021
   */
  public edit(entity: ConfiguracaoNotificacaoAutomatica): void {
    this.configuracaoNotificacaoAutomaticaCadastroModal.id = entity.id;
    this.configuracaoNotificacaoAutomaticaCadastroModal.open();
  }

  /**
   * Exclusão do registro
   * @param entity 
   * @author Marcos Frare
   * @since 22/02/2021
   */
  public desativar(entity: ConfiguracaoNotificacaoAutomatica): void {
    if (confirm(`Deseja desativar a configuração?`)) {
      this.configuracaoNotificacaoAutomaticaHttpService.mudaSituacao(entity).subscribe(
        () => {
          this.getAll();
          this.messageService.success('Configuração desativada com sucesso!', 'Sucesso!');
        },
        error => {
          this.messageService.error('Erro ao desativar configuração. Tente novamente.', 'Erro', error);
        }
      );
    }
  }

  /**
   * Ativar configuração
   * @param entity 
   * @author Marcos Frare
   * @since 22/02/2021
   */
  public ativar(entity: ConfiguracaoNotificacaoAutomatica): void {
    if (confirm(`Deseja ativar a configuração?`)) {
      this.configuracaoNotificacaoAutomaticaHttpService.mudaSituacao(entity).subscribe(
        () => {
          this.getAll();
          this.messageService.success('Configuração ativada com sucesso!', 'Sucesso!');
        },
        error => {
          this.messageService.error('Erro ao ativar configuração. Tente novamente.', 'Erro', error);
        }
      );
    }
  }

  /**
   * Exclusão de configuração
   * @param entity 
   * @author Marcos Frare
   * @since 28/02/2021
   */
  public delete(entity: ConfiguracaoNotificacaoAutomatica): void {
    if (confirm(`Deseja excluir a configuração?`)) {
      this.spinner.show();
      this.configuracaoNotificacaoAutomaticaHttpService.delete(entity).subscribe(
        () => {
          this.spinner.hide();
          this.getAll();
          this.messageService.success('Configuracao excluída com sucesso!', 'Sucesso!');
        },
        error => {
          this.spinner.hide();
          this.messageService.error('Erro ao excluir configuração! Tente novamente', 'Erro', error);
        }
      );
    }
  }

}