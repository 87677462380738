import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';

import { Crud, CrudService, ConfigService, CustomHttp } from '../../../core';
import { Seguro } from '../../../models';

@Injectable()
export class SegurosHttpService implements Crud<Seguro> {
    resource = 'seguro';
    private baseUrl: string;

    constructor(private customHttp: CustomHttp,
                private crudService: CrudService,
                private configService: ConfigService) {
        this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
    }

    get(id: number): Observable<Seguro> {
        return this.crudService.get<Seguro>(this.resource, id);
    }

    getAll(): Observable<Seguro[]> {
        return this.crudService.getAll<Seguro>(this.resource);
    }

    post(entity: Seguro): Observable<Seguro> {
        return this.crudService.post<Seguro>(this.resource, entity);
    }

    put(entity: Seguro): Observable<Seguro> {
        return this.crudService.put<Seguro>(this.resource, entity);
    }

    delete(entity: Seguro): Observable<string> {
        return this.crudService.delete<Seguro>(this.resource, entity);
    }

    getAllByCondominio(id_condominio: number): Observable<Seguro[]> {
        return this.customHttp.get(`${this.baseUrl}/consultar`, { params: { id_condominio } })
                    .pipe(
                        map(response => response.json()),
                        catchError(error => _throw(error))
                    );
    }

    search(params: any): Observable<Seguro[]> {
        return this.customHttp.get(`${this.baseUrl}/consultar`, { params })
                    .pipe(
                        map(response => response.json()),
                        catchError(error => _throw(error))
                    );
    }

}
