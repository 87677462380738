/** Opções padrões do component datepicker */
export const datepickerDefaultOptions: any = {
	format: 'dd/mm/yyyy',
	todayBtn: 'linked',
	language: 'pt-BR',
	autoclose: true,
	todayHighlight: true,
	assumeNearbyYear: true,

	// Atributos personalizados (não são do bootstrap-datepicker)
	mask: '00/00/0000',
	placeholder: '__/__/____'
};

/** Opções do component datepicker para datas com apenas mês e ano */
export const datepickerMesAnoOptions: any = {
	minViewMode: 'months',
	format: 'mm/yyyy',
	todayBtn: false,
	language: 'pt-BR',
	autoclose: true,
	assumeNearbyYear: true,

	// Atributos personalizados (não são do bootstrap-datepicker)
	mask: '00/0000',
	placeholder: '__/____'
};