import { Pipe, PipeTransform } from '@angular/core';

import { UnidadeEstruturaItem } from '../estrutura';

/**
 * Pipe para ordenar os itens da estrutura dos consumos pelo o id do tipo de consumo
 */
@Pipe({
    name: 'unidadeEstruturaItemOrderBy'
})
export class UnidadeEstruturaItemOrderByPipe implements PipeTransform {

    transform(entities: UnidadeEstruturaItem[]): UnidadeEstruturaItem[] {
        entities.sort((entityA: UnidadeEstruturaItem, entityB: UnidadeEstruturaItem) => {
            if (entityA.consumoUnidade.id_tipo_consumo < entityB.consumoUnidade.id_tipo_consumo) {
                return -1;
            } else if (entityA.consumoUnidade.id_tipo_consumo > entityB.consumoUnidade.id_tipo_consumo) {
                return 1;
            }
            return 0;
        });
        return entities;
    }
}
