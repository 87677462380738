import { Injectable } from '@angular/core';
import { ConfigService, CustomHttp } from 'app/core';
import { ConfiguracaoEmailCliente } from 'app/models';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs-compat/observable/throw';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracaoEmailClienteHttpService {

  resource = 'configuracao-email';
  private baseUrl: string;

  constructor(private customHttp: CustomHttp,
    private configService: ConfigService) {
    this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
  }

  /**
   * Busca a configuração do cliente
   * @returns 
   * @author Marcos Frare
   * @since 10/03/2021
   */
  public getByCliente(id_cliente: number): Observable<ConfiguracaoEmailCliente> {
    return this.customHttp.get(`${this.baseUrl}/cliente/${id_cliente}`).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
  }

  /**
   * Salvar configuração do cliente
   * @param configuracao 
   * @returns 
   * @author Marcos Frare
   * @since 10/03/2021
   */
  public salvarCliente(configuracao: ConfiguracaoEmailCliente): Observable<ConfiguracaoEmailCliente> {
    return this.customHttp.post(`${this.baseUrl}/cliente`, configuracao).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
  }

  /**
   * Faz teste de envio do e-mail para ele mesmo
   * @param Integer $id - ID da configuração
   * @returns 
   * @author Marcos Frare
   * @since 10/03/2021
   */
   public getTesteEnvio(id: number): Observable<any> {
    return this.customHttp.get(`${this.baseUrl}/teste/${id}`).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
  }

  /**
   * Excluir configuração
   * @param id  
   * @author Marcos Frare
   * @since 16/03/2021
   */
  public excluir(id: number): Observable<any> {
    return this.customHttp.delete(`${this.baseUrl}/${id}`).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
  }

}