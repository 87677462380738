import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared';
import { RelatoriosUtilsService } from './services';

@NgModule({
	imports: [
		SharedModule
	],
	declarations: [],
	exports: [],
	providers: [
		RelatoriosUtilsService
	]
})
export class RelatoriosSharedModule { }