import { Injectable } from '@angular/core';
import { ConfigService, Crud, CrudService, CustomHttp } from 'app/core';
import { Relatorio } from 'app/models';
import { Observable } from 'rxjs';

@Injectable()
export class RelatorioHttpService implements Crud<Relatorio> {
  resource = 'relatorio';
  private baseUrl: string;
  private url: string;

  constructor(private customHttp: CustomHttp,
      private crudService: CrudService,
      private configService: ConfigService) {
    this.url = configService.getBaseUrl();
    this.baseUrl = `${this.url}/${this.resource}`;
  }

  get(id: number): Observable<Relatorio> {
    return this.crudService.get<Relatorio>(this.resource, id);
  }
  getAll(): Observable<Relatorio[]> {
    return this.crudService.getAll<Relatorio>(this.resource);
  }
  post(entity: Relatorio): Observable<Relatorio> {
    return this.crudService.post<Relatorio>(this.resource, entity);
  }
  put(entity: Relatorio): Observable<Relatorio> {
    return this.crudService.put<Relatorio>(this.resource, entity);
  }
  delete(entity: Relatorio): Observable<string> {
    return this.crudService.delete<Relatorio>(this.resource, entity);
  }

}