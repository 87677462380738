import { map, catchError } from 'rxjs/operators';

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { _throw } from 'rxjs/observable/throw';

import { AuthService, Crud, CrudService, ConfigService, CustomHttp } from '../../../core';
import { Cheque, Pagamento } from '../../../models';
import { PagamentosConsulta } from '../pagamentos-consulta';
import { PagamentosEnvio } from '../pagamentos-envio';

@Injectable()
export class PagamentosHttpService implements Crud<Pagamento> {
	resource = 'pagamento';
	private baseUrl: string;

	constructor(private customHttp: CustomHttp,
		private crudService: CrudService,
		private authService: AuthService,
		private configService: ConfigService) {
		this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
	}

	public get(id: number): Observable<Pagamento> {
		return this.crudService.get<Pagamento>(this.resource, id);
	}

	public getAll(): Observable<Pagamento[]> {
		return this.crudService.getAll<Pagamento>(this.resource);
	}

	public post(entity: Pagamento): Observable<Pagamento> {
		return this.crudService.post<Pagamento>(this.resource, entity);
	}

	public put(entity: Pagamento): Observable<Pagamento> {
		return this.crudService.put<Pagamento>(this.resource, entity);
	}

	public delete(entity: Pagamento): Observable<string> {
		const id_pagamento = entity.id;
		const id_usuario = this.authService.getIdCurrentUser();
		return this.customHttp.post(`${this.baseUrl}/desativar`, { id_pagamento, id_usuario }).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public getAllAPagar(idCondominio: number): Observable<PagamentosConsulta[]> {
		return this.customHttp.get(`${this.baseUrl}/consultar/condominio/${idCondominio}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public pagar(envio: PagamentosEnvio[]): Observable<any> {
		return this.customHttp.put(`${this.baseUrl}/pagar`, envio).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public estornar(id: number, envio: PagamentosEnvio): Observable<any> {
		envio.id_usuario = this.authService.getCurrentUser().id;
		return this.customHttp.put(`${this.baseUrl}/estornar/${id}`, envio).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public search(filtro: any): Observable<PagamentosConsulta[]> {
		return this.customHttp.post(`${this.baseUrl}/filtrar`, filtro).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Busca o pagamento e anexos
	 * @param id 
	 * @author Marcos Frare
	 * @since 28/07/2020
	 */
	public getPagamentoAnexos(id): Observable<Pagamento> {
		return this.customHttp.get(`${this.baseUrl}/anexo/${id}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Persiste os anexos em banco de dados
	 * @param pagamento 
	 * @author Marcos Frare
	 * @since 28/07/2020
	 */
	public gravarAnexos(pagamento: Pagamento): Observable<Pagamento> {
		return this.customHttp.post(`${this.baseUrl}/anexo`, pagamento).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Buca dados do cheque
	 * @param id 
	 * @author Marcos Frare
	 * @since 20/02/2023
	 */
	public getPagamentoCheque(id: number): Observable<Cheque> {
		return this.customHttp.get(`${this.baseUrl}/cheque/${id}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Pesrsistir dados do cheque
	 * @param cheque 
	 * @author Marcos Frare
	 * @since 20/02/2023
	 */
	public saveCheque(cheque: Cheque): Observable<Cheque> {
		return this.customHttp.post(`${this.baseUrl}/cheque`, cheque).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Excluir cheque
	 * @param id 
	 * @author Marcos Frare
	 * @since 22/02/2023
	 */
	public deleteCheque(id: number): Observable<any> {
		return this.customHttp.delete(`${this.baseUrl}/cheque/${id}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

}