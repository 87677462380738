import { BaseEntity } from "../base-entity";
import { Condomino } from "../entidades/condomino";

export class Boleto extends BaseEntity {
	id_conta_bancaria: number;
	id_arquivo_remessa: number;
	id_sacado: number;
	situacao: string;
	integrado: boolean;
	data_processamento: Date;
	codigo_boleto: string;
	data_vencimento: Date;
	data_pagamento: Date;
	data_credito: Date;
	data_baixa: Date;
	nosso_numero: string;
	codigo_barras: string;
	linha_digitavel: string;
	numero_documento: number;
	valor: number;
	valor_pago: number;
	valor_pago_multa: number;
	valor_pago_juros: number;
	valor_tarifa: number;

	sacado: Condomino;

	constructor() {
		super();
		this.valor = 0;
		this.sacado = new Condomino();
	}
}