import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe baseado no pipe currency nativo do Angular para formatar valores monetários no formato brasileiro
 */
@Pipe({
    name: 'currencyFormat'
})
export class CurrencyFormatPipe implements PipeTransform {

    transform(value: number,
              minIntegerDigits: number = 1,
              minFractionDigits: number = 0,
              maxFractionDigits: number = 2) {
        if (value == null) {
            return '';
        }
        if (typeof value !== 'number') {
            value = Number(value);
        }
        return value.toLocaleString('pt-br', {style: 'currency',
                                              currency: 'BRL',
                                              minimumIntegerDigits: minIntegerDigits,
                                              minimumFractionDigits: minFractionDigits,
                                              maximumFractionDigits: maxFractionDigits});
    }
}
