import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared';
import { RazaoRelComponent } from './razao-rel.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    RazaoRelComponent
  ]
})
export class RazaoRelModule { }