import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { MessageService, AuthService } from 'app/core';
import { ExtratoBanco, TemplateLancamentoCondominio, CategoriaContas, ContaBancaria, Fornecedor, tiposDivisaoRateio, tiposUnidade, Unidade, TemplateLancamentoCondominioItem } from 'app/models';
import { FornecedoresHttpService } from 'app/home/fornecedores';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { UnidadesHttpService } from 'app/home/blocos';
import { CategoriaContasHttpService, CategoriaContasPesquisaModalComponent } from 'app/home/plano-de-contas';
import { LancamentosCondominioHttpService } from 'app/home/lancamentos-condominio/services';

declare var jQuery: any;

@Component({
  selector: 'conciliacao-conta-lancamento-modal',
  templateUrl: './conciliacao-conta-lancamento-modal.component.html',
  styleUrls: ['./conciliacao-conta-lancamento-modal.component.css']
})
export class ConciliacaoContaLancamentoModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @Input() extratosLancamento: ExtratoBanco[];
  @Input() contaBancaria: ContaBancaria;

  @ViewChild('categoriaContasPesquisaModal') categoriaContasPesquisaModal: CategoriaContasPesquisaModalComponent;
  @ViewChild('categoriaContasDespesaPesquisaModal') categoriaContasDespesaPesquisaModal: CategoriaContasPesquisaModalComponent;
  @ViewChild('categoriaContasNaoRateadoPesquisaModal') categoriaContasNaoRateadoPesquisaModal: CategoriaContasPesquisaModalComponent;

  public tiposDivisaoRateio = tiposDivisaoRateio;
  public tiposUnidade = tiposUnidade;

  public templates: TemplateLancamentoCondominio[] = [];
  public id_template: number;
  private id_condominio: number;
  private id_usuario: number;
  public operacao: string;
  public categoriaConta: CategoriaContas = null;
  public categoriasContas: CategoriaContas[] = [];
  public complemento: string;
  
  public selectedTemplate: TemplateLancamentoCondominio;
  
  public categoriasContasReceita: CategoriaContas[] = [];
  public categoriasContasDespesa: CategoriaContas[] = [];
  public fornecedores: Fornecedor[] = [];
  public unidades: Unidade[];
  public loading: boolean = true;

  public currentItem: TemplateLancamentoCondominioItem = null;

  // public multiselectSettings: IMultiSelectSettings = {
  //   enableSearch: true,
  //   containerClasses: 'container-multiselect',
  //   showUncheckAll: true,
  //   showCheckAll: true,
  //   dynamicTitleMaxItems: 3,
  //   checkedStyle: 'checkboxes',
  //   buttonClasses: 'btn btn-default',
  //   itemClasses: 'dropdown-menu'
  // };
  // public multiselectOptionsUnidades: IMultiSelectOption[];
  // public multiselectTextsUnidades: IMultiSelectTexts = {
  //   checkAll: 'Selecionar todas',
  //   uncheckAll: 'Limpar',
  //   checked: 'selecionada',
  //   checkedPlural: 'selecionadas',
  //   searchPlaceholder: 'Pesquisar...',
  //   defaultTitle: 'Selecionar',
  //   allSelected: 'Todas selecionadas',
  //   searchEmptyResult: 'Nenhum registro encontrado',
  //   searchNoRenderText: 'Digite para pesquisar',
  // };

  constructor(private messageService: MessageService,
      private authService: AuthService,
      private lancamentoCondominioHttpService: LancamentosCondominioHttpService,
      private categoriaContasHttpService: CategoriaContasHttpService,
      private fornecedoresHttpService: FornecedoresHttpService,
      private unidadesHttpService: UnidadesHttpService) {
    this.id_usuario = this.authService.getIdCurrentUser();
    this.selectedTemplate = new TemplateLancamentoCondominio();
    // this.selectedTemplate.ids_unidades = [];
  }

  ngOnInit() {
    this.getUnidades();
    this.getAllFornecedores();
    this.id_condominio = this.authService.getIdCurrentCondominio();
    if (this.idModal == '') {
      this.idModal = 'conciliacaoContaLancamentoModal';
    }
    this.operacao = 'DEBITO';
  }

  /**
   * Carrega a operação do lançamento
   * @author Marcos Frare
   * @since 11/08/2020
   */
  public loadOperacao(): void {
    this.operacao = this.extratosLancamento[0].operacao;
  }

  /**
   * Carrega as templates de configurações de lançamento
   * @author Marcos Frare
   * @since 11/08/2020
   */
  public loadConfiguracao(): void {
    this.templates = [];
    this.lancamentoCondominioHttpService.buscaTemplatesCondominioConciliacao(this.id_condominio).subscribe(
      response => {
        this.templates = response;
      },
      error => {
        this.messageService.error('Erro ao buscar configurações para lançamento. Tente novamente', 'Erro', error);
      }
    );
  }

  /**
   * Busca todas as unidades do condomínio
   * @author Marcos Frare
   * @since 01/02/2021
   */
  private getUnidades(): void {
    this.unidades = [];
    this.unidadesHttpService.getAllTipoByCondominio().subscribe(
      res => this.unidades = res
    );
  }

  /**
   * Filtra unidades conforme tipo
   * @author Marcos Frare
   * @since 07/08/2020
   */
  public getUnidadesByTipo(): void {
    // this.multiselectOptionsUnidades = [];
    // // Todas Unidades
    // if (this.selectedTemplate.cobrar_tipo_unidade == 100) {
    //   this.multiselectOptionsUnidades = this.unidades.map(unid => {
    //     return {id: unid.id, name: `${unid.nome_bloco} - ${unid.nome_unidade}`};
    //   });
    // } else { // Conforme tipo de unidade
    //   this.multiselectOptionsUnidades = this.unidades.map(unid => {
    //     if (unid.tipo == this.selectedTemplate.cobrar_tipo_unidade) {
    //       return {id: unid.id, name: `${unid.nome_bloco} - ${unid.nome_unidade}`};
    //     }
    //   });
    // }
  }

  /**
   * Atualiza dados do template
   * @param template 
   * @author Marcos Frare
   * @since 01/02/2021
   */
  public loadTemplate(): void {
    this.loading = true;
    this.selectedTemplate = new TemplateLancamentoCondominio();
    let arTemplate = this.templates.filter(tmp => tmp.id == this.id_template);
    if (arTemplate.length > 0) {
      this.selectedTemplate = arTemplate[0];
      let item = this.selectedTemplate.items.length ? this.selectedTemplate.items[0] : new TemplateLancamentoCondominioItem();
      item.valor = Number(this.extratosLancamento.reduce((acc, crv) => {return acc+Number(crv.valor)}, 0));
      this.selectedTemplate.items = [item];
      setTimeout(() => {
        this.loading = false;
      }, 500)
    }
  }

  /**
   * Validar os dados ao ser submetido ao backend
   * @param entity 
   * @author Marcos Frare
   * @since 11/08/2020
   */
  public validarLancamento(entity: any): boolean {
    if (entity.operacao == '') {
      this.messageService.warning('Não identificado a operação do lançamento! Tente novamente.', 'Atenção');
      return false;
    } else {
      if (entity.operacao == 'DEBITO' && !entity.id_template) {
        this.messageService.warning('Configuração de lançamento é obrigatório!', 'Atenção');
        return false;
      }
      if (entity.operacao == 'CREDITO' && !entity.id_categoria_conta) {
        this.messageService.warning('Categoria de conta é obrigatório!', 'Atenção');
        return false;
      }
    }
    return true;
  }

  /**
   * Efeturar os lançamentos
   * @author Marcos Frare
   * @since 11/08/2020
   */
  public efetuarLancamento(): void {
    this.loading = true;
    let btn = jQuery('#btnEfeturarLancamento');
    btn.button('loading');
    const entity = {
      id_condominio: this.id_condominio,
      id_usuario: this.id_usuario,
      id_template: this.id_template,
      id_categoria_conta: this.categoriaConta ? this.categoriaConta.id : null,
      id_conta_bancaria: this.contaBancaria.id,
      operacao: this.operacao,
      extratosLancamento: this.extratosLancamento,
      complemento: this.complemento,
      template: this.selectedTemplate,
      descricao: this.categoriaConta ? this.categoriaConta.nome : ''
    }
    if (this.validarLancamento(entity)) {
      this.lancamentoCondominioHttpService.salvarLancamentoConciliacao(entity).subscribe(
        () => {
          this.loading = false;
          btn.button('reset');
          this.messageService.info('Lançamento realizado com sucesso!!', 'Info');
          jQuery('#loadConciliacaoBancaria').click();
          this.close();
        },
        error => {
          this.loading = false;
          btn.button('reset');
          this.messageService.error('Erro ao efeturar lançamento! Tente novamente.', 'Erro', error);
        }
      );
    } else {
      this.loading = false;
      btn.button('reset');
    }
  }

  private getAllCategoriasByCondominio(): void {
    this.categoriasContas = [];
    if (this.id_condominio) {
      this.categoriaContasHttpService.getAllByCondominio(this.id_condominio).then(entities => {
        this.categoriasContas = entities;
        this.categoriasContasReceita = entities.filter(conta => conta.tipo_conta == 1);
        this.categoriasContasDespesa = entities.filter(conta => conta.tipo_conta == 2);
      });
    }
  }

  /**
   * Busca os fornecedores do Cliente
   * @author Marcos Frare
   * @since 10/07/2020
   */
  private getAllFornecedores(): void {
    this.fornecedoresHttpService.getAllByCliente(this.authService.getIdCurrentCliente()).subscribe(
      entities => this.fornecedores = entities,
      error => this.messageService.error('Erro ao carregar os fornecedores', 'Erro', error)
    );
  }

  public onSelectCategoriaContasDespesa(tm: TypeaheadMatch, item: TemplateLancamentoCondominioItem): void {
    const categoriaContas: CategoriaContas = tm.item as CategoriaContas;
    if (categoriaContas && item) {
      item.categoriaConta = categoriaContas;
      item.id_categoria_conta = categoriaContas.id;
    } else {
      item.categoriaConta = null
      item.id_categoria_conta = null;
    }
  }

  public onCleanCategoriaContasDespesa(input: any, item: TemplateLancamentoCondominioItem): void {
    item.categoriaConta = null;
    item.id_categoria_conta = null;
    input.value = '';
    input.focus();
  }

  public openCategoriaContasPequisaModalDespesa(item: TemplateLancamentoCondominioItem): void {
    this.currentItem = item;
    this.categoriaContasDespesaPesquisaModal.open();
  }

  public onSelectCategoriaContasPesquisaModalDespesa(categoriaContas: CategoriaContas): void {
    if (categoriaContas && this.currentItem) {
      this.currentItem.categoriaConta = categoriaContas;
      this.currentItem.id_categoria_conta = categoriaContas.id;
    }
    jQuery('#categoriaContasDespesa').focus();
  }

  public onSelectCategoriaContasNaoRateado(tm: TypeaheadMatch, item: TemplateLancamentoCondominioItem): void {
    const categoriaContas: CategoriaContas = tm.item as CategoriaContas;
    if (categoriaContas && item) {
      item.categoriaContaNaoRateado = categoriaContas;
      item.id_categoria_conta_nao_rateado = categoriaContas.id;
    } else {
      item.categoriaContaNaoRateado = null
      item.id_categoria_conta_nao_rateado = null;
    }
  }

  public onCleanCategoriaContasNaoRateado(input: any, item: TemplateLancamentoCondominioItem): void {
    item.categoriaContaNaoRateado = null;
    item.id_categoria_conta_nao_rateado = null;
    input.value = '';
    input.focus();
  }

  public openCategoriaContasNaoRateadoPequisaModal(item: TemplateLancamentoCondominioItem): void {
    this.currentItem = item;
    this.categoriaContasNaoRateadoPesquisaModal.open();
  }

  public onSelectCategoriaContasNaoRateadoPesquisaModal(categoriaContas: CategoriaContas): void {
    if (categoriaContas && this.currentItem) {
      this.currentItem.id_categoria_conta_nao_rateado = categoriaContas.id;
      this.currentItem.categoriaContaNaoRateado = categoriaContas;
    }
    jQuery('#categoriaContasNaoRateado').focus();
  }

  public onSelectCategoriaContas(tm: TypeaheadMatch): void {
    const categoriaContas: CategoriaContas = tm.item as CategoriaContas;
    if (categoriaContas) {
      this.categoriaConta = categoriaContas;
    } else {
      this.categoriaConta = null;
    }
  }

  public onCleanCategoriaContas(input: any): void {
    this.categoriaConta = null;
    input.value = '';
    input.focus();
  }

  public openCategoriaContasPequisaModal(): void {
    this.categoriaContasPesquisaModal.open();
  }

  public onSelectCategoriaContasPesquisaModal(categoriaContas: CategoriaContas): void {
    if (categoriaContas) {
      this.categoriaConta = categoriaContas;
    }
    jQuery('#categoriaContas').focus();
  }

  public changeValorItem(item: TemplateLancamentoCondominioItem): void {
    console.log(item);
  }

  open() {
    this.loading = true;
    this.loadOperacao();
    this.getAllCategoriasByCondominio();
    this.loadConfiguracao();
    this.categoriaConta = null;
    if (this.operacao == 'CREDITO') {
      this.loading = false;
    }
    jQuery(`#${this.idModal}`).modal('show');
  }

  close() {
    this.extratosLancamento = [];
    this.id_template = null;
    this.loading = false;
    jQuery(`#${this.idModal}`).modal('hide');
  }

}