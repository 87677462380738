import { map, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { Crud, CrudService, ConfigService, CustomHttp, DataUtilsService, AuthService } from '../../../core';
import { ConsumoUnidade, ConsumoUnidadeHistorico, Unidade } from 'app/models';

@Injectable()
export class ConsumosUnidadesHttpService implements Crud<ConsumoUnidade> {
	resource = 'consumounidade';
	private baseUrl: string;

	constructor(private customHttp: CustomHttp,
			private authService: AuthService,
			private crudService: CrudService,
			private configService: ConfigService,
			private dataUtilsService: DataUtilsService) {
		this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
	}

	get(id: number): Observable<ConsumoUnidade> {
		return this.crudService.get<ConsumoUnidade>(this.resource, id);
	}

	getAll(): Observable<ConsumoUnidade[]> {
		return this.crudService.getAll<ConsumoUnidade>(this.resource);
	}

	post(entity: ConsumoUnidade): Observable<ConsumoUnidade> {
		return this.crudService.post<ConsumoUnidade>(this.resource, entity);
	}

	put(entity: ConsumoUnidade): Observable<ConsumoUnidade> {
		return this.crudService.put<ConsumoUnidade>(this.resource, entity);
	}

	delete(entity: ConsumoUnidade): Observable<string> {
		return this.crudService.delete<ConsumoUnidade>(this.resource, entity);
	}

	public limpar(consumoUnidade: ConsumoUnidade): Observable<ConsumoUnidade> {
		return this.customHttp.put(`${this.baseUrl}/limpar/${consumoUnidade.id}`, consumoUnidade).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public getAllUnidadesByCondominio(idCondominio: number): Observable<Unidade[]> {
		return this.customHttp.get(`${this.baseUrl}/consultar/condominio/${idCondominio}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public getConsumosUnidades(idCondominio: number, dataCompetencia: string): Observable<ConsumoUnidade[]> {
		return this.customHttp.get(`${this.baseUrl}/consultar/datacompetencia/${dataCompetencia}/condominio/${idCondominio}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public getDataCompetenciaAtualByCondominio(idCondominio: number): Observable<any> {
		return this.customHttp.get(`${this.baseUrl}/consultar/datacompetencia/condominio/${idCondominio}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public saveLeitura(payload: any): Observable<any> {
		const save = payload.id ? this.customHttp.put(`${this.baseUrl}/leitura/editar/${payload.id}`, payload) :
			this.customHttp.post(`${this.baseUrl}/leitura/cadastrar`, payload);
		return save.pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Buscar os consumos
	 * @param id_unidade 
	 * @param id_tipo_consumo 
	 * @author Marcos Frare
	 * @since 24/08/2021
	 */
	public obtemAbertoCondominioUnidade(id_unidade: number, id_tipo_consumo: number): Observable<ConsumoUnidade[]> {
		let id_condominio = this.authService.getIdCurrentCondominio();
		return this.customHttp.get(`${this.baseUrl}/sem-cobranca/condominio/${id_condominio}`, {params: {id_unidade, id_tipo_consumo}}).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Busca histórico de alteração de um consumo de unidade
	 * @param id_consumo_unidade 
	 * @author Marcos Frare
	 * @since 02/11/2021
	 */
	public getHistory(id_consumo_unidade: number): Observable<ConsumoUnidadeHistorico[]> {
		return this.customHttp.get(`${this.baseUrl}/historico/${id_consumo_unidade}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

}