import { map, catchError } from 'rxjs/operators';

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { _throw } from 'rxjs/observable/throw';

import { ConfiguracaoCondominio, PlanoDeContas } from '../../../models';
import { Crud, CrudService, ConfigService, CustomHttp } from '../../../core';

@Injectable()
export class CondominiosConfiguracaoHttpService implements Crud<ConfiguracaoCondominio> {
	resource = 'configuracaocondominio';
	private baseUrl: string;

	constructor(private customHttp: CustomHttp,
		private crudService: CrudService,
		private configService: ConfigService) {

		this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
	}

	get(id: number): Observable<ConfiguracaoCondominio> {
		return this.crudService.get<ConfiguracaoCondominio>(this.resource, id);
	}

	getAll(): Observable<ConfiguracaoCondominio[]> {
		return this.crudService.getAll<ConfiguracaoCondominio>(this.resource);
	}

	post(entity: ConfiguracaoCondominio): Observable<ConfiguracaoCondominio> {
		return this.crudService.post<ConfiguracaoCondominio>(this.resource, entity);
	}

	put(entity: ConfiguracaoCondominio): Observable<ConfiguracaoCondominio> {
		return this.crudService.put<ConfiguracaoCondominio>(this.resource, entity);
	}

	delete(entity: ConfiguracaoCondominio): Observable<string> {
		return this.crudService.delete<ConfiguracaoCondominio>(this.resource, entity);
	}

	/**
	 * Consulta a configuração do condomínio pelo Condomínio
	 */
	public getByCondominio(idCondominio: number): Observable<ConfiguracaoCondominio> {
		return this.customHttp.get(`${this.baseUrl}/consultar/condominio/${idCondominio}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public getContaPadraoByCondominio(idCondominio: number): Observable<number> {
		return this.customHttp.get(`${this.baseUrl}/consultar/condominio/${idCondominio}`).pipe(
			map(response => response.json().id_conta_bancaria_padrao),
			catchError(error => _throw(error))
		);
	}

	public getPlanoDeContaPadraoByCondominio(idCondominio: number): Observable<PlanoDeContas> {
		return this.customHttp.get(`${this.baseUrl}/consultar/condominio/${idCondominio}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public getTipoDivisaoRateioByCondominio(idCondominio: number): Observable<number> {
		return this.customHttp.get(`${this.baseUrl}/consultar/condominio/${idCondominio}`).pipe(
			map(response => response.json().tipo_divisao_rateio),
			catchError(error => _throw(error))
		);
	}

	public getFracaoSoloByCondominio(idCondominio: number): Observable<number> {
		return this.customHttp.get(`${this.baseUrl}/consultar/condominio/${idCondominio}`).pipe(
			map(response => response.json().fracao_ideal),
			catchError(error => _throw(error))
		);
	}

	public getDataVencimentoDefault(id_condominio: number, data_competencia: string): Observable<string> {
		const params = { id_condominio, data_competencia };
		return this.customHttp.get(`${this.baseUrl}/datavencimento`, { params }).pipe(
			map(response => response.text()),
			catchError(error => _throw(error))
		);
	}
	
}