import { Component, EventEmitter, OnInit, Input, ViewChild, Output } from '@angular/core';
import { AuthService, MessageService } from 'app/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { TiposConsumoHttpService } from 'app/home/tipos-consumo/services';
import { CondominioTiposConsumo, CategoriaContas, TipoConsumo } from 'app/models';
import { isNull } from 'util';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { CategoriaContasHttpService, CategoriaContasPesquisaModalComponent } from 'app/home/plano-de-contas';

declare var jQuery: any;

@Component({
  selector: 'vinculo-tabela-consumo-modal',
  templateUrl: './vinculo-tabela-consumo-modal.component.html',
  styleUrls: ['./vinculo-tabela-consumo-modal.component.css']
})
export class VinculoTabelaConsumoModalComponent implements OnInit, BaseModal {

  @ViewChild('categoriaContasTiposConsumoPesquisaModal') categoriaContasTiposConsumoPesquisaModal: CategoriaContasPesquisaModalComponent;
  
  public tiposConsumo: TipoConsumo[];
  public categoriasContas: CategoriaContas[] = [];

  @Input() idModal: string;
  @Input() condominioTiposConsumo: CondominioTiposConsumo;
  @Output() onSave: EventEmitter<CondominioTiposConsumo> = new EventEmitter();
  
  constructor(
      private authService: AuthService,
      private messageService: MessageService,
      private tiposConsumoHttpService: TiposConsumoHttpService,
      private categoriaContasHttpService: CategoriaContasHttpService) {
    this.condominioTiposConsumo = new CondominioTiposConsumo();
  }
  
  ngOnInit() {
    if (!this.idModal) {
      this.idModal = 'vinculaTabelaConsumoCondominio';
    }
    this.getAllTipoConsumo();
    this.getAllCategoriasByCondominio(this.authService.getIdCurrentCondominio());
  }

  private getAllCategoriasByCondominio(idCondominio: number): void {
    this.categoriasContas = [];
    if (idCondominio) {
      this.categoriaContasHttpService.getAllByCondominio(idCondominio, 1)
        .then(entities => this.categoriasContas = entities)
        .catch(() => this.messageService.info(`Configuração do condomínio não encontrada. Configure o condomínio para prosseguir.`, 'Informação'));
    }
  }

  edit(entity: any) {
    this.condominioTiposConsumo.id = entity.id;
    this.condominioTiposConsumo.id_tipo_consumo = entity.id_tipo_consumo;
    this.condominioTiposConsumo.id_condominio = entity.id_condominio;
    this.condominioTiposConsumo.id_categoria_conta = entity.id_categoria_conta;
    this.condominioTiposConsumo.meses_calculo = entity.meses_calculo;
    this.condominioTiposConsumo.tolerancia_inferior = entity.tolerancia_inferior;
    this.condominioTiposConsumo.tolerancia_superior = entity.tolerancia_superior;
    this.condominioTiposConsumo.categoriaConta = entity.CategoriaConta;

    jQuery(`#${this.idModal}`).modal('show');
  }
  
  open() {
    this.condominioTiposConsumo = new CondominioTiposConsumo();
    this.condominioTiposConsumo.id_condominio = this.authService.getIdCurrentCondominio();
    jQuery(`#${this.idModal}`).modal('show');
  }

  close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  private getAllTipoConsumo() {
    this.tiposConsumoHttpService.getAllTipos(true).subscribe(
      response => {
        this.tiposConsumo = response;
      },
      error => this.messageService.error('Erro ao carregar tabelas de serviço. Tente novamente', 'Erro', error)
    );
  }

  private validateForm(): boolean {
    if (!this.condominioTiposConsumo.id_tipo_consumo || isNull(this.condominioTiposConsumo.id_tipo_consumo)) {
      this.messageService.warning('Campo de tipo de consumo é obrigatório!!', 'Atenção!');
      return false;
    }
    if (!this.condominioTiposConsumo.id_categoria_conta || isNull(this.condominioTiposConsumo.id_categoria_conta)) {
      this.messageService.warning('Campo de plano de contas é obrigatório!!', 'Atenção!');
      return false;
    }
    return true;
  }

  private salvar(): void {
    if (this.validateForm()) {
      const btnSave = jQuery('#btnSalvar');
      btnSave.button('loading');

      this.tiposConsumoHttpService.vincularTabelaCondominio(this.condominioTiposConsumo)
        .subscribe(
          () => {
            btnSave.button('reset');
            this.condominioTiposConsumo = new CondominioTiposConsumo();
            this.onSave.emit(this.condominioTiposConsumo);
            this.messageService.success('', 'Tabela vinculada com sucesso!!');
            this.close();
          },
          error => {
            this.messageService.error('Erro ao vincular tabela. Tente novamente', 'Erro', error);
            btnSave.button('reset');
          }
        );
    }
  }

  private setCategoriaContasTipoConsumo(): void {
    if (!this.condominioTiposConsumo.id_categoria_conta) {
      return;
    }
    this.categoriaContasHttpService.get(this.condominioTiposConsumo.id_categoria_conta).subscribe(
      entity => {
        this.condominioTiposConsumo.id_categoria_conta = entity.id;
        this.condominioTiposConsumo.categoriaConta = entity;
      }
    );
  }

  public onSelectCategoriaContasTipoConsumo(tm: TypeaheadMatch): void {
    const categoriaContas: CategoriaContas = tm.item as CategoriaContas;
    if (categoriaContas) {
      this.condominioTiposConsumo.id_categoria_conta = categoriaContas.id;
      this.condominioTiposConsumo.categoriaConta = categoriaContas;
    } else {
      this.condominioTiposConsumo.id_categoria_conta = null;
      this.condominioTiposConsumo.categoriaConta = null;
    }
  }

  public onCleanCategoriaContasTipoConsumo(input: any): void {
    this.condominioTiposConsumo.id_categoria_conta = null;
    this.condominioTiposConsumo.categoriaConta = null;
    input.value = '';
    input.focus();
  }

  public openCategoriaContasTipoConsumoPequisaModal(): void {
    this.categoriaContasTiposConsumoPesquisaModal.open();
  }

  public onSelectCategoriaContasTiposConsumoPesquisaModal(categoriaContas: CategoriaContas): void {
    if (categoriaContas) {
      this.condominioTiposConsumo.id_categoria_conta = categoriaContas.id;
      this.condominioTiposConsumo.categoriaConta = categoriaContas;
    }
    jQuery('#categoriaContas').focus();
  }

}