export class CreditosLancamentosRelFiltro {
    id_cliente: number;
    id_condominio: number;
    id_usuario: number;
    id_bloco: number;
    unidades: number[];
    tipoFiltro: number;
    data_inicial: Date;
    data_final: Date;
    competencia_inicial: Date;
    competencia_final: Date;

    /** Limpa o filtro */
    limpar() {
        this.id_bloco = null;
        this.unidades = [];
        this.tipoFiltro = 1;
        const today = new Date();
        this.data_inicial = new Date(today.getFullYear(), today.getMonth()-1, 1); // primeiro dia do mês passado
        this.data_final = new Date(today.getFullYear(), today.getMonth(), 0); // último dia do mês passado
        this.competencia_inicial = new Date(today.getFullYear(), today.getMonth()-3, 1); // Competência fechada
        this.competencia_final = new Date(today.getFullYear(), today.getMonth(), 0); // Última competência fechada
    }
}
