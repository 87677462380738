
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';
import { Injectable, EventEmitter } from '@angular/core';
import { Http, Response} from '@angular/http';
import { Router } from '@angular/router';
import { ConfigService } from '../../config';
import { SessionStorageService } from '../web-storage';
import { JwtTokenService } from './jwt-token.service';
import { Condominio, Usuario, TIPO_USUARIO, Cliente } from '../../../models';
import { Auth } from './auth';
import { map, catchError } from 'rxjs/operators';

const currentCondominioKey = 'currentCondominio';
const currentClienteKey = 'currentCliente';
const currentUserKey = 'currentUser';

@Injectable()
export class AuthService {

	private baseUrl: string;
	public changedCondominio$: EventEmitter<any>;
	public changedCliente$: EventEmitter<any>;
	public changedUser$: EventEmitter<any>;

	constructor(private http: Http,
			private router: Router,
			public configService: ConfigService,
			private jwtTokenService: JwtTokenService,
			private sessionStorageService: SessionStorageService) {
		this.baseUrl = configService.getBaseUrl();
		this.changedCondominio$ = new EventEmitter();
		this.changedCliente$ = new EventEmitter();
		this.changedUser$ = new EventEmitter();
	}

	get isCondominioLoaded(): boolean {
		if (this.getCurrentCondominio()) return true;
		return false;
	}

	get isClienteLoaded(): boolean {
		if (this.getIdCurrentCliente()) return true;
		return false;
	}

	get isAuth(): boolean {
		if (!this.jwtTokenService.accessToken || this.jwtTokenService.isTokenExpired())
			return false;
		return true;
	}

	get isAdmin(): boolean {
		const tipo: number = this.getTipoCurrentUser();
		return tipo && tipo === TIPO_USUARIO.ADMINISTRADOR ? true : false;
	}

	public login(auth: Auth): Observable<Response> {
		return this.http.post(`${this.baseUrl}/logar`, auth).pipe(
			map((response:any) => {
				this.jwtTokenService.accessToken = response.json().token;
				return response;
			}),
			catchError(error => Observable.throw(error))
		);
	}

	public logout(): void {
		this.removeLoggedInfo();
		this.router.navigate(['/login']);
	}

	public getCurrentUser(): Usuario {
		const tokenDecoded = this.jwtTokenService.decodeToken();
		if (!tokenDecoded) return null;
		return tokenDecoded.usuario;
	}

	/**
	 * Retorna o cliente
	 * @author Marcos Frare
	 * @since 24/01/2020
	 */
	public getCurrentClient(): Cliente {
		const tokenDecoded = this.jwtTokenService.decodeToken();
		if (!tokenDecoded) return null;
		return tokenDecoded.cliente;
	}

	public getIdCurrentUser(): number {
		const currentUser = this.getCurrentUser();
		if (!currentUser) return null;
		return currentUser.id;
	}

	public getTipoCurrentUser(): number {
		const currentUser = this.getCurrentUser();
		if (!currentUser) return null;
		return currentUser.tipo;
	}

	public getCurrentCondominio(): Condominio {
		const currentCondominio = this.sessionStorageService.getObject(currentCondominioKey) as Condominio;
		return currentCondominio;
	}

	public getIdCurrentCondominio(): number {
		const condominio = this.getCurrentCondominio();
		return condominio ? condominio.id : null;
	}

	public getIdCurrentCliente(): number {
		const currentCliente = this.sessionStorageService.getObject(currentClienteKey) as number;
		return currentCliente;
	}

	public removeLoggedInfo(): void {
		this.jwtTokenService.remove();
		this.sessionStorageService.remove(currentCondominioKey);
		this.sessionStorageService.remove(currentClienteKey);
		this.sessionStorageService.remove(currentUserKey);
	}

	public setLoggedInfo(condominio: Condominio = null): void {
		let tipoUsuario = this.getTipoCurrentUser();
		let user = this.getCurrentUser();
		let idCliente = null;
		switch (tipoUsuario) {
			case TIPO_USUARIO.FUNCIONARIO:
				idCliente = user.id_cliente
				break;
			case TIPO_USUARIO.CONDOMINO:
				idCliente =  condominio ? condominio.id_cliente : null;
				break;
			default:
				break;
		}

		this.sessionStorageService.setObject(currentUserKey, user.id);
		this.changedUser$.emit(user.id);

		if(!idCliente) return;
		this.sessionStorageService.setObject(currentClienteKey, idCliente);
		this.changedCliente$.emit(idCliente);

		if(!condominio) return;
		this.sessionStorageService.setObject(currentCondominioKey, condominio);
		this.changedCondominio$.emit(condominio);
	}

	/**
	 * Logout do condomínio
	 * @author Marcos Frare
	 * @since 03/04/2020
	 */
	public logoutCondominio() {
		this.sessionStorageService.remove(currentCondominioKey);
		this.router.navigate(['/dashboard']);
		this.changedCondominio$.emit();
	}
}
