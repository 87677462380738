import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseVisualizacaoComponent } from '../../componentes-base';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { AuthService, MessageService } from '../../core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { NgxSpinnerService } from 'ngx-spinner';
import { Bloco, CategoriaContas, LancamentoUnidade, SITUACAO_RATEIO, tipoRateio, Unidade } from 'app/models';
import { BlocosHttpService, BlocosPesquisaModalComponent, UnidadesHttpService, UnidadesPesquisaModalComponent } from '../blocos';
import { CategoriaContasHttpService, CategoriaContasPesquisaModalComponent } from '../plano-de-contas';
import { LancamentosUnidadesHttpService } from './services';

declare var jQuery: any;

@Component({
	selector: 'lancamentos-unidades',
	templateUrl: './lancamentos-unidades.component.html',
	styleUrls: ['./lancamentos-unidades.component.css'],
	providers: [PermissionService]
})
export class LancamentosUnidadesComponent extends BaseVisualizacaoComponent<LancamentoUnidade> implements OnInit {

	@ViewChild('inputBloco') inputBloco: ElementRef;
	@ViewChild('inputUnidade') inputUnidade: ElementRef;
	@ViewChild('blocosPesquisaModal') blocosPesquisaModal: BlocosPesquisaModalComponent;
	@ViewChild('unidadesPesquisaModal') unidadesPesquisaModal: UnidadesPesquisaModalComponent;
	@ViewChild('categoriaContasPesquisaModal') categoriaContasPesquisaModal: CategoriaContasPesquisaModalComponent;

	public idCliente: number;
	public idCondominio: number;
	public bloco: Bloco;
	public blocos: Bloco[] = [];
	public unidade: Unidade;
	public unidades: Unidade[] = [];
	public filtro: any = {};
	public categoriasContas: CategoriaContas[] = [];
	public tiposRateio = tipoRateio;
	public total: number = 0;

	constructor(activatedRoute: ActivatedRoute,
		router: Router,
		messageService: MessageService,
		permissionService: PermissionService,
		private authService: AuthService,
		private blocosHttpService: BlocosHttpService,
		private categoriaContasHttpService: CategoriaContasHttpService,
		private unidadesHttpService: UnidadesHttpService,
		private lancamentosUnidadesHttpService: LancamentosUnidadesHttpService,
		public spinner: NgxSpinnerService) {
		super('#tableLancamentosUnidades', lancamentosUnidadesHttpService, activatedRoute, router, messageService, permissionService, spinner);
	}

	ngOnInit() {
		this.idCondominio = this.authService.getIdCurrentCondominio();
		this.idCliente = this.authService.getIdCurrentCliente();
		this.getAllCategoriasByCondominio();
		this.setInitialFilter();
		this.search();
		this.getBlocosByCondominio();
		this.getPermissions();
	}

	/**
	 * Origem do lançamento
	 * @param entity 
	 * @author Marcos Frare
	 * @since 29/07/2021
	 */
	public getOrigem(entity: LancamentoUnidade): string {
		switch(entity.origem) {
			case 'RESERVA': {
				return 'Reserva';
			}
			case 'COBRANCAAVULSA': {
				return 'Cobrança Avulsa';
			}
			default : {
				return 'Lançamentos';
			}
		}
	}

	public getAll(): void {
		this.spinner.show();
		this.entities = [];
		this.filtro.situacao_rateio = SITUACAO_RATEIO.TODOS;
		this.destroyTable();
		this.lancamentosUnidadesHttpService.getAllByCondominio(this.idCondominio).subscribe(
			response => {
				this.spinner.hide();
				this.entities = response.lancamentos;
				this.total = response.total;
				this.showTable();
			},
			error => {
				this.spinner.hide();
				this.messageService.error('Erro ao carregar os lançamentos da unidade. Tente novamente', 'Erro', error);
			}
		);
	}

	public search(): void {
		this.spinner.show();
		this.entities = [];
		this.destroyTable();
		this.lancamentosUnidadesHttpService.search(this.filtro).subscribe(
			response => {
				this.spinner.hide();
				this.entities = response.lancamentos;
				this.total = response.total;
				this.showTable();
			},
			error => {
				this.spinner.hide();
				this.messageService.error('Erro ao carregar os lançamentos da unidade. Tente novamente.', 'Erro', error);
			}
		);
	}

	public setInitialFilter(): void {
		const today = new Date();
		const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
		this.filtro.id_condominio = this.idCondominio;
		this.filtro.data_competencia_final = lastDay;
		this.filtro.situacao_rateio = SITUACAO_RATEIO.NAO_PROCESSADO;
	}

	public getBlocosByCondominio(): void {
		this.blocos = [];
		this.blocosHttpService.getAllByCondominio(this.idCondominio).subscribe(
			entities => {
				this.blocos = entities;
				if (entities.length === 1) this.setBloco(entities[0])
			},
			error => this.messageService.error('Erro ao carregar os blocos do condomínio. Tente novamente', 'Erro', error)
		);
	}

	private setBloco(blocoSelecionado: Bloco): void {
		this.bloco = blocoSelecionado;
		this.filtro.id_bloco = this.bloco.id;
		this.getAllUnidadesByBloco(this.bloco.id);
		this.onCleanUnidade(this.inputUnidade.nativeElement);
	}

	public onSelectBloco(tm: TypeaheadMatch): void {
		this.setBloco(tm.item as Bloco);
	}

	public openBlocosPequisaModal(): void {
		this.blocosPesquisaModal.open();
	}

	public onSelectBlocosPesquisaModal(bloco: Bloco): void {
		if (bloco) this.setBloco(bloco);
		jQuery('#bloco').focus();
	}

	public onCleanBloco(input: any): void {
		this.onCleanUnidade(this.inputUnidade.nativeElement);
		this.bloco = null;
		this.filtro.id_bloco = null;
		input.value = '';
		input.focus();
	}

	public getAllUnidadesByBloco(idBloco: number): void {
		this.unidades = [];
		this.unidadesHttpService.getAllByBloco(idBloco).subscribe(
			entities => {
				for (const unidade of entities) {
					if (unidade.nome) {
						this.unidades.push(unidade);
					}
				}
			},
			error => this.messageService.error('Erro ao carregar as unidades do bloco. Tente novamente', 'Erro', error)
		);
	}

	private setUnidade(unidadeSelecionada: Unidade): void {
		this.unidade = unidadeSelecionada;
		this.filtro.id_unidade = this.unidade.id;
	}

	public onSelectUnidade(tm: TypeaheadMatch): void {
		this.setUnidade(tm.item as Unidade);
	}

	public onCleanUnidade(input: any): void {
		this.unidade = null;
		this.filtro.id_unidade = null;
		input.value = '';
		input.focus();
	}

	public openUnidadesPequisaModal(): void {
		this.unidadesPesquisaModal.open();
	}

	public onSelectUnidadesPesquisaModal(unidade: Unidade): void {
		if (unidade) this.setUnidade(unidade);
		jQuery('#unidade').focus();
	}

	public isShowTable(): boolean {
		if (!this.entities) return false;
		return true;
	}

	public insert(): void {
		this.router.navigate(['new'], { relativeTo: this.activatedRoute });
	}

	public edit(entity: LancamentoUnidade): void {
		if (entity.origem != 'RESERVA') {
			this.router.navigate([entity.id], { relativeTo: this.activatedRoute });
		}
	}

	private getAllCategoriasByCondominio(): void {
		this.categoriasContas = [];
		if (this.idCondominio) {
			this.categoriaContasHttpService.getAllByCondominio(this.idCondominio, 1)
				.then(entities => this.categoriasContas = entities)
				.catch(() => this.messageService.info(`Configuração do condomínio não encontrada. Configure o condomínio para prosseguir.`, 'Informação'));
		}
	}

	public onSelectCategoriaContas(tm: TypeaheadMatch): void {
		const categoriaContas: CategoriaContas = tm.item as CategoriaContas;
		if (categoriaContas) {
			this.filtro.id_categoria_conta = categoriaContas.id;
			this.filtro.categoriaContas = categoriaContas;
		} else {
			this.filtro.id_categoria_conta = null;
			this.filtro.categoriaContas = null;
		}
	}

	public onCleanCategoriaContas(input: any): void {
		this.filtro.id_categoria_conta = null;
		this.filtro.categoriaContas = null;
		input.value = '';
		input.focus();
	}

	public openCategoriaContasPequisaModal(): void {
		this.categoriaContasPesquisaModal.open();
	}

	public onSelectCategoriaContasPesquisaModal(categoriaContas: CategoriaContas): void {
		if (categoriaContas) {
			this.filtro.id_categoria_conta = categoriaContas.id;
			this.filtro.categoriaContas = categoriaContas;
		}
		jQuery('#categoriaContas').focus();
	}

	public delete(lancamento: LancamentoUnidade): void {
		if (confirm(`Tem certeza que deseja excluir o lancamento ${lancamento.nome_categoria_conta}?`)) {
			this.spinner.show();
			this.lancamentosUnidadesHttpService.delete(lancamento).subscribe(
				() => {
					this.spinner.hide();
					this.messageService.success('Lançamento excluído com sucesso');
					this.search();
				},
				error => {
					this.spinner.hide();
					if (error && error.status && error.status === 403) {
						this.messageService.warning('Não é possível excluir o lançamento selecionado pois o mesmo faz parte de um rateio');
					} else {
						this.messageService.error('Erro ao excluir lançamento');
					}
				}
			);
		}
	}

	public labelTotal(): string {
		switch (this.filtro.situacao_rateio) {
			case SITUACAO_RATEIO.PROCESSADO:
				return 'Total dos lançamentos processados';
			case SITUACAO_RATEIO.NAO_PROCESSADO:
				return 'Total dos lançamentos não processados';
			case SITUACAO_RATEIO.TODOS:
				return 'Total de todos lançamentos';
			case SITUACAO_RATEIO.CATEGORIA_NAO_RATEADA:
				return 'Total dos lançamentos de categoria de conta não rateada';
			default:
				return 'Total dos lançamentos';
		}
	}

}