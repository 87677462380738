import { Injectable } from '@angular/core';

import { MessageService } from '../../../core';
import { ConsumosUnidadesHttpService } from './consumos-unidades-http.service';


/**
 * Service com métodos para trabalhar com o mês/data competência dos consumos das unidades
 */
@Injectable()
export class ConsumosUnidadesDataCompetenciaService {

    constructor(private messageService: MessageService,
                private consumosUnidadesHttpService: ConsumosUnidadesHttpService) { }


    /* Valida se a data competência informada pelo usuário é maior do que a data competência atualmente
    válida para lançamentos dos consumos das undiades do bloco selecionado */
    private validarDataMaiorQuePermitida(dataCompetencia: Date, dataCompetenciaAtual: Date): boolean {
        if (!dataCompetenciaAtual) {
            return true;
        }
        return dataCompetencia.getTime() <= dataCompetenciaAtual.getTime();
    }

    /**
     * Realiza a validação da data de competência informada para a geração dos consumos das undiades
     */
    validar(dataCompetencia: Date, dataCompetenciaAtual: Date): boolean {
        if (!dataCompetencia || isNaN(dataCompetencia.getTime())) {
            this.messageService.info('É necessário informar o mês competência para poder lançar os consumos', 'Informação');
            return false;
        }
        if (!this.validarDataMaiorQuePermitida(dataCompetencia, dataCompetenciaAtual)) {
            let mesCompetenciaMsg = Number(dataCompetenciaAtual.getMonth() + 1).toLocaleString('pt-br', {minimumIntegerDigits: 2});
            mesCompetenciaMsg += '/' + dataCompetenciaAtual.getFullYear();
            this.messageService.info(`O mês competência não pode ser maior do que ${mesCompetenciaMsg}`, 'Informação');
            return false;
        }
        return true;
    }

    /**
     * Carrega a data de competência atual para os lançamentos de consumos das unidades do bloco informado
     */
    getByCondominio(idCondominio: number): Promise<Date> {
        return new Promise<Date>((resolve, reject) => {
            this.consumosUnidadesHttpService.getDataCompetenciaAtualByCondominio(idCondominio)
                .subscribe(
                    response => {
                        if (response.data_competencia) {
                            resolve(new Date(response.data_competencia));
                        } else {
                            resolve(null);
                        }
                    },
                    error => reject(error)
                );
        });
    }
}
