import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseCadastroComponent } from 'app/componentes-base';
import { MessageService } from 'app/core';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { Filtro, tiposRelatorioFiltro } from 'app/models';
import { FiltroHttpService } from '../service';

@Component({
  selector: 'filtro-cadastro',
  templateUrl: './filtro-cadastro.component.html',
  styleUrls: ['./filtro-cadastro.component.css']
})
export class FiltroCadastroComponent extends BaseCadastroComponent<Filtro> implements OnInit {

  public tipos = tiposRelatorioFiltro;

  constructor(public activatedRoute: ActivatedRoute,
      public router: Router,
      public messageService: MessageService,
      public permissionService: PermissionService,
      private filtroHttpService: FiltroHttpService) {
    super(Filtro, filtroHttpService, activatedRoute, router, messageService, permissionService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

}