import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { MessageService } from 'app/core';
import { Anexo, LancamentosCondominio } from 'app/models';
import { AttachmentComponent, VisualizaAnexoComponent } from 'app/shared';
import { LancamentosCondominioHttpService } from '../services';

declare var jQuery: any;

@Component({
  selector: 'lancamento-condominio-anexo-modal',
  templateUrl: './lancamento-condominio-anexo-modal.component.html',
  styleUrls: ['./lancamento-condominio-anexo-modal.component.css']
})
export class LancamentoCondominioAnexoModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @Input() idLancamento: number;

  @ViewChild('anexoComponent') anexoComponent: AttachmentComponent;
  @ViewChild('visualizaAnexoModal') visualizaAnexoModal: VisualizaAnexoComponent;

  public lancamento: LancamentosCondominio = new LancamentosCondominio();

  constructor(private lancamentosCondominioHttpService: LancamentosCondominioHttpService,
    private messageService: MessageService) { }

  ngOnInit() {
  }

  /**
   * Carregar os anexos do lançamento
   * @author Marcos Frare
   * @since 02/12/2020
   */
  private load(): void {
    this.lancamento = new LancamentosCondominio();
    this.lancamentosCondominioHttpService.getLancamentoAnexos(this.idLancamento).subscribe(
      response => {
        this.lancamento = response;
        if (this.lancamento.anexos.length < 1) {
          this.addAnexos();
        }
      },
      error => {
        this.messageService.error('Erro ao carregar anexos!', 'Erro', error);
      }
    );
  }

  /**
   * Persiste os anexos
   * @author Marcos Frare
   * @since 01/12/2020
   */
  public anexar(): void {
    let btnAnexar = jQuery('#btnAnexar');
    btnAnexar.button('loading');
    this.lancamentosCondominioHttpService.gravarAnexos(this.lancamento).subscribe(
      () => {
        btnAnexar.button('reset');
        this.messageService.success('Arquivos anexados com sucesso!');
        this.close();
      },
      error => {
        btnAnexar.button('reset');
        this.messageService.error('Erro ao anexar arquivos! Tente novamente.', 'Erro', error);
      }
    );
  }

  open() {
    this.load();
    jQuery(`#${this.idModal}`).modal('show');
  }
  close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  public addAnexos(): void {
    this.lancamento.anexos.push(new Anexo());
  }

  public removeAnexos(index): void {
    this.lancamento.anexos.splice(index, 1);
  }

  /**
	 * Mostra anexo
	 * @param id
	 * @author Marcos Frare
	 * @since 01/03/2022
	 */
	 public fileView(file: any): void {
		if (file.id) {
			this.visualizaAnexoModal.id_anexo = file.id;
			this.visualizaAnexoModal.fileType = file.fileType;
			this.visualizaAnexoModal.open();
		}
	}

}