import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseCadastroComponent } from '../../../componentes-base';
import { MessageService, AuthService, FormsUtilsService } from '../../../core';
import { Fornecedor, CategoriaContas, Telefone } from '../../../models';
import { FornecedoresHttpService } from '../services';
import { CategoriaContasPesquisaModalComponent } from '../../plano-de-contas/categoria-contas';
import { CategoriaContasHttpService } from '../../plano-de-contas/categoria-contas';

import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { PermissionService } from '../../../core/services/permission/permission.service';
import { PlanoDeContasCadastroModalComponent } from '../../plano-de-contas/cadastro-modal/plano-de-contas-cadastro-modal.component';
import { CpfFormatPipe } from '../../../shared/pipes';
import { CnpjFormatPipe } from '../../../shared/pipes';

declare var jQuery: any;

@Component({
	selector: 'fornecedores-cadastro',
	templateUrl: './fornecedores-cadastro.component.html',
	styleUrls: ['./fornecedores-cadastro.component.css'],
	providers: [PermissionService, CpfFormatPipe, CnpjFormatPipe]
})
export class FornecedoresCadastroComponent extends BaseCadastroComponent<Fornecedor> implements OnInit {

	@Input('isShowOptions') isShowOptions = true;
	@ViewChild('fornecedorCategoriaContasPesquisaModal') categoriaContasPesquisaModal: CategoriaContasPesquisaModalComponent;
	@ViewChild('fornecedorCategoriaContasPesquisaNaoRateadoModal') categoriaContasPesquisaNaoRateadoModal: CategoriaContasPesquisaModalComponent;
	@ViewChild('planoDeContasCadastroModal') planoDeContasCadastroModal: PlanoDeContasCadastroModalComponent;

	categoriasContas: CategoriaContas[] = [];

	constructor(activatedRoute: ActivatedRoute,
			router: Router,
			private authService: AuthService,
			messageService: MessageService,
			private fornecedoresHttpService: FornecedoresHttpService,
			private categoriaContasHttpService: CategoriaContasHttpService,
			permissionService: PermissionService,
			private cpfFormatPipe: CpfFormatPipe,
			private cnpjFormatPipe: CnpjFormatPipe,
			private formsUtilsService: FormsUtilsService) {
		super(Fornecedor, fornecedoresHttpService, activatedRoute, router, messageService, permissionService);
	}

	ngOnInit() {
		this.getAllCategoriasByCondominio(this.authService.getIdCurrentCondominio());

		if (this.isShowOptions) {
			super.ngOnInit();
		} else {
			this.initEntity();
		}

		setTimeout(() => {
			if (!this.entity.telefones) {
				this.entity.telefones = [];
			}
			this.addTelefone();
		}, 500);
	}

	initEntity() {
		if (this.entity == null) {
			this.entity = new Fornecedor();
			this.editMode = false;
			this.afterInsert();
			this.getPermissions();
		}
	}

	public onChangeCpf(event: any): void {
		this.entity.cpf = this.cpfFormatPipe.transform(event);
		if (this.entity.cpf && this.entity.cpf.length === 14) {
			jQuery('#telefone_0').focus();
		}
	}

	public onChangeCpfContato(event: any): void {
		this.entity.cpf_contato = this.cpfFormatPipe.transform(event);
		if (this.entity.cpf_contato && this.entity.cpf_contato.length === 14) {
			jQuery('#dataNascimentoContato').focus();
		}
	}

	public onChangeCnpj(event: any): void {
		this.entity.cnpj = this.cnpjFormatPipe.transform(event);
		if (this.entity.cnpj && this.entity.cnpj.length === 18) {
			jQuery('#telefone_0').focus();
		}
	}

	/** Carrega as categorias de contas do plano de contas cadastrado na config. do condomínio */
	private getAllCategoriasByCondominio(idCondominio: number) {
		this.categoriasContas = [];
		if (idCondominio) {
			this.categoriaContasHttpService.getAllByCondominio(idCondominio)
				.then(entities => this.categoriasContas = entities)
				.catch(() => this.messageService.info(`Configuração do condomínio não encontrada. Configure o condomínio para prosseguir.`, 'Informação'));
		}
	}

	/** Atribui a categoria de conta (Objeto que representa o id da categoria) para o lançamento */
	private setCategoriaContas() {
		if (!this.entity.id_categoria_conta) {
			return;
		}

		this.categoriaContasHttpService.get(this.entity.id_categoria_conta).subscribe(
			entity => {
				this.entity.categoriaContas = entity
			},
			error => this.messageService.error('Erro ao carregar a categoria de contas do lançamento', 'Erro', error)
		);
	}

	public openPlanoDeContasCadastroModal(): void {
		this.planoDeContasCadastroModal.open();
	}

	public onAfterSaveContaCategoriaConta(categoriaConta: CategoriaContas): void {
		this.entity.categoriaContas = categoriaConta;
	}

	/** Atribui a categoria de conta não rateado (Objeto que representa o id da categoria) para o lançamento */
	private setCategoriaContasNaoRateado() {
		if (!this.entity.id_categoria_conta_nao_rateado) {
			return;
		}

		this.categoriaContasHttpService.get(this.entity.id_categoria_conta_nao_rateado).subscribe(
			entity => this.entity.categoriaContasNaoRateado = entity,
			error => this.messageService.error('Erro ao carregar a categoria de contas não rateada do lançamento', 'Erro', error)
		);
	}

	afterEdit(entity: Fornecedor) {
		this.setCategoriaContas();
		this.setCategoriaContasNaoRateado();
		// Caso não tenha um telefone cadastrado, inlcui um campo em branco
		if (entity.telefones.length < 1) {
			this.addTelefone();
		}
	}

	onSaveClick() {
		this.checkTelefones();
		this.save();
	}

	// Ao selecionar a categoria de contas
	onSelectCategoriaContas(tm: TypeaheadMatch) {
		const categoriaContas: CategoriaContas = tm.item as CategoriaContas;
		if (categoriaContas) {
			this.entity.id_categoria_conta = categoriaContas.id;
			this.entity.categoriaContas = categoriaContas;
		} else {
			this.entity.id_categoria_conta = null;
			this.entity.categoriaContas = null;
		}
	}

	beforeSave(): boolean {
		if (this.entity.cnpj) {
			if (!this.formsUtilsService.isValidCnpj(this.entity.cnpj)) {
				this.messageService.info('O CNPJ informado não é um CNPJ válido', ' Informação');
				return false;
			}
		}
		if (this.entity.cpf) {
			if (!this.formsUtilsService.isValidCpf(this.entity.cpf)) {
				this.messageService.info('O CPF informado não é um CPF válido', ' Informação');
				return false;
			}
		}
		this.entity.id_cliente = this.authService.getIdCurrentCliente();
		return true;
	}

	// Ao limpar a categoria de contas
	onCleanCategoriaContas(input: any) {
		this.entity.id_categoria_conta = null;
		this.entity.categoriaContas = null;

		input.value = '';
		input.focus();
	}

	// Abre o modal de pesquisa da categoria de conta
	openCategoriaContasPequisaModal() {
		this.categoriaContasPesquisaModal.open();
	}

	// Ao selecionar a categoria de conta no modal de pesquisa
	onSelectCategoriaContasPesquisaModal(categoriaContas: CategoriaContas) {
		if (categoriaContas) {
			this.entity.id_categoria_conta = categoriaContas.id;
			this.entity.categoriaContas = categoriaContas;
		}
		jQuery('#categoriaContas').focus();
	}


	// Ao selecionar a categoria de contas não rateado
	onSelectCategoriaContasNaoRateado(tm: TypeaheadMatch) {
		const categoriaContas: CategoriaContas = tm.item as CategoriaContas;
		if (categoriaContas) {
			this.entity.id_categoria_conta_nao_rateado = categoriaContas.id;
			this.entity.categoriaContasNaoRateado = categoriaContas;
		} else {
			this.entity.id_categoria_conta_nao_rateado = null;
			this.entity.categoriaContasNaoRateado = null;
		}
	}

	// Ao limpar a categoria de contas não rateado
	onCleanCategoriaContasNaoRateado(input: any) {
		this.entity.id_categoria_conta_nao_rateado = null;
		this.entity.categoriaContasNaoRateado = null;

		input.value = '';
		input.focus();
	}

	// Abre o modal de pesquisa da categoria de conta não rateados
	openCategoriaContasNaoRateadoPequisaModal() {
		this.categoriaContasPesquisaNaoRateadoModal.open();
	}

	// Ao selecionar a categoria de conta não rateado no modal de pesquisa
	onSelectCategoriaContasNaoRateadoPesquisaModal(categoriaContasNaoRateado: CategoriaContas) {
		if (categoriaContasNaoRateado) {
			this.entity.id_categoria_conta_nao_rateado = categoriaContasNaoRateado.id;
			this.entity.categoriaContasNaoRateado = categoriaContasNaoRateado;
		}
		jQuery('#categoriaContasNaoRateado').focus();
	}

	clearDigitsSpecialChars(value) {
		if (value) {
			const anyNonDigitRegExp = /[^0-9]/g
			return value.replace(anyNonDigitRegExp, '')
		}

		return ""
	}

	formatPhone(telefone, value) {
		let rawValue = this.clearDigitsSpecialChars(value)

		let front = 4
		if (rawValue.length > 10) {
			front = 5
		}

		let newValue = ''
		if (rawValue.length > 0) {
			newValue += '(' + rawValue.substr(0, 2)
		}

		if (rawValue.length > 2) {
			newValue += ') ' + rawValue.substr(2, front)
		}

		if (rawValue.length >= 7) {
			newValue += '-' + rawValue.substr(front + 2, 4)
		}

		telefone.numero = newValue;
	}

	public addTelefone(): void {
		this.entity.telefones.push(new Telefone());
	}

	public removeTelefone(telefone): void {
		const index = this.entity.telefones.findIndex(it => it.numero === telefone.numero);
		if (telefone.id) {
			this.fornecedoresHttpService.deleteTelefone(telefone).subscribe(
				response => this.entity.telefones.splice(index, 1),
				error => this.messageService.error('Não foi possível excluir o telefone', 'Erro', error)
			);
		} else {
			this.entity.telefones.splice(index, 1)
		}
	}

	private checkTelefones(): void {
		const telefones = this.entity.telefones;
		const invalidNumbers = telefones.filter(it => !it.numero);
		invalidNumbers.forEach(it => {
			telefones.splice(telefones.indexOf(it), 1);
		});
	}

}