import { Component, OnInit, ViewChild, LOCALE_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseCadastroComponent } from '../../componentes-base';
import { AuthService, MessageService } from '../../core';
import { ReservaAreaHttpService } from './services';
import { PermissionService } from '../../core/services/permission/permission.service';
import { Reserva, Local, Usuario } from '../../models';
import { BlocosHttpService } from '../blocos/services';
import { ClientesHttpService } from '../clientes/services';
import { CondominiosHttpService } from 'app/home/condominios';
import { UnidadesHttpService } from '../blocos/unidades/services/unidades-http.service';
import { LocalHttpService } from 'app/home/local/services';
import { CadastroReservaModalComponent } from './cadastro-reserva-modal';
import { startOfDay, setHours, endOfDay, setMinutes } from 'date-fns';
import { Subject } from 'rxjs';
import { CalendarEvent, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { DatePipe, registerLocaleData } from '@angular/common';

import localePt from '@angular/common/locales/pt';
registerLocaleData(localePt);

@Component({
	selector: 'reserva-area',
	templateUrl: 'reserva-area-cadastro.component.html',
	styleUrls: ['./reserva-area-cadastro.component.css'],
	providers: [
		PermissionService,
		ClientesHttpService,
		DatePipe,
		{ provide: LOCALE_ID, useValue: "pt-BR" }
	]
})

export class ReservaAreaCadastroComponent extends BaseCadastroComponent<Reserva> implements OnInit {

	@ViewChild('cadastroReservaModal') cadastroReservaModal: CadastroReservaModalComponent;

	public dateClicked: Date;
	public locais: Local[] = [];
	public reservas: Reserva[] = [];
	public id_condominio: number;
	public editMode: boolean;
	private usuario: Usuario;
	private hoje: Date;

	view: CalendarView = CalendarView.Month;

	CalendarView = CalendarView;
	clickedDate: Date;

	viewDate: Date = new Date();

	modalData: {
		action: string;
		event: CalendarEvent;
	};

	refresh: Subject<any> = new Subject();

	events: CalendarEvent[] = [];

	activeDayIsOpen: boolean = true;

	constructor(public activatedRoute: ActivatedRoute,
		public router: Router,
		public messageService: MessageService,
		public blocosHttpService: BlocosHttpService,
		public clientesHttpService: ClientesHttpService,
		public condominiosHttpService: CondominiosHttpService,
		public unidadesHttpService: UnidadesHttpService,
		private authService: AuthService,
		public reservaAreaHttpService: ReservaAreaHttpService,
		public permissionService: PermissionService,
		private localHttpService: LocalHttpService,
		public datePipe: DatePipe) {
		super(Reserva, reservaAreaHttpService, activatedRoute, router, messageService, permissionService);
	}

	ngOnInit() {
		this.id_condominio = this.authService.getIdCurrentCondominio();
		this.editMode = false;
		super.ngOnInit();
		this.getAllLocalByCondominio();
		this.getAllReserva();
		this.usuario = this.authService.getCurrentUser();
		this.hoje = new Date();
		this.hoje = new Date(this.hoje.getFullYear(), this.hoje.getMonth(), this.hoje.getDate());
	}

	/**
	 * Busca os locais do condomínio
	 * @author Marcos Frare
	 * @since 16/03/2020
	 */
	private getAllLocalByCondominio(): void {
		this.localHttpService.getAllByCondominio(this.id_condominio).subscribe(
			response => this.locais = response,
			error => this.messageService.error('Erro ao carregar os locais do condomínio.', 'Erro', error)
		);
	}

	/**
	 * Busca as reservas
	 * @author Marcos Frare
	 * @since 16/03/2020
	 */
	public getAllReserva(): void {
		const params = {
			id_condominio: this.id_condominio,
			id_local: this.entity && this.entity.id_local ? this.entity.id_local : null,
			periodo: this.viewDate
		}
		this.reservaAreaHttpService.getReservas(params).subscribe(
			response => this.loadEvents(response),
			error => this.messageService.error('Erro ao carregar as reservas!', 'Erro', error)
		);
	}

	/**
	 * Carrega os eventos no calendário
	 * @param dados - Dados de reservas
	 * @author Marcos Frare
	 * @since 16/03/2020
	 */
	private loadEvents(dados: Reserva[]): void {
		this.events = [];
		dados.map(reserva => {
			const horaInicial = {
				hora: reserva.hora_inicial.toString().split(':')[0],
				minuto: reserva.hora_inicial.toString().split(':')[1]
			}
			const horaFinal = {
				hora: reserva.hora_final.toString().split(':')[0],
				minuto: reserva.hora_final.toString().split(':')[1]
			}

			this.events.push({
				id: reserva.id,
				title: reserva.tipo == 'NORMAL' ? reserva.nome_local + ' - ' + reserva.nome_unidade : '* ' + reserva.nome_local,
				start: setHours(setMinutes(reserva.data_reserva, parseInt(horaInicial.minuto)), parseInt(horaInicial.hora)),
				end: setHours(setMinutes(reserva.data_reserva, parseInt(horaFinal.minuto)), parseInt(horaFinal.hora)),
				resizable: {
					beforeStart: true,
					afterEnd: true
				}
			});
		});
	}

	/**
	 * Edição de uma reserva
	 * @param evento 
	 * @author Marcos Frare
	 * @since 16/03/2020
	 */
	public editEvento(evento: Reserva): void {
		this.editMode = true;
		this.entity.id = evento.id;
	}

	/**
	 * Valida a efetivação da reserva
	 * @author Marcos Frare
	 * @since 25/03/2020
	 */
	private validaReserva(): boolean {
		if (this.usuario.tipo === 3 && !this.editMode) {
			if (this.cadastroReservaModal.dataReserva < this.hoje) {
				this.messageService.warning('Selecione uma data igual ou posterior ao dia de hoje!', 'Aviso!');
				return false;
			}
		}
		return true;
	}

	// Calendar
	public dayClicked(event: any): void {
		if (this.editMode) {
			this.cadastroReservaModal.idReserva = this.entity.id;
		}
		this.dateClicked = event.date;
		this.cadastroReservaModal.dataReserva = event.date;
		this.cadastroReservaModal.idLocal = this.entity.id_local || null;
		// Valida se o usuário pode efetuar a reserva
		if (this.validaReserva()) {
			this.cadastroReservaModal.open();
		}
		this.editMode = false;
		this.cadastroReservaModal.dataReserva = null;
		this.entity = new Reserva();
	}

	public eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
		this.events = this.events.map(iEvent => {
			if (iEvent === event) {
				return {
					...event,
					start: newStart,
					end: newEnd
				};
			}
			return iEvent;
		});
		this.handleEvent('Dropped or resized', event);
	}

	public handleEvent(action: string, event: CalendarEvent): void {
		this.modalData = { event, action };
	}

	public setView(view: CalendarView) {
		this.view = view;
	}

	public closeOpenMonthViewDay() {
		this.activeDayIsOpen = false;
		// Carrega reservas no calendário
		this.getAllReserva();
	}

	public addEvent(): void {
		this.events = [
			...this.events,
			{
				title: '',
				start: startOfDay(this.dateClicked),
				end: endOfDay(this.dateClicked),
				draggable: true,
				resizable: {
					beforeStart: true,
					afterEnd: true
				}
			}
		];
	}

	public deleteEvent(eventToDelete: CalendarEvent) {
		this.events = this.events.filter(event => event !== eventToDelete);
	}
}