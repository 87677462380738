import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseCadastroComponent } from '../../../componentes-base';
import { AuthService, MessageService } from '../../../core';
import { Condominio, ContaBancaria, tiposContasBancarias, Banco, AutorizacaoContaBancaria } from '../../../models';
import { ContasBancariasHttpService } from '../services';
import { CondominiosHttpService } from '../../condominios';
import { PermissionService } from '../../../core/services/permission/permission.service';
import { CpfFormatPipe } from '../../../shared/pipes/cpf-format';
import { ConfiguracaoTarifaBancariaModalComponent } from '../configuracao-tarifa-bancaria-modal';
import { BancoHttpService } from 'app/home/banco';
import { ConfiguracaoCobrancaModalComponent } from '../configuracao-cobranca-modal';
import { AutorizacaoContaBancariaModalComponent } from './autorizacao-conta-bancaria-modal';

declare var jQuery: any;

@Component({
	selector: 'contas-bancarias-cadastro',
	templateUrl: './contas-bancarias-cadastro.component.html',
	styleUrls: ['./contas-bancarias-cadastro.component.css'],
	providers: [
		CondominiosHttpService,
		PermissionService,
		CpfFormatPipe
	]
})
export class ContasBancariasCadastroComponent extends BaseCadastroComponent<ContaBancaria> {

	@ViewChild('configuracaoTarifaBancariaModal') configuracaoTarifaBancariaModal: ConfiguracaoTarifaBancariaModalComponent;
	@ViewChild('configuracaoCobrancaModal') configuracaoCobrancaModal: ConfiguracaoCobrancaModalComponent;
	@ViewChild('autorizacaoContaBancariaModal') autorizacaoContaBancariaModal: AutorizacaoContaBancariaModalComponent;
	@Input('isShowOptions') isShowOptions = true;

	condominio: Condominio;
	bancos: Banco[];
	tipos = tiposContasBancarias;
	public isAutorization = false;
	public isKey = false;

	constructor(public activatedRoute: ActivatedRoute,
			public router: Router,
			public messageService: MessageService,
			private authService: AuthService,
			private bancoHttpService: BancoHttpService,
			private contasBancariasHttpService: ContasBancariasHttpService,
			private condominiosHttpService: CondominiosHttpService,
			private cpfFormatPipe: CpfFormatPipe,
			public permissionService: PermissionService) {
		super(ContaBancaria, contasBancariasHttpService, activatedRoute, router, messageService, permissionService);
		this.getAllBanco();
	}

	/** Carrega o condomínio que é o "dono" do lançamento **/
	public getCondominio(idCondominio: number) {
		this.condominiosHttpService.get(idCondominio).subscribe(
			entity => this.condominio = entity,
			error => this.messageService.error('Erro ao carregar o condomínio', 'Erro', error)
		);
	}

	/**
	 * Busca todos os bancos
	 * @author Marcos Frare
	 * @since 16/02/2021
	 */
	public getAllBanco() {
		this.bancoHttpService.getAll().subscribe(
			res => this.bancos = res,
			error => this.messageService.error('Erro ao buscar bancos!', 'Erro', error)
		);
	}

	afterEdit(entity: ContaBancaria) {
		this.isAutorization = false;
		this.isKey = false;
		if (entity.banco && entity.banco.integra_api) {
			if (!entity.autorizacao || !entity.autorizacao.code) {
				this.isAutorization = ['AILOS'].includes(entity.banco.tag);
			}
			this.isKey = ['SICREDI','SICREDIV2','SICOOB'].includes(entity.banco.tag);
		}
		this.getCondominio(this.entity.id_condominio);
	}

	afterInsert() {
		this.entity.id_condominio = this.authService.getIdCurrentCondominio();
		this.getCondominio(this.entity.id_condominio);
	}

	beforeSave(): boolean {
		this.btnSave = jQuery('#btnSave');
		return super.beforeSave();
	}

	public onChangeNumero(): void {
		if (this.entity.numero || this.entity.cedente) {
			this.entity.numero = this.cpfFormatPipe.clearDigitsSpecialChars(this.entity.numero);
			this.entity.cedente = this.cpfFormatPipe.clearDigitsSpecialChars(this.entity.cedente);
		}
	}

	/**
	 * Evento de alteração do banco
	 * @author Marcos Frare
	 * @since 23/03/2021
	 */
	public changeBanco(): void {
		const selBanco = this.bancos.filter(ban => ban.id == this.entity.id_banco);
		this.entity.banco = selBanco.length ? selBanco[0] : null;
	}

	/**
	 * Integração OAuth2
	 * @author Marcos Frare
	 * @since 28/05/2020
	 */
	public integrar(): void {
		let btn = jQuery('#btnIntegrar');
		btn.button('loading');
		this.contasBancariasHttpService.getUrlAutorize(this.entity.id).subscribe(
			res => {
				btn.button('reset');
				if (res.url_autorizacao) {
					window.open(res.url_autorizacao, "integrarBanco", "height=500,width=700,left=" + (window.innerWidth - 700) / 2 + ",top=" + (window.innerHeight - 500) / 2);
				} else {
					this.messageService.success('Conta integrada com sucesso!');
				}
			},
			error => {
				btn.button('reset');
				this.messageService.error('Erro ao gerar acesso para integração! Tente mais tarde.', 'Erro', error);
			}
		);

		// switch (this.entity.banco) {
		//     case '085': {
		//         this.contasBancariasHttpService.getUrlAutorize(this.entity.id).subscribe(
		//             res => {
		//                 window.open(res.url_autorizacao, "integrarBanco", "height=400,width=600,left="+(window.innerWidth-600)/2+",top="+(window.innerHeight-400)/2);
		//             }
		//         );
		//         break;
		//     }
		//     case '756': {
		//         window.open(`http://localhost:8000/contabancaria/integrar/${this.entity.id}`, "integrarBanco", "height=400,width=600,left="+(window.innerWidth-600)/2+",top="+(window.innerHeight-400)/2);
		//         break;
		//     }
		//     default: {
		//         this.messageService.info('Banco ainda não homologado para integração!', 'Informação');
		//     }
		// }
	}

	public autorizacao(): void {
		this.autorizacaoContaBancariaModal.open();
	}

	/**
	 * Abrir modal para configurar lançamentos manuais de tarifas bancárias
	 * @author Marcos Frare
	 * @since 09/07/2020
	 */
	public tarifaBancaria(): void {
		this.configuracaoTarifaBancariaModal.open();
	}

	/**
	 * Abrir modal de configuração de cobranças
	 * @author Marcos Frare
	 * @since 19/07/2022
	 */
	public openConfiguracaoCobranca(): void {
		this.configuracaoCobrancaModal.open();
	}

	public onSaveAutorizacaoContaBancaria(event: AutorizacaoContaBancaria): void {
		this.isAutorization = false;
		this.isKey = false;
	}
	
}