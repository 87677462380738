import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared';

import { NotasModalModule } from '../../componentes-utils/notas-modal';
import { FornecedoresModule } from '../fornecedores';
import { PlanoDeContasModule } from '../plano-de-contas';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { LancamentosCondominioHttpService } from './services';
import { LancamentoPagamentosModule } from 'app/componentes-utils/lancamento-pagamentos';
import { ParcelaDespesaRateioModule } from 'app/componentes-utils/parcela-despesa-rateio';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { TemplateLancamentoCondominioComponent, TemplateLancamentoCondominioCadastroModalComponent } from './template-lancamento-condominio';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { UiSwitchModule } from 'ngx-ui-switch';
import { GeracaoDespesaFixaModalComponent } from './geracao-despesa-fixa-modal';
import { PopoverModule } from 'ngx-popover';
import { AjusteParcelaPagamentoModalComponent, LancamentoCondominioItemComponent, LancamentosCondominioCadastroComponent } from './cadastro';
import { LancamentosCondominioComponent } from './lancamentos-condominio.component';
import { LancamentoCondominioAnexoModalComponent } from './lancamento-condominio-anexo-modal';

@NgModule({
	imports: [
		SharedModule,
		NotasModalModule,
		FornecedoresModule,
		PlanoDeContasModule,
		TypeaheadModule,
		LancamentoPagamentosModule,
		ParcelaDespesaRateioModule,
		MultiselectDropdownModule,
		UiSwitchModule,
		PopoverModule,
	],
	declarations: [
		LancamentosCondominioComponent,
		LancamentosCondominioCadastroComponent,
		TemplateLancamentoCondominioComponent,
		TemplateLancamentoCondominioCadastroModalComponent,
		GeracaoDespesaFixaModalComponent,
		LancamentoCondominioAnexoModalComponent,
		LancamentoCondominioItemComponent,
		AjusteParcelaPagamentoModalComponent,
	],
	providers: [
		LancamentosCondominioHttpService,
		PermissionService
	],
	exports: [
		LancamentoCondominioAnexoModalComponent
	]
})
export class LancamentosCondominioModule { }