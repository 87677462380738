import { BaseEntity } from '../base-entity';

export class ConsumoUnidade extends BaseEntity {
	consumo_anterior: number;
	consumo_atual: number;
	consumo_atual_copy: number;
	data_competencia: any;
	valor: any;
	id_tipo_consumo: number;
	id_unidade: number;
	nomePessoa: string;
	dataConsumo: any;
	loading: boolean;
	tolerancia: any;

	selected: boolean;
	editConsumoAnterior: boolean;
}