import { Component, Input, Output, OnInit, EventEmitter} from '@angular/core';

import { AuthService, MessageService } from '../../../core';
import { BaseModal } from '../../../componentes-utils/modal';
import { Usuario } from '../../../models';
import { UsuariosHttpService } from '../services';

declare var jQuery: any;

@Component({
    selector: 'usuarios-pesquisa-modal',
    templateUrl: './usuarios-pesquisa-modal.component.html',
    styleUrls: ['./usuarios-pesquisa-modal.component.css']
})
export class UsuariosPesquisaModalComponent implements OnInit, BaseModal {
    @Input() idModal: string;
    @Input() data: any;
    @Output() select = new EventEmitter<any>();
    public arrayPosition: number;
    public idCliente: number;
    public idCondominio: number;
    public idUsuario: number;
    entities: Usuario[] = [];

    constructor(private authService: AuthService,
                private messageService: MessageService,
                private usuariosHttpService: UsuariosHttpService) {
        
        this.idUsuario = this.authService.getIdCurrentUser();
    }

    ngOnInit() {
        this.idCliente = this.authService.getIdCurrentCliente();
        this.idCondominio = this.authService.getIdCurrentCondominio();
        if (!this.idModal) {
            this.idModal = 'usuariosPesquisaModal';
        }
    }

    private loadUsuarios() {
        this.entities = [];
        let filterParams = {
			id_cliente: this.idCliente, 
			tipo: 3, 
			id_condominio: this.idCondominio, 
			id_usuario: this.idUsuario, 
			bloco: false, 
			unidade: false
		};
        this.usuariosHttpService.getAllByClienteCondominioAndTipo(filterParams).subscribe(
            entities => this.entities = entities,
            () => this.messageService.info(`Configuração do condomínio não encontrada. Configure o condomínio para prosseguir.`, 'Informação')
        )
    }

    open(position?: number) {
        this.arrayPosition = position;
        this.loadUsuarios();
        jQuery(`#${this.idModal}`).modal('show');
    }

    close() {
        jQuery(`#${this.idModal}`).modal('hide');
    }

    selectClick(usuario: Usuario) {
        if(this.arrayPosition > -1){
            let event = {entity:{}, position:{}, data:{}};
            event.entity = usuario;
            event.position = this.arrayPosition;
            event.data = this.data
            this.select.emit(event);
        } else {
            this.select.emit(usuario);
        }
        this.close();
    }
}
