import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Unidade, Bloco, TipoConsumo } from 'app/models';
import { ConsumosRelFiltro } from './consumos-rel-filtro';
import { RelatorioModalComponent } from 'app/home/relatorios/relatorio-modal';
import { BlocosPesquisaModalComponent, UnidadesHttpService, BlocosHttpService } from 'app/home/blocos';
import { IMultiSelectSettings, IMultiSelectOption, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import { datepickerMesAnoOptions } from 'app/shared';
import { AuthService, MessageService } from 'app/core';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { TiposConsumoHttpService } from 'app/home/tipos-consumo';

declare var jQuery: any;

@Component({
  selector: 'consumos-rel',
  templateUrl: './consumos-rel.component.html',
  styleUrls: ['./consumos-rel.component.css']
})
export class ConsumosRelComponent implements OnInit {

  public unidades: Unidade[] = [];
  public blocos: Bloco[] = [];
  public filtro = new ConsumosRelFiltro();
  public bloco: Bloco;
  public showInputBlocos: boolean = true;
  public tiposConsumo: TipoConsumo[] = [];

  @ViewChild('relatorioModal') relatorioModal: RelatorioModalComponent;
  @ViewChild('inputBloco') inputBloco: ElementRef;
  @ViewChild('blocosPesquisaModal') blocosPesquisaModal: BlocosPesquisaModalComponent;

  public datepickerDataCompetenciaOptions = datepickerMesAnoOptions;

  multiselectSettings: IMultiSelectSettings = {
    enableSearch: true,
    containerClasses: 'container-multiselect',
    showUncheckAll: true,
    showCheckAll: true,
    dynamicTitleMaxItems: 3,
    checkedStyle: 'checkboxes',
    buttonClasses: 'btn btn-default',
    itemClasses: 'dropdown-menu'
  };

  multiselectOptionsUnidades: IMultiSelectOption[];
  multiselectTextsUnidades: IMultiSelectTexts = {
    checkAll: 'Selecionar todas',
    uncheckAll: 'Limpar',
    checked: 'selecionada',
    checkedPlural: 'selecionadas',
    searchPlaceholder: 'Pesquisar...',
    defaultTitle: 'Selecionar',
    allSelected: 'Todas selecionadas',
    searchEmptyResult: 'Nenhum registro encontrado',
    searchNoRenderText: 'Digite para pesquisar',
  };

  multiselectSettingsTiposConsumo: IMultiSelectSettings = {
    enableSearch: false,
    containerClasses: 'container-multiselect',
    showUncheckAll: false,
    showCheckAll: false,
    dynamicTitleMaxItems: 1,
    checkedStyle: 'checkboxes',
    buttonClasses: 'btn btn-default',
    itemClasses: 'dropdown-menu'
  };

  multiselectOptionsTiposConsumo: IMultiSelectOption[];
  multiselectTextsTiposConsumo: IMultiSelectTexts = {
    checkAll: 'Selecionar todas',
    uncheckAll: 'Limpar',
    checked: 'selecionada',
    checkedPlural: 'selecionadas',
    searchPlaceholder: 'Pesquisar...',
    defaultTitle: 'Selecionar',
    allSelected: 'Todas selecionadas',
    searchEmptyResult: 'Nenhum registro encontrado',
    searchNoRenderText: 'Digite para pesquisar',
  };

  constructor(private authService: AuthService,
    private messageService: MessageService,
    private unidadesHttpService: UnidadesHttpService,
    private blocosHttpService: BlocosHttpService,
    private tiposConsumoHttpService: TiposConsumoHttpService) { }

  ngOnInit() {
    this.limpar();
    this.filtro.id_cliente = this.authService.getIdCurrentCliente();
    this.filtro.id_condominio = this.authService.getIdCurrentCondominio();; 
    this.filtro.id_usuario = this.authService.getIdCurrentUser();
    this.getBlocosByCondominio(this.filtro.id_condominio);
    this.getTiposConsumo();
    this.getAllUnidades();
  }

  private getTiposConsumo(): void {
    this.tiposConsumoHttpService.getConfiguradasByCondominio(this.filtro.id_condominio).subscribe(
      response => {
        const selects: IMultiSelectOption[] = [];
        response.forEach(entity => {
          selects.push({
            id: entity.id_tipo_consumo,
            name: `${entity.nome}`
          });
        });
        this.multiselectOptionsTiposConsumo = selects;
      },
      error => this.messageService.error('Erro ao carregar os tipos de consumo do condomínio. Tente novamente', 'Erro', error)
    );
  }

  public limpar(): void {
    this.filtro.limpar();
    this.bloco = null;
  }

  private validateView(): boolean {
    if (!this.filtro.competencia_inicial) {
      this.messageService.info('É necessário informar a competência inicial para imprimir relatório', 'Informação');
      return false;
    }
    return true;
  }

  public view(): void {
    if (this.validateView()) {
      this.relatorioModal.url = '/relatorio/consumos';
      this.relatorioModal.params = this.filtro;
      this.relatorioModal.open();
    }
  }

  private getBlocosByCondominio(idCondominio: number) {
    this.blocosHttpService.getAllByCondominio(idCondominio).subscribe(
      entitites => {
        this.blocos = entitites
        if (this.blocoUnico(this.blocos)) {
          this.setBloco(this.blocos[0]);
          this.showInputBlocos = false;
        } else if (!this.blocos) {
          this.messageService.error('Não existem blocos cadastrados no Condomínio. Cadastre  um bloco e tente novamente', 'Erro')
        }
      },
      error => this.messageService.error('Erro ao carregar os blocos do condomínio. Tente novamente', 'Erro', error)
    );
  }

  private setBloco(blocoSelecionado: Bloco) {
    this.bloco = blocoSelecionado;
    this.filtro.id_bloco = blocoSelecionado.id;
    this.filterUnidades();
    this.onCleanUnidades();
  }

  /**
   * Busca todas as unidades do codomínio
   * @author Marcos Frare
   * @since 24/04/2020
   */
  private getAllUnidades(): void {
    this.unidadesHttpService.getAllByCondominioBloco(this.filtro.id_condominio, null).subscribe(
      response => {
        this.unidades = response;
        this.filterUnidades();
      },
      error => this.messageService.error('Erro ao carregar as unidades do condomínio. Tente novamente', 'Erro', error)
    );
  }

  /**
   * Filtra as unidades com base no campo de bloco
   * @author Marcos Frare
   * @since 24/04/2020
   */
  private filterUnidades(): void {
    const selects: IMultiSelectOption[] = [];
    let arr = this.unidades;
    if (this.bloco && this.bloco.id) {
      arr = this.unidades.filter(und => und.id_bloco === this.bloco.id);
    }
    arr.forEach(entity => {
      selects.push({
        id: entity.id,
        name: `${entity.nome_bloco} - ${entity.nome_unidade}`
      });
    });
    this.multiselectOptionsUnidades = selects;
  }

  onCleanUnidades() {
    this.filtro.ids_unidades = [];
  }

  onSelectBloco(tm: TypeaheadMatch): void {
    this.setBloco(tm.item as Bloco);
  }

  openBlocosPequisaModal() {
    this.blocosPesquisaModal.open();
  }

  onSelectBlocosPesquisaModal(bloco: Bloco) {
    if (bloco) {
      this.setBloco(bloco)
    }
    jQuery('#bloco').focus();
  }

  onCleanBloco(input: any) {
    this.bloco = null;
    this.filtro.id_bloco = null;
    input.value = '';
    this.filterUnidades();
  }

  private blocoUnico(blocos: Bloco[]): boolean {
    return blocos.length === 1;
  }

}