import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { ReservaAreaHttpService } from './services';

import { FlatpickrModule } from 'angularx-flatpickr';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxEditorModule } from 'ngx-editor';
import { ReservaAreaCadastroComponent } from './reserva-area-cadastro.component';
import { CadastroReservaModalComponent } from './cadastro-reserva-modal';
import { AcessosPainelHttpService } from '../grupos-de-usuarios/acessos-painel/services';

@NgModule({
	imports: [
		SharedModule,
		TypeaheadModule,
		BrowserAnimationsModule,
		NgxEditorModule,
		FlatpickrModule.forRoot(),
		CalendarModule.forRoot({
			provide: DateAdapter,
			useFactory: adapterFactory
		}),
	],
	declarations: [
		ReservaAreaCadastroComponent,
		CadastroReservaModalComponent
	],
	providers: [
		ReservaAreaHttpService,
		AcessosPainelHttpService
	]
})
export class ReservaAreaModule { }