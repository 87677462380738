import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseVisualizacaoComponent } from 'app/componentes-base';
import { AuthService, MessageService } from 'app/core';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { CategoriaContaGrupo } from 'app/models';
import { NgxSpinnerService } from 'ngx-spinner';
import { CategoriaContaGrupoCadastroModalComponent } from './categoria-conta-grupo-cadastro-modal';
import { CategoriaContaGrupoHttpService } from './service';

@Component({
  selector: 'categoria-conta-grupo',
  templateUrl: './categoria-conta-grupo.component.html',
  styleUrls: ['./categoria-conta-grupo.component.css']
})
export class CategoriaContaGrupoComponent extends BaseVisualizacaoComponent<CategoriaContaGrupo> {

  @ViewChild('categoriaContaGrupoCadastroModal') categoriaContaGrupoCadastroModal: CategoriaContaGrupoCadastroModalComponent;

  constructor(public activatedRoute: ActivatedRoute,
      public router: Router,
      public messageService: MessageService,
      private authService: AuthService,
      private categoriaContaGrupoHttpService: CategoriaContaGrupoHttpService,
      public permissionService: PermissionService,
      public spinner: NgxSpinnerService) {
    super('#tableCategoriaContaGrupo', categoriaContaGrupoHttpService, activatedRoute, router, messageService, permissionService, spinner);
  }

  ngOnInit() {
    this.search();
  }

  /**
   * Excluir um grupo
   * @param entity Objeto a ser excluído
   * @author Marcos Frare
   * @since 10/04/2021
   */
   public delete(entity: CategoriaContaGrupo): void {
    if (confirm(`Deseja excluir o grupo ${entity.nome}?`)) {
      this.categoriaContaGrupoHttpService.delete(entity).subscribe(
        response => {
          if (response.error) {
            this.messageService.warning(response.msg, 'Atenção!');
          } else {
            this.search();
            this.messageService.success('Grupo excluído com sucesso!', 'Sucesso!');
          }
        },
        error => {
          this.messageService.error(`Erro ao excluir grupo ${entity.nome}!`, 'Erro', error);
        }
      );
    }
  }

  /**
   * Busca dados
   * @author Marcos Frare
   * @since 10/04/2021
   */
  public search(): void {
    super.getAll();
  }

  /**
   * Editar registro
   * @param entity 
   * @author Marcos Frare
   * @since 10/04/2021
   */
  public edit(entity: CategoriaContaGrupo): void {
    this.categoriaContaGrupoCadastroModal.id = entity.id;
    this.categoriaContaGrupoCadastroModal.open();
  }

  /**
   * Incluir novo grupo
   * @author Marcos Frare
   * @since 10/04/2021
   */
  public insert(): void {
    this.categoriaContaGrupoCadastroModal.id = null;
    this.categoriaContaGrupoCadastroModal.open();
  }

}