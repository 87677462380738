import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { ArquivosRemessaComponent } from './arquivos-remessa.component';
import { ArquivosRemessaHttpService } from './services';
import { LancamentosUnidadesModule } from '../lancamentos-unidades';

@NgModule({
    imports: [
        SharedModule,
        TypeaheadModule,
        LancamentosUnidadesModule
    ],
    declarations: [
        ArquivosRemessaComponent,
        ArquivosRemessaComponent,
    ],
    providers: [
        ArquivosRemessaHttpService,
        ArquivosRemessaHttpService,
    ]
})
export class ArquivosRemessaModule { }
