import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseVisualizacaoComponent } from 'app/componentes-base';
import { AuthService, MessageService } from 'app/core';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { Relatorio } from 'app/models';
import { NgxSpinnerService } from 'ngx-spinner';
import { RelatorioFiltroModalComponent } from './relatorio-filtro-modal/relatorio-filtro-modal.component';
import { RelatorioHttpService } from './service';

@Component({
  selector: 'relatorio',
  templateUrl: './relatorio.component.html',
  styleUrls: ['./relatorio.component.css']
})
export class RelatorioComponent extends BaseVisualizacaoComponent<Relatorio> {

  @ViewChild('relatorioFiltroModal') relatorioFiltroModal: RelatorioFiltroModalComponent;

  constructor(public activatedRoute: ActivatedRoute,
      public router: Router,
      public messageService: MessageService,
      private authService: AuthService,
      private relatorioHttpService: RelatorioHttpService,
      public permissionService: PermissionService,
      public spinner: NgxSpinnerService) {
    super('#tableRelatorio', relatorioHttpService, activatedRoute, router, messageService, permissionService, spinner);
  }

  ngOnInit() {
    if(!this.authService.isAdmin) {
      this.router.navigate(['/dashboard'], {relativeTo: this.activatedRoute});
    }
    super.getAll();
  }

  /**
   * Excluir um relatório
   * @param entity Objeto a ser excluído
   * @author Marcos Frare
   * @since 05/12/2020
   */
  public delete(entity: Relatorio): void {
    if (confirm(`Deseja excluir o relatório ${entity.nome}?`)) {
      this.relatorioHttpService.delete(entity).subscribe(
        () => {
          super.getAll();
          this.messageService.success('Relatório excluído com sucesso!', 'Info');
        },
        error => {
          this.messageService.error(`Erro ao excluir o relatório ${entity.nome}!`, 'Erro', error);
        }
      );
    }
  }

  /**
   * Abrir modal de configitação dos filtros do relatório
   * @param entity 
   * @author Marcos Frare
   * @since 08/12/2020
   */
  public filtros(entity: Relatorio): void {
    this.relatorioFiltroModal.relatorio = entity;
    this.relatorioFiltroModal.open();
  }

}