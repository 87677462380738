import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe para filtar o síndico nos membros do conselho de um condomínio
 */
@Pipe({
    name: 'sindicoFilter',
    pure: false
})
export class SindicioFilterPipe implements PipeTransform {

    transform(values: any[]): any[] {
         if (!values) {
             return [];
         }
         return values.filter(value => {
            return value.tipo === 1; // Síndico
         });
    }
}
