import { Component, Input } from '@angular/core';

@Component({
    selector: 'form-control-error',
    templateUrl: './form-control-error.component.html',
    styleUrls: ['./form-control-error.component.css']
})
export class FormControlErroComponent {
    @Input() msgError: string;
}
