import { Injectable } from '@angular/core';
import { ConfigService, CustomHttp } from 'app/core';
import { ComparativoPrevisaoReceitaDespesaRelFiltro } from '../comparativo-previsao-receita-despesa-rel-filtro';
import { catchError, map } from 'rxjs/operators';
import { _throw } from 'rxjs-compat/observable/throw';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComparativoPrevisaoReceitaDespesaHttpService {

  public resource = '/relatorio/comparativo-previsao-receita-despesa';
  private baseUrl: string;

  constructor(private customHttp: CustomHttp, private configService: ConfigService) {
    this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
  }

  /**
   * Buscar dados para o relatório em tela
   * @param filtro
   * @author Marcos Frare
   * @since 11/07/2023
   */
  public getDados(filtro: ComparativoPrevisaoReceitaDespesaRelFiltro): Observable<any> {
    return this.customHttp.post(`${this.baseUrl}/expandido`, filtro)
      .pipe(
        map(response => response.json()),
        catchError(error => _throw(error))
      );
  }
}
