/**
 * Classe utilizada nos pagamentos específicos a serem rateados
 */
export class PagamentoEspecificoRateio {
    id: number;
    checado: boolean;
    numero_parcela: number;
    valor_pago: number;
    tipo: number;
    pago: boolean;
    valor: string;
    data_competencia: string;
    id_lancamento_especifico: number;
    id_demonstrativo_rateio: number;
    nome_categoria_conta: string;
    nome_lancamento_especifico: string;
}
