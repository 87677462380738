import { BaseEntity } from '../base-entity';

export class CategoriaContas extends BaseEntity {
	id_categoria_conta_grupo: number;
	codigo: string;
	nome: string;
	nome_nao_rateado: string;
	observacao: string;
	data_lancamento: Date;
	data_saldo: Date;
	saldo: any;
	saldo_inicial: any;
	id_categoria_conta: number;
	id_categoria_fundo_reserva: number;
	id_plano_de_conta: number;
	id_categoria_conta_pai: number;
	id_fornecedor: number;
	tipo_conta: number;
	ativo: boolean;
	categoria_contas_filhas: CategoriaContas[];
	tipo_divisao_rateio: number;

	constructor() {
		super();
		this.ativo = true;
		this.categoria_contas_filhas = [];
	}
}