import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MessageService, AuthService } from 'app/core';
import { datepickerMesAnoOptions } from 'app/shared';
import { TypeaheadMatch } from 'ngx-bootstrap';

import { Bloco, Unidade, Local, Condominio } from 'app/models';
import { BlocosPesquisaModalComponent, UnidadesPesquisaModalComponent, BlocosHttpService, UnidadesHttpService } from 'app/home/blocos';
import { LocalHttpService } from 'app/home/local';
import { RelatorioModalComponent } from 'app/home/relatorios/relatorio-modal';
import { RelatorioReservasFiltro } from './relatorio-reservas-filtro';
import { CondominiosHttpService } from 'app/home/condominios';

declare var jQuery: any;

@Component({
  selector: 'relatorio-reservas',
  templateUrl: './relatorio-reservas.component.html',
  styleUrls: ['./relatorio-reservas.component.css'],
  providers: [
    UnidadesHttpService,
  ]
})
export class RelatorioReservasComponent implements OnInit {

  @ViewChild('relatorioModal') relatorioModal: RelatorioModalComponent;
  @ViewChild('inputBloco') inputBloco: ElementRef;
  @ViewChild('inputUnidade') inputUnidade: ElementRef;
  @ViewChild('blocosPesquisaModal') blocosPesquisaModal: BlocosPesquisaModalComponent;
  @ViewChild('unidadesPesquisaModal') unidadesPesquisaModal: UnidadesPesquisaModalComponent;

  public filtro = new RelatorioReservasFiltro();
  public locais: Local[] = [];
  public blocos: Bloco[] = [];
  public unidades: Unidade[] = [];
  public condominios: Condominio[] = [];
  public bloco: Bloco;
  public unidade: Unidade;

  public mostraCondominio: boolean = false;

  public datepickerDataCompetenciaOptions = datepickerMesAnoOptions;

  constructor(private messageService: MessageService,
              private authService: AuthService,
              private unidadesHttpService: UnidadesHttpService,
              private blocosHttpService: BlocosHttpService,
              private localHttpService: LocalHttpService,
              private condominioHttpService: CondominiosHttpService) { }

  ngOnInit() {
    this.limpar();
    this.filtro.id_cliente = this.authService.getIdCurrentCliente();
    this.filtro.id_condominio = this.authService.getIdCurrentCondominio();
    this.filtro.id_usuario = this.authService.getIdCurrentUser();
    this.mostraCondominio = this.filtro.id_condominio ? false : true;
    if (this.mostraCondominio) {
      this.getAllCondominios();
    } else {
      this.getBlocosByCondominio();
    }
    this.getAllLocal();
  }

  /**
   * Busca os condomínios do cliente
   * @author Marcos Frare
   * @since 26/11/2021
   */
  private getAllCondominios(): void {
    this.condominioHttpService.getAllByCliente(this.filtro.id_cliente).subscribe(
      response => {
        this.condominios = response;
      },
      error => {
        this.messageService.error('Erro ao buscar condomínios do cliente! Tente novamente', error)
      }
    )
  }

  /**
   * Ação de alteração do campo condomínio
   * @author Marcos Frare
   * @since 26/11/2021
   */
  public changeCondominio(): void {
    this.getAllLocal();
  }
  
  /**
   * Limpar filtros
   */
  public limpar(): void {
    if (this.filtro.id_condominio) {
      this.onCleanBloco(this.inputBloco.nativeElement);
    }
    this.filtro.limpar();
    this.filtro.id_condominio = this.authService.getIdCurrentCondominio();
  }

  /**
   * Busca os locais
   */
  private getAllLocal(): void {
    this.locais = [];
    let fn = this.filtro.id_condominio ? this.localHttpService.getAllByCondominio(this.filtro.id_condominio) : this.localHttpService.getAllByCliente(this.filtro.id_cliente);
    fn.subscribe(
      response => {
        this.locais = response;
      },
      error => this.messageService.error('Erro ao carregar os locais do condomínio. Tente novamente', 'Erro', error)
    );
  }

  private validateView(): boolean {
    if (this.filtro.tipoFiltro) {
      if (this.filtro.tipoFiltro === 1) {
        if (!this.filtro.competencia) {
          this.messageService.info('É necessário informar a competência para imprimir relatório', 'Informação');
          return false;
        }
      } else {
        if (!this.filtro.data_inicial) {
          this.messageService.info('É necessário informar a data inicial para imprimir relatório', 'Informação');
          return false;
        }
        if (!this.filtro.data_final) {
          this.messageService.info('É necessário informar a data final para imprimir relatório', 'Informação');
          return false;
        }
      }
    } else {
      this.messageService.info('É necessário Tipo para filtro de período', 'Informação');
      return false;
    }

    return true;
  }

  public view(): void {
    if (this.validateView()) {
      this.relatorioModal.url = '/relatorio/reservas';
      this.relatorioModal.params = this.filtro;
      this.relatorioModal.open();
    }
  }

  public getBlocosByCondominio(): void {
    this.blocos = [];
    this.blocosHttpService.getAllByCondominio(this.filtro.id_condominio).subscribe(
      entities => this.blocos = entities,
      error => this.messageService.error('Erro ao carregar os blocos do condomínio. Tente novamente', 'Erro', error)
    );
  }

  private setBloco(blocoSelecionado: Bloco): void {
    this.bloco = blocoSelecionado;
    this.filtro.id_bloco = this.bloco.id;
    this.getAllUnidadesByBloco(this.bloco.id);
    this.onCleanUnidade(this.inputUnidade.nativeElement);
  }

  public onSelectBloco(tm: TypeaheadMatch): void {
    this.setBloco(tm.item as Bloco);
  }

  public openBlocosPequisaModal(): void {
    this.blocosPesquisaModal.open();
  }

  public onSelectBlocosPesquisaModal(bloco: Bloco): void {
    if (bloco) this.setBloco(bloco);
    jQuery('#bloco').focus();
  }

  public onCleanBloco(input: any): void {
    this.onCleanUnidade(this.inputUnidade.nativeElement);
    this.bloco = null;
    this.filtro.id_bloco = null;
    input.value = '';
    input.focus();
  }

  public getAllUnidadesByBloco(idBloco: number): void {
    this.unidades = [];
    this.unidadesHttpService.getAllByBloco(idBloco).subscribe(
      entities => {
        for (const unidade of entities) {
          if (unidade.nome) {
            this.unidades.push(unidade);
          }
        }
      },
      error => this.messageService.error('Erro ao carregar as unidades do bloco. Tente novamente', 'Erro', error)
    );
  }

  private setUnidade(unidadeSelecionada: Unidade): void {
    this.unidade = unidadeSelecionada;
    this.filtro.id_unidade = this.unidade.id;
  }

  public onSelectUnidade(tm: TypeaheadMatch): void {
    this.setUnidade(tm.item as Unidade);
  }

  public onCleanUnidade(input: any): void {
    this.unidade = null;
    this.filtro.id_unidade = null;
    input.value = '';
    input.focus();
  }

  public openUnidadesPequisaModal(): void {
    this.unidadesPesquisaModal.open();
  }

  public onSelectUnidadesPesquisaModal(unidade: Unidade): void {
    if (unidade) this.setUnidade(unidade);
    jQuery('#unidade').focus();
  }

}