import { BaseEntity } from "../base-entity";

export class PagamentoCancelamento extends BaseEntity {
	id_usuario: number;
	data_cancelamento: Date;
	motivo: string;

	id_pagamento: number;

	constructor() {
		super();
		this.data_cancelamento = new Date();
		this.motivo = '';
	}
}