import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';

import { ContaBancaria, ConfiguracaoTarifaBancaria, ConfiguracaoCobranca, AutorizacaoContaBancaria } from '../../../models';
import { Crud, CrudService, ConfigService, CustomHttp, AuthService } from '../../../core';
import { observableToBeFn } from 'rxjs/internal/testing/TestScheduler';

@Injectable()
export class ContasBancariasHttpService implements Crud<ContaBancaria> {
	resource = 'contabancaria';
	private baseUrl: string;

	constructor(private customHttp: CustomHttp,
			private crudService: CrudService,
			private authService: AuthService,
			private configService: ConfigService) {
		this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
	}

	get(id: number): Observable<ContaBancaria> {
		return this.crudService.get<ContaBancaria>(this.resource, id);
	}

	getAll(): Observable<ContaBancaria[]> {
		return this.crudService.getAll<ContaBancaria>(this.resource);
	}

	post(entity: ContaBancaria): Observable<ContaBancaria> {
		return this.crudService.post<ContaBancaria>(this.resource, entity);
	}

	put(entity: ContaBancaria): Observable<ContaBancaria> {
		return this.crudService.put<ContaBancaria>(this.resource, entity);
	}

	delete(entity: ContaBancaria): Observable<string> {
		return this.crudService.delete<ContaBancaria>(this.resource, entity);
	}

	/**
	 * Carrega todas as contas bancária pelo condomínio informado
	 */
	public getAllByCondominio(idCondominio: number): Observable<ContaBancaria[]> {
		return this.customHttp.get(`${this.baseUrl}/consultar/condominio/${idCondominio}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Carrega todas as contas bancária pelo condomínio informado e tipo de conta
	 */
	public getAllByCondominioETipo(idCondominio: number, tipo: number): Observable<ContaBancaria[]> {
		return this.customHttp.get(`${this.baseUrl}/consultar/condominio/${idCondominio}/tipo/${tipo}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Busca a configuração de tarifas de uma conta
	 * @param id_conta_bancaria 
	 * @author Marcos Frare
	 * @since 10/07/2020
	 */
	public getConfiguracaoTarifaBancaria(id_conta_bancaria: number): Observable<ConfiguracaoTarifaBancaria> {
		return this.customHttp.get(`${this.baseUrl}/tarifa-bancaria/${id_conta_bancaria}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Salvar configuração de tarifa bancária
	 * @param configuracaoTarifaBancaria 
	 * @author Marcos Frare
	 * @since 10/07/2020
	 */
	public saveConfiguracaoTarifaBancaria(configuracaoTarifaBancaria: ConfiguracaoTarifaBancaria): Observable<ConfiguracaoTarifaBancaria> {
		return this.customHttp.post(`${this.baseUrl}/tarifa-bancaria`, configuracaoTarifaBancaria).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	// ============================================================================================================================
	// Integração bancária
	// ============================================================================================================================

	/**
	 * Busca URL para autorização
	 * @param id_conta_bancaria 
	 * @author Marcos Frare
	 * @since 12/02/2021
	 */
	public getUrlAutorize(id_conta_bancaria: number): Observable<any> {
		return this.customHttp.get(`${this.baseUrl}/integrar/${id_conta_bancaria}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Busca as contas bancárias integradas do condomínio
	 * @author Marcos Frare
	 * @since 07/06/2021
	 */
	public contaBancariaIntegradaCondominio(): Observable<ContaBancaria[]> {
		let id_condominio = this.authService.getIdCurrentCondominio();
		return this.customHttp.get(`${this.baseUrl}/integrada/api/${id_condominio}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Teste de consulta de boletos
	 */
	// public consultaBoleto(id_conta_bancaria: number): Observable<any> {
	// 	return this.customHttp.get(`${this.configService.getBaseUrl()}/boleto/consultar-api/${id_conta_bancaria}`).pipe(
	// 		map(response => response.json()),
	// 		catchError(error => _throw(error))
	// 	);
	// }
	// public geraBoleto(id_conta_bancaria: number): Observable<any> {
	// 	return this.customHttp.post(`${this.configService.getBaseUrl()}/boleto/gerar-api`, {id: 8400}).pipe(
	// 		map(response => response.json()),
	// 		catchError(error => _throw(error))
	// 	);
	// }

	/**
	 * Buscar configuração de cobrança de uma conta bancária
	 * @param id_conta_bancaria 
	 * @returns ConfiguracaoCobranca
	 * @author Marcos Frare
	 * @since 19/07/2022
	 */
	public getConfiguracaoCobranca(id_conta_bancaria: number): Observable<ConfiguracaoCobranca> {
		return this.customHttp.get(`${this.baseUrl}/configuracao-cobranca/${id_conta_bancaria}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Salvar configuração de cobrança
	 * @param configuracaoCobranca 
	 * @author Marcos Frare
	 * @since 19/07/2022
	 */
	 public saveConfiguracaoCobranca(configuracaoCobranca: ConfiguracaoCobranca): Observable<ConfiguracaoCobranca> {
		return this.customHttp.post(`${this.baseUrl}/configuracao-cobranca`, configuracaoCobranca).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	// ============================================================================================================================
	// Autorização de Integração bancária
	// ============================================================================================================================

	/**
	 * Busca a autorização da Conta Bancária
	 * @param idContaBancaria 
	 * @author Marcos Frare
	 * @since 27/10/2022
	 */
	public getAutorizacaoContaBancaria(idContaBancaria: number): Observable<AutorizacaoContaBancaria> {
		return this.customHttp.get(`${this.baseUrl}/autorizacao/${idContaBancaria}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Salvar autorização de integração bancária
	 * @param autorizacaoContaBancaria 
	 * @author Marcos Frare
	 * @since 27/10/2022
	 */
	public saveAutorizacaoContaBancaria(autorizacaoContaBancaria: AutorizacaoContaBancaria): Observable<AutorizacaoContaBancaria> {
		return this.customHttp.post(`${this.baseUrl}/autorizacao`, autorizacaoContaBancaria).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

}