import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MenuItemAcessoPainel } from './menu-item-acesso-painel';
import { AcessoPainel } from 'app/models';

@Component({
	selector: 'acessos-painel',
	templateUrl: './acessos-painel.component.html',
	styleUrls: ['./acessos-painel.component.css'],
})
export class AcessosPainelComponent {

	@Input() entities: MenuItemAcessoPainel[];
	@Input() name: string = "Item";
	@Output() onChangeAcesso: EventEmitter<AcessoPainel> = new EventEmitter();

	constructor() { }

	public onChangeAcessoClick(acessoPainel: AcessoPainel): void {
		this.onChangeAcesso.emit(acessoPainel);
	}

}