import { Bloco, CategoriaContas } from "app/models";

export class GeracaoCobrancaAvulsa {
  id_bloco: number;
  ids_unidades: number[];
  id_categoria_conta: number;
  complemento: string;
  id_fornecedor: number;
  para_proprietario: boolean;

  categoriaConta: CategoriaContas;
  bloco: Bloco;

  constructor() {
    this.id_bloco = null;
    this.ids_unidades = [];
    this.id_fornecedor = null;
    this.para_proprietario = false;
    this.bloco = new Bloco();
    this.categoriaConta = new CategoriaContas();
  }
}