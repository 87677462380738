import { Injectable } from '@angular/core';


import { CustomHttp, ConfigService } from 'app/core';

@Injectable()
export class GerarCobrancaService {

  private firstResource: string = 'boleto/download';
  private secondResource: string = 'arquivoremessa/download';
  private boletoUrl: string;
  private arquivoUrl: string;

  constructor(
    private customHttp: CustomHttp,
    private configService: ConfigService
    // private jwtTokenService: JwtTokenService
  ) {
    this.boletoUrl = `${configService.getBaseUrl()}/${this.firstResource}`;
    this.arquivoUrl = `${configService.getBaseUrl()}/${this.secondResource}`;
  }

  // efetuarDownloadDosBoletos(idCondominio: number, idRateio: number): Observable<Response> {
  //   return this.customHttp.get(`${this.baseUrl}/condominio/${idCondominio}/rateio/${idRateio}`)
  //               .map(response => response.json())
  //               .catch(error => error);
  // }

  efetuarDownloadDosBoletos(idCondominio: number, idRateio: number) {
    let janela = window.open(`${this.boletoUrl}/condominio/${idCondominio}/rateio/${idRateio}`, '_blank');
    // :${this.jwtTokenService.accessToken}
  }

  efetuarDownloadDoArquivoRemessa(idRateio: number) {
    let janela = window.open(`${this.arquivoUrl}/rateio/${idRateio}`, '_blank');
  }

}