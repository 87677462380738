import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { DocumentosHttpService } from '../services';
import { Documento } from 'app/models';
import { MessageService, AuthService } from 'app/core';
import { DatePipe } from '@angular/common';

declare var jQuery: any;

@Component({
  selector: 'documentos-pesquisa-modal',
  templateUrl: './documentos-pesquisa-modal.component.html',
  styleUrls: ['./documentos-pesquisa-modal.component.css'],
  providers: [
    DatePipe
  ]
})
export class DocumentosPesquisaModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @Input() data_competencia: Date;
  @Output() selectDoc$ = new EventEmitter<Documento[]>();

  public documentos: Documento[];
  public allSelectedDespesa: boolean;

  constructor(private authService: AuthService,
    private messageService: MessageService,
    private documentosHttpService: DocumentosHttpService,
    private datePipe: DatePipe) { }
  
  ngOnInit() {
    this.documentos = [];
    if (!this.idModal) {
      this.idModal = 'documentosPesquisaModal';
    }
  }

  /**
   * Carrega os arquivos disponíveis para o livro
   * @author Marcos Frare
   * @since 03/08/2020
   */
  private load(): void {
    this.documentos = [];
    const id_condominio = this.authService.getIdCurrentCondominio();
    const competencia = this.datePipe.transform(this.data_competencia, 'yyyy-MM-dd');
    this.documentosHttpService.getAllByLivro(id_condominio, competencia).subscribe(
      response => {
        this.documentos = response;
      },
      error => {
        this.messageService.error('Erro ao buscar documentos. Tente novamente', 'Erro', error);
      }
    );
  }

  open() {
    this.allSelectedDespesa = false;
    this.load();
    jQuery(`#${this.idModal}`).modal('show');
  }
  
  close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Ação de click do botão Selecionar
   * @author Marcos Frare
   * @since 03/08/2020
   */
  public selection(): void {
    let selectionDoc = this.documentos.filter(doc => doc.checado);
    this.selectDoc$.emit(selectionDoc);
    this.close();
  }

  /** Ao selecionar uma categoria de conta */
  selectClick(documento: Documento) {
    documento.checado = !documento.checado;
  }

  /**
   * Faz seleção de todos os pagamentos de despesa
   * @author Marcos Frare
   * @since 03/08/2020
   */
  public toggleAllSelectedDespesa(): void {
    for (let documento of this.documentos) {
      documento.checado = this.allSelectedDespesa;
    }
  }

  /**
   * Verifica se todos os pagamentos de despesas estão selecionadas
   * @author Marcos Frare
   * @since 03/08/2020
   */
  public checkAllSelectedDespesa(): boolean {
    for (const documento of this.documentos) {
      if (!documento.checado) {
        return false;
      }
    }
    return true;
  }

}