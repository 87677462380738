import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgForm } from '@angular/forms';

import { Unidade, Usuario } from '../../../../../models';
import { MessageService } from '../../../../../core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { UsuariosCadastroModalComponent, UsuariosHttpService } from '../../../../usuarios';
import { UnidadesHistoricoHttpService } from '../services';
import { UnidadesHttpService } from '../../services';

declare var jQuery: any;

interface CadastroUsuarioSetup {
	input: any;
}

@Component({
	selector: 'unidades-troca-usuario',
	templateUrl: './unidades-troca-usuario.component.html',
	styleUrls: ['./unidades-troca-usuario.component.css'],
	providers: [
		UnidadesHistoricoHttpService
	]
})
export class UnidadesTrocaUsuarioComponent implements OnInit, AfterViewInit {

	unidade: Unidade;
	tipo: string;
	usuarios: Usuario[];

	// Usuário atual
	idUsuarioAtual: number;
	usuarioAtual: Usuario;
	dataEntradaUsuarioAtual: Date;
	dataSaidaUsuarioAtual: Date;

	// Usuario novo
	// possuiUsuarioNovo = true;
	idUsuarioNovo: number;
	usuarioNovo: Usuario;
	dataEntradaUsuarioNovo: Date;
	usuarioNovoNotFound = false;

	@ViewChild('form') form: NgForm;
	@ViewChild('usuariosCadastroModal') usuariosCadastroModalComponent: UsuariosCadastroModalComponent;
	cadastroNovoUsuarioSetup: CadastroUsuarioSetup = { input: null };
	private btnSave: any;

	constructor(private activatedRoute: ActivatedRoute,
		private router: Router,
		private messageService: MessageService,
		private unidadesHttpService: UnidadesHttpService,
		private unidadesHistoricoHttpService: UnidadesHistoricoHttpService,
		private usuariosHttpService: UsuariosHttpService) { }

	ngOnInit() {
		this.activatedRoute.params.subscribe((params: Params) => {
			const idUnidade = +params['idunidade'];
			this.tipo = params['tipo'];
			if (!this.tipo || (this.tipo !== 'sacado')) {
				this.messageService.info('O tipo da troca informado é invalido. Somente é possível realizar troca do proprietário ou morador da unidade', 'Informação');
				this.cancel();
				return;
			}
			this.load(idUnidade);
		});
		this.getAllUsuarios();
	}

	ngAfterViewInit() {
		this.btnSave = jQuery('#btnSave');
	}

	// Carrega a unidade que será realizada a trocar do proprietário/morador
	private load(idUnidade: number) {
		if (!idUnidade) {
			this.messageService.info('A unidade para realizar a troca não foi informada. Tente novamente', 'Informação');
			this.cancel();
			return;
		}

		this.unidadesHttpService.get(idUnidade)
			.subscribe(
				entity => {
					this.unidade = entity;

					if (this.tipo === 'proprietario') {
						this.idUsuarioAtual = entity.id_sacado;
					}
					this.getUsuarioAtual();
				},
				error => {
					if (error.status === 400) {
						this.messageService.info('Unidade não encontrada. Verifique se você selecionou a troca em uma unidade existente e tente novamente', 'Informação');
						this.cancel();
					} else {
						this.messageService.error('Erro ao carregar a unidade. Tente novamente', 'Erro', error);
					}
				}
			);
	}

	// Pega as informações do usuário atual referente ao proprietário/morador
	private getUsuarioAtual() {
		this.usuariosHttpService.get(this.idUsuarioAtual)
			.subscribe(
				entity => this.usuarioAtual = entity,
				error => this.messageService.error(`Erro ao carregar o ${this.tipo} atual. Tente novamente`, 'Erro', error)
			);
	}

	private getAllUsuarios() {
		this.usuariosHttpService.getAll()
			.subscribe(
				entities => this.usuarios = entities,
				error => this.messageService.error('Erro ao carregar os usuários. Tente novamente', 'Erro', error)
			);
	}


	// Ao selecionar o novo usuário
	onSelectUsuarioNovo(tm: TypeaheadMatch) {
		this.usuarioNovo = tm.item as Usuario;
		this.idUsuarioNovo = this.usuarioNovo.id;
	}

	// Ao limpar o campo do usuário novo
	onCleanUsuarioNovo(input: any) {
		this.idUsuarioNovo = null;
		this.usuarioNovo = null;
		this.usuarioNovoNotFound = false;

		input.value = '';
		input.focus();
	}

	// Exibi o modal para criar um novo usuário quando o mesmo não for encontrado
	createUsuario(nome: string, input: any) {
		this.cadastroNovoUsuarioSetup.input = input;

		this.usuariosCadastroModalComponent.open(nome);
	}

	// Após o usuário ser cadastrado via modal, setamos os valores desse novo cadastro no usuário novo
	onAfterSaveUsuario(usuario: Usuario) {
		this.usuarioNovo = usuario;
		this.idUsuarioNovo = usuario.id;
		this.usuarioNovoNotFound = false;
		this.usuarios.push(usuario);
		this.cadastroNovoUsuarioSetup.input.focus();
	}

	/**
	 * Torna todos os campos do formulário "sujos" para exibir as mensagens de campos inválidos
	 */
	makeAllFieldsDirty() {
		for (const key in this.form.controls) {
			if (this.form.controls.hasOwnProperty(key)) {
				const control = this.form.controls[key];
				control.markAsDirty();
			}
		}
	}

	save() {
		this.makeAllFieldsDirty();
		if (this.form.valid) {
			this.btnSave.button('loading');
			// 1 - Salva os históricos de saída e entrada
			this.saveHistorico()
				.then(() => {
					// 2 - Salva alteração na unidade
					this.unidadesHttpService.put(this.unidade).subscribe(
						() => {
							this.messageService.success('', 'Troca realizada com sucesso');
							this.cancel();
						},
						error => {
							this.messageService.error(`Erro ao salvar alteração do ${this.tipo} na unidade. Tente novamente`, 'Erro', error);
							this.btnSave.button('reset');
						}
					);
				})
				.catch(() => { });
		} else {
			this.messageService.info('Para realizar a troca é preciso informar corretamente todos os campos destacados em vermelho', 'Informação');
		}
	}

	private saveHistorico(): Promise<any> {
		if (this.tipo === 'proprietario') {
			return this.saveHistoricoProprietario();
		} else {
			return this.saveHistoricoMorador();
		}
	}

	// Salva os histórico de saída e entrada do proprietário
	private saveHistoricoProprietario(): Promise<any> {
		return new Promise((resolve, reject) => {
			// 1 - Salva a saída do proprietário atual
			this.unidadesHistoricoHttpService.saveSaidaProprietario(this.unidade.id, this.idUsuarioAtual, this.dataSaidaUsuarioAtual)
				.subscribe(
					response => {
						// 2 - Salva a entrada do novo proprietário (somente quando já tiver um novo proprietário)
						if (this.idUsuarioNovo) {
							this.unidadesHistoricoHttpService.saveEntradaProprietario(this.unidade.id, this.idUsuarioNovo, this.dataEntradaUsuarioNovo)
								.subscribe(
									() => {
										this.unidade.id_sacado = this.idUsuarioNovo;
										resolve();
									},
									error => {
										this.messageService.error('Erro ao salvar o histórico de entrada do novo proprietário. Tente novamente', 'Erro', error);
										this.btnSave.button('reset');
										reject(error);
									}
								);
						} else {
							this.unidade.id_sacado = null;
							resolve();
						}
					},
					error => {
						this.messageService.error('Erro ao salvar o histórico de saída do proprietário atual. Tente novamente', 'Erro', error);
						this.btnSave.button('reset');
						reject(error);
					}
				);
		});
	}

	// Salva os histórico de saída e entrada do morador
	private saveHistoricoMorador(): Promise<any> {
		return new Promise((resolve, reject) => {
			// 1 - Salva a saída do morador atual
			this.unidadesHistoricoHttpService.saveSaidaMorador(this.unidade.id, this.idUsuarioAtual, this.dataSaidaUsuarioAtual)
				.subscribe(
					response => {
						// 2 - Salva a entrada do novo morador (somente quando já tiver um novo morador)
						if (this.idUsuarioNovo) {
							this.unidadesHistoricoHttpService.saveEntradaMorador(this.unidade.id, this.idUsuarioNovo, this.dataEntradaUsuarioNovo)
								.subscribe(
									() => {
										this.unidade.id_sacado = this.idUsuarioNovo;
										resolve();
									},
									error => {
										this.messageService.error('Erro ao salvar o histórico de entrada do novo morador. Tente novamente', 'Erro', error);
										this.btnSave.button('reset');
										reject(error);
									}
								);
						} else {
							this.unidade.id_sacado = null;
							resolve();
						}
					},
					error => {
						this.messageService.error('Erro ao salvar o histórico de saída do morador atual. Tente novamente', 'Erro', error);
						this.btnSave.button('reset');
						reject(error);
					}
				);
		});
	}

	cancel() {
		this.router.navigate(['../../../../'], { relativeTo: this.activatedRoute });
	}
}
