import { Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';

import { MessageService } from '../message';

/** Service com métodos uteis para trabalhar com formulários */
@Injectable()
export class FormsUtilsService {

    constructor(private messageService: MessageService) { }

    /**
     * Torna todos os campos do formulário "sujos" para exibir as mensagens de campos inválidos
     */
    makeAllFieldsDirty(form: NgForm) {
        if (!form) {
            return;
        }

        for (const key in form.controls) {
            if (form.controls.hasOwnProperty(key)) {
                const control = form.controls[key];
                control.markAsDirty();
            }
        }
    }

    /**
     * Exibe mensagem que o formulário está invalido ao usuário
     */
    showMsgFormInvalid() {
        this.messageService.info('Para salvar é preciso informar corretamente todos os campos destacados em vermelho', 'Informação');
    }

    isValidCpf(cpf) {	
        cpf = cpf.replace(/[^\d]+/g,'');	
        if(cpf == '') return false;	
        // Elimina CPFs invalidos conhecidos	
        if (cpf.length != 11 || 
            cpf == "00000000000" || 
            cpf == "11111111111" || 
            cpf == "22222222222" || 
            cpf == "33333333333" || 
            cpf == "44444444444" || 
            cpf == "55555555555" || 
            cpf == "66666666666" || 
            cpf == "77777777777" || 
            cpf == "88888888888" || 
            cpf == "99999999999")
                return false;		
        // Valida 1o digito	
        let add = 0;	
        for (let i=0; i < 9; i ++)		
            add += parseInt(cpf.charAt(i)) * (10 - i);	
            let rev = 11 - (add % 11);	
            if (rev == 10 || rev == 11)		
                rev = 0;	
            if (rev != parseInt(cpf.charAt(9)))		
                return false;		
        // Valida 2o digito	
        add = 0;	
        for (let i = 0; i < 10; i ++)		
            add += parseInt(cpf.charAt(i)) * (11 - i);	
        rev = 11 - (add % 11);	
        if (rev == 10 || rev == 11)	
            rev = 0;	
        if (rev != parseInt(cpf.charAt(10)))
            return false;		
        return true;   
    }
    
    isValidCnpj(c) {
        var b = [6,5,4,3,2,9,8,7,6,5,4,3,2];
    
        if((c = c.replace(/[^\d]/g,"")).length != 14)
            return false;
    
        if(/0{14}/.test(c))
            return false;

        for (var i = 0, n = 0; i < 12; n += c[i] * b[++i]);
        if(c[12] != (((n %= 11) < 2) ? 0 : 11 - n))
            return false;
    
        for (var j = 0, m = 0; j <= 12; m += c[j] * b[j++]);
        if(c[13] != (((m %= 11) < 2) ? 0 : 11 - m))
            return false;
    
        return true;
    };

}
