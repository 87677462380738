import { BaseEntity } from "../base-entity";
import { AutorizacaoContaBancaria } from "./autorizacao-conta-bancaria";
import { Banco } from "./banco";

export const tiposContasBancarias = [
	{ codigo: 1, nome: 'Conta Corrente' },
	{ codigo: 2, nome: 'Conta Poupança' },
	{ codigo: 3, nome: 'Conta Investimento' },
	{ codigo: 4, nome: 'Caixa' },
	{ codigo: 5, nome: 'Cotas' },
];

export class ContaBancaria extends BaseEntity {
	agencia: number;
	numero: number;
	tipo: number;
	id_banco: number;
	id_condominio: number;
	id: number;
	nome: string;
	cedente: string;
	dias_compensacao: number;
	ultimo_numero_boleto: string;
	posto: any;
	carteira: any;
	convenio: any;
	open: boolean = false;
	saldo: any;
	token_integracao: string;
	situacao: string;
	compartilhada: boolean;
	gera_cobranca_pix: boolean;

	pendentes: any;
	logo: string;
	data_inativacao: Date;
	ultimo_lote_remessa: number;

	banco?: Banco;
	code: string;
	autorizacao?: AutorizacaoContaBancaria;

	constructor() {
		super();
		this.banco = new Banco();
		this.code = '';
		this.dias_compensacao = 1;
		this.compartilhada = false;
		this.gera_cobranca_pix = false;
	}
}