export class ReciboGeracaoFiltro {
	id_cliente: number;
	id_condominio: number;
	data_pagamento_inicial: Date;
	data_pagamento_final: Date;
  id_fornecedor: number;

  ids_pagamento: number[];

  constructor() {
    this.limpar();
  }

	/** Limpa o filtro */
	limpar() {
    this.id_cliente = null;
    this.id_condominio = null;
    this.id_fornecedor = null;
		const today = new Date();
		this.data_pagamento_inicial = new Date(today.getFullYear(), today.getMonth(), 1); // primeiro dia do mês
		this.data_pagamento_final = new Date(today.getFullYear(), today.getMonth()+1, 0); // último dia do mês
    this.ids_pagamento = [];
	}
}