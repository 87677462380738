import { NgModule } from '@angular/core';
import { CertificadoDigitalComponent } from './certificado-digital.component';
import { SharedModule } from 'app/shared';
import { CertificadoDigitalCadastroModalComponent } from './certificado-digital-cadastro-modal';

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    CertificadoDigitalComponent,
    CertificadoDigitalCadastroModalComponent
  ]
})
export class CertificadoDigitalModule { }
