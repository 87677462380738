import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { AuthService, MessageService } from '../../../core';
import { InadimplenciaRelFiltro } from './inadimplencia-rel-filtro';
import { Unidade, Bloco } from './../../../models';
import { BlocosHttpService } from './../../blocos/services/blocos-http.service';
import { UnidadesHttpService } from './../../blocos/unidades/services/unidades-http.service';
import { BlocosPesquisaModalComponent } from './../../blocos/blocos-pesquisa-modal';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import { RelatorioModalComponent } from '../../relatorios/relatorio-modal';
import { datepickerMesAnoOptions } from '../../../shared';

declare var jQuery: any;

@Component({
	selector: 'inadimplencia-rel',
	templateUrl: './inadimplencia-rel.component.html',
	styleUrls: ['../shared/visualizacao.relatorio.css']
})

export class InadimplenciaRelComponent implements OnInit {

	public unidades: Unidade[] = [];
	public allUnidades: Unidade[] = [];
	public blocos: Bloco[] = [];
	public filtro = new InadimplenciaRelFiltro();
	public bloco: Bloco;
	public showInputBlocos: boolean = true;
	public datepickerDataCompetenciaOptions = datepickerMesAnoOptions;

	@ViewChild('inputBloco') inputBloco: ElementRef;
	@ViewChild('blocosPesquisaModal') blocosPesquisaModal: BlocosPesquisaModalComponent;
	@ViewChild('relatorioModal') relatorioModal: RelatorioModalComponent;

	multiselectSettings: IMultiSelectSettings = {
		enableSearch: true,
		containerClasses: 'container-multiselect',
		showUncheckAll: true,
		showCheckAll: true,
		dynamicTitleMaxItems: 3,
		checkedStyle: 'checkboxes',
		buttonClasses: 'btn btn-default',
		itemClasses: 'dropdown-menu'
	};

	multiselectOptionsUnidades: IMultiSelectOption[];
	multiselectTextsUnidades: IMultiSelectTexts = {
		checkAll: 'Selecionar todas',
		uncheckAll: 'Limpar',
		checked: 'selecionada',
		checkedPlural: 'selecionadas',
		searchPlaceholder: 'Pesquisar...',
		defaultTitle: 'Selecionar',
		allSelected: 'Todas selecionadas',
		searchEmptyResult: 'Nenhum registro encontrado',
		searchNoRenderText: 'Digite para pesquisar',
	};

	constructor(private authService: AuthService,
		private messageService: MessageService,
		private unidadesHttpService: UnidadesHttpService,
		private blocosHttpService: BlocosHttpService) {
	}

	ngOnInit() {
		this.filtro.id_cliente = this.authService.getIdCurrentCliente();
		this.filtro.id_condominio = this.authService.getIdCurrentCondominio();;
		this.filtro.id_usuario = this.authService.getIdCurrentUser();
		this.getBlocosByCondominio(this.filtro.id_condominio);
		this.filtro.limpar();
		this.getAllUnidadesCondominio();
	}

	/**
	 * Busca todas as unidades do condomínio
	 * @author Marcos Frare
	 * @since 15/03/2021
	 */
	private getAllUnidadesCondominio(): void {
		this.allUnidades = [];
		this.unidadesHttpService.getAllByCondominioBloco(this.filtro.id_condominio, null).subscribe(
			response => {
				this.allUnidades = response;
				this.getUnidadesByBloco(null);
			},
			error => {
				this.messageService.error('Erro ao buscar unidades do condomínio! Tente novamente.', 'Erro', error);
			}
		);
	}

	private getBlocosByCondominio(idCondominio: number) {
		this.blocosHttpService.getAllByCondominio(idCondominio)
			.subscribe(
				entitites => {
					this.blocos = entitites
					if (this.blocoUnico(this.blocos)) {
						this.setBloco(this.blocos[0]);
						this.showInputBlocos = false;
					} else if (!this.blocos) {
						this.messageService.error('Não existem blocos cadastrados no Condomínio. Cadastre  um bloco e tente novamente', 'Erro')
					}
				},
				error => this.messageService.error('Erro ao carregar as unidades do condomínio. Tente novamente', 'Erro', error)
			);
	}

	private setBloco(blocoSelecionado: Bloco) {
		this.bloco = blocoSelecionado;
		this.getUnidadesByBloco(this.bloco.id);
		this.onCleanUnidades();
		this.filtro.id_bloco = this.bloco.id;
	}

	/**
	 * Filtra unidades conforme bloco selecionado
	 * @param idBloco 
	 */
	private getUnidadesByBloco(idBloco: number) {
		this.filtro.unidades = [];
		this.multiselectOptionsUnidades = [];
		if (idBloco) {
			this.allUnidades.filter(unid => unid.id_bloco == idBloco).map(un => {
				this.multiselectOptionsUnidades.push({id: un.id, name: `${un.nome_bloco}-${un.nome_unidade}`});
			});
		} else {
			this.allUnidades.map(un => {
				this.multiselectOptionsUnidades.push({id: un.id, name: `${un.nome_bloco}-${un.nome_unidade}`});
			});
		}
		
	}

	onCleanUnidades() {
		this.filtro.unidades = [];
		this.filtro.id_bloco = null;
	}

	onSelectBloco(tm: TypeaheadMatch): void {
		this.setBloco(tm.item as Bloco);
	}

	openBlocosPequisaModal() {
		this.blocosPesquisaModal.open();
	}

	onSelectBlocosPesquisaModal(bloco: Bloco) {
		if (bloco) {
			this.setBloco(bloco)
		}
		jQuery('#bloco').focus();
	}

	onCleanBloco(input: any) {
		this.getUnidadesByBloco(null);
		this.bloco = null;
		input.value = '';
		input.focus();
		this.onCleanUnidades();
	}

	private blocoUnico(blocos: Bloco[]): boolean {
		return blocos.length === 1;
	}

	public view(): void {
		this.relatorioModal.url = '/relatorio/inadimplencia';
		this.relatorioModal.params = this.filtro;
		this.relatorioModal.open();
	}

	public limpar(): void {
		this.filtro.limpar();
	}

	/**
	 * Evento de alteração do tipo de relatório
	 * @author Marcos Frare
	 * @since 15/03/2021
	 */
	public changeTipoRelatorio(): void {
		if (this.filtro.tipo_relatorio == 'RESUMIDO') {
			this.filtro.tipoFiltro = 1;
		}
	}
}