import { Component, OnInit } from '@angular/core';
import { AcessosPainelHttpService } from '../grupos-de-usuarios/acessos-painel/services';
import { MessageService, AuthService } from 'app/core';
import { AcessoPainel, Menu, TIPO_USUARIO } from 'app/models';
import { TitulosVencidosEnvio } from './services/titulos-vencidos-envio';
import { DashboardHttpService } from './services';
import { PermissionService } from 'app/core/services/permission/permission.service';

@Component({
	selector: 'dashboard',
	styleUrls: ['./dashboard.component.css'],
	templateUrl: './dashboard.component.html',
	providers: [
		AcessosPainelHttpService,
		DashboardHttpService
	]
})
export class DashboardComponent implements OnInit {

	private idCondominio: number;
	private idCliente: number;
	private idUsuario: number;
	public tiposUsuario = TIPO_USUARIO;
	public tipoUsuario: number;
	public graficos: AcessoPainel[] = [];
	public cards: AcessoPainel[] = [];
	public totalTitulosAbertos: number = null;
	public quantidadeTitulos: number;
	public contasAPagar: any[];
	public idsMenusCondomino: number[] = [];

	constructor(private acessosPainelHttpService: AcessosPainelHttpService,
		private messageService: MessageService,
		private authService: AuthService,
		private dashboardHttpService: DashboardHttpService,
		private permission: PermissionService) {
		this.idUsuario = this.authService.getIdCurrentUser();
		this.tipoUsuario = this.authService.getTipoCurrentUser();
	}

	ngOnInit() {
		this.idCondominio = this.authService.getIdCurrentCondominio();
		this.idCliente = this.authService.getIdCurrentCliente();
		if (this.tipoUsuario !== TIPO_USUARIO.CONDOMINO) {
			this.getAllAcessosPainel();
		}
		if (this.tipoUsuario == 3) {
			this.getMenusCondomino();
		}
		this.authService.changedCondominio$.subscribe(() => {
			this.getAllAcessosPainel();
		});
	}

	private getMenusCondomino(): void {
		this.permission.getAcessosCondomino(this.idUsuario).subscribe(
			res => this.idsMenusCondomino = res.map(m => m.id),
			error => this.messageService.error('Não foi possíel carregar os menus do usuário! Tente novamente.', 'Erro', error)
		);
	}

	/**
	 * Limpar os paineis no momento de atualização
	 * @author Marcos Frare
	 * @since 03/06/2020
	 */
	private limpaPaineis() {
		this.contasAPagar = [];
		this.totalTitulosAbertos = null;
		this.quantidadeTitulos = null;
	}

	private getAllAcessosPainel(): void {
		this.limpaPaineis();
		this.idCondominio = this.authService.getIdCurrentCondominio();
		this.acessosPainelHttpService.getAllByUsuarioAndCliente(this.idCliente, this.idUsuario).subscribe(
			acessosPainel => this.setAcessosPainel(acessosPainel)
		);
	}

	private setAcessosPainel(acessosPainel: any): void {
		this.graficos = acessosPainel.graficos;
		this.cards = acessosPainel.cards;
		this.setItems();
	}

	private setItems(): void {
		this.cards.forEach(card => {
			switch (card.codigo) {
				case 1: // Total de títulos vencidos
					this.getTitulosVencidos();
					break;
				case 2:
					this.getContasAPagar();
					break;
				default:
					break;
			}
		});
	}

	private getTitulosVencidos(): void {
		const titulosVencidosEnvio = new TitulosVencidosEnvio(this.idCondominio, this.idCliente, this.idUsuario);
		this.dashboardHttpService.getTitulosVencidos(titulosVencidosEnvio).subscribe(
			response => {
				this.totalTitulosAbertos = response.valor_total_emaberto;
				this.quantidadeTitulos = response.quantidade_total_emaberto
			},
			error => this.messageService.error('Erro ao obter total de títulos vencidos do condomínio, favor contate o administrador do sistema', 'Erro', error)
		);
	}

	// --------------------------------------------------------------------------------------------------------------------------------------------
	// Quadro de contas à pagar
	// --------------------------------------------------------------------------------------------------------------------------------------------
	private getContasAPagar(): void {
		const filtro = {
			id_cliente: this.idCliente,
			id_condominio: this.idCondominio,
			id_usuario: this.idUsuario
		}
		this.dashboardHttpService.getContasAPagar(filtro).subscribe(
			response => this.contasAPagar = response,
			error => this.messageService.error('Erro ao busca contas à pagar!', 'Erro', error)
		);
	}

	/**
	 * Ação para expandir contas do condominio
	 * @param entity - Condmínio
	 */
	public expadeCondominio(entity): void {
		entity.open = !entity.open;
	}
}