import { Injectable } from '@angular/core';
import { ConfigService, CustomHttp } from 'app/core';
import { Boleto, Titulo } from 'app/models';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs-compat/observable/throw';
import { catchError, map } from 'rxjs/operators';
import { IntegracaoBancariaFiltro } from '../integracao-bancaria-filtro';

@Injectable({
  providedIn: 'root'
})
export class BoletoIntegracaoHttpService {

  public resource = 'boleto';
	private baseUrl: string;

  constructor(private customHttp: CustomHttp,
      private configService: ConfigService) {
    this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
  }

  /**
   * Busca títulos ainda sem boleto integrado
   * @returns 
   * @author Marcos Frare
   * @since 04/05/2021
   */
  public getTitulosIntegracao(filtro: IntegracaoBancariaFiltro): Observable<Titulo[]> {
    return this.customHttp.post(`${this.baseUrl}/titulos-cobranca`, filtro).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
  }

  /**
	 * Enviar boletos para integração
	 * @param ids_titulo 
	 * @returns 
	 * @author Marcos Frare
	 * @since 04/05/2021
	 */
	public envioBoletosIntegracao(titulos: Titulo[], id_conta_bancaria: number): Observable<any> {
		return this.customHttp.post(`${this.baseUrl}/enviar-api`, {titulos, id_conta_bancaria}).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

  /**
   * Buscar dados do boleto
   * @param entity 
   * @author Marcos Frare
   * @since 21/01/2021
   */
  public buscarDadosBoleto(entity: any): Observable<any> {
    let params = entity;
    return this.customHttp.get(`${this.baseUrl}/geracao-dados`, {params}).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
  }

  /**
   * Geração de boleto após consulta na API e confirmação do usuário
   * @param id_titulo 
   * @param boleto 
   * @author Marcos Frare
   * @since 12/10/2021
   */
  public gerarConsultaApi(id_titulo:number, boleto: Boleto): Observable<any> {
    return this.customHttp.post(`${this.baseUrl}/gerar-consulta-api`, {id_titulo, boleto}).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
  }

  /**
   * Buscar os boletos de um título
   * @param id_titulo 
   * @author Marcos Frare
   * @since 22/01/2022
   * @returns Boleto
   */
  public getByIdTitulo(id_titulo: number): Observable<Boleto[]> {
    return this.customHttp.get(`${this.baseUrl}/titulo/${id_titulo}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
  }

  /**
   * Excluir um boleto de cobrança
   * @param entity 
   * @author Marcos Frare
   * @since 22/01/2022
   */
  public delete(entity: Boleto): Observable<any> {
    return this.customHttp.delete(`${this.baseUrl}/${entity.id}`).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
  }
  
}