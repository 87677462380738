import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MessageService } from 'app/core';
import { CertificadoDigital } from 'app/models';
import { AttachmentComponent } from 'app/shared';
import { CertificadoDigitalHttpService } from '../service';
import { BaseModal } from 'app/componentes-utils/modal';
import { Subscription } from 'rxjs';

declare var jQuery: any;

@Component({
  selector: 'certificado-digital-cadastro-modal',
  templateUrl: './certificado-digital-cadastro-modal.component.html',
  styleUrls: ['./certificado-digital-cadastro-modal.component.css']
})
export class CertificadoDigitalCadastroModalComponent implements OnInit, OnDestroy, BaseModal {

  @Input() idModal: string;
  @Input() id: number;
  @Output() onSave: EventEmitter<CertificadoDigital> = new EventEmitter();

  @ViewChild('anexoComponent') anexoComponent: AttachmentComponent;

  public entity: CertificadoDigital;
  private subs: Subscription[] = [];

  constructor(private messageService: MessageService, private certificadoDigitalHttpService: CertificadoDigitalHttpService) { }

  ngOnInit() {
    this.entity = new CertificadoDigital();
  }

  ngOnDestroy(): void {
    try {
			this.subs.map(s => s.unsubscribe());
		} catch (err) {
			console.error(err);
		}
  }

  public open() {
    this.entity = new CertificadoDigital();
    this.anexoComponent.onFileClear();
    jQuery(`#${this.idModal}`).modal('show');
  }
  public close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  public salvar(): void {
    let btn = jQuery('#btnSaveCertificado');
    btn.button('loading');
    this.subs.push(this.certificadoDigitalHttpService.passwordValidate(this.entity).subscribe(
      res => {
        btn.button('reset');
        if (res.valid) {
          this.entity.vigencia_inicio = res.validity.start || '';
          this.entity.vigencia_fim = res.validity.end || '';
          this.persist();
        } else {
          alert(res.msg || "Certificado ou senha inválidos!");
        }
      },
      error => {
        btn.button('reset');
        console.error(error);
      }
    ));
  }

  private persist(): void {
    let btn = jQuery('#btnSaveCertificado');
    btn.button('loading');
    const fn = this.entity.id ? this.certificadoDigitalHttpService.put(this.entity) : this.certificadoDigitalHttpService.post(this.entity);
    this.subs.push(fn.subscribe(
      () => {
        btn.button('reset');
        this.messageService.success('Certificado salvo com sucesso!');
        this.onSave.emit();
        this.close();
      },
      error => {
        btn.button('reset');
        this.messageService.error('Erro ao salvar certificado! Tente novamente', 'Erro', error);
      }
    ));
  }

}
