import { Injectable } from '@angular/core';
import { TipoConsumo, TarifasConsumo, Unidade } from '../../../models';

@Injectable()
export class ConsumosUnidadesUtilsService {

	constructor() { }

	/**
	 * Busca a categoria tarifária da unidade
	 * @param unidade 
	 * @param tipoConsumo 
	 * @author Marcos Frare
	 * @since 28/02/2020
	 */
	private getTarifasUnidade(unidade: Unidade, tipoConsumo: TipoConsumo): TarifasConsumo[] {
		let tarifas: TarifasConsumo[] = [];
		if (unidade.categorias_servico_unidade && Array.isArray(unidade.categorias_servico_unidade)) {
			for (const catConfig of unidade.categorias_servico_unidade) {
				for (const catTarifa of tipoConsumo.categorias_tarifa_consumo) {
					if (catConfig.id_categoria_tarifa_consumo === catTarifa.id) {
						tarifas = catTarifa.tarifas;
					}
				}
			}
		}

		return tarifas;
	}

	/**
	 * Calcula o valor de consumo da unidade
	 * @param unidade Unidade
	 * @param consumoAnterior Consumo anterior
	 * @param consumoAtual Consumo lido atualmente
	 * @param tipoConsumo Tipo do consumo
	 * @author Marcos Frare
	 * @since 28/02/2020
	 */
	public calcularValorConsumido(unidade: Unidade, consumoAnterior: number, consumoAtual: number, tipoConsumo: TipoConsumo): number {
		// possui categoria(s) e Tarifas para o tipo de consumo
		const possuiTarifas = tipoConsumo.categorias_tarifa_consumo && Array.isArray(tipoConsumo.categorias_tarifa_consumo);
		if (!possuiTarifas) {
			return 0;
		}

		const quantidadeConsumida = (consumoAtual - consumoAnterior);

		// Busca as tarifas da categoria correspondente configurado para a Unidade
		const tarifas = this.getTarifasUnidade(unidade, tipoConsumo);
		if (tarifas.length > 0) {
			for (const tarifa of tarifas) {
				if (quantidadeConsumida >= parseFloat(tarifa.consumo_inicial) && quantidadeConsumida <= parseFloat(tarifa.consumo_final)) {
					const tarifaExtra = quantidadeConsumida - parseFloat(tarifa.consumo_inicial);
					const valorConsumido = (tarifaExtra * parseFloat(tarifa.valor)) + parseFloat(tarifa.consumo_minimo);
					return valorConsumido;
				}
			}
		}
		return 0;
	}

}