import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { AuthService, MessageService } from 'app/core';
import { BlocosHttpService, BlocosPesquisaModalComponent, UnidadesHttpService, UnidadesPesquisaModalComponent } from 'app/home/blocos';
import { Bloco, Unidade, UnidadeIsencao } from 'app/models';
import { TypeaheadMatch } from 'ngx-bootstrap';

declare var jQuery: any;

@Component({
  selector: 'unidade-isencao-modal',
  templateUrl: './unidade-isencao-modal.component.html',
  styleUrls: ['./unidade-isencao-modal.component.css']
})
export class UnidadeIsencaoModalComponent implements OnInit, BaseModal {

  @ViewChild('blocosPesquisaModal') blocosPesquisaModal: BlocosPesquisaModalComponent;
	@ViewChild('unidadesPesquisaModal') unidadesPesquisaModal: UnidadesPesquisaModalComponent;

  @Input() idModal: string;
  @Input() id: number;
  @Output() onSave: EventEmitter<UnidadeIsencao> = new EventEmitter();

  public unidadeIsencao: UnidadeIsencao = new UnidadeIsencao();
  public blocos: Bloco[] = [];
  public unidades: Unidade[] = [];

  constructor(private messageService: MessageService,
    private unidadeHttpService: UnidadesHttpService,
    private blocosHttpService: BlocosHttpService,
    private unidadesHttpService: UnidadesHttpService,
    private authService: AuthService) { }

  ngOnInit() {
    if (!this.idModal) {
      this.idModal = 'unidadeIsencaoModal';
    }
  }

  open() {
    this.getAllBlocosByCondominio();
    this.loadIsencao();
    jQuery(`#${this.idModal}`).modal('show');
  }

  close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Carregar dados da isenção
   * @author Marcos Frare
   * @since 09/08/2021
   */
  private loadIsencao(): void {
    this.unidadeIsencao = new UnidadeIsencao();
    if (this.id) {
      this.unidadeHttpService.getUnidadeIsencao(this.id).subscribe(
        response => {
          this.unidadeIsencao = response;
          this.getAllUnidadesByBloco(this.unidadeIsencao.bloco.id);
        },
        error => {
          this.messageService.error('Erro o carregar dados da isenção! Tente novamente.', 'Erro!', error);
          this.unidadeIsencao = new UnidadeIsencao();
        }
      );
    }
  }

  /**
   * Salvar dados
   * @author Marcos Frare
   * @since 09/08/2021
   */
  public salvar(): void {
    let btn = jQuery('#btnSalvarIsencao');
    btn.button('loading');
    this.unidadeHttpService.saveUnidadeIsencao(this.unidadeIsencao).subscribe(
      () => {
        btn.button('reset');
        this.unidadeIsencao = new UnidadeIsencao();
        this.close();
        this.onSave.emit(this.unidadeIsencao);
      },
      error => {
        btn.button('reset');
        this.messageService.error('Erro ao gravar dados! Tente novamente.', 'Erro!', error);
      }
    );
  }

  private getAllBlocosByCondominio(): void {
		this.blocosHttpService.getAllByCondominio(this.authService.getIdCurrentCondominio()).subscribe(
			blocos => this.blocos = blocos,
			error => this.messageService.error('Erro ao carregar os blocos do condomínio. Tente novamente', 'Erro', error)
		);
	}

	private getAllUnidadesByBloco(idBloco: number): void {
		this.unidadesHttpService.getAllByBloco(idBloco).subscribe(
			unidades => this.unidades = unidades,
			error => this.messageService.error('Erro ao carregar as unidades do bloco. Tente novamente', 'Erro', error)
		);
	}

  public onSelectBloco(tm: TypeaheadMatch): void {
		this.setBloco(tm.item as Bloco);
	}

	private setBloco(selectedBloco: Bloco): void {
		this.unidadeIsencao.bloco = selectedBloco;
		this.unidadeIsencao.id_bloco = selectedBloco.id;
		this.getAllUnidadesByBloco(selectedBloco.id);
	}

	public openBlocosPequisaModal(): void {
		this.blocosPesquisaModal.open();
	}

	public onSelectBlocosPesquisaModal(bloco: Bloco): void {
		if (bloco) this.setBloco(bloco);
		jQuery('#bloco').focus();
	}

	public onCleanBloco(input: any): void {
		this.unidadeIsencao.bloco = null;
		this.unidadeIsencao.id_bloco = null;
		this.unidadeIsencao.unidade = null;
		this.unidadeIsencao.id_unidade = null;
		this.unidades = [];
		input.value = '';
		input.focus();
	}

	// Unidade
	public onSelectUnidade(tm: TypeaheadMatch): void {
		this.setUnidade(tm.item as Unidade);
	}

	private setUnidade(selectedUnidade: Unidade): void {
		this.unidadeIsencao.unidade = selectedUnidade;
		this.unidadeIsencao.id_unidade = selectedUnidade.id;
	}

	public openUnidadesPequisaModal(): void {
		this.unidadesPesquisaModal.open();
	}

	public onSelectUnidadesPesquisaModal(unidade: Unidade): void {
		if (unidade) this.setUnidade(unidade);
		jQuery('#unidade').focus();
	}

	public onCleanUnidade(input: any): void {
		this.unidadeIsencao.unidade = null;
		this.unidadeIsencao.id_unidade = null;
		input.value = '';
		input.focus();
	}

}