import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService, MessageService } from 'app/core';
import { datepickerMesAnoOptions } from 'app/shared';
import { RelatorioModalComponent } from '../relatorio-modal';
import { HistoricoMovimentacaoMensalRelFiltro } from './historico-movimentacao-mensal-rel-filtro';

@Component({
  selector: 'historico-movimentacao-mensal-rel',
  templateUrl: './historico-movimentacao-mensal-rel.component.html',
  styleUrls: ['./historico-movimentacao-mensal-rel.component.css']
})
export class HistoricoMovimentacaoMensalRelComponent implements OnInit {

  public filtro = new HistoricoMovimentacaoMensalRelFiltro();
  public datepickerDataCompetenciaOptions = datepickerMesAnoOptions;

  @ViewChild('relatorioModal') relatorioModal: RelatorioModalComponent;

  constructor(private authService: AuthService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.limpar();
    this.filtro.id_cliente = this.authService.getIdCurrentCliente();
    this.filtro.id_condominio = this.authService.getIdCurrentCondominio();
    this.filtro.id_usuario = this.authService.getIdCurrentUser();
  }

  public limpar() {
    this.filtro.limpar();
  }

  private validateView(): boolean {
    if (!this.filtro.competencia_inicial) {
      this.messageService.info('É necessário informar a competência inicial', 'Informação');
      return false;
    }
    if (!this.filtro.competencia_final) {
      this.messageService.info('É necessário informar a competência final', 'Informação');
      return false;
    }
    return true;
  }

  public view(): void {
    if (this.validateView()) {
      this.relatorioModal.url = '/relatorio/historico-movimentacao-mensal-pdf';
      this.relatorioModal.params = this.filtro;
      this.relatorioModal.open();
    }
  }

}