import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { AuthService, MessageService } from 'app/core';
import { CategoriaContas } from 'app/models';
import { CategoriaContasHttpService } from '../services';

declare var jQuery: any;

@Component({
  selector: 'categoria-contas-pesquisa-completa-modal',
  templateUrl: './categoria-contas-pesquisa-completa-modal.component.html',
  styleUrls: ['./categoria-contas-pesquisa-completa-modal.component.css']
})
export class CategoriaContasPesquisaCompletaModalComponent implements OnInit, BaseModal {

  @Input() tipo: string;
  @Input() idModal: string; // id do modal para identificar um modal caso em uma mesma tela possa ter mais de um modal
  @Output() select = new EventEmitter<CategoriaContas>();
  public arCategoriaConta: any[] = [];

  constructor(private messageService: MessageService,
      private authService: AuthService,
      private categoriaContasHttpService: CategoriaContasHttpService) { }

  ngOnInit() {
  }

  open() {
    this.loadData();
    jQuery(`#${this.idModal}`).modal('show');
  }

  close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Carrega estrutura do plano de contas do condomínio
   * @author Marcos Frare
   * @since 02/11/2020
   */
  private loadData(): void {
    this.arCategoriaConta = [];
    this.categoriaContasHttpService.getPlanoCompletoCondominio(this.authService.getIdCurrentCondominio()).subscribe(
      response => {
        this.arCategoriaConta = response;
      },
      error => {
        this.messageService.error('Erro ao carregar plano de contas do condomínio!', 'Erro!', error);
        this.close();
      }
    );
  }

  /**
   * Evento de click na conta
   * @param conta 
   * @author Marcos Frare
   * @since 02/11/2020
   */
  public onClickConta(conta: any): void {
    let catConta = new CategoriaContas();
    catConta.id = conta.id;
    catConta.codigo = conta.codigo;
    catConta.nome = conta.nome;
    
    this.select.emit(catConta);
    this.close();
  }

}