import { Component, Input, OnInit } from '@angular/core';
import { ResponseContentType } from '@angular/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ConfigService, CustomHttp, MessageService } from 'app/core';
import { NgxSpinnerService } from 'ngx-spinner';

declare var jQuery: any;

@Component({
  selector: 'visualiza-anexo',
  templateUrl: './visualiza-anexo.component.html',
  styleUrls: ['./visualiza-anexo.component.css']
})
export class VisualizaAnexoComponent implements OnInit {

  @Input() idModal: string;
  @Input() urlSafe: SafeResourceUrl;
  @Input() id_anexo: number;
  @Input() fileType: string;

  private urlBase: string;

  constructor(private messageService: MessageService,
      private customHttp: CustomHttp,
      private configService: ConfigService,
      private domSanitizer: DomSanitizer,
      private spinner: NgxSpinnerService) {
    this.urlBase = `${configService.getBaseUrl()}`;
  }

  ngOnInit() {
    if (!this.idModal) {
      this.idModal = 'visualizaAnexoModal';
    }
  }

  open() {
    try {
      this.spinner.show();
      if (this.id_anexo && this.fileType) {
        this.customHttp.get(`${this.urlBase}/anexo/busca-id/${this.id_anexo}`, { responseType: ResponseContentType.Blob }).subscribe(
          res => {
            jQuery(`#${this.idModal}`).modal('show');
            this.spinner.hide();
            const blob = new Blob([res.blob()], { type: this.fileType });
            const fileURL = URL.createObjectURL(blob);
            this.urlSafe = this.domSanitizer.bypassSecurityTrustResourceUrl(fileURL);
          },
          error => {
            this.spinner.hide();
            this.messageService.error('Erro ao visualizar arquivo!', 'Erro!', error);
          }
        );
      } else {
        this.spinner.hide();
        this.messageService.warning('Tipo de arquivo não definido! Tente novamente', 'Atenção!');
      }
    } catch (e) {
      this.spinner.hide();
      this.messageService.error('Erro ao visualizar arquivo!', 'Erro!', e);
      return e;
    }
  }

  close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

}