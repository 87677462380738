import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseVisualizacaoComponent } from '../../componentes-base';
import { MessageService, AuthService } from '../../core';
import { Fornecedor, BaseEntity } from '../../models';
import { FornecedoresHttpService } from './services';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
	selector: 'fornecedores',
	templateUrl: 'fornecedores.template.html',
	providers: [PermissionService]
})
export class FornecedoresComponent extends BaseVisualizacaoComponent<Fornecedor>  {
	constructor(activatedRoute: ActivatedRoute,
		router: Router,
		messageService: MessageService,
		private fornecedoresHttpService: FornecedoresHttpService,
		private authService: AuthService,
		permissionService: PermissionService,
		public spinner: NgxSpinnerService) {
		super('#tableFornecedores', fornecedoresHttpService, activatedRoute, router, messageService, permissionService, spinner);
	}

	public getAll(): void {
		this.spinner.show();
		const idCliente = this.authService.getIdCurrentCliente();
		this.fornecedoresHttpService.getAllByCliente(idCliente).subscribe(
			entities => {
				this.spinner.hide();
				this.entities = entities;
				this.showTable();
			},
			error => {
				this.spinner.hide();
				this.messageService.error('Erro', 'Não foi possível carregar os grupos de acessos');
			}
		);
	}
}
