import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CustomHttp } from 'app/core';

import { MessageService, ConfigService } from '../../../core';
import { BaseModal } from '../../../componentes-utils/modal';
import { ResponseContentType } from '@angular/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { BoletosHttpService } from 'app/home/boletos/services';
import { EnvioEmailBoletoModalComponent } from 'app/home/boletos/envio-email-boleto-modal';

declare var jQuery: any;

@Component({
  selector: 'relatorio-modal',
  templateUrl: './relatorio-modal.component.html',
  styleUrls: ['./relatorio-modal.component.css']
})
export class RelatorioModalComponent implements OnInit, BaseModal {
  @Input() idModal: string;
  @Input() titulo: string;
  @Input() url: string;
  @Input() urlSafe: SafeResourceUrl;
  @Input() urlBase: string;
  @Input() params: any;
  
  @Input() listarTitulos: boolean;

  @ViewChild('envioEmailBoletoModal') envioEmailBoletoModal: EnvioEmailBoletoModalComponent;

  constructor(private messageService: MessageService,
              private customHttp: CustomHttp,
              private configService: ConfigService,
              private boletoHttpService: BoletosHttpService,
              private domSanitizer: DomSanitizer,
              private spinner: NgxSpinnerService) {
    this.urlBase = `${configService.getBaseUrl()}`;
    this.listarTitulos = false;
  }

  ngOnInit() {
    if (!this.idModal) {
      this.idModal = 'relatorioModal';
    }
  }

  open() {
    try {
      this.spinner.show();
      this.customHttp.post(this.urlBase+this.url, this.params, { responseType: ResponseContentType.Blob }).subscribe(
        res => {
          this.spinner.hide();
          jQuery(`#${this.idModal}`).modal('show');
          const blob = new Blob([res.blob()], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(blob);
          this.urlSafe = this.domSanitizer.bypassSecurityTrustResourceUrl(fileURL);
        },
        error => {
          this.spinner.hide();
          this.messageService.error('Erro ao gerar relatório!!', 'Erro!!', error);
        }
      );
    } catch (e) {
      this.spinner.hide();
      this.messageService.error('Erro ao gerar relatório!!', 'Erro!!', e);
      return e;
    }
  }

  close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Enviar títulos listados por e-mail
   * @author Marcos Frare
   * @since 27/08/2020
   */
  public enviarEmail(): void {
    if (confirm(`Confirma envio dos títulos selecionados para e-mail?`)) {
      this.spinner.show();
      this.boletoHttpService.sendMailFiltro(this.params).subscribe(
        response => {
          this.spinner.hide();
          this.envioEmailBoletoModal.objetoEmail = response;
          this.envioEmailBoletoModal.open();
        },
        error => {
          this.spinner.hide();
          this.messageService.error('Erro ao enviar e-mails. Tente novamente', 'Erro!', error);
        }
      );
    }
  }

}