import { map, catchError } from 'rxjs/operators';

import { Observable, forkJoin } from 'rxjs';
import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { MessageService, FormsUtilsService } from '../../../../../core';

import { Contato } from '../../../../../models';
import { UnidadesContatosHttpService } from '../services';

declare var jQuery: any;

@Component({
	selector: 'unidades-contatos-modal',
	templateUrl: './unidades-contatos-modal.component.html',
	styleUrls: ['./unidades-contatos-modal.component.css'],
	providers: [
		UnidadesContatosHttpService
	]
})

export class UnidadesContatosModalComponent {
	@ViewChild('formUnidadesContatosModal') form: NgForm;

	idUnidade: number;
	nomeUnidade: string;
	entities: Contato[];

	constructor(private messageService: MessageService,
		private formsUtilsService: FormsUtilsService,
		private unidadesContatosHttpService: UnidadesContatosHttpService) { }


	open(idUnidade: number, nomeUnidade: string) {
		this.idUnidade = idUnidade;
		this.nomeUnidade = nomeUnidade;
		this.unidadesContatosHttpService.getAllByUnidade(idUnidade).subscribe(
			entities => {
				this.entities = entities;
				jQuery('#unidadesContatosModal').modal('show');
			},
			error => this.messageService.error('Erro ao carregar os contatos da unidade. Tente novamente', 'Erro', error)
		);
	}

	close() {
		jQuery(`#unidadesContatosModal`).modal('hide');
	}

	/** Adiciona um novo contato */
	insert() {
		const entity = new Contato();
		entity.id_unidade = this.idUnidade;
		// Tipo do contato e-mail
		entity.tipo = 1;
		this.entities.push(entity);
	}

	/** Alterar alguma informação do contato */
	onChange(entity: Contato) {
		entity.editado = true;
	}

	/** Cria os observables para salvar os contatos alteradas simultaneamente **/
	private createSave(): Observable<Contato>[] {
		const list: Observable<Contato>[] = [];
		for (const entity of this.entities) {
			if (entity.editado) {
				let observable: Observable<Contato>;
				if (entity.id) { // PUT
					observable = this.unidadesContatosHttpService.put(entity).pipe(
						map(response => response),
						catchError(error => {
							this.messageService.error(`Erro ao salvar o contato ${entity.valor}. Tente novamente`, 'Erro', error);
							return Observable.throw(error);
						})
					);
				} else { // POST
					observable = this.unidadesContatosHttpService.post(entity).pipe(
						map(response => {
							entity.id = response.id;
							return response;
						}),
						catchError(error => {
							this.messageService.error(`Erro ao salvar o contato ${entity.valor}. Tente novamente`, 'Erro', error);
							return Observable.throw(error);
						})
					);
				}
				list.push(observable);
			}
		}
		return list;
	}

	/** Salva os contatos */
	save() {
		const saves = this.createSave();
		if (saves.length > 0) {
			this.formsUtilsService.makeAllFieldsDirty(this.form);

			if (this.form.valid) {
				const btnSave = jQuery('#btnSaveUnidadesContatosModal');
				btnSave.button('loading');
				forkJoin(saves).subscribe(
					() => {
						btnSave.button('reset');
						this.messageService.success('', 'Contatos salvos com sucesso');
						this.close();
					},
					error => {
						btnSave.button('reset');
					});
			} else {
				this.formsUtilsService.showMsgFormInvalid();
			}
		} else {
			this.close();
		}
	}

	/** Deleta o contato informada */
	delete(entity: Contato) {
		const valor = entity.valor ? ' ' + entity.valor.toString() : '';
		if (confirm(`Tem certeza que deseja excluir o contato ${valor}?`)) {
			if (entity.id) { // Com id deleta do banco de dados
				this.unidadesContatosHttpService.delete(entity).subscribe(
					() => {
						const index = this.entities.findIndex(nota => nota.id === entity.id);
						this.deleteOfList(index);
						this.messageService.success('', `O e-mail ${valor} foi excluída com sucesso`);
					},
					error => this.messageService.error(`Erro ao exlcuir o e-mail ${valor}. Tente novamente`, 'Erro', error)
				);
			} else { // Sem id deleta em memória
				const index = this.entities.indexOf(entity);
				if (this.deleteOfList(index)) {
					this.messageService.success('', `O e-mail ${valor} foi excluída com sucesso`);
				}
			}
		}
	}

	/** Deleta o contato do array de contatos em memória */
	private deleteOfList(index: number): boolean {
		if (index > -1) {
			this.entities.splice(index, 1);
			return true;
		}
		return false;
	}

}