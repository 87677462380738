import { BaseEntity } from '../base-entity';
import { Usuario } from './usuario';
import { CategoriaServicoUnidade } from './categoria-servico-unidade';
import { TipoConsumo } from './tipo-consumo';

export const tiposUnidade = [
	{ id: 100, nome: 'Todas' },
	{ id: 1, nome: 'Apartamento' },
	{ id: 2, nome: 'Casa' },
	{ id: 3, nome: 'Terreno' },
	{ id: 4, nome: 'Sala Comercial' },
	{ id: 5, nome: 'Despensa' },
	{ id: 6, nome: 'Box' },
	{ id: 7, nome: 'Garagem' },
	{ id: 8, nome: 'Galpão' },
	{ id: 99, nome: 'Outros' }
];

export const tiposEnvioBoleto = [
	{ id: 1, nome: 'E-mail' },
	{ id: 2, nome: 'No Condomínio' },
	{ id: 3, nome: 'Correio' },
	{ id: 99, nome: 'E-mail e condomínio' }
];

export const cobrarFundoReserva = [
	{ tag: 'PADRAO', nome: 'Config. Condomínio' },
	{ tag: 'PROPRIETARIO', nome: 'Proprietário' },
	{ tag: 'SACADO', nome: 'Sacado' },
];

export class Unidade extends BaseEntity {
	nome: string;
	nome_unidade: string;
	tipo: number;
	tipo_envio_boleto: number;
	id_bloco: number;
	id_sacado: number;
	nome_sacado: string;
	andar: number;
	fracao_solo: any;
	ocupacao: number;
	bloco: string;
	area: any;
	sacado: Usuario;
	sacadoNotFound: boolean; // Flag para indicar que proprietario não foi encontrado no typeahead
	permiteInformarSacado: boolean;

	pode_excluir: boolean; // Flag se pode ou não excluir a unidade

	proprietarios_moradores: any[];

	categorias_servico_unidade: CategoriaServicoUnidade[];
	tipos_consumo: TipoConsumo[];

	nome_bloco: string;

	name: string;

	constructor() {
		super();
		this.permiteInformarSacado = true;
	}
}

export class UnidadeBulk extends BaseEntity {
	constructor(public id_cliente?: number,
		public id_condominio?: number,
		public id_bloco?: number,
		public quantidadeAndar?: number,
		public quantidadePorAndar?: number,
		public tipo?: number) {
		super();
	}
}