import { NgModule } from '@angular/core';
import { AntecipacaoTaxaCondominioRelComponent } from './antecipacao-taxa-condominio-rel.component';
import { SharedModule } from 'app/shared';
import { TypeaheadModule } from 'ngx-bootstrap';

@NgModule({
  imports: [
    SharedModule,
    TypeaheadModule
  ],
  declarations: [
    AntecipacaoTaxaCondominioRelComponent
  ]
})
export class AntecipacaoTaxaCondominioRelModule { }