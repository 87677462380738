import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseEntity } from '../models';
import { Crud, MessageService } from '../core';
import { Permission } from './permission';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';

declare var jQuery: any;

export class BaseVisualizacaoComponent<T extends BaseEntity> implements OnInit, OnDestroy {

	entities: T[] = [];
	dataTable: any;

	private subscriptions: Subscription[] = [];

	public permissions: Permission;
	public resource: string;
	public useDatatable: boolean = true;

	constructor(private gridId: string,
		protected crudService: Crud<T>,
		protected activatedRoute: ActivatedRoute,
		protected router: Router,
		protected messageService: MessageService,
		protected permissionService: PermissionService,
		protected spinner: NgxSpinnerService) {
		this.resource = activatedRoute.data['value']['resource'];
	}

	ngOnInit() {
		this.getAll();
		this.getPermissions();
	}

	ngOnDestroy(): void {
		try {
			this.subscriptions.map(s => s.unsubscribe());
		} catch(err) {
			console.error(err);
		}
	}

	/**
	 * Carrega todos os registros cadastrados da Entidade
	 */
	getAll() {
		this.spinner.show();
		this.destroyTable();
		this.subscriptions.push(this.crudService.getAll().subscribe(
			entities => {
				this.spinner.hide();
				this.entities = entities;
				this.showTable();
			},
			error => {
				this.spinner.hide();
				this.messageService.error('Erro ao realizar a consulta. Tente novamente', 'Erro', error);
			}
		));
	}

	/**
	 * Executa a biblioteca DataTables na table de visualização
	 */
	showTable(notOrdered?, detailData?) {
		if (!this.useDatatable) return;
		if (notOrdered) {
			setTimeout(() =>
				this.dataTable = jQuery(this.gridId).DataTable({
					ordering: false,
					columnDefs: [{
						width: '40px'
					}],
					pageLength: 50,
					dom: 'lBfrtip',
					buttons: [{
						class: 'btn-g',
						extend: 'excel',
						text: '<b>Exportar</b>',
						fileneme: 'ultracondominio'
					}, {
						class: 'btn-g',
						extend: 'print',
						text: '<b>Imprimir</b>',
					}],
					language: { 'url': './assets/scripts/datatables-ptbr.json' },
					destroy: true,
				}
				), 200);
		} else {
			setTimeout(() =>
				this.dataTable = jQuery(this.gridId).DataTable({
					columnDefs: [{
						orderable: false, targets: -1, width: '40px'
					}],
					pageLength: 50,
					dom: 'lBfrtip',
					buttons: [{
						class: 'btn-g',
						extend: 'excel',
						text: '<b>Exportar</b>',
						fileneme: 'ultracondominio',
					}, {
						class: 'btn-g',
						extend: 'print',
						text: '<b>Imprimir</b>',
					}],
					language: { 'url': './assets/scripts/datatables-ptbr.json' },
					destroy: true,
				}
				), 200);
		}

		if (detailData) {
			setTimeout(() =>
			jQuery('#' + this.gridId + ' tbody').on('click', 'td.'+ detailData.elementId, function () {
				var tr = jQuery(this).closest('tr');
				var row = this.dataTable.row( tr );
	
				if ( row.child.isShown() ) {
						// This row is already open - close it
						row.child.hide();
						tr.removeClass('shown');
				}
				else {
						// Open this row
						row.child(detailData.html).show();
						tr.addClass('shown');
				}
			}), 400);
		}
	}

	/**
	 * Destroi a table para poder reexibi-la novamente em seguida
	 */
	destroyTable() {
		if (this.dataTable) {
			this.dataTable.destroy();
		}
	}

	insert() {
		this.router.navigate(['./new'], { relativeTo: this.activatedRoute });
	}

	edit(entity: T) {
		this.router.navigate(['./', entity.id], { relativeTo: this.activatedRoute });
	}

	public getPermissions() {
		this.subscriptions.push(this.permissionService.getPermissions(this.resource).subscribe(
			permissions => this.permissions = permissions
		));
	}

}