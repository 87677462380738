export class TitulosFiltro {
	id_condominio: number;
	id_usuario: number;
	periodo: number;
	id_unidade: number;
	data_inicial: Date;
	data_final: Date;
	situacao: number;
	unidades_id: number[];
	aberto: boolean;

	/** Limpa o filtro */
	public limpar() {
		this.periodo = 1;
		this.situacao = null;
		this.data_inicial = null;
		this.data_final = null;
		this.aberto = false;
	}
}

export const periodos = [
	{ id: 1, nome: "Última gerada" },
	{ id: 2, nome: "Últimos 6 meses" },
	{ id: 3, nome: "Últimos 12 meses" },
	{ id: 4, nome: "Este ano" },
	{ id: 99, nome: "Selecione" },
]