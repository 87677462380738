import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'floatFormat'
})
export class FloatFormatPipe implements PipeTransform {

    transform(value: any, mode: string = 'c', minIntegerDigits: number = 1, minFractionDigits: number = 2, maxFractionDigits: number = 2) {
        if (value === null) return '';
        if (typeof value !== 'number') value = Number(value);

        switch (mode) {
            case 'currency':
                return value.toLocaleString('pt-br', {minimumIntegerDigits: minIntegerDigits, minimumFractionDigits: minFractionDigits, maximumFractionDigits: maxFractionDigits});
            case 'consumo':
                return value.toLocaleString('pt-br', {style: 'decimal', minimumIntegerDigits: 1, minimumFractionDigits: 3, maximumFractionDigits: 3});
            case 'c':
                return value;
            case 'm':
                return value;
            case 'dm':
                return value;
            case 'cm':
                return value;
            case 'cc':
                return value.toLocaleString('pt-br', {style: 'decimal', minimumIntegerDigits: 1, minimumFractionDigits: 8, maximumFractionDigits: 8});
            case 'valueConsumo':
                return value.toLocaleString('pt-br', {minimumIntegerDigits: minIntegerDigits, minimumFractionDigits: minFractionDigits, maximumFractionDigits: maxFractionDigits});
            default:
                return '';
        }
    }
}