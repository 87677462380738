import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseVisualizacaoComponent } from 'app/componentes-base';
import { AuthService, MessageService } from 'app/core';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { ModeloVeiculo } from 'app/models';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModeloVeiculoCadastroModalComponent } from './modelo-veiculo-cadastro-modal';
import { ModeloVeiculoHttpService } from './service';

@Component({
  selector: 'modelo-veiculo',
  templateUrl: './modelo-veiculo.component.html',
  styleUrls: ['./modelo-veiculo.component.css']
})
export class ModeloVeiculoComponent extends BaseVisualizacaoComponent<ModeloVeiculo> {

  @ViewChild('modeloVeiculoCadastroModal') modeloVeiculoCadastroModal: ModeloVeiculoCadastroModalComponent;

  constructor(public activatedRoute: ActivatedRoute,
      public router: Router,
      public messageService: MessageService,
      private authService: AuthService,
      private modeloVeiculoHttpService: ModeloVeiculoHttpService,
      public permissionService: PermissionService,
      public spinner: NgxSpinnerService) {
    super('#tableModeloVeiculo', modeloVeiculoHttpService, activatedRoute, router, messageService, permissionService, spinner);
  }

  ngOnInit() {
    if (!this.authService.isAdmin) {
      this.router.navigate(['/dashboard'], { relativeTo: this.activatedRoute });
    }
    this.search();
  }

  /**
   * Excluir ummodelo
   * @param entity Objeto a ser excluído
   * @author Marcos Frare
   * @since 10/04/2021
   */
  public delete(entity: ModeloVeiculo): void {
    if (confirm(`Deseja excluir o modelo ${entity.nome}?`)) {
      this.modeloVeiculoHttpService.delete(entity).subscribe(
        () => {
          this.search();
          this.messageService.success('Modelo excluído com sucesso!', 'Sucesso!');
        },
        error => {
          this.messageService.error(`Erro ao excluir modelo ${entity.nome}!`, 'Erro', error);
        }
      );
    }
  }

  /**
   * Busca dados
   * @author Marcos Frare
   * @since 10/04/2021
   */
  public search(): void {
    super.getAll();
  }

  /**
   * Editar registro
   * @param entity 
   * @author Marcos Frare
   * @since 10/04/2021
   */
  public edit(entity: ModeloVeiculo): void {
    this.modeloVeiculoCadastroModal.id = entity.id;
    this.modeloVeiculoCadastroModal.open();
  }

  /**
   * Incluir novo banco
   * @author Marcos Frare
   * @since 10/04/2021
   */
  public insert(): void {
    this.modeloVeiculoCadastroModal.id = null;
    this.modeloVeiculoCadastroModal.open();
  }
}