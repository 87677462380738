import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared';

import { GruposDeUsuariosComponent } from './grupos-de-usuarios.component';
import { GruposDeUsuariosCadastroComponent } from './cadastro';
import { AcessosComponent } from './acessos';

import { GruposDeUsuariosHttpService } from './services';
import { AcessosPainelComponent } from './acessos-painel';

@NgModule({
	imports: [
		SharedModule
	],
	declarations: [
		GruposDeUsuariosComponent,
		GruposDeUsuariosCadastroComponent,
		AcessosComponent,
		AcessosPainelComponent
	],
	providers: [
		GruposDeUsuariosHttpService
	]
})
export class GruposDeUsuariosModule { }
