import { Bloco } from "app/models";

export class CondominosRelFiltro {
	id_cliente: number;
	id_condominio: number;
	id_usuario: number;
	tipo: number;
	listagem: number;
	situacao: string;
	vinculo: string;

	bloco: Bloco;
	id_bloco: number;
	ids_unidades: number[];

	/** Limpa o filtro */
	public limpar() {
		this.bloco = null;
		this.id_bloco = null;
		this.ids_unidades = [];
		this.tipo = null;
		this.listagem = 1;
		this.situacao = 'TODOS';
		this.vinculo = 'TODOS';
	}

}