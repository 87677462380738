import { Pipe, PipeTransform } from '@angular/core';

import {DESTINATARIO, destinatarios} from '../../../models';

@Pipe({
    name: 'tiposDestinatarios'
})
export class TiposDestinatariosPipe implements PipeTransform {
    transform(value: any[], tipo: string, sindico: boolean = false): any[] {
        if (!value) {
            return [];
        };
        switch (tipo) {
            case 'funcionarioSindico':
                return destinatarios.filter(destinatario => (
                    destinatario.id === DESTINATARIO.ADMINISTRADORA ||
                    destinatario.id === DESTINATARIO.CONDOMINO));
            case 'funcionarioNormal':
                return destinatarios.filter(destinatario => (
                    destinatario.id === DESTINATARIO.CONDOMINO ||
                    destinatario.id === DESTINATARIO.SINDICO));
            case 'funcionarioTodos':
                return destinatarios.filter(destinatario => (
                    destinatario.id === DESTINATARIO.ADMINISTRADORA));
            case 'condominoSindico':
                if (sindico) {
                    return destinatarios.filter(destinatario => (
                        destinatario.id === DESTINATARIO.ADMINISTRADORA));
                } else {
                    return destinatarios.filter(destinatario => (
                        destinatario.id === DESTINATARIO.ADMINISTRADORA ||
                        destinatario.id === DESTINATARIO.SINDICO));
                }
            case 'condominoNormal':
                return destinatarios.filter(destinatario => (
                    destinatario.id === DESTINATARIO.ADMINISTRADORA ||
                    destinatario.id === DESTINATARIO.SINDICO));
            default:
                return [];
        }
    }
}
