import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';

import { Crud, AuthService, ConfigService, CrudService, CustomHttp } from 'app/core';
import { PrevisaoOrcamentaria, PrevisaoCategoriaConta } from 'app/models';

@Injectable()
export class PrevisaoOrcamentariaHttpService implements Crud<PrevisaoOrcamentaria> {

	public resource = 'previsaoorcamentaria';
	private baseUrl: string;

	constructor(private customHttp: CustomHttp,
		private crudService: CrudService,
		private configService: ConfigService,
		private authService: AuthService) {
		this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
	}

	/**
	 * Carregar dados iniciais para previsão orçamentária
	 * @param entity 
	 * @author Marcos Frare
	 * @since 15/06/2020
	 */
	public carregar(entity: PrevisaoOrcamentaria): Observable<any> {
		return this.customHttp.post(`${this.baseUrl}/carregar`, entity).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Sugerir período
	 * @param entity 
	 * @author Marcos Frare
	 * @since 16/06/2020
	 */
	public sugerirPeriodo(entity: PrevisaoOrcamentaria): Observable<any> {
		const id_condominio = entity.id_condominio;
		return this.customHttp.get(`${this.baseUrl}/periodo`, { params: { id_condominio } }).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Buscar dados de uma previsão já cadastrada
	 * @param id 
	 * @author Marcos Frare
	 * @since 17/06/2020
	 */
	public buscarDadosId(id: number): Observable<any> {
		return this.customHttp.get(`${this.baseUrl}/consultar/${id}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Persistir todas as contas do orçamento
	 * @param contas 
	 * @author Marcos Frare
	 * @since 23/06/2020
	 */
	public saveOrcamento(previsaoOrcamentaria: PrevisaoOrcamentaria): Observable<any> {
		return this.customHttp.post(`${this.baseUrl}`, previsaoOrcamentaria).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Buscar as contas de uma determinada previsão orçamentária
	 * @param id 
	 * @author Marcos Frare
	 * @since 19/06/2020
	 */
	public buscarContas(id: number): Observable<PrevisaoCategoriaConta[]> {
		return this.customHttp.get(`${this.baseUrl}/contas/buscar-previsao/${id}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Aplica índice nas contas que recebem valor
	 * @param previsaoOrcamentaria 
	 * @author Marcos Frare
	 * @since 22/06/2020
	 */
	public aplicarIndice(previsaoOrcamentaria: PrevisaoOrcamentaria): Observable<any> {
		const entity = {
			id: previsaoOrcamentaria.id,
			indice_calculo: previsaoOrcamentaria.indice_calculo,
			referencia_calculo: previsaoOrcamentaria.referencia_calculo
		}
		return this.customHttp.post(`${this.baseUrl}/aplicar-indice`, entity).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Sincronizar contas do plano de contas com previsão orçamentária
	 * @param previsaoOrcamentaria 
	 * @author Marcos Frare
	 * @since 22/06/2020
	 */
	public sincronizarContas(previsaoOrcamentaria: PrevisaoOrcamentaria): Observable<any> {
		const entity = {
			id_previsao_orcamentaria: previsaoOrcamentaria.id,
			id_condominio: previsaoOrcamentaria.id_condominio
		}
		return this.customHttp.post(`${this.baseUrl}/contas/sincronizar-contas`, entity).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	get(id: number): Observable<PrevisaoOrcamentaria> {
		return this.crudService.get<PrevisaoOrcamentaria>(this.resource, id);
	}

	getAll(): Observable<PrevisaoOrcamentaria[]> {
		return this.crudService.getAll<PrevisaoOrcamentaria>(this.resource, { params: { id_condominio: this.authService.getIdCurrentCondominio() } });
	}

	post(entity: PrevisaoOrcamentaria): Observable<PrevisaoOrcamentaria> {
		return this.crudService.post<PrevisaoOrcamentaria>(this.resource, entity);
	}

	put(entity: PrevisaoOrcamentaria): Observable<PrevisaoOrcamentaria> {
		return this.crudService.put<PrevisaoOrcamentaria>(this.resource, entity);
	}

	delete(entity: PrevisaoOrcamentaria): Observable<string> {
		const params = { body: { id_usuario: this.authService.getCurrentUser().id } };
		return this.customHttp.delete(`${this.baseUrl}/${entity.id}`, params).pipe(
			map(response => response),
			catchError(error => _throw(error))
		);
	}

}