import { AcessosHttpService } from './acessos/services/acessos-http.service';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseVisualizacaoComponent } from '../../componentes-base';
import { GrupoDeUsuario } from '../../models';
import { MessageService, AuthService } from '../../core';
import { GruposDeUsuariosHttpService } from './services';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
	selector: 'grupos-de-usuarios',
	templateUrl: 'grupos-de-usuarios.component.html',
	providers: [AcessosHttpService, PermissionService]
})
export class GruposDeUsuariosComponent extends BaseVisualizacaoComponent<GrupoDeUsuario>  {

	constructor(gruposDeUsuariosHttpService: GruposDeUsuariosHttpService,
			activatedRoute: ActivatedRoute,
			router: Router,
			messageService: MessageService,
			private acessosHttpService: AcessosHttpService,
			permissionService: PermissionService,
			private authService: AuthService,
			public spinner: NgxSpinnerService) {
		super('#tableGruposDeUsuarios', gruposDeUsuariosHttpService, activatedRoute, router, messageService, permissionService, spinner);
	}


	ngOnInit() {
		this.getAll();
		this.getPermissions();
	}

	getAll() {
		this.spinner.show();
		const idCliente = this.authService.getIdCurrentCliente();
		this.acessosHttpService.getAllByCliente(idCliente).subscribe(
			entities => {
				this.spinner.hide();
				this.entities = entities;
				this.showTable();
			},
			error => {
				this.spinner.hide();
				this.messageService.error('Erro', 'Não foi possível carregar os grupos de acessos');
			}
		);
	}
}
