export class ComparativoPrevisaoReceitaDespesaRelFiltro {
  id_cliente: number;
  id_condominio: number;
  id_usuario: number;
  competencia_inicial: Date;
  competencia_final: Date;
  categoriaContaReceitaIds: any;
  categoriaContaDespesaIds: any;
  desconsiderarExtratoridinaria: boolean;
  desconsiderarNaoRateada: boolean;
  expandido: boolean;

  /** Limpa o filtro */
  limpar() {
    const today = new Date();
    // Como padrão, os últimos 12 meses
    this.competencia_inicial = new Date(today.getFullYear(), today.getMonth()-12, 1);
    this.competencia_final = new Date(today.getFullYear(), today.getMonth(), 0);
    this.categoriaContaReceitaIds = [];
    this.categoriaContaDespesaIds = [];
    this.desconsiderarExtratoridinaria = false;
    this.desconsiderarNaoRateada = false;
    this.expandido = false;
  }

}