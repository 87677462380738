import { BaseEntity } from "../base-entity";

export class CertificadoDigital extends BaseEntity {
	nome: string;
	vigencia_inicio: string;
	vigencia_fim: string;
	senha: string;
	certificado_pfx: any;
	certificado_crt: any;
	certificado_pem: any;

	constructor() {
		super();
	}
}