import { NgModule } from '@angular/core';
import { EnvioEmailLogModalComponent } from './envio-email-log-modal.component';
import { SharedModule } from 'app/shared';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    EnvioEmailLogModalComponent
  ],
  exports: [
    EnvioEmailLogModalComponent
  ]
})
export class EnvioEmailLogModalModule { }
