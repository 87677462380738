import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import { BaseModal } from 'app/componentes-utils/modal';
import { AuthService, MessageService } from 'app/core';
import { UnidadesHttpService } from 'app/home/blocos';
import { Bloco, PagamentoUnidade, Unidade } from 'app/models';
import { LancamentosUnidadesPagamentosHttpService } from '../services';

declare var jQuery: any;

@Component({
  selector: 'lancamentos-unidades-pagamentos-consulta-modal',
  templateUrl: './lancamentos-unidades-pagamentos-consulta-modal.component.html',
  styleUrls: ['./lancamentos-unidades-pagamentos-consulta-modal.component.css']
})
export class LancamentosUnidadesPagamentosConsultaModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @Input() idUnidade: number;
	@Output() select = new EventEmitter<PagamentoUnidade[]>();
  
	public arPagamentoUnidade: PagamentoUnidade[] = [];
  public allSelected: boolean = false;

  public blocos: Bloco[] = [];
  public unidades: Unidade[] = [];

  public filtro: any = {};

  public multiselectSettings: IMultiSelectSettings = {
		enableSearch: true,
		containerClasses: 'container-multiselect',
		showUncheckAll: true,
		showCheckAll: true,
		dynamicTitleMaxItems: 3,
		checkedStyle: 'checkboxes',
		buttonClasses: 'btn btn-default',
		itemClasses: 'dropdown-menu'
	};
	public multiselectOptionsUnidades: IMultiSelectOption[];
	public multiselectTextsUnidades: IMultiSelectTexts = {
		checkAll: 'Selecionar todas',
		uncheckAll: 'Limpar',
		checked: 'selecionada',
		checkedPlural: 'selecionadas',
		searchPlaceholder: 'Pesquisar...',
		defaultTitle: 'Selecionar',
		allSelected: 'Todas selecionadas',
		searchEmptyResult: 'Nenhum registro encontrado',
		searchNoRenderText: 'Digite para pesquisar',
	};

  constructor(private authService: AuthService,
		private messageService: MessageService,
    private lancamentosUnidadesPagamentosHttpService: LancamentosUnidadesPagamentosHttpService,
    private unidadesHttpService: UnidadesHttpService) { }

  ngOnInit() {
    this.getUnidades();
    if (!this.idModal) {
			this.idModal = 'lancamentoUnidadePagamentoPesquisaModal';
		}
  }

  /**
   * Busca os pagamentos em aberto
   * @author Marcos Frare
   * @since 16/08/2021
   */
   private getAllPagamentos(): void {
    this.arPagamentoUnidade = [];
    this.lancamentosUnidadesPagamentosHttpService.getAllSelectCondominio(this.authService.getIdCurrentCondominio(), this.filtro).subscribe(
      response => {
        this.arPagamentoUnidade = response;
      },
      error => {
        this.messageService.error('Erro ao buscar lançamentos das unidades! Tente novamente.', 'Erro!', error);
      }
    );
  }

  open() {
    this.setInitialFilter();
    this.getAllPagamentos();
		jQuery(`#${this.idModal}`).modal('show');
	}

	close() {
		jQuery(`#${this.idModal}`).modal('hide');
	}

  /**
   * Inicialização do filtro para busca de dados
   * @author Marcos Frare
   * @since 02/11/2021
   */
  private setInitialFilter(): void {
    this.filtro.id_unidade = this.idUnidade;
    this.filtro.ids_unidades = [];
  }

  /**
   * Evento de click na linha da parcela do lançamento
   * @param pagamentoUnidade 
   * @autor Marcos Frare
   * @since 16/08/2021
   */
  public onLineClick(pagamentoUnidade: PagamentoUnidade): void {
    pagamentoUnidade.selected = !pagamentoUnidade.selected;
  }

  /**
   * Finalizar seleção
   * @author Marcos Frare
   * @since 16/08/2021
   */
  public onSelect(): void {
    let arItem = this.arPagamentoUnidade.filter(item => item.selected);
    this.select.emit(arItem);
		this.close();
  }

  /**
	 * Faz seleção de todos os lançamentos
	 * @author Marcos Frare
	 * @since 16/08/2021
	 */
	 public toggleAllSelected(): void {
		for (let rec of this.arPagamentoUnidade) {
			rec.selected = this.allSelected;
		}
	}

  /**
	 * Verifica se todos os consumos das unidades estão selecionadas
	 * @author Marcos Frare
	 * @since 05/08/2021
	 */
	 public checkAllSelected(): boolean {
		if (Array.isArray(this.arPagamentoUnidade) && this.arPagamentoUnidade.length > 0) {
			for (const pgUnidade of this.arPagamentoUnidade) {
				if (!pgUnidade.selected) {
					return false;
				}
			}
		} else {
			return false;
		}
		return true;
	}

  /**
   * Busca as unidades do condomínio
   * @author Marcos Frare
   * @since 15/10/2021
   */
  private getUnidades(): void {
		this.unidades = [];
		this.multiselectOptionsUnidades = [];
		this.unidadesHttpService.getAllByCondominioBloco(this.authService.getIdCurrentCondominio(), null).subscribe(
			entities => {
        this.multiselectOptionsUnidades = <IMultiSelectOption[]>entities.map(und => {
          return <IMultiSelectOption>{id: und.id, name: `${und.nome_bloco} - ${und.nome_unidade}`}
        });
			},
			error => this.messageService.error('Erro ao carregar as unidades do condomínio. Tente novamente', 'Erro', error)
		);
	}

}