import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { LogoClienteCadastroComponent } from './logo-cliente-cadastro.component';
import { LogoClienteHttpService } from './services';

@NgModule({
    imports: [
        SharedModule,
        TypeaheadModule,
    ],
    declarations: [
        LogoClienteCadastroComponent
    ],
    providers: [
        LogoClienteHttpService
    ]
})
export class LogoClienteModule { }
