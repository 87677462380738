import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';

import { CustomHttp, ConfigService } from '../../../core';
import { Cidade } from '../../../models';

@Injectable()
export class CidadeHttpService {

    private baseUrl: string;

    constructor(private customHttp: CustomHttp,
                private configService: ConfigService) {
        this.baseUrl = `${configService.getBaseUrl()}/cidade`;
    }

    get(id: number): Observable<Cidade> {
        return this.customHttp.get(`${this.baseUrl}/consultar/${id}`)
                    .pipe(
                        map(response => response.json()),
                        catchError(error => _throw(error))
                    );
    }

    /**
     * Carrega todas as cidade pelo nome informado
     */
    getAllByNome(nome: string): Observable<Cidade[]> {
        return this.customHttp.get(`${this.baseUrl}/consultar/nome/${nome}`)
                    .pipe(
                        map(response => response.json()),
                        catchError(error => _throw(error))
                    );
    }
}
