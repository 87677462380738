import { ThrowStmt } from '@angular/compiler';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { AuthService, MessageService } from 'app/core';
import { ContasBancariasHttpService } from 'app/home/contas-bancarias';
import { Boleto, ContaBancaria, Titulo } from 'app/models';
import { BoletoIntegracaoHttpService } from '../integracao-bancaria-modal';
import { BoletosHttpService } from '../services';

declare var jQuery: any;

@Component({
  selector: 'buscar-boleto-modal',
  templateUrl: './buscar-boleto-modal.component.html',
  styleUrls: ['./buscar-boleto-modal.component.css']
})
export class BuscarBoletoModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @Input() idTitulo: number;
	@Output() onSave: EventEmitter<any> = new EventEmitter();

  public arContaBancaria: ContaBancaria[] = [];
  public boleto: Boleto;
  public arBoletoTitulo: Boleto[];
  public titulo: Titulo;

  public entity = {
    id_conta_bancaria: null,
    id_titulo: null,
    numero_documento: null,
    forma_geracao: null
  }

  public validSave: boolean;

  constructor(private authService: AuthService,
		private messageService: MessageService,
		private contaBancariaHttpService: ContasBancariasHttpService,
    private boletosHttpService: BoletosHttpService,
    private boletoIntegracaoHttpService: BoletoIntegracaoHttpService) { }

  ngOnInit() {
    if (!this.idModal) {
      this.idModal = 'buscaBoletoModal';
    };
		this.getContasBancarias();
  }

  public open(): void {
    this.entity.id_titulo = this.idTitulo;
    this.entity.numero_documento = null;
    this.entity.forma_geracao = 'MANUAL';

    this.boleto = new Boleto();
    this.validSave = false;
    this.getBoletoTitulo();
    this.getTitulo();
		jQuery(`#${this.idModal}`).modal('show');
	}

	public close(): void {
		jQuery(`#${this.idModal}`).modal('hide');
	}

  /**
	 * Busca as contas bancárias do condomínio
	 * @author Marcos Frare
	 * @since 14/06/2021
	 */
	private getContasBancarias(): void {
		this.arContaBancaria = [];
		this.contaBancariaHttpService.getAllByCondominioETipo(this.authService.getIdCurrentCondominio(), 1).subscribe(
			response => {
				this.arContaBancaria = response;
        this.entity.id_conta_bancaria = response && response.length > 0 ? response[0].id : null;
			}
		);
	}

  /**
   * Buscar os dados do título
   * @author Marcos Frare
   * @since 12/10/2021
   */
  private getTitulo(): void {
    this.titulo = new Titulo();
    if (this.idTitulo) {
      this.boletosHttpService.get(this.idTitulo).subscribe(
        response => {
          this.titulo = response;
          this.entity.id_titulo = this.titulo.id;
        },
        error => {
          this.messageService.error('Erro ao buscar dados do título! Tente novamente.', 'Erro', error);
        }
      );
    }
  }

  /**
   * Buscar o boleto do título
   * @author Marcos Frare
   * @since 22/01/2022
   */
  private getBoletoTitulo(): void {
    this.arBoletoTitulo = [];
    jQuery("#tbBoletoTitulo").hide();
    jQuery("#geracaoBoleto").hide();
    if (this.idTitulo) {
      this.boletoIntegracaoHttpService.getByIdTitulo(this.idTitulo).subscribe(
        response => {
          if (response.length) {
            jQuery("#tbBoletoTitulo").show();
            jQuery("#geracaoBoleto").hide();
          } else {
            jQuery("#tbBoletoTitulo").hide();
            jQuery("#geracaoBoleto").show();
          }
          this.arBoletoTitulo = response;
        },
        error => {
          this.messageService.error('Erro ao buscar dodos do boleto do título!', 'Erro', error);
        }
      );
    }
  }

  /**
   * Exclusão de um boleto existente
   * @param boletoTitulo 
   * @author Marcos Frare
   * @since 22/01/2022
   */
  public excluirBoleto(boletoTitulo: Boleto): void {
    if (confirm(`Deseja realmente fazer a exclusão do boleto número ${boletoTitulo.numero_documento}?`)) {
      this.boletoIntegracaoHttpService.delete(boletoTitulo).subscribe(
        () => {
          this.messageService.success('Boleto exlcuído com sucesso!');
          this.getBoletoTitulo();
        },
        error => {
          this.messageService.error('Erro ao excluir boleto! Tente novamente.', 'Erro', error);
        }
      );
    }
  }

  /**
   * Verifica se o boleto consultado
   * @author Marcos Frare
   * @since 12/10/2021
   */
  private validateBoleto(): void {
    this.validSave = true;
    if (this.titulo.data_vencimento != this.boleto.data_vencimento) {
      this.validSave = false;
    }
    if (Number(this.titulo.valor_original) != Number(this.boleto.valor)) {
      this.validSave = false;
    }
  }

  public pesquisarBoleto(): void {
    let btn = jQuery('#btnPesquisarBoleto');
		btn.button('loading');
    this.boletoIntegracaoHttpService.buscarDadosBoleto(this.entity).subscribe(
      response => {
        btn.button('reset');
        if (response.dados && response.dados.numero_documento) {
          this.boleto = response.dados;
        } else {
          this.boleto = new Boleto();
          if (response.msg) {
            this.messageService.warning(response.msg, 'Atenção!');
          } else {
            this.messageService.warning('Não foi encontrado boleto no banco! Verifique dados informados.', 'Atenção!');
          }
        }
        this.validateBoleto();
      },
      error => {
        btn.button('reset');
        this.messageService.error('Erro ao tentar consultar boleto! Tente novamente.', 'Erro', error);
      }
    );
  }

  /**
   * Salvar dados do boleto
   * @author Marcos Frare
   * @since 11/10/2021
   */
  public save(): void {
    let btnSave = jQuery('#btnSaveBoletoConsulta');
		btnSave.button('loading');
    this.boleto.id_conta_bancaria = this.entity.id_conta_bancaria;
    this.boleto.numero_documento = this.entity.numero_documento;
    this.boletoIntegracaoHttpService.gerarConsultaApi(this.idTitulo, this.boleto).subscribe(
      () => {
        btnSave.button('reset');
        this.messageService.success('Boleto gerado com sucesso!');
        this.onSave.emit();
        this.close();
      },
      error => {
        btnSave.button('reset');
        this.messageService.error('Erro ao gerar boleto para o título! Tente novamente.', 'Erro', error);
      }
    );
  }

}