import { NgModule } from '@angular/core';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { BlocosHttpService } from 'app/home/blocos';
import { SharedModule } from 'app/shared';
import { TypeaheadModule } from 'ngx-bootstrap';
import { ProtocoloRelComponent } from './protocolo-rel.component';

@NgModule({
  imports: [
    SharedModule,
    TypeaheadModule,
    MultiselectDropdownModule
  ],
  declarations: [
    ProtocoloRelComponent
  ],
  providers: [
    BlocosHttpService
  ]
})
export class ProtocoloRelModule { }