import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { AuthService, MessageService } from 'app/core';
import { AutorizacaoContaBancaria } from 'app/models';
import { EROFS } from 'constants';
import { ContasBancariasHttpService } from '../../services';

declare var jQuery: any;

@Component({
  selector: 'autorizacao-conta-bancaria-modal',
  templateUrl: './autorizacao-conta-bancaria-modal.component.html',
  styleUrls: ['./autorizacao-conta-bancaria-modal.component.css']
})
export class AutorizacaoContaBancariaModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @Input() idContaBancaria: number;
  @Output() onSave: EventEmitter<AutorizacaoContaBancaria> = new EventEmitter();

  public autorizacaoContaBancaria: AutorizacaoContaBancaria;

  constructor(private messageService: MessageService,
    private contasBancariasHttpService: ContasBancariasHttpService,
    private authService: AuthService) { }
  
  ngOnInit() {
    this.autorizacaoContaBancaria = new AutorizacaoContaBancaria();
  }

  private getAutorizacao(): void {
    this.autorizacaoContaBancaria = new AutorizacaoContaBancaria();
    this.autorizacaoContaBancaria.id_conta_bancaria = this.idContaBancaria;
    this.contasBancariasHttpService.getAutorizacaoContaBancaria(this.idContaBancaria).subscribe(
      resp => {
        if (resp.id) {
          this.autorizacaoContaBancaria = resp;
        }
      },
      error => {
        this.messageService.error('Não foi possível buscar dados da autorização de integração! Tente novamente.', 'Erro!', error);
      }
    );
  }

  public open() {
    this.getAutorizacao();
    jQuery(`#${this.idModal}`).modal('show');
  }

  public close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  public salvar(): void {
    let btnSave = jQuery('#btnSalvarAtorizacao');
    btnSave.button('loading');
    this.autorizacaoContaBancaria.id_conta_bancaria = this.idContaBancaria;
    this.contasBancariasHttpService.saveAutorizacaoContaBancaria(this.autorizacaoContaBancaria).subscribe(
      res => {
        btnSave.button('reset');
        this.messageService.info('Dados gravados com sucesso!');
        this.onSave.emit(res);
        this.close();
      },
      error => {
        btnSave.button('reset');
        this.messageService.error('Problemas ao gravar configurações. Tente novamente.', 'Erro', error);
      }
    );
  }

}