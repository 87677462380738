import { map, catchError } from 'rxjs/operators';

import { Observable ,  forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';

import { MessageService } from '../../../../../core';

import { AcessosCondominioHttpService } from './acessos-condominio-http.service';
import { AcessoCondominio } from '../../../../../models';

@Injectable()
export class AcessosCondominioService {

    constructor(private acessosCondominioHttpService: AcessosCondominioHttpService,
                private messageService: MessageService) { }


    validarSave(entities: AcessoCondominio[]): boolean {
        if (!entities || entities.length === 0) {
            return true;
        }
        for (let i = 0; i < entities.length; i++) {
            const entity = entities[i];
            if (!entity.id_usuario) {
                this.messageService.info('É necessário informar o usuário', 'Informação');
                return false;
            }
        }
        return true;
    }

    private createSave(idUsuario: number, entities: AcessoCondominio[]): Observable<AcessoCondominio>[] {
        if (!entities) {
            return null;
        }
        const saves: Observable<AcessoCondominio>[] = [];
        entities.forEach(entity => {
            if (entity.editado) {
                if (!entity.id) {
                    const observable: Observable<AcessoCondominio> = this.acessosCondominioHttpService.post(entity)
                                                                    .pipe(
                                                                        map(response => response),
                                                                        catchError(error => {
                                                                            this.messageService.error(`Erro ao salvar o usuário. Tente novamente`, 'Erro', error);
                                                                            return Observable.throw(error);
                                                                        })
                                                                    );
                    saves.push(observable);
                }
            }
        });
        return saves;
    }

    save(idUsuario: number, entities: AcessoCondominio[]): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const saves = this.createSave(idUsuario, entities);
            if (saves.length > 0) {
                forkJoin(saves)
                    .subscribe(
                        () => resolve(),
                        error => reject(error)
                    );
            } else {
                resolve();
            }
        });
    }
}

