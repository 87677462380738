import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {
	ConfiguracaoCondominio,
	PlanoDeContas,
	tiposRateio,
	tiposMultaJuros,
	tiposFundoReserva,
	identificacaoMultaJuros,
	tiposDivisaoRateio,
	tiposMesVencimento,
	Formulario,
	CategoriaContas,
	competenciaTitulo,
	TipoConsumo,
	UnidadeIsencao,
	UnidadeAntecipacao,
	CondominioConfiguracaoReserva
} from '../../models';
import { BaseCadastroComponent } from '../../componentes-base';
import { AuthService, MessageService } from '../../core';
import { CondominiosConfiguracaoHttpService } from './services';
import { PlanoDeContasHttpService } from '../plano-de-contas';
import { ContaBancaria } from '../../models';
import { ContasBancariasHttpService } from '../contas-bancarias/services';
import { PermissionService } from '../../core/services/permission/permission.service';
import { FloatFormatPipe } from '../../shared/pipes';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { CategoriaContasHttpService } from '../plano-de-contas/categoria-contas/services';
import { VinculoTabelaConsumoModalComponent } from 'app/home/condominios-configuracao/vinculo-tabela-consumo-modal';
import { ContasBancariasCadastroModalComponent } from '../contas-bancarias';
import { CategoriaContasPesquisaModalComponent } from '../plano-de-contas/categoria-contas';
import { CondominiosHttpService } from '../condominios/services';
import { TiposConsumoHttpService } from '../tipos-consumo/services/tipos-consumo-http.service';
import { UnidadesHttpService } from '../blocos';
import { UnidadeIsencaoModalComponent } from './unidade-isencao-modal';
import { UnidadeAntecipacaoModalComponent } from './unidade-antecipacao-modal';
import { ReservaAreaHttpService } from '../reserva-area';

declare var jQuery: any;

@Component({
	selector: 'condominios-configuracao',
	templateUrl: 'condominios-configuracao.component.html',
	providers: [
		PlanoDeContasHttpService, PermissionService, FloatFormatPipe
	]
})
export class CondominiosConfiguracaoComponent extends BaseCadastroComponent<ConfiguracaoCondominio> implements OnInit {
	planosContas: PlanoDeContas[];
	formularios: Formulario[];
	tiposRateio = tiposRateio;
	tiposMultaJuros = tiposMultaJuros;
	tiposFundoReserva = tiposFundoReserva;
	tiposDivisaoRateio = tiposDivisaoRateio;
	tiposDescontos = tiposMultaJuros;
	competenciaTitulos = competenciaTitulo;
	tiposMesVencimento = tiposMesVencimento;
	identificacaoMultaJuros = identificacaoMultaJuros;
	contas: ContaBancaria[];
	totalUnidades: number;
	possuiDesconto = false;
	public categoriasContasReceita: CategoriaContas[] = [];
	public categoriasContasDespesa: CategoriaContas[] = [];
	public filtro: any = {};
	public idCondominio: number;
	public idCliente: number;
	public tiposConsumo: TipoConsumo[];
	public condominioConfiguracaoReserva: CondominioConfiguracaoReserva = new CondominioConfiguracaoReserva();

	@ViewChild('contasBancariasCadastroModal') contasBancariasCadastroModal: ContasBancariasCadastroModalComponent;
	@ViewChild('categoriaContasFundoReservaPesquisaModal') categoriaContasFundoReservaPesquisaModal: CategoriaContasPesquisaModalComponent;
	@ViewChild('categoriaContasTaxaCondominioPesquisaModal') categoriaContasTaxaCondominioPesquisaModal: CategoriaContasPesquisaModalComponent;
	@ViewChild('categoriaContasJurosRenegociacaoPesquisaModal') categoriaContasJurosRenegociacaoPesquisaModal: CategoriaContasPesquisaModalComponent;
	@ViewChild('categoriaContasMultaJurosPesquisaModal') categoriaContasMultaJurosPesquisaModal: CategoriaContasPesquisaModalComponent;
	@ViewChild('categoriaContasPagamentoJurosPesquisaModal') categoriaContasPagamentoJurosPesquisaModal: CategoriaContasPesquisaModalComponent;
	@ViewChild('vinculoTabelaConsumoModal') vinculoTabelaConsumoModal: VinculoTabelaConsumoModalComponent;
	@ViewChild('unidadeIsencaoModal') unidadeIsencaoModal: UnidadeIsencaoModalComponent;
	@ViewChild('unidadeAntecipacaoModal') unidadeAntecipacaoModal: UnidadeAntecipacaoModalComponent;
	@ViewChild('multa') multa: any;
	@ViewChild('juros') juros: any;
	@ViewChild('fundoReserva') fundoReserva: any;

	constructor(activatedRoute: ActivatedRoute,
		router: Router,
		messageService: MessageService,
		private authService: AuthService,
		private condominiosConfiguracaoHttpService: CondominiosConfiguracaoHttpService,
		private planoDeContasHttpService: PlanoDeContasHttpService,
		private contasBancariasHttpService: ContasBancariasHttpService,
		private condominiosHttpService: CondominiosHttpService,
		public permissionService: PermissionService,
		private categoriaContasHttpService: CategoriaContasHttpService,
		private tiposConsumoHttpService: TiposConsumoHttpService,
		private unidadeHttpService: UnidadesHttpService,
		private reservaAreaHttpService: ReservaAreaHttpService) {
		super(ConfiguracaoCondominio, condominiosConfiguracaoHttpService, activatedRoute, router, messageService, permissionService);
	}

	ngOnInit() {
		this.idCondominio = this.authService.getIdCurrentCondominio();
		this.idCliente = this.authService.getIdCurrentCliente();
		if (this.entity == null) {
			this.entity = new ConfiguracaoCondominio();
		}
		if (this.idCondominio) {
			this.getByCondominio(this.idCondominio);
			this.getTotalUnidades(this.idCondominio);
		} else {
			this.messageService.error('O condomínio não foi encontrado. É selecionar o condomínio para editar a sua configuração', 'Erro');
		}
		this.getAllCategoriasByCondominio(this.idCondominio);
		this.getAllContasCorrentes();
		this.getConfiguracaoReserva()
		this.getPermissions();
	}

	afterEdit() {
		this.entity.identificacao_multa = 3; // Único
		this.entity.identificacao_juros = 2; // Ao mês
		if (this.entity.desconto || this.entity.tipo_desconto || this.entity.data_desconto) { this.possuiDesconto = true; }
		this.setCategoriaContasFundoReserva();
		this.setCategoriaContasTaxaCondominio();
		this.setCategoriaContasJurosRenegociacao();
		this.setCategoriaContasMultaJuros();
		this.setCategoriaContaPagamentoJuros();
		this.getTiposConsumoConfigurados();
		this.unidadesIsentas();
		this.unidadesAntecipacao();
	}

	getAllContasCorrentes() {
		this.contas = [];
		this.contasBancariasHttpService.getAllByCondominioETipo(this.authService.getIdCurrentCondominio(), 1).subscribe(
			contas => {
				this.contas = contas;
			},
			error => this.messageService.error('Erro ao carregar contas. Tente novamente', 'Erro', error)
		);
	}

	private setCategoriaContasFundoReserva(): void {
		if (!this.entity.id_categoria_fundo_reserva) {
			return;
		}
		this.categoriaContasHttpService.get(this.entity.id_categoria_fundo_reserva).subscribe(
			entity => this.entity.categoriaContasFundoReserva = entity
		);
	}

	private setCategoriaContasTaxaCondominio(): void {
		if (!this.entity.id_categoria_taxa_condominio) {
			return;
		}
		this.categoriaContasHttpService.get(this.entity.id_categoria_taxa_condominio).subscribe(
			entity => this.entity.categoriaContasTaxaCondominio = entity
		);
	}

	private setCategoriaContasJurosRenegociacao(): void {
		if (!this.entity.id_categoria_juros_renegociacao) {
			return;
		}
		this.categoriaContasHttpService.get(this.entity.id_categoria_juros_renegociacao).subscribe(
			entity => this.entity.categoriaContasJurosRenegociacao = entity
		);
	}

	private getAllCategoriasByCondominio(idCondominio: number): void {
		this.categoriasContasReceita = [];
		this.categoriasContasDespesa = [];
		if (idCondominio) {
			this.categoriaContasHttpService.getAllByCondominio(idCondominio)
				.then(entities => {
					this.categoriasContasReceita = entities.filter(conta => conta.tipo_conta == 1);
					this.categoriasContasDespesa = entities.filter(conta => conta.tipo_conta == 2);
				})
				.catch(() => this.messageService.info(`Configuração do condomínio não encontrada. Configure o condomínio para prosseguir.`, 'Informação'));
		}
	}

	private getByCondominio(idCondominio: number) {
		this.condominiosConfiguracaoHttpService.getByCondominio(idCondominio).subscribe(
			config => {
				if (config && config.id) {
					this.edit(config.id);
				} else {
					this.insert();
					this.getAllPlanoDeContas();
				}
			},
			(error: Response) => {
				if (error.status === 400) {
					this.insert();
					this.getAllPlanoDeContas();
				} else {
					this.messageService.error('Erro ao carregar a configuração do condomínio. Tente novamente', 'Erro', error);
				}
			}
		);
	}

	private getAllPlanoDeContas() {
		this.planosContas = [];
		const idCliente = this.authService.getIdCurrentCliente();
		this.planoDeContasHttpService.getAllByCliente(idCliente).subscribe(
			entities => {
				this.planosContas = entities
			},
			error => this.messageService.error('Erro ao carregar os planos de contas do cliente. Tente novamente', 'Erro', error)
		);
	}

	edit(id: number) {
		this.editMode = true;
		this.load(id);
	}

	insert() {
		this.editMode = false;
		this.entity = new ConfiguracaoCondominio();
		this.afterInsert();
	}

	afterInsert() {
		this.entity.id_condominio = this.authService.getIdCurrentCondominio();
	}

	createConta() {
		this.contasBancariasCadastroModal.open(this.authService.getIdCurrentCondominio());
	}

	onAfterSaveContaBancaria(contaBancaria: ContaBancaria) {
		this.entity.id_conta_bancaria_padrao = contaBancaria.id;
		this.contas.push(contaBancaria);
		jQuery('#contaPadrao').focus();
	}

	cancel() {
		// Nada Faz
	}

	getTotalUnidades(idCondominio) {
		this.condominiosHttpService.getTotalUnidades(idCondominio).subscribe(
			(response: any) => this.totalUnidades = response.total,
			error => this.messageService.error('Error ao obter total de unidades', 'Erro', error)
		);
	}

	save(): Promise<any> {
		this.saveConfiguracaoReserva();
		return new Promise<any>((resolve, reject) => {
			this.makeAllFieldsDirty();

			if (this.form.valid) {
				if (this.beforeSave()) {
					this.btnSave.button('loading');

					const envio: any = { ...this.entity };

					if (this.editMode) { // PUT
						this.crudService.put(envio).subscribe(
							entity => {
								this.messageService.success('', 'Registro salvo com sucesso');
								this.afterSave(entity);
								resolve(entity);
							},
							error => {
								this.messageService.error('Erro ao atualizar o registro', 'Erro', error);
								this.btnSave.button('reset');
								reject(null);
							}
						);
					} else { // POST
						this.crudService.post(envio).subscribe(
							entity => {
								this.messageService.success('', 'Registro salvo com sucesso');
								this.afterSave(entity);
								this.router.navigate(['condominios-configuracao']);
								resolve(entity);
							},
							error => {
								this.messageService.error('Erro ao salvar o registro', 'Erro', error);
								this.btnSave.button('reset');
								reject(null);
							}
						);
					}

				} else {
					reject(null);
				}
			} else {
				this.showMessageFormInvalid();
				reject(null);
			}
		});
	}

	public afterSave(entity: ConfiguracaoCondominio) {
		super.afterSave(entity);
		this.entity = entity;
		this.edit(entity.id);
	}

	public onChangePossuiDesconto(event: any) {
		this.entity.tipo_desconto = null;
		this.entity.desconto = null;
		this.entity.data_desconto = null;
	}

	public onSelectCategoriaContasFundoReserva(tm: TypeaheadMatch): void {
		const categoriaContas: CategoriaContas = tm.item as CategoriaContas;
		if (categoriaContas) {
			this.entity.id_categoria_fundo_reserva = categoriaContas.id;
			this.entity.categoriaContasFundoReserva = categoriaContas;
		} else {
			this.entity.id_categoria_fundo_reserva = null;
			this.entity.categoriaContasFundoReserva = null;
		}
	}

	public onSelectCategoriaContasTaxaCondominio(tm: TypeaheadMatch): void {
		const categoriaContas: CategoriaContas = tm.item as CategoriaContas;
		if (categoriaContas) {
			this.entity.id_categoria_taxa_condominio = categoriaContas.id;
			this.entity.categoriaContasTaxaCondominio = categoriaContas;
		} else {
			this.entity.id_categoria_taxa_condominio = null;
			this.entity.categoriaContasTaxaCondominio = null;
		}
	}

	public onSelectCategoriaContasJurosRenegociacao(tm: TypeaheadMatch): void {
		const categoriaContas: CategoriaContas = tm.item as CategoriaContas;
		if (categoriaContas) {
			this.entity.id_categoria_juros_renegociacao = categoriaContas.id;
			this.entity.categoriaContasJurosRenegociacao = categoriaContas;
		} else {
			this.entity.id_categoria_juros_renegociacao = null;
			this.entity.categoriaContasJurosRenegociacao = null;
		}
	}

	public onCleanCategoriaContasFundoReserva(input: any): void {
		this.entity.id_categoria_fundo_reserva = null;
		this.entity.categoriaContasFundoReserva = null;
		input.value = '';
		input.focus();
	}

	public onCleanCategoriaContasTaxaCondominio(input: any): void {
		this.entity.id_categoria_taxa_condominio = null;
		this.entity.categoriaContasTaxaCondominio = null;
		input.value = '';
		input.focus();
	}

	public onCleanCategoriaContasJurosRenegociacao(input: any): void {
		this.entity.id_categoria_juros_renegociacao = null;
		this.entity.categoriaContasJurosRenegociacao = null;
		input.value = '';
		input.focus();
	}

	public openCategoriaContasFundoReservaPequisaModal(): void {
		this.categoriaContasFundoReservaPesquisaModal.open();
	}

	public openCategoriaContasTaxaCondominioPequisaModal(): void {
		this.categoriaContasTaxaCondominioPesquisaModal.open();
	}

	public openCategoriaContasJurosRenegociacaoPesquisaModal(): void {
		this.categoriaContasJurosRenegociacaoPesquisaModal.open();
	}

	public onSelectCategoriaContasFundoReservaPesquisaModal(categoriaContas: CategoriaContas): void {
		if (categoriaContas) {
			this.entity.id_categoria_fundo_reserva = categoriaContas.id;
			this.entity.categoriaContasFundoReserva = categoriaContas;
		}
		jQuery('#categoriaContas').focus();
	}

	public onSelectCategoriaContasTaxaCondominioPesquisaModal(categoriaContas: CategoriaContas): void {
		if (categoriaContas) {
			this.entity.id_categoria_taxa_condominio = categoriaContas.id;
			this.entity.categoriaContasTaxaCondominio = categoriaContas;
		}
		jQuery('#categoriaContas').focus();
	}

	public onSelectCategoriaContasJurosRenegociacaoPesquisaModal(categoriaContas: CategoriaContas): void {
		if (categoriaContas) {
			this.entity.id_categoria_juros_renegociacao = categoriaContas.id;
			this.entity.categoriaContasJurosRenegociacao = categoriaContas;
		}
		jQuery('#categoriaContas').focus();
	}

	// Configuração de tabelas de serviço

	/**
	 * Abrir tela para vincular tabela de consumo ao condomínio
	 * @author Marcos Frare
	 * @since 18/02/2020
	 */
	public addTabelaConsumo(): void {
		this.vinculoTabelaConsumoModal.open();
	}

	/**
	 * Busca as tabelas configuradas
	 * @author Marcos Frare
	 * @since 18/02/2020
	 */
	public getTiposConsumoConfigurados() {
		this.tiposConsumoHttpService.getConfiguradasByCondominio(this.idCondominio).subscribe(
			response => {
				this.entity.tiposConsumo = response;
			},
			error => this.messageService.error('Erro ao carregar tabelas configuradas. Tente novamente', 'Erro', error)
		);
	}

	/**
	 * Remove uma tabela de consumo vinculada
	 * @param tipoConsumo 
	 * @author Marcos Frare
	 * @since 18/02/2020
	 */
	public removeTipoConsumo(tipoConsumo: TipoConsumo): void {
		if (confirm(`Tem certeza que deseja desvincular a tabela ${tipoConsumo.nome}?`)) {
			this.tiposConsumoHttpService.desvincularTabelaCondominio(tipoConsumo).subscribe(
				() => {
					this.messageService.success('', `Tabela ${tipoConsumo.nome} desvinculada com sucesso!!`);
					this.getTiposConsumoConfigurados();
				},
				error => {
					this.messageService.error('Erro ao desvincular tabela!!', 'Erro', error)
				}
			);
		}
	}

	/**
	 * Editar uma tabela vinculada
	 * @param tipoConsumo 
	 * @author Marcos Frare
	 * @since 18/02/2020
	 */
	public editTipoConsumo(tipoConsumo: TipoConsumo): void {
		if (this.permissions.atualizar) {
			this.vinculoTabelaConsumoModal.edit(tipoConsumo);
		}
	}

	// 
	private setCategoriaContasMultaJuros(): void {
		if (!this.entity.id_categoria_conta_multa_juros) {
			return;
		}
		this.categoriaContasHttpService.get(this.entity.id_categoria_conta_multa_juros).subscribe(
			entity => {
				this.entity.id_categoria_conta_multa_juros = entity.id;
				this.entity.categoriaContasMultaJuros = entity;
			}
		);
	}

	public onSelectCategoriaContasMultaJuros(tm: TypeaheadMatch): void {
		const categoriaContas: CategoriaContas = tm.item as CategoriaContas;
		if (categoriaContas) {
			this.entity.id_categoria_conta_multa_juros = categoriaContas.id;
			this.entity.categoriaContasMultaJuros = categoriaContas;
		} else {
			this.entity.id_categoria_conta_multa_juros = null;
			this.entity.categoriaContasMultaJuros = null;
		}
	}

	public onCleanCategoriaContasMultaJuros(input: any): void {
		this.entity.id_categoria_conta_multa_juros = null;
		this.entity.categoriaContasMultaJuros = null;
		input.value = '';
		input.focus();
	}

	public openCategoriaContasMultaJurosPesquisaModal(): void {
		this.categoriaContasMultaJurosPesquisaModal.open();
	}

	public onSelectCategoriaContasMultaJurosPesquisaModal(categoriaContas: CategoriaContas): void {
		if (categoriaContas) {
			this.entity.id_categoria_conta_multa_juros = categoriaContas.id;
			this.entity.categoriaContasMultaJuros = categoriaContas;
		}
		jQuery('#categoriaContasMultaJuros').focus();
	}


	// =======================================================================================================================================
	// Conta de pagamentos de juros e multa
	// =======================================================================================================================================

	private setCategoriaContaPagamentoJuros(): void {
		if (!this.entity.id_categoria_pagamento_juros) {
			return;
		}
		this.categoriaContasHttpService.get(this.entity.id_categoria_pagamento_juros).subscribe(
			entity => {
				this.entity.id_categoria_pagamento_juros = entity.id;
				this.entity.categoriaPagamentoJuros = entity;
			}
		);
	}

	public onSelectCategoriaPagamentoJuros(tm: TypeaheadMatch): void {
		const categoriaContas: CategoriaContas = tm.item as CategoriaContas;
		if (categoriaContas) {
			this.entity.id_categoria_pagamento_juros = categoriaContas.id;
			this.entity.categoriaPagamentoJuros = categoriaContas;
		} else {
			this.entity.id_categoria_pagamento_juros = null;
			this.entity.categoriaPagamentoJuros = null;
		}
	}

	public onCleanCategoriaPagamentoJuros(input: any): void {
		this.entity.id_categoria_pagamento_juros = null;
		this.entity.categoriaPagamentoJuros = null;
		input.value = '';
		input.focus();
	}

	public openCategoriaPagamentoJurosPesquisaModal(): void {
		this.categoriaContasPagamentoJurosPesquisaModal.open();
	}

	public onSelectCategoriaPagamentoJurosPesquisaModal(categoriaContas: CategoriaContas): void {
		if (categoriaContas) {
			this.entity.id_categoria_pagamento_juros = categoriaContas.id;
			this.entity.categoriaPagamentoJuros = categoriaContas;
		}
		jQuery('#categoriaPagamentoJuros').focus();
	}

	// =======================================================================================================================================
	// Isenção de unidades
	// =======================================================================================================================================

	/**
	 * Obtem as unidades com isenção de taxa de condomínio
	 * @author Marcos Frare
	 * @since 06/08/2021
	 */
	public unidadesIsentas(): void {
		this.entity.unidadesIsencao = [];
		this.unidadeHttpService.getUnidadesIsencaoCondominio().subscribe(
			response => {
				this.entity.unidadesIsencao = response;
			},
			error => {
				this.messageService.error('Erro ao buscas unidades isentas! Tente novamente.', 'Erro', error);
			}
		)
	}

	/**
	 * Abir modal para inserir unidade isenta
	 * @author Marcos Frare
	 * @since 09/08/2021
	 */
	public addUnidadeIsenta(): void {
		this.unidadeIsencaoModal.id = null;
		this.unidadeIsencaoModal.open();
	}

	/**
	 * Editar unidade isenta
	 * @param unidadeIsencao
	 * @author Marcos Frare
	 * @since 06/08/2021 
	 */
	public editUnidadeIsencao(unidadeIsencao: UnidadeIsencao): void {
		this.unidadeIsencaoModal.id = unidadeIsencao.id;
		this.unidadeIsencaoModal.open();
	}

	/**
	 * Exclusão de uma unidade isenta
	 * @param unidadeIsencao 
	 * @author Marcos Frare
	 * @since 06/08/2021
	 */
	public removeUnidadeIsencao(unidadeIsencao: UnidadeIsencao): void {
		if (confirm(`Deseja excluir isenção para unidade ${unidadeIsencao.nome_bloco}-${unidadeIsencao.nome_unidade}?`)) {
			this.unidadeHttpService.excluirIsencao(unidadeIsencao.id).subscribe(
				() => {
					this.messageService.info('Isenção de unidade excluída com sucesso!');
					this.unidadesIsentas();
				},
				error => {
					this.messageService.error('Erro ao excluir isenção de unidade! Tente novamente.', 'Erro!', error);
				}
			);
		}
	}

	// =======================================================================================================================================
	// Unidades com Antecipações
	// =======================================================================================================================================

	/**
	 * Obtem as unidades com antecipações habilitadas no condomínio
	 * @author Marcos Frare
	 * @since 18/08/2021
	 */
	 public unidadesAntecipacao(): void {
		this.entity.unidadesAntecipacao = [];
		this.unidadeHttpService.getUnidadesAntecipacaoCondominio().subscribe(
			response => {
				this.entity.unidadesAntecipacao = response;
			},
			error => {
				this.messageService.error('Erro ao buscar configuração de antecipação! Tente novamente.', 'Erro', error);
			}
		)
	}

	/**
	 * Abir modal para inserir unidade para antecipação
	 * @author Marcos Frare
	 * @since 18/08/2021
	 */
	public addUnidadeAntecipacao(): void {
		this.unidadeAntecipacaoModal.id = null;
		this.unidadeAntecipacaoModal.open();
	}

	/**
	 * Editar unidade isenta
	 * @param unidadeAntecipacao
	 * @author Marcos Frare
	 * @since 06/08/2021 
	 */
	public editUnidadeAntecipacao(unidadeAntecipacao: UnidadeAntecipacao): void {
		this.unidadeAntecipacaoModal.id = unidadeAntecipacao.id;
		this.unidadeAntecipacaoModal.open();
	}

	/**
	 * Exclusão de uma unidade isenta
	 * @param unidadeAntecipacao 
	 * @author Marcos Frare
	 * @since 06/08/2021
	 */
	public removeUnidadeAntecipacao(unidadeAntecipacao: UnidadeAntecipacao): void {
		if (confirm(`Deseja desabilitar unidade ${unidadeAntecipacao.nome_bloco}-${unidadeAntecipacao.nome_unidade} de antecipações?`)) {
			this.unidadeHttpService.excluirUnidadeAntecipacao(unidadeAntecipacao.id).subscribe(
				() => {
					this.messageService.info('Configuração de antecipação excluída com sucesso!');
					this.unidadesAntecipacao();
				},
				error => {
					this.messageService.error('Erro ao excluir configuração de antecipação! Tente novamente.', 'Erro!', error);
				}
			);
		}
	}

	// =======================================================================================================================================
	// Configurações de reservas
	// =======================================================================================================================================

	/**
	 * Busca configurações de reservas do condomínio
	 * @author Marcos Frare'
	 * @since 23/08/2022
	 */
	public getConfiguracaoReserva(): void {
		this.reservaAreaHttpService.getConfiguracao(this.idCondominio).subscribe(
			response => {
				this.condominioConfiguracaoReserva = response.id ? response : new CondominioConfiguracaoReserva();
				this.condominioConfiguracaoReserva.id_condominio = this.idCondominio;
			},
			error => {
				this.messageService.error('Problemas ao buscar dados de configurações de reservas!', 'Atenção!', error);
			}
		);
	}

	/**
	 * Salvar configuração de reservas
	 * @author Marcos Frare
	 * @since 23/08/2022
	 */
	public saveConfiguracaoReserva(): void {
		this.reservaAreaHttpService.saveConfiguracao(this.condominioConfiguracaoReserva).subscribe(
			() => { },
			error => {
				this.messageService.error('Problemas ao salvar configurações de reservas!', 'Atenção!', error);
				return false;
			}
		);
	}
}