import { BaseEntity } from "../base-entity";
import { CategoriaContas } from "./categoria-contas";

export class TemplateLancamentoEspecifico extends BaseEntity {
	descricao: string;
	id_condominio: number;
	id_categoria_conta: number;
	parcelas: number;
	valor: number;
	tipo_divisao_rateio: number;
	cobrar_tipo_unidade: number;
	cobrar_fundo_reserva: boolean;
	para_proprietario: boolean;
	observacao: string;

	ids_unidades: number[];

	categoriaConta: CategoriaContas;
}