import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseVisualizacaoComponent } from 'app/componentes-base';
import { AuthService, MessageService } from 'app/core';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { Fornecedor, Recibo } from 'app/models';
import { NgxSpinnerService } from 'ngx-spinner';
import { FornecedoresHttpService } from '../fornecedores';
import { RelatorioModalComponent } from '../relatorios';
import { ReciboFiltro } from './recibo-filtro';
import { ReciboGeracaoModalComponent } from './recibo-geracao-modal';
import { ReciboHttpService } from './services';

@Component({
  selector: 'recibo',
  templateUrl: './recibo.component.html',
  styleUrls: ['./recibo.component.css']
})
export class ReciboComponent extends BaseVisualizacaoComponent<Recibo> implements OnInit {

  @ViewChild('relatorioModal') relatorioModal: RelatorioModalComponent;
  @ViewChild('reciboGeracaoModal') reciboGeracaoModal: ReciboGeracaoModalComponent;

  public filtro: ReciboFiltro = new ReciboFiltro();
  public fornecedores: Fornecedor[] = [];

  constructor(public activatedRoute: ActivatedRoute,
      public router: Router,
      public messageService: MessageService,
      private reciboHttpService: ReciboHttpService,
      public permissionService: PermissionService,
      public spinner: NgxSpinnerService, 
      private authService: AuthService,
      private fornecedoresHttpService: FornecedoresHttpService) {
    super('#tableRecibo', reciboHttpService, activatedRoute, router, messageService, permissionService, spinner);
  }

  ngOnInit() {
    super.ngOnInit();
    this.getFornecedores();
  }

  getAll() {
    this.entities = [];
    this.destroyTable();
    this.filtro.id_cliente = this.authService.getIdCurrentCliente();
    this.filtro.id_condominio = this.authService.getIdCurrentCondominio();
    this.reciboHttpService.getAllByParams(this.filtro).subscribe(
      entities => {
        this.entities = entities;
        this.showTable();
      },
      error => this.messageService.error('Erro ao carregar os condominios do condomínio. Tente novamente', 'Erro', error)
    );
  }

  getFornecedores(): void {
    this.fornecedores = [];
    this.fornecedoresHttpService.getAllByCliente(this.authService.getIdCurrentCliente()).subscribe(
			entities => this.fornecedores = entities,
			error => this.messageService.error('Erro ao carregar os fornecedores', 'Erro', error)
		);
  }

  /**
   * Atualizar dados da tabela
   * @author marcos Frare
   * @since 21/02/2023
   */
  public loadTable(): void {
    this.getAll();
  }

  /**
   * Incluir novo recibo
   * @author Marcos Frare
   * @since 22/02/2023
   */
  public adicionar(): void {
    this.reciboGeracaoModal.open();
  }

  /**
   * Exclusão do recibo
   * @param recibo 
   * @author Marcos Frare
   * @since 21/02/2023
   */
  public delete(recibo: Recibo): void {
    if (confirm(`Tem certeza que deseja excluir recibo?`)) {
      this.reciboHttpService.delete(recibo).subscribe(
        () => {
          this.messageService.success('', `Recibo excluído com sucesso!`);
          this.getAll();
        },
        error => {
          switch (error.status) {
            case 404:
              this.messageService.warning(`Recibo não encontrado, atualize a consulta e tente novamente.`, 'Atenção', error);
              break;
            case 500:
              this.messageService.error(`Erro ao excluir o recibo. Tente novamente`, 'Erro', error);
              break;
            default:
              break;
          }
        });
    }
  }

  /**
   * Carrega o PDF do recibo em tela
   * @param recibo 
   * @author Marcos Frare
   * @since 21/02/2023
   */
  public view(recibo: Recibo): void {
    this.relatorioModal.url = `/recibo/visualizar/${recibo.id}`;
    this.relatorioModal.params = recibo;
    this.relatorioModal.open();
  }

}
