import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService, AuthService } from '../../core';
import { DocumentosHttpService } from './services';
import { Documento } from '../../models/entidades/documento';
import { PermissionService } from '../../core/services/permission/permission.service';
import { VisualizaAnexoComponent } from 'app/shared';
import { DocumentoPastaHttpService, DocumentoPastaModalComponent } from './documento-pasta-modal';
import { Subscription } from 'rxjs';
import { DocumentoPasta } from 'app/models';

@Component({
	selector: 'documentos',
	templateUrl: './documentos.component.html',
	providers: [PermissionService]
})

export class DocumentosComponent implements OnInit, OnDestroy {

	@ViewChild('visualizaAnexoModal') visualizaAnexoModal: VisualizaAnexoComponent;
	@ViewChild('documentoPastaModal') documentoPastaModal: DocumentoPastaModalComponent;

	public idCliente: number;
	public isCondominoUser: boolean;
	public idCondominio: number;
	public entities: Documento[] = [];
	public pastas: DocumentoPasta[] = [];

	private subs: Subscription[] = [];

	constructor(public activatedRoute: ActivatedRoute,
			public router: Router,
			public messageService: MessageService,
			private authService: AuthService,
			public documentosHttpService: DocumentosHttpService,
			public documentoPastaHttpService: DocumentoPastaHttpService,
			public permissionService: PermissionService) {
		this.idCliente = this.authService.getIdCurrentCliente();
		this.isCondominoUser = this.authService.getTipoCurrentUser() === 3;
	}

	ngOnInit() {
		this.idCondominio = this.authService.getIdCurrentCondominio();
		this.getAllDocs();
	}

	ngOnDestroy(): void {
		try {
			this.subs.map(s => s.unsubscribe());
		} catch (err) {
			console.error(err);
		}
	}

	public toggleShowDocumentos(pasta: DocumentoPasta): void {
		pasta.showDocumentos = !pasta.showDocumentos;
	}

	public getAllDocs(): void {
		this.pastas = [];
		this.subs.push(this.documentosHttpService.getAllByCondominio(this.idCondominio, !this.isCondominoUser).subscribe(
			res => this.pastas = res,
			error => this.messageService.error('Erro ao consultar os documentos. Tente novamente', 'Erro', error)
		));
	}

	public insert(): void {
		this.router.navigate(['./new'], { relativeTo: this.activatedRoute });
	}

	public onEditDocumentoClick(entity: Documento): void {
		this.router.navigate(['./', entity.id], { relativeTo: this.activatedRoute });
	}

	/**
	 * Download do documento anexado
	 * @param entity 
	 * @author Marcos Frare
	 * @since 26/03/2020
	 */
	public onDownloadDocumento(entity: Documento): void {
		this.subs.push(this.documentosHttpService.get(entity.id).subscribe(
			response => {
				if (response.anexo) {
					const a = window.document.createElement('a');
					a.href = response.anexo.arquivo;
					a.download = response.anexo.nome;
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
					this.messageService.info('Documento baixado com sucesso', 'Informação');
				} else {
					this.messageService.warning('Não encontrado documento anexado', 'Atenção');
				}
			},
			error => this.messageService.error('Erro ao fazer download do arquivo', 'Erro', error)
		));
	}

	public onRemoveDocumentoClick(entity: Documento): void {
		if (confirm(`Tem certeza que deseja excluir este documento ${entity.nome}?`)) {
			this.subs.push(this.documentosHttpService.delete(entity).subscribe(
				() => {
					const index = this.entities.findIndex(form => form.id === entity.id);
					this.deleteOfList(index);
					this.messageService.success('', `Documento ${entity.nome} foi excluído com sucesso`);
					this.getAllDocs();
				},
				error => {
					switch (error.status) {
						case 403:
							this.messageService.warning(`Não foi possível excluir o documento ${entity.nome}.`, 'Erro', error);
							break;
						default:
							this.messageService.error(`Erro ao excluir o documento ${entity.nome}. Tente novamente`, 'Erro', error);
					}
				}
			));
		}
	}

	private deleteOfList(index: number): boolean {
		if (index > -1) {
			this.entities.splice(index, 1);
			return true;
		}
		return false;
	}

	/**
	 * Mostra anexo
	 * @param id
	 * @author Marcos Frare
	 * @since 01/03/2022
	 */
	public fileView(file: any): void {
		if (file.id) {
			this.visualizaAnexoModal.id_anexo = file.id;
			this.visualizaAnexoModal.fileType = file.tipo;
			this.visualizaAnexoModal.open();
		}
	}

	public addPasta(): void {
		this.documentoPastaModal.open();
	}

	public pushPasta(pasta: DocumentoPasta): void {
		this.pastas.push(pasta);
	}

	public onRemovePastaClick(pasta: DocumentoPasta): void {
		if (confirm(`Deseja excluir a pasta ${pasta.nome}?`)) {
			this.subs.push(this.documentoPastaHttpService.delete(pasta).subscribe(
				() => {
					this.getAllDocs();
				},
				error => {
					this.messageService.error(`Erro ao excluir pasta ${pasta.nome}! Tente novamente`, 'Erro', error);
				}
			))
		}
	}

}