export class PrevisaoPorFracaoRelFiltro {
	id_cliente: number;
	id_condominio: number;
	id_bloco: number;
	ids_unidade: number[];
	id_usuario: number;
	competencia: Date;
	meses_competencia_anterior: number;

	/** Limpa o filtro */
	limpar() {
		this.id_bloco = null;
		this.ids_unidade = [];
		this.meses_competencia_anterior = 0;
		const today = new Date();
		this.competencia = new Date(today.getFullYear(), today.getMonth() - 1, 1); // primeiro dia do mês anterior;
	}
}