import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Permission } from '../../componentes-base';
import { Usuario, TIPO_USUARIO, GrupoDeUsuario } from '../../models';
import { MessageService, AuthService } from '../../core';
import { UsuariosHttpService } from './services';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { getSituacaoFromBoolean, SituacaoEnum } from 'app/models/enums/situacao-enum';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { GruposDeUsuariosHttpService } from '../grupos-de-usuarios';
import { forkJoin } from 'rxjs';

declare var jQuery: any;

@Component({
	selector: 'usuarios',
	templateUrl: 'usuarios.template.html',
	providers: [PermissionService, UsuariosHttpService, GruposDeUsuariosHttpService]
})
export class UsuariosComponent implements OnInit {

	public gruposDeUsuarios: GrupoDeUsuario[] = [];
	public entities: Usuario[] = [];
	public dataTable: any;
	public filtro: any = {
		situacao: SituacaoEnum.ATIVO.toUpperCase()
	};
	public gridId: string = "#tableUsuarios";
	public permissions: Permission;
	public resource: string;
	public pageTitle: string;
	public tipoCadastro: number;
	public isColaborador: boolean;
	public idCliente: number;
	public idCondominio: number;
	public idUsuario: number;
	public getSituacaoFromBoolean = getSituacaoFromBoolean;

	constructor(private usuariosHttpService: UsuariosHttpService,
			private activatedRoute: ActivatedRoute,
			private router: Router,
			private messageService: MessageService,
			private permissionService: PermissionService,
			private authService: AuthService,
			private spinner: NgxSpinnerService,
			private gruposDeUsuariosHttpService: GruposDeUsuariosHttpService) {
		this.resource = this.activatedRoute.data['value']['resource'];
		if (this.resource === '/condominos') this.tipoCadastro = TIPO_USUARIO.CONDOMINO;
		if (this.resource === '/colaboradores') this.tipoCadastro = TIPO_USUARIO.FUNCIONARIO;
		this.pageTitle = this.tipoCadastro === TIPO_USUARIO.CONDOMINO ? 'Condôminos' : 'Colaboradores';
		this.isColaborador = this.tipoCadastro == TIPO_USUARIO.FUNCIONARIO;
		this.idUsuario = this.authService.getIdCurrentUser();
	}

	ngOnInit() {
		this.idCliente = this.authService.getIdCurrentCliente();
		this.idCondominio = this.authService.getIdCurrentCondominio();
		this.getAllByCliente();
		this.getPermissions();
	}

	private getAllByCliente(): void {
		this.entities = [];
		this.reloadTable();
		this.spinner.show();
		let filterParams = {
			id_cliente: this.idCliente, 
			tipo: this.tipoCadastro, 
			id_condominio: this.idCondominio, 
			id_usuario: this.idUsuario, 
			bloco: false, 
			unidade: false, 
			listar_unidades: true, 
			telefones: this.isTipoCadastroCondomino(),
			situacao: this.filtro.situacao
		};
		forkJoin([
			this.usuariosHttpService.getAllByClienteCondominioAndTipo(filterParams),
			this.gruposDeUsuariosHttpService.getAllByCliente(this.idCliente)
		])
		.pipe(finalize(() => this.spinner.hide()))
		.subscribe(
			([usuarios, gruposDeUsuarios]: [Usuario[], GrupoDeUsuario[]]) => {
				this.entities = usuarios;
				this.gruposDeUsuarios = gruposDeUsuarios;
				this.showTable();
			},
			error => this.messageService.error('Erro ao realizar a consulta. Tente novamente', 'Erro', error)
		);
	}


	private showTable(): void {
		if (!this.dataTable) {
			setTimeout(() =>
			this.dataTable = jQuery(this.gridId).DataTable({
				columnDefs: [
					{ orderable: false, targets: -1, width: '40px' }
				],
				language: { 'url': './assets/scripts/datatables-ptbr.json' }
			}
			), 200);
		}
	}

	public reloadTable(): void {
		if (this.dataTable) {
			this.dataTable.destroy();
			this.dataTable = null;
		}
	}

	public insert(): void {
		this.router.navigate(['./new'], { relativeTo: this.activatedRoute });
	}

	public edit(entity: Usuario): void {
		this.router.navigate(['./', entity.id], { relativeTo: this.activatedRoute });
	}

	public delete(usuario: Usuario): void {
		if (confirm(`Tem certeza que deseja excluir o usuário ${usuario.nome}? Ao excluir o usuário, ele perderá o acesso a todos os condomínios, excluir mesmo assim?`)) {
			usuario.id = usuario.id_usuario;
			this.usuariosHttpService.delete(usuario).subscribe(
				() => {
					this.messageService.success('Usuário excluído com sucesso');
					this.getAllByCliente();
				},
				error => {
					switch (error.status) {
						case 403:
							this.messageService.warning(`Não é possivel excluir o usuário ${usuario.nome}, pois o mesmo possui lançamentos no financeiro ou faz parte do conselho administrativo do condomínio.`, 'Atenção');
							break;
						default:
							this.messageService.error('Erro ao excluir usuário, favor contate o administrador do sistema', 'Erro', error)
							break;
					}
				}
			);
		}
	}

	public getPermissions(): void {
		this.permissionService.getPermissions(this.resource).subscribe(
			permissions => this.permissions = permissions
		);
	}

	public isTipoCadastroCondomino() {
		return this.tipoCadastro === TIPO_USUARIO.CONDOMINO ;
	}

	public getGrupoDeUsuarioLabel(id: number): String {
		const grupoDoUsuario = this.gruposDeUsuarios.find(grupo => grupo.id === id);
		return grupoDoUsuario ? grupoDoUsuario.nome : '';
	} 

}
