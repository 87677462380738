import { BaseEntity } from '../base-entity';
import { CategoriaContas } from './categoria-contas';
import { TipoConsumo } from './tipo-consumo';
import { UnidadeAntecipacao } from './unidade-antecipacao';
import { UnidadeIsencao } from './unidade-isencao';

export class ConfiguracaoCondominio extends BaseEntity {
	id_condominio: number;
	dia_vencimento_boleto: number;
	id_plano_de_contas: number;
	tipo_rateio: number;
	fracao_ideal: any;
	tipo_multa: number;
	multa: any;
	tipo_juros: number;
	juros: any;
	tipo_fundo_reserva: number;
	fundo_reserva: any;
	id_conta_bancaria_padrao: number;
	tipo_divisao_rateio: number;
	identificacao_multa: any;
	identificacao_juros: any;
	desconto: any;
	tipo_desconto: any;
	id_categoria_fundo_reserva: number;
	id_categoria_taxa_condominio: number;
	categoriaContasFundoReserva: CategoriaContas;
	categoriaContasTaxaCondominio: CategoriaContas;
	data_desconto: any;
	nome: string;
	id_categoria_juros_renegociacao: number;
	categoriaContasJurosRenegociacao: CategoriaContas;
	id_categoria_conta_multa_juros: number;
	id_categoria_pagamento_juros: number;
	categoriaContasMultaJuros: CategoriaContas;
	categoriaPagamentoJuros: CategoriaContas;
	competencia_titulo: number;
	tiposConsumo: TipoConsumo[];
	titulo_envio_email: string;
	fundo_reserva_proprietario: boolean;
	unidadesIsencao: UnidadeIsencao[];
	unidadesAntecipacao: UnidadeAntecipacao[];
}

export enum TIPO_DIVISAO_RATEIO {
	FRACAO_SOLO = 1,
	IGUALITARIO = 2
};

export const tiposDivisaoRateio = [
	{ id: 1, nome: 'Fração solo' },
	{ id: 2, nome: 'Igualitário' },
	// {id: 3, nome: 'Misto'}
	{ id: 4, nome: 'Não rateado' },
	{ id: 5, nome: 'Previsão Orçamentária' },
	{ id: 6, nome: 'Ocupação' },
];

export const competenciaTitulo = [
	{ id: 1, nome: 'Atual' },
	{ id: 2, nome: 'Anterior' },
];