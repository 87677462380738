import { Component, OnInit } from '@angular/core';

import { BaseModal } from '../../../componentes-utils/modal';
import { ConselhosCondominio, Usuario } from '../../../models';
import { CondominiosConselhoHttpService } from '../services';
import { MessageService } from '../../../core';
import { UsuariosHttpService } from '../../usuarios';

declare var jQuery: any;

@Component({
    selector: 'condominios-conselho-historico-modal',
    templateUrl: './condominios-conselho-historico-modal.component.html',
    providers: [
        CondominiosConselhoHttpService
    ]
})
export class CondominiosConselhoHistorioModalComponent implements OnInit, BaseModal {

    entities: ConselhosCondominio[];

    constructor(private condominiosConselhoHttpService: CondominiosConselhoHttpService,
                private messageService: MessageService,
                private usuariosHttpService: UsuariosHttpService) { }

    ngOnInit() {
        this.entities = [];
    }

    // Seta o objecto "usuarioConselho" com o usuário carregado
    private setUsuarioConselho(entity: ConselhosCondominio) {
        if (!entity || !entity.id_usuario_conselho) {
            return;
        }
        this.usuariosHttpService.get(entity.id_usuario_conselho)
            .subscribe(
                usuario => entity.usuarioConselho = usuario,
                error => this.messageService.error('Erro ao carregar o usuário. Tente novamente', 'Erro')
            );
    }

    open(idCondominio?: number) {
        this.entities = [];
        this.condominiosConselhoHttpService.getHistoricoDoisAnos(idCondominio)
            .subscribe(
                entities => {
                    entities.forEach(entity => {
                        this.setUsuarioConselho(entity);
                        this.entities.push(entity);
                    });

                    jQuery('#conselhoHistoricoModal').modal('show');
                },
                error => this.messageService.error('Erro ao carregar o histório de dois anos do conselho desse condomínio. Tente novamente', 'Erro', error)
            );
    }

    close() {
        jQuery('#conselhoHistoricoModal').modal('hide');
    }
}
