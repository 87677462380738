export class ReciboFiltro {
	id_cliente: number;
	id_condominio: number;
	data_emissao_inicial: Date;
	data_emissao_final: Date;
  data_pagamento_inicial: Date;
	data_pagamento_final: Date;
  id_fornecedor: number;

  constructor() {
    this.limpar();
  }

	/** Limpa o filtro */
	limpar() {
    this.id_cliente = null;
    this.id_condominio = null;
    this.data_pagamento_inicial = null;
    this.data_pagamento_final = null;
    this.id_fornecedor = null;
		const today = new Date();
		this.data_emissao_inicial = new Date(today.getFullYear(), today.getMonth(), 1); // primeiro dia do mês
		this.data_emissao_final = new Date(today.getFullYear(), today.getMonth()+1, 0); // último dia do mês
	}
}