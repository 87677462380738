import { BaseEntity } from '../base-entity';
import { Extrato } from './extrato';
import { ExtratoBanco } from './extrato-banco';

export class ConciliacaoExtrato extends BaseEntity {
	id_extrato_banco: number;
	id_extrato: number;
	hash: string;
	sistema: boolean;

	extrato: Extrato;
	extratoBanco: ExtratoBanco;
	checado: boolean;
}