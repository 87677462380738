import { BaseEntity } from '../base-entity';
import { Anexo } from './anexo';

export class Documento extends BaseEntity {
	id_anexo: number;
	id_documento_pasta: number;
	id_condominio: number;
	nome: string;
	documento: string;
	data_competencia: Date;
	livro: boolean;
	observacao: string;
	privado: boolean;

	tamanho_arquivo: number;
	anexo: Anexo;
	checado: boolean;

	constructor() {
		super();
		this.livro = false;
		this.anexo = new Anexo();
		this.privado = false;
		this.id_documento_pasta = null;
	}
};
