import { Injectable } from '@angular/core';

/** Service com funções uteis para trabalhar com datas */
@Injectable()
export class DataUtilsService {

    /**
     * Recupera o mês e ano no formato: MM/YYYY de uma data passada no formato: DD/MM/YYYY
     */
    getMesAnoFormatados(dataString: string): string {
        if (!dataString || dataString === '') {
            return '';
        }
        // Formato: DD/MM/YYYY
        const mes = dataString.substring(3, 5);
        const ano = dataString.substring(6);
        return `${mes}/${ano}`;
    }

    /** Altera o separador da data. Por exemplo, altera o formato de DD/MM/YYYY para DD-MM-YYYY */
    replaceSeparator(dataString, oldSeparator, newSeparator: string): string {
        if (!dataString) {
            return '';
        }
        return dataString.replace(new RegExp(oldSeparator, 'g'), newSeparator);
    }

    /** Formata a data informada para a impressão de relatórios */
    formatarDataParaRelatorio(data: Date): string {
        if (!data) {
            return '';
        }

        const dataString = data.toLocaleDateString('pt-br');
        return this.replaceSeparator(dataString, '/', '-');
    }

}
