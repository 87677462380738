import { BaseEntity } from '../base-entity';
import { LancamentoUnidade } from './lancamento-unidade';

export class PagamentoUnidade extends BaseEntity {
	id_lancamento_unidade: number;
	id_rateio_unidade: number;
	cobrado: boolean;
	valor: any;
	numero_parcela: number;
	data_competencia: Date;

	selected: boolean;
	lancamentoUnidade: LancamentoUnidade;

	constructor() {
		super();
		this.selected = false;
		this.cobrado = false;
		this.lancamentoUnidade = new LancamentoUnidade();
	}
}