import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService, MessageService } from 'app/core';
import { CategoriaContasHttpService } from 'app/home/plano-de-contas/categoria-contas';
import { CategoriaContas } from 'app/models';
import { datepickerMesAnoOptions } from 'app/shared';
import { RelatorioModalComponent } from '../relatorio-modal';
import { LancamentosCategoriaContaRelFiltro } from './lancamentos-categoria-conta-rel-filtro';

@Component({
  selector: 'lancamentos-categoria-conta-rel',
  templateUrl: './lancamentos-categoria-conta-rel.component.html',
  styleUrls: ['./lancamentos-categoria-conta-rel.component.css']
})
export class LancamentosCategoriaContaRelComponent implements OnInit {

  @ViewChild('relatorioModal') relatorioModal: RelatorioModalComponent;

  public filtro = new LancamentosCategoriaContaRelFiltro();
  public datepickerDataCompetenciaOptions = datepickerMesAnoOptions;
  public categoriasContas: CategoriaContas[] = [];

  constructor(private authService: AuthService,
    private messageService: MessageService,
    private categoriaContasHttpService: CategoriaContasHttpService) { }

  ngOnInit() {
    this.filtro.id_cliente = this.authService.getIdCurrentCliente();
    this.filtro.id_condominio = this.authService.getIdCurrentCondominio();
    this.filtro.id_usuario = this.authService.getIdCurrentUser();
    this.getAllCategoriasByCondominio();
    this.limpar();
  }

  public limpar(): void {
    this.filtro.limpar();
  }

  private validateView(): boolean {
    if (!this.filtro.competencia_inicial) {
      this.messageService.info('É necessário informar a competência início para imprimir', 'Informação');
      return false;
    }
    return true;
  }

  public view(): void {
    if (this.validateView()) {
      this.relatorioModal.url = '/relatorio/lancamentos-cateogoria-conta';
      this.relatorioModal.params = this.filtro;
      this.relatorioModal.open();
    }
  }

  /**
   * Buscar todas as contas do condomínio
   */
  private getAllCategoriasByCondominio(): void {
    this.categoriasContas = [];
    this.categoriaContasHttpService.getPlanoCompletoCondominio(this.filtro.id_condominio).subscribe(
      response => {
        this.categoriasContas = response;
      },
      error => {
        this.messageService.error('Erro ao carregar plano de contas do condomínio!', 'Erro!', error);
      }
    );
  }

  public onSelectConta(conta: CategoriaContas): void {
    this.filtro.categoriaConta = conta;
    this.filtro.id_categoria_conta = conta.id;
  }

  public onCleanCategoriaContas(): void {
    this.filtro.id_categoria_conta = null;
    this.filtro.categoriaConta = null;
  }

}