import { nomenclaturaBloco } from './../../../models/entidades/bloco';
import { Component, Output, Input, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService, MessageService, FormsUtilsService } from '../../../core';
import { Condominio, Bloco, UnidadeBulk, tiposUnidade, Endereco } from '../../../models';
import { BaseCadastroComponent } from '../../../componentes-base';
import { CondominiosHttpService } from './../services';
import { EnderecoComponent, EnderecoHttpService } from '../../../componentes-utils/endereco';
import { BlocosHttpService } from '../../blocos';
import { UnidadesHttpService } from './../../blocos/unidades/services/unidades-http.service';
import { PermissionService } from '../../../core/services/permission/permission.service';
import { CnpjFormatPipe } from '../../../shared/pipes';
declare var jQuery: any;

@Component({
  selector: 'condominios-cadastro',
  templateUrl: 'condominios-cadastro.component.html',
  styleUrls: ['./condominios-cadastro.component.css'],
  providers: [
    BlocosHttpService,
    UnidadesHttpService,
    PermissionService,
    CnpjFormatPipe
  ]
})
export class CondominiosCadastroComponent extends BaseCadastroComponent<Condominio> implements OnInit {
  @ViewChild('endereco') enderecoComponent: EnderecoComponent;
  @Input() isLogin: boolean; // isLogin significa que o cadastrado está sendo feito dentro do login
  // ou seja, quando ainda não existem condomínios cadastrados.

  @Output() saveLogin = new EventEmitter<Condominio>();
  @Output() cancelLogin = new EventEmitter<any>();

  quantidadeBlocos: number;
  andaresPorBloco: number;
  unidadesPorAndar: number;
  blocos: Bloco[];
  blocoUnico = false;
  tipoUnidade: number = null;
  tiposUnidade = tiposUnidade;
  nomenclatura: number = 1;
  nomenclaturasBloco = nomenclaturaBloco;
  alfabeto = [
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
    'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
  ];
  prefixo: string = "Bloco";
  showLoader: boolean = false;

  constructor(activatedRoute: ActivatedRoute,
              router: Router,
              messageService: MessageService,
              private authService: AuthService,
              private condominiosHttpService: CondominiosHttpService,
              private enderecoHttpService: EnderecoHttpService,
              private unidadesHttpService: UnidadesHttpService,
              permissionService: PermissionService,
              private cnpjFormatPipe: CnpjFormatPipe,
              private formsUtilsService: FormsUtilsService) {
    super(Condominio, condominiosHttpService, activatedRoute, router, messageService, permissionService);
    this.blocos = [];
  }



  // Carrega o endereço do usuário
  private getEndereco(idEndereco: number) {
    if (idEndereco) {
      this.enderecoHttpService.get(idEndereco)
        .subscribe(
          endereco => this.entity.endereco = endereco,
          error => this.messageService.error('Erro ao carregar o endereço do condomínio. Tente novamente', 'Erro', error)
        );
    } else {
      this.entity.endereco = new Endereco();
    }
  }

  /** Adicionar um novo condomínio  */
  insert() {
    this.editMode = false;
    this.entity = new Condominio();
    this.afterInsert();
  }

  /** Editar o condomínio atualmente selecionado */
  edit(idCondominio: number) {
    this.editMode = true;
    this.load(idCondominio);
  }

  afterEdit(entity: Condominio) {
    this.getEndereco(entity.id_endereco);
  }

  /** Ativa/Desativa o condomínio */
  onChangeAtivo(ativo: boolean) {
    this.condominiosHttpService.ativaDesativa(this.entity.id, ativo)
      .subscribe(
        () => {
        },
        error => this.messageService.error('Erro ao alterar a situação Ativo/Inativo do condomínio. Tente novamente', 'Erro', error)
      );
  }

  public onChangeCnpj(event: any): void {
    this.entity.cnpj = this.cnpjFormatPipe.transform(event);
    if (this.entity.cnpj && this.entity.cnpj.length === 18)
      jQuery('#cep').focus();
  }

  onChangeQuantidade() {
    this.createBlocos();
  }

  onChangeNomenclatura(nomenclatura) {
    for (let i = 0; i < this.blocos.length; i++) {
      if (nomenclatura == 1) {
        this.blocos[i].nome = `${this.prefixo} ${this.alfabeto[i]}`;
      }
      if (nomenclatura == 2) {
        this.blocos[i].nome = `${this.prefixo} ${i + 1}`;
      }
      if (nomenclatura == 3) {
        this.blocos[i].nome = `${this.prefixo}`;
      }
    }
  }

  onBlurQuantidade() {
    if (this.quantidadeBlocos === 1) {
      this.blocoUnico = true;
    }
  }

  private createBlocos() {
    this.blocos = [];
    if (this.quantidadeBlocos === 1) {
      return;
    }

    let items = new Array();
    for (let i = 1; i <= this.quantidadeBlocos; i++) {
      const bloco = new Bloco();
      this.nomenclatura == 2 ? bloco.nome = `${this.prefixo} ${i}` : bloco.nome = `${this.prefixo} ${this.alfabeto[i - 1]}`
      items.push(bloco);
    }

    this.blocos = items;
  }

  private validaSaveBlocos(): boolean {
    for (const bloco of this.blocos) {
      if (!bloco.nome || bloco.nome.trim() === '') {
        this.messageService.info('É necessário informar o nome do bloco', 'Informação');
        return false;
      }
    }
    return true;
  }

  insereNomeNosBlocos() {
    this.blocos.forEach(bloco => {
      let nomeFinal = this.prefixo + ' - ' + bloco.nome;
      bloco.nome = nomeFinal;
    });
  }

  beforeSave(): boolean {

    if (!this.formsUtilsService.isValidCnpj(this.entity.cnpj)) {
      this.messageService.info('O CNPJ informado não é um CNPJ válido', ' Informação');
      return false;
    }
    if (this.quantidadeBlocos <= 0) {
      this.messageService.info('A quantidade de blocos deve ser maior do que zero', 'Informação');
      return false;
    }
    if (this.andaresPorBloco <= 0) {
      this.messageService.info('A quantidade de andares por blocos deve ser maior do que zero', 'Informação');
      return false;
    }
    if (this.unidadesPorAndar <= 0) {
      this.messageService.info('A quantidade de unidades por andar deve ser maior do que zero', 'Informação');
      return false;
    }
    if (!this.validaSaveBlocos()) {
      return false;
    }
    this.entity.id_cliente = this.authService.getIdCurrentCliente();
    return true;
  }

  save(): Promise<Condominio> {
    this.cadastrarBlocos();
    return new Promise<Condominio>((resolve, reject) => {
      this.makeAllFieldsDirty();
      this.enderecoComponent.makeAllFieldsDirty();

      if (this.form.valid) {
        if (this.beforeSave()) {
          this.showLoader = true;
          this.btnSave.button('loading');

          this.enderecoComponent.save().then(endereco => {
            this.entity.id_endereco = endereco.id;
            this.entity.blocos = this.blocos;
            this.entity.quantidade_andar = this.andaresPorBloco;
            this.entity.quantidade_por_andar = this.unidadesPorAndar;
            this.entity.tipo_unidade = this.tipoUnidade;
            super.save()
              .then(entity => {
                resolve(entity)
                this.showLoader = false;
              })
              .catch(() => {
                reject({message: 'Não foi possível validar o cadastro'})
                this.showLoader = false;
              });
          }).catch(() => {
            this.showLoader = false;
            this.btnSave.button('reset');
            reject({message: 'Não foi possível validar o cadastro'});
          });
        } else {
          reject({message: 'Não foi possível validar o cadastro'});
        }
      } else {
        this.showMessageFormInvalid();
        reject({message: 'Não foi possível validar o cadastro'});
      }
    });
  }

  afterSave(entity: Condominio) {
    super.afterSave(entity);
    if (!this.editMode) this.onTrocarCondominio(entity);
  }

  onTrocarCondominio(condominio: Condominio) {
    this.authService.setLoggedInfo(condominio);
    this.router.navigate(['./condominios-configuracao']);
    setTimeout(() => location.reload(), 500);
  }

  cadastrarBlocos() {
    if (this.blocoUnico) {
      const blocoUnico = new Bloco();
      blocoUnico.nome = 'A';

      this.blocos = [];
      this.blocos.push(blocoUnico);
    }
  }

  cadastrarUnidades(bloco: Bloco) {
    const unidade = new UnidadeBulk(bloco.id, this.andaresPorBloco, this.unidadesPorAndar, this.tipoUnidade);
    this.unidadesHttpService.postBulk(unidade)
      .subscribe(
        () => {
        },
        error => this.messageService.error('Erro ao cadastrar as unidades. Tente novamente', 'Erro', error)
      );
  }

  public onChangeRazaoSocial(razaoSocial: string): void {
    this.entity.nome_fantasia = razaoSocial;
  }

}
