import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';

import { MessageService, DataTablesService } from '../../core';
//import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

import { Condominio } from '../../models';
@Component({
    selector: 'login-seleciona-condominio',
    templateUrl: './login-seleciona-condominio.component.html',
    styleUrls: ['./login-seleciona-condominio.component.css']
})
export class LoginSelecionaCondominioComponent implements OnChanges {
    @Input() condominios: Condominio[];

    @Output() selecionar = new EventEmitter<Condominio>();
    @Output() cancel = new EventEmitter<any>();

    constructor(private messageService: MessageService,
                private dataTableService: DataTablesService) { }

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            if (propName === 'condominios') {
                this.showTable();
            }
        }
    }

    private showTable() {
        setTimeout(() => {
            this.dataTableService.showTable('#tableSelecionaCondominios', {
                lengthChange: false,
                paging: false,
                info: false
            });
        }, 200);
    }

    selecionarClick(condominio: Condominio) {
        this.selecionar.emit(condominio);
    }

    cancelClick() {
        this.cancel.emit();
    }
}
