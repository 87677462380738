import { Component, Input, OnChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { UnidadeEstrutura } from './unidade-estrutura';
import { UnidadeEstruturaItem } from './unidade-estrutura-item';
import { ConsumosUnidadesHttpService, ConsumosUnidadesUtilsService } from '../services';
import { Permission } from 'app/componentes-base';
import { css } from './visualizacao.css';
import { DatePipe } from '@angular/common';
import { isNull } from 'util';
import { AuthService, MessageService } from 'app/core';
import { Bloco, Condominio, TipoConsumo, Unidade } from 'app/models';
import { ConsumoUnidadeHistoricoModalComponent } from '../consumo-unidade-historico-modal';

declare var jQuery: any;

interface Andares {
	andar: string;
	unidades: UnidadeEstrutura[];
}

@Component({
	selector: 'consumos-unidades-estrutura',
	templateUrl: './consumos-unidades-estrutura.component.html',
	styleUrls: ['./consumos-unidades-estrutura.component.css']
})
export class ConsumosUnidadesEstruturaComponent implements OnChanges {

	@Input() entities: any[];
	@Input() tiposConsumo: TipoConsumo[];
	@Input() dataCompetencia: string;
	@Input() selectedBloco: Bloco;
	@Input() permissions: Permission;
	@Output() onSaveItem: EventEmitter<any> = new EventEmitter();
	@Output() onDeleteItem: EventEmitter<any> = new EventEmitter();
	@Output() onUpdateList: EventEmitter<any> = new EventEmitter();

	@ViewChild('consumoUnidadeHistoricoModal') consumoUnidadeHistoricoModal: ConsumoUnidadeHistoricoModalComponent;

	public andares: Andares[];
	public selectedTab: string;
	public selectedTipoConsumo: TipoConsumo;
	public dataConsumo: Date;
	public condominio: Condominio;

	constructor(private consumosUnidadesUtilsService: ConsumosUnidadesUtilsService,
		private consumosUnidadesHttpService: ConsumosUnidadesHttpService,
		private datePipe: DatePipe,
		private messageService: MessageService,
		private authService: AuthService) {
		this.selectedTab = 'tab-0';
	}

	ngOnChanges() {
		this.condominio = this.authService.getCurrentCondominio();
		if (this.entities) {
			this.selectedTipoConsumo = null;
			if (isNull(this.selectedTipoConsumo)) {
				if (Array.isArray(this.tiposConsumo) && this.tiposConsumo.length > 0) {
					this.selectedTipoConsumo = this.tiposConsumo[0];
				}
			}
			if (isNull(this.selectedTipoConsumo)) {
				const link = `/#/blocos`;
				this.messageService.warning(`Serviços não configurados para as unidades do condomínio!</br>Configure <a href="${link}"><b>aqui</b></a>.`, 'Atenção');
			}
			this.agruparUnidadesPorAndar();
		}
	}

	private agruparUnidadesPorAndar() {
		this.entities.forEach(entity => {
			const group_to_values = entity.estrutura.reduce((obj, item) => {
				obj[item.unidade.andar] = obj[item.unidade.andar] || [];
				obj[item.unidade.andar].push(item);
				return obj;
			}, {});
			entity.andares = Object.keys(group_to_values).map(key => {
				return { andar: key, unidades: group_to_values[key] };
			});
		});
	}

	public onChange(unidadeEstruturaItem: UnidadeEstruturaItem): void {
		unidadeEstruturaItem.consumoUnidade.editado = true;
	}

	public onChangeConsumoAnterior(unidadeEstruturaItem: UnidadeEstruturaItem, unidade: any): void {
		this.changeConsumo(unidadeEstruturaItem, unidade);
		this.onChange(unidadeEstruturaItem);
		this.onChangeQuantidadeConsumo(unidadeEstruturaItem, unidade);
	}

	/**
	 * Evento do mouse direito para edição da leitura anterior
	 * @author Marcos Frare
	 * @since 25/10/2021
	 */
	public editLeituraAnterior(event, item): void {
		event.preventDefault();
		item.consumoUnidade.editConsumoAnterior = !item.consumoUnidade.editConsumoAnterior;
	}

	/**
	 * Validação da 
	 * @param unidadeEstruturaItem 
	 * @author Marcos Frare
	 * @since 04/09/2020
	 */
	public onChangeQuantidadeConsumo(unidadeEstruturaItem: UnidadeEstruturaItem, unidade: Unidade): void {
		// Verificar dependência da tabela
		if (!unidadeEstruturaItem.tipoConsumo.id_dependencia) {
			unidadeEstruturaItem.consumoUnidade.consumo_atual = unidadeEstruturaItem.consumoUnidade.consumo_atual != 0 ? unidadeEstruturaItem.consumoUnidade.consumo_atual : unidadeEstruturaItem.consumoUnidade.consumo_anterior;
			if (unidadeEstruturaItem.consumoUnidade.consumo_atual) {
				const consumoUnidade = unidadeEstruturaItem.consumoUnidade.consumo_atual - unidadeEstruturaItem.consumoUnidade.consumo_anterior;
				if (consumoUnidade < 0) {
					unidadeEstruturaItem.consumoUnidade.consumo_atual = unidadeEstruturaItem.consumoUnidade.consumo_atual_copy ? unidadeEstruturaItem.consumoUnidade.consumo_atual_copy : null;
					this.messageService.error('O valor de leitura informado está menor que a leitura anterior.', 'Verifique!');
				} else {
					if (unidadeEstruturaItem.consumoUnidade && unidadeEstruturaItem.consumoUnidade.consumo_atual && unidadeEstruturaItem.consumoUnidade.tolerancia && unidadeEstruturaItem.consumoUnidade.tolerancia.ativo) {
						let tolerancia = unidadeEstruturaItem.consumoUnidade.tolerancia;
						let min = tolerancia.tolerancia_inferior > 0 ? Number(tolerancia.media) - (Number(tolerancia.media) * Number(tolerancia.tolerancia_inferior) / 100) : null;
						let max = tolerancia.tolerancia_inferior > 0 ? Number(tolerancia.media) + (Number(tolerancia.media) * Number(tolerancia.tolerancia_inferior) / 100) : null;

						let msg = '';
						if (consumoUnidade < min) {
							msg = `O valor informado na leitura ficou abaixo da tolerância configurada. Deseja manter a leitura informada?`;
						}
						if (consumoUnidade > max) {
							msg = `O valor informado na leitura ficou acima da tolerância configurada. Deseja manter a leitura informada?`;
						}

						if (msg != '') {
							if (confirm(msg)) {
								this.updateConsumo(unidadeEstruturaItem, unidade);
							} else {
								unidadeEstruturaItem.consumoUnidade.consumo_atual = null;
							}
						} else {
							this.updateConsumo(unidadeEstruturaItem, unidade);
						}
					} else {
						this.updateConsumo(unidadeEstruturaItem, unidade);
					}
				}
			} else {
				this.updateConsumo(unidadeEstruturaItem, unidade);
			}
		}
	}

	/**
	 * Grava dados da leitura
	 * @param unidadeEstruturaItem 
	 * @param unidade 
	 * @author Marcos Frare
	 * @since 07/10/2020
	 */
	private updateConsumo(unidadeEstruturaItem: UnidadeEstruturaItem, unidade: Unidade): void {
		this.changeConsumo(unidadeEstruturaItem, unidade);
		this.onSaveConsumoUnidade(unidadeEstruturaItem.consumoUnidade);
		this.onChange(unidadeEstruturaItem);
	}

	private changeConsumo(unidadeEstruturaItem: UnidadeEstruturaItem, unidade: Unidade): void {
		const consumoAnterior = unidadeEstruturaItem.consumoUnidade.consumo_anterior;
		const consumoAtual = unidadeEstruturaItem.consumoUnidade.consumo_atual;
		unidadeEstruturaItem.consumoUnidade.valor = this.consumosUnidadesUtilsService.calcularValorConsumido(unidade, consumoAnterior, consumoAtual, unidadeEstruturaItem.tipoConsumo);
	}

	public onChangeDataConsumo(): void {
		if (this.dataConsumo) {
			this.dataConsumo = new Date(this.dataConsumo);
		}
	}

	public onUpdate(tabId: string, tipoConsumo: TipoConsumo): void {
		this.selectedTab = tabId;
		this.selectedTipoConsumo = tipoConsumo;
		this.onUpdateList.emit();
	}

	public onSaveConsumoUnidade(consumoUnidade: any): void {
		consumoUnidade.editado = true;
		this.onSaveItem.emit(consumoUnidade);
	}

	public onDeleteConsumoUnidade(consumoUnidade: any): void {
		this.onDeleteItem.emit(consumoUnidade);
	}

	public validConsumoAnterior(item: any): boolean {
		return Number(item.consumoUnidade.consumo_anterior) < 0;
	}

	public temServicoUnidade(unidade: Unidade, idTipoConsumo: number): boolean {
		for (const servicoUnidade of unidade.tipos_consumo) {
			if (servicoUnidade.id === idTipoConsumo) {
				return true;
			}
		}
		return false;
	}

	public print(printableArea: string): void {
		let printContent = document.getElementById(printableArea).innerHTML;
		const printWindow = window.open('', 'PRINT', 'height=400,width=600');
		printWindow.document.write(` 
            <html>
           <head>
                <style>${css}</style>
            </head>                  
            <body>${printContent}</body>   
            </html>`);
		printWindow.document.close();
		printWindow.focus();
		printWindow.print();
		printWindow.close();
	}

	private formataData(data: Date): string {
		const date = new Date(data);
		return this.datePipe.transform(date.setHours(1), 'dd-MM-yyyy');
	}

	public saveLeitura(tipoConsumo): void {
		const btn = jQuery('#btnSaveLeitura');
		btn.button('loading');
		const payload = {
			id: tipoConsumo.id_leitura,
			nome_leiturista: tipoConsumo.nome_leiturista,
			data_leitura: this.formataData(tipoConsumo.data_leitura),
			id_tipo_consumo: tipoConsumo.id,
			data_competencia: this.dataCompetencia
		};
		this.consumosUnidadesHttpService.saveLeitura(payload).subscribe(
			() => btn.button('reset'),
			() => {
				btn.button('reset');
				this.messageService.error('Erro ao salvar a leitura, favor contate o administrador do sistema', 'Erro')
			}
		);
	}

	/**
	 * Ação do click no botão para vizualização do histórico de alterações
	 * @param consumoUnidade 
	 * @author Marcos Frare
	 * @since 01/11/2021
	 */
	public onViewHistory(consumoUnidade: any): void {
		this.consumoUnidadeHistoricoModal.idConsumoUnidade = consumoUnidade.id;
		this.consumoUnidadeHistoricoModal.open();
	}

}
