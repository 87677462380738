import { Injectable } from '@angular/core';
import { ConfiguracaoLivro, ConfiguracaoLivroItem, Filtro } from 'app/models';
import { Crud, CustomHttp, CrudService, ConfigService, AuthService } from 'app/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';

@Injectable({
  providedIn: 'root'
})
export class LivroConfiguracaoHttpService implements Crud<ConfiguracaoLivro> {

  public resource = 'livro/configuracao';
  private baseUrl: string;
  private baseAcessoUrl: string;
  private id_cliente: number;

  constructor(private customHttp: CustomHttp,
      private crudService: CrudService,
      private configService: ConfigService,
      private authService: AuthService) {
    this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
    this.baseAcessoUrl = `${configService.getBaseUrl()}/${this.resource}`;
    this.id_cliente = this.authService.getIdCurrentCliente();
  }

  public get(id: number): Observable<ConfiguracaoLivro> {
    return this.crudService.get<ConfiguracaoLivro>(this.resource, id);
  }

  public getAll(): Observable<ConfiguracaoLivro[]> {
    const params = {
      id_cliente: this.id_cliente,
      id_condominio: this.authService.getIdCurrentCondominio()
    }
    return this.crudService.getAll<ConfiguracaoLivro>(this.resource, {params});
  }

  public post(entity: ConfiguracaoLivro): Observable<ConfiguracaoLivro> {
    return this.crudService.post<ConfiguracaoLivro>(this.resource, entity);
  }

  public put(entity: ConfiguracaoLivro): Observable<ConfiguracaoLivro> {
    return this.crudService.put<ConfiguracaoLivro>(this.resource, entity);
  }
  
  public delete(entity: ConfiguracaoLivro): Observable<string> {
    return this.crudService.delete<ConfiguracaoLivro>(this.resource, entity);
  }

  /**
   * Busca item de configuração do livro pelo ID
   * @param id 
   * @author Marcos Frare
   * @since 22/07/2020
   */
  public getItemById(id: number): Observable<ConfiguracaoLivroItem> {
    return this.customHttp.get(`${this.baseUrl}/item/${id}`).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
  }

  /**
   * Exclusão de um item pelo ID
   * @param id 
   * @author Marcos Frare
   * @since 22/07/2020
   */
  public deleteItem(id: number): Observable<ConfiguracaoLivroItem> {
    return this.customHttp.delete(`${this.baseUrl}/item/${id}`).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
  }

  /**
   * Remeter formulário para persistencia dos dados
   * @param item 
   * @author Marcos Frare
   * @since 22/07/2020
   */
  public saveItem(item: ConfiguracaoLivroItem): Observable<ConfiguracaoLivroItem> {
    return this.customHttp.post(`${this.baseUrl}/item`, item).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
  }

  /**
   * Busca os ítens de uma configuração de livro
   * @param id 
   * @author Marcos Frare
   * @since 22/07/2020
   */
  public getItensByIdConfiguracao(id: number): Observable<ConfiguracaoLivroItem[]> {
    return this.customHttp.get(`${this.baseUrl}/itens/${id}`).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
  }

  /**
   * Busca todas as configurações de um cliente
   * @author Marcos Frare
   * @since 22/07/2020
   */
  public getAllCliente(): Observable<ConfiguracaoLivro[]> {
    const params = {
      id_cliente: this.id_cliente
    }
    return this.customHttp.get(`${this.baseUrl}/buscarCliente`, {params}).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
  }

  /**
   * Busca os filtros pelo ID do relatório
   * @param id 
   * @author Marcos Frare
   * @since 09/12/2020
   */
  public getFiltros(id_relatorio: number, id: number): Observable<Filtro[]> {
    const params = { id_condominio: this.authService.getIdCurrentCondominio(), id };
    return this.customHttp.get(`${this.baseUrl}/item/filtros/${id_relatorio}`, {params}).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
  }

  /**
   * Buscar configuração para geração
   * @returns 
   * @author Marcos Frare
   * @since 13/12/2022
   */
  public getAllGeracao(): Observable<ConfiguracaoLivro[]> {
    const params = {
      id_cliente: this.id_cliente,
      id_condominio: this.authService.getIdCurrentCondominio()
    }
    return this.customHttp.get(`${this.baseUrl}/buscarGeracao`, {params}).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
  }

}