import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { AuthService, MessageService } from 'app/core';
import { UnidadesHttpService, UnidadesUsuariosHttpService } from 'app/home/blocos';
import { ConsumoUnidadePesquisaModalComponent } from 'app/home/consumos-unidades';
import { LancamentosUnidadesPagamentosConsultaModalComponent } from 'app/home/lancamentos-unidades';
import { ConsumoUnidade, PagamentoUnidade, ProprietariosMoradores, Titulo, Unidade } from 'app/models';
import { BoletosHttpService } from '../services';

declare var jQuery: any;

@Component({
  selector: 'titulo-editar-modal',
  templateUrl: './titulo-editar-modal.component.html',
  styleUrls: ['./titulo-editar-modal.component.css']
})
export class TituloEditarModalComponent implements OnInit, BaseModal {

  @ViewChild('lancamentoUnidadePagamentoPesquisaModal') lancamentoUnidadePagamentoPesquisaModal: LancamentosUnidadesPagamentosConsultaModalComponent;
  @ViewChild('consumoUnidadePesquisaModal') consumoUnidadePesquisaModal: ConsumoUnidadePesquisaModalComponent;

  @Input() idModal: string;
  @Input() idTitulo: number;
	@Output() onSave: EventEmitter<any> = new EventEmitter();

  public titulo: Titulo = new Titulo();
  public pagamentos: PagamentoUnidade[] = [];
  public consumos: ConsumoUnidade[] = [];
  public proprietariosMoradores: ProprietariosMoradores[] = [];
  public unidade: Unidade = new Unidade();

  constructor(private boletosHttpService: BoletosHttpService,
		private authService: AuthService,
		private messageService: MessageService,
    private unidadesUsuariosHttpService: UnidadesUsuariosHttpService,
    private unidadeHttpService: UnidadesHttpService) { }

  ngOnInit() {
    if (!this.idModal) {
      this.idModal = 'tituloEditarModal';
    };
  }

  public open(): void {
    // Buscar os moradores da unidade para carregar o sacado
    this.loadTitulo();
		jQuery(`#${this.idModal}`).modal('show');
	}

	public close(): void {
    this.titulo = new Titulo();
    this.unidade = new Unidade();
    this.pagamentos = [];
    this.consumos = [];
		jQuery(`#${this.idModal}`).modal('hide');
	}

  /**
   * Caregar o título para edição
   * @author Marcos Frare
   * @since 13/08/2021
   */
  private loadTitulo(): void {
    this.pagamentos = [];
    this.consumos = [];
    if (!this.idTitulo) {
      this.close();
    }
    this.boletosHttpService.getTituloEdicao(this.idTitulo).subscribe(
      response => {
        this.titulo = response.titulo;
        this.pagamentos = response.pagamentos;
        this.consumos = response.consumos;
        this.getMoradores();
        this.getUnidade();
      },
      error => {
        this.messageService.error('Erro ao buscar dados do título! Tente novamente.', 'Erro!', error);
        this.close();
      }
    );
  }

  /**
   * Busca os condôminos da unidade
   * @author Marcos Frare
   * @since 12/08/2021
   */
  private getMoradores(): void {
    this.proprietariosMoradores = [];
    this.unidadesUsuariosHttpService.getAllByUnidade(this.titulo.id_unidade).subscribe(
      response => {
        this.proprietariosMoradores = response;
      },
      error => {
        this.messageService.error('Erro ao buscar condôminos da unidade! Tente novamente.', 'Erro!', error);
      }
    );
  }

  /**
   * Busca a unidade do título
   * @author Marcos Frare
   * @since 13/08/2021
   */
  private getUnidade(): void {
    this.unidadeHttpService.get(this.titulo.id_unidade).subscribe(
      response => {
        this.unidade = response;
      },
      error => {
        this.messageService.error('Erro ao buscar a unidade do título! Tente novamente.', 'Erro!', error);
      }
    );
  }

  /**
	 * Salvar alteração do título
	 * @author Marcos Frare
   * @since 12/08/2021
	 */
	public onSaveClick(): void {
    if (confirm(`Com a confirmação, o título e seus ítens serão atualizados. Caso possua boleto, será cancelado. Deseja executar esta operação?`)) {
      let btnSaveTitulo = jQuery('#btnSalvarTitulo');
      btnSaveTitulo.button('loading');
      this.boletosHttpService.alteracaoTitulo(this.titulo, this.pagamentos, this.consumos).subscribe(
        () => {
          this.onSave.emit();
          this.messageService.success('Alteração de título realizada com sucesso.', 'Sucesso!');
          btnSaveTitulo.button('reset');
          this.close();
        },
        error => {
          btnSaveTitulo.button('reset');
          this.messageService.error('Erro na alteração do título! Tente novamente.', 'Erro!', error);
        }
      );
    }
	}

  /**
   * Adicionar um lançamento para a unidade
   * @author Marcos Frare
   * @since 24/08/2021
   */
  public addPagamentoUnidade(): void {
    this.lancamentoUnidadePagamentoPesquisaModal.open();
  }

  /**
   * Pagamento selecionado no modal
   * @param pagamento 
   * @author Marcos Frare
   * @since 24/08/2021
   */
  public onSelectedLancamentos(pagamentos: PagamentoUnidade[]): void {
    pagamentos.forEach(pg => {
      // let valorTitulo = Number(this.titulo.valor_original) + Number(parseFloat(pg.valor));
      this.titulo.valor_original = Number(this.titulo.valor_original) + Number(parseFloat(pg.valor));
      this.pagamentos.push(pg);
    });
  }

  /**
   * Remover um item de pagamento
   * @param pagamento 
   * @author Marcos Frare
   * @since 20/08/2021
   */
  public onRemovePagamento(pagamento: any): void {
    this.titulo.valor_original = Number(this.titulo.valor_original) - Number(parseFloat(pagamento.valor));
    this.pagamentos = this.pagamentos.filter(pg => pg.id != pagamento.id);
  }

  /**
   * Adicionar o consumo para a unidade
   * @autor Marcos Frare
   * @since 24/08/2021
   */
  public addConsumoUnidade(): void {
    this.consumoUnidadePesquisaModal.open();
  }

  /**
   * Remover consumo da unidade
   * @param consumo 
   * @author Marcos Frare
   * @since 24/08/2021
   */
  public onRemoveConsumo(consumo: ConsumoUnidade): void {
    this.titulo.valor_original = Number(this.titulo.valor_original) - Number(parseFloat(consumo.valor));
    this.consumos = this.consumos.filter(cs => cs.id != consumo.id);
  }

  /**
   * Seleção de consumos da pesquisa modal
   * @param consumos 
   * @author Marcos Frare
   * @since 25/08/2021
   */
  public onSelectedConsumos(consumos: ConsumoUnidade[]): void {
    consumos.forEach(cs => {
      this.titulo.valor_original = Number(this.titulo.valor_original) + Number(cs.valor);
      this.consumos.push(cs);
    });
  }

}