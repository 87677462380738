import { Component, Input, OnInit } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { AuthService, MessageService } from 'app/core';
import { UsuariosHttpService } from 'app/home/usuarios';

declare var jQuery: any;

interface ResetPassword {
  senhaAtual: string,
  novaSenha: string,
  repeteSenha: string
}

@Component({
  selector: 'alterar-senha-modal',
  templateUrl: './alterar-senha-modal.component.html',
  styleUrls: ['./alterar-senha-modal.component.css']
})
export class AlterarSenhaModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;

  public resetSenha: ResetPassword;

  constructor(private authService: AuthService,
    private usuarioHtttpService: UsuariosHttpService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.loadEntity();
  }

  private loadEntity(): void {
    this.resetSenha = {senhaAtual: '', novaSenha: '', repeteSenha: ''};
  }

  open() {
    this.loadEntity();
    jQuery(`#${this.idModal}`).modal('show');
  }

  close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Valida senhas digitadas
   * @returns 
   * @author Marcos Frare
   * @since 21/05/2021
   */
  private validaSenhas(): boolean {
    if (!this.resetSenha.senhaAtual) {
      this.messageService.warning('Senha atual é obrigatório!', 'Atenção!');
      return false;
    }
    if (this.resetSenha.novaSenha != this.resetSenha.repeteSenha) {
      this.messageService.warning('Nova senha e senha repetida são diferentes!', 'Atenção!');
      return false;
    }

    return true;
  }

  /**
   * Alterar senha
   * @author Marcos Frare
   * @since 21/05/2021
   */
  public alterar(): void {
    let btnReset = jQuery('#btnResetSenha');
    btnReset.button('loading');
    const id_usuario = this.authService.getIdCurrentUser();
    if (this.validaSenhas()) {
      this.usuarioHtttpService.resetPassword(id_usuario, this.resetSenha.senhaAtual, this.resetSenha.novaSenha).subscribe(
        response => {
          switch (response.status) {
            case 200: {
              this.messageService.success('Senha alterada com sucesso!');
              btnReset.button('reset');
              this.close();
              break;
            }
            case 204: {
              this.messageService.warning('Senha atual inválida!', 'Atenção!');
              btnReset.button('reset');
              this.loadEntity();
              break;
            }
            default: {
              this.messageService.success('Algo de errado aconteceu! tente novamente.', 'Atenção!');
              btnReset.button('reset');
              this.loadEntity();
              break;
            }
          }
        },
        error => {
          btnReset.button('reset');
          this.messageService.error('Algo de errado aconteceu! Tente novamente.', 'Erro', error);
        }
      );
    }
  }

}