import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

import { AuthService, MessageService } from '../../../core';
import { BaseModal } from '../../../componentes-utils/modal';
import { Bloco } from '../../../models';
import { BlocosHttpService } from '../services';

declare var jQuery: any;

@Component({
	selector: 'blocos-pesquisa-modal',
	templateUrl: './blocos-pesquisa-modal.component.html',
	styleUrls: ['./blocos-pesquisa-modal.component.css']
})
export class BlocosPesquisaModalComponent implements OnInit, BaseModal {
	@Input() idModal: string; // id do modal para identificar um modal caso em uma mesma tela possa ter mais de um modal
	@Input() blocos: Bloco[];
	@Output() select = new EventEmitter<Bloco>();

	constructor(private authService: AuthService,
		private messageService: MessageService,
		private blocosHttpService: BlocosHttpService) { }

	ngOnInit() {
		if (!this.idModal) {
			this.idModal = 'blocosPesquisaModal';
		}
	}

	open() {
		jQuery(`#${this.idModal}`).modal('show');
	}

	close() {
		jQuery(`#${this.idModal}`).modal('hide');
	}

	/** Ao selecionar uma categoria de conta */
	selectClick(bloco: Bloco) {
		this.select.emit(bloco);
		this.close();
	}
}