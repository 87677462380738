import { BaseEntity } from '../base-entity';
import { Usuario } from './usuario';

export const tiposConselhosCondominio = [
	{ id: 1, nome: 'Síndico' },
	{ id: 2, nome: 'Sub Síndico' },
	{ id: 3, nome: 'Conselheiro Fiscal 1' },
	{ id: 4, nome: 'Conselheiro Fiscal 2' },
	{ id: 5, nome: 'Conselheiro Fiscal 3' },
	{ id: 6, nome: 'Conselheiro Consultivo 1' },
	{ id: 7, nome: 'Conselheiro Consultivo 2' },
	{ id: 8, nome: 'Conselheiro Consultivo 3' },
	{ id: 9, nome: 'Suplente 1' },
	{ id: 10, nome: 'Suplente 2' },
	{ id: 11, nome: 'Suplente 3' },
	{ id: 99, nome: 'Outros' }
];

export class ConselhosCondominio extends BaseEntity {
	tipo: number;
	id_usuario_conselho: number;
	id_condominio: number;
	data_entrada: Date;
	data_saida: Date;
	id_bloco: number;

	usuarioConselho: Usuario;
	usuarioConselhoNotFound: boolean;
	renovando: boolean;

	constructor(tipo?: number, idCondominio?: number) {
		super();
		this.tipo = tipo;
		this.id_condominio = idCondominio;
		this.renovando = false;
	}
}
