import { BaseEntity } from '../base-entity';
import { Filtro } from './filtro';
import { Formulario } from './formulario';

export class ConfiguracaoLivroItem extends BaseEntity {
	id_configuracao_livro: number;
	tipo: string;
	titulo: string;
	id_formulario: number;
	id_relatorio: number;
	sequencia: number;
	sumario: boolean;
	controlar_frente_verso: boolean;

	formulario: Formulario;
	filtros: Filtro[];
}

export enum TIPO_ITEM_CONFIGURACAO_LIVRO {
	FORMULARIO = 'Formulário',
	RELATORIO = 'Relatório',
	ANEXO = 'Anexo',
	EXTRATO = 'Extratos',
	OUTROSANEXOS = 'Outros Anexos',
	SUMARIO = 'Sumário',
}

export const tiposItemConfiguracaoLivro = [
	{ value: 'FORMULARIO', label: 'Formulário' },
	{ value: 'RELATORIO', label: 'Relatório' },
	{ value: 'ANEXO', label: 'Anexos' },
	{ value: 'EXTRATO', label: 'Anexo de extratos' },
	{ value: 'OUTROSANEXOS', label: 'Outros anexos' },
	{ value: 'SUMARIO', label: 'Sumário' },
];