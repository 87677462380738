import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';
import { Crud, CrudService, ConfigService, CustomHttp, AuthService } from '../../../core';
import { LancamentoEspecifico } from '../../../models';
import { PagamentoEspecificoRateio } from 'app/home/pagamentos';

@Injectable()
export class LancamentosEspecificosHttpService implements Crud<LancamentoEspecifico> {
	resource = 'lancamentosespecificos';
	resourcePagamento = 'pagamentoespecifico'
	private baseUrl: string;
	private basePagamentoUrl: string;

	constructor(private customHttp: CustomHttp,
		private crudService: CrudService,
		private configService: ConfigService,
		private authService: AuthService) {
		this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
		this.basePagamentoUrl = `${configService.getBaseUrl()}/${this.resourcePagamento}`;
	}

	get(id: number): Observable<LancamentoEspecifico> {
		return this.crudService.get<LancamentoEspecifico>(this.resource, id);
	}

	getAll(): Observable<LancamentoEspecifico[]> {
		return this.crudService.getAll<LancamentoEspecifico>(this.resource);
	}

	post(entity: LancamentoEspecifico): Observable<LancamentoEspecifico> {
		return this.crudService.post<LancamentoEspecifico>(this.resource, entity);
	}

	put(entity: LancamentoEspecifico): Observable<LancamentoEspecifico> {
		return this.crudService.put<LancamentoEspecifico>(this.resource, entity);
	}

	delete(entity: LancamentoEspecifico): Observable<string> {
		const id_lancamento_especifico = entity.id;
		const id_usuario = this.authService.getIdCurrentUser();
		return this.customHttp.post(`${this.baseUrl}/desativar`, { id_lancamento_especifico, id_usuario }).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	getAllByCondominio(idCondominio: number): Observable<any> {
		return this.customHttp.get(`${this.baseUrl}/consultar/condominio/${idCondominio}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	search(filtro: any): Observable<any> {
		return this.customHttp.post(`${this.baseUrl}/filtrar`, filtro).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	obterTodosEspecificosParaRateioPeloCondominio(idCondominio: number): Observable<PagamentoEspecificoRateio[]> {
		return this.customHttp.get(`${this.basePagamentoUrl}/consultar/rateio/condominio/${idCondominio}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	obterTodosEspecificosParaRateioPeloRateioECondominio(idDemonstrativoRateio: number, idCondominio: number): Observable<PagamentoEspecificoRateio[]> {
		return this.customHttp.get(`${this.basePagamentoUrl}/consultar/rateio/${idDemonstrativoRateio}/condominio/${idCondominio}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

}