import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { AuthService, MessageService } from 'app/core';
import { LocalHttpService } from 'app/home/local';
import { Local, ReservaCalendarioBloqueio } from 'app/models';
import { ReservaBloqueioHttpService } from '../service';

declare var jQuery: any;

@Component({
  selector: 'reserva-bloqueio-cadastro-modal',
  templateUrl: './reserva-bloqueio-cadastro-modal.component.html',
  styleUrls: ['./reserva-bloqueio-cadastro-modal.component.css']
})
export class ReservaBloqueioCadastroModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @Input() id: number;
  @Output() onSave: EventEmitter<ReservaCalendarioBloqueio> = new EventEmitter()

  public entity: ReservaCalendarioBloqueio = new ReservaCalendarioBloqueio();
  public allSelected: boolean = false;
		

  constructor(private authService: AuthService,
    private messageService: MessageService,
    private reservaBloqueioHttpService: ReservaBloqueioHttpService,
    private localHttpService: LocalHttpService) { }

  ngOnInit() {
    if (!this.idModal) {
      this.idModal = 'notificacaoCadastroModal';
    }
  }

  /**
   * Carrega os locais do condomínio
   * @author Marcos Frare
   * @since 31/05/2021
   */
  private loadLocal(): void {
    this.entity.locais = [];
    this.localHttpService.getAllByCondominio(this.authService.getIdCurrentCondominio()).subscribe(
      response => this.entity.locais = response,
      error => this.messageService.error('Erro ao buscar locais do condomínio!', 'Erro', error)
    );
  }

  /**
   * Carregar entidade para edicao ou vazio
   * @author Marcos Frare
   * @since 28/05/2021
   */
  private loadEntity(): void {
    this.entity = new ReservaCalendarioBloqueio();
    this.entity.id_condominio = this.authService.getIdCurrentCondominio();
    if (this.id) {
      this.reservaBloqueioHttpService.get(this.id).subscribe(
        response => this.entity = response,
        error => {
          this.messageService.error('Erro ao buscar dados para edição! Tente novamente.', 'Erro', error);
        }
      );
    } else {
      this.loadLocal();
    }
  }

  open() {
    this.loadEntity();
    jQuery(`#${this.idModal}`).modal('show');
  }

  close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * seleção de todos
   * @author Marcos Frare
   * @since 31/05/2021
   */
  public toggleAllSelected(): void {
    this.entity.locais.forEach(lc => {
      lc.selected = this.allSelected;
    });
	}

  /**
   * Verifica se todos os ítens selecionados
   * @returns 
   * @author Marcos Frare
   * @since 31/05/2021
   */
  public checkAllSelected(): boolean {
    for (let lc of this.entity.locais) {
      if (!lc.selected) {
        return false;
      }
    }
    return true;
	}

  /**
   * Salvar dados do bloqueio
   * @author Marcos Frare
   * @since 31/05/2021
   */
  public salvar(): void {
    let btn = jQuery('#btnSalvar');
    btn.button('loading');
    const fn = this.entity.id ? this.reservaBloqueioHttpService.put(this.entity) : this.reservaBloqueioHttpService.post(this.entity);
    fn.subscribe(
      response => {
        btn.button('reset');
        if (response.msg) {
          this.messageService.warning(response.msg, 'Atenção!');
        } else {
          this.messageService.success('Boqueio salvo com sucesso!');
          this.onSave.emit(response);
          this.close();
        }
      },
      error => {
        btn.button('reset');
        this.messageService.error('Erro ao gravar dados! Tente novamente', 'Erro!', error);
      }
    );
  }

  /**
   * Selecionar o local
   * @param local 
   * @author Marcos Frare
   * @since 31/05/2021
   */
  public selectLocal(local: Local): void {
    local.selected = !local.selected;
  }

}