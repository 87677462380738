import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared';

import { UsuariosComponent } from './usuarios.component';
import { UsuariosCadastroComponent } from './cadastro/usuarios-cadastro.component';
import { UsuariosCadastroModalComponent } from './cadastro-modal/usuarios-cadastro-modal.component';
import { UsuariosPesquisaModalComponent } from './pesquisa-modal/usuarios-pesquisa-modal.component';
import { EnderecoModule } from '../../componentes-utils/endereco';
import { TelefonesComponent } from './telefones/telefones.component';

import { UsuariosHttpService } from './services';
import { TelefonesService } from './telefones/services/telefones.service';
import { TelefonesEditarComponent } from './telefones/telefones-editar/telefones-editar.component';
import { AcessosCondominioComponent } from './cadastro/acessos-condominio/acessos-condominio.component';
import { AcessosCondominioHttpService } from './cadastro/acessos-condominio/services/acessos-condominio-http.service';
import { AcessosCondominioService } from './cadastro/acessos-condominio/services/acessos-condominio.service';
import { UiSwitchModule } from 'ngx-ui-switch';


@NgModule({
    imports: [
        SharedModule,
        EnderecoModule,
        UiSwitchModule
    ],
    declarations: [
        UsuariosComponent,
        UsuariosCadastroComponent,
        UsuariosCadastroModalComponent,
        UsuariosPesquisaModalComponent,
        TelefonesComponent,
        TelefonesEditarComponent,
        AcessosCondominioComponent
    ],
    exports: [
        UsuariosCadastroModalComponent,
        UsuariosPesquisaModalComponent
    ],
    providers: [
        UsuariosHttpService,
        TelefonesService,
        AcessosCondominioHttpService,
        AcessosCondominioService
    ]
})
export class UsuariosModule { }
