import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MessageService, AuthService } from '../../core';
import { Formulario } from '../../models';
import { FormulariosHttpService } from './services';

declare var jQuery: any;

@Component({
	selector: 'formularios-livro',
	templateUrl: './formularios.component.html'
})
export class FormulariosComponent implements OnInit {

	public entities: Formulario[] = [];
	public idCliente: number;

	constructor(private activatedRoute: ActivatedRoute,
			private router: Router,
			private messageService: MessageService,
			private formulariosHttpService: FormulariosHttpService,
			private authService: AuthService) {
		this.idCliente = this.authService.getIdCurrentCliente();
	}


	ngOnInit() {
		this.getAll();
	}

	getAll() {
		this.formulariosHttpService.getAllByCliente(this.idCliente)
			.subscribe(
				entities => {
					this.entities = entities;
					this.showTable();
				},
				error => this.messageService.error('Erro ao consultar os formulários. Tente novamente', 'Erro', error)
			);
	}

	private showTable() {
		setTimeout(() =>
			jQuery('#tableFormularios').DataTable({
				columnDefs: [
					{ orderable: false, targets: -1, width: '40px' }
				],
				language: { 'url': './assets/scripts/datatables-ptbr.json' }
			}), 200);
	}

	insert() {
		this.router.navigate(['./new'], { relativeTo: this.activatedRoute });
	}

	public onEditFormularioClick(entity: Formulario): void {
		this.router.navigate(['./', entity.id], { relativeTo: this.activatedRoute });
	}

	public onRemoveFormularioClick(entity: Formulario): void {
		if (confirm(`Tem certeza que deseja excluir este formulário ${entity.nome}?`)) {
			if (entity.id) { // Com id deleta do banco de dados
				this.formulariosHttpService.delete(entity).subscribe(
					() => {
						const index = this.entities.findIndex(form => form.id === entity.id);
						this.deleteOfList(index);
						this.messageService.success('', `Formulário ${entity.nome} foi excluído com sucesso`);
					},
					error => {
						switch (error.status) {
							case 403:
								this.messageService.warning(
									`Não foi possível excluir o forumlário ${entity.nome}, pois o mesmo está sendo utilizado no sistema. Verifique a utilização do mesmo e tente excluir novamente.`,
									'Erro', error);
								break
							default:
								this.messageService.error(
									`Erro ao excluir o formulário ${entity.nome}. Tente novamente`,
									'Erro', error);
						}
					}
				);
			}
		}
	}

	private deleteOfList(index: number): boolean {
		if (index > -1) {
			this.entities.splice(index, 1);
			return true;
		}
		return false;
	}
}
