import { Component, Input, OnInit } from '@angular/core';
import { EmailEnvio } from 'app/models';
import { BaseModal } from '../modal';

declare var jQuery: any;

@Component({
	selector: 'envio-email-log-modal',
	templateUrl: './envio-email-log-modal.component.html',
	styleUrls: ['./envio-email-log-modal.component.css']
})
export class EnvioEmailLogModalComponent implements OnInit, BaseModal {

	@Input() idModal: string;
	@Input() data?: EmailEnvio[];

	constructor() { }

	ngOnInit() {
		if (!this.idModal) {
			this.idModal = 'envioEmailLogModal';
		};
	}

	/**
	 * Abrir modal
	 * @author Marcos Frare
	 * @since 27/08/2020
	 */
	public open(): void {
		jQuery(`#${this.idModal}`).modal('show');
	}

	public close(): void {
		jQuery(`#${this.idModal}`).modal('hide');
	}
}