import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared';
import { LancamentoPagamentosComponent } from './lancamento-pagamentos.component';
import { PagamentoCancelamentoModalComponent } from './pagamento-cancelamento-modal';

@NgModule({
	imports: [
		SharedModule,
	],
	declarations: [
		LancamentoPagamentosComponent,
		PagamentoCancelamentoModalComponent
	],
	exports: [
		LancamentoPagamentosComponent
	],
	providers: []
})
export class LancamentoPagamentosModule { }