import { Injectable } from '@angular/core';
import { ConfigService, Crud, CrudService, CustomHttp } from 'app/core';
import { Anexo, ExtratoAnexo } from 'app/models';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';

@Injectable()
export class ExtratoAnexoHttpServiceService implements Crud<ExtratoAnexo> {

  resource = 'extrato/anexo';
  private baseUrl: string;

  constructor(private customHttp: CustomHttp,
      private crudService: CrudService,
      private configService: ConfigService) {
    this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
  }
  get(id: number): Observable<ExtratoAnexo> {
    return this.crudService.get<ExtratoAnexo>(this.resource, id);
  }
  getAll(): Observable<ExtratoAnexo[]> {
    return this.crudService.getAll<ExtratoAnexo>(this.resource);
  }
  post(entity: ExtratoAnexo): Observable<ExtratoAnexo> {
    return this.crudService.post<ExtratoAnexo>(this.resource, entity);
  }
  put(entity: ExtratoAnexo): Observable<ExtratoAnexo> {
    return this.crudService.put<ExtratoAnexo>(this.resource, entity);
  }
  delete(entity: ExtratoAnexo): Observable<string> {
    return this.crudService.delete<ExtratoAnexo>(this.resource, entity);
  }

  /**
   * Buscar pelo filtro
   * @param filtro 
   */
  public getByFiltro(filtro: any): Observable<ExtratoAnexo[]> {
    return this.customHttp.get(`${this.baseUrl}/consultar`, {params: filtro})
      .pipe(
        map(response => response.json()),
        catchError(error => _throw(error))
      );
  }

  /**
   * Busca pelo ID do Anexo
   * @param id_anexo 
   * @author Marcos Frare
   * @since 19/11/2020
   */
  public buscaAnexo(id_anexo: number): Observable<Anexo> {
    return this.customHttp.get(`${this.configService.getBaseUrl()}/anexo/${id_anexo}`)
      .pipe(
        map(response => response.json()),
        catchError(error => _throw(error))
      );
  }

}