import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { MessageService } from '../../../core';
import { Bloco, BlocoNovoAndar, tiposUnidade } from '../../../models';
import { UnidadesHttpService } from '../unidades/services/unidades-http.service';

declare var jQuery: any;

@Component({
    selector: 'blocos-novo-andar-modal',
    templateUrl: './blocos-novo-andar-modal.component.html'
})
export class BlocosNovoAndarModalComponent {

    @ViewChild('formNovoAndar') private form: NgForm;

    @Input() private bloco: Bloco;
    @Output() private callback = new EventEmitter<boolean>();

    numeroAndar: number;
    unidadesPorAndar: number;
    tipoUnidade: number = null;
    tiposUnidade = tiposUnidade;

    constructor(private messageService: MessageService,
                private unidadesHttpService: UnidadesHttpService) { }


    private clear() {
        this.form.resetForm();
    }

    open() {
        this.clear();
        jQuery('#modalNovoAndar').modal('show');
        setTimeout(() => {
            jQuery('#numeroAndar').focus();
        }, 200);
    }

    close() {
        jQuery('#modalNovoAndar').modal('hide');
        this.clear();
    }

    criarNovoAndar() {
        const btn = jQuery('#btnCriarNovoAndar');
        btn.prop('disabled', true);

        const novoAndar = new BlocoNovoAndar(this.bloco.id, this.numeroAndar, this.unidadesPorAndar, this.tipoUnidade);
        this.unidadesHttpService.criarNovoAndar(novoAndar)
            .subscribe(
                () => {
                    btn.prop('disabled', false);
                    this.close();
                    this.callback.emit(true);
                },
                error => {
                    btn.prop('disabled', false);
                    this.messageService.error('Erro ao criar o novo andar. Tente novamente', 'Erro', error);
                }
            );
    }

    cancel() {
        this.close();
        this.callback.emit(false);
    }
}
