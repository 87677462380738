import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared';
import { FornecedoresModule } from '../fornecedores';
import { SegurosComponent } from './seguros.component';
import { SegurosCadastroComponent } from './cadastro/seguros-cadastro.component';
import { SegurosHttpService } from './services';
import { PlanoDeContasModule } from '../plano-de-contas';
import { TypeaheadModule } from 'ngx-bootstrap';

@NgModule({
    imports: [
        SharedModule,
        FornecedoresModule,
        TypeaheadModule,
        PlanoDeContasModule
    ],
    declarations: [
        SegurosComponent,
        SegurosCadastroComponent
    ],
    providers: [
        SegurosHttpService
    ]
})
export class SegurosModule { }
