import { Component, Input, OnInit } from '@angular/core';
import { TiposConsumoHistoricoHttpService } from './services';
import { MessageService } from 'app/core';

declare var jQuery: any;

@Component({
    selector: 'tipos-consumo-historico',
    templateUrl: './tipos-consumo-historico.component.html',
    styleUrls: ['./tipos-consumo-historico.component.css']
})
export class TiposConsumoHistoricoComponent implements OnInit {

    @Input() idTipoConsumo: number;
    @Input() unidadeMedida: string;
    @Input() idModal: string;
    public entities: any[];

    constructor(
        private tiposConsumoHistoricoHttpService: TiposConsumoHistoricoHttpService,
        private messageService: MessageService) {}

    ngOnInit() {
    }

    public open(): void {
        this.getAll();
        jQuery(`#${this.idModal}`).modal('show');
    }

    private getAll(): void {
        this.tiposConsumoHistoricoHttpService.getAllByTipoConsumo(this.idTipoConsumo).subscribe(
            entities => {
                this.entities = entities
            },
            error => this.messageService.error('Erro ao obter histórico dos tipos de consumo', 'Erro', error)
        );
    }

    private close(): void {
        jQuery(`#${this.idModal}`).modal('hide');
    }

    public cancel(): void {
        this.close();
    }

}
