import { BaseEntity } from '../base-entity';

export class ConfiguracaoNotificacaoAutomatica extends BaseEntity {
	nome: string;
	situacao: string;
	id_condominio: number;
	tipo: number;
	id_formulario: number;
	frequencia_execucao: string;
	dia_mes: number;
	referencia: string;
	dias_vencimento: number;
	titulo_anexo: boolean;
	observacao: string;

	nome_formulario: string;

	constructor() {
		super();
		this.situacao = 'ATIVO';
		this.frequencia_execucao = 'DIARIA';
		this.referencia = 'DEPOIS';
		this.titulo_anexo = false;
		this.tipo = 1;
	}

}