import { Component, OnInit, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { MessageService } from 'app/core';
import { FabricanteVeiculo, ModeloVeiculo, Veiculo } from 'app/models';
import { FabricanteVeiculoCadastroModalComponent, FabricanteVeiculoHttpService, ModeloVeiculoCadastroModalComponent, ModeloVeiculoHttpService } from '../fabricante-veiculo';
import { VeiculoHttpService } from '../service';

declare var jQuery: any;

@Component({
  selector: 'veiculo-cadastro-modal',
  templateUrl: './veiculo-cadastro-modal.component.html',
  styleUrls: ['./veiculo-cadastro-modal.component.css']
})
export class VeiculoCadastroModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @Input() id: number;
  @Output() onSave: EventEmitter<Veiculo> = new EventEmitter();

  @ViewChild('fabricanteVeiculoCadastroModal') fabricanteVeiculoCadastroModal: FabricanteVeiculoCadastroModalComponent;
  @ViewChild('modeloVeiculoCadastroModal') modeloVeiculoCadastroModal: ModeloVeiculoCadastroModalComponent;

  public entity: Veiculo;
  public fabricantes: FabricanteVeiculo[] = [];
  public modelos: ModeloVeiculo[] = [];
  public modelosFabricante: ModeloVeiculo[] = [];

  constructor(private fabricanteVeiculoHttpService: FabricanteVeiculoHttpService,
    private modeloVeiculoHttpService: ModeloVeiculoHttpService,
    private veiculoHttpService: VeiculoHttpService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.entity = new Veiculo();
    if (!this.idModal) {
      this.idModal = 'veiculoCadastroModal';
    }
  }

  /**
   * Busca todos os fabricantes
   * @author Marcos Frare
   * @since 15/04/2021
   */
  private getAllFabricante(): void {
    this.fabricantes = [];
    this.fabricanteVeiculoHttpService.getAll().subscribe(
      response => {
        this.fabricantes = response;
      },
      error => {
        this.messageService.error('Erro ao buscar fabricantes! Tente novamente.', 'Erro', error);
      }
    );
  }

  /**
   * Busca todos os modelos para filtrar ao selecionar o fabricante
   * @author Marcos Frare
   * @since 15/04/2021
   */
  public getAllModelo(): void {
    this.modelos = [];
    this.modeloVeiculoHttpService.getAll().subscribe(
      response => {
        this.modelos = response;
      },
      error => {
        this.messageService.error('Erro ao buscar modelos de veículos! Tente novamente.', 'Erro', error);
      }
    );
  }

  public open() {
    this.getAllFabricante();
    this.getAllModelo();
    this.entity = new Veiculo();
    if (this.id) {
      this.veiculoHttpService.get(this.id).subscribe(
        response => {
          this.entity = response;
          this.changeFabricante();
        },
        error => {
          this.messageService.error('Erro ao buscar dados do veículo! Tente novamente', 'Erro', error);
        }
      );
    }
    jQuery(`#${this.idModal}`).modal('show');
  }
  public close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Salvar veículo
   * @author Marcos Frare
   * @since 18/03/2021
   */
  public salvar(): void {
    let btn = jQuery('#btnSaveVeiculo');
    btn.button('loading');
    const fn = this.entity.id ? this.veiculoHttpService.put(this.entity) : this.veiculoHttpService.post(this.entity);
    fn.subscribe(
      response => {
        btn.button('reset');
        this.messageService.success('Veículo salvo com sucesso!');
        this.onSave.emit(response);
        this.close();
      },
      error => {
        btn.button('reset');
        this.messageService.error('Erro ao salvar veículo! Tente novamente', 'Erro', error);
      }
    );
  }

  /**
   * Abrir tela para cadastro de fabricante de veículo
   * @author Marcos Frare
   * @since 12/04/2021
   */
  public createFabricante(): void {
    this.fabricanteVeiculoCadastroModal.id = null;
    this.fabricanteVeiculoCadastroModal.open();
  }

  /**
   * Callback de persistencia de fabricante de veículo
   * @param fabricante 
   * @author Marcos Frare
   * @since 12/04/2021
   */
  public saveFabricante(fabricante: FabricanteVeiculo): void {
    this.fabricantes.push(fabricante);
    this.entity.id_fabricante_veiculo = fabricante.id;
  }

  /**
   * Evento de alteração do fabricante
   * @author Marcos Frare
   * @since 16/04/2021
   */
  public changeFabricante(): void {
    this.modelosFabricante = this.modelos.filter(mod => mod.id_fabricante_veiculo == this.entity.id_fabricante_veiculo);
    this.entity.id_modelo_veiculo = this.modelosFabricante[0] ? this.modelosFabricante[0].id : null;
  }

  /**
   * Abrir tela para cadastro de modelo de veículo
   * @author Marcos Frare
   * @since 12/04/2021
   */
  public createModelo(): void {
    this.modeloVeiculoCadastroModal.id = null;
    this.modeloVeiculoCadastroModal.open();
  }

  /**
   * Callback de persistencia de modelo de veículo
   * @param modelo
   * @author Marcos Frare
   * @since 12/04/2021
   */
  public saveModelo(modelo: ModeloVeiculo): void {
    this.getAllModelo();
    this.modelosFabricante.push(modelo);
    this.entity.id_modelo_veiculo = modelo.id;
  }

  /**
   * Busca o veículo pela placa
   * @author Marcos Frare
   * @since 16/04/2021
   */
  public buscaVeiculoPlaca(): void {
    let placa = this.entity.placa;
    this.veiculoHttpService.obterPorPlaca(this.entity.placa).subscribe(
      response => {
        this.entity = response;
        this.entity.placa = placa;
        this.changeFabricante();
      }
    );
  }

}