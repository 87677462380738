import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';

import { Crud, CrudService, ConfigService, CustomHttp, AuthService } from '../../../core';
import { LancamentoUnidade, PagamentoUnidade } from '../../../models';

@Injectable()
export class LancamentosUnidadesHttpService implements Crud<LancamentoUnidade> {
	resource = 'lancamentounidade';
	private baseUrl: string;

	constructor(private customHttp: CustomHttp,
			private crudService: CrudService,
			private configService: ConfigService,
			private authService: AuthService) {
		this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
	}

	get(id: number): Observable<LancamentoUnidade> {
		return this.crudService.get<LancamentoUnidade>(this.resource, id);
	}

	getAll(): Observable<LancamentoUnidade[]> {
		return this.crudService.getAll<LancamentoUnidade>(this.resource);
	}

	post(entity: LancamentoUnidade): Observable<LancamentoUnidade> {
		return this.crudService.post<LancamentoUnidade>(this.resource, entity);
	}

	put(entity: LancamentoUnidade): Observable<LancamentoUnidade> {
		return this.crudService.put<LancamentoUnidade>(this.resource, entity);
	}

	delete(entity: LancamentoUnidade): Observable<string> {
		const id_lancamento_unidade = entity.id;
		const id_usuario = this.authService.getIdCurrentUser();
		return this.customHttp.post(`${this.baseUrl}/desativar`, { id_lancamento_unidade, id_usuario }).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Carrega todos os lançamentos da unidade informada
	 */
	public getAllByCondominio(idCondominio: number): Observable<any> {
		return this.customHttp.get(`${this.baseUrl}/consultar/condominio/${idCondominio}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public search(filtro: any): Observable<any> {
		return this.customHttp.post(`${this.baseUrl}/filtrar`, filtro).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}
}