import { _throw } from 'rxjs/observable/throw';
import { Permission } from './../../../componentes-base/permission';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { CustomHttp } from 'app/core';
import { ConfigService } from 'app/core/config/config.service';
import { AuthService } from '../auth';

@Injectable()
export class PermissionService {

	private baseUrl: string;

	constructor(private authService: AuthService,
		private customHttp: CustomHttp,
		private configService: ConfigService) {
		this.baseUrl = `${configService.getBaseUrl()}/acessos`;
	}

	public getPermissions(recurso: string): Observable<Permission> {
		const id_cliente = this.authService.getIdCurrentCliente();
		const id_usuario = this.authService.getIdCurrentUser();
		return this.customHttp.post(`${this.baseUrl}/recurso`, { recurso, id_cliente, id_usuario }).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public getAcessosCondomino(id_usuario: number): Observable<any> {
		return this.customHttp.get(`${this.baseUrl}/area-condomino/${id_usuario}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}
}