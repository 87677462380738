import { Injectable } from '@angular/core';

import { TipoConsumo } from '../../../models';

/**
 * Service com métodos uteis para os tipos de consumos
 */
@Injectable()
export class TiposConsumoUtilsService {

    /**
     * Verifica se o tipo de consumo informado possui um consumo mínino.
     * Se possuir os campos valor_tarifa e consumo_minimo, então o tipo de consumo
     * possui um consumo minímo que será cobrado.
     */

    /** Método removido junto com os campos consumo_minimo e valor_tarifa, o serviço será mantido
    para uma possível utilização, se não se demonstrar útil no futuro pode ser descartado.
     
     possuiConsumoMinimo(entity: TipoConsumo): boolean {
         if (!entity) {
             return false;
         }
        return entity.valor_tarifa != null && entity.consumo_minimo != null;
     }
    */
}
