import { Component, Input, EventEmitter, OnInit, Output } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { AuthService, MessageService } from 'app/core';
import { PagamentoCancelamento } from 'app/models';
import { PagamentoCancelamentoHttpService } from './service';

declare var jQuery: any;

@Component({
  selector: 'pagamento-cancelamento-modal',
  templateUrl: './pagamento-cancelamento-modal.component.html',
  styleUrls: ['./pagamento-cancelamento-modal.component.css']
})
export class PagamentoCancelamentoModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @Input() id_pagamento: number;

  @Output() onSave: EventEmitter<PagamentoCancelamento> = new EventEmitter();

  pagamentoCancelamento: PagamentoCancelamento = new PagamentoCancelamento();

  constructor(private authService: AuthService,
    private pagamentoCancelamentoHttpService: PagamentoCancelamentoHttpService,
    private messageService: MessageService) { }

  ngOnInit() {
    if (!this.idModal) {
      this.idModal = 'pagamentoCancelamentoModal';
    };
  }

  public open() {
    this.pagamentoCancelamento = new PagamentoCancelamento();
    this.pagamentoCancelamento.id_usuario = this.authService.getIdCurrentUser();
    jQuery(`#${this.idModal}`).modal('show');
  }
  public close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Validar dados para cancelamento
   * @returns 
   * @author Marcos Frare
   * @since 11/05/2021
   */
  private validate(): boolean {
    if (!this.id_pagamento) {
      this.messageService.warning('Parcela de pagamento inexistente! Tente novamente.', 'Atenção!');
      return false;
    }
    if (!this.pagamentoCancelamento.id_usuario) {
      this.messageService.warning('Usuário não identificado!', 'Atenção!');
      return false;
    }
    if (!this.pagamentoCancelamento.data_cancelamento) {
      this.messageService.warning('Data de cancelamento é obrigatória!', 'Atenção!');
      return false;
    }
    if (!this.pagamentoCancelamento.motivo) {
      this.messageService.warning('Motivo é obrigatório!', 'Atenção!');
      return false;
    }

    return true;
  }

  /**
   * Fazer cancelamento do pagamento
   * @author Marcos Frare
   * @since 11/05/2021
   */
  public save(): void {
    if (this.validate()) {
      if (confirm(`Deseja efetuar o cancelamento da parcela de pagamento?`)) {
        this.pagamentoCancelamentoHttpService.cancelarPagamento(this.pagamentoCancelamento, this.id_pagamento).subscribe(
          response => {
            // Injetar o ID do pagamento
            response.id_pagamento = this.id_pagamento;
            this.messageService.success('Cancelamento efetuado com sucesso!');
            this.onSave.emit(response);
            this.close();
          },
          error => {
            this.messageService.error('Erro ao efetuar pagamento! Tente novamente.', 'Erro!', error);
          }
        );
      }
    }
  }

}