import { Injectable } from '@angular/core';
import { ConfigService, Crud, CrudService, CustomHttp } from 'app/core';
import { TipoVeiculo } from 'app/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TipoVeiculoHttpService implements Crud<TipoVeiculo> {

  resource: string = 'veiculo/tipo';
  private baseUrl: string;
  private url: string;

  constructor(private customHttp: CustomHttp,
      private crudService: CrudService,
      private configService: ConfigService) {
    this.url = configService.getBaseUrl();
    this.baseUrl = `${this.url}/${this.resource}`;
  }

  get(id: number): Observable<TipoVeiculo> {
    return this.crudService.get<TipoVeiculo>(this.resource, id);
  }
  getAll(): Observable<TipoVeiculo[]> {
    return this.crudService.getAll<TipoVeiculo>(this.resource);
  }
  post(entity: TipoVeiculo): Observable<TipoVeiculo> {
    return this.crudService.post<TipoVeiculo>(this.resource, entity);
  }
  put(entity: TipoVeiculo): Observable<TipoVeiculo> {
    return this.crudService.put<TipoVeiculo>(this.resource, entity);
  }
  delete(entity: TipoVeiculo): Observable<string> {
    return this.crudService.delete<TipoVeiculo>(this.resource, entity);
  }
}