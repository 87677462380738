import { Bloco } from "app/models";

export class ProtocoloRelFiltro {
	id_cliente: number;
	id_condominio: number;
	id_usuario: number;
	tipo_morador: number;
	titulo: string;
	mensagem: string;
	mostrarCpfCnpj: boolean;
	mostrarData: boolean;
	tipo_envio_boleto: number;
	sacado: boolean;
	id_bloco: number;
	unidades: number[];
	situacao_financeira: string;

	bloco: Bloco;

	/** Limpa o filtro */
	limpar() {
		this.tipo_morador = 0;
		this.titulo = '';
		this.mensagem = '';
		this.mostrarCpfCnpj = true;
		this.mostrarData = true;
		this.sacado = false;
		this.tipo_envio_boleto = 0
		this.bloco = new Bloco();
		this.unidades = [];
		this.situacao_financeira = 'TODOS';
	}
}