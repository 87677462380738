import { Component, EventEmitter, OnInit, Output, ViewChild, Input } from '@angular/core';

import { BaseModal } from '../../../componentes-utils/modal';
import { ContasBancariasCadastroComponent } from '../cadastro/contas-bancarias-cadastro.component';
import { ContaBancaria} from '../../../models';

declare var jQuery: any;

@Component({
    selector: 'contas-bancarias-cadastro-modal',
    templateUrl: './contas-bancarias-cadastro-modal.component.html',
})
export class ContasBancariasCadastroModalComponent implements OnInit, BaseModal {

    @Input() idModal: string;
    @Output() afterSave = new EventEmitter<ContaBancaria>();

    @ViewChild('cadastro') cadastroComponent: ContasBancariasCadastroComponent;

    private btnSave: any;

    ngOnInit() {
        this.btnSave = jQuery('#btnSaveContasModal');
        this.cadastroComponent.cancel = () => { }; // Alterando método cancel do ContasBancariasCadastroComponent para ao salvar não excutar esse método que faz retornar para a rota de visualização
    }

    private clear() {
        this.btnSave.button('reset');
        this.cadastroComponent.form.resetForm();
        this.cadastroComponent.condominio = null;
    }

    open(idCondominio?: number) {
        this.clear();
        jQuery('#contasBancariasCadastroModal').modal('show');
        setTimeout(() => {
            this.cadastroComponent.entity = new ContaBancaria();
            this.cadastroComponent.getCondominio(idCondominio);
            this.cadastroComponent.entity.id_condominio = idCondominio;
            this.cadastroComponent.editMode = false;
            jQuery('.modal-body').find('input[id="agencia"]').focus();
        }, 200);
    }

    close() {
        jQuery('#contasBancariasCadastroModal').modal('hide');
    }
    save() {
        this.btnSave.button('loading');
        this.cadastroComponent.save()
            .then(entity => {
                this.afterSave.emit(entity);
                this.btnSave.button('reset');
                this.close();
            }).catch(() => {
                this.btnSave.button('reset');
            });
    }
}
