import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Bloco, Unidade, UnidadeBulk, Usuario, tiposUnidade } from '../../../models';
import { BaseCadastroComponent } from '../../../componentes-base';
import { AuthService, MessageService } from '../../../core';
import { BlocosHttpService } from '../services';
import { UsuariosHttpService } from '../../usuarios';
import { UnidadesHttpService } from '../unidades/services/unidades-http.service';
import { PermissionService } from '../../../core/services/permission/permission.service';
import { FloatFormatPipe } from '../../../shared/pipes';
import { Observable } from 'rxjs';

declare var jQuery: any;

@Component({
	selector: 'blocos-cadastro',
	templateUrl: './blocos-cadastro.component.html',
	styleUrls: ['./blocos-cadastro.component.css'],
	providers: [PermissionService, FloatFormatPipe]
})
export class BlocosCadastroComponent extends BaseCadastroComponent<Bloco> implements OnInit {

	public usuarios: Usuario[];
	public unidades: Unidade[];
	public andaresPorBloco: number;
	public unidadesPorAndar: number;
	public tipoUnidade: number = null;
	public tiposUnidade = tiposUnidade;
	public idCliente: number;
	public idCondominio: number;
	public idUsuario: number;
	public totalFracaoSoloUnidades: number = 0;
	public totalFracaoConfiguracao: number = 0;

	constructor(activatedRoute: ActivatedRoute,
		router: Router,
		messageService: MessageService,
		blocosHttpService: BlocosHttpService,
		permissionService: PermissionService,
		private authService: AuthService,
		private usuariosHttpService: UsuariosHttpService,
		private unidadesHttpService: UnidadesHttpService,
		private floatFormatPipe: FloatFormatPipe) {
		super(Bloco, blocosHttpService, activatedRoute, router, messageService, permissionService);
	}

	ngOnInit() {
		this.idCondominio = this.authService.getIdCurrentCondominio();
		this.idCliente = this.authService.getIdCurrentCliente();
		this.idUsuario = this.authService.getIdCurrentUser();
		super.ngOnInit();
		this.activatedRoute.params.subscribe((params: Params) => {
			let idBloco = +params['id'];
			if (!isNaN(idBloco)) {
				this.getTotalFracaoSoloUnidades(idBloco);
			}
		});
		this.getTotalFracaoSoloConfiguracao();
	}

	private getTotalFracaoSoloUnidades(idBloco: number): void {
		this.unidadesHttpService.getTotalFracaoSoloByCondominio(this.idCondominio, idBloco)
			.subscribe(totalFracaoSolo => this.totalFracaoSoloUnidades = totalFracaoSolo);
	}

	private getTotalFracaoSoloConfiguracao(): void {
		this.unidadesHttpService.getFracaoSoloByCondominio(this.idCondominio).subscribe(totalFracaoSolo => {
			this.totalFracaoConfiguracao = totalFracaoSolo;
		});
	}

	private getAllUsuarios(): void {
		let filterParams = {
			id_cliente: this.idCliente, 
			tipo: 3, 
			id_condominio: this.idCondominio, 
			id_usuario: this.idUsuario, 
			bloco: true, 
			unidade: true
		};
		this.usuariosHttpService.getAllByClienteCondominioAndTipo(filterParams).subscribe(
			entities => {
				this.usuarios = entities
				this.getAllUnidades();
			},
			error => this.messageService.error('Erro ao carregar os usuários', 'Erro', error)
		);
	}

	private getAllUnidades(): void {
		this.unidadesHttpService.getAllByBloco(this.entity.id).subscribe(
			entities => {
				this.unidades = entities;
				this.afterGetAllUnidades();
			},
			error => this.messageService.error('Erro ao carregar as unidades do bloco selecionado. Tente novamente', 'Erro', error)
		);
	}

	private afterGetAllUnidades(): void {
		if (this.unidades && this.unidades.length > 0) {
			this.unidades.forEach(unidade => {
				const indexSacado: number = this.usuarios.findIndex(usuario => usuario.condomino_id === unidade.id_sacado);
				unidade.sacado = this.usuarios[indexSacado];
				if (unidade.id_sacado) {
					unidade.permiteInformarSacado = false;
				} else {
					unidade.permiteInformarSacado = true;
				}
			});
		}
	}

	private validaSaveUnidadesNovoBloco(): boolean {
		if (this.andaresPorBloco <= 0) {
			this.messageService.info('A quantidade de andares do bloco deve ser maior do que zero', 'Informação');
			this.btnSave.button('reset');
			return false;
		}
		if (this.unidadesPorAndar <= 0) {
			this.messageService.info('A quantidade de unidades por andar deve ser maior do que zero', 'Informação');
			this.btnSave.button('reset');
			return false;
		}
		return true;
	}

	private validaSaveUnidades(): boolean {
		if (!this.unidades) {
			return true;
		}

		for (let unidade of this.unidades) {
			if (!unidade.editado) {
				continue;
			}
		}
		return true;
	}

	public afterEdit(entity: Bloco): void {
		this.getAllUsuarios();
	}

	public afterInsert(): void {
		this.entity.id_condominio = this.authService.getIdCurrentCondominio();
	}

	public beforeSave(): boolean {
		this.btnSave = jQuery('#btnSave');
		this.btnSave.button('loading');
		if (!this.editMode) {
			if (!this.validaSaveUnidadesNovoBloco()) return false;
		} else {
			if (!this.validaSaveUnidades()) return false;
			if (!this.validaTotalFracaoSolo()) return false;
			if (this.totalFracaoSolo() < this.totalFracaoConfiguracao) {
				if (confirm(`A soma da fração de solo das unidadades é menor que o total da fração de solo configurado no condomínio, dessa forma não sera possivel realizar o rateio! Deseja salvar mesmo assim?`)) {
					return true;
				} else {
					this.btnSave.button('reset');
					return false;
				}
			}
		}
		return true;
	}

	private validaTotalFracaoSolo(): boolean {
		if (this.totalFracaoSolo() > this.totalFracaoConfiguracao) {
			this.messageService.warning('A soma da fração de solo das unidades não pode ser superior a fração ideal cadastrada na configuração do condomínio', 'Atenção');
			this.btnSave.button('reset');
			return false;
		}
		return true;
	}

	public afterSave(entity: Bloco): void {
		this.saveUnidades(entity);
		this.btnSave.button('reset');
		if (!this.editMode) this.cancel();
		this.getAllUnidades();
	}

	private saveUnidades(entity: Bloco): void {
		if (this.editMode) {
			if (!this.unidades) {
				return;
			}
			this.unidades.forEach(unidade => {
				if (unidade.editado) {
					let unidadeSave: any = { ...unidade };
					const observable: Observable<Unidade> = unidade.id ? this.unidadesHttpService.put(unidadeSave) : this.unidadesHttpService.post(unidadeSave);
					observable.subscribe(
						() => { },
						error => this.messageService.error(`Erro ao salvar a unidade ${unidade.nome}. Tente novamente`, 'Erro', error)
					);
				}
			});
		} else {
			const unidade = new UnidadeBulk(this.idCliente, this.idCondominio, entity.id, this.andaresPorBloco, this.unidadesPorAndar, this.tipoUnidade);
			this.unidadesHttpService.postBulk(unidade).subscribe(
				() => { },
				error => this.messageService.error('Erro ao cadastrar as unidades. Tente novamente', 'Erro', error)
			);
		}
	}

	public onCallbackNovoAndarModal(andarCriado: boolean): void {
		if (andarCriado) {
			this.getAllUnidades();
		}
	}

	public cancel(): void {
		this.router.navigate(['../'], { relativeTo: this.activatedRoute });
	}

	public onBlocoEdit(): void {
		this.editMode = true;
		this.getAllUnidades();
		this.getAllUsuarios();
	}

	public totalFracaoSolo(): any {
		let total = 0;
		if (this.unidades) {
			total = this.unidades.reduce((sum, unidade) => {
				return sum + Number(unidade.fracao_solo);
			}, 0);
		}
		total += Number(this.totalFracaoSoloUnidades);
		return Number(total.toFixed(8));
	}
}
