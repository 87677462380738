
import { Observable ,  forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';

import { ConsumoUnidade } from '../../../../models';
import { ConsumosUnidadesHttpService } from '../../services';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ConsumosUnidadesEstruturaCadastroService {

    constructor(private consumosUnidadesHttpService: ConsumosUnidadesHttpService) { }

   // Valida se algum consumo possui o valor informado inválido antes de sava-los
   private validarSave(consumoUnidade: ConsumoUnidade): boolean {
        
        if (!consumoUnidade) {
            return false;
        }

        if (consumoUnidade.editado) {
            // Se estiver editando um consumo já salvo, valida se tem os consumos e valor informados
            if (consumoUnidade.id) {
                if (consumoUnidade.consumo_anterior == null) {
                    return false;
                }
                if (consumoUnidade.consumo_atual == null) {
                    return false;
                }
                if (consumoUnidade.valor == null) {
                    return false;
                }                        
            }
            if (consumoUnidade.consumo_anterior < 0) {
                return false;
            }
            if (consumoUnidade.consumo_atual < 0) {
                return false;
            }
            if (consumoUnidade.valor < 0) {
                return false;
            }
        }

        return true;
    }

     /* Cria um array de observables de todos os consumos que foram editados
    para salva-los posteriormente de forma simultanea */
    private createSave(consumoUnidade: any): Observable<ConsumoUnidade> {
        if (!consumoUnidade) {
            return null;
        }

        let observable: Observable<ConsumoUnidade>;
        if (consumoUnidade.editado) {
            if (consumoUnidade.id) { // PUT
                observable = this.consumosUnidadesHttpService.put(consumoUnidade)
                    .pipe(
                        map(response => response),
                        catchError(error => {
                            return Observable.throw(error);
                        })
                    );
            } else { // POST
                // Somente salva se tiver o consumo anterior, consumo atual e valor informados
                if (consumoUnidade.consumo_anterior != null && consumoUnidade.consumo_atual != null && consumoUnidade.valor != null) {
                    observable = this.consumosUnidadesHttpService.post(consumoUnidade)
                        .pipe(
                            map(response => response),
                            catchError(error => {
                                return Observable.throw(error);
                            })
                        );
                }
            }
        }
        return observable;
    }

    /**
     * Salva os consumos das unidades de um bloco em um mês de competência
     */
    save(consumoUnidade: ConsumoUnidade): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            if (this.validarSave(consumoUnidade)) {
                const save = this.createSave(consumoUnidade);
                if (save) {
                // Salvando simultaneamente todos os consumos editados
                forkJoin(save).subscribe(
                    response => resolve(response),
                    error => reject(error)
                );
                } else {
                    reject("Não foi possível salvar o consumo!");
                }
            } else {
                reject("Não foi possível salvar o consumo!");
            }
        });
    }
}
