import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../../shared';

import { TopnavbarComponent } from './topnavbar.component';
import { TrocaCondominioModalComponent } from './troca-condominio-modal';

@NgModule({
	imports: [
		SharedModule,
		RouterModule
	],
	declarations: [
		TopnavbarComponent,
		TrocaCondominioModalComponent
	],
	exports: [
		TopnavbarComponent
	],
})

export class TopnavbarModule { }