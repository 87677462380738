import { Component, Input, OnInit } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { MessageService } from 'app/core';
import { Cheque } from 'app/models';
import { PagamentosHttpService } from '../services';

declare var jQuery: any;

@Component({
  selector: 'pagamento-cheque-modal',
  templateUrl: './pagamento-cheque-modal.component.html',
  styleUrls: ['./pagamento-cheque-modal.component.css']
})
export class PagamentoChequeModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @Input() idPagamento: number;
  @Input() valor: number;

  public cheque: Cheque;

  constructor(private pagamentoHttpService: PagamentosHttpService, private messageService: MessageService) { }

  ngOnInit() {
    this.cheque = new Cheque();
    if (!this.idModal) {
      this.idModal = 'pagamentoChequeModal';
    }
  }

  public load(): void {
    this.cheque = new Cheque();
    this.cheque.id_pagamento = this.idPagamento;
    this.cheque.valor = this.valor || 0;
    this.pagamentoHttpService.getPagamentoCheque(this.idPagamento).subscribe(
      res => {
        if (res.id) {
          this.cheque = res;
          this.cheque.id_pagamento = this.idPagamento;
          this.cheque.valor = this.valor || 0;
        }
      },
      error => {
        this.messageService.error('Não foi possível carregar dados do cheque! Tente novamente', 'Erro', error);
        this.close();
      }
    );
  }

  public open() {
    this.load();
    jQuery(`#${this.idModal}`).modal('show');
  }

  public close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  public save(): void {
    let btnSave = jQuery('#btnSave');
    btnSave.button('loading');
    this.pagamentoHttpService.saveCheque(this.cheque).subscribe(
      res => {
        this.cheque = res;
        btnSave.button('reset');
        this.messageService.success('Cheque gravado com sucesso!');
      },
      error => {
        btnSave.button('reset');
        this.messageService.error('Parece que algo de errado aconteceu! Tente novamente.', 'Erro', error);
      }
    );
  }

  public delete(): void {
    if (confirm(`Deseja realmente excluir cheque de número ${this.cheque.numero}?`)) {
      let btnDelete = jQuery('#btnDelete');
      btnDelete.button('loading');
      this.pagamentoHttpService.deleteCheque(this.cheque.id).subscribe(
        () => {
          this.cheque = new Cheque();
          this.close();
          btnDelete.button('reset');
        },
        error => {
          this.messageService.error('Parece que algo deu errado! Tente novamente.', 'Erro', error);
          btnDelete.button('reset');
        }
      );
    }
  }

}