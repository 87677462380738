import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';
import { AuthService, ConfigService, Crud, CrudService, CustomHttp } from 'app/core';
import { Condomino, ContatoProprietarioMorador, ProprietariosMoradores, Veiculo } from 'app/models';

@Injectable()
export class UnidadesUsuariosHttpService implements Crud<ProprietariosMoradores> {
	resource = 'proprietariosmoradores';
	private baseUrl: string;

	constructor(private customHttp: CustomHttp,
			private crudService: CrudService,
			private configService: ConfigService,
			private authService: AuthService) {
		this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
	}

	public get(id: number): Observable<ProprietariosMoradores> {
		return this.crudService.get<ProprietariosMoradores>(this.resource, id);
	}

	public getAll(): Observable<ProprietariosMoradores[]> {
		return this.crudService.getAll<ProprietariosMoradores>(this.resource);
	}

	/**
	 * Carrega todos os usuários ligados a informada
	 */
	public getAllByUnidade(idUnidade: number): Observable<ProprietariosMoradores[]> {
		return this.customHttp.get(`${this.baseUrl}/consultar/unidade/${idUnidade}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Busca os condominos de uma unidade
	 * @param id_unidade 
	 * @author Marcos Frare
	 * @since 18/08/2021
	 */
	public getAllCondominosUnidade(id_unidade: number): Observable<Condomino[]> {
		return this.customHttp.get(`${this.baseUrl}/condominos/unidade/${id_unidade}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	public post(entity: ProprietariosMoradores): Observable<ProprietariosMoradores> {
		entity.id_condominio = this.authService.getIdCurrentCondominio();
		return this.crudService.post<ProprietariosMoradores>(this.resource, entity);
	}

	public put(entity: ProprietariosMoradores): Observable<ProprietariosMoradores> {
		entity.id_condominio = this.authService.getIdCurrentCondominio();
		return this.crudService.put<ProprietariosMoradores>(this.resource, entity);
	}

	public delete(entity: ProprietariosMoradores): Observable<string> {
		return this.crudService.delete<ProprietariosMoradores>(this.resource, entity);
	}

	// ==============================================================================================================
	// Veículos
	// ==============================================================================================================
	/**
	 * Relacionamento de veículos com o condômino
	 * @param id_veiculo 
	 * @param id_condomino 
	 * @author Marcos Frare
	 * @since 16/04/2021
	 */
	public relacionarVeiculoCondomino(id_veiculo: number, id_condomino: number): Observable<any> {
		return this.customHttp.post(`${this.baseUrl}/veiculo`, {id_veiculo, id_condomino}).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Busca os veículos do condômino
	 * @param id_condomino 
	 * @author Marcos Frare
	 * @since 16/04/2021
	 */
	public getVeiculosCondomino(id_condomino: number): Observable<Veiculo[]> {
		return this.customHttp.get(`${this.baseUrl}/veiculo/${id_condomino}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Excluir relacionamento de veículo e condômino
	 * @param id_condomino 
	 * @param id_veiculo 
	 * @author Marcos Frare
	 * @since 16/04/2021
	 */
	public excluirVeiculoCondomino(id_condomino: number, id_veiculo: number): Observable<any> {
		return this.customHttp.delete(`${this.baseUrl}/veiculo/${id_condomino}/${id_veiculo}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	// ==============================================================================================================
	// Contatos
	// ==============================================================================================================

	/**
	 * Gavar contato para o proprietário/morador
	 * @param entity 
	 * @param contato 
	 * @author Marcos Frare
	 * @since 23/04/2021
	 */
	public gravarContato(entity: ProprietariosMoradores, contato: ContatoProprietarioMorador): Observable<any> {
		contato.id_proprietario_morador = entity.id;
		return this.customHttp.post(`${this.baseUrl}/contato`, contato).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Obter os contatos do Proprietário Morador
	 * @param entity 
	 * @author Marcos Frare
	 * @since 23/04/2021
	 */
	public obterContatosMorador(entity: ProprietariosMoradores): Observable<ContatoProprietarioMorador[]> {
		return this.customHttp.get(`${this.baseUrl}/contato/${entity.id}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}

	/**
	 * Excluir um contato
	 * @param id 
	 * @author Marcos Frare
	 * @since 23/04/2021
	 */
	public excluirContatoMorador(id: number): Observable<any> {
		return this.customHttp.delete(`${this.baseUrl}/contato/${id}`).pipe(
			map(response => response.json()),
			catchError(error => _throw(error))
		);
	}
}