import { Injectable } from '@angular/core';
import { ConfigService, Crud, CrudService, CustomHttp } from 'app/core';
import { ModeloVeiculo } from 'app/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModeloVeiculoHttpService implements Crud<ModeloVeiculo> {

  resource: string = 'veiculo/fabricante/modelo';
  private baseUrl: string;
  private url: string;

  constructor(private customHttp: CustomHttp,
      private crudService: CrudService,
      private configService: ConfigService) {
    this.url = configService.getBaseUrl();
    this.baseUrl = `${this.url}/${this.resource}`;
  }

  get(id: number): Observable<ModeloVeiculo> {
    return this.crudService.get<ModeloVeiculo>(this.resource, id);
  }
  getAll(): Observable<ModeloVeiculo[]> {
    return this.crudService.getAll<ModeloVeiculo>(this.resource);
  }
  post(entity: ModeloVeiculo): Observable<ModeloVeiculo> {
    return this.crudService.post<ModeloVeiculo>(this.resource, entity);
  }
  put(entity: ModeloVeiculo): Observable<ModeloVeiculo> {
    return this.crudService.put<ModeloVeiculo>(this.resource, entity);
  }
  delete(entity: ModeloVeiculo): Observable<string> {
    return this.crudService.delete<ModeloVeiculo>(this.resource, entity);
  }
}