import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { MessageService } from 'app/core';
import { DocumentoPasta } from 'app/models';
import { DocumentoPastaHttpService } from './services';
import { Subscription } from 'rxjs';

declare var jQuery: any;

@Component({
  selector: 'documento-pasta-modal',
  templateUrl: './documento-pasta-modal.component.html',
  styleUrls: ['./documento-pasta-modal.component.css']
})
export class DocumentoPastaModalComponent implements OnInit, BaseModal, OnDestroy {

  @Input() idModal: string;
  @Input() id: number;
  @Output() onSave: EventEmitter<DocumentoPasta> = new EventEmitter();

  public entity: DocumentoPasta;
  private subs: Subscription[] = [];

  constructor(private documentoPastaHttpService: DocumentoPastaHttpService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.entity = new DocumentoPasta();
    if (!this.idModal) {
      this.idModal = 'documentoPastaCadastroModal';
    }
  }

  ngOnDestroy(): void {
    try {
      this.subs.map(s => s.unsubscribe());
    } catch(err) {
      console.error(err);
    }
  }

  public open() {
    this.entity = new DocumentoPasta();
    if (this.id) {
      this.documentoPastaHttpService.get(this.id).subscribe(
        response => {
          this.entity = response;
        },
        error => {
          this.messageService.error('Erro ao buscar dados do veículo! Tente novamente', 'Erro', error);
        }
      );
    }
    jQuery(`#${this.idModal}`).modal('show');
  }
  public close() {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Salvar Pasta
   * @author Marcos Frare
   * @since 12/09/2022
   */
   public salvar(): void {
    let btn = jQuery('#btnSavePasta');
    btn.button('loading');
    const fn = this.entity.id ? this.documentoPastaHttpService.put(this.entity) : this.documentoPastaHttpService.post(this.entity);
    this.subs.push(fn.subscribe(
      res => {
        btn.button('reset');
        this.messageService.success('Pasta salva com sucesso!');
        this.onSave.emit(res);
        this.close();
      },
      error => {
        btn.button('reset');
        this.messageService.error('Erro ao salvar pasta! Tente novamente', 'Erro', error);
      }
    ));
  }

}