import { BaseEntity } from '../base-entity';
import { Bloco, Anexo, Condominio, CondominioConfiguracaoReserva, LocalConfiguracao, LocalUtensilio, LocalPolitica } from 'app/models';

export class Local extends BaseEntity {
	id_condominio: number;
	id_bloco: number;
	nome: string;
	tipo: number;
	lotacao: number;
	area: number;
	ativo: boolean;
	exige_reserva: boolean;

	bloco: Bloco;
	condominio: Condominio;
	anexos: Anexo[] = [];
	localConfiguracao: LocalConfiguracao;
	localUtensilios: LocalUtensilio[] = [];
	localPolitica: LocalPolitica;
	condominioConfiguracaoReserva: CondominioConfiguracaoReserva;
	msgCancelamento: string;
	restricao: string;

	selected: boolean;

	constructor() {
		super();
		this.msgCancelamento = '';
		this.restricao = '';
		this.selected = false;
	}
}

export const tiposLocal = [
	{ id: 1, nome: 'Área de Esporte' },
	{ id: 2, nome: 'Área de Festas' },
	{ id: 3, nome: 'Área de Convívio' },
	{ id: 4, nome: 'Área de Alimentação' },
	{ id: 5, nome: 'Lazer' }
];

export const periodoLocal = [
	{ id: 1, nome: 'Matutino' },
	{ id: 2, nome: 'Vespertino' },
	{ id: 3, nome: 'Noturno' },
	{ id: 4, nome: 'Diurno' },
	{ id: 5, nome: 'Todos' },
];