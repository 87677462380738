import { Component, OnInit } from '@angular/core';

import { Ocorrencias, Usuario } from '../../models';
import { MessageService, DataTablesService } from '../../core';
import { OcorrenciasHttpService } from './services';
import { UsuariosHttpService } from '../usuarios';

@Component({
    selector: 'ocorrencias',
    templateUrl: 'ocorrencias.template.html',
    providers: [
        UsuariosHttpService
    ]
})
export class OcorrenciasComponent implements OnInit {
    entities: Ocorrencias[];
    usuarios: Usuario[];

    constructor(private ocorrenciasHttpService: OcorrenciasHttpService,
                private usuariosHttpService: UsuariosHttpService,
                private messageService: MessageService,
                private dataTablesService: DataTablesService) { }

    ngOnInit() {
        this.getAll();
        this.getAllUsuarios();
    }

    private showTable() {
        setTimeout(() =>
            this.dataTablesService.showTable('#tabelOcorrencias', {
                columnDefs: [
                    { orderable: false, targets: -1, width: '40px' }
                ],
                language: { 'url': './assets/scripts/datatables-ptbr.json' }
                })
        , 200);
    }

    /** Carrega todas as ocorrências */
    getAll() {
        this.ocorrenciasHttpService.getAll()
            .subscribe(
                entities => {
                    this.entities = entities;
                    this.showTable();
                },
                error => this.messageService.error('Erro ao carregar as ocorrências. Tente novamente', 'Erro', error)
            );
    }


    /** Carrega todos os usuários */
    getAllUsuarios() {
        this.usuariosHttpService.getAll()
        .subscribe(
            entities => this.usuarios = entities,
            error => this.messageService.error('Erro ao carregar os usuários. Tente novamente', 'Erro', error)
        );
    }

    getUsuario(id) {
        return this.usuarios.find(x => x.id === id).nome;
    }
}
