import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared';
import { FabricanteVeiculoCadastroModalComponent, FabricanteVeiculoComponent, ModeloVeiculoCadastroModalComponent, ModeloVeiculoComponent } from './fabricante-veiculo';
import { TipoVeiculoCadastroModalComponent, TipoVeiculoComponent } from './tipo-veiculo';
import { VeiculoCadastroModalComponent } from './veiculo-cadastro-modal';
import { VeiculoComponent } from './veiculo.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    VeiculoComponent,
    VeiculoCadastroModalComponent,
    ModeloVeiculoComponent,
    ModeloVeiculoCadastroModalComponent,
    TipoVeiculoComponent,
    TipoVeiculoCadastroModalComponent,
    FabricanteVeiculoComponent,
    FabricanteVeiculoCadastroModalComponent
  ],
  exports: [
    VeiculoCadastroModalComponent,
    ModeloVeiculoCadastroModalComponent,
    FabricanteVeiculoCadastroModalComponent,
    TipoVeiculoCadastroModalComponent
  ]
})
export class VeiculoModule { }