import { Component, OnInit, Input } from '@angular/core';
import { cssResumo } from './email-resumo.css';
import { BaseModal } from 'app/componentes-utils/modal';

declare var jQuery: any;

@Component({
  selector: 'envio-email-boleto-modal',
  templateUrl: './envio-email-boleto-modal.component.html',
  styleUrls: ['./envio-email-boleto-modal.component.css']
})
export class EnvioEmailBoletoModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @Input() objetoEmail: any[];

  constructor() { }

  ngOnInit() {
    if (!this.idModal) {
      this.idModal = 'envioEmailBoletoModal';
    };
  }

  /**
   * Abrir modal
   * @author Marcos Frare
   * @since 27/08/2020
   */
  public open(): void {
    jQuery(`#${this.idModal}`).modal('show');
  }

  public close(): void {
    jQuery(`#${this.idModal}`).modal('hide');
  }

  private loadContent() {
    let content = '<table class="table table-striped table-bordered"><thead><tr style="padding-top: 0;"><td>Bloco</td><td>Unidade</td><td>Email</td><td class="text-center">Status</td></tr></thead>';
    
    for (let item of this.objetoEmail) {
      for (let destinatario of item.destinatarios) {
        let small = '';
        switch (destinatario.status) {
          case 1: {
            small = '<small class="label label-primary">Enviado</small>';
            break;
          }
          case 3: {
            small = '<small class="label label-warning">Invalido</small>';
            break;
          }
          case 4: {
            small = '<small class="label label-warning">Não enviado</small>';
            break;
          }
          default: {
            small = '<small class="label label-warning">Não enviado</small>';
            break;
          }
        }
        content += `<tr><td>${item.unidade.nome_bloco}</td><td>${item.unidade.nome_unidade}</td><td>${destinatario.email}</td><td class="text-center">${small}</td></tr>`;
      }
    }

    return content+='</tbody></table>';
  }

  /**
   * Imprimir retorno de envio de e-mails
   * @author Marcos Frare
   * @since 27/08/2020
   */
  public printResumo(): boolean {
    let printContents = this.loadContent();
    const printableWindow = window.open('', 'PRINT', 'height=400,width=600');
    printableWindow.document.write(`<html>
                                        <head>
                                            <title>Resumo do processo de envio de e-mails</title>
                                            <style>${cssResumo}</style>
                                        </head>
                                        <body>${printContents}</body>
                                    </html>`);

    printableWindow.document.close();
    printableWindow.focus();
    printableWindow.print();
    printableWindow.close();

    return true;
  }

}