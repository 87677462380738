import { BaseEntity } from '../base-entity';
import { Condomino } from './condomino';
import { ContatoProprietarioMorador } from './contato-proprietario-morador';
import { Usuario } from './usuario';
import { Veiculo } from './veiculo';

export class ProprietariosMoradores extends BaseEntity {
	id: number;
	id_unidade: number;
	id_proprietario_morador: number;
	tipo: number;
	data_entrada: Date;
	data_saida: Date;
	grau_parentesco: number;
	morador: Usuario;
	moradorNotFound: boolean;
	id_condominio: number;
	id_condomino: number;
	enviar_email: boolean;
	relacao: string;

	show_veiculos: boolean;
	veiculos: Veiculo[];
	show_contatos: boolean;
	contatos: ContatoProprietarioMorador[];

	condomino: Condomino;

	constructor() {
		super();
		this.enviar_email = false;
		this.show_veiculos = false;
		this.condomino = new Condomino();
		this.veiculos = [];
		this.contatos = [];
	}
}

export const tipos = [
	{ id: 1, codigo: 1, nome: 'Morador' },
	{ id: 2, codigo: 2, nome: 'Proprietário' },
	{ id: 3, codigo: 3, nome: 'Imobiliária' },
	{ id: 4, codigo: 4, nome: 'Inquilino' }
];

export const grausParentesco = [
	{ codigo: 1, nome: 'Esposo(a)' },
	{ codigo: 2, nome: 'Filho(a)' },
	{ codigo: 4, nome: 'Mãe' },
	{ codigo: 3, nome: 'Pai' },
	{ codigo: 5, nome: 'Primo(a)' },
	{ codigo: 6, nome: 'Sogro(a)' },
	{ codigo: 7, nome: 'Outros' },
	{ codigo: 8, nome: 'Nenhum' }
];