import { Injectable } from '@angular/core';
import { AuthService, ConfigService, CrudService, CustomHttp } from 'app/core';
import { TemplateLancamentoEspecifico } from 'app/models/entidades/template-lancamento-especifico';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs-compat/observable/throw';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TemplateLancamentoEspecificoHttpService {

  private resource = 'lancamentosespecificos/template';
  private baseUrl: string;

  constructor(private customHttp: CustomHttp,
    private crudService: CrudService,
    private configService: ConfigService,
    private authService: AuthService) {
    this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
  }

  /**
   * Busca as templates de lançamentos do condomínio
   * @author Marcos Frare
   * @since 09/03/2021
   */
  public buscaTemplatesCondominio(id_condominio: number): Observable<TemplateLancamentoEspecifico[]> {
    return this.customHttp.get(`${this.baseUrl}`, { params: { id_condominio } }).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
  }

  /**
   * Busca as templates de lançamentos do condomínio
   * @author Marcos Frare
   * @since 09/03/2021
   */
  public buscaTemplateId(id: number): Observable<TemplateLancamentoEspecifico> {
    return this.customHttp.get(`${this.baseUrl}/${id}`).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
  }

  /**
   * Persistir os dados de uma template de lançamento
   * @param template 
   * @author Marcos Frare
   * @since 09/03/2021
   */
  public saveTemplate(template: TemplateLancamentoEspecifico): Observable<TemplateLancamentoEspecifico> {
    return this.customHttp.post(`${this.baseUrl}`, template).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
  }

  /**
   * Exclusão de uma template
   * @author Marcos Frare
   * @since 09/03/2021
   */
  public deleteTemplate(id: number): Observable<TemplateLancamentoEspecifico> {
    return this.customHttp.delete(`${this.baseUrl}/${id}`).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
  }

  /**
   * Buscar templates de lançamento conforme competência informada
   * @param id_condominio 
   * @param competencia 
   * @author Marcos Frare
   * @since 10/03/2021
   */
  public loadTemplatesCompetencia(id_condominio: number, competencia: Date): Observable<TemplateLancamentoEspecifico[]> {
    return this.customHttp.get(`${this.baseUrl}/lancamento/competencia`, {params: {id_condominio, competencia}}).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
  }

  /**
   * Salvar os lançamentos
   * @param templates 
   * @author Marcos Frare
   * @since 10/03/2021
   */
  public salvarLancamentosTemplate(id_condominio: number, templates: TemplateLancamentoEspecifico[]): Observable<any> {
    return this.customHttp.post(`${this.baseUrl}/lancamento`, {id_condominio, templates}).pipe(
      map(response => response.json()),
      catchError(error => _throw(error))
    );
  }

}