import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'app/core';
import { PermissionService } from 'app/core/services/permission/permission.service';
import { LancamentosUnidadesPagamentosConsultaModalComponent } from 'app/home/lancamentos-unidades';
import { CobrancaAvulsa, PagamentoUnidade } from 'app/models';
import { NgxSpinnerService } from 'ngx-spinner';
import { CobrancaAvulsaHttpService } from '../service';
import { CobrancaAvulsaFaturaModalComponent, GeracaoCobrancaAvulsa } from './cobranca-avulsa-fatura-modal';

declare var jQuery: any;

@Component({
  selector: 'cobranca-avulsa-cadastro',
  templateUrl: './cobranca-avulsa-cadastro.component.html',
  styleUrls: ['./cobranca-avulsa-cadastro.component.css']
})
export class CobrancaAvulsaCadastroComponent implements OnInit {

  @ViewChild('cobrancaAvulsaFaturaModal') cobrancaAvulsaFaturaModal: CobrancaAvulsaFaturaModalComponent;
  @ViewChild('LancamentoUnidadePagamentoPesquisaModal') LancamentoUnidadePagamentoPesquisaModal: LancamentosUnidadesPagamentosConsultaModalComponent;

  public cobrancas: CobrancaAvulsa[];
  public dataVencimentoPadrao: Date;

  constructor(public activatedRoute: ActivatedRoute,
      public router: Router,
      public messageService: MessageService,
      private cobrancaAvulsaHttpService: CobrancaAvulsaHttpService,
      public permissionService: PermissionService,
      public spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.cobrancas = [];
    this.dataVencimentoPadrao = new Date();
  }

  /**
   * Voltar
   * @author Marcos Frare
   * @since 26/07/2021
   */
  public cancel(): void {
    this.router.navigate(['../'], { relativeTo: this.activatedRoute });
  }

  /**
   * Adicionar itens para cobrança avulsa
   * @author Marcos Frare
   * @since 26/07/2021
   */
  public addItem(): void {
    this.cobrancaAvulsaFaturaModal.open();
  }

  /**
   * Gerar faturas das unidades para cobrança avulsa
   * @param faturas 
   * @author Marcos Frare
   * @since 26/07/2021
   */
  public gerarFaturas(geracaoCobranca: GeracaoCobrancaAvulsa): void {
    this.spinner.show();
    this.cobrancaAvulsaHttpService.loadCobrancas(this.cobrancas, geracaoCobranca).subscribe(
      response => {
        this.spinner.hide();
        this.cobrancas = response;
      },
      error => {
        this.spinner.hide();
        this.messageService.error('Erro ao montar cobranças! Tente novamente.', 'Erro!', error);
      }
    );
  }

  /**
   * Evento de alteração de data padrão das cobranças
   * @author Marcos Frare
   * @since 27/07/2021
   */
  public changeDataPadrao(): void {
    this.cobrancas.map(cob => {
      cob.data_vencimento = this.dataVencimentoPadrao;
    });
  }

  /**
   * Excluir um lançamento de uma unidade
   * @param id_unidade 
   * @param index 
   * @author Marcos Frare
   * @since 27/07/2021
   */
  public deleteLancamento(id_unidade, index): void {
    let fCobranca = this.cobrancas.filter(cob => cob.id_unidade == id_unidade);
    if (fCobranca.length) {
      fCobranca[0].lancamentosUnidade.splice(index, 1);
      // Soma total da cobrança
      fCobranca[0].valor = 0;
      for (let lc of fCobranca[0].lancamentosUnidade) {
        fCobranca[0].valor += lc.valor_total;
      }
      if (fCobranca[0].valor == 0) {
        this.cobrancas = this.cobrancas.filter(cob => cob.id_unidade != id_unidade);
      }
    }
  }

  /**
   * Validações para gravação de cobranças
   * @author Marcos Frare
   * @returns Boolean
   * @since 28/07/2021
   */
  private validate(): boolean {
    let validate = true;
    let msg = '';
    for (let cobranca of this.cobrancas) {
      if (!cobranca.data_vencimento) {
        validate = false;
        msg += (msg != '' ? '<br>' : '') + `&raquo; Cobrança para sacado ${cobranca.sacado.nome} sem data de vencimento!`;
      }
    }
    if (msg) {
      this.messageService.warning(msg, 'Atenção!');
    }
    return validate;
  }

  /**
   * Salvar cobranças
   * @author Marcos Frare
   * @since 27/07/2021
   */
  public save(): void {
    let btnSave = jQuery("#btnSave");
    btnSave.button('loading');
    if (this.validate()) {
      this.spinner.show();
      this.cobrancaAvulsaHttpService.saveCobrancas(this.cobrancas).subscribe(
        () => {
          btnSave.button('reset');
          this.spinner.hide();
          this.messageService.success('Cobranças geradas com sucesso!');
          this.cancel();
        },
        error => {
          btnSave.button('reset');
          this.spinner.hide();
          this.messageService.error('Erro ao gravar cobrnças! Tente novamente.', 'Erro', error);
        }
      );
    } else {
      btnSave.button('reset');
    }
  }

  /**
   * Selecionar lançamentos das unidades
   * @autor Marcos Frare
   * @since 16/08/2021
   */
  public selectItem(): void {
    this.LancamentoUnidadePagamentoPesquisaModal.open();
  }

  /**
   * Seleção dos pagamentos das unidades
   * @param arPagamentoUnidade 
   * @author Marcos Frare
   * @since 16/08/2021
   */
  public onSelectedLancamentos(arPagamentoUnidade: PagamentoUnidade[]): void {
    this.spinner.show();
    this.cobrancaAvulsaHttpService.loadCobrancasLancamentos(this.cobrancas, arPagamentoUnidade).subscribe(
      response => {
        this.spinner.hide();
        this.cobrancas = response;
      },
      error => {
        this.spinner.hide();
        this.messageService.error('Erro ao montar cobranças! Tente novamente.', 'Erro!', error);
      }
    );
  }
  
}