import { Component, OnInit } from '@angular/core';

import { BaseModal } from '../../../../../componentes-utils/modal';
import { HistoricoUnidade } from '../../../../../models';
import { UnidadesHistoricoHttpService } from '../services/unidades-historico-http.service';
import { MessageService } from '../../../../../core';
import { UsuariosHttpService } from '../../../../usuarios';

declare var jQuery: any;

@Component({
    selector: 'unidades-historico-modal',
    templateUrl: './unidades-historico-modal.component.html',
    styleUrls: ['./unidades-historico-modal.component.css'],
    providers: [
        UnidadesHistoricoHttpService
    ]
})
export class UnidadesHistoricoModalComponent implements BaseModal, OnInit {

    entities: HistoricoUnidade[];

    constructor(private unidadesHistoricoHttpService: UnidadesHistoricoHttpService,
                private messageService: MessageService,
                private usuariosHttpService: UsuariosHttpService) { }


    ngOnInit() {
        this.entities = [];
    }


    // Seta o objecto "usuario" com o usuário carregado
    private setUsuarioConselho(entity: HistoricoUnidade) {
        if (!entity || !entity.id_usuario_historico) {
            return;
        }
        this.usuariosHttpService.get(entity.id_usuario_historico)
            .subscribe(
                usuario => entity.usuarioHistorico = usuario,
                error => this.messageService.error('Erro ao carregar o usuário. Tente novamente', 'Erro')
            );
    }

    open(idUnidade?: number) {
        this.entities = [];
        this.unidadesHistoricoHttpService.getAll(idUnidade)
            .subscribe(
                entities => {
                    entities.forEach(entity => {
                        this.setUsuarioConselho(entity);
                        this.entities.push(entity);
                    });

                    jQuery('#unidadesHistoricoModal').modal('show');
                },
                error => this.messageService.error('Erro ao carregar o histório da unidade. Tente novamente', 'Erro', error)
            );
    }

    close() {
        jQuery('#unidadesHistoricoModal').modal('hide');
    }
}
