import { map, catchError } from 'rxjs/operators';

import {throwError as observableThrowError,  Observable ,  forkJoin } from 'rxjs';
import { Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { BaseModal } from '../modal';
import { Notas } from '../../models';
import { MessageService } from '../../core';
import { NotasHttpService } from './services';

declare var jQuery: any;

export class BaseNotasModal implements BaseModal {
    @Input() idModal: string;

    @ViewChild('formNotasModal') form: NgForm;

    /** id do "dono" da nota (lançamento, pagamento...) */
    idOwner: number;
    title: string;
    btnSave: any;
    entities: Notas[];

    constructor(protected notasHttpService: NotasHttpService,
                protected messageService: MessageService) {
        this.title = 'Notas';
    }

    open(id?: number) {
        this.idOwner = id;

        this.getAll(id)
            .then(entities => {
                this.entities = entities;
                if (this.entities.length === 0) {
                    this.insert();
                }

                jQuery(`#${this.idModal}`).modal('show');
                setTimeout(() => {
                    jQuery('.modal-body').find('input:first').focus();
                }, 200);
            })
            .catch(error => {});
    }

    close() {
        jQuery(`#${this.idModal}`).modal('hide');
    }

    getAll(id: number): Promise<Notas[]> {
        return new Promise<Notas[]>((resolve, reject) => {
            resolve([]);
        });
    }

    insert() { }

    onChange(nota: Notas) {
        nota.editado = true;
    }

    /**
     * Torna todos os campos do formulário "sujos" para exibir as mensagens de campos inválidos
     */
    private makeAllFieldsDirty() {
        for (const key in this.form.controls) {
            if (this.form.controls.hasOwnProperty(key)) {
                const control = this.form.controls[key];
                control.markAsDirty();
            }
        }
    }

    // Cria os observables para salvar as notas alteradas simultaneamente
    private createSave(): Observable<Notas>[] {
        let list: Observable<Notas>[] = [];
        for (let entity of this.entities) {
            if (entity.editado) {
                let observable: Observable<Notas>;
                if (entity.id) {
                    observable = this.notasHttpService.put(entity)
                                    .pipe(
                                        map(response => response),
                                        catchError(error => {
                                            this.messageService.error(`Erro ao salvar a nota de número ${entity.numero}. Tente novamente`, 'Erro', error);
                                            return Observable.throw(error);
                                        })
                                    );
                } else {
                    observable = this.notasHttpService.post(entity)
                                    .pipe(
                                        map(response => {
                                            entity.id = response.id;
                                            return response;
                                        }),
                                        catchError(error => {
                                            this.messageService.error(`Erro ao salvar a nota de número ${entity.numero}. Tente novamente`, 'Erro', error);
                                            return Observable.throw(error);
                                        })
                                    );
                }
                list.push(observable);
            }
        }
        return list;
    }

    save() {
        const saves = this.createSave();
        if (saves.length > 0) {
            this.makeAllFieldsDirty();

            if (this.form.valid) {
                this.btnSave.button('loading');
                forkJoin(saves)
                        .subscribe(
                            () => {
                                this.btnSave.button('reset');
                                this.messageService.success('', 'Registros salvos com sucesso');
                                this.close();
                            },
                            error => {
                                this.btnSave.button('reset');
                            }
                        );
            } else {
                this.messageService.info('Para salvar é preciso informar corretamente todos os campos destacados em vermelho', 'Informação');
            }
        } else {
            this.messageService.info('É necessário alterar alguma nota para salvar', 'Informação');
        }
    }

    delete(entity: Notas) {
        const numeroNota = entity.numero ? entity.numero.toString() : '';
        if (confirm(`Tem certeza que deseja excluir a nota ${numeroNota}?`)) {
            if (entity.id) { // Com id deleta do banco de dados
                this.notasHttpService.delete(entity)
                    .subscribe(
                        () => {
                            const index = this.entities.findIndex(nota => nota.id === entity.id);
                            this.deleteOfList(index);
                            this.messageService.success('', `A nota ${numeroNota} foi excluida com sucesso`);
                        },
                        error => this.messageService.error(`Erro ao exlcuir a nota ${numeroNota}. Tente novamente`, 'Erro', error)
                    );
            } else { // Sem id deleta em memória
                const index = this.entities.indexOf(entity);
                if (this.deleteOfList(index)) {
                    this.messageService.success('', `A nota ${numeroNota} foi excluida com sucesso`);
                }
            }
        }
    }

    // Deleta a nota da lista de notas em memória
    private deleteOfList(index: number): boolean {
        if (index > -1) {
            this.entities.splice(index, 1);
            return true;
        }
        return false;
    }
}
