import { BaseEntity } from '../base-entity';

export class ConfiguracaoEmailCliente extends BaseEntity {
	id_cliente: number;
	host: string;
	nome: string;
	username: string;
	password: string;
	replyto: string;
	replytoname: string;
	security: string;
	port: number;

	constructor() {
		super();
		this.security = 'TLS';
	}
}