import { Bloco, Unidade } from "app/models";

export class AntecipacaoRelFiltro {
	id_cliente: number;
	id_condominio: number;
	id_usuario: number;
	data_inicial: Date;
	data_final: Date;
	competencia_inicial: Date;
	competencia_final: Date;
	id_bloco: number;
	id_unidade: number;
	tipoFiltro: number;

	bloco: Bloco;
	unidade: Unidade;

	limpar() {
		const today = new Date();
		this.data_inicial = new Date(today.getFullYear(), today.getMonth(), 1); // primeiro dia do mês corrente;
		this.data_final = new Date(today.getFullYear(), today.getMonth() + 1, 0); // último dia do mês corrente;
		this.competencia_inicial = new Date(today.getFullYear(), today.getMonth(), 1); // primeiro dia do mês corrente;
		this.competencia_final = null;
		this.id_bloco = null;
		this.id_unidade = null;
		this.tipoFiltro = 1;
		this.bloco = new Bloco();
		this.unidade = new Unidade();
	}
}