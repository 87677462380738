import { Component, Input, OnInit } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { MessageService } from 'app/core';
import { ConsumoUnidadeHistorico } from 'app/models';
import { ConsumosUnidadesHttpService } from '../services';

declare var jQuery: any;

@Component({
  selector: 'consumo-unidade-historico-modal',
  templateUrl: './consumo-unidade-historico-modal.component.html',
  styleUrls: ['./consumo-unidade-historico-modal.component.css']
})
export class ConsumoUnidadeHistoricoModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @Input() idConsumoUnidade: number;

  public arHistorico: ConsumoUnidadeHistorico[];

  constructor(private messageService: MessageService,
    private consumoUnidadeHttpService: ConsumosUnidadesHttpService) { }

  ngOnInit() {
    if (!this.idModal) {
			this.idModal = 'consumoUnidadeHistoricoModal';
		}
  }

  /**
   * Carrega histórico de alterações
   * @author Marcos Frare
   * @since 02/11/2021
   */
  private getHistorico(): void {
    this.arHistorico = [];
    if (this.idConsumoUnidade) {
      this.consumoUnidadeHttpService.getHistory(this.idConsumoUnidade).subscribe(
        response => {
          this.arHistorico = response;
        },
        error => {
          this.messageService.error('Erro ao buscar histórico de alterações!', 'Erro', error);
        }
      );
    }
  }

  open() {
    this.getHistorico();
		jQuery(`#${this.idModal}`).modal('show');
	}

	close() {
		jQuery(`#${this.idModal}`).modal('hide');
	}

}