import { BaseEntity } from '../base-entity';
import { Anexo } from './anexo';
import { Notificacao } from './notificacao';

export class EventoNotificacao extends BaseEntity {
	id: number;
	id_condominio: number;
	id_formulario: number;
	tipo: number;
	forma: boolean;
	Notificacoes: Notificacao[];
	created_at: Date;
	diasVencido: number;
	id_bloco: number;
	id_unidade: number;
	dias_manifestacao: number;
	contato_sindico: boolean;
	contato_administradora: boolean;
	email_copia_administradora: boolean;
	data_ocorrencia: Date;
	mensagem: string;

	anexos: Anexo[];

	constructor() {
		super();
		this.dias_manifestacao = 1;
		this.contato_sindico = true;
		this.contato_administradora = true;
		this.email_copia_administradora = false;
		this.data_ocorrencia = new Date();
		this.anexos = [];
	}
}

export const tiposNotificacao = [
	{ id: 1, nome: 'Aviso de débitos' },
	{ id: 2, nome: 'Notificação extra juducial' },
	{ id: 3, nome: 'Infração' },
	{ id: 4, nome: 'Aviso de vencimento' }
];

export const formasNotificacao = [
	{ id: 1, nome: 'Automático' },
	{ id: 2, nome: 'Manual' }
];