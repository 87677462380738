import { Component, EventEmitter, OnInit, Input, ViewChild, Output } from '@angular/core';
import { BaseModal } from 'app/componentes-utils/modal';
import { NgForm } from '@angular/forms';
import { FornecedoresCadastroModalComponent, FornecedoresHttpService } from 'app/home/fornecedores';
import { MessageService, AuthService } from 'app/core';
import { Fornecedor, CategoriaContas, tiposDivisaoRateio, TemplateLancamentoCondominio, tiposUnidade, templateUtilidades, TemplateLancamentoCondominioItem, Unidade } from 'app/models';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { IMultiSelectSettings, IMultiSelectOption, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import { UnidadesHttpService } from 'app/home/blocos';
import { CategoriaContasHttpService, CategoriaContasPesquisaModalComponent, PlanoDeContasCadastroModalComponent } from 'app/home/plano-de-contas';
import { LancamentosCondominioHttpService } from '../../services';
import { t } from '@angular/core/src/render3';

declare var jQuery: any;

@Component({
  selector: 'template-lancamento-condominio-cadastro-modal',
  templateUrl: './template-lancamento-condominio-cadastro-modal.component.html',
  styleUrls: ['./template-lancamento-condominio-cadastro-modal.component.css']
})
export class TemplateLancamentoCondominioCadastroModalComponent implements OnInit, BaseModal {

  @Input() idModal: string;
  @Input() id: number;
  @ViewChild('form') form: NgForm;
  @Output() onSave: EventEmitter<TemplateLancamentoCondominio> = new EventEmitter();

  @ViewChild('categoriaContasPesquisaModal') categoriaContasPesquisaModal: CategoriaContasPesquisaModalComponent;
  @ViewChild('planoDeContasCadastroModal') planoDeContasCadastroModal: PlanoDeContasCadastroModalComponent;
  @ViewChild('categoriaContasPesquisaNaoRateadoModal') categoriaContasPesquisaNaoRateadoModal: CategoriaContasPesquisaModalComponent;
  @ViewChild('planoDeContasNaoRateadoCadastroModal') planoDeContasNaoRateadoCadastroModal: PlanoDeContasCadastroModalComponent;
  @ViewChild('fornecedoresCadastroModal') fornecedoresCadastroModal: FornecedoresCadastroModalComponent;

  public idCliente: number;
  public idCondominio: number;
  public fornecedores: Fornecedor[] = [];
  public categoriasContasReceita: CategoriaContas[] = [];
  public categoriasContasDespesa: CategoriaContas[] = [];
  public tiposDivisaoRateio = tiposDivisaoRateio;
  public tiposUnidade = tiposUnidade;
  public utilidades = templateUtilidades;
  public tipoDivisaoRateioDefault: number;
  public unidades: Unidade[] = [];

  public multiselectSettings: IMultiSelectSettings = {
    enableSearch: true,
    containerClasses: 'container-multiselect',
    showUncheckAll: true,
    showCheckAll: true,
    dynamicTitleMaxItems: 3,
    checkedStyle: 'checkboxes',
    buttonClasses: 'btn btn-default',
    itemClasses: 'dropdown-menu'
  };
  public multiselectOptionsUnidades: IMultiSelectOption[];
  public multiselectTextsUnidades: IMultiSelectTexts = {
    checkAll: 'Selecionar todas',
    uncheckAll: 'Limpar',
    checked: 'selecionada',
    checkedPlural: 'selecionadas',
    searchPlaceholder: 'Pesquisar...',
    defaultTitle: 'Selecionar',
    allSelected: 'Todas selecionadas',
    searchEmptyResult: 'Nenhum registro encontrado',
    searchNoRenderText: 'Digite para pesquisar',
  };

  public templateLancamentoCondominio: TemplateLancamentoCondominio;
  private currentItem: TemplateLancamentoCondominioItem = null;

  constructor(private messageService: MessageService,
      private lancamentosCondominioHttpService: LancamentosCondominioHttpService,
      private authService: AuthService,
      private fornecedoresHttpService: FornecedoresHttpService,
      private categoriaContasHttpService: CategoriaContasHttpService,
      private unidadesHttpService: UnidadesHttpService) {
    this.idCliente = this.authService.getIdCurrentCliente();
  }

  ngOnInit() {
    this.getAllUnidades();
    this.idCondominio = this.authService.getIdCurrentCondominio();
    this.templateLancamentoCondominio = new TemplateLancamentoCondominio();
    if (this.idModal == '') {
      this.idModal = 'templateLancamentoCondominioCadastro';
    }
  }

  /**
	 * Carregar todas as unidades do condomínio
	 * @author Marcos Frare
	 * @since 23/08/2021
	 */
	private getAllUnidades():void {
		this.unidades = [];
		this.unidadesHttpService.getAllByCondominioBloco(this.authService.getIdCurrentCondominio(), null).subscribe(
			response => {
				this.unidades = response;
				this.templateLancamentoCondominio.templateLancamentoCondominioItems.map(item => {
					this.getUnidadesByTipo(item);
				});
			},
			error => {
				this.messageService.error('Erro ao carregar as unidades do condomínio! Tente novamente.', 'Erro!', error);
			}
		);
	}

  /**
   * Validação do dia do vencimento
   * @author Marcos Frare
   * @since 20/07/2021
   */
  public validDiaVencimento(): void {
    if (this.templateLancamentoCondominio.dia_vencimento < 1) {
      this.templateLancamentoCondominio.dia_vencimento = 1;
      this.messageService.warning('Dia do vencimento não pode ser menor que 1!', 'Atenção');
    }
    if (this.templateLancamentoCondominio.dia_vencimento > 30) {
      this.templateLancamentoCondominio.dia_vencimento = 30;
      this.messageService.warning('Dia do vencimento não pode ser maior que 30!', 'Atenção');
    }
  }

  /**
	 * Carregar as opções de unidades pelo tipo
	 * @author Marcos Frare
	 * @since 07/09/2021
	 */
	public getUnidadesByTipo(item: TemplateLancamentoCondominioItem): void {
		item.multiselectOptionsUnidades = (item.cobrar_tipo_unidade != 100 ? this.unidades.filter(un => un.tipo == item.cobrar_tipo_unidade) : this.unidades).map(uni => {
			return {
				id: uni.id,
				name: `${uni.nome_bloco} - ${uni.nome_unidade}`
			};
		});
	}

  /**
   * Busca os fornecedores do Cliente
   * @author Marcos Frare
   * @since 01/02/2021
   */
  private getAllFornecedores(): void {
    this.fornecedoresHttpService.getAllByCliente(this.idCliente).subscribe(
      entities => this.fornecedores = entities,
      error => this.messageService.error('Erro ao carregar os fornecedores', 'Erro', error)
    );
  }

  /**
   * Busca as categorias de contas do condomínio
   * @param idCondominio 
   * @author Marcos Frare
   * @since 10/07/2020
   */
  private getAllCategoriasByCondominio(idCondominio: number): void {
    this.categoriasContasReceita = [];
    this.categoriasContasDespesa = [];
    if (idCondominio) {
      this.categoriaContasHttpService.getAllByCondominio(idCondominio).then(entities => {
        this.categoriasContasReceita = entities.filter(conta => conta.tipo_conta == 1);
        this.categoriasContasDespesa = entities.filter(conta => conta.tipo_conta == 2);
      })
      .catch(() => this.messageService.warning(`Configuração do condomínio não encontrada! Configure o condomínio para prosseguir.`, 'Atenção!'));
    }
  }

  public onSelectCategoriaContas(tm: TypeaheadMatch, item: TemplateLancamentoCondominioItem): void {
    const categoriaContas: CategoriaContas = tm.item as CategoriaContas;
    if (categoriaContas) {
        item.id_categoria_conta = categoriaContas.id;
        item.categoriaConta = categoriaContas;
        item.tipo_divisao_rateio = categoriaContas.tipo_divisao_rateio || this.tipoDivisaoRateioDefault;
        // Sugestão de fornecedor apenas na criação
        if (!this.templateLancamentoCondominio.id && categoriaContas.id_fornecedor) {
          this.templateLancamentoCondominio.id_fornecedor = categoriaContas.id_fornecedor;
        }
    } else {
      item.id_categoria_conta = null;
      item.categoriaConta = null;
    }
  }

  public onCleanCategoriaContas(input: any, item: TemplateLancamentoCondominioItem): void {
    item.id_categoria_conta = null;
    item.categoriaConta = null;

    input.value = '';
    input.focus();
  }

  public openCategoriaContasPequisaModal(item: TemplateLancamentoCondominioItem): void {
    this.currentItem = item;
    this.categoriaContasPesquisaModal.open();
  }

  public onSelectCategoriaContasPesquisaModal(categoriaContas: CategoriaContas): void {
    if (categoriaContas && this.currentItem) {
      this.currentItem.id_categoria_conta = categoriaContas.id;
      this.currentItem.categoriaConta = categoriaContas;
      this.currentItem.tipo_divisao_rateio = categoriaContas.tipo_divisao_rateio || this.tipoDivisaoRateioDefault;
      // Sugestão de fornecedor apenas na criação
      if (!this.templateLancamentoCondominio.id && categoriaContas.id_fornecedor) {
        this.templateLancamentoCondominio.id_fornecedor = categoriaContas.id_fornecedor;
      }
    }
    jQuery('#categoriaContas').focus();
  }

  public openPlanoDeContasCadastroModal(): void {
    this.planoDeContasCadastroModal.open();
  }

  public createFornecedor(): void {
    this.fornecedoresCadastroModal.open();
  }

  public onAfterSaveFornecedor(fornecedor: Fornecedor): void {
    this.templateLancamentoCondominio.id_fornecedor = fornecedor.id;
    this.fornecedores.push(fornecedor);
    jQuery('#fornecedor').focus();
  }

  public onSelectCategoriaContasNaoRateado(tm: TypeaheadMatch, item: TemplateLancamentoCondominioItem): void {
    const categoriaContas: CategoriaContas = tm.item as CategoriaContas;
    if (categoriaContas) {
      item.id_categoria_conta_nao_rateado = categoriaContas.id;
      item.categoriaContaNaoRateado = categoriaContas;
    } else {
      item.id_categoria_conta_nao_rateado = null;
      item.categoriaContaNaoRateado = null;
    }
  }

  public onCleanCategoriaContasNaoRateado(input: any, item: TemplateLancamentoCondominioItem): void {
    item.id_categoria_conta_nao_rateado = null;
    item.categoriaContaNaoRateado = null;

    input.value = '';
    input.focus();
  }

  public openCategoriaContasNaoRateadoPequisaModal(item: TemplateLancamentoCondominioItem): void {
    this.currentItem = item;
    this.categoriaContasPesquisaNaoRateadoModal.open();
  }

  public onSelectCategoriaContasNaoRateadoPesquisaModal(categoriaContasNaoRateado: CategoriaContas): void {
    if (categoriaContasNaoRateado && this.currentItem) {
      this.currentItem.id_categoria_conta_nao_rateado = categoriaContasNaoRateado.id;
      this.currentItem.categoriaContaNaoRateado = categoriaContasNaoRateado;
    }
    jQuery('#categoriaContasNaoRateado').focus();
  }

  /**
   * Abre modal com dados
   * @author Marcos Frare
   * @since 10/07/2020
   */
  open() {
    this.templateLancamentoCondominio = new TemplateLancamentoCondominio();
    this.getAllFornecedores();
    this.getAllCategoriasByCondominio(this.idCondominio);
    if (this.id) {
      this.lancamentosCondominioHttpService.buscaTemplateId(this.id).subscribe(
        response => {
          this.templateLancamentoCondominio = response;
          this.templateLancamentoCondominio.templateLancamentoCondominioItems.map(item => {
            this.getUnidadesByTipo(item);
          });
        },
        error => this.messageService.error('Erro ao carregar configurações de lançamento!', 'Erro', error)
      );
    } else {
      this.templateLancamentoCondominio.templateLancamentoCondominioItems.push(new TemplateLancamentoCondominioItem());
    }
    jQuery(`#${this.idModal}`).modal('show');
  }

  close() {
    this.templateLancamentoCondominio = new TemplateLancamentoCondominio();
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Persistir dados de configuração
   * @author Marcos Frare
   * @since 10/07/2020
   */
  public salvar(): void {
    let btnSave = jQuery('#btnSalvarConfiguracao');
    btnSave.button('loading');
    this.templateLancamentoCondominio.id_condominio = this.idCondominio;
    this.lancamentosCondominioHttpService.saveTemplate(this.templateLancamentoCondominio).subscribe(
      () => {
        btnSave.button('reset');
        this.messageService.info('Configurações gravadas com sucesso!');
        this.onSave.emit();
        this.close();
      },
      error => {
        btnSave.button('reset');
        this.messageService.error('Problemas ao gravar configurações. Tente novamente.', 'Erro', error);
      }
    );
  }

  /**
   * Incluir itens de lançamento
   * @author Marcos Frare
   * @since 07/09/2021
   */
  public addItem(): void {
    let item = new TemplateLancamentoCondominioItem();
    this.getUnidadesByTipo(item);
    this.templateLancamentoCondominio.templateLancamentoCondominioItems.push(item);
  }

  /**
   * Excluir item
   * @param index - Posição do item no array
   * @author Marcos Frare
   * @since 07/09/2021
   */
  public removeItem(index: number): void {
    this.templateLancamentoCondominio.templateLancamentoCondominioItems.splice(index, 1);
    // Ajustar valor da template
    this.changeValorItem();
  }

  /**
   * Evento de mudança de valor de um ítem da template do lançamento
   * @author Marcos Frare
   * @since 07/09/2021
   */
  public changeValorItem(): void {
    this.templateLancamentoCondominio.valor = 0;
    this.templateLancamentoCondominio.templateLancamentoCondominioItems.map(item => {
      this.templateLancamentoCondominio.valor += Number(item.valor);
    });
  }

}