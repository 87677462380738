export class MenuItem {
    codigo: string;
    titulo: string;
    caminho: string;
    icone: string;
    criar: boolean;
    atualizar: boolean;
    ler: boolean;
    deletar: boolean;
    pai: string; // Item pai
    gerencial: boolean;

    // Grupo de itens
    grupo: boolean;
    expandidoPorPadrao?: boolean; // Se o grupo deve ficar expandido por padrão ao exibir o menu
    subItens?: MenuItem[];
}
