import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { MessageService, AuthService } from 'app/core';
import { PlanoDeContas, CategoriaContas, CategoriaContaGrupo } from 'app/models';
import { PlanoDeContasHttpService } from '../services';

import { PermissionService } from 'app/core/services/permission/permission.service';
import { CategoriaContaGrupoHttpService, CategoriaContasHttpService, UtilsCategoriaContasService } from '../categoria-contas';
import { Permission } from 'app/componentes-base';

@Component({
	selector: 'plano-de-contas-cadastro',
	templateUrl: './plano-de-contas-cadastro.component.html',
	styleUrls: ['./plano-de-contas-cadastro.component.css'],
	providers: [PermissionService]
})
export class PlanoDeContasCadastroComponent implements OnInit {

	public isCarregarCategoriasFilhas: boolean;
	public permissions: Permission;
	public resource = '/plano-de-contas';
	public idCondominio: number;
	public entity: PlanoDeContas;
	public gruposConta: CategoriaContaGrupo[] = [];

	@Input() simpleForm = false;
	@Output() onSaveModal: EventEmitter<CategoriaContas> = new EventEmitter();

	constructor(private activatedRoute: ActivatedRoute,
		private Router: Router,
		private messageService: MessageService,
		private planoDeContasHttpService: PlanoDeContasHttpService,
		private utilsCategoriaContas: UtilsCategoriaContasService,
		private permissionService: PermissionService,
		private categoriaContasHttpService: CategoriaContasHttpService,
		private authService: AuthService,
		private categoriaContaGrupoHttpService: CategoriaContaGrupoHttpService) { }

	ngOnInit() {
		this.idCondominio = this.authService.getIdCurrentCondominio();
		this.loadGruposConta();
		if (!this.entity) {
			this.entity = new PlanoDeContas();
		}
		if (!this.simpleForm) {
			this.activatedRoute.params.subscribe((params: Params) => {
				const id = +params['id'];
				if (!isNaN(id)) {
					this.isCarregarCategoriasFilhas = true;
					this.load(id);
				} else {
					this.isCarregarCategoriasFilhas = false;
					this.entity = new PlanoDeContas();
				}
			});
		}
		this.getPermissions();
	}

	/**
	 * Busca os grupos de conta do condominio
	 * @author Marcos Frare
	 * @since 27/08/2021
	 */
	private loadGruposConta(): void {
		this.gruposConta = [];
		this.categoriaContaGrupoHttpService.getAll().subscribe(
			response => {
				this.gruposConta = response;
			},
			error => {
				this.messageService.error('Erro ao carregar os grupos de conta! Tente novamente.', 'Erro!', error);
			}
		)
	}

	public load(id: number) {
		this.planoDeContasHttpService.get(id).subscribe(
			entity => this.entity = entity
		);
	}

	public clean(): void {
		this.entity = new PlanoDeContas();
	}

	public loadDefault(): void {
		this.planoDeContasHttpService.getIdPlanoDeContaPadraoByCondominio(this.idCondominio).subscribe(
			(idPlanoDeContaPadrao: number) => {
				this.isCarregarCategoriasFilhas = true;
				this.load(idPlanoDeContaPadrao);
			}
		);
	}

	save() {
		this.entity.editado = false;
		this.entity.id_condominio = this.idCondominio;
		this.entity.id_cliente = this.authService.getIdCurrentCliente();
		if (this.entity.id) {
			this.planoDeContasHttpService.put(this.entity).subscribe(
				() => { },
				error => {
					if (error.status === 403) {
						this.messageService.error('Já existe nome similar cadastrado!', 'Erro');
					} else {
						this.messageService.error('Erro ao editar registro. Tente novamente', 'Erro');
					}
				}
			);
		} else {
			this.planoDeContasHttpService.post(this.entity).subscribe(
				entity => {
					this.entity = entity;
					this.createCategoriasFilhasDasPadroes();
				},
				error => {
					if (error.status === 403) {
						this.messageService.error('Já existe nome similar cadastrado!', 'Erro');
					} else {
						this.messageService.error('Erro ao salvar o registro. Tente novamente', 'Erro');
					}
				}
			);
		}
	}

	/**
	 * Cria uma categoria filha para as categorias padrões: Receitas e Depesas para já deixar
	 * disponível para o usuário digitar...
	 */
	private createCategoriasFilhasDasPadroes() {
		this.entity.categoria_contas.forEach(categoriaContaPadrao => {
			// Criando o array das categorias filhas para as categorias padrões "Receitas" e "Despesas"
			categoriaContaPadrao.categoria_contas_filhas = [];

			const categoriaContasFilha = new CategoriaContas();
			categoriaContasFilha.codigo = this.utilsCategoriaContas.gerarCodigo(categoriaContaPadrao.codigo, this.getProximaSequenciaCategoria(categoriaContaPadrao));
			categoriaContasFilha.id_plano_de_conta = categoriaContaPadrao.id_plano_de_conta;
			categoriaContasFilha.id_categoria_conta_pai = categoriaContaPadrao.id;
			categoriaContasFilha.tipo_conta = categoriaContaPadrao.tipo_conta;
			categoriaContaPadrao.categoria_contas_filhas.push(categoriaContasFilha);
		});
	}

	cancel() {
		this.Router.navigate(['../'], { relativeTo: this.activatedRoute });
	}

	/**
	 * Pega a próxima sequência para a categoria filha das categorias padrões: Receitas e Despesas
	 */
	getProximaSequenciaCategoria(categoriaConta: CategoriaContas) {
		if (!categoriaConta.categoria_contas_filhas) {
			return 1;
		}
		const lastIndex: number = categoriaConta.categoria_contas_filhas.length - 1;
		if (lastIndex < 0) {
			return 1;
		}
		const lastCategoria: CategoriaContas = categoriaConta.categoria_contas_filhas[lastIndex];
		const codigos: string[] = lastCategoria.codigo.split('.');
		const sequencia: number = Number(codigos[codigos.length - 1]) + 1;
		return sequencia;
	}

	onChangeNome() {
		this.entity.editado = true;
	}

	onDeleteCategoria(categoriaConta: CategoriaContas) {
		const index: number = this.entity.categoria_contas.indexOf(categoriaConta);
		const isNew: boolean = !categoriaConta.id;
		if (index > -1) {
			if (isNew) {
				this.entity.categoria_contas.splice(index, 1);
			} else {
				this.categoriaContasHttpService.delete(categoriaConta).subscribe(
					() => {
						this.entity.categoria_contas.splice(index, 1);
						this.messageService.success('Categoria de conta excluída com sucesso', 'Sucesso');
					},
					error => {
						if (error.status === 403) {
							this.messageService.error(`Não foi possível excluir a categoria de conta ${categoriaConta.nome}, pois a mesma está sendo utilizada por algum processo do sistema.`, 'Erro', error);
						} else {
							this.messageService.error('Erro ao excluir categoria de conta, contate o administrador do sistema.', 'Erro', error);
						}
					}
				);
			}
		}
	}

	public onSaveCategoriaConta(categoriaConta: CategoriaContas): void {
		this.onSaveModal.emit(categoriaConta);
	}

	public getPermissions() {
		this.permissionService.getPermissions(this.resource).subscribe(permissions => this.permissions = permissions);
	}
}