import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';

import { ConfigService, CustomHttp } from '../../../core';

import { Ocorrencias } from '../../../models';

@Injectable()
export class OcorrenciasHttpService {
    resource = 'ocorrencia';
    baseUrl: string;

    constructor(private customHttp: CustomHttp,
                private configService: ConfigService) {
        this.baseUrl = `${configService.getBaseUrl()}/${this.resource}`;
    }

    getAll(): Observable<Ocorrencias[]> {
        return this.customHttp.get(`${this.baseUrl}/consultar`)
            .pipe(
                map(response => response.json()),
                catchError(error => _throw(error))
            );
    }

}
