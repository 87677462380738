import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared';
import { ConciliacaoBancariaRelComponent } from './conciliacao-bancaria-rel.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [ConciliacaoBancariaRelComponent]
})
export class ConciliacaoBancariaRelModule { }